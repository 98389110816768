import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import Select from "react-select";
import "../../../../../../../employee-folder/styles/dashboard/reports/consolidatedBudget/index.scss";
import { FiArrowUp } from "react-icons/fi";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
// import EmpTableBodyRow from "../../../../../layout/dashboard/EmpTableBodyRow";
// import EmpTableBodyRow from "../../../../../../../employee-folder/component/layout/dashboard/EmpTableBodyRow";
import {
  formatNumber,
  reactSelectStyle,
} from "../../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminEngDeptConsolidatedBudgetReport,
  getAdminEngDeptConsolidatedBudgetReportPagination,
} from "../../../../../../../redux/reports/consolidated-budget";
import TableBodyRow from "../../../../../../tables/TableBodyRow";
import { getDepartments } from "../../../../../../../redux/department/deparment";
import ActionContext from "../../../../../../../context/ActionContext";
// import { getUserDepartment } from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";

const EngDeptConsolidatedBudget = () => {
  const history = useHistory();

  const [details, setDetails] = useState({
    subsidiary: "",
    department: "",
    period: "",
  });

  const optionList = [
    {
      label: "Subsidiary",
      value: 1,
    },
    {
      label: "Department",
      value: 2,
    },
    {
      label: "Expense",
      value: 3,
    },
  ];

  const tableHeadList = [
    "Department",
    "Subsidiary",
    "Total Amount Projected",
    "Total Actual",
  ];

  const dispatch = useDispatch();
  // const refreshTable = false

  const { user } = useSelector((state) => state.user);
  const { departmentDrop } = useSelector((state) => state.department);

  console.log("dept_drop", departmentDrop);

  const { adminConsolidatedBudgetReports, loadingPage } = useSelector(
    (state) => state.admin_consolidated_budget_report
  );

  const actionCtx = useContext(ActionContext);
  useEffect(() => {
    // dispatch(getUserDepartment(user?.organizations[0]?.id));
    dispatch(getDepartments(user?.organizations[0]?.id));
    if (departmentDrop[0]?.id) {
      const obj = {
        report_type: "consolidated_budget",
        org_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
        dept_id: departmentDrop[0]?.id,
        cur_id: actionCtx?.currencyValue?.id  || "",
      };
      dispatch(getAdminEngDeptConsolidatedBudgetReport(obj));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.activeOrg, actionCtx?.currencyValue]);

  const handlePagination = async (param) => {
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // console.log("linkTwo", linkTwo);
    // link: linkTwo,
    const obj = {
      report_type: "consolidated_budget",
      org_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
      dept_id: departmentDrop[0]?.id,
      page: linkTwo,
      cur_id: actionCtx?.currencyValue?.id  || "",
    };
    dispatch(getAdminEngDeptConsolidatedBudgetReportPagination(obj));
  };

  return (
    <>
      {/* <EmployeeDashboardLayout
        goBack
        pageTitle={`View Consolidated Budget Report`}
        organization
      > */}
      <div className="admin-org-settings-wrap">
        {/* left box start */}
        <div className="title-btn-box ">
          <div className="emp-consolidate-wrap ">
            <div className="consolidate-left-box">
              <div className="subtitle-box ">
                <p className="text">Report</p>
                <FaAngleRight className="icon" />
                <p
                  className="text"
                  onClick={() => history.push("/dashboard-general-report")}
                >
                  General Reports
                </p>
                <FaAngleRight className="icon" />
                <p className="active text">Consolidated Budget</p>
              </div>
            </div>
            {/* left box end */}

            {/* select wrap start */}

            <div className="emp-filter-section consolidate-right-box">
              <div className="filter-label">
                <label className="label">Filter By</label>
              </div>
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  placeholder="Period"
                  className="select"
                  value={details.period}
                  styles={reactSelectStyle}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, period: e };
                    });
                  }}
                  options={optionList}
                />
              </div>
              {/* <div>
                                <Select
                                    // isSearchable={true}
                                    // isMulti

                                    placeholder="Department"
                                    className="select"
                                    value={details.department}
                                    styles={reactSelectStyle}
                                    onChange={(e) => {
                                        setDetails((prev) => {
                                            return { ...prev, department: e };
                                        });
                                    }}
                                    options={optionList}
                                />
                            </div> */}
              {/* <div>
                                <Select
                                    // isSearchable={true}
                                    // isMulti
                                    placeholder="Subsidiary"
                                    className="select"
                                    value={details.subsidiary}
                                    styles={reactSelectStyle}
                                    onChange={(e) => {
                                        setDetails((prev) => {
                                            return { ...prev, subsidiary: e };
                                        });
                                    }}
                                    options={optionList}
                                />
                            </div> */}
            </div>
          </div>
        </div>
        {/* select wrap end */}

        {/* sub-title start */}
        <div className="title-btn-bo">
          {/* text title box start */}
          <div className=" sub-right-box">
            <div>
              <p className="title">
                Engineering Department Consolidated Budget
              </p>
            </div>
            <div className="emp-btn-wrap">
              <button className="emp-export-btn">
                <FiArrowUp className="export-icon" />
                Export
              </button>
              <button className="emp-print-btn">
                Print
                <FaAngleDown className="print-icon" />
              </button>
            </div>
            {/* sub-title end */}
          </div>
        </div>
        {/* table start */}
        <ActualReuseableTable
          dontShowBnSearch={true}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          dontShowPagination={adminConsolidatedBudgetReports?.data?.length < 1}
          fromPage={adminConsolidatedBudgetReports?.from}
          toPage={adminConsolidatedBudgetReports?.to}
          totalPage={adminConsolidatedBudgetReports?.total}
          nextPage={adminConsolidatedBudgetReports?.next_page_url}
          prevPage={adminConsolidatedBudgetReports?.prev_page_url}
          onNextPage={() =>
            handlePagination(adminConsolidatedBudgetReports?.next_page_url)
          }
          onPrevPage={() =>
            handlePagination(adminConsolidatedBudgetReports?.prev_page_url)
          }
        >
          {adminConsolidatedBudgetReports?.data?.map((chi, idx) => {
            const {
              chart_of_account,
              expense_class,
              amount_projected,
              supplementary_budget,
              total_budget,
            } = chi;
            return (
              <TableBodyRow
                key={idx}
                one={chart_of_account}
                two={expense_class}
                three={formatNumber(String(amount_projected))}
                four={formatNumber(String(supplementary_budget))}
                five={formatNumber(String(total_budget))}
                loading={loadingPage}
                rowNo={idx}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default EngDeptConsolidatedBudget;
