import React from "react";
import "../../../../styles/dashboard/admin-settings/ApprovalSettingModal.css";
import passwordUnlock from "../../../../assets/password-unlock-icon.svg";
import "../../../styles/auth/employee-success-message.scss";

const EmployeePasswordUpdate = ({ children }) => {
  return (
    <div
      style={{ width: "120vh" }}
      className="approval-setting-modal-wrap emp-success-msg "
    >
      {/* no comment 😐 */}
      <figure>
        <img src={passwordUnlock} alt="" />
      </figure>
      <span className="employee-congratulations">Password Updated !</span>
      <br />
      <span>Your attempt to setup a new password is confirmed</span>
      <span>and your new password is updated successfully. </span>
      {children}
    </div>
  );
};
export default EmployeePasswordUpdate;
