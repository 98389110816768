import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc, customErrorId } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getFiscalYears = createAsyncThunk(
  "admin/get-fiscal-years",
  async (formData, thunkAPI) => {
    try {
      let url = "";
      if (formData?.reason) {
        url = `/employee/fiscal-years/organization/${formData?.id}`;
      } else {
        url = `/admin/fiscal-years/organization/${formData}`;
      }
      const { data } = await axios.get(url);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setfiscalYearDrop(data.data.fiscal_years));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      //   toast.error(err?.response?.data?.message, {
      //     theme: "colored",
        // toastId: customErrorId,
      //   });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getFiscalYearWithPagination = createAsyncThunk(
  "admin/get-fiscal-year-with-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/fiscal-years/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}`
      );
      // console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setFiscalYears(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setFiscalYears(data.data.fiscal_years));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getFiscalYearByFilter = createAsyncThunk(
  "admin/get-fiscal-year-filter",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/admin/fiscal-years/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const departmentInfo = data.data.fiscal_years;
        await thunkAPI.dispatch(setFiscalYearFilter(departmentInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getFiscalYearPagination = createAsyncThunk(
  "admin/get-fiscal-year-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        // await thunkAPI.dispatch(setFiscalYears(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const departmentInfo = data.data.fiscal_years;
        await thunkAPI.dispatch(setFiscalYears(departmentInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getFiscalYear = createAsyncThunk(
  "admin/get-fiscal-year",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/fiscal-years/${formData}`);
      // console.log("shared-ser", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createFiscalYear = createAsyncThunk(
  "admin/create-fiscal-year",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/fiscal-years", formData);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteFiscalYear = createAsyncThunk(
  "admin/delete-fiscal-year",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/shared-services/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateFiscalYear = createAsyncThunk(
  "admin/update-fiscal-year",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/shared-services/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const fiscal_year = createSlice({
  name: "fiscal-year",
  initialState: {
    fiscalYearDrop: [],
    fiscal_years: [],
    links: {},
    meta: {},
    fiscal_year: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setFiscalYears: (state, action) => {
      state.isAuth = true;
      state.fiscal_years = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setFiscalYearFilter: (state, action) => {
      state.isAuth = true;
      state.fiscal_years = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setfiscalYearDrop: (state, action) => {
      state.isAuth = true;
      state.fiscalYearDrop = addLabelValueFunc(action.payload);
    },
    setFiscalYear: (state, action) => {
      state.isAuth = true;
      state.role = action.payload;
    },
  },
  extraReducers: {
    [createFiscalYear.pending]: (state) => {
      state.loading = true;
    },
    [createFiscalYear.fulfilled]: (state) => {
      state.loading = false;
    },
    [createFiscalYear.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateFiscalYear.pending]: (state) => {
      state.loading = true;
    },
    [updateFiscalYear.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateFiscalYear.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getFiscalYearPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getFiscalYearPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getFiscalYearPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getFiscalYearByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getFiscalYearByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getFiscalYearByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getFiscalYearWithPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getFiscalYearWithPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getFiscalYearWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteFiscalYear.pending]: (state) => {
      state.loading = true;
    },
    [deleteFiscalYear.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteFiscalYear.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFiscalYear,
  setFiscalYears,
  setfiscalYearDrop,
  setFiscalYearFilter,
} = fiscal_year.actions;

export default fiscal_year.reducer;
