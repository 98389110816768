import React, { useContext, useEffect, useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { updateChatOfAccount } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import { getCategoriesByClass } from "../../../../../../redux/budget-setting/expenditure-projection/categories";

const UpdateBudgetExpAccountModal = ({
  onCancel,
  onEdit,
  onFinish,
  detail,
}) => {
  const { classDrop } = useSelector((state) => state.classes);
  const { categoryDrop } = useSelector((state) => state.categories);
  const { loading } = useSelector((state) => state.chatOfAccount);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    organization_id: "",
    projection_class_id: "",
    projection_category_id: "",
    name: "",
    code: "",
    description: "",
    should_spread_budget_item: false,
  });

  useEffect(() => {
    details?.projection_class_id &&
      setDetails((prev) => {
        return {
          ...prev,
          projection_class_id: "",
          projection_categories_id: "",
        };
      });
    onEdit("", true);
      // eslint-disable-next-line
  }, [actionCtx?.activeOrg]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    onEdit(e);
  };

  const handleSubmit = async () => {
    const obj = {
      id: detail?.id,
      name: details?.name || detail?.name,
      description: details?.description || detail?.description,
      projection_class_id:
        details?.projection_class_id?.value || detail?.projection_class?.id,
      organization_id: actionCtx?.activeOrg?.id,
      projection_category_id:
        details?.projection_category_id?.value ||
        detail?.projection_category_id?.value,
      should_spread_budget_item:
        details?.should_spread_budget_item || detail?.should_spread_budget_item,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(updateChatOfAccount(obj));
    if (data?.payload?.success) {
      onFinish();
      setDetails({
        organization_id: "",
        projection_class_id: "",
        projection_category_id: "",
        name: "",
        code: "",
        description: "",
        should_spread_budget_item: false,
      });
    }
  };

  return (
    <>
      <CommonInputModal
        longContent
        onCancel={onCancel}
        title={`Update Chart Of Account`}
        btnText={`Update`}
        loading={loading}
        btnActive={
          details?.description ||
          details?.name ||
          details?.projection_class_id ||
          details?.projection_category_id
            ? true
            : false
        }
        onBtnClick={handleSubmit}
        btnDisable={
          !details?.description &&
          !details?.name &&
          !details?.projection_class_id
        }
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={detail?.name}
            type="text"
            placeholder="Name expense category"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Code
          </label>
          <input
            name="code"
            onChange={handleChange}
            value={detail?.code}
            type="text"
            placeholder="enter account code"
            className={`input ${details.code && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Class <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="Select expense class"
            className="select "
            value={detail?.projection_class_id}
            onChange={(e) => {
              dispatch(getCategoriesByClass(e.value));
              setDetails((prev) => {
                return { ...prev, projection_class_id: e };
              });
              onEdit(e, "class");
            }}
            options={classDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Category <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="Select expense category"
            className="select "
            value={detail?.projection_category_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, projection_category_id: e };
              });
              onEdit(e, "category");
            }}
            options={categoryDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
            value={detail?.description}
            type="text"
            placeholder="describe your expenditure class"
          ></textarea>
        </div>
        {/* group box end */}
        {/* check start */}
        <div className="check-label-box">
          <input
            checked={
              detail?.should_spread_budget_item === "1"
                ? true
                : detail?.should_spread_budget_item === "0"
                ? false
                : detail?.should_spread_budget_item
            }
            // value={detail?.should_spread_budget_item ===  "1" ? true : false}
            type="checkbox"
            className="check"
            id="check-email-update"
            name="should_spread_budget_item"
            onChange={(e) => {
              // console.log(e.target.checked);
              setDetails((prev) => {
                return { ...prev, should_spread_budget_item: e.target.checked };
              });
              onEdit(e.target.checked, "check");
            }}
          />
          <label htmlFor="check-email-update" className="label">
            <span>
              <FaCheck className="icon" />
            </span>
            Spread budget item <div className="blue">(chart of account)</div>{" "}
            evenly across months{" "}
          </label>
        </div>
        {/* check end */}
      </CommonInputModal>
    </>
  );
};

export default UpdateBudgetExpAccountModal;
