import { useRef, useState } from "react";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import "../../../../../../styles/dashboard/PartialReimbursement.css";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { partialReimbursement } from "../../../../../../redux/employee/actual-expense";
import { useDispatch } from "react-redux";
import { Dna } from "react-loader-spinner";
import { NumericFormat } from "react-number-format";
import { formatNumberWithoutComma } from "../../../../../../helper/Helpers";

const PartialReimbursement = ({ onCancel, expense, handleFinalModal }) => {
  const [details, setDetails] = useState({
    id: "",
    is_partial_payment: false,
    is_full_payment: false,
    amount: "",
    full_amount: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const [loader, setLoader] = useState(false);

  const ref = useRef(null);
  const reff = useRef(null);

  const dispatch = useDispatch();

  const handleFinish = () => {
    setDetails({
      id: "",
      is_partial_payment: false,
      is_full_payment: false,
      amount: "",
      full_amount: "",
    });
    // onCancel();
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    const obj = {
      id: expense?.id,
      is_partial_payment: details?.is_partial_payment,
      amount: formatNumberWithoutComma(
        String(details?.amount || details?.full_amount)
      ),
    };
    setLoader(true);
    const data = await dispatch(partialReimbursement(obj));
    if (data?.payload?.success) {
      setLoader(false);
      handleFinish();
      handleFinalModal();
    } else {
      setLoader(false);
    }
  };

  return (
    <div className="partial-reimburse-wrap">
      <p className="title">{`${expense?.chart_of_account?.name} Re-imbursement `}</p>
      <p className="text">Perform a full or partial reimbursement below</p>
      {/* form start */}
      <form className="par-form-wrap" onSubmit={handleSubmit}>
        {/* form group start */}
        {/* partial reimburesement wrap start */}
        <div className="par-form-group">
          {/* partial reimbursement checkbox start */}
          <input
            type="radio"
            name="radio-btn"
            id="partial_payment"
            value={details?.is_partial_payment}
            checked={details?.is_partial_payment}
            onChange={(e) => {
              setDetails({ full_amount: "" });
              setDetails((prev) => {
                return {
                  ...prev,
                  is_partial_payment: e.target.checked,
                  is_full_payment: false,
                };
              });
              ref.current.focus();
            }}
          />
          <label
            htmlFor="partial_payment"
            className={`label ${details?.is_partial_payment && "label-active"}`}
          >
            <MdOutlineRadioButtonChecked
              className={`radio-icon ${
                details?.is_partial_payment && "radio-icon-active"
              }`}
            />
            Partial Reimbursement
          </label>
          {/* partial reimbursement checkbox end */}
          {/* partial reimbursement field start */}
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            type="text"
            name="amount"
            id="partial_reimbursement"
            placeholder={details?.is_partial_payment ? "" : "Enter amount here"}
            className={`input ${details?.is_partial_payment && "field-active"}`}
            value={details?.amount}
            onChange={handleChange}
            readOnly={!details?.is_partial_payment}
            autoFocus={details?.is_partial_payment}
            getInputRef={ref}
          />
          {/* partial reimbursement field end */}
        </div>
        {/* partial reimburesement wrap end */}
        {/* form group end */}
        {/* form group start */}
        {/* full reimbursement wrap start */}
        {/* full reimburesement checkbox start */}
        <div className="par-form-group">
          <input
            type="radio"
            id="full_payment"
            name="full_payment"
            value={details?.is_full_payment}
            checked={details?.is_full_payment}
            onChange={(e) => {
              setDetails({ amount: "" });
              setDetails((prev) => {
                return {
                  ...prev,
                  is_full_payment: e.target.checked,
                  is_partial_payment: false,
                };
              });
              reff.current.focus();
            }}
          />
          {/* full reimburesement checkbox end */}
          {/* full reimbursement field start */}
          <label
            htmlFor="full_payment"
            className={`label ${details?.is_full_payment && "label-active"}`}
          >
            <MdOutlineRadioButtonChecked
              className={`radio-icon ${
                details?.is_full_payment && "radio-icon-active"
              }`}
            />
            Full Reimbursement
          </label>
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            type="text"
            name={"full_amount"}
            id="full_reimbursement"
            placeholder={details?.is_full_payment ? "" : "Enter amount here"}
            className={`input ${details?.is_full_payment && "field-active"}`}
            onChange={handleChange}
            value={details?.full_amount}
            getInputRef={reff}
            readOnly={!details?.is_full_payment}
            autoFocus={details?.is_full_payment}
          />
          {/* full reimbursement field end */}
        </div>
        {/* full reimbursement wrap end */}
      </form>
      {/* form end */}
      <div className="btn">
        <ButtonBlue
          style={{
            border: ".1rem solid var(--blue-color)",
            color: "var(--blue-color)",
          }}
          text={"Cancel"}
          bgColor={"var(--white-color)"}
          onClick={() => {
            handleFinish(onCancel());
          }}
        />

        {loader ? (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="40"
              width="100"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue
            text={"Confirm"}
            bgColor={
              details?.amount || details?.full_amount
                ? "var(--blue-color)"
                : "#cccccc"
            }
            onClick={() => {
              (details?.amount || details?.full_amount) && handleSubmit();
            }}
          />
        )}
      </div>
    </div>
  );
};
export default PartialReimbursement;
