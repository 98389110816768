import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import cameraImg from "../../../../../../../assets/camera-icon.svg";
// import userImg from "../../../../../../../assets/userImage.png";
import userImg from "../../../../../../../assets/Unknown_person.jpeg";
// import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import Select from "react-select";
import EmployeeCommonSettingsLayout from "../links/commonLayout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCountries, updateUser } from "../../../../../../../redux/user";
import { reactSelectStyle } from "../../../../../../../helper/Helpers";
import { Dna } from "react-loader-spinner";
import { PatternFormat } from "react-number-format";

const EmployeeSettingsIndex = () => {
  // const history = useHistory();
  const [details, setDetails] = useState({
    profile_picture: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    role: "",
    gender: "",
    country: "",
    department: "",
    email: "",
    about_me: "",
  });
  const [userDisplay, setUserDisplay] = useState({});
  const { user, loading, countries } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountries());
    const obj = {
      ...user,
      country: { label: user?.country, value: user?.country },
      gender: { label: user?.gender, value: user?.gender },
    };
    // console.log(obj);
    setUserDisplay(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    const dataTwo = { ...userDisplay, [name]: value };
    setUserDisplay(dataTwo);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      first_name: details?.first_name || userDisplay?.first_name,
      last_name: details?.last_name || userDisplay?.last_name,
      phone_number: details?.phone_number || userDisplay?.phone_number,
      gender: details?.gender?.label || userDisplay?.gender.label,
      country: details?.country?.label || userDisplay?.country.value,
      profile_picture: details?.profile_picture ? details?.profile_picture : "",
      about_me: details?.about_me || userDisplay?.about_me,
      // department: details?.department?.name || userDisplay?.department?.name
    };
    const formData = new FormData();
    formData.append("country", obj?.country);
    formData.append("first_name", obj?.first_name);
    formData.append("last_name", obj?.last_name);
    formData.append("phone_number", obj?.phone_number);
    formData.append("gender", obj?.gender);
    formData.append("about_me", obj?.about_me);
    obj?.profile_picture &&
      formData.append("profile_picture", obj?.profile_picture);
    // console.log(obj?.profile_picture, "profile");

    const data = await dispatch(updateUser(formData));
    if (data?.payload?.success) {
      // console.log(user);
      const newObj = data?.payload?.data[0];
      const combineObj = { ...user, ...newObj };
      localStorage.setItem("user", JSON.stringify(combineObj));
      // setRefreshDetail(!refreshDetail)
      // window.location.reload();
    }
  };

  // console.log(user, "--user--- ");
  const genderList = [
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
    { label: "Any", value: 3 },
  ];

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   const data = { ...details, [name]: value };
  //   setDetails(data);
  // };

  return (
    <>
      <EmployeeCommonSettingsLayout settingsLink>
        {/* <DashboardLayout pageTitle={`Admin Settings`} goBack> */}
        <div className="profile-setting-wrap">
          {/* title btn box start */}
          <div className="title-btn-box">
            {/* text tititl box start */}
            <div className="title-text-box">
              <p className="title">Profile Picture</p>
              {/* <p className="text">
                Set your organization subsidiary . Once added, you can easily
                switch between created subsidiaries of the organization.
              </p> */}
            </div>
          </div>
          {/* text tititl box end */}
          {/* form statrt */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* image profile start */}
            <div className="image-display-box">
              <div className="wrap">
                <figure className="img-display">
                  <img
                    src={
                      details?.profile_picture
                        ? URL.createObjectURL(details?.profile_picture)
                        : user?.profile_picture || userImg
                        ? user?.profile_picture || userImg
                        : userImg
                    }
                    className="img"
                    alt=""
                  />
                </figure>
                <input
                  onChange={(e) => {
                    setDetails((prev) => {
                      return {
                        ...prev,
                        profile_picture: e.target.files[0],
                      };
                    });
                    setUserDisplay((prev) => {
                      return {
                        ...prev,
                        profile_picture: e.target.files[0],
                      };
                    });
                  }}
                  type="file"
                  className="input"
                  name=""
                  id="img-user"
                />
                <label htmlFor="img-user" className="label">
                  <figure className="img-box">
                    <img src={cameraImg} alt="" className="img" />
                  </figure>
                </label>
              </div>
              <p className="text">
                Only standard format are allowed Jpg, png. File must not be more
                than 500kb
              </p>
            </div>
            {/* image profile start */}
            {/* 6 inputs form wrap start */}
            <div className="form-group-wrap">
              <p className="title">Personal Details</p>
              {/* wrap start */}
              <div className="wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="First Name" className="label">
                    First Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="First name"
                    className={`input ${details?.first_name && "input-active"}`}
                    id="first_name"
                    name="first_name"
                    onChange={handleChange}
                    value={userDisplay?.first_name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Last Name" className="label">
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Last name"
                    className={`input ${details?.last_name && "input-active"}`}
                    id="last_name"
                    name="last_name"
                    onChange={handleChange}
                    value={userDisplay?.last_name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group" style={{ marginTop: "2px" }}>
                  <label htmlFor="email" className="label">
                    Email
                  </label>
                  <input
                    type="text"
                    placeholder="hlamidi@zojatech.com"
                    className={`input ${details.email && "input-active"}`}
                    id="email"
                    style={{
                      background: "#ECEDED",
                    }}
                    name="email"
                    //   onChange={handleChange}
                    value={userDisplay?.email}
                    disabled
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="gender" className="label">
                    Gender
                  </label>
                  <Select
                    className={`select-class ${
                      userDisplay?.gender && "select-wrap-active"
                    }`}
                    placeholder={"Select Gender"}
                    value={
                      userDisplay?.gender === null
                        ? "Select Gender"
                        : userDisplay?.gender || details?.gender
                    }
                    styles={reactSelectStyle}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, gender: e };
                      });
                      setUserDisplay((prev) => {
                        return { ...prev, gender: e };
                      });
                    }}
                    options={genderList}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="country" className="label">
                    Country
                  </label>
                  <Select
                    className={`select-class ${
                      userDisplay?.country && "select-wrap-active"
                    }`}
                    placeholder="Select Country"
                    styles={reactSelectStyle}
                    value={
                      userDisplay?.country === null
                        ? "Select Country"
                        : userDisplay?.country || details?.country
                    }
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, country: e };
                      });
                      setUserDisplay((prev) => {
                        return { ...prev, country: e };
                      });
                    }}
                    options={countries}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Phone Number" className="label">
                    Phone Number
                  </label>
                  <PatternFormat
                    id="contact_line"
                    onChange={handleChange}
                    value={userDisplay?.phone_number || details?.phone_number}
                    name="contact_line"
                    placeholder="Contact line"
                    format="### ### ### ###"
                    type="text"
                    // placeholder="0908705060617"
                    className={`input ${
                      details.phone_number && "input-active"
                    }`}
                    // style={{
                    //   background: "#ECEDED",
                    // }}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group" style={{ marginTop: "2px" }}>
                  <label htmlFor="department" className="label">
                    Department
                  </label>
                  <input
                    type="text"
                    placeholder="Product & Sales"
                    className={`input ${details?.department && "input-active"}`}
                    id="department"
                    style={{
                      background: "#ECEDED",
                    }}
                    name="department"
                    // onChange={handleChange}
                    value={userDisplay?.department?.name}
                    disabled
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group" style={{ marginTop: "2px" }}>
                  <label htmlFor="role" className="label">
                    Role
                  </label>
                  <input
                    type="text"
                    placeholder="Budget Approval"
                    className={`input ${details.role && "input-active"}`}
                    id="role"
                    name="role"
                    style={{
                      textTransform: "capitalize",
                      background: "#ECEDED",
                    }}
                    // onChange={handleChange}
                    value={userDisplay?.role}
                    disabled
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="about" className="label">
                    About Me
                  </label>
                  <textarea
                    type="text"
                    placeholder="Describe yourself"
                    className={`input ${details?.about_me && "input-active"}`}
                    id="about_me"
                    cols="30"
                    rows="6"
                    style={{ width: "205%", resize: "unset" }}
                    name="about_me"
                    onChange={handleChange}
                    value={details?.about_me}
                  />
                </div>
                {/* group box end */}
              </div>
              {/* wrap end */}
            </div>
            {/* 6 inputs form wrap end */}
            {/* button start */}
            {!loading ? (
              <input
                disabled={
                  !details?.country &&
                  !details?.gender &&
                  !details?.profile_picture &&
                  !details?.first_name &&
                  !details?.last_name &&
                  details?.phone_number
                }
                type="submit"
                value="Save & Continue"
                className={`form-group btn-reuse form-submit ${
                  details?.country ||
                  details?.gender ||
                  details?.profile_picture ||
                  details?.first_name ||
                  details?.last_name ||
                  details?.phone_number
                    ? "form-submit-active"
                    : ""
                }`}
                style={{ marginTop: "3rem" }}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "3rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/*btn end */}
          </form>
          {/* form end */}
        </div>
        {/* </DashboardLayout> */}
      </EmployeeCommonSettingsLayout>
    </>
  );
};
export default EmployeeSettingsIndex;
