/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
import "../../../../../styles/dashboard/actuals/expense/actualExpense.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import EmployeeAttachMoreFiles from "./addMoreFiles";
import moment from "moment";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/airbnb.css";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import { Dna } from "react-loader-spinner";
import {
  createEmployeeActualExpense,
  getSingleEmployeeActualExpensesDraft,
  updateEmployeeActualExpense,
} from "../../../../../../redux/employee/actual-expense";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { NumericFormat } from "react-number-format";
import Modal from "react-awesome-modal";
import EmpSharedServiceModal from "../modals/sharedServiceModal";
import {
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import SharedBudgetModal from "../modals/sharedBudgetModal";
import SharedBudgetModalMessage from "../modals/sharedBudgetMessage";
import ActionContext from "../../../../../../context/ActionContext";
import { toast } from "react-toastify";

const EmployeeActualNewExpenseUpdate = ({ text, name, button }) => {
  // const [startDate, setstartDate] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.actual_expense_employee);
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);
  const [singleActual, setSingleActual] = useState({});
  const { user } = useSelector((state) => state.user);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );

  const { loadingPage } = useSelector((state) => state.department);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { departmentDrop } = useSelector((state) => state.department);
  const [details, setDetails] = useState({
    expense_date: "",
    chart_of_account_id: "",
    unit_price: "",
    units: "",
    description: "",
    total_expense: "",
    receipt: [],
  });

  const status = localStorage.getItem("employee-actual-expense-status");

  const [imageToUpload, setImageToUpload] = useState([]);

  const history = useHistory();

  const [showSharedModal, setShowSharedModal] = useState(false);
  // const [isApportion, setIsApportion] = useState([]);
  // const [isShareBudgetModal, setIsShareBudgetModal] = useState(false);
  const [isSharedBtnLoad, setIsSharedBtnLoad] = useState(false);
  // const [isShareBudgetModal, setIsShareBudgetModal] = useState(false);
  const [isSharedBudget, setIsSharedBudget] = useState(false);
  const [isSharedBudgetMessage, setIsSharedBudgetMessage] = useState(false);
  const [isApportionAlert, setIsApportionAlert] = useState(false);
  const [isShareBudgetModal, setIsShareBudgetModal] = useState(false);
  const [isSharedComplete, setIsSetSharedComplete] = useState(false);
  // const [subOption, setSubOption] = useState([]);

  const actionCtx = useContext(ActionContext);

  // const editApportionToSubmit = async (list, dept) => {
  //   // console.log(list, "list");
  //   if (list?.length > 0) {
  //     // console.log(singleActual?.shared_service_details, "new-list");
  //     const subsidiaryOptions = list?.map((chi) => {
  //       return {
  //         // ...chi,
  //         value: chi?.value,
  //         label: chi?.label,
  //         apportions: chi?.apportions,
  //       };
  //     });
  //     setSubOption(subsidiaryOptions);
  //   }
  // };

  // useEffect(() => {
  //   editApportionToSubmit(
  //     singleActual?.shared_service_details,
  //     singleActual?.department_id
  //   );
  // }, []);

  // const formatApportionToSubmit = (obj, e) => {
  //   const newList = isApportion?.map((chi) =>
  //     obj?.id === chi?.id ? { ...chi, apportion: e } : chi
  //   );
  //   setIsApportion(newList);
  // };

  // useEffect(() => {
  //   handleApportionSum();
  // }, [isApportion]);

  // const [apportionSum, setApportionSum] = useState("");
  // const handleApportionSum = () => {
  //   const totalApportion = isApportion
  //     ?.map((chi) => chi.apportion)
  //     .reduce((a, b) => Number(a) + Number(b), 0);
  //   setApportionSum(totalApportion);
  // };

  const handleSubmit = async (e, param) => {
    e.preventDefault();
    // console.log("details-------", {...details, receipt: imageToUpload});
    // const status = localStorage.getItem("employee-actual-expense-status");
    const newApportionListForSHared = singleActual?.shared_service_details?.map(
      (chi) => {
        return {
          subsidiary_id: chi?.value,
          apportions: Number(chi?.budget_percentage),
          subsidiary_departments: chi?.selected_departments?.map((child) => {
            return {
              department_id: child?.value,
              apportion: Number(child?.shared_apportion),
            };
          }),
        };
      }
    );

    if (singleActual?.shared_service_details?.length > 0 && !isSharedComplete) {
      toast.info("Submit To Confirm Your Budget Percentage", {
        theme: "colored",
      });
      return;
    }

    const newApportionListData = JSON.stringify(newApportionListForSHared);
    const formData = new FormData();
    formData.append(
      "expense_date",
      moment(singleActual?.expense_date).format("YYYY-MM-DD")
    );
    formData.append(
      "chart_of_account_id",
      singleActual?.chart_of_account_id?.value
    );
    formData.append(
      "is_shared_service",
      singleActual?.is_shared_service &&
        singleActual?.shared_service_details?.length > 0
        ? 1
        : 0
    );
    formData.append(
      "unit_price",
      formatNumberWithoutComma(String(singleActual?.unit_price))
    );
    formData.append("subsidiary_id", singleActual?.subsidiary_id?.value);
    formData.append(
      "units",
      formatNumberWithoutComma(String(singleActual?.units))
    );
    formData.append("description", singleActual?.description);
    // details?.total_expense
    //   ? formData.append(
    //       "total_expense",
    //       formatNumberWithoutComma(singleActual?.total_expense)
    //     )
    //   :
    formData.append("total_expense", singleActual?.total_expense);
    Array.from(imageToUpload).forEach((image) => {
      formData.append("receipt[]", image);
    });
    formData.append("departments_aportions", newApportionListData);
    formData.append("status", singleActual?.status);
    formData.append("organization_id", user?.organizations[0]?.id);
    formData.append("is_submitted", param);
    formData.append("department_id", singleActual?.department_id?.value);
    // console.log(singleActual);
    // return
    const val = {
      id: singleActual?.id,
      obj: formData,
    };

    // console.log(val);
    const data = await dispatch(updateEmployeeActualExpense(val));
    if (data?.payload?.success && singleActual?.is_submitted === 0) {
      history.push("/employee-dashboard-actual-expense");
      // setDetails({
      //   expense_date: "",
      //   chart_of_account_id: "",
      //   unit_price: "",
      //   units: "",
      //   description: "",
      //   total_expense: "",
      //   receipt: [],
      // });
      // setstartDate("");
      // setImageToUpload([]);
    } else if (data?.payload?.success && singleActual?.is_submitted === 1) {
      history.push("/employee-dashboard-actual-expense-submitted");
    }
  };

  useEffect(() => {
    // console.log("ertyuiuyt");
    const empObj = {
      reason: true,
      id: user?.organizations[0]?.id,
    };
    dispatch(getChatAccounts(empObj));
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    dispatch(getDepartments(user?.organizations[0]?.id));
    dispatch(getSubsidiaries(empObj));
    getEmployeeActualInfo();
    // eslint-disable-next-line
  }, []);

  const getEmployeeActualInfo = async () => {
    const id = localStorage.getItem("employee-actual-expense-id");
    const obj = {
      id: id,
    };

    const data = await dispatch(getSingleEmployeeActualExpensesDraft(obj));
    if (data?.payload?.success) {
      const expense = data?.payload?.data?.expense;

      const getApportionListForShared = expense?.shared_service_details?.map(
        async (chi) => {
          const obj = {
            reason: true,
            id: chi?.subsidiary?.id,
          };
          const data = await dispatch(getDepartmentsBySubsidiary(obj));

          return {
            ...chi,
            value: chi?.subsidiary?.id,
            label: chi?.subsidiary?.name,
            departments: data?.payload?.data?.departments,
            budget_percentage: chi?.apportions,
            selected_departments: chi?.departments_shared?.map((chil) => {
              return {
                ...chil,
                name: chil?.department?.name,
                id: chil?.department?.id,
                shared_apportion: chil?.apportion,
                value: chil?.department?.id,
                label: chil?.department?.name,
              };
            }),
            // options: dropData,
          };
        }
      );
      let sharedList = [];
      if (getApportionListForShared) {
        const newData = await Promise.all(getApportionListForShared);
        sharedList = newData;
      }

      let departmentOptionDrop = [];
      let checkActiveSharedBtn = [];
      const obj = {
        id: expense?.subsidiary?.id,
        reason: true,
      };

      const drop = await dispatch(getDepartmentsBySubsidiary(obj));
      if (drop?.payload?.success) {
        const newDropData = drop?.payload?.data?.departments;
        const activeSharedBtnData = newDropData?.map(
          (chi) => chi?.branch?.subsidiary?.shared_service
        );
        const newActveSharedBtnData = activeSharedBtnData[0]?.length > 0;
        checkActiveSharedBtn = newActveSharedBtnData;
        departmentOptionDrop = newDropData;
      }

      let subsdiaryBudgetDropdown = [];
      const deptBudgetDrop = await dispatch(
        getDepartments(user?.organizations[0]?.id)
      );
      if (deptBudgetDrop?.payload?.success) {
        const newDeptBudgetDrop = deptBudgetDrop?.payload?.data?.departments;
        const deptValue = newDeptBudgetDrop?.find((chi, idx) => {
          if (chi?.id === expense?.department?.id) {
            return {
              ...chi,
            };
          }
        });
        const newDeptValue =
          deptValue?.branch?.subsidiary?.shared_service[0]?.apportions?.map(
            (chi) => {
              return {
                ...chi,
                label: chi?.subsidiary?.name,
                value: chi?.subsidiary?.id,
              };
            }
          );
        if (deptValue) {
          subsdiaryBudgetDropdown = newDeptValue;
        }
      }
      if (expense?.is_shared_service === true) {
        setIsShareBudgetModal(true);
        actionCtx.setIsModalOut(true);
      }

      const newObj = {
        ...expense,
        chart_of_account_id: {
          ...expense?.chart_of_account,
          label: expense?.chart_of_account?.name,
          value: expense?.chart_of_account?.id,
        },
        deptOptions: departmentOptionDrop,
        subBudgetOption: subsdiaryBudgetDropdown,
        department_id: {
          ...expense?.department,
          label: expense?.department?.name,
          value: expense?.department?.id,
        },
        check_shared: checkActiveSharedBtn,
        subsidiary_id: {
          ...expense?.subsidiary,
          label: expense?.subsidiary?.name,
          value: expense?.subsidiary?.id,
        },
        shared_service_details: sharedList,
      };
      setSingleActual(newObj);
    }
  };

  useEffect(() => {
    const calcVal =
      formatNumberWithoutComma(String(singleActual?.unit_price)) *
      formatNumberWithoutComma(String(singleActual?.units));
    setSingleActual((prev) => {
      return { ...prev, total_expense: calcVal };
    });
    setDetails((prev) => {
      return { ...prev, total_expense: calcVal };
    });
  }, [singleActual?.unit_price, singleActual?.units]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...singleActual, [name]: value };
    setSingleActual(data);
  };

  const handleAddToImageList = (param) => {
    const obj = [param, ...singleActual?.receipts];
    setSingleActual((prev) => {
      return {
        ...prev,
        receipts: obj,
      };
    });
  };

  // const handleApportionSubmit = () => {
  //   const alertText = "The sharing percentage is above 100%";
  //   if (isApportion && apportionSum > 100) {
  //     toast.error(alertText, {
  //       theme: "colored",
  //     });
  //   } else {
  //     setShowSharedModal(false);
  //     actionCtx?.setIsModalOut(false);
  //   }
  // };

  const apportionData = departmentDrop?.branch?.subsidiary?.shared_service;

  // const { preference } = useSelector((state) => state.preference);
  // const isDarkTheme = useThemeDetector();
  // require(`flatpickr/dist/themes/${
  //   preference?.display === "Light"
  //               ? "airbnb"
  //               : preference?.display === "Dark"
  //               ? "dark"
  //               : isDarkTheme
  //               ? "dark"
  //               : "airbnb"
  // }.css`);
  const handleNewBudgetPercentage = (value, valId) => {
    const newValue = singleActual.shared_service_details?.map((chi) =>
      chi?.value === valId ? { ...chi, budget_percentage: value } : chi
    );
    setSingleActual((prev) => {
      return { ...prev, shared_service_details: newValue };
    });
  };

  const handleMultiSelectChange = (
    selectedValues,
    { action, removedValue },
    list
  ) => {
    const addSharedPortionToList = (param) => {
      const newList = param?.map((chi, idx) => {
        return { ...chi, budget_percentage: "" };
      });
      return newList;
    };

    if (action === "select-option") {
      const newlyAddedOptionList = selectedValues.map((option) =>
        !singleActual?.shared_service_details?.includes(option)
          ? {
              ...option,
              departments: addSharedPortionToList(list),
              selected_departments: [],
            }
          : option
      );
      // console.log("Newly added option:", newlyAddedOptionList);
      // setAllSelectedSubsidiary(newlyAddedOptionList);
      setSingleActual((prev) => {
        return {
          ...prev,
          shared_service_details: newlyAddedOptionList,
        };
      });
    }
  };

  const handleFormatDepartmentDrop = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          value: chi?.id,
          label: chi?.name,
        };
      });
      return newList;
    }
  };

  const handleMultiSelectChangeTwo = (
    selectedValues,
    { action, removedValue },
    list,
    id
  ) => {
    const getObjChild = singleActual?.shared_service_details?.find(
      (chi) => chi?.value === id
    );
    const addSelectedToObjChild = {
      ...getObjChild,
      selected_departments: selectedValues,
    };
    // console.log(addSelectedToObjChild);
    const addBackToAllList = singleActual?.shared_service_details?.map((chi) =>
      chi?.value === id ? addSelectedToObjChild : chi
    );
    setSingleActual((prev) => {
      return { ...prev, shared_service_details: addBackToAllList };
    });
  };

  const handleApportionDeptChange = (sudId, deptId, value) => {
    const getObjChild = singleActual?.shared_service_details?.find(
      (chi) => chi?.value === sudId
    );

    const addSelectedToObjChild = getObjChild?.selected_departments?.map(
      (chi) =>
        chi?.value === deptId ? { ...chi, shared_apportion: value } : chi
    );
    const addBackToAllList = singleActual?.shared_service_details?.map((chi) =>
      chi?.value === sudId
        ? { ...chi, selected_departments: addSelectedToObjChild }
        : chi
    );
    setSingleActual((prev) => {
      return { ...prev, shared_service_details: addBackToAllList };
    });
  };

  const handleDeleteChildFromAll = (sudId, deptId) => {
    const getObjChild = singleActual?.shared_service_details?.find(
      (chi) => chi?.value === sudId
    );
    // console.log(getObjChild, "get-obj");
    const addSelectedToObjChild = getObjChild?.selected_departments?.filter(
      (chi) => chi?.value !== deptId
    );
    // console.log(deptId, "dept-id");
    // console.log(addSelectedToObjChild, "add-selected");
    const addBackToAllList = singleActual?.shared_service_details?.map((chi) =>
      chi?.value === sudId
        ? { ...chi, selected_departments: addSelectedToObjChild }
        : chi
    );
    // setAllSelectedSubsidiary(addBackToAllList);
    setSingleActual((prev) => {
      return { ...prev, shared_service_details: addBackToAllList };
    });
  };

  const handleSharedSum = () => {
    const newApportionListForShared = singleActual?.shared_service_details?.map(
      (chi) => {
        return {
          subsidiary_id: chi?.value,
          budget_percentage: chi?.budget_percentage,
          label: chi?.label,
          subsidiary_departments: chi?.selected_departments?.map((child) => {
            return {
              department_id: child?.value,
              apportion: child?.shared_apportion,
            };
          }),
        };
      }
    );

    const newInvalidBudgetLength = [];

    newApportionListForShared?.forEach((chi) => {
      const totalApportion = chi?.subsidiary_departments
        ?.map((chil) => chil?.apportion)
        ?.reduce((a, b) => Number(a) + Number(b), 0);

      if (totalApportion !== 100) {
        newInvalidBudgetLength.push({
          ...chi,
          subsidiary_id: chi.subsidiary_id,
          label: chi?.label,
          department_id: chi.subsidiary_departments.find(
            (chil) => chil.apportion !== 100
          ),
        });
      }
    });

    if (newInvalidBudgetLength.length > 0) {
      setIsApportionAlert(true);
      actionCtx?.setIsModalOut(true);
      setIsSharedBtnLoad(true);
      const errorMessage = newInvalidBudgetLength
        .map((dept) => {
          return `Subsidiary: ${dept.label}.`;
        })
        .join("\n");

      setTimeout(() => {
        toast.error(
          `Budget Allocation Error: The total allocation across all categories must add up to 100% of \n${errorMessage}`,
          {
            theme: "colored",
          }
        );
        setIsSharedBtnLoad(false);
      }, 2000);
    } else {
      setIsApportionAlert(false);
      setIsSharedBtnLoad(true);
      actionCtx?.setIsModalOut(true);
      setTimeout(() => {
        setIsSharedBtnLoad(false);
        // setIsSharedBudget(false);
        setIsShareBudgetModal(false);
        actionCtx?.setIsModalOut(false);
        setIsSetSharedComplete(true);
        // setAllSelectedSubsidiary([]);
        toast.success("Budget Percentage Submitted Successfully", {
          theme: "colored",
        });
      }, 2000);

      // setIsSharedBudget(false);
    }
  };

  const handleDeptDropOption = (option) => {
    const optionVal = option?.map((chi) => {
      return {
        ...chi,
        value: chi?.id,
        label: chi?.name,
      };
    });
    return optionVal;
  };

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Actual Expense`}> */}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container start */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">Update Actual Expense</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          <div
            className="btn-download-box"
            //   onClick={() => setShowModal(true)}
          >
            <button className={`btn-bulk `}>
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div>
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container end */}
        <div
          className="form-box"
          style={{
            width: "100%",
          }}
        >
          <form action="" className="form" onSubmit={handleSubmit}>
            {/* <div className=""> */}
            <div
              className="wrapper"
              style={{
                borderBottom: "unset",
                // paddingBottom: "-40rem",
                height: "unset",
                minHeight: "unset",
                // background: "green"
              }}
            >
              <p className="box-title">1.Expense Information</p>
              <div
                className="form-wrap"
                style={{
                  gridTemplateRows: "1fr",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <div className="employee-actual-expense-left-upload-wrap">
                  {/* select content wrap start */}
                  <div className="select-content">
                    {imageToUpload?.length < 1 && (
                      <>
                        <p className="title">Upload receipt </p>
                        <input
                          type="file"
                          name="upload-recipt-employee"
                          id="upload-recipt-employee"
                          className="upload-receipt"
                          onChange={(e) =>
                            handleAddToImageList(e.target.files[0])
                          }
                        />
                        <label
                          htmlFor="upload-recipt-employee"
                          className="label-upload"
                        >
                          <div className="wrap">
                            <FaPlus className="icon" />
                            <p className="text">Attach file</p>
                          </div>
                        </label>
                        <p className="info">
                          Supported format is jpg, xls, pdf. maximum of 5 files
                          and must not be less than 450kb
                        </p>
                      </>
                    )}
                    {/* less than end */}
                    {imageToUpload?.length > 0 && (
                      <>
                        <input
                          type="file"
                          name="upload-recipt-employee"
                          id="upload-recipt-employee"
                          className="upload-receipt"
                          onChange={(e) =>
                            handleAddToImageList(e.target.files[0])
                          }
                        />
                        <label
                          htmlFor="upload-recipt-employee"
                          className="label-upload-two"
                        >
                          {" "}
                          <div className="plus-box">
                            <FaPlus className="icon" />
                          </div>
                          <p className="text">Attach more files</p>
                        </label>
                      </>
                    )}
                  </div>
                  {/* select content wrap end */}
                  {/* slected content box start */}
                  <div className="selected-content-box">
                    <div
                      style={{
                        gridTemplateColumns: `repeat(${singleActual?.receipts?.length}, max-content)`,
                      }}
                      className="wrap"
                    >
                      {singleActual?.receipts?.map((chi, idx) => {
                        const { original_url, mime_type } = chi;
                        // console.log(original_url);
                        if (original_url && !mime_type?.includes("image/")) {
                          return (
                            <div className="box box-file" key={idx}>
                              {/* <p className="type">File type: <span>{formatApplicationType(chi?.type)}</span></p> */}
                              <p className="name">
                                File name: <span>{chi?.name}</span>
                              </p>
                            </div>
                          );
                        }
                        return (
                          <div
                            style={{
                              backgroundImage: original_url
                                ? `url(${original_url})`
                                : `url(${URL.createObjectURL(chi)})`,
                              width: "25rem",
                              //   backgroundColor: "red"
                            }}
                            key={idx}
                            className="box"
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                  {/* slected content box error */}
                </div>
                {/* form group-container start */}
                <div
                  // style={{ background: "green" }}
                  className="form-group form-group right-box-expense-actual-box-wrap"
                >
                  {/* form-group start */}
                  <div className="form-group">
                    <label htmlFor="expense_date" className="label">
                      Expense Date <span>*</span>
                    </label>
                    <Flatpickr
                      id="start_date"
                      name="start_date"
                      className={`input ${
                        singleActual?.expense_date && "input-active"
                      }`}
                      autoComplete="off"
                      value={moment(singleActual?.expense_date).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(date) => {
                        setSingleActual((prev) => {
                          return {
                            ...prev,
                            expense_date: moment(date[0]).format("YYYY-MM-DD"),
                          };
                        });
                      }}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="chart_of_account" className="label">
                      Chart of Account <span>*</span>
                    </label>
                    <Select
                      styles={reactSelectStyle}
                      className={`select-wrap ${
                        singleActual?.chart_of_account_id &&
                        "select-wrap-active"
                      }`}
                      placeholder="Select chart of account"
                      value={singleActual?.chart_of_account_id}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, chart_of_account_id: e };
                        });
                        setSingleActual((prev) => {
                          return {
                            ...prev,
                            chart_of_account_id: e,
                          };
                        });
                      }}
                      options={chatAccountDrop}
                    />
                  </div>
                  {/* form-group end */}
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="subsidiary" className="label">
                      Subsidiary <span>*</span>
                    </label>
                    <Select
                      className={`select-wrap ${
                        singleActual?.subsidiary && "select-wrap-active"
                      }`}
                      placeholder="Select Subsidiary"
                      value={singleActual?.subsidiary_id}
                      onChange={async (e) => {
                        setSingleActual((prev) => {
                          return { ...prev, subsidiary_id: e };
                        });
                        const obj = {
                          reason: true,
                          id: e?.value,
                        };
                        const data = await dispatch(
                          getDepartmentsBySubsidiary(obj)
                        );
                        if (data?.payload?.success) {
                          setSingleActual((prev) => {
                            return {
                              ...prev,
                              deptOptions: data?.payload?.data?.departments,
                            };
                          });
                        }
                      }}
                      styles={reactSelectStyle}
                      options={subsidiariesDrop}
                      // isLoading={isLoading}
                    />
                  </div>
                  {/* group box end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="department" className="label">
                      Department <span>*</span>
                    </label>
                    <Select
                      styles={reactSelectStyle}
                      className={`Select-wrap ${
                        singleActual?.department_id && "select-wrap-active"
                      }`}
                      placeholder="Select Department"
                      value={singleActual?.department_id}
                      onChange={(e) => {
                        // setDetails((prev) => {
                        //   return { ...prev, department_id: e };
                        // });
                        setSingleActual((prev) => {
                          return {
                            ...prev,
                            department_id: e,
                            is_shared_service:
                              e?.branch?.subsidiary?.shared_service?.length < 1
                                ? false
                                : true,
                            check_shared:
                              e?.branch?.subsidiary?.shared_service?.length < 1
                                ? false
                                : true,
                          };
                        });
                      }}
                      // options={departmentDrop}
                      options={handleDeptDropOption(singleActual?.deptOptions)}
                      isLoading={loadingPage}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="unit_price" className="label">
                      Unit Price <span>*</span>
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      id="unit_price"
                      name="unit_price"
                      placeholder="specify unit price"
                      value={singleActual?.unit_price}
                      // onChange={(e) =>
                      //   setDetails((prev) => {
                      //     return {
                      //       ...prev,
                      //       unit_price: handleNumberFormatInput(e),
                      //     };
                      //   })
                      // }
                      onChange={handleChange}
                      className={`input ${
                        singleActual.unit_price && "input-active"
                      }`}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="units" className="label">
                      Units <span>*</span>
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      id="units"
                      name="units"
                      placeholder="specify unit"
                      value={singleActual?.units}
                      // onChange={(e) =>
                      //   setDetails((prev) => {
                      //     return {
                      //       ...prev,
                      //       units: handleNumberFormatInput(e),
                      //     };
                      //   })
                      // }
                      onChange={handleChange}
                      className={`input ${
                        singleActual.units && "input-active"
                      }`}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="description" className="label">
                      Description <span>*</span>
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      onChange={(e) => {
                        handleChange(e);
                        setSingleActual((prev) => {
                          return {
                            ...prev,
                            description: e.target.value,
                          };
                        });
                      }}
                      style={{ resize: "none" }}
                      cols="30"
                      rows="7"
                      placeholder="Enter your description here..."
                      className={`input ${
                        singleActual?.description && "input-active"
                      }`}
                      value={singleActual?.description}
                    />
                  </div>
                  {/* form-group end */}
                  {/* sharing percent start */}
                  {apportionData?.length > 0 && (
                    <div>
                      <label className="label">
                        Set Sharing Percentage <span>*</span>
                      </label>
                      <ButtonBlue
                        onClick={() => {
                          setShowSharedModal(true);
                          actionCtx?.setIsModalOut(true);
                        }}
                        text={"Set Below"}
                      />
                    </div>
                  )}
                  {/* sharing percent end */}

                  {/* form-group start */}
                  <div>
                    <label htmlFor="total_expense" className="label">
                      Total Expense
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      disabled
                      style={{
                        backgroundColor: "#F1F2F3",
                        marginBottom: "-10rem",
                      }}
                      type="text"
                      onChange={handleChange}
                      id="total_expense"
                      name="total_expense"
                      placeholder="shows total value"
                      value={singleActual?.total_expense}
                      // onChange={handleChange}
                      className={`input`}
                    />
                  </div>
                  {/* form-group end */}

                  {/* sharing percent start */}
                  {/* {apportionData?.length > 0 && (
                        <div>
                          <label className="label">
                            Set Sharing Percentage <span>*</span>
                          </label>
                          <ButtonBlue
                            onClick={() => {
                              actionCtx?.setIsModalOut(true);
                              setShowSharedModal(true);
                            }}
                            text={"Set Below"}
                          />
                        </div>
                      )} */}
                  <div className="emp-expenditure-shared-service-budget-wrap">
                    {/* shared budget wrap start */}
                    {/* {apportionData?.length > 0 && ( */}
                    <div>
                      <div className="wrapper" />
                      <p className="shared-percent-title">
                        Set Sharing Percentage
                      </p>
                      {/* set sharing percentage note wrap start */}
                      <div>
                        <p>
                          Set cost sharing percentage or retain default shared
                          service settings.
                        </p>
                        <p
                          style={{
                            marginTop: ".5rem",
                            marginBottom: "2rem",
                            fontWeight: "400",
                          }}
                        >
                          Note: Percentage for all subsidiaries add up to 100%
                        </p>
                      </div>
                      {/* set sharing percentage note wrap end */}
                      {/* <div className="hide-box"> */}
                      {/* <input
                          checked={isSharedBudget}
                          disabled={apportionData?.length > 0 ? false : true}
                          type="checkbox"
                          name=""
                          id="is_shared_toggle"
                          className="input"
                          onChange={() => {
                            setIsSharedBudget(!isSharedBudget);
                            setAllSelectedSubsidiary([]);
                            handleSubOptions();
                          }}
                        /> */}
                      <ButtonBlue
                        text={"Set Budget Percentage"}
                        onClick={() => {
                          if (
                            singleActual?.check_shared ||
                            singleActual?.is_shared_service
                          ) {
                            actionCtx?.setIsModalOut(true);
                            setIsShareBudgetModal(true);
                          }
                        }}
                        bgColor={
                          singleActual?.check_shared ||
                          singleActual?.is_shared_service
                            ? "var(--blue-color)"
                            : "#ccc"
                        }
                      />
                      {/* <label className="label" htmlFor="is_shared_toggle"> */}
                      {/* <span></span> */}
                      {/* <span></span> */}
                      {/* </label> */}
                      {/* </div> */}
                    </div>
                    {/* )} */}
                    {/* shared budget wrap end */}
                    {/* shared budget form wrap start */}

                    {/* {isSharedBudget && ( */}
                    {/* shared budget modal start */}
                    <Modal visible={isShareBudgetModal} effect="fadeInLeft">
                      <SharedBudgetModal
                        allSubisidiarySelected={
                          singleActual?.shared_service_details
                        }
                        setAllSelectedSubsidiary={(e) => {
                          setSingleActual((prev) => {
                            return { ...prev, shared_service_details: e };
                          });
                        }}
                        handleNewBudgetPercentage={handleNewBudgetPercentage}
                        handleMultiSelectChange={handleMultiSelectChange}
                        subOption={singleActual?.subBudgetOption || ""}
                        loadingPage={loadingPage}
                        handleFormatDepartmentDrop={handleFormatDepartmentDrop}
                        handleMultiSelectChangeTwo={handleMultiSelectChangeTwo}
                        departmentDrop={departmentDrop}
                        handleApportionDeptChange={handleApportionDeptChange}
                        handleDeleteChildFromAll={handleDeleteChildFromAll}
                        sharedBtnLoad={isSharedBtnLoad}
                        onSetPercent={() => {
                          actionCtx?.setIsModalOut(false);
                          handleSharedSum();
                        }}
                        onDiscard={() => {
                          actionCtx?.setIsModalOut(true);
                          setIsShareBudgetModal(false);
                          setIsSharedBudgetMessage(true);
                        }}
                        onClose={() => {
                          actionCtx?.setIsModalOut(false);
                          // setIsSharedBudget(false);
                          setIsShareBudgetModal(false);
                        }}
                      />
                    </Modal>
                    {/* shared budget modal end */}

                    <Modal visible={isSharedBudgetMessage} effect="fadeInLeft">
                      <SharedBudgetModalMessage
                        onCancel={() => {
                          setIsSharedBudgetMessage(false);
                          // setIsSharedBudget(true);
                          setIsShareBudgetModal(true);
                        }}
                        discardLoader={isSharedBtnLoad}
                        onDiscard={() => {
                          setIsSharedBtnLoad(true);
                          if (isSharedBudgetMessage) {
                            setTimeout(() => {
                              // setAllSelectedSubsidiary([]);
                              setSingleActual((prev) => {
                                return { ...prev, shared_service_details: [] };
                              });
                              actionCtx?.setIsModalOut(false);
                              setIsSharedBudgetMessage(false);
                              setIsSharedBudget(false);
                              setIsSharedBtnLoad(false);
                              toast.success(
                                "Budget Percentage Successfully Discarded",
                                {
                                  theme: "colored",
                                }
                              );
                            }, 2000);
                          }
                        }}
                      />
                    </Modal>
                    {/* )} */}

                    {/* shared budget form wrap end */}
                  </div>
                  {/* sharing percent end */}
                </div>

                <div className="btn-box-wrap">
                  {!loading ? (
                    <input
                      onClick={(e) => {
                        handleSubmit(e, 0);
                      }}
                      type="submit"
                      value={"Update"}
                      className={`form-submit form-submit-active`}
                    />
                  ) : (
                    <div style={{ alignSelf: "center", marginLeft: "3rem" }}>
                      <Dna
                        visible={true}
                        height="80"
                        width="100"
                        ariaLabel="dna-loading"
                        // wrapperStyle={{color: "red", backgroundColor : "red"}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  )}
                  {/* btn-box-end */}
                </div>
                {/* form group-container end */}
              </div>
            </div>
            {/* </div> */}
            {/* modal start */}
            {/* <Modal effect="fadeInLeft" visible={showSharedModal}>
              <EmpSharedServiceModal
                onCancel={() => {
                  setShowSharedModal(false);
                  actionCtx?.setIsModalOut(false);
                }}
                list={isApportion}
                listData={apportionData}
                deptVal={details?.department_id?.label}
                onSubmit={handleApportionSubmit}
                formatApportionToSubmit={formatApportionToSubmit}
              />
            </Modal> */}
            {/* modal end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default EmployeeActualNewExpenseUpdate;
