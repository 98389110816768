import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import "../../../../../styles/dashboard/projection/expense/removebtn.scss";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { NumericFormat } from "react-number-format";

const EmployeeSupplementaryRemoveGroup = ({
  list,
  loading,
  onSelectvalueChange,
  Quantity,
  onInputValueChange,
  handleAddMonthList,
  onInputTotalChange,
  total_supplementary,
  Total_quantity_label,
  handleRemoveMonthList,
  total_quantity,
  quantity,
  // name
}) => {
  const [details] = useState({
    month: "",
    value: "",
    quantity: "",
  });

  const monthOption = [
    {
      label: "January",
      name: "January",
      value: "January",
    },
    {
      label: "February",
      name: "February",
      value: "February",
    },
    {
      label: "March",
      name: "March",
      value: "March",
    },
    {
      label: "April",
      name: "April",
      value: "April",
    },
    {
      label: "May",
      name: "May",
      value: "May",
    },
    {
      label: "June",
      name: "June",
      value: "June",
    },
    {
      label: "July",
      name: "July",
      value: "July",
    },
    {
      label: "August",
      name: "August",
      value: "August",
    },
    {
      label: "September",
      name: "September",
      value: "September",
    },
    {
      label: "October",
      name: "October",
      value: "October",
    },
    {
      label: "November",
      name: "November",
      value: "November",
    },
    {
      label: "December",
      name: "December",
      value: "December",
    },
  ];
  return (
    <div>
      {list?.map((item, idx) => {
        const { month_name: name, value } = item;
        // console.log("name", item.name);
        return (
          <div
            key={idx}
            className="form-wrap"
            style={{
              marginBottom: "-10rem",
              marginTop: "-2rem",
              gap: "5rem",
              width: "110%",
              // display:"grid",
              gridTemplateColumns: "1fr 1fr .1fr",
              // display: "grid",
            }}
          >
            <div className="form-group">
              <label htmlFor="" className="label">
                Select Month <span>*</span>
              </label>
              <Select
                className={`select-wrap ${
                  (item?.name?.label && "select-wrap-active") ||
                  (name && "select-wrap-active")
                }`}
                placeholder="Select Month"
                // defaultValue="Select Month"
                value={name}
                isLoading={loading}
                onChange={(e) => {
                  onSelectvalueChange(e, item);
                  // console.log(e);
                  // console.log(item);
                }}
                styles={reactSelectStyle}
                options={monthOption}
                // options={productDrop}
              />
            </div>
            {/* form=group start */}
            <div className="form-group">
              <label htmlFor="quantity" className="label">
                {Quantity || "Quantity"} <span>*</span>
              </label>
              <NumericFormat
                decimalScale={3}
                decimalSeparator="."
                allowNegative
                thousandSeparator={","}
                type="text"
                // disabled
                // type="number"
                id=""
                name="value"
                // style={{ width: "230%" }}
                placeholder="value populated"
                value={value}
                onChange={(e) => {
                  onInputValueChange(e, item);
                }}
                className={`input ${value && "input-active"}`}
              />
            </div>
            {/* form=group end */}
            <div
              className="employee-remove-btn"
              style={{ display: "flex", alignItems: "center" }}
            >
              <FaMinus
                className="icon"
                onClick={() => handleRemoveMonthList(item)}
              />
            </div>

            <div className="emp-cancel-wrap emp-minus-wrap"></div>
          </div>
        );
      })}
      {/* form-wrap end */}
      <div
        className="emp-add-months-wrap"
        onClick={handleAddMonthList}
        // style={{}}
        // style={{ marginTop: "-1rem" }}
      >
        <FaPlus className="icon" />
        <p className="text">Add more months</p>
      </div>

      {/* form-wrap start */}
      <div
        className="form-wrap"
        style={{
          marginTop: "3rem",
          marginBottom: "-13rem",
          // display: "flex",
          gap: "5rem",
        }}
      >
        {/* form-group start */}
        <div className="form-group">
          <label htmlFor="total quantity" className="label">
            {Total_quantity_label || "Total Quantity"}
          </label>
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            type="text"
            disabled
            placeholder="quantity populated"
            id="total_quantity"
            name="total_qunatity"
            style={{
              background: "#ECEDED",
              // width: "120%",
            }}
            className={`input`}
            value={total_quantity}
            onChange={onInputTotalChange}
          />
        </div>
        {/* form-group end */}
        {/* form-group start */}
        {/* form-group end */}
        <div className="form-group">
          <label htmlFor="total_revenue_projection" className="label">
            Total Supplementary Budget
          </label>
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            type="text"
            disabled
            placeholder="quantity populated"
            id="total_supplementary"
            name="total_supplementary"
            value={details?.total_supplementary || total_supplementary}
            style={{
              background: "#ECEDED",
              // width: "120%",
            }}
            className={`input`}
          />
        </div>
      </div>
      {/* form-wrap end */}
    </div>
  );
};

export default EmployeeSupplementaryRemoveGroup;
