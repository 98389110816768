import React, { useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import "../../../../../styles/dashboard/modals/overviewmodal.scss";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
// import { FaPlus } from "react-icons/fa";
// import EmployeeDraftProjection from "../../projection/expense/EmpDraftProjection";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
// import EmployeeCommonRevenueLayout from "./layout/CommonLayout";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import Modal from "react-awesome-modal";
// import EmployeeDeleteRevenueModal from "../modals/deleteRevenue";
// import EmployeeOverviewModal from "../modals/overviewModal";
// import EmployeeActualOverviewModal from "../modals/overviewModal";
import EmployeeCommonExpenseLayout from "./CommonLayout";
import EmployeeOverviewActualReinbursementModal from "../../home/modals/Reimbursement/EmployeeOverviewModal";
import EmployeeActualExpenseDeleteModal from "../modals/deleteModal";

const EmployeeActualDraftExpenseTable = ({ children }) => {
  const history = useHistory();

  const [indexNo, setIndexNo] = useState("");

  const [showModal, setShowModal] = useState({
    delete: false,
    approveOverview: false,
    big_img: false,
  });

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Units",
    "Unit Price",
    "Date",
  ];

  const employeeTableBodyList = [
    {
      chart_of_account: "Food and Supply",
      classes: "Liability",
      description: "Employee food",
      units: "1.00",
      unit_price: "20,000.00",
      date: "01 Jun 2022",
    },
  ];

  const cardHeader = [
    "Projected Expense",
    "Actual Expense",
    "Book Balance",
    "Available Balance",
  ];
  const cardValue = ["00.00", "00.00", "00.00", "00.00"];

  const link = ["/employee-dashboard-actual-expense-new"];

  // const onAction = () => {
  //   setShowModal((prev) => {
  //     return { ...prev, overview: false };
  //   });
  // };

  return (
    <>
      <EmployeeCommonExpenseLayout draftExpense={true}>
        <EmployeeReusableTable
          firstCard={true}
          secondCard={true}
          thirdCard={true}
          fourthCard={true}
          cardTitleOne={cardHeader[0]}
          cardTitleTwo={cardHeader[1]}
          cardTitleThree={cardHeader[2]}
          cardTitleFour={cardHeader[3]}
          cardBodyOne={cardValue[0]}
          cardBodyTwo={cardValue[1]}
          cardBodyThree={cardValue[2]}
          cardBodyFour={cardValue[3]}
          sort
          filter
          addButton={true}
          BtnTitle={`New Expense`}
          showSearch
          onPage={() => history.push(link[0])}

          // Title={"new expense"}
        />

        {/* table start  */}
        {children}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowPagination={false}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            action
            onNextPage
          >
            {employeeTableBodyList.map((chi, idx) => {
              const {
                chart_of_account,
                classes,
                description,
                units,
                unit_price,
                date,
              } = chi;
              return (
                <EmpTableBodyRow
                  key={idx}
                  one={chart_of_account}
                  two={classes}
                  three={description}
                  four={units}
                  five={unit_price}
                  six={date}
                  //   progressType={status}
                  // action={onAction}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  updateText={`modify`}
                  deleteText={`Delete`}
                  onDelete={() =>
                    setShowModal((prev) => {
                      return { ...prev, delete: true };
                    })
                  }
                  onUpdate={() => history.push(link[0])}
                  onOverview={() =>
                    setShowModal((prev) => {
                      return { ...prev, approveOverview: true };
                    })
                  }
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
          {/* button start */}
          <div style={{ display: "flex", justifyContent: "right" }}>
            <ButtonBlue text={`Submit for Approval`} />
          </div>
          {/* button end */}
        </div>
      </EmployeeCommonExpenseLayout>
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeOverviewActualReinbursementModal
          submitBtn={`Submit`}
          handleSubmit={() =>
            history.push("/employee-dashboard-actual-expense-submitted")
          }
          chart_of_account={`Food and supply`}
          classes={`Liability`}
          unitsTwo
          unit_price
          date_text={`Expense Date`}
          bigImg
          viewComments={`Modify`}
          onComment={() => history.push(link[0])}
          deleteText
          onDelete={() =>
            setShowModal((prev) => {
              return { ...prev, delete: true, approveOverview: false };
            })
          }
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal effect="fadeInLeft" visible={showModal.delete}>
        <EmployeeActualExpenseDeleteModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, delete: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeActualDraftExpenseTable;
