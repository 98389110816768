import React, { useState } from "react";
// import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
// import nameIcon from "../../../assets/name-input-icon.svg";
import "../../../../styles/auth/AuthLayout.css";
import "../../../../styles/auth/AuthForm.css";
import workMailIcon from "../../../../assets/work-mail-icon.svg";
import lockIcon from "../../../../assets/password-icon.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import AuthLayout from "../../../../component/layout/auth";
import Modal from 'react-awesome-modal'
import EmployeeSuccessModal from "./SuccessMessage";
import ButtonBlue from "../../../../component/buttons/ButtonBlue";

// import Aos from "aos";
// import "aos/dist/aos.css";

const EmployeeLogin = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [showPassword, setShwoPassord] = useState(false);
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  return (
    <>
      <AuthLayout
        getHelp
        title="Sign in to your account"
        small_text={`Access with your registered credentials`}
      >
        {/* get started */}
        {/* auth form start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // history.push("/setup-org");
          }}
          action=""
          className="auth-form-wrap"
          style={{ marginTop: "4rem" }}
        >
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="work_email" className="form-label">
              <figure className="img-box">
                <img src={workMailIcon} className="img" alt="" />
              </figure>
            </label>
            <input
              type="work_email"
              name="email"
              id="work_email"
              className="form-input white-black-color"
              placeholder="Email"
              onChange={handleChange}
              value={details.email}
            />
            <div className={`status ${details.email && "status-active"}`}></div>
            <p
              className={`placeholder white-black-bg white-black-color ${
                details.email && "placeholder-active"
              }`}
            >
              Email
            </p>
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Password" className="form-label">
              <figure className="img-box">
                <img src={lockIcon} className="img" alt="" />
              </figure>
            </label>
            <input
              type={showPassword ? "text" : "Password"}
              name="password"
              id="Password"
              className="form-input white-black-color"
              placeholder="password"
              onChange={handleChange}
              value={details.password}
            />
            <div
              className={`status ${details.password && "status-active"}`}
            ></div>
            <p
              className={`placeholder  white-black-bg white-black-color ${
                details.password && "placeholder-active"
              }`}
            >
              Password
            </p>
            <div
              onClick={() => setShwoPassord(!showPassword)}
              className="show-hide"
            >
              {showPassword ? (
                <FaEyeSlash className="icon" />
              ) : (
                <FaEye className="icon" />
              )}
            </div>
          </div>
          {/* form group end */}
          {/* already text start */}
          <div className="already-box already-box-login">
            <p
              onClick={() => history.push("/employee-forget-password")}
              className="text text-login"
            >
              <span>Forgot Password</span>
            </p>
          </div>
          {/* already text end */}
          {/* submit form start */}
          <input
            type="submit"
            value="Continue"
            className={`form-group btn-reuse form-submit ${
              details.email && details.password && "form-submit-active"
            }`}
            // style={{display:"flex", justifyContent:"center"}}
            onClick={()=>setShowModal(true)}
          />
          {/* submit form  end */}
        </form>
        {/* auth form end */}
      </AuthLayout>
      <Modal visible={showModal} effect={`fadeInUp`}>
        <EmployeeSuccessModal>
          <div
            style={{
              display: "flex",
              width: "50rem",
              justifyContent: "center",
            }}
          >
            <ButtonBlue
              onClick={() => history.push("/employee-dashboard-home")}
              text={`Continue to Dashboard`}
            />
          </div>
        </EmployeeSuccessModal>
      </Modal>
    </>
  );
};

export default EmployeeLogin;
