// import EmployeeSupportDropDown from "../../../../component/pages/dashboard/dropdown/supportdropdown";
import EmployeeSupport from "../../../../component/pages/dashboard/external/support";

export const Employee_dashboard_support_route_group = [
  {
    path: "/employee-dashboard-administration-support",
    component: EmployeeSupport,
    pageTitle: "Supports",
    goBack: true,
  },
  // {
  //   path: "/employee-dashboard-administration-support-report-issue",
  //   component: EmployeeSupportDropDown,
  // },
];
