import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
// import "aos/dist/aos.css";
// import Aos from "aos";

const EmployeeSupplementarySpreadForm = ({
  unit_price,
  onInputChange,
  units,
  total_value,
}) => {
  const [details] = useState({
    unit_price: "",
    units: "",
    total_value: "",
  });

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   const data = { ...details, [name]: value };
  //   setDetails(data);
  // };

  return (
    <div
      className="form-wrap"
      style={{
        marginTop: "3rem",
        gridTemplateColumns: "3fr 2fr 2fr",
        marginBottom: "-10rem",
      }}
    >
      <div className="form-group">
        {/* unit-price input start */}
        <label htmlFor="unit price" className="label">
          Unit Price <span>*</span>
        </label>
        <NumericFormat
          // type="number"
          decimalScale={3}
          decimalSeparator="."
          allowNegative
          thousandSeparator={","}
          type="text"
          placeholder="Specify unit price"
          name="unit_price"
          className={`input ${unit_price && "input-active"}`}
          id="unit_price"
          value={details?.unit_price || unit_price}
          onChange={onInputChange}
        />
      </div>
      {/* unit-price input start */}
      <div className="form-group">
        <label htmlFor="units" className="label">
          Units <span>*</span>
        </label>
        <NumericFormat
          // type="number"
          decimalScale={3}
          decimalSeparator="."
          allowNegative
          thousandSeparator={","}
          type="text"
          placeholder="Specify quantity"
          name="units"
          className={`input ${units && "input-active"}`}
          id="unit_price"
          value={details?.units || units}
          onChange={onInputChange}
        />
      </div>
      {/* unit-price input start */}
      {/* unit input start */}
      <div className="form-group">
        <label htmlFor="total_value" className="label">
          Total Value
        </label>
        <NumericFormat
          decimalScale={3}
          decimalSeparator="."
          allowNegative
          thousandSeparator={","}
          type="text"
          disabled
          // type="text"
          name="total_supplementary"
          id="total_value"
          style={{ backgroundColor: "#ECEDED" }}
          placeholder="total value"
          value={details?.total_value || total_value}
          className={`input`}
        />
      </div>
      {/* input group end */}
    </div>
  );
};
export default EmployeeSupplementarySpreadForm;
