import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectMain.css";
import Select from "react-select";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
// import TableBodyRow from "../../../../tables/TableBodyRow";
import Modal from "react-awesome-modal";
import OverviewCardExpenseModal from "./modals/OverviewCardExpenseModal";
import FinalApprovalModal from "./modals/FinalApprovalModal";
import ApprovalModal from "./modals/ApprovalModal";
import RejectModal from "./modals/RejectModal";
import ComentModal from "./modals/CommentModal";
import {
  addAllToList,
  checkCurrencyFormat,
  checkCurrentApproval,
  formatNumberWithComma,
  perPageOptions,
  reactSelectStyle,
  // FormatNumWithCurrency,
  reactSelectStyleCommonSort,
  reactSelectStyleHeaderNav,
  reactSelectStyleTable,
} from "../../../../../helper/Helpers";
import { useEffect } from "react";
import { useContext } from "react";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import {
  getExpenditureProjectionChartByDepartment,
  getExpenditureProjectionChartByDepartmentPendingApproval,
  // getExpenditureProjectionChartByProjection,
  getExpenditureProjectionComments,
} from "../../../../../redux/projections/ExpenditureProjection";
import EmpTableBodyRow from "../../../../../employee-folder/component/layout/dashboard/EmpTableBodyRow";
import { getDepartments } from "../../../../../redux/department/deparment";
import { Dna } from "react-loader-spinner";
import { getProjectionDownload } from "../../../../../redux/projections/RevenueProjection";

const AwaitPendingApprovalExpense = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterVal, setFilterVal] = useState("");
  const [allApproveId, setAllAppproveId] = useState([]);
  const { departmentDrop } = useSelector((state) => state.department);
  const [filterValTwo, setFilterValTwo] = useState("");
  const {
    loading,
    expenditure_projection_single,
    loadingPage,
    awaitingListApprove,
  } = useSelector((state) => state.expenditure_projection_chart);
  const [singleChild, setSingleChild] = useState({});
  const actionCtx = useContext(ActionContext);
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    overview: false,
    approve: false,
    approve_all: false,
    reject: false,
    comment: false,
  });

  const categoryList = [
    { label: "Current Fiscal Year", value: 1 },
    { label: "Previous Year", value: 2 },
    { label: "Today", value: 3 },
    { label: "Yesterday", value: 4 },
    { label: "Last 7 days", value: 5 },
    { label: "Monthly", value: 6 },
  ];

  const tableFilterList = [
    { label: "All Projection", value: "" },
    { label: "Approved", value: "approved" },
    { label: "Pending", value: "pending" },
    { label: "Pending ", value: "pending-current" },
    { label: "Rejected", value: "rejected" },
  ];

  const [details, setDetails] = useState({
    name: "",
    category: "",
    class: "",
    code: "",
    description: "",
  });

  const tableHeadList = [
    "Chart of Account",
    "Expense Class",
    "Description",
    "Quantity",
    `Units Price ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    `Total Value ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
  ];

  const [refreshTable, setRefreshTable] = useState(false);

  useEffect(() => {
    getPendingMyApprovalList();
    // eslint-disable-next-line
  }, [refreshTable, actionCtx?.currencyValue, actionCtx?.perPage]);

  useEffect(() => {
    dispatch(getDepartments(actionCtx?.activeOrg?.id));
    // eslint-disable-next-line
  }, [refreshTable]);

  const handleFisnish = () => {
    actionCtx?.setIsModalOut(false);
    setShowModal({
      overview: false,
      approve: false,
      approve_all: false,
      reject: false,
      comment: false,
    });
    setRefreshTable(!refreshTable);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const getMainExpenseProjection = async (dept_id) => {
    const obj = {
      dept_id:
        dept_id ||
        filterValTwo?.value ||
        localStorage.getItem("expense-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
      page: currentPage,
    };
    const data = await dispatch(getExpenditureProjectionChartByDepartment(obj));
    // console.log(data);
    if (data?.payload?.success) {
      if (data?.payload?.data?.expenditure?.data?.length > 0) {
        const info = data?.payload?.data?.expenditure?.data[0]?.department;
        setFilterValTwo({ label: info?.name, value: info?.id });
      }
    }
  };
  const [editList, setEditList] = useState({});
  useEffect(() => {
    setEditList(awaitingListApprove);
    // console.log(awaitingListApprove);
  }, [awaitingListApprove]);

  const handleEditListCheck = (id) => {
    const newList = editList?.data?.map((chi) =>
      chi?.id === id
        ? { ...chi, current_approver: !chi?.current_approver }
        : chi
    );
    const newObj = { ...editList, data: newList };
    setEditList(newObj);
  };

  const handleApproveAll = () => {
    const list = editList?.data?.filter(
      (chi) => chi?.status === "pending" && chi?.current_approver
    );
    const newList = list.map((chi) => chi?.id);
    console.log(newList);
    setAllAppproveId(newList);
  };

  const getPendingMyApprovalList = async (dept_id) => {
    const obj = {
      dept_id:
        dept_id ||
        filterValTwo?.value ||
        localStorage.getItem("expense-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
      page: currentPage,
    };
    const data = await dispatch(
      getExpenditureProjectionChartByDepartmentPendingApproval(obj)
    );
    if (data?.payload?.status === "success") {
      //   console.log(data);
    }
  };

  const handleGetMainExpenseProjectionByStatus = async (param) => {
    const obj = {
      dept_id:
        filterValTwo?.value || localStorage.getItem("expense-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      cur_id: actionCtx?.currencyValue?.id || "" || "",
      status: param,
      per_page: actionCtx?.perPage?.value,
    };
    // console.log(expenditure_projection_single);
    // console.log(obj);
    // return;
    // dispatch(getExpenditureProjectionChartByDepartment(obj));
  };

  const getComments = async (id) => {
    dispatch(getExpenditureProjectionComments(id));
  };

  const handlePagination = (param) => {
    const num = param.split("=")[1];
    setCurrentPage(num);
    const obj = {
      dept_id:
        filterValTwo?.value || localStorage.getItem("expense-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      status: filterVal,
      page: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getExpenditureProjectionChartByDepartmentPendingApproval(obj));
  };

  const handlePaginationNum = (num) => {
    // const num = param.split("=")[1];
    setCurrentPage(num);
    const obj = {
      dept_id:
        filterValTwo?.value || localStorage.getItem("expense-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      status: filterVal,
      page: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getExpenditureProjectionChartByDepartmentPendingApproval(obj));
  };

  const exportType = [
    { label: "CSV", value: "excel" },
    { label: "PDF", value: "pdf" },
  ];
  const [formatSelect, setFormatSelect] = useState({
    label: "CSV",
    value: "excel",
  });

  const [loadingDownload, setLoadingDownload] = useState(false);
  const handleCsvDownload = async () => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      dept_id:
        filterValTwo?.value || localStorage.getItem("expense-pro-chart-id"),
      type: "expense_projection",
      format: formatSelect?.value,
      status: filterVal.value,
    };
    setLoadingDownload(true);
    const data = await dispatch(getProjectionDownload(obj));
    //  console.log(data);
    if (data?.payload?.status === 200) {
      downloadCsv(data.payload.data.url);
      setLoadingDownload(false);
    } else {
      setLoadingDownload(false);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <>
      {/* <DashboardLayout goBack pageTitle={`Expense Projection`}> */}
      {/* main wrap start */}
      <div className="expense-projection-main-wrap">
        {/* title arrow select box start */}
        <div className="title-arrow-select-box">
          <div className="subtitle-box">
            <p
              //   onClick={() => {
              //     history.goBack();
              //   }}
              onClick={() => {
                console.log(awaitingListApprove);
              }}
              className="text"
            >
              All Expense Projection
            </p>
            <FaAngleRight className="icon" />
            <p className="active text">
              {expenditure_projection_single?.department || ""}
            </p>
          </div>
          {/* end of subtitle box */}
          {/* select fiscal year wrap start */}
          <div className="select-wrap">
            <label className="label" htmlFor="">
              Fiscal Year
            </label>
            <Select
              // isSearchable={true}
              // isMulti
              styles={reactSelectStyleCommonSort}
              placeholder="Select Year"
              className="select"
              value={details.class.value}
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, class: e.value };
                });
              }}
              options={categoryList}
            />
          </div>
          {/* select fiscal year wrap end */}
          {/* select fiscal year wrap start */}
          <div style={{ marginLeft: "3rem" }} className="select-wrap">
            <label className="label" htmlFor="">
              Export type
            </label>
            <Select
              // isSearchable={true}
              // isMulti
              placeholder="Select Option"
              className="select"
              value={formatSelect}
              onChange={(e) => {
                setFormatSelect(e);
              }}
              options={exportType}
              styles={reactSelectStyle}
            />
          </div>
          {/* select fiscal year wrap end */}
        </div>
        {/* title arrow select box end */}
        {/* card detail wrap start */}

        {/* card detail wrap end */}
        {/* fitler and button box start */}
        <div
          //   style={{ marginBottom: "2rem" }}
          className="filter-approve-btn-box"
        >
          {/* perpage box start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              position: "relative",
              zIndex: "20",
              marginRight: "2rem",
              // marginBottom: "-1rem",
            }}
            className="perpage-wrap"
          >
            <span style={{ fontWeight: "600" }}> All Expense Projection</span>{" "}
            <Select
              styles={reactSelectStyleTable}
              onChange={(e) => {
                actionCtx?.setPerpage(e);
              }}
              value={actionCtx?.perPage}
              options={perPageOptions}
            />
          </div>
          {/* perpage box end */}
          {/* select fiscal year wrap start */}
          <div
            style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}
            className="select-wrap"
          >
            <Select
              // isSearchable={true}
              // isMulti
              instanceId={`filter-table`}
              styles={reactSelectStyleHeaderNav}
              placeholder=" All Projection"
              className="select"
              value={filterVal}
              onChange={(e) => {
                setFilterVal(e);
                handleGetMainExpenseProjectionByStatus(e.value);
              }}
              options={tableFilterList}
            />
            <Select
              // isSearchable={true}
              // isMulti
              instanceId={`filter-table`}
              styles={reactSelectStyleHeaderNav}
              placeholder="Sort by department"
              className="select"
              value={filterValTwo}
              onChange={(e) => {
                setFilterValTwo(e);
                getMainExpenseProjection(e.value);
              }}
              options={addAllToList(departmentDrop, "Departments")}
            />
          </div>
          {/* select fiscal year wrap end */}

          {/* btn approve start */}
          {loadingDownload ? (
            <div style={{ alignSelf: "center", marginTop: "0rem" }}>
              <Dna
                visible={true}
                height="60"
                width="120"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <>
              {expenditure_projection_single?.expenditure?.data?.length > 0 && (
                <ButtonBlue
                  onClick={handleCsvDownload}
                  style={{ marginRight: "1.5rem" }}
                  bgColor={`#cccccc`}
                  text={`Download ${formatSelect?.label}`}
                />
              )}
            </>
          )}
          <ButtonBlue
            onClick={() => {
              setShowModal((prev) => {
                return { ...prev, approve_all: true };
              });
              handleApproveAll();
            }}
            text={`Approve all`}
          />
          {/* btn approve end */}
        </div>
        {/* fitler and button box end */}
        {/* table box start */}
        <ActualReuseableTable
          useNumPagination
          currentPage={awaitingListApprove?.current_page}
          totalTableLength={awaitingListApprove?.total}
          onNumPage={(e) => {
            handlePaginationNum(e);
          }}
          dontShowBnSearch
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          six={tableHeadList[5]}
          // action
          seven="Status"
          eight
          prevPage={awaitingListApprove?.prev_page_url}
          nextPage={awaitingListApprove?.next_page_url}
          onNextPage={() =>
            handlePagination(awaitingListApprove?.next_page_url)
          }
          onPrevPage={() =>
            handlePagination(awaitingListApprove?.prev_page_url)
          }
          toPage={awaitingListApprove?.to}
          fromPage={awaitingListApprove?.from}
          totalPage={awaitingListApprove?.total}
        >
          {editList?.data?.map((chi, idx) => {
            const {
              chart_of_account,
              description,
              units,
              total_value,
              unit_price,
              status,
              id,
              current_approver,
            } = chi;
            return (
              <EmpTableBodyRow
                checkBoxType
                onCheck={(e) => {
                  handleEditListCheck(id);
                }}
                checkValue={current_approver}
                checkId={`${id}-${idx}`}
                key={idx}
                one={chart_of_account?.name || <>&nbsp:</>}
                two={chart_of_account?.class || <>&nbsp:</>}
                three={
                  description?.length > 25
                    ? `${description.slice(0, 25)}....`
                    : description || <>&nbsp:</>
                }
                loading={loading || loadingPage}
                four={formatNumberWithComma(String(units)) || <>&nbsp:</>}
                five={
                  formatNumberWithComma(String(unit_price || "0")) || (
                    <>&nbsp:</>
                  )
                }
                // five={
                //   `${checkCurrencyFormat(actionCtx?.currencyValue?.label)} ${ formatNumberWithComma(String(unit_price || "0")) || (
                //      <>&nbsp:</>
                //    )}`
                //  }
                six={
                  formatNumberWithComma(String(total_value || "0")) || (
                    <>&nbsp:</>
                  )
                }
                // action
                // eight
                progressType={
                  status === "approved"
                    ? "accept"
                    : status === "rejected"
                    ? "reject"
                    : "Pending"
                }
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                //   updateText={` Update Branch`}
                onAcceptStatus={() => {
                  actionCtx?.setIsModalOut(true);
                  getComments(chi?.id);
                  setSingleChild(chi);
                  // const obj = {
                  //   id: chi?.id,
                  //   cur_id: actionCtx?.currencyValue?.id || "" || "",
                  // };
                  // console.log(obj);
                  // dispatch(getExpenditureProjectionChartByProjection(obj));
                  setShowModal((prev) => {
                    return { ...prev, overview: true };
                  });
                }}
                onRejectStatus={() => {
                  actionCtx?.setIsModalOut(true);
                  getComments(chi?.id);
                  setSingleChild(chi);
                  // const obj = {
                  //   id: chi?.id,
                  //   cur_id: actionCtx?.currencyValue?.id || "" || "",
                  // };
                  // dispatch(getExpenditureProjectionChartByProjection(obj));
                  setShowModal((prev) => {
                    return { ...prev, overview: true };
                  });
                }}
                onOverview={() => {
                  actionCtx?.setIsModalOut(true);
                  getComments(chi?.id);
                  setSingleChild(chi);
                  // const obj = {
                  //   id: chi?.id,
                  //   cur_id: actionCtx?.currencyValue?.id || "" || "",
                  // };
                  // console.log(obj);

                  // dispatch(getExpenditureProjectionChartByProjection(obj));
                  setShowModal((prev) => {
                    return { ...prev, overview: true };
                  });
                }}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
      </div>
      {/* main wrap end */}
      {/* </DashboardLayout> */}
      {/* approve modal start */}
      <Modal effect="fadeInDown" visible={showModal.overview}>
        <OverviewCardExpenseModal
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, overview: false };
            });
          }}
          onApprove={() =>
            setShowModal((prev) => {
              return { ...prev, approve: true, overview: false };
            })
          }
          onReject={() =>
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            })
          }
        />
      </Modal>
      {/* approve modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInRight" visible={showModal.approve_all}>
        <FinalApprovalModal
          //   detail={editList?.data[0]}
          allId={allApproveId}
          onFinish={handleFisnish}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approve_all: false };
            })
          }
        />
      </Modal>
      {/* approve modal end */}

      {/* approve modal start */}
      <Modal effect="fadeInUp" visible={showModal.approve}>
        <ApprovalModal
          onFinish={handleFisnish}
          detail={singleChild}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approve: false, overview: true };
            })
          }
        />
      </Modal>
      {/* approve modal end */}

      {/* approve modal start */}
      <Modal effect="fadeInLeft" visible={showModal.reject}>
        <RejectModal
          onFinish={handleFisnish}
          detail={singleChild}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false, overview: true };
            })
          }
        />
      </Modal>
      {/* approve modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInLeft" visible={showModal.comment}>
        <ComentModal
          detail={singleChild}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, comment: false, overview: true };
            })
          }
        />
      </Modal>
      {/* approve modal end */}
    </>
  );
};

export default AwaitPendingApprovalExpense;
