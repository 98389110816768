import React, { useContext, useEffect, useState } from "react";
import BudgetMain from "..";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import CommonExpenditureLink from "../expenditure-rev/commonLink";
import AddBudgetRevProducts from "../modals/AddBudgetRevProduct";
import Modal from "react-awesome-modal";
import UpdateBudgetRevProducts from "../modals/UpdateBudgetRevProductModal";
import DeleteBudgetRevProduct from "../modals/DeleteBudgetRevProduct";
import useDebounce from "../../../../../../helper/UseDebounce";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import { getVerticals } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import {
  getProductByFilter,
  getProductPagination,
  getProductWithPagination,
} from "../../../../../../redux/budget-setting/revenue-projection/product";
import { getPortfolios } from "../../../../../../redux/budget-setting/revenue-projection/portfolio";

const tableHeadList = ["Name", "Description", "Subsidiary", "GP %", "Action"];

const RevenueProjectionProduct = () => {
  const [indexNo, setIndexNo] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const { products, loadingPage, meta, links } = useSelector(
    (state) => state.product
  );
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      dispatch(getVerticals(actionCtx?.activeOrg?.id));
      dispatch(getProductWithPagination(obj));
      dispatch(getPortfolios(obj));
    }
    // eslint-disable-next-line
  }, [actionCtx?.activeOrg, refreshTable]);

  // useEffect(() => {}, [refreshTable]);

  const handleUpdate = async (param) => {
    actionCtx?.setIsModalOut(true);
    setShowModal((prev) => {
      return { ...prev, edit: true };
    });
    const obj = {
      ...param,
      subsidiary_id: {
        label: param?.subsidiary?.name,
        value: param?.subsidiary?.id,
      },
      vertical_id: { label: param?.vertical?.name, value: param?.vertical?.id },
    };
    setSingleChild(obj);
  };

  const handleFinish = () => {
    actionCtx?.setIsModalOut(false);
    setShowModal((prev) => {
      return { add: false, edit: false, delete: false };
    });
    setRefreshTable(!refreshTable);
  };

  const handleDelete = (param) => {
    // console.log(param);
    setSingleChild(param);
    setShowModal((prev) => {
      return { ...prev, delete: true };
    });
  };

  const handleEditSingleChild = (e, param) => {
    // e.preventDefault();
    if (param === "vertical") {
      setSingleChild((prev) => {
        return { ...prev, vertical_id: e };
      });
    }
    if(param === "is_allowed"){
      setSingleChild((prev) => {
        return { ...prev, is_allowed: e };
      });
    }
    if (param === "subsidiary") {
      // console.log("yes");
      setSingleChild((prev) => {
        return { ...prev, subsidiary_id: e };
      });
    } else {
      const { name, value } = e.target;
      const data = { ...singleChild, [name]: value };
      setSingleChild(data);
    }
  };

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getProductPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    // console.log(obj);
    user?.organizations?.length > 0 && dispatch(getProductWithPagination(obj));
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: "",
        org_id: actionCtx?.activeOrg?.id,
      };
      // console.log(obj);
      dispatch(getProductByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink
          title={`Revenue Projection`}
          textOne={`Vertical`}
          textTwo={`Portfolio`}
          textThree={`Products`}
          linkOne={`/dashboard-administration-admin-settings-budget-rev-pro-vertical`}
          linkTwo={`/dashboard-administration-admin-settings-budget-rev-pro-portfolio`}
          linkThree={`/dashboard-administration-admin-settings-budget-rev-pro-product`}
        />
        <div className="table-box-budget">
          {/* table box start */}
          <ActualReuseableTable
            fromPage={meta?.from || 1}
            toPage={meta?.to || products?.length}
            totalPage={meta?.total || products?.length}
            nextPage={links?.next}
            prevPage={links?.prev}
            onNextPage={() => handlePagination(links?.next)}
            onPrevPage={() => handlePagination(links?.prev)}
            onSearchTable={(e) => {
              setSearch(e);
            }}
            perPageValue={actionCtx?.perPage?.value}
            setPerPageValue={(e) => {
              // setPerpageVal(e);
              handleSortPerPage(e.value);
            }}
            tableTitle={products?.length > 1 ? `Products` : "Product"}
            tableTitleSpan={`(${products?.length})`}
            showBulkUpload
            dontShowSelect
            addBtntext={`New Product`}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            action
            onCreateNew={() => {
              actionCtx?.setIsModalOut(true);
              setShowModal((prev) => {
                return { ...prev, add: true };
              });
            }}
            loading={loadingPage && products?.length < 1}
            loadingNum={6}
          >
            {products?.map((chi, idx) => {
              const { name, description, subsidiary, gp_percentage } = chi;
              return (
                <TableBodyRow
                  loading={loadingPage}
                  key={idx}
                  one={name}
                  two={description}
                  three={subsidiary?.name || <>&nbsp;</>}
                  four={gp_percentage}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  deleteText={`Delete`}
                  updateText={`Update`}
                  onUpdate={() => {
                    handleUpdate(chi);
                    console.log(chi);
                  }}
                  onDelete={() => handleDelete(chi)}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
      </BudgetMain>

      <Modal effect="fadeInUp" visible={showModal.add}>
        <AddBudgetRevProducts
          onFinish={handleFinish}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, add: false };
            });
          }}
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.edit}>
        <UpdateBudgetRevProducts
          onFinish={handleFinish}
          detail={singleChild}
          onEdit={handleEditSingleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, edit: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.delete}>
        <DeleteBudgetRevProduct
          onFinish={handleFinish}
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
    </>
  );
};

export default RevenueProjectionProduct;
