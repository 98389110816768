import "../../../../../styles/dashboard/subscription/modals/successModal.css";
import thumbsUp from "../../../../../../assets/thumbs-up.svg";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { cancelIcon } from "./upgrade";

const SuccessModal = ({ onCancel, onClick }) => {
  // console.log(publicKey, "pubkey");
  // console.log(reference, "ref");
  return (
    <div className="success-modal-wrap">
      <figure className="cancel-icon" onClick={onCancel}>
        {cancelIcon}
      </figure>
      <img src={thumbsUp} alt="" />
      <p>
        Congratulations on your subscription! Kindly proceed to complete your
        transaction
      </p>
      <ButtonBlue onClick={onClick} text={"Proceed to Paystack"} />
    </div>
  );
};

export default SuccessModal;
