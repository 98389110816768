import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../context/ActionContext";
// import { reactSelectStyle } from "../../../../../helper/helpers";
import "../../../../../styles/dashboard/admin-settings/ProfileTransfer/ProfileTransfer.css";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../helper/Helpers";
import { getUsers } from "../../../../../redux/users/users";
import ButtonBlue from "../../../../buttons/ButtonBlue";

import Modal from "react-awesome-modal";
import ConfirmTransferModal from "./ConfirmTransferModal";
import SuccessTransferModal from "./SuccessTransferModal";
import { toast } from "react-toastify";

const ProfileTransferIndex = () => {
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  const { usersDrop } = useSelector((state) => state.users);
  const [details, setDetails] = useState({
    subsidiary_id: "",
    department_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    role: "",
    sex: "",
    submit_to: "",
    organization_id: "",
    transfer_from_email: "",
    transfer_to_email: "",
    is_new_user: false,
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      dispatch(getUsers(actionCtx?.activeOrg?.id));
    }
    // eslint-disable-next-line
  }, []);

  const formatUserMail = (list) => {
    const newList = list?.map((chi) => {
      return { ...chi, label: chi?.email, value: chi?.id };
    });
    return newList;
  };

  const [showModal, setShowModal] = useState({
    confirm: false,
    success: false,
  });

  const handleFinish = () => {
    setDetails({
      subsidiary_id: "",
      department_id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      role: "",
      sex: "",
      submit_to: "",
      organization_id: "",
      transfer_from_email: "",
      transfer_to_email: "",
      is_new_user: false,
    });
    setShowModal((prev) => {
        return { ...prev, confirm: false, success: true };
      });
  };

  const handleCheckSubmit = () => {
    if (details?.email?.label === details?.transfer_from_email?.label) {
      toast.error("You can not transfer profile with same email");
      return;
    }
    if (details?.transfer_to_email === details?.transfer_from_email?.label) {
      toast.error("You can not transfer profile with same email");
      return;
    }
    setShowModal((prev) => {
      return { ...prev, confirm: true };
    });
  };

  return (
    <>
      <div className="admin-settings-profile-transfer-wrap">
        {/* title box start */}
        <div className="subtitle-box">
          <p
            onClick={() => {
              // history.push("/dashboard-administration-admin-settings");
              // console.log(user?.permissions);
            }}
            className="text"
          >
            Admin Settings
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Profile Transfer</p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>
        {/* title box end */}
        {/* page title start */}
        <div className="page-title-box">
          <p className="title">Existing Profile</p>
          <p className="text">
            Select the email attached to the account you want to perform a
            profile transfer on, then input password to validate
          </p>
        </div>
        {/* page title end */}
        <div
          onClick={() => {
            // console.log(usersDrop);
          }}
          className="two-grid-box"
        >
          {/* group box start */}
          <div className="form-group">
            <label htmlFor="Choose budget approval" className="label">
              User Email <span style={{ color: "#CC0905" }}>*</span>
            </label>
            <Select
              className={`select-wrap ${
                details?.email && "select-wrap-active"
              }`}
              // isMulti

              styles={reactSelectStyle}
              placeholder="Search existing email"
              value={details.transfer_from_email}
              onChange={(e) => {
                // console.log(e);
                setDetails((prev) => {
                  return {
                    ...prev,
                    transfer_from_email: e,
                    first_name: e.first_name,
                    last_name: e.last_name,
                    sex: e.gender,
                    role: e.role,
                  };
                });
              }}
              options={formatUserMail(usersDrop)}
            />
          </div>
          {/* group box end */}
          <div className="form-group"></div>
          {/* group box end */}
        </div>
        {/* four grid start */}
        <div className="four-grid-box">
          {/* group box start */}
          <div className="form-group">
            <input
              type="text"
              placeholder="First Name"
              className={`input disable ${
                details.first_name && "input-active"
              }`}
              id="First Name"
              name="first_name"
              onChange={handleChange}
              value={details.first_name}
              disabled
            />
          </div>
          {/* group box end */}
          {/* group box start */}
          <div className="form-group">
            <input
              type="text"
              placeholder="Last Name"
              className={`input disable ${details.last_name && "input-active"}`}
              id="First Name"
              name="last_name"
              onChange={handleChange}
              value={details.last_name}
              disabled
            />
          </div>
          {/* group box end */}
          {/* group box start */}
          <div className="form-group">
            <input
              type="text"
              placeholder="Role"
              className={`input disable ${
                details.first_name && "input-active"
              }`}
              id="First Name"
              name="role"
              onChange={handleChange}
              value={details.role}
              disabled
            />
          </div>
          {/* group box end */}
          {/* group box start */}
          <div className="form-group">
            <input
              type="text"
              placeholder="Gender"
              className={`input disable ${
                details.first_name && "input-active"
              }`}
              id="First Name"
              name="sex"
              onChange={handleChange}
              value={details.sex}
              disabled
            />
          </div>
          {/* group box end */}
        </div>
        {/* four grid end */}

        {/* page title start */}
        <div className="page-title-box">
          <p className="title">New Profile</p>
          <p className="text">
            Select the new user email to assume authorization on above account
            and role.
          </p>
        </div>
        {/* page title end */}
        <div
          onClick={() => {
            // console.log(usersDrop);
          }}
          className="two-grid-box"
        >
          {/* group box start */}
          <div
            className={`form-group form-group-hide ${
              !details?.is_new_user && `form-group-show`
            }`}
          >
            <label htmlFor="Choose budget approval" className="label">
              User Email <span style={{ color: "#CC0905" }}>*</span>
            </label>
            <Select
              className={`select-wrap ${
                details?.email && "select-wrap-active"
              }`}
              styles={reactSelectStyle}
              placeholder="Search existing email"
              value={details?.email}
              onChange={(e) => {
                // console.log(e);
                setDetails((prev) => {
                  return { ...prev, email: e };
                });
              }}
              menuPlacement={`top`}
              options={formatUserMail(usersDrop)}
            />
          </div>
          <div
            className={`form-group form-group-hide ${
              details?.is_new_user && `form-group-show`
            }`}
          >
            <label htmlFor="Choose budget approval" className="label">
              User Email <span style={{ color: "#CC0905" }}>*</span>
            </label>
            <input
              style={{ backgroundColor: "#ffffff" }}
              type="text"
              placeholder="Enter email"
              className={`input disable ${
                details.transfer_to_email && "input-active"
              }`}
              id="First Name"
              name="transfer_to_email"
              onChange={handleChange}
              value={details.transfer_to_email}
            />
          </div>
          {/* group box end */}
          {/* group box end */}
          <div className="form-group"></div>
          {/* group box end */}
        </div>
        {/* text btn box start */}
        <div className="text-btn-box">
          <p className="text">
            User is not part of your organization yet? Add user here
          </p>
          <ButtonBlue
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDetails((prev) => {
                return {
                  ...prev,
                  is_new_user: !details?.is_new_user,
                  email: "",
                  transfer_to_email: "",
                };
              });
            }}
            bgColor={`#F6F8F9`}
          >
            <FaPlus className="icon" />{" "}
            <span>{details?.is_new_user ? `Existing User` : `New User`}</span>
          </ButtonBlue>
        </div>
        {/* text btn box end */}
        {/* submit btn start */}
        <ButtonBlue
          text={`Confirm Transfer`}
          style={{ alignSelf: "flex-start" }}
          onClick={() => {
            if (
              details?.is_new_user &&
              details?.transfer_to_email &&
              details?.transfer_from_email
            ) {
              handleCheckSubmit();
            }
            if (
              !details?.is_new_user &&
              details?.transfer_from_email &&
              details?.email
            ) {
              handleCheckSubmit();
            }
          }}
          bgColor={
            details?.is_new_user &&
            details?.transfer_to_email &&
            details?.transfer_from_email
              ? `var(--blue-color)`
              : !details?.is_new_user &&
                details?.transfer_from_email &&
                details?.email
              ? `var(--blue-color)`
              : "#cccccc"
          }
        />
        {/* submit btn end */}
      </div>

      {/* confirm modal start */}
      <Modal effect={`fadeInUp`} visible={showModal?.confirm}>
        <ConfirmTransferModal
          onCancel={() => {
            setShowModal((prev) => {
              return { ...prev, confirm: false };
            });
          }}
          onFinish={handleFinish}
          detail={details}
        />
      </Modal>
      {/* confirm modal end */}
      {/* confirm modal start */}
      <Modal effect={`fadeInUp`} visible={showModal?.success}>
        <SuccessTransferModal
          onCancel={() => {
            setShowModal((prev) => {
              return { ...prev, success: false };
            });
          }}
        />
      </Modal>
      {/* confirm modal end */}
    </>
  );
};

export default ProfileTransferIndex;
