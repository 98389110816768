import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc, customErrorId } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const checkDepartmentApprovalFlow = createAsyncThunk(
  "employee/check-for-approval-flow",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/check-department-approval-flow",
        formData
      );
      if (data?.status !== 200) {
        // toast.error(data.message, {
        //   theme: "colored",
        //   toastId: customErrorId,
        // });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.status === 200) {
        // toast.success(data.message, {
        //   theme: "colored",
        // });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const verify_approval_flow = createSlice({
  name: "verify_approval_flow",
  initialState: {
    loadingFlow: false,
  },
  extraReducers: {
    [checkDepartmentApprovalFlow.pending]: (state) => {
      state.loadingFlow = true;
    },
    [checkDepartmentApprovalFlow.fulfilled]: (state) => {
      state.loadingFlow = false;
    },
    [checkDepartmentApprovalFlow.rejected]: (state) => {
      state.loadingFlow = false;
      //   state.isAuth = false;
      //   state = null;
    },
  },
});

export default verify_approval_flow.reducer;
