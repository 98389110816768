import React from "react";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { deleteSharedService } from "../../../../../redux/shared-service/shareService";
import "../../../../../styles/dashboard/modals/DeleteSharedmodal.css";
import ButtonBlue from "../../../../buttons/ButtonBlue";

const DeleteSharedModal = ({ onClose, onFinish, detail }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.shared_service);

  const handleDelete = async () => {
    const id = detail?.id;
    const data = await dispatch(deleteSharedService(id));
    if (data?.payload?.success) {
      onFinish();
    }
  };
  return (
    <div className="delete-shared-modal-wrap">
      <p className="title">{`Delete Shared Service (${detail?.title})`}</p>
      <p className="text">
        Are you sure you want to delete this information. Deleting this, would
        affect your organizatiuon budget settings. Do you still want to delete?
      </p>
      {/* <p className="text text-two">
        organizatiuon budget settings. Do you still want to delete?
      </p> */}

      <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        {!loading ? (
          <ButtonBlue
            bgColor={`#CC0905`}
            text={`Yes Delete`}
            onClick={handleDelete}
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteSharedModal;
