import ExpenseProjectionIndex from "../../../component/pages/dashboard/projection/expense";
import AwaitPendingApprovalExpense from "../../../component/pages/dashboard/projection/expense/AwaitinCurrentApproval";
import ExpenseApproved from "../../../component/pages/dashboard/projection/expense/ExpenseApproved";
import ExpenseProjectionMain from "../../../component/pages/dashboard/projection/expense/ExpenseProjectionMain";
import ExpenseRejected from "../../../component/pages/dashboard/projection/expense/ExpenseRejected";
import ExpenxeAwaitApproval from "../../../component/pages/dashboard/projection/expense/ExpenxeAwaitApproval";

export const Expense_projection_route_group = [
  {
    path: "/dashboard-projection-expense",
    component: ExpenseProjectionIndex,
    pageTitle: "Expense Projection",
    goBack: true,
  },
  {
    path: "/dashboard-projection-expense-approved",
    component: ExpenseApproved,
    pageTitle: "Expense Projection",
    goBack: true,
  },
  {
    path: "/dashboard-projection-expense-awaiting-approval",
    component: ExpenxeAwaitApproval,
    pageTitle: "Expense Projection",
    goBack: true,
  },
  {
    path: "/dashboard-projection-expense-rejected",
    pageTitle: "Expense Projection",
    goBack: true,
    component: ExpenseRejected,
  },
  {
    path: "/dashboard-projection-expense-main",
    component: ExpenseProjectionMain,
    pageTitle: "Expense Projection",
    goBack: true,
  },
  {
    path: "/dashboard-projection-expense-cur-approve",
    component: AwaitPendingApprovalExpense,
    pageTitle: "Expense Projection",
    goBack: true,
  },
];
