export const accountLinks = [
  {
    name: "My Profile",
    link: "/dashboard-administration-admin-settings-user-profile",
  },
  {
    name: "Organization",
    link: "/dashboard-administration-admin-settings-account-organization",
  },
  {
    name: "Preferences",
    link: "/dashboard-administration-admin-settings-account-preference",
  },
  {
    name: "Transfer Ownership",
    link: "/dashboard-administration-admin-settings-profile-transfer",
  },
];

export const organizationLinks = [
  {
    name: "Fiscal Year",
    link: "/dashboard-administration-admin-settings-fiscal-year",
  },
  {
    name: "Subsidiaries",
    link: "dashboard-administration-admin-settings-subsidaries-main",
  },
  {
    name: "Shared Services",
    link: "/dashboard-administration-admin-settings-add-subsidary-shared-service-main",
  },
  {
    name: "Branches",
    link: "/dashboard-administration-admin-settings-branch",
  },
  {
    name: "Departments",
    link: "/dashboard-administration-admin-settings-department",
  },
  {
    name: "Groups",
    link: "/dashboard-administration-admin-settings-group",
  },
  {
    name: "User",
    link: "/dashboard-administration-admin-settings-employee",
  },
  {
    name: "Budget",
    link: "/dashboard-administration-admin-settings-budget-exp-classes",
  },
  {
    name: "Roles",
    link: "/dashboard-administration-admin-settings-role",
  },
  {
    name: "Currency",
    link: "/dashboard-administration-admin-settings-currency",
  },
  {
    name: "Billings / Subscription",
    link: "/dashboard-administration-admin-settings-billing",
  },
  {
    name: "Customization",
    link: "/dashboard-administration-admin-settings-customization",
  },
];
