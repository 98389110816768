import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteBudgetAssumption } from "../../../../../../redux/budget-setting/budget-assumption/budgetAssumptions";
import CommonDeleteModal from "./CommonDeleteModal";

const DeleteBudgetAssumption = ({ onClose, onFinish, detail }) => {
  const { loading } = useSelector((state) => state.budgetAssumptions);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    const id = detail?.id;
    const data = await dispatch(deleteBudgetAssumption(id));
    if (data?.payload?.success) {
      onFinish();
    }
  };

  return (
    <>
      <CommonDeleteModal
        loading={loading}
        onDelete={handleDelete}
        title={`Delete Budget Assumption (${detail?.assumption})`}
        text={`Are you sure you want to delete this information. Deleting this, would affect your 
organizatiuon budget settings. Do you still want to delete?`}
        onClose={onClose}
      />
    </>
  );
};

export default DeleteBudgetAssumption;
