import React, { useContext, useEffect, useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
// import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import {
  formatNumberWithoutComma,
  formatNumberWithoutDecimal,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { getDepartmentsBySubsidiary } from "../../../../../../redux/department/deparment";
import { updateExpenseLimit } from "../../../../../../redux/budget-setting/expense-limit/expenseLimit";

const UpdateExpenseLimitModal = ({ onCancel, onEdit, onFinish, detail }) => {
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { departmentDrop } = useSelector(
    (state) => state.department
  );
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  // const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.expense_limit);
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const [details, setDetails] = useState({
    organization_id: "",
    fiscal_year_id: "",
    subsidiary_id: "",
    department_id: "",
    limit: "",
  });


  useEffect(() => {
    if (details?.subsidiary_id) {
      setDetails((prev) => {
        return { ...prev, subsidiary_id: "", department_id: "" };
      });
    }
      // eslint-disable-next-line
  }, [actionCtx?.activeOrg]);

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   const data = { ...details, [name]: value };
  //   setDetails(data);
  // };

  const handleSubmit = async () => {
    const obj = {
      id: detail?.id,
      subsidiary_id: detail?.subsidiary_id.value,
      organization_id: actionCtx?.activeOrg?.id,
      department_id: detail?.department_id.value,
      fiscal_year_id: detail?.fiscal_year_id.value,
      limit: detail?.limit
    };
    // console.log(obj);
    // return;
    const data = await dispatch(updateExpenseLimit(obj));
    if (data?.payload?.success) {
      onFinish();
      setDetails({
        organization_id: "",
        fiscal_year_id: "",
        subsidiary_id: "",
        department_id: "",
        limit: "",
      });
    }
  };

  return (
    <>
      <CommonInputModal
        loading={loading}
        btnActive={
          details?.department_id ||
          details?.fiscal_year_id ||
          details?.limit ||
          details?.subsidiary_id
            ? true
            : false
        }
        onBtnClick={handleSubmit}
        btnDisable={
          !details?.department_id &&
          !details?.fiscal_year_id &&
          !details?.limit &&
          !details?.subsidiary_id
        }
        longContent
        onCancel={() => {
          onCancel();
          setDetails({
            organization_id: "",
            fiscal_year_id: "",
            subsidiary_id: "",
            department_id: "",
            limit: "",
          });
        }}
        title={`Update Expense Limit`}
        btnText={`Update`}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Fiscal Year <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="Select fiscal year"
            className="select "
            value={detail?.fiscal_year_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, fiscal_year_id: e };
              });
              onEdit(e, "fiscal");
            }}
            options={fiscalYearDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Subsidiary <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="select subsidiary"
            className="select "
            value={detail?.subsidiary_id}
            onChange={(e) => {
              dispatch(getDepartmentsBySubsidiary(e.value));
              setDetails((prev) => {
                return { ...prev, subsidiary_id: e };
              });
              onEdit(e, "subsidiary");
            }}
            options={subsidiariesDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Departments <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="select department"
            className="select "
            value={detail?.department_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, department_id: e };
              });
              onEdit(e, "department");
            }}
            options={departmentDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Limit (NGN) <span>*</span>
          </label>
          <input
            name="limit"
            onChange={(e) => {
              setDetails((prev) => {
                return {
                  ...prev,
                  limit: formatNumberWithoutComma(String(e.target.value)),
                };
              });
              onEdit(formatNumberWithoutComma(String(e.target.value)), "limit");
            }}
            value={formatNumberWithoutDecimal(String(detail?.limit))}
            type="text"
            placeholder="enter expenditure limit"
            className={`input ${details?.limit && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
      </CommonInputModal>
    </>
  );
};

export default UpdateExpenseLimitModal;
