import React, { useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import GroupImg from "../../../../../assets/group-house-icon.svg";
import singleImg from "../../../../../assets/single-house-icon.svg";
import "../../../../../styles/dashboard/admin-settings/OrganizationSettings.css";
import { FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useThemeDetector } from "../../../../../utils/getThemeColor";
import { useSelector } from "react-redux";

const list = [
  {
    name: "Group Organization",
    text: "Has multiple subsidiaries and branches.",
    img: GroupImg,
    value: "group",
  },
  {
    name: "Single Organization",
    text: "Has multiple branches and no subsidiaries",
    img: singleImg,
    value: "single",
  },
];

const OrganizationSelect = () => {
  const history = useHistory();
  const [orgType, setOrgType] = useState(""); 
  const { plan_details } = useSelector((state) => state.users);
  const isDarkTheme = useThemeDetector();
  const { preference } = useSelector((state) => state.preference);
  const handleSetOrgTypeVal = (val) => {
    setOrgType(val)
    localStorage.setItem("organization_type_id", val)
  }
  return (
    <>
      {/* <DashboardLayout goBack pageTitle={`Admin Settings`}> */}
        <div className="organization-wrap">
          <p className="title">Welcome! let’s setup your organization</p>
          <p className="text">
            You can always modify your organization settings at any time via the
            admin setting .
          </p>
          <div className="select-org-wrap">
            <p className="title">Select organization type</p>

            {/* select box start */}
            {list.map((child, idx) => {
              const { name, text, img , value} = child;
              return (
                <div
                  key={idx}
                  className={`child ${orgType === name && "child-active"}`}
                  onClick={() => {
                    if (
                      name === "Group Organization" &&
                      !plan_details?.privilages?.organization_type
                    ) {
                      return;
                    }
                    handleSetOrgTypeVal(value)
                    setOrgType(name)
                  }}
                  style={{
                    cursor:
                      name === "Group Organization" &&
                      !plan_details?.privilages?.organization_type &&
                      "not-allowed",
                  }}
                >
                  <figure className="img-box">
                    <img style={{
              filter:
                preference?.display === "Light"
                  ? "unset"
                  : preference?.display === "Dark"
                  ? "brightness(0%)"
                  : isDarkTheme
                  ? "brightness(0%)"
                  : "unset",
            }}  src={img} alt="" className="img" />
                  </figure>
                  <div className="text-box">
                    <p className="name">{name}</p>
                    <p className="text">{text}</p>
                  </div>
                  {/* check box start */}
                  <div className="check-box">
                    <FaCheck
                      className={`icon ${orgType === name && "icon-active"}`}
                    />
                  </div>
                  {/* check box end */}
                </div>
              );
            })}
            {/* select box end */}
            {/* button start */}
            <button
              onClick={() => {
                orgType &&
                  history.push(
                    "/dashboard-administration-admin-settings-account-organization-settings"
                  );
              }}
              className={`btn-set-up ${orgType && "btn-set-up-active"}`}
            >
              Start Setup
            </button>
            {/* button end */}
          </div>
        </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default OrganizationSelect;
