import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId, getChartStructures } from "../../helper/Helpers";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getSupplementaryBudgetChart = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/supplementary?organization_id=${
          formData?.org_id
        }&currency_conversion_id=${formData?.cur_id}&perPage=${
          formData?.per_page || 20
        }`
      );
      // console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        await thunkAPI.dispatch(setSupplementaryBudget(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSupplementaryBudgetActivityLog = createAsyncThunk(
  "admin/get-exp-pro-activity-log",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/supplementaryActivity/${formData?.id}`
      );
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        // const branchInfo = data.data.approval_type;
        // await thunkAPI.dispatch(setApprovals(data?.data?.approvals));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSupplementaryBudgetChartBySort = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/supplementary?organization_id=${
          formData?.org_id
        }&department_id=${formData?.dept_id || ""}&branch_id=${
          formData?.bra_id || ""
        }&subsidiary_id=${formData?.sub_id || ""}&currency_conversion_id=${
          formData?.cur_id || ""
        }&month=${formData?.date || ""}&perPage=${
          formData?.per_page || 20
        }&fiscal_year_id=${formData?.fis_id || ""}`
      );
      // console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        await thunkAPI.dispatch(setSupplementaryBudget(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSupplementaryBudgetChartPagination = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/supplementary?organization_id=${
          formData?.org_id
        }&page=${formData?.num}&currency_conversion_id=${
          formData?.cur_id
        }&perPage=${formData?.per_page || 20}`
      );
      // console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        await thunkAPI.dispatch(setSupplementaryBudget(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovalType = createAsyncThunk(
  "admin/get-approval-type",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`admin/approvalType`);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovalType(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovalByClass = createAsyncThunk(
  "admin/get-approval-by-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/approvals/organization/${formData?.org_id}/${formData?.class_id}`
      );
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data.data.approvals);
        // const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovals(data?.data?.approvals));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesWithPagination = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/branches/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesByFilter = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/branches/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setApprovals(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesPagination = createAsyncThunk(
  "admin/get-branches-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranch = createAsyncThunk(
  "admin/get-branch",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/branches/${formData}`);
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesBySubsidiary = createAsyncThunk(
  "admin/get-branch",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(
        `/admin/branches/subsidiary/${formData}`
      );
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // const branchInfo = data.data.branches;
        // await thunkAPI.dispatch(setBranchSubDrop(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const setUpApprovalFlow = createAsyncThunk(
  "admin/create-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { org_id } = formData;
      const data = await axios.post(
        `/admin/organizations/${org_id}/approval-setup`,
        formData
      );
      // console.log('set-up-org', data);
      if (data?.data?.success === false) {
        toast.error(data.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        // return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.success === true) {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // console.log(data);
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response?.status === 422) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "admin/delete-delete",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/branches/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateBranch = createAsyncThunk(
  "admin/update-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/branches/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSupplementaryBudgetChartByDepartment = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/projections/department-supplementary?organization_id=${
          formData?.org_id
        }&filter[department_id]=${formData?.dept_id}&page=${
          formData?.page || "1"
        }&currency_conversion_id=${formData?.cur_id}&perPage=${
          formData?.per_page || 20
        }&fiscal_year_id=${formData?.fis_id || ""}`
      );
      console.log("approval-class", data.data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setSupplementaryBudgetSingle(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSupplementaryBudgetChartByDepartmentByStatus = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/projections/department-supplementary?organization_id=${
          formData?.org_id
        }&department_id=${formData?.dept_id}&filter[status]=${
          formData?.status || ""
        }&page=${formData?.page || "1"}&currency_conversion_id=${
          formData?.cur_id
        }&perPage=${formData?.per_page || 20}&fiscal_year_id=${
          formData?.fis_id || ""
        }`
      );
      console.log("approval-class", data.data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setSupplementaryBudgetSingle(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSupBudgComments = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `employee/comments/supplementary-expense/${formData}`
      );
      console.log("branch", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data);
        const branchInfo = data.data.actual_expense_comments;
        await thunkAPI.dispatch(setComments(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const addSupBudgComment = createAsyncThunk(
  "admin/approve-exp-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `employee/comments/supplementary-expense/add-comment?supplementary_budget_id=${formData?.id}`,
        formData
      );
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteCommentSupBudg = createAsyncThunk(
  "admin/delete-delete",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `employee/comments/actual-expenditure/${formData?.exp_id}/${formData?.comment_id}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const suplementary_budget_chart = createSlice({
  name: "suplementary_budget_chart",
  initialState: {
    chartInfo: {},
    supplementary_budget: [],
    supplementary_budget_single: [],
    comments: [],
    links: {},
    meta: {},
    branch: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setSupplementaryBudget: (state, action) => {
      state.isAuth = true;
      state.supplementary_budget = action.payload;
      state.links = action.payload.links;
    },
    setComments: (state, action) => {
      state.isAuth = true;
      state.comments = action.payload;
      state.links = action.payload.links;
    },
    setSupplementaryBudgetSingle: (state, action) => {
      state.isAuth = true;
      state.supplementary_budget_single = action.payload;
    },
    setChartInfo: (state, action) => {
      state.isAuth = true;
      state.chartInfo = getChartStructures(action.payload);
    },
    setApprovals: (state, action) => {
      state.isAuth = true;
      state.approvals = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setApprovalClass: (state, action) => {
      state.isAuth = true;
      state.approvalClass = action.payload;
    },
    setApprovalType: (state, action) => {
      state.isAuth = true;
      state.approvalType = action.payload;
    },
    setBranch: (state, action) => {
      state.isAuth = true;
      state.branch = action.payload;
    },
  },
  extraReducers: {
    [setUpApprovalFlow.pending]: (state) => {
      state.loading = true;
    },
    [setUpApprovalFlow.fulfilled]: (state) => {
      state.loading = false;
    },
    [setUpApprovalFlow.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSupplementaryBudgetChartByDepartment.pending]: (state) => {
      state.loading = true;
      state.loadingPage = true;
    },
    [getSupplementaryBudgetChartByDepartment.fulfilled]: (state) => {
      state.loading = false;
      state.loadingPage = false;
    },
    [getSupplementaryBudgetChartByDepartment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getSupplementaryBudgetChart.pending]: (state) => {
      state.loading = true;
    },
    [getSupplementaryBudgetChart.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSupplementaryBudgetChart.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSupplementaryBudgetChartBySort.pending]: (state) => {
      state.loading = true;
    },
    [getSupplementaryBudgetChartBySort.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSupplementaryBudgetChartBySort.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSupplementaryBudgetChartPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSupplementaryBudgetChartPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSupplementaryBudgetChartPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [setUpApprovalFlow.pending]: (state) => {
      state.loading = true;
    },
    [setUpApprovalFlow.fulfilled]: (state) => {
      state.loading = false;
    },
    [setUpApprovalFlow.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesBySubsidiary.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesBySubsidiary.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesBySubsidiary.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovalByClass.pending]: (state) => {
      state.loading = true;
    },
    [getApprovalByClass.fulfilled]: (state) => {
      state.loading = false;
    },
    [getApprovalByClass.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSupBudgComments.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSupBudgComments.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSupBudgComments.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [addSupBudgComment.pending]: (state) => {
      state.loading = true;
    },
    [addSupBudgComment.fulfilled]: (state) => {
      state.loading = false;
    },
    [addSupBudgComment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteCommentSupBudg.pending]: (state) => {
      state.loading = true;
      state.loadingPage = true;
    },
    [deleteCommentSupBudg.fulfilled]: (state) => {
      state.loading = false;
      state.loadingPage = false;
    },
    [deleteCommentSupBudg.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBranch,
  setBranches,
  setApprovalClass,
  setApprovals,
  setApprovalType,
  setChartInfo,
  setSupplementaryBudget,
  setSupplementaryBudgetSingle,
  setComments,
} = suplementary_budget_chart.actions;

export default suplementary_budget_chart.reducer;
