import React from "react";
import { trimLongString } from "../../../../../../../helper/Helpers";

const EmployeeOverviewTableBodyRow = ({
  one,
  two,
  three,
  userOne,
  userImg,
  userName,
  userEmail,
  status,
  colorLess,
}) => {
  return (
    <>
      <tr
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(max-content, 1fr))",
          marginBottom: "2rem",
          marginTop: "1rem",
          borderBottom: ".1rem solid var(--border-color)",
          paddingBottom: "2rem",
          fontWeight: "400",
        }}
        // className="top-box"
      >
        {one && <td>{one}</td>}
        {two && <td>{two}</td>}
        {userOne && (
          <td>
            <div style={{ display: "flex", gap: "1rem", marginLeft: "-5rem" }}>
              <div>
                <figure>
                  <img
                    style={{
                      borderRadius: "20%",
                      width: "4rem",
                      height: "4rem",
                    }}
                    alt=""
                    className="img"
                    src={userImg}
                  />
                </figure>
              </div>
              <div>
                <p
                  style={{
                    color: "#1D1D18",
                    fontWeight: "600",
                    paddingRight: "unset",
                  }}
                >
                  {trimLongString(userName, "12")}
                </p>
                <p className="text" style={{ fontSize: "1.2rem" }}>
                  {trimLongString(userEmail, "15")}
                </p>
              </div>
            </div>
          </td>
        )}
        {three && <td>{three}</td>}
        {status && (
          <td
            // className="overview-content-status"
            // note that both approved and rejected text are case sensitive...
            // noted :)
            style={{
              textTransform: "capitalize",
              color:
                status === ("approved" || "Approved")
                  ? "#07A287"
                  : status === ("rejected" || "Rejected")
                  ? "#CC0905"
                  : "#FCA549",
            }}
          >
            {status}
          </td>
        )}
      </tr>
      <div />
    </>
  );
};
export default EmployeeOverviewTableBodyRow;
