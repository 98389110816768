import React, { useContext, useState, useEffect } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import Select from "react-select";
import ActionContext from "../../../../../../context/ActionContext";
// import { useHistory } from "react-router-dom";
import EmployeeNetProfitProjection from ".";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import EmployeeNetProfitProjectionGraph from "../chart/profitProjection";
import TableBodyRow from "../../../../../../component/tables/TableBodyRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeActualProfitProjection,
  getEmployeeActualProfitProjectionPagination,
} from "../../../../../../redux/employee/dashboard";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";

const EmployeeActualNetProfitIndex = ({ children, chartBox }) => {
  const actionCtx = useContext(ActionContext);
  // const [chartFilterVal, setChartFilterVal] = useState("");
  const { user } = useSelector((state) => state.user);

  const [totalNetProfit, setTotalActualProfit] = useState({});
  const [actualProfitGraphVal, setActualProfitGraphVal] = useState({});
  const { actualprofitData, loading } = useSelector(
    (state) => state.employee_dashboard
  );
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );

  // const

  const dispatch = useDispatch();

  const department_id = localStorage.getItem("employee-department-id");

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    if (user?.organizations[0]?.id || department_id) {
      handleGetNetProfitGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id, department_id]);

  const handleGetNetProfitGraph = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: department_id || userDepartmentDrop[0]?.id,
      // user_id: user?.id,
    };
    const data = await dispatch(getEmployeeActualProfitProjection(obj));
    if (data?.payload?.success) {
      // console.log(data, "data");
      const totalNetProfitval = data?.payload?.data?.total_actual_net_profit;
      const netProfitGraph = data?.payload?.data?.net_profit_graph;
      let netProfitGraphValues = [];
      for (const netProfitObj of netProfitGraph) {
        netProfitGraphValues.push(
          parseInt(netProfitObj?.value?.net_projected_graph)
        );
      }
      // for(const netProfitData p)
      setActualProfitGraphVal(netProfitGraphValues);
      setTotalActualProfit(totalNetProfitval);
    }
  };

  const handlePagination = async (param) => {
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: department_id || userDepartmentDrop[0]?.id,
      page: linkTwo,
    };
    dispatch(getEmployeeActualProfitProjectionPagination(obj));
  };

  const tableHeadList = [
    "Product",
    "Actual Expense (N)",
    "Actual Revenue (N)",
    "Total GP (N)",
    "Total GP%",
    "Net Profit (N)",
  ];

  return (
    <>
      <EmployeeNetProfitProjection
        actualVal={formatNumber(
          String(
            FormatNumWithCurrency(
              totalNetProfit,
              actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
              actionCtx?.currencyValue
            ) || 0
          )
        )}
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={` ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          ></div>
        )}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Net Profit Graph</p>
              {/* <Select
                // isSearchable={true}
                // isMulti
                placeholder="Monthly"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={optionList}
              /> */}
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              {/* <EmployeeMonthlyExpenseChart /> */}
              <EmployeeNetProfitProjectionGraph
                dataValue={actualProfitGraphVal}
                loading={loading}
              />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            dontShowPagination={actualprofitData?.data?.length < 1}
            fromPage={actualprofitData?.from}
            toPage={actualprofitData?.to}
            nextPage={actualprofitData?.next_page_url}
            prevPage={actualprofitData?.prev_page_url}
            onNextPage={() => handlePagination(actualprofitData?.next_page_url)}
            onPrevPage={() => handlePagination(actualprofitData?.prev_page_url)}
            totalPage={actualprofitData?.total}
          >
            {actualprofitData?.data?.map((chi, idx) => {
              const {
                product_name,
                total_actual_expense,
                total_actual_revenue,
                total_gp,
                total_gp_percent,
                net_profit,
                // status,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={product_name}
                  two={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        total_actual_expense,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  three={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        total_actual_revenue,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  four={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        total_gp,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  five={formatNumber(String(total_gp_percent) || 0) + " %"}
                  six={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        net_profit,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  loading={loading}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </EmployeeNetProfitProjection>
    </>
  );
};

export default EmployeeActualNetProfitIndex;
