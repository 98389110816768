import AdminInvite from "../../../component/pages/dashboard/administration/AdminInvite";
import ApprovalDetail from "../../../component/pages/dashboard/administration/approval";
// import ExpenditureProjectionSettings from "../../../component/pages/dashboard/administration/approval/expenditure-projection";
// import ExpenditureMain from "../../../component/pages/dashboard/administration/approval/expenditure-projection/ExpenditureMain";
// import RevenueProjectionApprovalSettings from "../../../component/pages/dashboard/administration/approval/revenue-projection";
// import RevenueProjectApprovalIdex from "../../../component/pages/dashboard/administration/approval/revenue-projection/RevenueProjectApprovalIndex";
import BillingMain from "../../../component/pages/dashboard/administration/billing";
import Branch from "../../../component/pages/dashboard/administration/branch";
import AddBranch from "../../../component/pages/dashboard/administration/branch/AddBranch";
import UpdateBranch from "../../../component/pages/dashboard/administration/branch/UpdateBranch";
// import BudgetMain from "../../../component/pages/dashboard/administration/budget";
import BudgetAssumption from "../../../component/pages/dashboard/administration/budget/budget-assumption";
import AddBulkUploadTemplate from "../../../component/pages/dashboard/administration/budget/bulk-upload-template/AddBulkUploadTemplate";
import BulkUploadTemplate from "../../../component/pages/dashboard/administration/budget/bulk-upload-template/BulkUploadTemplate";
import CustomizeBulkUploadTemplate from "../../../component/pages/dashboard/administration/budget/bulk-upload-template/CustomizeBulkUploadTemplate";
import UpdateBulkUploadTemplate from "../../../component/pages/dashboard/administration/budget/bulk-upload-template/UpdateBulkUploadTemplate";
import BudgetExpAccount from "../../../component/pages/dashboard/administration/budget/expenditure-rev/BudgetExpAccount";
import BudgetExpCategories from "../../../component/pages/dashboard/administration/budget/expenditure-rev/BudgetExpCategories";
import BudgetExpClasses from "../../../component/pages/dashboard/administration/budget/expenditure-rev/BudgetExpClasses";
import ExpenseLimit from "../../../component/pages/dashboard/administration/budget/expense-limit";
import RevenueLimit from "../../../component/pages/dashboard/administration/budget/revenue-limit";
import RevenueProjectionPortfolio from "../../../component/pages/dashboard/administration/budget/revenue-projection/RevenueProjectionPortfolio";
import RevenueProjectionProduct from "../../../component/pages/dashboard/administration/budget/revenue-projection/RevenueProjectionProduct";
import RevenueProjectionVertical from "../../../component/pages/dashboard/administration/budget/revenue-projection/RevenueProjectionVertical";
import Currency from "../../../component/pages/dashboard/administration/currency";
import CustomizationIndex from "../../../component/pages/dashboard/administration/customization";
import AddCustomization from "../../../component/pages/dashboard/administration/customization/AddCustomization";
import Department from "../../../component/pages/dashboard/administration/department";
import AddDepartment from "../../../component/pages/dashboard/administration/department/AddDepartment";
import EditDepartment from "../../../component/pages/dashboard/administration/department/EditDepartment";
import ViewDepartment from "../../../component/pages/dashboard/administration/department/ViewDepartment";
import Employee from "../../../component/pages/dashboard/administration/employee";
import AddEmployee from "../../../component/pages/dashboard/administration/employee/AddEmployee";
import EditEmployee from "../../../component/pages/dashboard/administration/employee/EditEmployee";
import ViewEmployee from "../../../component/pages/dashboard/administration/employee/ViewEmployee";
import FiscalYear from "../../../component/pages/dashboard/administration/fiscal";
import AddFiscalYear from "../../../component/pages/dashboard/administration/fiscal/AddFiscalYear";
import GroupSettings from "../../../component/pages/dashboard/administration/group";
import AddGroup from "../../../component/pages/dashboard/administration/group/AddGroup";
import EditGroup from "../../../component/pages/dashboard/administration/group/EditGroup";
import ViewGroup from "../../../component/pages/dashboard/administration/group/ViewGroup";
import OrganizationMain from "../../../component/pages/dashboard/administration/organization";
import OrganizationSelect from "../../../component/pages/dashboard/administration/organization/OrganizationSelect";
import OrganizationSettings from "../../../component/pages/dashboard/administration/organization/OrganizationSettings";
import PreferenceSettings from "../../../component/pages/dashboard/administration/preference";
import ProfileSetting from "../../../component/pages/dashboard/administration/profile";
import ProfileTransferIndex from "../../../component/pages/dashboard/administration/profileTransfer";
import RoleMain from "../../../component/pages/dashboard/administration/role";
import CreatorAdministrator from "../../../component/pages/dashboard/administration/role/CreatorAdminStrator";
import UpdateRole from "../../../component/pages/dashboard/administration/role/UpdateRole";
import ViewRole from "../../../component/pages/dashboard/administration/role/viewRole";
import Subsidiary from "../../../component/pages/dashboard/administration/subsidiary";
import AddSharedService from "../../../component/pages/dashboard/administration/subsidiary/AddSharedService";
import AddSubsidiary from "../../../component/pages/dashboard/administration/subsidiary/AddSubsidiary";
import SharedServiceMain from "../../../component/pages/dashboard/administration/subsidiary/SharedServiceMain";
import SubsidiaryAfterCheckList from "../../../component/pages/dashboard/administration/subsidiary/SubsidiaryAfterCheckList";
import SubsidiarySharedServiceUpdate from "../../../component/pages/dashboard/administration/subsidiary/SubsidiarySharedService";
// import SubsidiarySharedService from "../../../component/pages/dashboard/administration/subsidiary/SubsidiarySharedService";
import UpdateSubsidiary from "../../../component/pages/dashboard/administration/subsidiary/UpdateSubsidary";
import ViewSharedService from "../../../component/pages/dashboard/administration/subsidiary/ViewSharedService";
import UserInvite from "../../../component/pages/dashboard/administration/UserInvite";

export const organization_routes_group = [
  {
    path: "/dashboard-administration-admin-settings-user-profile",
    component: ProfileSetting,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-account-organization",
    component: OrganizationMain,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-account-organization-select",
    component: OrganizationSelect,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-account-organization-settings",
    component: OrganizationSettings,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-account-preference",
    component: PreferenceSettings,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-admin-invite",
    component: AdminInvite,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-organization-users",
    component: UserInvite,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-subsidaries",
    component: Subsidiary,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-subsidaries-main",
    component: SubsidiaryAfterCheckList,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-subsidaries-update",
    component: UpdateSubsidiary,
    pageTitle: "Edit Subsidiary",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-add-subsidary",
    component: AddSubsidiary,
    pageTitle: "Add Subsidiary",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-add-subsidary-shared-service-update",
    component: SubsidiarySharedServiceUpdate,
    pageTitle: "Shared service",
    goBack: true,
  },

  {
    path: "/dashboard-administration-admin-settings-add-subsidary-shared-service-main",
    component: SharedServiceMain,
    pageTitle: "Shared service",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-add-subsidary-shared-service-add",
    component: AddSharedService,
    pageTitle: "Shared service",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-add-subsidary-shared-service-view",
    component: ViewSharedService,
    pageTitle: "Shared service",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-add-branch",
    component: AddBranch,
    pageTitle: "Add Branch",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-branch",
    component: Branch,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-branch-update",
    component: UpdateBranch,
    pageTitle: "Edit Branch",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-fiscal-year",
    component: FiscalYear,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-fiscal-year-add",
    component: AddFiscalYear,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-add-department",
    component: AddDepartment,
    pageTitle: "Add Department",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-department",
    component: Department,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-edit-department",
    component: EditDepartment,
    pageTitle: "Edit department",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-view-department",
    component: ViewDepartment,
    pageTitle: "view department",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-role-creator-adminostrator",
    component: CreatorAdministrator,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-role-creator-adminostrator-view",
    component: ViewRole,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-role-creator-adminostrator-update",
    component: UpdateRole,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-role",
    component: RoleMain,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-currency",
    component: Currency,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-exp-categories",
    component: BudgetExpCategories,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-exp-classes",
    component: BudgetExpClasses,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-exp-account",
    component: BudgetExpAccount,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-rev-pro-vertical",
    component: RevenueProjectionVertical,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-rev-pro-portfolio",
    component: RevenueProjectionPortfolio,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-rev-pro-product",
    component: RevenueProjectionProduct,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-expense-limit",
    component: ExpenseLimit,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-revenue-limit",
    component: RevenueLimit,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-budget-assumption",
    component: BudgetAssumption,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-billing",
    component: BillingMain,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-add-employee",
    component: AddEmployee,
    pageTitle: "Add employee",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-view-employee",
    component: ViewEmployee,
    pageTitle: "view employee",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-edit-employee",
    component: EditEmployee,
    pageTitle: "Edit employee",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-employee",
    component: Employee,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-customization",
    component: CustomizationIndex,
    pageTitle: "Customization",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-customization-add",
    component: AddCustomization,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-group",
    component:GroupSettings,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-group-add",
    component: AddGroup,
    pageTitle: "Add group",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-group-view",
    component: ViewGroup,
    pageTitle: "view group",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-group-edit",
    component: EditGroup,
    pageTitle: "edit group",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-bulk-upload-template",
    component: BulkUploadTemplate,
    pageTitle: "Budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-bulk-upload-template-add",
    component: AddBulkUploadTemplate,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-bulk-upload-template-customize",
    component: CustomizeBulkUploadTemplate,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-budget-bulk-upload-template-update",
    component: UpdateBulkUploadTemplate,
    pageTitle: "budget",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-approval-flow",
    component: ApprovalDetail,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  {
    path: "/dashboard-administration-admin-settings-profile-transfer",
    component: ProfileTransferIndex,
    pageTitle: "Admin Settings",
    goBack: true,
  },
];
