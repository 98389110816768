import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../utils/axios";
// import { encrypt, decrypt } from 'utils/encrypt';
import setAuthToken from "../utils/auth";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../helper/Helpers";
toast.configure();

export const registerUser = createAsyncThunk(
  "admin/register-user",
  async (formData, thunkAPI) => {
    try {
      // const payload = {
      //   email: formData?.email,
      //   password: formData?.password
      // };
      // // console.log(formData);
      // return
      const { data } = await axios.post("/admin/register", formData);
      if (!data.success) {
        // // console.log(data.message);
        toast.error(data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        localStorage.setItem("token", data.data.token);
        toast.success(data?.message, {
          theme: "colored",
        });
        // return thunkAPI.rejectWithValue(data);
      }
      await thunkAPI.dispatch(setUserEmail(formData.email));
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const VerifyUserEmail = createAsyncThunk(
  "admin/verify-email",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/verify-otp", formData);
      // // console.log('verify', data);
      if (!data.success) {
        // // console.log(data.message);
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }

      if (data.success) {
        // // console.log(data.message);
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }

      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      // // console.log(err.response.data.message);
      if (err) {
        if (err.message === "Network Error") {
          toast.error(err.message, {
            theme: "colored",
            toastId: customErrorId,
          });
        }
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const resendverifyUserEmail = createAsyncThunk(
  "admin/verify-email",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/resend-otp", formData);
      // // console.log('resend', data);
      if (!data.success) {
        // // console.log(data.message);
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }

      if (data.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }

      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const loginUser = createAsyncThunk(
  "admin/login-user",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post("/admin/login", formData);
      // console.log("Login", data);
      if (!data?.success) {
        toast.error(data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        localStorage.removeItem("email-to-receive-otp");
        await thunkAPI.dispatch(setUser(data?.data?.user));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err?.message === "Network Error") {
        toast.error(err?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "admin/get-user",
  async (formData, thunkAPI) => {
    // console.log(actionCtx?.curencyConversion?.id);
    // console.log("yes--------------");
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get("/admin/user");
      // // console.log("Login", data);
      // if (!data.success) {
      //   toast.error(data.message, {
      //     theme: "colored",
      //  toastId: customErrorId,
      //   });
      //   return thunkAPI.rejectWithValue(data);
      // }

      if (data?.success) {
        localStorage.removeItem("email-to-receive-otp");
        // // console.log(data);
        // await thunkAPI.dispatch(setUser(data.data.user));
        // return thunkAPI.rejectWithValue(data);
      }
      // // console.log("Login", data);
      localStorage.setItem("user", JSON.stringify(data.data));
      await thunkAPI.dispatch(setUser(data.data));
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getUserEmployeeDetails = createAsyncThunk(
  "admin/get-user",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get("/admin/user");
      // // console.log("Login", data);
      // if (!data.success) {
      //   toast.error(data.message, {
      //     theme: "colored",
      //  toastId: customErrorId,
      //   });
      //   return thunkAPI.rejectWithValue(data);
      // }

      if (data?.success) {
        localStorage.removeItem("email-to-receive-otp");
        // // console.log(data);
        // await thunkAPI.dispatch(setUser(data.data.user));
        // return thunkAPI.rejectWithValue(data);
      }
      // // console.log("Login", data);
      localStorage.setItem("user", JSON.stringify(data.data));
      await thunkAPI.dispatch(setUser(data.data));
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const confirmReceivingEmail = createAsyncThunk(
  "admin/confirm-receiving-email",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post("/admin/confirm-email", formData);
      // // console.log("reset------------", data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // // console.log("error", data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // const email = data?.data?.email;
        // localStorage.setItem("email-to-receive-otp", email);
        // await thunkAPI.dispatch(setUserEmail(email));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      const emailErr = err?.response?.data?.message;
      // console.log(err.response.data.message);
      if (emailErr) {
        if (emailErr === "Server Error") {
          toast.error(`${emailErr}`, {
            theme: "colored",
            toastId: customErrorId,
          });
          return;
        }
        toast.error(emailErr, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const resendForgetPassword = createAsyncThunk(
  "admin/resend-forget-password",
  async (payload, thunkAPI) => {
    try {
      // // console.log(payload);
      // return;
      const { data } = await axios.post("/resendForgotpasswordOpt", payload);
      // // console.log("resend------------", data);
      if (!data.status) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // // console.log("error" , data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status) {
        toast.success(`OTP sent to ${data?.data?.email}`, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(setUserEmail(email));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      const emailErr = err?.response?.data?.message;
      // console.log(err.response.data.message);
      if (emailErr) {
        // if(emailErr === "Server Error"){
        //   toast.info(`Check your mail, OTP already sent to email ${formData?.email}`, {
        //     theme: 'colored'
        //   });
        //   return;
        // }
        toast.error(emailErr, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ResetPasswordVerifyOtp = createAsyncThunk(
  "admin/reset-password-verify-otp",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post("/admin/verify-password-otp", formData);
      // // console.log('reset------------', data.data.email);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // // console.log("error" , data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      const emailErr = err?.response?.data?.message;
      // console.log(err.response.data.message);
      if (emailErr) {
        toast.error("Invalid OTP, check your email for valid OTP", {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ResetPassword = createAsyncThunk(
  "admin/reset-password",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post("/admin/reset-password", formData);
      // // console.log('reset------------', data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // // console.log("error" , data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ResetPasswordDashboard = createAsyncThunk(
  "admin/reset-password-dashboard",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/admin/user/password/update",
        formData
      );
      // // console.log('reset------------', data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log("error", data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // // console.log(err);
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const LogoutUser = createAsyncThunk(
  "admin/reset-password",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete("/admin/logout", formData);
      // // console.log('reset------------', data);
      if (!data.success) {
        // // console.log("not success", data);
        toast.success(data.message, {
          theme: "colored",
        });
        localStorage.clear();
        window.location.reload();
        // window.location.reload();
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // // console.log("not success", data);
        toast.success(data.message, {
          theme: "colored",
        });
        localStorage.clear();
        window.location.reload();
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getOrganizations = createAsyncThunk(
  "admin/get-countries",
  async (employee = false, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      let url = "";
      if (employee) {
        url = "employee/employee-organization";
      } else {
        url = "/admin/organizations";
      }
      const { data } = await axios.get(url);
      // // console.log(data);
      if (!data.success) {
      }
      if (data.success && !employee) {
        // // console.log(data.data.organizations);
        await thunkAPI.dispatch(setOrganization(data.data.organizations));
        return thunkAPI.rejectWithValue(data);
      } else if (data?.success && employee) {
        await thunkAPI.dispatch(setOrganization(data.data.user_organization));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getOrganizationsWithPagination = createAsyncThunk(
  "admin/get-countries",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `/admin/organizations?paginated=true&per_page=${formData}`
      );
      // // console.log(data.data.organizations);
      if (!data.success) {
      }
      if (data.success) {
        // // console.log(data.data.organizations);
        await thunkAPI.dispatch(setOrganizationMain(data.data.organizations));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getOrganizationsPagination = createAsyncThunk(
  "admin/get-countries",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(`${formData}`);
      // // console.log(data.data.organizations);
      if (!data.success) {
      }
      if (data.success) {
        // // console.log(data.data.organizations);
        await thunkAPI.dispatch(setOrganizationMain(data.data.organizations));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCountries = createAsyncThunk(
  "admin/get-countries",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get("/dropdowns/countries");
      // // console.log("country", data.data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data.success) {
        // // console.log(data);
        await thunkAPI.dispatch(setCountries(data.data));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getStates = createAsyncThunk(
  "admin/get-states",
  async (formData, thunkAPI) => {
    try {
      // const token = localStorage.getItem("token")
      // await setAuthToken(token);
      const { data } = await axios.get(`/dropdowns/states/${formData}`);
      // // console.log("country", data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data.success) {
        // // console.log(data.data);
        await thunkAPI.dispatch(setStates(data.data));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCurrencies = createAsyncThunk(
  "admin/get-countries",
  async (formData, thunkAPI) => {
    try {
      // const token = localStorage.getItem("token")
      // await setAuthToken(token);
      const { data } = await axios.get("/dropdowns/currencies");
      // console.log("currency", data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data.success) {
        // console.log(data.data);
        // await thunkAPI.dispatch(setCountries(data.data));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const setUpOrganization = createAsyncThunk(
  "admin/organization-set-up",
  async (formData, employee = false, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let url = "";
      if (employee) {
        url = "/admin/organizations";
      } else {
        url = "/employee/organizations";
      }
      const data = await axios.post(url, formData, config);
      // console.log('set-up-org', data);
      if (data?.data?.success === false) {
        toast.error(data.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // // console.log(data);
        // return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.success === true) {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        // // console.log(data);
        return data;
      }
    } catch (err) {
      // // console.log(err);
      if (err?.response?.status === 422) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      // toast.error(err.response.data.message, {
      //   theme: "colored",
      //  toastId: customErrorId,
      // });
      // if (err.message === "Network Error") {
      //   toast.info("Logo size should not exceed 500kb", {
      //     theme: "colored",
      //   });
      // }
    }
  }
);

export const updateUser = createAsyncThunk(
  "admin/organization-set-up",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      // // console.log(token);
      // return;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        "admin/user/profile/update",
        formData,
        config
      );
      // // console.log('set-up-org', data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // // console.log(err.message);
      // toast.error(err.response.data.message, {
      //   theme: "colored",
      //  toastId: customErrorId,
      // });
      if (err.message === "Network Error") {
        toast.info("Logo size should not exceed 500kb", {
          theme: "colored",
        });
      }
    }
  }
);

const addLabelValueFunc = (list) => {
  // // console.log(list);
  const newList = list.map((chi) => {
    return { ...chi, label: chi?.name, value: chi?.id };
  });
  // // console.log(newList);
  return newList;
};

export const user = createSlice({
  name: "user",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || { clearance: "support" },
    balances: [],
    isAuth: false,
    loading: false,
    loadingTable: false,
    selectLoading: false,
    userEmail: localStorage.getItem("email-to-receive-otp"),
    countries: [],
    states: [],
    currencies: [],
    organizations: [],
    organizationMain: [],
    links: {},
    meta: {},
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setUser: (state, action) => {
      state.isAuth = true;
      state.user = action.payload;
    },
    login: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      state.user = action.payload.user;
      state.isAuth = true;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = addLabelValueFunc(action.payload);
    },
    setStates: (state, action) => {
      state.states = addLabelValueFunc(action.payload);
    },
    setOrganization: (state, action) => {
      state.organizations = addLabelValueFunc(action.payload);
    },
    setOrganizationMain: (state, action) => {
      state.organizationMain = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    // logout: state => {
    //   delete axios.defaults.headers.common['Authorization'];
    //   localStorage.removeItem('token');
    //   state.user = null;
    //   state.isAuth = false;
    //   state.loading = false;
    //   state.balances = [];
    //   state = null;
    // }
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.loading = true;
    },
    [registerUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [registerUser.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [loginUser.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [VerifyUserEmail.pending]: (state) => {
      state.loading = true;
    },
    [VerifyUserEmail.fulfilled]: (state) => {
      state.loading = false;
    },
    [VerifyUserEmail.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [confirmReceivingEmail.pending]: (state) => {
      state.loading = true;
    },
    [confirmReceivingEmail.fulfilled]: (state) => {
      state.loading = false;
    },
    [confirmReceivingEmail.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [setUpOrganization.pending]: (state) => {
      state.loading = true;
    },
    [setUpOrganization.fulfilled]: (state) => {
      state.loading = false;
    },
    [setUpOrganization.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [ResetPasswordVerifyOtp.pending]: (state) => {
      state.loading = true;
    },
    [ResetPasswordVerifyOtp.fulfilled]: (state) => {
      state.loading = false;
    },
    [ResetPasswordVerifyOtp.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [ResetPassword.pending]: (state) => {
      state.loading = true;
    },
    [ResetPassword.fulfilled]: (state) => {
      state.loading = false;
    },
    [ResetPassword.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [ResetPasswordDashboard.pending]: (state) => {
      state.loading = true;
    },
    [ResetPasswordDashboard.fulfilled]: (state) => {
      state.loading = false;
    },
    [ResetPasswordDashboard.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getStates.pending]: (state) => {
      state.selectLoading = true;
    },
    [getStates.fulfilled]: (state) => {
      state.selectLoading = false;
    },
    [getStates.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.selectLoading = false;
      state.isAuth = false;
      state = null;
    },
    [LogoutUser.pending]: (state) => {
      state.loading = true;
    },
    [LogoutUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [LogoutUser.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getOrganizationsWithPagination.pending]: (state) => {
      state.loadingTable = true;
    },
    [getOrganizationsWithPagination.fulfilled]: (state) => {
      state.loadingTable = false;
    },
    [getOrganizationsWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTable = false;
      state.isAuth = false;
      state = null;
    },
    [getOrganizationsPagination.pending]: (state) => {
      state.loadingTable = true;
    },
    [getOrganizationsPagination.fulfilled]: (state) => {
      state.loadingTable = false;
    },
    [getOrganizationsPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTable = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setUser,
  setUserEmail,
  setCountries,
  setOrganization,
  setOrganizationMain,
  setStates,
} = user.actions;

export default user.reducer;
