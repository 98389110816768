import React, { useEffect, useState } from "react";
import "../../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
// import imgOne from "../../../../../../../assets/hod-two-img.svg";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  // ApproveExpenditureProjection,
  commentExpenditureProjection,
  // deleteExpenditueProjectionComment,
  getAllExpenditureProjectionComment,
  getUserDepartment,
  // setExpenditureProjectionComments,
} from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { Dna } from "react-loader-spinner";
import userImg from "../../../../../../../assets/Unknown_person.jpeg";
import moment from "moment";

// import Skeleton from "react-loading-skeleton";

const EmpExpProjectionComentModal = ({ onCancel, details, onGetAll }) => {
  const [lists, setLists] = useState({ comment: "" });
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { loading, expenditureProjectionComments } = useSelector(
    (state) => state.expenditure_projection
  );

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...lists, [name]: value };
    setLists(data);
  };


  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      status: "pending",
      comment: lists?.comment,
      user_id: user?.id,
      expenditure_id: details?.id,
    };
    const data = await dispatch(commentExpenditureProjection(obj));
    if (data?.payload?.success) {
      setLists({
        comment: "",
      });
      dispatch(getAllExpenditureProjectionComment(details?.id));
    }
  };

  // const handleDelete = async (param) => {
  //   console.log(param?.id);
  //   const obj = {
  //     id: details?.id,
  //     comment_id: param?.id,
  //   };
  //   const data = await dispatch(deleteExpenditueProjectionComment(obj));
  //   if (data?.payload?.success) {
  //     const newList = expenditureProjectionComments?.filter(
  //       (chi) => chi?.id !== param?.id
  //     );
  //     setExpenditureProjectionComments(newList);
  //   }
  //   dispatch(getAllExpenditureProjectionComment(details?.id));
  // };

  return (
    <div className="overview-card-modal-wrap emp-approval-modal">
      <div className="top-box">
        <p className="title">{`Comments (${details?.chart_of_account?.name})`}</p>
        <div
          onClick={() => {
            setLists((prev) => {
              return { ...prev, comment: "" };
            });
            onCancel();
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      <div className="content-box" style={{ overflowY: "visible" }}>
        <div className="img-input-wrap">
          <figure className="img-box">
            <img
              style={{
                borderRadius: "20%",
                width: "4rem",
                height: "4rem",
              }}
              src={user?.profile_picture || userImg}
              alt=""
              className="img"
            />
          </figure>
          <textarea
            placeholder="type here your comment"
            style={{ resize: "unset" }}
            name="comment"
            value={lists?.comment}
            onChange={handleChange}
            id="comment"
            cols="30"
            rows="5"
            className="input-text"
          ></textarea>
        </div>
        {/* btn box start */}
        {/* btn box end */}
      </div>

      <div className="bottom-box-two" style={{ display: "flex" }}>
        {expenditureProjectionComments?.map((chi, idx) => {
          const { comment, status, user, created_at } = chi;
          return (
            <>
              <div className="comment-wrap" key={idx}>
                <figure className="img-box">
                  <img
                    style={{
                      borderRadius: "20%",
                      width: "4rem",
                      height: "4rem",
                    }}
                    src={user?.profile_picture || userImg}
                    alt=""
                    className="img"
                  />
                </figure>
                <div className="box-wrap" style={{ marginLeft: "2rem" }}>
                  <div className="top-box-wrap">
                    <div className="box">
                      <p className="value">{user?.name}</p>
                      <p
                        style={{ marginBottom: "unset", fontSize: "1rem" }}
                        className="text"
                      >
                        {user?.email}
                      </p>
                      <p style={{ marginBottom: "unset" }} className="text">
                        {moment(created_at).format("YYYY-MM-DD HH:mm a")}
                      </p>
                    </div>
                    {status !== "pending" ? (
                      <div className="reject-box">
                        <p
                          style={{ color: status === "approved" && "#07A287" }}
                        >
                          {status}
                        </p>
                      </div>
                    ) : (
                      <div
                        className="reject-box"
                        style={{ visibility: "hidden" }}
                      >
                        <p
                          style={{ color: status === "approved" && "#07A287" }}
                        >
                          {status}
                        </p>
                      </div>
                    )}
                    {/* {loadingTwo ? (
                      <div
                        className="dna-wrapper-box"
                        style={{ alignSelf: "center", marginTop: "0rem" }}
                      >
                        <Skeleton width={"70%"} height={17} />
                      </div>
                    ) : (
                      <FaTrash
                        onClick={() => {
                          handleDelete(chi);
                        }}
                        className="delete-icon"
                      />
                    )} */}
                  </div>
                  {/* end of top box */}
                  <p className="comment">{comment}</p>
                </div>
              </div>
            </>
          );
        })}
        {/* comment wrap end */}
        {/* comment wrap start */}

        {/* comment wrap end */}
      </div>
      <div className="bottom-box">
        <ButtonBlue
          onClick={() => {
            setLists((prev) => {
              return { ...prev, comment: "" };
            });
            onCancel();
          }}
          text={`Cancel`}
        />
        {loading ? (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="40"
              width="100"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue
            text={`Add Comment `}
            bgColor={!lists?.comment && "#CCCC"}
            onClick={lists?.comment && handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default EmpExpProjectionComentModal;
