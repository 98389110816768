import React, { useContext, useEffect, useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
// import EmployeeDraftProjection from "../../projection/expense/EmpDraftProjection";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeCommonRevenueLayout from "./layout/CommonLayout";
// import EmployeeActualDraftExpense from "../actualDraftExpense";
import EmployeeActualRevenueDraftExpense from "./revenueDraftIndex";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteActualRevenue,
  getActualRevenueBySearch,
  getActualRevenuePagination,
  getActualRevenues,
  getSubmittedActualRevenue,
  submitActualRevenueDraftForApproval,
  submitSingleActualRevenueDraftForApproval,
} from "../../../../../../redux/employee/actual-revenue/actual-revenue";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmpActualRevenueDelete from "./layout/modals/deleteModal";
import Modal from "react-awesome-modal";
import EmployeeActualOverviewModal from "../modals/overviewModal";
import {
  formatNumber,
  perPageOptions,
  // FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import useDebounce from "../../../../../../helper/UseDebounce";
import { Dna, Oval } from "react-loader-spinner";
import ActionContext from "../../../../../../context/ActionContext";
import EmpActualRevSubmitForApproval from "./modal/submit";
import moment from "moment";
import { deleteExpenditueProjection } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";

const EmployeeActualRevenueIndex = ({ children }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [singleChild, setSingleChild] = useState({});
  // const [sortByVal, setSortByVal] = useState({ label: "Sort by" });
  const actionCtx = useContext(ActionContext);
  const { loadingPage, actualRevenues, loadingTwo, submittedActualRevenues } =
    useSelector((state) => state.actual_revenue);
  // const [perPageVal, setPerPageVal] = useState({ label: "Per Page" });

  const [refreshTable, setRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState({
    delete: false,
    overview: false,
    submit: false,
  });
  const [indexNo, setIndexNo] = useState("");
  const [search, setSearch] = useState("");

  // const SortByOptions = [
  //   { label: "Ascending", value: "ASC" },
  //   { label: "Descending", value: "DESC" },
  // ];
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Product", value: "product_id" },
    { label: "Vertical", value: "vertical_id" },
  ];
  const dispatch = useDispatch();
  const debouncedSearchTerm = useDebounce(search, 1000);

  const [showTable, setShowTable] = useState(false);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        q: search,
        filter_by: filterByVal?.value,
        // sort_by: sortByVal?.value,
        org_id: actionCtx?.activeOrg?.id,
        per_page: actionCtx?.perPage?.value,
      };
      setIndexNo(false);
      dispatch(getActualRevenueBySearch(obj));
      setShowTable(true);
    } else if (isMount && debouncedSearchTerm?.length < 1) {
      const obj = {
        org_id: actionCtx?.activeOrg?.id,
        per_page: actionCtx?.perPage?.value,
      };
      const data = dispatch(getActualRevenues(obj));
      if (data?.payload?.success) {
        setShowTable(false);
      }
    } else if (
      isMount &&
      debouncedSearchTerm?.length < 1 &&
      actualRevenues?.data?.length < 1
    ) {
      setShowTable(true);
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  // const handleSortChange = (param) => {
  //   const obj = {
  //     // q: search,
  //     sort_by: param?.value,
  //     // sort_by: sortByVal?.value,
  //     org_id: actionCtx?.activeOrg?.id,
  //     per_page: actionCtx?.perPage?.value,
  //   };
  //   // console.log("obj", obj)
  //   setIndexNo(false);
  //   dispatch(getActualRevenueBySearch(obj));
  // };

  useEffect(() => {
    user?.organizations?.length > 0 &&
      // dispatch(getActualRevenues(actionCtx?.activeOrg?.id));
      dispatch(getSubmittedActualRevenue({ org_id: actionCtx?.activeOrg?.id }));
    handleGetActualRevenueData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, actionCtx?.activeOrg?.id, actionCtx?.perPage?.value]);

  const handleFinish = (del = false) => {
    setIndexNo(false);
    setSingleChild({});
    actionCtx.setIsModalOut(false);
    setRefreshTable(!refreshTable);
    setShowModal((prev) => {
      return { ...prev, delete: false, submit: false };
    });
    if (del) {
      setShowModal((prev) => {
        return {
          ...prev,
          overview: false,
        };
      });
    }
  };

  const handleActualRevenueUpdate = (param, update = false) => {
    actionCtx?.setIsModalOut(false);
    localStorage.setItem("single-actual-revenue-id", param.id);
    history.push("/employee-dashboard-actual-revenue-update");
    if (update) {
      history.push("/employee-dashboard-actual-revenue-update");
    }
  };

  // const handlePagination = async (param) => {
  //   const splitLink = await param.split("/api");
  //   const linkTwo = splitLink[1];
  //   // console.log("linkTwo", linkTwo);
  //   setIndexNo(false);
  //   dispatch(getActualRevenuePagination(linkTwo));
  // };

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      // filter_submitted: false,
      filter: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: linkTwo,
    };
    setIndexNo(false);
    await dispatch(getActualRevenuePagination(obj));
  };
  const handlePaginationNum = async (num) => {
    // const splitLink = param.split("/api");
    // const splitLink = param.split("?page=");
    // const linkTwo = splitLink[1];
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      // filter_submitted: false,
      filter: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: num,
    };
    setIndexNo(false);
    await dispatch(getActualRevenuePagination(obj));
  };

  const [singleItem, setSingleItem] = useState({});
  const handleGetActualRevenueData = async () => {
    // const id = actionCtx?.activeOrg?.id;
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx?.perPage?.value,
    };
    const data = await dispatch(getActualRevenues(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };

  const cardValue = [
    singleItem?.total_projected || 0,
    singleItem?.total_projected_achieved || 0,
    // singleItem?.book_balance || 0,
    // singleItem?.available_balance || 0,
  ];

  const tableHeadList = [
    "Product",
    "Portfolio",
    "Units",
    "Unit Price",
    "Revenue",
    "GP",
    "GP %",
    "Date",
  ];

  const cardHeader = ["Projected Revenue", "Achieved Revenue"];
  // const cardValue = ["00.00", "00.00"];

  const link = ["/employee-dashboard-actual-revenue-form"];

  // multi select checkbox
  const [selectedSubmit, setSelectedSubmit] = useState([]);
  const [checkHeader, setCheckHeader] = useState(!true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmitDrafts = async () => {
    // const val = actualRevenues?.data?.map((chi) => String(chi?.id));
    const obj = {
      revenue_actual_ids: selectedSubmit,
    };
    setSubmitLoading(true);
    const data = await dispatch(submitActualRevenueDraftForApproval(obj));
    if (data?.payload?.success) {
      setSubmitLoading(false);
      handleFinish();
      history.push("/employee-dashboard-actual-revenue-submitted");
    } else {
      setSubmitLoading(false);
    }
  };
  const handleCheckSubmit = (id) => {
    const checkedList = selectedSubmit.indexOf(id);
    if (checkedList > -1) {
      setSelectedSubmit(selectedSubmit.filter((chi) => chi !== id));
    } else {
      setSelectedSubmit([...selectedSubmit, id]);
    }
    console.log(selectedSubmit, "check submit");
  };
  const handleAllSubmitCheck = (e) => {
    if (e.target.checked) {
      setSelectedSubmit(actualRevenues?.data?.map((chi) => chi.id));
    } else {
      setSelectedSubmit([]);
    }
    setCheckHeader(e.target.checked);
    console.log(selectedSubmit, "check submit");
  };

  // handleDeleteAll
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async () => {
    const obj = {
      multi: true,
      ids: selectedSubmit,
      projection_type: "actual_revenue",
    };
    setDeleteLoading(true);
    const data = await dispatch(deleteExpenditueProjection(obj));
    if (data?.payload?.success) {
      setDeleteLoading(false);
      handleFinish();
    } else {
      setDeleteLoading(false);
    }
  };

  const [showActionDropDown, setShowActionDropDown] = useState(false);

  return (
    <>
      {actualRevenues?.data?.length > 0 ||
      submittedActualRevenues?.data?.length > 0 ||
      showTable ? (
        <EmployeeCommonRevenueLayout
          commonNavLink={true}
          showCommonLink={actualRevenues?.data?.length < 1}
        >
          {(actualRevenues?.data?.length > 0 ||
            (debouncedSearchTerm?.length > 0 &&
              actualRevenues?.data?.length < 1) ||
            showTable) && (
            <EmployeeReusableTable
              submitAll
              onSubmitAll={() => {
                selectedSubmit?.length > 0 && handleSubmitDrafts();
              }}
              submitBgColor={selectedSubmit?.length < 1 && "#cccccc"}
              submitLoader={submitLoading}
              allActionBtn
              perPage
              optionPerPage={perPageOptions}
              setPerPage={(e) => {
                actionCtx?.setPerpage(e);
              }}
              perPageVal={actionCtx?.perPage}
              showDropDown={showActionDropDown}
              setShowDropDown={() => {
                setShowActionDropDown(!showActionDropDown);
                setIndexNo(false);
              }}
              filter
              // sort
              // optionSort={SortByOptions}
              // optionSortVal={sortByVal}
              // setOptionSortVal={(e) => {
              //   setIndexNo(false);
              //   setSortByVal(e);
              //   handleSortChange(e);
              // }}
              optionFilter={filterByOptions}
              optionFilterVal={filterByVal}
              setOptionFilterVal={(e) => {
                setIndexNo(false);
                setFilterByVal(e);
                // handleFil
              }}
              onSearchTable={(e) => {
                setIndexNo(false);
                setSearch(e);
                // console.log("e", e)
              }}
              cardTitleOne={cardHeader[0]}
              titleCardTwo={cardHeader[1]}
              cardBodyOne={formatNumber(String(cardValue[0] || 0))}
              cardBodyTwo={formatNumber(String(cardValue[1] || 0))}
              firstCard={true}
              secondCard={true}
              thirdCard
              fourthCard
              showCardThree={"hidden"}
              showCardFour={"hidden"}
              addButton
              BtnTitle={"new revenue"}
              showSearch
              onPage={() => history.push(link[0])}
              delBtn
              onDel={() => {
                selectedSubmit?.length > 0 && handleDelete();
              }}
              loading={loadingTwo}
              delLoader={deleteLoading}
              delBtnTitle={"Delete Expenditure"}
              delBgColor={selectedSubmit?.length > 0}
            />
          )}

          {/* table start  */}
          {children}
          <div className="table-bo">
            {/* table box start */}
            {(actualRevenues?.data?.length > 0 ||
              (debouncedSearchTerm?.length > 0 &&
                actualRevenues?.data?.length < 1) ||
              showTable) && (
              <ActualReuseableTable
                massSelectable={actualRevenues?.data?.length > 0}
                checkValue={checkHeader}
                onMassCheck={(e) => {
                  handleAllSubmitCheck(e);
                }}
                useNumPagination
                currentPage={actualRevenues?.current_page}
                totalTableLength={actualRevenues?.total}
                onNumPage={(e) => {
                  handlePaginationNum(e);
                }}
                dontShowBnSearch
                dontShowPagination={actualRevenues.data?.length < 1}
                nextPage={actualRevenues?.next_page_url}
                prevPage={actualRevenues?.prev_page_url}
                fromPage={actualRevenues?.from}
                toPage={actualRevenues?.to}
                onNextPage={() => {
                  handlePagination(actualRevenues?.next_page_url);
                  setShowActionDropDown(false);
                }}
                onPrevPage={() => {
                  handlePagination(actualRevenues?.prev_page_url);
                  setShowActionDropDown(false);
                }}
                totalPage={actualRevenues?.total}
                one={tableHeadList[0]}
                two={tableHeadList[1]}
                three={tableHeadList[2]}
                four={tableHeadList[3]}
                five={tableHeadList[4]}
                six={tableHeadList[5]}
                seven={tableHeadList[6]}
                eight={tableHeadList[7]}
                action
              >
                <>
                  {actualRevenues?.data?.map((chi, idx) => {
                    const {
                      product,
                      unit,
                      unit_price,
                      gross_revenue,
                      gp,
                      gross_profit_percent,
                      date,
                      portfolio,
                      id,
                    } = chi;
                    return (
                      <EmpTableBodyRow
                        checkBoxType
                        onCheck={(e) => {
                          handleCheckSubmit(id);
                        }}
                        checkValue={selectedSubmit.includes(id)}
                        checkId={`${id}-${idx}`}
                        item={chi}
                        key={idx}
                        one={product?.name}
                        three={formatNumber(String(unit))}
                        two={portfolio?.name || <></>}
                        four={formatNumber(String(unit_price || 0))}
                        five={formatNumber(String(gross_revenue || 0))}
                        six={formatNumber(String(gp || 0))}
                        seven={formatNumber(String(gross_profit_percent)) || 0}
                        eight={moment(date).format("YYYY-MM-DD")}
                        // status={status}
                        loading={loadingPage}
                        updateText={`Update`}
                        deleteText={`Delete`}
                        onDelete={() => {
                          setIndexNo(false);
                          setShowActionDropDown(false);
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, delete: true };
                          });
                        }}
                        updateTextTwo={"Submit for Approval"}
                        // onUpdateTwo={async (e) => {
                        //   e.preventDefault();
                        //   // dispatch submit projection action
                        //   // then redirect to the submission page

                        //   const dat = {
                        //     revenue_actual_ids: [chi?.id],
                        //   };

                        //   const data = await dispatch(
                        //     submitSingleActualRevenueDraftForApproval(dat)
                        //   );

                        //   if (data?.payload?.success) {
                        //     handleFinish(true);
                        //   }
                        // }}
                        onUpdateTwo={() => {
                          setIndexNo(false);
                          setShowActionDropDown(false);
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, submit: true };
                          });
                        }}
                        onUpdate={() => handleActualRevenueUpdate(chi)}
                        rowNo={idx}
                        indexNo={indexNo}
                        setIndexNo={() => {
                          setIndexNo(idx);
                          setShowActionDropDown(false);
                        }}
                        onOverview={() => {
                          setShowActionDropDown(false);
                          setIndexNo(false);
                          actionCtx?.setIsModalOut(true);
                          setShowModal((prev) => {
                            return { ...prev, overview: true };
                          });
                          setSingleChild(chi);
                        }}
                        action
                      />
                    );
                  })}
                </>
              </ActualReuseableTable>
            )}
            {/* table box end */}
            {/* {actualRevenues?.data?.length > 0 && (
            <div
              className="submit-all-draft-actual-box"
              style={{ padding: "5rem 0rem" }}
            >
              {loadingTwo ? (
                <div style={{ alignSelf: "center", marginLeft: "5rem" }}>
                  <Dna
                    visible={true}
                    height="40"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    selectedSubmit?.length > 0 && handleSubmitDrafts();
                  }}
                  text="Submit for approval"
                  bgColor={selectedSubmit?.length < 1 && "#cccccc"}
                  //
                />
              )}
            </div>
          )} */}
          </div>
        </EmployeeCommonRevenueLayout>
      ) : (
        <div style={{ marginTop: "20rem" }}>
          {loadingPage ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Oval
                height={80}
                width={80}
                color="var(--blue-color)"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--blue-color)"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <EmployeeActualRevenueDraftExpense>
              <ButtonBlue
                onClick={() =>
                  history.push("/employee-dashboard-actual-revenue-form")
                }
                text={`Create Revenue`}
              >
                <FaPlus style={{ marginRight: "15px" }} />
              </ButtonBlue>
            </EmployeeActualRevenueDraftExpense>
          )}
        </div>
      )}

      {/* modal start */}
      <Modal visible={showModal.delete}>
        <EmpActualRevenueDelete
          details={singleChild}
          onFinish={handleFinish}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}

      <Modal effect="fadeInLeft" visible={showModal.overview}>
        <EmployeeActualOverviewModal
          data={singleChild}
          // onViewBigImg={() => {
          //   setShowModal((prev) => {
          //     return { ...prev, overview: false, big_img: true };
          //   });
          // }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, overview: false };
            });
          }}
          handleSubmit={async () => {
            // e.preventDefault();
            // dispatch submit projection action
            // then redirect to the submission page

            const dat = {
              revenue_actual_ids: [singleChild?.id],
            };

            const data = await dispatch(
              submitSingleActualRevenueDraftForApproval(dat)
            );

            if (data?.payload?.success) {
              handleFinish(true);
              history.push("/employee-dashboard-actual-revenue-submitted");
            }
          }}
          onUpdate={() =>
            // history.push("/employee-dashboard-actual-revenue-form")
            {
              // console.log("chi", singleChild?.id);
              localStorage.setItem("single-actual-revenue-id", singleChild.id);
              history.push("/employee-dashboard-actual-revenue-update");
            }
          }
          onDelete={() => {
            //eslint-disable-next-line
            {
              // setSingleChild(chi);
              actionCtx?.setIsModalOut(true);
              setShowModal((prev) => {
                return { ...prev, delete: true, overview: false };
              });
            }
          }}
          details={singleChild}
        />
      </Modal>

      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.submit}>
        <EmpActualRevSubmitForApproval
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, submit: false };
            });
          }}
          details={singleChild}
          onFinish={handleFinish}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeActualRevenueIndex;
