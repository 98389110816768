import React, { useContext, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH, FaTimes } from "react-icons/fa";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import ProfileImg from "../../../../../../assets/Unknown_person.jpeg";
import userImg from "../../../../../../assets/Unknown_person.jpeg";
import {
  formatNumber,
  formatNumberWithComma,
  // FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import moment from "moment";
import EmployeeOverviewTableBodyRow from "../../../../../../employee-folder/component/pages/dashboard/home/modals/Reimbursement/overviewTableRow";
import ActionContext from "../../../../../../context/ActionContext";
// import html2canvas from "html2canvas";
import { useDispatch } from "react-redux";
import { getRevProjectionPdf } from "../../../../../../redux/projections/RevenueProjection";
import // approvedIcon,
// rejectIcon,
"../../../projection/expense/activityLogIcons";
import { getActualExpenseActivityLog } from "../../../../../../redux/actuals/expense";
import Skeleton from "react-loading-skeleton";

const OverviewCardExpenseModal = ({
  onCancel,
  onApprove,
  onReject,
  onComment,
  onViewBigImg,
  detail,
}) => {
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
    four: false,
  });
  const actionCtx = useContext(ActionContext);
  const [showActions, setShowActions] = useState(false);

  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
    if (param === "four") {
      setShowBox((prev) => {
        return { ...prev, four: !showBox.four };
      });
    }
  };

  const screenShotRef = useRef();

  const dispatch = useDispatch();
  const handleDowload = async () => {
    // html2canvas(screenShotRef.current).then((canvas) => {
    //   const link = document?.createElement("a");
    //   link.download = "expenditure_projection.png";
    //   link.href = canvas.toDataURL("image/png");
    //   link.click();
    // });

    const obj = {
      id: detail?.id,
      type: "actual_expense",
      cur_id: actionCtx?.currencyValue?.id || "",
    };
    const data = await dispatch(getRevProjectionPdf(obj));
    downloadCsv(data.payload.url);
    if (data?.payload?.status === 200) {
      downloadCsv(data.payload.data.url);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const [detailToShow, setDetailToShow] = useState("overview");

  const [logList, setLogList] = useState([]);
  const [loadingLog, setLoadingLog] = useState(false);
  const handleGetActivityLog = async (id) => {
    setLoadingLog(true);
    const obj = {
      id: id,
    };
    const data = await dispatch(getActualExpenseActivityLog(obj));
    if (data?.payload?.success) {
      setLoadingLog(false);
      setLogList(data?.payload?.data);
    }
  };

  return (
    <div className="overview-card-modal-wrap">
      <div
        onClick={() => {
          // console.log(detail);
        }}
        className="top-box"
      >
        <div className="title-box">
          <p
            onClick={() => {
              setDetailToShow("overview");
            }}
            className={`title ${detailToShow === "overview" && "title-active"}`}
          >
            Overview
          </p>
          <p
            onClick={() => {
              setDetailToShow("activity");
              handleGetActivityLog(detail?.id);
            }}
            className={`title ${detailToShow === "activity" && "title-active"}`}
          >
            Activity Log
          </p>
        </div>
        {detail?.status !== "approved" && detail?.current_approver && (
          <ButtonBlue onClick={onApprove} text={`Approve`} />
        )}
        <div
          onClick={() => {
            // console.log(detail);
            setShowActions(!showActions);
          }}
          className="ellipse-box"
        >
          <FaEllipsisH className="icon" />
          {/* action box start */}
          <div className={`action-box ${showActions && "action-box-active"}`}>
            {detail?.status !== "rejected" && (
              <p onClick={onReject}>
                <span>Reject</span>
              </p>
            )}
            <p onClick={onComment}>
              <span>View Comments</span>
            </p>
            <p
              onClick={() => {
                handleDowload();
                setShowActions(!showActions);
              }}
            >
              <span>Export as PDF</span>
            </p>
          </div>
          {/* action box end */}
        </div>
        <div
          onClick={() => {
            setShowActions(false);
            onCancel();
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      <div ref={screenShotRef} className="content-box">
        {detailToShow === "overview" && (
          <>
            {" "}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("one")}
                className="title-drop-control-box"
              >
                <p className="title">Budget Owner Details</p>
                <div className="icon-box">
                  {!showBox.one && <FaAngleDown className="icon" />}
                  {showBox.one && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.one && (
                <div className="content-wrap">
                  <div className="three-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Chart of Account</p>
                      <p className="value">{detail?.chart_of_account?.name}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Class</p>
                      <p className="value">{detail?.chart_of_account?.class}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Source</p>
                      <p className="value">
                        {detail?.is_zoho_expense === 0 ? "Revvex" : "Zoho"}
                      </p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="two-box">
                    <div className="box">
                      <p className="text">Units</p>
                      <p className="value">
                        {formatNumberWithComma(String(detail?.units))}
                      </p>
                    </div>
                    {/* box start */}
                    <div className="box">
                      <p className="text">Unit Price</p>
                      <p className="value">
                        {formatNumberWithComma(
                          String(detail?.unit_price || "0")
                        )}
                      </p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    {/* <div className="box">
                  <p className="text">Total Value</p>
                  <p className="value">20,000.00</p>
                </div> */}
                    {/* box end */}
                  </div>
                  <div className="one-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Description</p>
                      <p className="value">{detail?.description}</p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="two-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Expense Date</p>
                      <p className="value">
                        {moment(detail?.expense_date).format("YYYY-MM-DD")}
                      </p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    {/* <div className="box">
                  <p className="text">Last Updated</p>
                  <p className="value">26 May, 2022 - 7:08pm</p>
                </div> */}
                    {/* box end */}
                  </div>

                  {/* created by wrap start */}
                  <div className="one-box">
                    <div className="box">
                      <p className="text">Created by</p>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="img-text-box"
                      >
                        {/* {detail?.createdBy?.profile_picture && ( */}
                        <figure
                          style={{
                            marginRight: "1rem",
                            width: "2.5rem",
                            height: "2.5rem",
                          }}
                          className="img-box"
                          // style={{width: "2.5rem", height: "2.5rem"}}
                        >
                          <img
                            src={
                              detail?.createdBy?.profile_picture || ProfileImg
                            }
                            alt=""
                            className="img"
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </figure>
                        {/* )} */}
                        <div className="text-box">
                          <p className="value">
                            {detail?.createdBy?.full_name}
                          </p>
                          <p style={{ marginBottom: "unset" }} className="text">
                            {detail?.createdBy?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* created by wrap end */}
                  {/* image wrap box start */}
                  <div className="image-wrap-box">
                    {detail?.receipts?.length > 0 &&
                      detail?.receipts?.map((chi, idx) => {
                        const { original_url, mime_type } = chi;
                        // console.log(original_url);
                        if (original_url && !mime_type?.includes("image/")) {
                          return (
                            <div className="box box-file" key={idx}>
                              {/* <p className="type">File type: <span>{formatApplicationType(chi?.type)}</span></p> */}
                              <p className="name">
                                File name: <span>{chi?.file_name}</span>
                              </p>
                            </div>
                          );
                        }
                        return (
                          <div onClick={onViewBigImg} className="box-img">
                            <figure className="img-box">
                              <img src={original_url} alt="" className="img" />
                            </figure>
                          </div>
                          // <div
                          //   style={{
                          //     backgroundImage: original_url
                          //       ? `url(${original_url})`
                          //       : `url(${URL.createObjectURL(chi)})`,
                          //     width: "25rem",
                          //     //   backgroundColor: "red"
                          //   }}
                          //   key={idx}
                          //   className="box"
                          // ></div>
                        );
                      })}
                  </div>
                  {/* image wrap box end */}
                </div>
              )}
            </>
            {/* title drop control box end */}
            {/* title drop control box start */}
            {detail?.monthly_breakdown?.length > 0 && (
              <>
                <div
                  onClick={() => handleShowBox("two")}
                  className="title-drop-control-box"
                  style={{ marginTop: "2rem" }}
                >
                  <p className="title">Monthly Breakdown</p>
                  <div className="icon-box">
                    {!showBox.two && <FaAngleDown className="icon" />}
                    {showBox.two && <FaAngleUp className="icon" />}
                  </div>
                </div>
                {showBox.two && (
                  <div className="content-wrap">
                    {detail?.monthly_breakdown?.map((chi, idx) => {
                      const { month_name, value } = chi;
                      return (
                        <>
                          <EmployeeOverviewTableBodyRow
                            key={idx}
                            one={month_name}
                            two={formatNumber(String(value))}
                            three={formatNumber(String(detail?.total_value))}
                          />
                        </>
                      );
                    })}
                  </div>
                )}
              </>
            )}
            {/* title drop control box end */}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("three")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem" }}
              >
                <p className="title">Approval Flow</p>
                <div className="icon-box">
                  {!showBox.three && <FaAngleDown className="icon" />}
                  {showBox.three && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox?.three && (
                <div className="content-wrap" style={{borderBottom: "unset"}}>
                  {detail?.approval_flow?.map((chi, idx) => {
                    const { step, user, status } = chi;
                    return (
                      <>
                        <EmployeeOverviewTableBodyRow
                          key={idx}
                          one={step?.toString()?.padStart(2, "0")}
                          userOne
                          userName={user?.first_name + " " + user?.last_name}
                          userEmail={user?.email}
                          userImg={user?.profile_picture || userImg}
                          status={
                            status === "approved"
                              ? "Approved"
                              : status === "pending"
                              ? "Pending"
                              : status === "rejected"
                              ? "Rejected"
                              : "pending"
                          }
                        />
                      </>
                    );
                  })}
                </div>
              )}
            </>
            {/* title drop control box end */}
            {/* reimbursment  start */}
            <>
              <div
                onClick={() => handleShowBox("four")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem"}}
              >
                <p className="title">Reimbursement</p>
                <div className="icon-box">
                  {!showBox.three && <FaAngleDown className="icon" />}
                  {showBox.three && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox?.four && (
                <div className="content-wrap">
                  {detail?.reimbursement?.map((chi, idx) => {
                    const {
                      // step,
                      user,
                      status,
                      activities,
                      reimbursed_amount,
                    } = chi || {};
                    return (
                      <>
                        <EmployeeOverviewTableBodyRow
                          colorLess={true}
                          key={idx}
                          one={(idx + 1)?.toString()?.padStart(2, "0")}
                          userOne
                          userName={
                            activities?.length > 0
                              ? activities[0]?.causer?.full_name
                              : "---"
                          }
                          userEmail={status?.replace("_", " ")}
                          userImg={user?.profile_picture || userImg}
                          status={formatNumberWithComma(reimbursed_amount)}
                        />
                      </>
                    );
                  })}
                </div>
              )}
            </>
            {/* reimbursement end */}
          </>
        )}
        {/* activity log start -------- */}
        {detailToShow === "activity" && (
          <>
            <div className="activity-log-wrap-box">
              {/* box start */}
              {detail?.activities?.map((chi, idx) => {
                const { causer, title, type, created_at, icon } = chi;
                if (logList?.length < 1) {
                  return (
                    <div key={idx} className="log-box">
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                      <div className="img-wrap">
                        <>
                          <Skeleton width={35} height={35} circle />
                        </>
                      </div>
                      <div className="dash"></div>
                      {/* detail wrap start */}
                      <div className="log-detail-wrap">
                        <>
                          <Skeleton width={100} height={15} />
                        </>
                        <>
                          <Skeleton width={170} height={12} />
                        </>
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      </div>
                      {/* detail wrap end */}
                    </div>
                  );
                }
                return (
                  <div key={idx} className="log-box">
                    {loadingLog ? (
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                    ) : (
                      <div className="num">
                        <span>
                          {detail?.activities.length - idx < 10
                            ? `0${detail?.activities.length - idx}`
                            : detail?.activities.length - idx}
                        </span>
                      </div>
                    )}
                    <div className="img-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={35} height={35} circle />
                        </>
                      ) : (
                        <figure className="img-box">
                          <img src={icon} alt="" className="img" />
                        </figure>
                      )}
                    </div>
                    <div className="dash"></div>
                    {/* detail wrap start */}
                    <div className="log-detail-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={15} />
                        </>
                      ) : (
                        <p className="title">{title || "---"}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={170} height={12} />
                        </>
                      ) : (
                        <p className="initiator">{`${type || ""} by: ${
                          causer?.full_name
                        }`}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="date">
                          {moment(created_at).format("MM-DD-YYYY HH:mm:ss A")}
                        </p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="time">{created_at?.split(" ")[1]}</p>
                      )}
                    </div>
                    {/* detail wrap end */}
                  </div>
                );
              })}
              {/* box end */}
            </div>
          </>
        )}
        {/* activity log end ----------- */}
      </div>
      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default OverviewCardExpenseModal;
