import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import "./OwnerShipTransferModal.css";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import ActionContext from "../../../../../../context/ActionContext";
import { getUsers } from "../../../../../../redux/users/users";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import { Dna } from "react-loader-spinner";
import { assignOwnerShip } from "../../../../../../redux/role/role";

const OwnerShipTransferModal = ({ onCancel, onFinish }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    user_id: "",
  });
  const actionCtx = useContext(ActionContext);
  const { usersDrop } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.user);
  const {loading} = useSelector((state) => state.role)

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      dispatch(getUsers(actionCtx?.activeOrg?.id));
      setDetails((prev) => {
        return { ...prev, subsidiary_id: "", branch_id: "" };
      });
    }
    // eslint-disable-next-line
  }, [actionCtx?.activeOrg]);

  const handleSubmitUser = async () => {
    const obj = {
        user_id: details?.user_id
    }
    const data = dispatch(assignOwnerShip(obj))
    if(data?.payload?.success){
        onFinish()
    }
  };

  return (
    <div className="ownership-transfer-modal-wrap">
      <div onClick={onCancel} className="cancel-box">
        <FaTimes className="icon" />
      </div>
      <div className="wrap">
        <p className="heading">Transfer ownership</p>
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Users
          </label>
          <Select
            placeholder="Select user"
            className="select "
            styles={reactSelectStyle}
            value={details.user_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, user_id: e };
              });
            }}
            options={usersDrop}
          />
          {/* btn startv */}
          {/* btn end */}
        </div>
        {!loading ? (
          <ButtonBlue onClick={handleSubmitUser} text="submit" />
        ) : (
          <div style={{ alignSelf: "flex-end" }}>
            <Dna
              visible={true}
              height="70"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
        {/* group box end */}
      </div>
    </div>
  );
};

export default OwnerShipTransferModal;
