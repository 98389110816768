/* eslint-disable no-dupe-keys */
import React, { useState } from "react";
import { FaAngleRight, FaCheck, FaEllipsisV, FaPlus } from "react-icons/fa";
// import { Button, Dropdown } from "react-bootstrap";
import "../../../../styles/dashboard/tables/ActualReuseableTable.css";
import ProgressBar from "../../../../component/fragments/ProgressBar";
import "../../../styles/dashboard/table/EmployeeReusableTable.scss";
import Skeleton from "react-loading-skeleton";
import { Dna } from "react-loader-spinner";

const EmpTableBodyRow = ({
  one,
  two,
  twoSpan,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirtheeen,
  action,
  status,
  imageData,
  Freenum,
  indexNo,
  rowNo,
  setIndexNo,
  updateText,
  onUpdate,
  deleteText,
  onDelete,
  approveText,
  onApprove,
  viewText,
  onView,
  exportText,
  onExport,
  donstShowStatusCircle,
  invoice,
  onDownLoadInvoice,
  progressType,
  user,
  onNextPage,
  userOne,
  onOverview,
  onRejectStatus,
  onAcceptStatus,
  updateTextTwo,
  onUpdateTwo,
  deleteTextTwo,
  onDeleteTwo,
  onApproveTwo,
  rejectAll,
  onRejectAll,
  rejectAllText,
  recallText,
  onRecall,
  updateTextThree,
  onUpdateThree,
  reimburseText,
  onReimburse,
  onSelect,
  item,
  selectable,
  loading,
  rejectedText,
  onRejected,
  checkBoxType,
  onCheck,
  checkValue,
  checkId,
  exportLoader,
  statusStep,
  statusList,
}) => {
  const [showDropAction, setShowDropAction] = useState(false);

  return (
    <>
      <tr
        style={{
          zIndex: showDropAction && rowNo === indexNo ? "3" : "1",
          cursor: onNextPage || action || onOverview ? "pointer" : "",
          // cursor: action ? "pointer" : "",
          // cursor: onOverview ? "pointer" : "",
        }}
        className="emp-row-pointe"
        onClick={() => {
          // onOverview();
          // setShowDropAction(false);
          onNextPage && onNextPage();
        }}
      >
        <td
        // onClick={() => {
        //   onOverview();
        //   setShowDropAction(false);
        //   onNextPage();
        // }}
        // onClick={onOverview}
        >
          {checkBoxType ? (
            <div className="table-check-wrap-box">
              <input
                type="checkbox"
                name={`table-check-${checkId}`}
                id={`table-check-${checkId}`}
                className="table-check-input-row"
                value={checkValue}
                onChange={(e) => {
                  onCheck(e);
                  // console.log(checkId);
                }}
                checked={checkValue}
              />
              {loading ? (
                <Skeleton width={17} height={17} />
              ) : (
                <label
                  className="table-check-label-input-row"
                  htmlFor={`table-check-${checkId}`}
                >
                  <FaCheck className="icon" />
                </label>
              )}
            </div>
          ) : (
            <span></span>
          )}
        </td>
        <td>
          {loading ? (
            <Skeleton width={17} height={17} />
          ) : (
            <span>{rowNo + 1 < 10 ? `0${rowNo + 1}` : rowNo + 1}</span>
          )}
        </td>
        {one && (
          <td
            // onClick={() => {
            //   // onOverview();
            //   // setShowDropAction(false);
            //   // onNextPage();
            //   onRejectStatus()
            // }}
            onClick={(e) => {
              e.preventDefault();
              progressType === "rejected"
                ? onRejectStatus()
                : progressType === "approved"
                ? onAcceptStatus()
                : progressType === "partial_payment"
                ? onAcceptStatus()
                : progressType === "full_payment"
                ? onAcceptStatus()
                : onOverview();
            }}
            style={{ color: Freenum ? "#5552D9" : "" }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : one}
          </td>
        )}
        {userOne && (
          <td
          // onClick={() => {
          //   onNextPage();
          //   setShowDropAction(false);
          // }}
          >
            <div className="user-box">
              <figure className="img-box">
                <img src={userOne?.img} alt="" className="img" />
              </figure>
              <p className="text">{userOne?.name}</p>
            </div>
          </td>
        )}
        {two && (
          <td
            // onClick={() => {
            //   onOverview();
            //   onNextPage();
            //   setShowDropAction(false);
            // }}
            onClick={() => {
              progressType === "rejected"
                ? onRejectStatus()
                : progressType === "approved"
                ? onAcceptStatus()
                : progressType === "partial_payment"
                ? onAcceptStatus()
                : progressType === "full_payment"
                ? onAcceptStatus()
                : onOverview();
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : two}
          </td>
        )}
        {twoSpan && (
          <td
            // onClick={() => {
            //   onOverview();
            //   onNextPage();
            //   setShowDropAction(false);
            // }}
            onClick={() => {
              progressType === "rejected"
                ? onRejectStatus()
                : progressType === "approved"
                ? onAcceptStatus()
                : progressType === "partial_payment"
                ? onAcceptStatus()
                : progressType === "full_payment"
                ? onAcceptStatus()
                : onOverview();
            }}
            style={{ color: "var(--blue-color)" }}
          >
            {twoSpan}
          </td>
        )}
        {three && (
          <td
            // onClick={() => {
            //   onOverview();
            //   onNextPage();
            //   setShowDropAction(false);
            // }}
            onClick={() => {
              progressType === "rejected"
                ? onRejectStatus()
                : progressType === "approved"
                ? onAcceptStatus()
                : progressType === "partial_payment"
                ? onAcceptStatus()
                : progressType === "full_payment"
                ? onAcceptStatus()
                : onOverview();
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : three}
          </td>
        )}
        {four && (
          <td
            // onClick={() => {
            //   onOverview();
            //   onNextPage();
            //   setShowDropAction(false);
            // }}
            onClick={() => {
              progressType === "rejected"
                ? onRejectStatus()
                : progressType === "approved"
                ? onAcceptStatus()
                : progressType === "partial_payment"
                ? onAcceptStatus()
                : progressType === "full_payment"
                ? onAcceptStatus()
                : onOverview();
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : four}
          </td>
        )}
        {user && (
          <td
            onClick={() => {
              onNextPage();
              setShowDropAction(false);
            }}
          >
            <div className="user-box">
              <figure className="img-box">
                <img src={user?.img} alt="" className="img" />
              </figure>
              <p className="text">{user.name}</p>
            </div>
          </td>
        )}
        {five && (
          <td
            // onClick={() => {
            //   onOverview();
            //   onNextPage();
            //   setShowDropAction(false);
            // }}
            onClick={() => {
              progressType === "rejected"
                ? onRejectStatus()
                : progressType === "approved"
                ? onAcceptStatus()
                : progressType === "partial_payment"
                ? onAcceptStatus()
                : progressType === "full_payment"
                ? onAcceptStatus()
                : onOverview();
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : five}
          </td>
        )}
        {six && (
          <td
            // onClick={() => {
            //   onOverview();
            //   onNextPage();
            //   setShowDropAction(false);
            // }}
            onClick={() => {
              progressType === "rejected"
                ? onRejectStatus()
                : progressType === "approved"
                ? onAcceptStatus()
                : progressType === "partial_payment"
                ? onAcceptStatus()
                : progressType === "full_payment"
                ? onAcceptStatus()
                : onOverview();
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : six}
          </td>
        )}

        {status && (
          <td
            onClick={() => {
              onOverview();
              onNextPage();
              setShowDropAction(false);
            }}
          >
            <p
              style={{
                color:
                  status.toLowerCase() === "active"
                    ? "#07A287"
                    : status.toLowerCase() === "successful"
                    ? "#07A287"
                    : "#FCA549",
              }}
              className={`body-text `}
            >
              {!donstShowStatusCircle && <span></span>}
              {loading ? <Skeleton width={"70%"} height={17} /> : status}
            </p>
          </td>
        )}
        {/* {statusTwo && (
          <td
            onClick={() => {
              onOverview();
              onNextPage();
              setShowDropAction(false);
            }}
          >
            <p
              style={{
                color:
                  status.toLowerCase() === "active"
                    ? "#07A287"
                    : status.toLowerCase() === "successful"
                    ? "#07A287"
                    : "#FCA549",
              }}
              className={`body-text `}
            >
              {!donstShowStatusCircle && <span></span>}
              {loading ? <Skeleton width={"70%"} height={17} /> : status}
            </p>
          </td>
        )} */}
        {seven && (
          <td
            // onClick={() => {
            //   onOverview();
            //   onNextPage();
            //   setShowDropAction(false);
            // }}
            onClick={() => {
              progressType === "rejected"
                ? onRejectStatus()
                : progressType === "approved"
                ? onAcceptStatus()
                : progressType === "partial_payment"
                ? onAcceptStatus()
                : progressType === "full_payment"
                ? onAcceptStatus()
                : onOverview();
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : seven}
          </td>
        )}
        {invoice && (
          <td
            onClick={() => {
              onNextPage();
              setShowDropAction(false);
            }}
          >
            <div
              style={{ display: "flex", justifyContent: "center" }}
              className=""
            >
              {" "}
              <span
                onClick={() => {
                  onDownLoadInvoice();
                }}
                style={{
                  color: "#004BFF",
                  // backgroundColor: "red",
                  cursor: "pointer",
                }}
              >
                Download Invoice
              </span>
            </div>
          </td>
        )}
        {eight && (
          <td
            onClick={() => {
              onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : eight}
          </td>
        )}
        {loading && !statusStep ? (
          <Skeleton width={"70%"} height={17} />
        ) : (
          progressType &&
          !statusStep && (
            <td
              // onClick={() => {
              //   // onOverview();
              //   onNextPage();
              //   setShowDropAction(false);
              // }}
              // progressType === "rejected" ? onRejectStatus : "";
              onClick={
                progressType === "rejected"
                  ? onRejectStatus
                  : progressType === "approved"
                  ? onAcceptStatus
                  : onOverview
              }
            >
              <div className="progress-text-wrap">
                <div className="progress-wrap">
                  {/* <ProgressBar
                    bgcolor={
                      progressType === "rejected"
                        ? "#CC0905"
                        : progressType === "approved"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  />
                  {/* <ProgressBar
                    bgcolor={
                      progressType === "rejected"
                        ? "#CC0905"
                        : progressType === "approved"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  /> */}
                  {/* <ProgressBar
                    bgcolor={
                      progressType === "rejected"
                        ? "#CC0905"
                        : progressType === "approved"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  /> */}
                  {/* <ProgressBar
                    bgcolor={
                      progressType === "rejected"
                        ? "#CC0905"
                        : progressType === "approved"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  /> */}
                  <ProgressBar
                    bgcolor={
                      progressType == "rejected"
                        ? "#CC0905"
                        : progressType === "partial_payment"
                        ? "#07A287"
                        : progressType === "full_payment"
                        ? "#07A287"
                        : progressType === "approved"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  />
                </div>
                <p
                  className="text"
                  // style={{
                  //   display: "flex",
                  //   textAlign: "center",
                  //   justifyContent: "center",
                  // }}
                >
                  {progressType === "rejected"
                    ? "Rejected"
                    : progressType === "approved"
                    ? "Approved"
                    : progressType === "partial_payment"
                    ? "Approved (Partial Payment)"
                    : progressType === "full_payment"
                    ? "Approved (Full Payment)"
                    : "Pending"}
                </p>
              </div>
            </td>
          )
        )}
        {loading && statusStep ? (
          <Skeleton width={"70%"} height={17} />
        ) : (
          statusStep && (
            <td
              // onClick={() => {
              //   // onOverview();
              //   onNextPage();
              //   setShowDropAction(false);
              // }}
              // progressType === "rejected" ? onRejectStatus : "";
              onClick={
                progressType === "rejected"
                  ? onRejectStatus
                  : progressType === "approved"
                  ? onAcceptStatus
                  : onOverview
              }
            >
              <div className="progress-text-wrap">
                <div className="progress-wrap">
                  {statusList?.map((chi, idx) => {
                    return (
                      <div key={idx} className="status-progress-box">
                        {/* name box start */}
                        <div className="name-box">
                          <p
                            style={{ color: "var(--blue-color)" }}
                            className="name"
                          >{`${chi?.first_name} ${chi?.last_name}`}</p>
                        </div>
                        {/* name box end */}
                        <ProgressBar
                          bgcolor={
                            chi?.status == "rejected"
                              ? "#CC0905"
                              : chi?.status === "partial_payment"
                              ? "#07A287"
                              : chi?.status === "full_payment"
                              ? "#07A287"
                              : chi?.status === "approved"
                              ? "#07A287"
                              : "#FCA549"
                          }
                          completed={100}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </td>
          )
        )}

        {nine && (
          <td
            onClick={() => {
              onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : nine}
          </td>
        )}
        {imageData && (
          <td
            onClick={() => {
              onNextPage();
              setShowDropAction(false);
            }}
            className="img-wrap"
          >
            {imageData?.map((chi, idx) => {
              const { img } = chi;
              if (idx === 0 || idx === 1) {
                return (
                  <figure key={idx} className="img-box">
                    <img src={img} alt="" className="img" />
                  </figure>
                );
              }
              return <React.Fragment key={idx}></React.Fragment>;
            })}
            {imageData.length > 2 && (
              <div className="other">
                <FaPlus className="icon" /> {imageData.length - 2}
              </div>
            )}
          </td>
        )}
        {ten && (
          <td
            onClick={() => {
              onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : ten}
          </td>
        )}
        {twelve && (
          <td
            onClick={() => {
              onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : twelve}
          </td>
        )}
        {thirtheeen && (
          <td
            onClick={() => {
              onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : thirtheeen}
          </td>
        )}
        {action && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
            }}
          >
            {/* wrpa start */}
            <div className="drop-wrap">
              {onNextPage ? (
                <FaAngleRight className="drop-icon" />
              ) : (
                <FaEllipsisV
                  className="drop-icon"
                  onClick={() => {
                    setIndexNo();
                    indexNo === rowNo
                      ? setShowDropAction(!showDropAction)
                      : setShowDropAction(true);
                  }}
                />
              )}
              <div
                className={`drop-box ${
                  showDropAction && indexNo === rowNo && "drop-box-show"
                }`}
              >
                {rejectAllText && progressType === "approved" && (
                  <p
                    style={{
                      width: "110%",
                      color: "#CC0905",
                      // justifyContent: "center",
                    }}
                    onClick={onRejectAll}
                    className="text"
                  >
                    {rejectAllText}
                  </p>
                )}
                {rejectedText && progressType === "approved" && (
                  <p
                    style={{
                      width: "110%",
                      color: "#CC0905",
                      justifyContent: "center",
                    }}
                    onClick={onRejected}
                    className="text"
                  >
                    {rejectedText}
                  </p>
                )}
                {updateText && progressType !== "approved" && (
                  <p onClick={onUpdate} className="text">
                    {updateText}
                  </p>
                )}
                {updateTextTwo && (
                  <p
                    onClick={onUpdateTwo}
                    style={{ width: "110%" }}
                    className="text"
                  >
                    {updateTextTwo}
                  </p>
                )}
                {updateTextThree && progressType === "rejected" && (
                  <p onClick={onUpdateThree} className="text">
                    {updateTextThree}
                  </p>
                )}
                {recallText &&
                  progressType !== "approved" &&
                  progressType !== "reject" &&
                  progressType !== "partial_payment" &&
                  progressType !== "full_payment" && (
                    <p onClick={onRecall} className="text">
                      {recallText}
                    </p>
                  )}
                {approveText && progressType !== "approved" && (
                  <p
                    style={{ color: "#119C2B" }}
                    onClick={onApprove}
                    className="text"
                  >
                    {approveText}
                  </p>
                )}
                {viewText && (
                  <p onClick={onView} className="text">
                    {viewText}
                  </p>
                )}
                {reimburseText &&
                  (progressType === "partial_payment" ||
                    progressType === "full_payment" ||
                    progressType === "approved") && (
                    <p
                      style={{ color: "#07A287" }}
                      onClick={onReimburse}
                      className="text"
                    >
                      {reimburseText}
                    </p>
                  )}
                {deleteText &&
                  progressType !== "approved" &&
                  progressType !== "reject" && (
                    <p
                      style={{ color: "#CC0905" }}
                      onClick={onDelete}
                      className="text"
                    >
                      {deleteText}
                    </p>
                  )}
                {deleteTextTwo && (
                  <p
                    style={{ color: "#CC0905" }}
                    onClick={onDeleteTwo}
                    className="text"
                  >
                    {deleteTextTwo}
                  </p>
                )}
                {exportText && (
                  <div>
                    {exportLoader ? (
                      <div style={{ alignSelf: "center", display: "flex" }}>
                        <Dna
                          visible={true}
                          height="40"
                          width="30"
                          ariaLabel="dna-loading"
                          // wrapperStyle={{color: "red", backgroundColor : "red"}}
                          wrapperClass="dna-wrapper"
                        />
                      </div>
                    ) : (
                      <p onClick={onExport} className="text">
                        {exportText}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* wrpa end */}
          </td>
        )}
      </tr>
    </>
  );
};

export default EmpTableBodyRow;
