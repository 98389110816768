import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight, FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import groupImg from "../../../../../assets/group-img.svg";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import Modal from "react-awesome-modal";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../context/ActionContext";
import {
  getGroupsByFilter,
  getGroupsPagination,
  getGroupsWithPagination,
} from "../../../../../redux/group/group";
import useDebounce from "../../../../../helper/UseDebounce";
import DeleteGroupModal from "./DeleteGroupModal";

const GroupSettings = () => {
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { loadingPage, groups, meta, links } = useSelector(
    (state) => state.group
  );
  const actionCtx = useContext(ActionContext);
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "Name", value: "name" },
    { label: "Email", value: "email" },
    { label: "Department", value: "department" },
    { label: "Subsidiary", value: "subsidiary" },
    { label: "Status", value: "status" },
    { label: "Role", value: "role" },
  ];
  const [indexNo, setIndexNo] = useState("");
  const tableHeadList = [
    "Group Name",
    "Departments",
    "Status",
    "HOG",
    "Action",
  ];
  // const tableBodyList = [
  //   {
  //     name: "Product & Sales",
  //     subsidiary: "Zojatech",
  //     Status: "Active",
  //     hod: [{ img: hodOne }, { img: hodTwo }, { img: hodTwo }],
  //     action: "",
  //   },
  //   {
  //     name: "Product & Sales",
  //     subsidiary: "Zojatech",
  //     Status: "inactive",
  //     hod: [{ img: hodOne }, { img: hodTwo }, { img: hodOne }, { img: hodTwo }],
  //     action: "",
  //   },
  // ];

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      dispatch(getGroupsWithPagination(obj));
    }
      // eslint-disable-next-line
  }, [refreshTable]);

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getGroupsPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    user?.organizations?.length > 0 && dispatch(getGroupsWithPagination(obj));
  };

  const handleFinish = () => {
    setSingleChild({});
    setRefreshTable(!refreshTable);
    setShowDeleteModal(false);
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: filterByVal?.value || "",
        org_id: actionCtx?.activeOrg?.id,
      };
      dispatch(getGroupsByFilter(obj));
    }

    return () => {
      isMount = false;
    };

      // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      {/* <DashboardLayout
        goBack
        //   pageTitleSpan={`Step 4 of 6`}
        pageTitle={`Add Department`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={
              () => console.log(groups)
              // history.push(
              //   "/dashboard-administration-admin-settings-subsidaries"
              // )
            }
            className="active text"
          >
            Group
          </p>
          {/* <FaAngleRight className="icon" />
          <p className="active text">Add Subsidiaries</p> */}
        </div>

        {/* table box start */}
        <ActualReuseableTable
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          perPageValue={actionCtx?.perPage?.value}
          setPerPageValue={(e) => {
            // setPerpageVal(e);
            handleSortPerPage(e.value);
          }}
          fromPage={meta?.from || 1}
          toPage={meta?.to || groups?.length}
          totalPage={meta?.total || groups?.length}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          tableTitle={`Group`}
          tableTitleSpan={`(1)`}
          addBtntext={`New Group`}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          action
          onCreateNew={() =>
            history.push("/dashboard-administration-admin-settings-group-add")
          }
          loading={loadingPage && groups?.length < 1}
          loadingNum={6}
        >
          {groups?.data?.map((chi, idx) => {
            const { name, departments, is_active, head_of_group } = chi;
         
            const departVal = `${departments[0].name} ${
              departments.length > 1 ? `, + ${departments?.length - 1}` : ""
            }`;
            return (
              <TableBodyRow
                key={idx}
                one={name}
                two={departVal}
                status={is_active === 1 ? "active" : "inactive"}
                ten={head_of_group?.full_name}
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                loading={loadingPage}
                action
                deleteText={`delete group`}
                onDelete={() => {
                  setSingleChild(chi);
                  setShowDeleteModal(true);
                }}
                updateText={`edit group`}
                viewText={`view group`}
                onView={() => {
                  localStorage.setItem("group_id", chi?.id);
                  history.push(
                    "/dashboard-administration-admin-settings-group-view"
                  );
                }}
                onUpdate={() => {
                  localStorage.setItem("group_id", chi?.id);
                  history.push(
                    "/dashboard-administration-admin-settings-group-edit"
                  );
                }}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}

        {groups?.data?.length < 1 && (
          <div className="group-budget-wrap">
            <div className="content-index-wrap">
              <figure className="img-box">
                <img src={groupImg} alt="" className="img" />
              </figure>
              <div className="text-box">
                <p className="text">
                  You currently do not have any saved group . Click on the
                  button
                </p>
                <p className="text"> below to create or start a group.</p>
              </div>
              <ButtonBlue
                onClick={() =>
                  history.push(
                    "/dashboard-administration-admin-settings-group-add"
                  )
                }
                text={`Create First Group`}
              >
                <FaPlus />
              </ButtonBlue>
            </div>
          </div>
        )}
      </div>
      {/* </DashboardLayout> */}
      {/* modal start  */}
      <Modal effect="fadeInUp" visible={showDeleteModal}>
        <DeleteGroupModal
          details={singleChild}
          onClose={() => {
            setSingleChild({});
            setShowDeleteModal(false);
          }}
          onFinish={handleFinish}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default GroupSettings;
