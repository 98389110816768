import EmployeeGeneralReportIndex from "../../../component/pages/dashboard/general/report";
import EmployeeActualExpenseReportIndex from "../../../component/pages/dashboard/general/report/actualsContent/actualExpense";
import EmployeeActualRevenueReportIndex from "../../../component/pages/dashboard/general/report/actualsContent/actualRevenue";
import EmployeeRecurrentAndCapitalExpenseReportIndex from "../../../component/pages/dashboard/general/report/actualsContent/recurrentAndCapitalExpense";
import EmployeeActualRevenueVsActualGpReportIndex from "../../../component/pages/dashboard/general/report/businessOverview/actualRevenueVsActualGp";
import EmployeeCashFlowReportIndex from "../../../component/pages/dashboard/general/report/businessOverview/cashFlow";
import EmployeeExpenseProjectionVsGpProjectionReportIndex from "../../../component/pages/dashboard/general/report/businessOverview/expenseProjectionVsGpProjection";
import EmployeeProjectionVsActualGPReportIndex from "../../../component/pages/dashboard/general/report/businessOverview/projectedVsActualGp";
import EmployeeConsolidatedBudgetReportIndex from "../../../component/pages/dashboard/general/report/consolidated";
import EmployeeGeneralCustomReport from "../../../component/pages/dashboard/general/report/customReports";
import EmployeeCreateReportFormIndex from "../../../component/pages/dashboard/general/report/form";
import EmployeeBudgetPerformanceReportIndex from "../../../component/pages/dashboard/general/report/projectionData/budgetPerformance";
import EmployeeBudgetVarianceReportIndex from "../../../component/pages/dashboard/general/report/projectionData/budgetVariance";
import EmployeeExpenseProjectionReportIndex from "../../../component/pages/dashboard/general/report/projectionData/ExpenseProjection";
import EmployeeRecurrentAndCapitalBudgetReportIndex from "../../../component/pages/dashboard/general/report/projectionData/recurrentAndCapitalBudget";
import EmployeeRevenueProjectionReportIndex from "../../../component/pages/dashboard/general/report/projectionData/reveneueProjetion";
import EmployeeReveuePerformanceReportIndex from "../../../component/pages/dashboard/general/report/projectionData/revenuePerformance";
import EmployeeRevenueVarianceReportIndex from "../../../component/pages/dashboard/general/report/projectionData/revenueVariance";

export const Employee_dashboard_general_report_route_group = [
  {
    path: "/employee-dashboard-general-report",
    component: EmployeeGeneralReportIndex,
    pageTitle: "Reports",
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-custom",
    component: EmployeeGeneralCustomReport,
    pageTitle: "Reports",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-consolidated-budget",
    component: EmployeeConsolidatedBudgetReportIndex,
    pageTitle: "View Consolidated Budget Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-create",
    component: EmployeeCreateReportFormIndex,
    pageTitle: "Reports",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-revenue-projection",
    component: EmployeeRevenueProjectionReportIndex,
    pageTitle: "View Revenue Projection Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-expense-projection",
    component: EmployeeExpenseProjectionReportIndex,
    pageTitle: "View Expense Projection Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-budget-performance",
    component: EmployeeBudgetPerformanceReportIndex,
    pageTitle: "View Budget Performance Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-revenue-performance",
    component: EmployeeReveuePerformanceReportIndex,
    pageTitle: "View Revenue Performance Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-budget-variance",
    component: EmployeeBudgetVarianceReportIndex,
    pageTitle: "View Budget Variance Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-revenue-variance",
    component: EmployeeRevenueVarianceReportIndex,
    pageTitle: "View Revenue Variance Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-recurrent-and-capital-budget",
    component: EmployeeRecurrentAndCapitalBudgetReportIndex,
    pageTitle: "View Recurrent and Capital Budget Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-actual-revenue",
    component: EmployeeActualRevenueReportIndex,
    pageTitle: "View Actual Revenue Reports",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-actual-expense",
    component: EmployeeActualExpenseReportIndex,
    pageTitle: "View Actual Expense Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-recurrent-and-capital-expense",
    component: EmployeeRecurrentAndCapitalExpenseReportIndex,
    pageTitle: "View Recurrent and Capital Expense Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-projection-vs-actual-gp",
    component: EmployeeProjectionVsActualGPReportIndex,
    pageTitle: "View Projections Vs. Actual GP Report",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-general-report-projection-vs-actual-revenue-and-actual-gp",
    component: EmployeeActualRevenueVsActualGpReportIndex,
    pageTitle: "View Projections Vs. Actual Revenue & Actual GP Report",
    goBack: true,
    organization: true,
  },
  {
    path: "/employee-dashboard-general-report-expense-projections-vs-gp-projections",
    component: EmployeeExpenseProjectionVsGpProjectionReportIndex,
    pageTitle: "View Expense Projection Vs. GP Projection Report",
    goBack: true,
    organization: true,
  },
  {
    path: "/employee-dashboard-general-report-cash-flow",
    component: EmployeeCashFlowReportIndex,
    pageTitle: "View Cashflow Report",
    organization: true,
    goBack: true,
  },
];
