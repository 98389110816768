import React from "react";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
// import { FaPlus } from "react-icons/fa";
// import EmployeeDraftProjection from "../../projection/expense/EmpDraftProjection";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeSupplementaryLayout from "./links/CommonLayout";
import TableBodyRow from "../../../../../../component/tables/TableBodyRow";
// import EmployeeCommonRevenueLayout from "./layout/CommonLayout";

const EmployeeSupplementaryDraftTable = ({ children }) => {
  const history = useHistory();

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "New Price",
    "Approved Price",
    "Unit Price",
    "Date",
  ];

  const tableBodyList = [
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      new_price: "250,000.00",
      approved_price: "200,000.00",
      unit_price: "20,000.00",
      date: "31 May, 2022",
    },
  ];

  const cardHeader = ["Total Supplementary", "Exhausted Budget"];
  const cardValue = ["00.00", "00.00"];

  const link = ["/employee-dashboard-actual-supplementary-budget-form"];



  return (
    <>
      <EmployeeSupplementaryLayout draftLink={true}>
        <EmployeeReusableTable
          cardTitleOne={cardHeader[0]}
          cardTitleTwo={cardHeader[1]}
          cardBodyOne={cardValue[0]}
          cardBodyTwo={cardValue[1]}
          firstCard={true}
          secondCard={true}
          sort
          filter
          addButton={true}
          BtnTitle={"New Budget"}
          showSearch={true}
          onPage={() => history.push(link[0])}
        />

        {/* table start  */}
        {children}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowPagination={true}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            action
            onNextPage
          >
            {tableBodyList.map((chi, idx) => {
              const {
                chart_of_account,
                classes,
                new_price,
                approved_price,
                unit_price,
                date,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={chart_of_account}
                  two={classes}
                  three={new_price}
                  four={approved_price}
                  five={unit_price}
                  six={date}
                  action
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
          {/* button start */}
          <div style={{ display: "flex", justifyContent: "right" }}>
            <ButtonBlue text={`Submit for Approval`}></ButtonBlue>
          </div>
          {/* button end */}
        </div>
      </EmployeeSupplementaryLayout>
    </>
  );
};

export default EmployeeSupplementaryDraftTable;
