import Subscription from "../../../employee-folder/component/pages/dashboard/subscription";

export const Dashboard_subscription_route_group = [
  {
    path: "/dashboard-subscription-upgrade-plan",
    component: Subscription,
    pageTitle: "Upgrade Plan",
    organization: true,
    goBack: true,
  },
];
