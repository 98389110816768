import React, { useEffect } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import "../../../../../styles/dashboard/actuals/expense/actualExpense.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
// import EmployeeAttachMoreFiles from "./addMoreFiles";
import moment from "moment";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/airbnb.css";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import { Dna } from "react-loader-spinner";
import {
  ActualExpenseDownload,
  createEmployeeActualExpense,
} from "../../../../../../redux/employee/actual-expense";
// import { getUserDetails } from "../../../../../../redux/user";
// >>>>>>> ecb72c0a072084fc7ba31a8c191900c979ba6733
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import EmpActualExpenseBulkModal from "../modals/actual-expense/upload";
import Modal from "react-awesome-modal";
import { useContext } from "react";
import ActionContext from "../../../../../../context/ActionContext";
import { useThemeDetector } from "../../../../../../utils/getThemeColor";
import { NumericFormat } from "react-number-format";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import EmpSharedServiceModal from "../modals/sharedServiceModal";
import {
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { toast } from "react-toastify";
import { MdCancel } from "react-icons/md";
import "../../../../../styles/dashboard/projection/expense/expenditureProjection.css";
import SharedBudgetModal from "../modals/sharedBudgetModal";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import SharedBudgetModalMessage from "../modals/sharedBudgetMessage";
import { checkDepartmentApprovalFlow } from "../../../../../../redux/employee/approval-flow";
import RevvexActionBar from "../../../../../../component/fragments/ActionBar";

const EmployeeActualNewExpense = ({ text, name, button }) => {
  const [startDate, setstartDate] = useState("");
  const dispatch = useDispatch();
  const { loadingTwo } = useSelector((state) => state.actual_expense_employee);
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);
  const { departmentDrop, loading } = useSelector((state) => state.department);
  const { user } = useSelector((state) => state.user);
  const { loadingPage } = useSelector((state) => state.department);
  const [isSharedComplete, setIsSetSharedComplete] = useState(false);
  const actionCtx = useContext(ActionContext);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [details, setDetails] = useState({
    expense_date: "",
    chart_of_account_id: "",
    unit_price: "",
    units: "",
    description: "",
    total_expense: "",
    is_submitted: "",
    subsidiary_id: "",
    department_id: "",
    // receipt: [],
    departments_aportions: [
      { department_id: "", subsidiary_id: "", apportions: "" },
    ],
  });

  // const status = localStorage.getItem("employee-actual-expense-status");

  const [imageToUpload, setImageToUpload] = useState([]);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [draftLoader, setDraftLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);
  const [isApportionAlert, setIsApportionAlert] = useState(false);
  const [showSharedModal, setShowSharedModal] = useState(false);
  const [isApportion, setIsApportion] = useState([]);
  const [departmentOption, setDepartmentOption] = useState([]);
  const [isSharedBudget, setIsSharedBudget] = useState(false);
  const [isSharedBudgetMessage, setIsSharedBudgetMessage] = useState(false);
  const [allSubisidiarySelected, setAllSelectedSubsidiary] = useState([]);
  const [subOption, setSubOption] = useState([]);
  const [isSharedBtnLoad, setIsSharedBtnLoad] = useState(false);
  const [isShareBudgetModal, setIsShareBudgetModal] = useState(false);
  const [approvalFlowAlert, setApprovalFlowAlert] = useState("");

  const editApportionToSubmit = (list, dept) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          subsidiary_id: chi?.subsidiary?.id,
          department_id: dept,
          apportion: chi?.budget_percentage,
        };
      });
      setIsApportion(newList);
    } else {
      setIsApportion([]);
    }
  };

  const formatApportionToSubmit = (obj, e) => {
    const newList = isApportion?.map((chi) =>
      obj?.id === chi?.id ? { ...chi, apportion: e } : chi
    );
    setIsApportion(newList);
  };

  useEffect(() => {
    handleApportionSum();
  }, [isApportion]);

  const [apportionSum, setApportionSum] = useState("");
  const handleApportionSum = () => {
    const totalApportion = isApportion
      ?.map((chi) => chi.apportion)
      .reduce((a, b) => Number(a) + Number(b), 0);
    setApportionSum(totalApportion);
  };

  const handleApprovalFlowCheckByDept = async () => {
    const checkObj = {
      projection_type: "Actual Expense",
      department_id: details?.department_id?.value,
    };
    const checkData = await dispatch(checkDepartmentApprovalFlow(checkObj));
    // console.log(checkData, "data");
    if (checkData?.payload?.status !== 201) {
      setApprovalFlowAlert(checkData?.payload?.message);
    } else {
      setApprovalFlowAlert("");
    }
  };

  useEffect(() => {
    if (details?.department_id?.value) {
      handleApprovalFlowCheckByDept();
    }
  }, [details?.department_id?.value]);

  const handleSubmit = async (e, param) => {
    e.preventDefault();
    // const newApportionList = isApportion?.map((chi) => {
    //   return {
    //     subsidiary_id: chi?.subsidiary_id,
    //     department_id: chi?.department_id,
    //     apportions: chi?.apportion,
    //   };
    // });
    // console.log(newApportionList);

    // const checkObj = {
    //   projection_type: "Actual Expense",
    //   department_id: details?.department_id?.value,
    // };
    // details?.is_submitted === 0 && setDraftLoader(true);
    // details?.is_submitted === 1 && setApproveLoader(true);
    // const checkData = await dispatch(checkDepartmentApprovalFlow(checkObj));
    // if (checkData?.payload?.status !== 201) {
    //   details?.is_submitted === 0 && setDraftLoader(false);
    //   details?.is_submitted === 1 && setApproveLoader(false);
    //   return;
    // }

    const newApportionListForSHared = allSubisidiarySelected?.map((chi) => {
      return {
        subsidiary_id: chi?.value,
        apportions: Number(chi?.budget_percentage),
        subsidiary_departments: chi?.selected_departments?.map((child) => {
          return {
            department_id: child?.value,
            apportion: Number(child?.shared_apportion),
          };
        }),
      };
    });

    if (allSubisidiarySelected.length > 0 && !isSharedComplete) {
      toast.info("Submit Your Budget Percentage", { theme: "colored" });
      return;
    }

    const newApportionListData = JSON.stringify(newApportionListForSHared);

    const formData = new FormData();

    formData.append("expense_date", startDate);
    formData.append("chart_of_account_id", details?.chart_of_account_id?.value);
    formData.append(
      "unit_price",
      formatNumberWithoutComma(String(details?.unit_price))
    );
    formData.append(
      "is_shared_service",
      isSharedBudget && allSubisidiarySelected?.length > 0 ? 1 : 0
    );
    formData.append("departments_aportions", newApportionListData);
    formData.append("units", formatNumberWithoutComma(String(details?.units)));
    formData.append("description", details?.description);
    formData.append("total_expense", details?.total_expense);
    Array.from(imageToUpload).forEach((image) => {
      formData.append("receipt[]", image);
    });
    formData.append("status", param);
    formData.append("subsidiary_id", details?.subsidiary_id?.value);
    formData.append("is_submitted", param);
    formData.append("organization_id", actionCtx?.activeOrg?.id);
    formData.append("department_id", details?.department_id?.value);

    // console.log(allSubisidiarySelected?.length, "apportion");
    // console.log(isSharedBudget, "biudget");
    // console.log(isSharedBudget, "")

    if (param === 0) {
      setDraftLoader(true);
    }
    if (param === 1) {
      setApproveLoader(true);
    }

    const data = await dispatch(createEmployeeActualExpense(formData));

    if (
      data?.payload?.success &&
      data?.payload?.data?.expense?.is_submitted === "0"
    ) {
      // setDetails({
      //   expense_date: "",
      //   chart_of_account_id: "",
      //   unit_price: "",
      //   units: "",
      //   description: "",
      //   total_expense: "",
      //   department_id: "",
      //   receipt: [],
      // });
      // setstartDate("");
      // setImageToUpload([]);
      setDraftLoader(false);
      history.push("/employee-dashboard-actual-expense");
    } else if (
      data?.payload?.success &&
      data?.payload?.data?.expense?.is_submitted === "1"
    ) {
      setApproveLoader(false);
      history.push("/employee-dashboard-actual-expense-submitted");
    } else {
      setDraftLoader(false);
      setApproveLoader(false);
    }
  };

  // const [displayNumber, setDisplayVal] = useState("");

  useEffect(() => {
    // console.log("ertyuiuyt");
    const empObj = {
      reason: true,
      id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
    };
    dispatch(getChatAccounts(empObj));
    dispatch(getSubsidiaries(empObj));
    dispatch(getDepartments(actionCtx?.activeOrg?.id));
    dispatch(getUserDepartment(actionCtx?.activeOrg?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calcVal =
      formatNumberWithoutComma(String(details?.unit_price || 0)) *
      formatNumberWithoutComma(String(details?.units || 0));
    setDetails((prev) => {
      return { ...prev, total_expense: calcVal };
    });
  }, [details?.unit_price, details?.units]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleUploadLogoToList = (param) => {
    const list = [...imageToUpload, param];
    setImageToUpload(list);
  };

  const apportionData =
    details?.department_id?.branch?.subsidiary?.shared_service;

  // const handleApportionSubmit = () => {
  //   const alertText = "The sharing percentage is above 100%";
  //   if (isApportion && apportionSum > 100) {
  //     toast.error(alertText, {
  //       theme: "colored",
  //     });
  //   } else {
  //     setShowSharedModal(false);
  //     actionCtx?.setIsModalOut(false);
  //   }
  // };

  const enableSubmit = () => {
    let allowSubmit = false;

    allowSubmit =
      details?.chart_of_account_id &&
      details?.description &&
      startDate &&
      approvalFlowAlert == "" &&
      // details?.receipt &&
      details?.unit_price &&
      details?.units &&
      imageToUpload.length > 0;

    return allowSubmit;
  };

  const handleReceiptDelete = (item) => {
    const newItem = imageToUpload.filter((chi) => chi !== item);
    setImageToUpload(newItem);
  };

  const handleDepartmentOptions = (option) => {
    if (option?.length > 0) {
      const newOption = option?.map((chi) => {
        return {
          ...chi,
          label: chi?.name,
          value: chi?.id,
        };
      });
      return newOption;
    }
  };

  const handleSubOptions = async () => {
    const subsidiaryOptions = isApportion?.map((chi) => {
      return {
        // ...chi,
        value: chi?.subsidiary?.id,
        label: chi?.subsidiary?.name,
        budget_percentage: chi?.budget_percentage,
      };
    });
    setSubOption(subsidiaryOptions);
  };

  const handleMultiSelectChange = (
    selectedValues,
    { action, removedValue },
    list
  ) => {
    const addSharedPortionToList = (param) => {
      const newList = param?.map((chi, idx) => {
        return { ...chi, shared_apportion: "" };
      });
      return newList;
    };

    if (action === "select-option") {
      const newlyAddedOptionList = selectedValues.map((option) =>
        !allSubisidiarySelected?.includes(option)
          ? {
              ...option,
              departments: addSharedPortionToList(list),
              selected_departments: [],
            }
          : option
      );
      // console.log("Newly added option:", newlyAddedOptionList);
      setAllSelectedSubsidiary(newlyAddedOptionList);
    }
  };

  const handleNewBudgetPercentage = (value, valId) => {
    const newValue = allSubisidiarySelected?.map((chi) =>
      chi?.value === valId ? { ...chi, budget_percentage: value } : chi
    );
    setAllSelectedSubsidiary(newValue);
  };

  const handleFormatDepartmentDrop = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return { ...chi, label: chi?.name, value: chi?.id };
      });
      return newList;
    }
  };

  const handleMultiSelectChangeTwo = (
    selectedValues,
    { action, removedValue },
    list,
    id
  ) => {
    const getObjChild = allSubisidiarySelected?.find(
      (chi) => chi?.value === id
    );
    const addSelectedToObjChild = {
      ...getObjChild,
      selected_departments: selectedValues,
    };
    // console.log(addSelectedToObjChild);
    const addBackToAllList = allSubisidiarySelected?.map((chi) =>
      chi?.value === id ? addSelectedToObjChild : chi
    );
    setAllSelectedSubsidiary(addBackToAllList);
  };

  const handleApportionDeptChange = (sudId, deptId, value) => {
    const getObjChild = allSubisidiarySelected?.find(
      (chi) => chi?.value === sudId
    );

    const addSelectedToObjChild = getObjChild?.selected_departments?.map(
      (chi) =>
        chi?.value === deptId ? { ...chi, shared_apportion: value } : chi
    );

    const addBackToAllList = allSubisidiarySelected?.map((chi) =>
      chi?.value === sudId
        ? { ...chi, selected_departments: addSelectedToObjChild }
        : chi
    );
    setAllSelectedSubsidiary(addBackToAllList);
  };

  const handleDeleteChildFromAll = (sudId, deptId) => {
    const getObjChild = allSubisidiarySelected?.find(
      (chi) => chi?.value === sudId
    );
    const addSelectedToObjChild = getObjChild?.selected_departments?.filter(
      (chi) => chi?.value !== deptId
    );
    // console.log(addSelectedToObjChild);
    const addBackToAllList = allSubisidiarySelected?.map((chi) =>
      chi?.value === sudId
        ? { ...chi, selected_departments: addSelectedToObjChild }
        : chi
    );
    setAllSelectedSubsidiary(addBackToAllList);
  };
  const [apportionWarningMsg, setApportionWarningMsg] = useState("");
  const handleSharedSum = () => {
    const newApportionListForShared = allSubisidiarySelected?.map((chi) => {
      return {
        subsidiary_id: chi?.value,
        apportions: chi?.budget_percentage,
        label: chi?.label,
        subsidiary_departments: chi?.selected_departments?.map((child) => {
          return {
            department_id: child?.value,
            apportion: child?.shared_apportion,
          };
        }),
      };
    });

    const newInvalidBudgetLength = [];

    newApportionListForShared?.forEach((chi) => {
      const totalApportion = chi?.subsidiary_departments
        ?.map((chil) => chil?.apportion)
        ?.reduce((a, b) => Number(a) + Number(b), 0);

      if (totalApportion !== 100) {
        newInvalidBudgetLength.push({
          ...chi,
          subsidiary_id: chi.subsidiary_id,
          label: chi?.label,
          department_id: chi.subsidiary_departments.find(
            (chil) => chil.apportion !== 100
          ),
        });
      }
    });

    if (newInvalidBudgetLength.length > 0) {
      setIsApportionAlert(true);
      actionCtx?.setIsModalOut(true);
      setIsSharedBtnLoad(true);
      const errorMessage = newInvalidBudgetLength
        .map((dept) => {
          return `Subsidiary: ${dept.label}.`;
        })
        .join("\n");

      setTimeout(() => {
        // toast.error(
        //   `Budget Allocation Error: The total allocation across all categories must add up to 100% of \n${errorMessage}`,
        //   {
        //     theme: "colored",
        //   }
        // );

        setApportionWarningMsg(
          `Budget Allocation Error: The total allocation across all categories must add up to 100% of \n${errorMessage}`
        );
        setIsSharedBtnLoad(false);
      }, 2000);
    } else {
      setIsApportionAlert(false);
      setIsSharedBtnLoad(true);
      actionCtx?.setIsModalOut(true);
      setTimeout(() => {
        setIsSharedBtnLoad(false);
        // setIsSharedBudget(false);
        setIsSetSharedComplete(true);
        setIsShareBudgetModal(false);
        actionCtx?.setIsModalOut(false);
        // setAllSelectedSubsidiary([]);
        toast.success("Budget Percentage Submitted Successfully", {
          theme: "colored",
        });
      }, 2000);

      // setIsSharedBudget(false);
    }
  };

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Actual Expense`}> */}

      {(approvalFlowAlert || isApportionAlert) && (
        <RevvexActionBar
          type={"warning"}
          visible={approvalFlowAlert || apportionWarningMsg}
          msg={approvalFlowAlert || apportionWarningMsg}
          // onAction={() =>
          //   history.push(
          //     "/employee-dashboard-projection-expenditure-submitted"
          //   )
          // }
          onCancel={() => {
            setApprovalFlowAlert("");
            setIsApportionAlert(false);
          }}
          // actionText={"Submitted Projections"}
        />
      )}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container start */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">New Expense</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          <div
            className="btn-download-box"
            //   onClick={() => setShowModal(true)}
          >
            <button
              className={`btn-bulk`}
              onClick={() => {
                actionCtx?.setIsModalOut(true);
                setShowModal(true);
              }}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            {loadingTwo ? (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <p
                className="text"
                onClick={() => dispatch(ActualExpenseDownload())}
              >
                See and download template
              </p>
            )}
          </div>
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container end */}
        <div
          className="form-box"
          style={{
            width: "100%",
          }}
        >
          <form action="" className="form" onSubmit={handleSubmit}>
            {/* <div className=""> */}
            <div
              className="wrapper"
              style={{
                borderBottom: "unset",
                // paddingBottom: "-40rem",
                height: "unset",
                minHeight: "unset",
                // background: "green"
              }}
            >
              <p className="box-title">1.Expense Information</p>
              <div
                className="form-wrap"
                style={{
                  gridTemplateRows: "1fr",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <div className="employee-actual-expense-left-upload-wrap">
                  {/* select content wrap start */}
                  <div className="select-content">
                    {imageToUpload?.length < 1 && (
                      <>
                        <p className="title">Upload receipt </p>
                        <input
                          type="file"
                          name="upload-recipt-employee"
                          id="upload-recipt-employee"
                          className="upload-receipt"
                          onChange={(e) =>
                            handleUploadLogoToList(e.target.files[0])
                          }
                        />
                        <label
                          htmlFor="upload-recipt-employee"
                          className="label-upload"
                        >
                          <div className="wrap">
                            <FaPlus className="icon" />
                            <p className="text">Attach file</p>
                          </div>
                        </label>
                        <p className="info">
                          Supported format is jpg, xls, pdf. maximum of 5 files
                          and must not be less than 450kb
                        </p>
                      </>
                    )}
                    {/* less than end */}
                    {imageToUpload?.length > 0 && (
                      <>
                        <input
                          type="file"
                          name="upload-recipt-employee"
                          id="upload-recipt-employee"
                          className="upload-receipt"
                          onChange={(e) =>
                            handleUploadLogoToList(e.target.files[0])
                          }
                        />
                        <label
                          htmlFor="upload-recipt-employee"
                          className="label-upload-two"
                        >
                          {" "}
                          <div className="plus-box">
                            <FaPlus className="icon" />
                          </div>
                          <p className="text">Attach more files</p>
                        </label>
                      </>
                    )}
                  </div>
                  {/* select content wrap end */}
                  {/* slected content box start */}
                  <div className="selected-content-box">
                    <div
                      style={{
                        gridTemplateColumns: `repeat(${imageToUpload?.length}, max-content)`,
                      }}
                      className="wrap"
                    >
                      {imageToUpload?.map((chi, idx) => {
                        if (!chi?.type.includes("image/")) {
                          return (
                            <div className="box box-file" key={idx}>
                              {/* <p className="type">File type: <span>{formatApplicationType(chi?.type)}</span></p> */}
                              <p className="name">
                                <figure
                                  onClick={() => handleReceiptDelete(chi)}
                                  className="box-delete-file"
                                >
                                  <MdCancel />
                                </figure>
                                File name: <span>{chi?.name}</span>
                              </p>
                            </div>
                          );
                        }
                        return (
                          <>
                            <div
                              style={{
                                backgroundImage: `url(${URL.createObjectURL(
                                  chi
                                )})`,
                                width: "25rem",
                              }}
                              key={idx}
                              className="box"
                            >
                              <figure
                                onClick={() => handleReceiptDelete(chi)}
                                className="box-delete"
                              >
                                <MdCancel />
                              </figure>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  {/* slected content box error */}
                </div>
                {/* form group-container start */}
                <div
                  // style={{ background: "green" }}
                  className="form-group form-group right-box-expense-actual-box-wrap"
                >
                  {/* form-group start */}
                  <div className="form-group">
                    <label htmlFor="expense_date" className="label">
                      Expense Date <span>*</span>
                    </label>
                    <Flatpickr
                      id="start_date"
                      name="start_date"
                      className={`form-input-calender input ${
                        startDate && "input-active"
                      }`}
                      autoComplete="off"
                      value={startDate}
                      onChange={(date) =>
                        setstartDate(moment(date[0]).format("YYYY-MM-DD"))
                      }
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="chart_of_account" className="label">
                      Chart of Account <span>*</span>
                    </label>
                    <Select
                      styles={reactSelectStyle}
                      className={`select-wrap ${
                        details.chart_of_account_id && "select-wrap-active"
                      }`}
                      placeholder="Select chart of account"
                      value={details.chart_of_account_id}
                      isLoading={loading}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, chart_of_account_id: e };
                        });
                      }}
                      options={chatAccountDrop}
                    />
                  </div>
                  {/* form-group end */}
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="subsidiary" className="label">
                      Subsidiary <span>*</span>
                    </label>
                    <Select
                      className={`select-wrap ${
                        details.subsidiary_id && "select-wrap-active"
                      }`}
                      placeholder="Select Subsidiary"
                      value={details.subsidiary_id}
                      onChange={async (e) => {
                        setDetails((prev) => {
                          // setDeptLoader(true);
                          return { ...prev, subsidiary_id: e };
                        });
                        const obj = {
                          reason: true,
                          id: e?.value,
                        };
                        const data = await dispatch(
                          getDepartmentsBySubsidiary(obj)
                        );
                        if (data?.payload?.success) {
                          const newData = data?.payload?.data?.departments;
                          setDepartmentOption(newData);
                        }
                      }}
                      styles={reactSelectStyle}
                      options={subsidiariesDrop}
                      // isLoading={isLoading}
                    />
                  </div>
                  {/* group box end */}
                  {/* form-group start */}
                  <div className="form-group">
                    <label htmlFor="Chart of account" className="label">
                      Department <span>*</span>
                    </label>
                    <Select
                      className={`select-wrap ${
                        details.department_id && "select-wrap-active"
                      }`}
                      placeholder="Select Department"
                      value={details?.department_id}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, department_id: e };
                        });

                        editApportionToSubmit(
                          e?.branch?.subsidiary?.shared_service[0]?.apportions,
                          e?.id
                        );
                        setIsSharedBudget(apportionData?.length < 1 && false);
                        setAllSelectedSubsidiary(
                          apportionData?.length < 1
                            ? []
                            : allSubisidiarySelected
                        );
                      }}
                      // isLoading={loading}
                      styles={reactSelectStyle}
                      // options={
                      //   user?.user_type === "ADMIN" && details?.subsidiary_id
                      //     ? departmentDrop
                      //     : user?.user_type === "EMPLOYEE" &&
                      //       details?.subsidiary_id
                      //     ? userDepartmentDrop
                      //     : []
                      // }
                      options={handleDepartmentOptions(departmentOption)}
                      // isLoading={deptLoader}
                      isLoading={loadingPage}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="unit_price" className="label">
                      Unit Price <span>*</span>
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      id="unit_price"
                      name="unit_price"
                      placeholder="specify unit price"
                      value={details.unit_price}
                      onChange={handleChange}
                      className={`input ${
                        details.unit_price && "input-active"
                      }`}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="units" className="label">
                      Units <span>*</span>
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      id="units"
                      name="units"
                      placeholder="specify unit"
                      value={details?.units}
                      // onChange={(e) =>
                      //   setDetails((prev) => {
                      //     return {
                      //       ...prev,
                      //       units: handleNumberFormatInput(e),
                      //     };
                      //   })
                      // }
                      onChange={handleChange}
                      className={`input ${details.units && "input-active"}`}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="description" className="label">
                      Description <span>*</span>
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      onChange={handleChange}
                      style={{ resize: "none" }}
                      cols="30"
                      rows="7"
                      placeholder="Enter your description here..."
                      className={`input ${
                        details.description && "input-active"
                      }`}
                      value={details.description}
                    />
                  </div>

                  {/* form-group start */}
                  <div>
                    <label htmlFor="total_expense" className="label">
                      Total Expense
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      disabled
                      style={{
                        backgroundColor: "#F1F2F3",
                        marginBottom: "-10rem",
                      }}
                      type="text"
                      onChange={handleChange}
                      id="total_expense"
                      name="total_expense"
                      placeholder="shows total value"
                      value={details?.total_expense || ""}
                      // onChange={handleChange}
                      className={`input`}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group end */}
                  {/* sharing percent start */}
                  {/* {apportionData?.length > 0 && (
                        <div>
                          <label className="label">
                            Set Sharing Percentage <span>*</span>
                          </label>
                          <ButtonBlue
                            onClick={() => {
                              actionCtx?.setIsModalOut(true);
                              setShowSharedModal(true);
                            }}
                            text={"Set Below"}
                          />
                        </div>
                      )} */}
                  <div className="emp-expenditure-shared-service-budget-wrap">
                    {/* shared budget wrap start */}
                    {/* {apportionData?.length > 0 && ( */}
                    <div>
                      <div className="wrapper" />
                      <p className="shared-percent-title">
                        Set Sharing Percentage
                      </p>
                      {/* set sharing percentage note wrap start */}
                      <div>
                        <p>
                          Set cost sharing percentage or retain default shared
                          service settings.
                        </p>
                        <p
                          style={{
                            marginTop: ".5rem",
                            marginBottom: "2rem",
                            fontWeight: "400",
                          }}
                        >
                          Note: Percentage for all subsidiaries add up to 100%
                        </p>
                      </div>
                      {/* set sharing percentage note wrap end */}
                      {/* <div className="hide-box"> */}
                      {/* <input
                          checked={isSharedBudget}
                          disabled={apportionData?.length > 0 ? false : true}
                          type="checkbox"
                          name=""
                          id="is_shared_toggle"
                          className="input"
                          onChange={() => {
                            setIsSharedBudget(!isSharedBudget);
                            setAllSelectedSubsidiary([]);
                            handleSubOptions();
                          }}
                        /> */}
                      <ButtonBlue
                        text={"Set Budget Percentage"}
                        onClick={() => {
                          if (apportionData?.length > 0) {
                            actionCtx?.setIsModalOut(true);
                            setIsShareBudgetModal(true);
                            setIsSharedBudget(true);
                            handleSubOptions();
                          }
                        }}
                        bgColor={
                          apportionData?.length > 0
                            ? "var(--blue-color)"
                            : "#cccc"
                        }
                      />
                      {/* <label className="label" htmlFor="is_shared_toggle"> */}
                      {/* <span></span> */}
                      {/* <span></span> */}
                      {/* </label> */}
                      {/* </div> */}
                    </div>
                    {/* )} */}
                    {/* shared budget wrap end */}
                    {/* shared budget form wrap start */}

                    {/* {isSharedBudget && ( */}
                    {/* shared budget modal start */}
                    <Modal visible={isShareBudgetModal} effect="fadeInLeft">
                      <SharedBudgetModal
                        allSubisidiarySelected={allSubisidiarySelected}
                        setAllSelectedSubsidiary={setAllSelectedSubsidiary}
                        handleNewBudgetPercentage={handleNewBudgetPercentage}
                        handleMultiSelectChange={handleMultiSelectChange}
                        subOption={subOption}
                        loadingPage={loadingPage}
                        handleFormatDepartmentDrop={handleFormatDepartmentDrop}
                        handleMultiSelectChangeTwo={handleMultiSelectChangeTwo}
                        departmentDrop={departmentDrop}
                        handleApportionDeptChange={handleApportionDeptChange}
                        handleDeleteChildFromAll={handleDeleteChildFromAll}
                        sharedBtnLoad={isSharedBtnLoad}
                        onSetPercent={() => {
                          actionCtx?.setIsModalOut(false);
                          handleSharedSum();
                        }}
                        onDiscard={() => {
                          actionCtx?.setIsModalOut(true);
                          // setIsSharedBudget(false);
                          setIsShareBudgetModal(false);
                          setIsSharedBudgetMessage(true);
                        }}
                        onClose={() => {
                          actionCtx?.setIsModalOut(false);
                          // setIsSharedBudget(false);
                          setIsShareBudgetModal(false);
                        }}
                      />
                    </Modal>
                    {/* shared budget modal end */}

                    <Modal visible={isSharedBudgetMessage} effect="fadeInLeft">
                      <SharedBudgetModalMessage
                        onCancel={() => {
                          setIsSharedBudgetMessage(false);
                          // setIsSharedBudget(true);
                          setIsShareBudgetModal(true);
                        }}
                        discardLoader={isSharedBtnLoad}
                        onDiscard={() => {
                          setIsSharedBtnLoad(true);
                          if (isSharedBudgetMessage) {
                            setTimeout(() => {
                              setAllSelectedSubsidiary([]);
                              actionCtx?.setIsModalOut(false);
                              setIsSharedBudgetMessage(false);
                              setIsSharedBudget(false);
                              setIsSharedBtnLoad(false);
                              toast.success(
                                "Budget Percentage Successfully Discarded",
                                {
                                  theme: "colored",
                                }
                              );
                            }, 2000);
                          }
                        }}
                      />
                    </Modal>
                    {/* )} */}

                    {/* shared budget form wrap end */}
                  </div>
                  {/* sharing percent end */}
                </div>

                <div className="btn-box-wrap">
                  {/* btn-box-start */}
                  {/* <button
                    className="btn-reuse btn-two"
                    style={{
                      backgroundColor: "#ffff",
                      marginRight: "3rem",
                    }}
                    onClick={() =>
                      history.push(
                        "/employee-dashboard-actual-expense-submitted"
                      )
                    }
                  >
                    Submit for approval
                  </button> */}
                  {!approveLoader ? (
                    <input
                      onClick={(e) => {
                        handleSubmit(e, 1);
                      }}
                      style={{ marginRight: "3rem" }}
                      disabled={
                        !details?.chart_of_account_id ||
                        !details?.description ||
                        !startDate ||
                        // !details?.receipt ||
                        !details?.unit_price ||
                        !details?.units ||
                        imageToUpload.length < 1
                      }
                      type="submit"
                      value={"Submit for approval"}
                      className={`form-submit ${
                        details?.chart_of_account_id &&
                        details?.description &&
                        startDate &&
                        // details?.receipt &&
                        details?.unit_price &&
                        details?.units &&
                        imageToUpload.length > 0 &&
                        "form-submit-active"
                      }`}
                    />
                  ) : (
                    <div style={{ alignSelf: "center" }}>
                      <Dna
                        visible={true}
                        height="50"
                        width="200"
                        ariaLabel="dna-loading"
                        // wrapperStyle={{color: "red", backgroundColor : "red"}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  )}
                  {!draftLoader ? (
                    <input
                      onClick={(e) => {
                        handleSubmit(e, 0);
                      }}
                      disabled={!enableSubmit()}
                      type="submit"
                      value={"Save To Draft"}
                      className={`form-submit ${
                        enableSubmit() && "form-submit-active"
                      }`}
                      // style={{ height: "5rem" }}
                      style={{
                        background: "#ffff",
                        color: !enableSubmit() ? "#cccc" : "var(--blue-color)",
                        border: !enableSubmit()
                          ? ".1rem solid #cccc"
                          : ".1rem solid var(--blue-color)",
                      }}
                    />
                  ) : (
                    <div style={{ alignSelf: "center", marginLeft: "3rem" }}>
                      <Dna
                        visible={true}
                        height="50"
                        width="80"
                        ariaLabel="dna-loading"
                        // wrapperStyle={{color: "red", backgroundColor : "red"}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  )}
                  {/* btn-box-end */}
                </div>
                {/* form group-container end */}
              </div>
            </div>
            {/* </div> */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
      {/* modal start */}
      <Modal effect="fadeInUp" visible={showModal}>
        <EmpActualExpenseBulkModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal(false);
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      {/* <Modal effect="fadeInLeft" visible={showSharedModal}>
        <EmpSharedServiceModal
          onCancel={() => {
            setShowSharedModal(false);
            actionCtx?.setIsModalOut(false);
          }}
          list={isApportion}
          listData={apportionData}
          deptVal={details?.department_id?.label}
          onSubmit={handleApportionSubmit}
          formatApportionToSubmit={formatApportionToSubmit}
        />
      </Modal> */}
      {/* modal end */}
    </>
  );
};
export default EmployeeActualNewExpense;
