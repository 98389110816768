import React, { useEffect, useState } from "react";
// import EmployeeSpreadForm from "./SpreadForm";
// import EmployeeMonthlyForm from "./MonthlyForm";
// import { useHistory } from "react-router-dom";
// import Modal from "react-awesome-modal";
// import EmployeeBulkModal from "../modals/upload";
import { HiInformationCircle } from "react-icons/hi";
import Select from "react-select";
import {
  formatNumber,
  formatNumberWithoutComma,
  // handleNumberFormatInput,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import product, {
  getProducts,
} from "../../../../../../redux/budget-setting/revenue-projection/product";
import { getVerticals } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import {
  getActualRevenue,
  updateActualRevenues,
} from "../../../../../../redux/employee/actual-revenue/actual-revenue";
import { Dna } from "react-loader-spinner";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import { useHistory } from "react-router-dom";
import { getProductByPortfolio } from "../../../../../../redux/employee/revenue-projection/revenue-projection";
import {
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { getPortfolios } from "../../../../../../redux/budget-setting/revenue-projection/portfolio";

const UpdateEmployeeActualRevenueForm = () => {
  const [details, setDetails] = useState({
    product_id: "",
    organization_id: "",
    subsidiary_id: "",
    department_id: "",
    fiscal_year_id: "",
    vertical_id: "",
    clientele_account: "",
    description: "",
    unit_price: "",
    unit: "",
    gross_profit_percent: "",
    gp: "",
    gross_revenue: "",
    // status: "",
    is_submitted: "",
  });
  // const [showModal, setShowModal] = useState(false);
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const { departmentDrop, loadingPage } = useSelector(
    (state) => state.department
  );
  const [startDate, setstartDate] = useState("");

  const { loading } = useSelector((state) => state.actual_revenue);
  const { verticalDrop } = useSelector((state) => state.vertical);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { user } = useSelector((state) => state.user);
  const { portfolioDrop, isLoading } = useSelector((state) => state.portfolio);
  const { productByPort, portLoading } = useSelector(
    (state) => state.revenue_projection
  );
  const [singleActualRevenue, setSingleActualRevenue] = useState({});
  const [refreshTable] = useState(false);
  // const [gpPercent, setGpPercent] = useState("");
  const [totalRev, setTotalRev] = useState("");
  const [gpAmount, setGpAmount] = useState("");
  const dispatch = useDispatch();

  const handleGetActualRevenue = async (id) => {
    const data = await dispatch(getActualRevenue(id));
    if (data?.payload?.success) {
      const actualRevenueDetail = data?.payload?.data?.actual_revenue;
      //   console.log("actual-revenue-detail", actualRevenueDetail);
      const newActualRevenueDetail = {
        ...actualRevenueDetail,
        product: {
          ...actualRevenueDetail?.product,
          label: actualRevenueDetail?.product?.name,
          value: actualRevenueDetail?.product?.id,
        },
        portfolio: {
          label: actualRevenueDetail?.portfolio?.name,
          value: actualRevenueDetail?.portfolio?.id,
        },
        vertical: {
          label: actualRevenueDetail?.vertical?.name,
          value: actualRevenueDetail?.vertical?.id,
        },
        subsidiary: {
          label: actualRevenueDetail?.subsidiary?.name,
          value: actualRevenueDetail?.subsidiary?.id,
        },
        department: {
          label: actualRevenueDetail?.department?.name,
          value: actualRevenueDetail?.department?.id,
        },
        fiscal_year: {
          label: actualRevenueDetail?.fiscal_year?.name,
          value: actualRevenueDetail?.fiscal_year?.id,
        },
        unit_price: formatNumber(String(actualRevenueDetail?.unit_price)),
        unit: formatNumber(String(actualRevenueDetail?.unit)),
        gp: formatNumber(String(actualRevenueDetail?.gp)),
        // gross_revenue: formatNumber(String(actualRevenueDetail?.gross_revenue))
      };
      setSingleActualRevenue(newActualRevenueDetail);
    }
  };

  useEffect(() => {
    const empObj = {
      id: user?.organizations[0]?.id,
      reason: true,
    };

    dispatch(getFiscalYears(empObj));
    dispatch(getSubsidiaries(empObj));
    dispatch(getUserDepartment(empObj?.id));
    dispatch(getProducts(empObj));
    dispatch(getVerticals(empObj));
    dispatch(getPortfolios({ org_id: user?.organizations[0]?.id }));
    // dispatch(getDepartments)
    const id = localStorage.getItem("single-actual-revenue-id");
    handleGetActualRevenue(id);
    setDetails((prev) => {
      return {
        ...prev,
        subsidiary_id: "",
        product_id: "",
        vertical_id: "",
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    const totalGrossRev =
      formatNumberWithoutComma(String(singleActualRevenue?.unit)) *
      formatNumberWithoutComma(String(singleActualRevenue?.unit_price));
    const totalGrossRevVal = totalGrossRev || 0;
    setTotalRev(totalGrossRevVal);

    const totalGpAmount =
      totalGrossRev *
      (formatNumberWithoutComma(
        String(singleActualRevenue?.gross_profit_percent || 0)
      ) /
        100);
    const totalGpAmountVal = totalGpAmount || 0;
    setGpAmount(totalGpAmountVal);
  }, [singleActualRevenue]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...singleActualRevenue, [name]: value };
    setSingleActualRevenue(data);
  };

  const history = useHistory();
  // setSpreadForm(!spreadForm);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      //   ...details,
      id: singleActualRevenue?.id,
      date: moment(startDate || singleActualRevenue?.date).format("YYYY-M-DD"),
      product_id: singleActualRevenue?.product?.value,
      subsidiary_id: singleActualRevenue?.subsidiary?.value,
      vertical_id: singleActualRevenue?.vertical?.value,
      department_id: singleActualRevenue?.department?.value,
      portfolio_id: singleActualRevenue?.portfolio?.value,
      fiscal_year_id: singleActualRevenue?.fiscal_year?.value,
      clientele_account: singleActualRevenue?.clientele_account,
      description: singleActualRevenue?.description,
      gp: formatNumberWithoutComma(String(gpAmount)),
      unit: Math.trunc(
        formatNumberWithoutComma(String(singleActualRevenue?.unit))
      ),
      // unit_price: Math.trunc(
      //   formatNumberWithoutComma(String(details?.unit_price))
      // ),
      unit_price: formatNumberWithoutComma(
        String(singleActualRevenue?.unit_price)
      ),
      organization_id: user?.organizations[0]?.id,
      gross_profit_percent: formatNumberWithoutComma(
        String(singleActualRevenue?.gross_profit_percent)
      ),
      gross_revenue: formatNumberWithoutComma(String(totalRev)),
      // status:
      //   singleActualRevenue?.status === "completed" ? "completed" : "draft",
      is_submitted: singleActualRevenue?.is_submitted === true ? true : false,
    };
    const data = await dispatch(updateActualRevenues(obj));
    if (data?.payload?.success) {
      history.push("/employee-dashboard-actual-revenue");
    }
  };

  const enableSubmit = () => {
    let allowSubmit = false;
    allowSubmit =
      singleActualRevenue?.product &&
      singleActualRevenue?.date &&
      singleActualRevenue?.vertical &&
      singleActualRevenue?.subsidiary &&
      // singleActualRevenue?.clientele_account &&
      singleActualRevenue?.department &&
      singleActualRevenue?.fiscal_year &&
      singleActualRevenue?.description &&
      singleActualRevenue?.unit_price &&
      singleActualRevenue?.unit &&
      singleActualRevenue?.gp;

    return allowSubmit;
  };

  //   console.log("checkimng-product", singleActualRevenue?.product)

  const formatPercentage = (param) => {
    const splitVal = param.split("%");
    return Number(splitVal[0]);
  };

  // console.log(singleActualRevenue?.product, "product")

  useEffect(() => {
    if (singleActualRevenue?.portfolio?.value) {
      dispatch(getProductByPortfolio(singleActualRevenue?.portfolio?.value));
    }
  }, [singleActualRevenue?.portfolio?.value]);

  useEffect(() => {
    if (singleActualRevenue?.subsidiary?.value) {
      const obj = {
        reason: true,
        id: singleActualRevenue?.subsidiary?.value,
      };
      dispatch(getDepartmentsBySubsidiary(obj));
    }
  }, [singleActualRevenue?.subsidiary?.value]);

  console.log(singleActualRevenue?.product?.is_allowed, "allow");

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Actual Revenue`}> */}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">Update Actual Revenue</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          {/* <div className="btn-download-box" onClick={() => setShowModal(true)}>
            <button className={`btn-bulk `}>
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div> */}

          {/* bulk upload btn start */}
        </div>
        {/* title and btn container */}

        <div className="form-box" style={{ width: "80%" }}>
          <form onSubmit={handleSubmit} action="" className="form">
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Revenue Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="date" className="label">
                    Date <span>*</span>
                  </label>
                  <Flatpickr
                    id="date"
                    name="date"
                    className={`input ${
                      singleActualRevenue?.date && "input-active"
                    }`}
                    autoComplete="off"
                    value={singleActualRevenue?.date}
                    onChange={(date) => {
                      setstartDate(moment(date[0]).format("YYYY-MM-DD"));
                    }}
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                {/* group start */}
                <div className="form-group">
                  <label htmlFor="portfolio" className="label">
                    Portfolio <span>*</span>
                  </label>

                  <Select
                    className={`select-wrap ${
                      singleActualRevenue?.portfolio && "select-wrap-active"
                    }`}
                    placeholder="Select Portfolio"
                    // isDisabled={true}
                    value={singleActualRevenue?.portfolio}
                    onChange={(e) => {
                      setSingleActualRevenue((prev) => {
                        return {
                          ...prev,
                          portfolio: e,
                        };
                      });
                      setDetails((prev) => {
                        return { ...prev, portfolio_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={portfolioDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group end */}
                <div className="form-group">
                  <label htmlFor="product" className="label">
                    Product <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleActualRevenue?.product && "select-wrap-active"
                    }`}
                    placeholder="Select Product"
                    value={singleActualRevenue?.product}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, product_id: e };
                      });
                      setSingleActualRevenue((prev) => {
                        return {
                          ...prev,
                          product: e,
                          gross_profit_percent: formatNumber(
                            String(formatPercentage(e.gp_percentage))
                          ),
                        };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={productByPort}
                    isLoading={portLoading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="vertical" className="label">
                    vertical <span>*</span>{" "}
                    <HiInformationCircle
                      style={{ marginLeft: "3px", fontSize: "16px" }}
                    />
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleActualRevenue?.vertical && "select-wrap-active"
                    }`}
                    placeholder="Select Vertical"
                    value={singleActualRevenue?.vertical}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, vertical_id: e };
                      });
                      setSingleActualRevenue((prev) => {
                        return { ...prev, vertical: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={verticalDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleActualRevenue?.subsidiary && "select-wrap-active"
                    }`}
                    placeholder="Select Subsidiary"
                    value={singleActualRevenue?.subsidiary}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                      setSingleActualRevenue((prev) => {
                        return { ...prev, subsidiary: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}

                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="department" className="label">
                    Department <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleActualRevenue?.department && "select-wrap-active"
                    }`}
                    placeholder="Select Department"
                    value={singleActualRevenue?.department}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, department_id: e };
                      });
                      setSingleActualRevenue((prev) => {
                        return { ...prev, department: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={
                      user?.user_type === "ADMIN" &&
                      singleActualRevenue?.subsidiary?.value
                        ? departmentDrop
                        : userDepartmentDrop
                    }
                    isLoading={loadingPage}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label
                    htmlFor="Company Name"
                    className="label"
                    style={{ marginTop: "3.6px" }}
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    placeholder="Unknown"
                    id="clientele_account"
                    value={singleActualRevenue?.clientele_account}
                    name="clientele_account"
                    onChange={handleChange}
                    className={`input ${
                      singleActualRevenue?.clientele_account && "input-active"
                    }`}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="fiscal year" className="label">
                    Fiscal Year <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleActualRevenue?.fiscal_year && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    value={singleActualRevenue?.fiscal_year}
                    placeholder="Select fiscal year"
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, fiscal_year_id: e };
                      });
                      setSingleActualRevenue((prev) => {
                        return { ...prev, fiscal_year: e };
                      });
                    }}
                    options={fiscalYearDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="description" className="label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={handleChange}
                    style={{ width: "206%", resize: "none", padding: "30px" }}
                    cols="30"
                    rows="7"
                    placeholder="medical use.."
                    className={`input ${
                      singleActualRevenue?.description && "input-active"
                    }`}
                    value={singleActualRevenue?.description}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
              </div>

              {/* second layer start */}
              <div
                className="form-wrap"
                style={{ marginTop: "3rem", marginBottom: "" }}
              >
                <div className="form-group">
                  {/* unit-price input start */}
                  <label htmlFor="unit price" className="label">
                    Unit Price <span>*</span>
                  </label>
                  <NumericFormat
                    // type="number"
                    // placeholder="input value"
                    // name="unit_price"
                    // className={`input ${
                    //   singleActualRevenue?.unit_price && "input-active"
                    // }`}
                    // id="unit_price"
                    // value={singleActualRevenue?.unit_price}
                    // onChange={handleChange}
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    // disabled
                    // type="number"
                    placeholder="input value"
                    name="unit_price"
                    className={`input ${
                      singleActualRevenue?.unit_price && "input-active"
                    }`}
                    id="unit_price"
                    value={singleActualRevenue?.unit_price}
                    onChange={handleChange}
                  />
                </div>
                {/* unit-price input start */}
                {/* unit input start */}
                <div className="form-group">
                  <label htmlFor="units" className="label">
                    Units <span>*</span>
                  </label>
                  <NumericFormat
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    name="unit"
                    id="units"
                    onChange={handleChange}
                    placeholder="input value"
                    value={singleActualRevenue?.unit}
                    className={`input ${
                      singleActualRevenue?.unit && "input-active"
                    }`}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="gross_revenue" className="label">
                    Gross Revenue
                  </label>
                  <NumericFormat
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    disabled
                    style={{ backgroundColor: "#F1F2F3" }}
                    // type="number"
                    name="gross_revenue"
                    id="gross_revenue"
                    placeholder="Shows Value"
                    onChange={handleChange}
                    value={totalRev}
                    className={`input`}
                  />
                </div>

                <div
                  style={{
                    gridTemplateColumns: "1fr 1fr",
                    display: "grid",
                    gap: "2rem",
                  }}
                >
                  <div className="form-group">
                    <label
                      htmlFor="units"
                      className="label"
                      style={{ marginBottom: "unset" }}
                    >
                      GP %<span>*</span>
                    </label>
                    <NumericFormat
                      disabled={singleActualRevenue?.product?.is_allowed === 0}
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      placeholder="Input value"
                      id="gross_profit_percent"
                      name="gross_profit_percent"
                      style={{
                        background:
                          singleActualRevenue?.product?.is_allowed === 0 &&
                          "#ECEDED",
                      }}
                      className={`input ${
                        singleActualRevenue?.product?.is_allowed !== 0 &&
                        singleActualRevenue?.gross_profit_percent &&
                        "input-active"
                      }`}
                      value={singleActualRevenue?.gross_profit_percent}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="gp_amount" className="label">
                      GP Amount
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      disabled
                      name="gp"
                      id="gp"
                      placeholder="Input Value"
                      onChange={handleChange}
                      value={gpAmount}
                      className={`input`}
                      style={{ backgroundColor: "#F1F2F3" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* second wrap end*/}

            <div className="wrapper"></div>
            {/* btn box start */}
            {/* <div className="btn-box-wrap">
             
              <>
                {" "}
                <button
                  className="btn-reuse btn-two"
                  style={{ backgroundColor: "#ffff", marginRight: "3rem" }}
                  onClick={() =>
                    history.push(
                      "/employee-dashboard-actual-revenue-total-revenue-submitted"
                    )
                  }
                >
                  Submit for approval
                </button>
               
                <button
                  className="btn-reuse btn-two"
                  style={{
                    backgroundColor: "var(--blue-color)",
                    color: "#F6F8F9",
                  }}
                  onClick={() =>
                    history.push(
                      "/employee-dashboard-actual-revenue-form-submitted"
                    )
                  }
                >
                  Save Revenue
                </button>
               
              </>
            </div> */}

            <div style={{ display: "flex", gap: "3rem" }}>
              {!loading ? (
                <input
                  disabled={!enableSubmit()}
                  type="submit"
                  // value={
                  //   singleActualRevenue?.is_submitted === 0
                  //     ? "Update To Draft"
                  //     : "Update For Approval"
                  // }
                  value="Update"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
              {/* button end */}
              {/* button start */}
              {/* {!loading ? (
                <input
                  onClick={() =>
                    setDetails({ ...details, status: "completed" })
                  }
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Submit for Approval"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{ height: "5rem" }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )} */}
            </div>
            {/* button end */}

            {/* btn box end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
      {/* modal start */}
      {/* <Modal effect="fadeInUp" visible={showModal}>
        <EmployeeBulkModal onCancel={() => setShowModal(false)} />
      </Modal> */}
      {/* modal end */}
    </>
  );
};

export default UpdateEmployeeActualRevenueForm;
