import React, { useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
// import ButtonBlue from "../../../../../buttons/ButtonBlue";
import imgOne from "../../../../../../assets/hod-two-img.svg";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import { approveAllProjection } from "../../../../../../redux/projections/ExpenditureProjection";
import { useContext } from "react";
import ActionContext from "../../../../../../context/ActionContext";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaTimes } from "react-icons/fa";
import userImg from "../../../../../../assets/Unknown_person.jpeg";

const FinalApprovalModalExpense = ({
  onCancel,
  detail,
  allId,
  onFinish,
  allApprovalStatus,
}) => {
  const [comments, setComments] = useState("");
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.expenditure_projection_chart
  );
  const { user } = useSelector((state) => state.user);

  const handleApproveAll = async () => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
      projection_type: "actual_expense",
      comment: comments,
      status: allApprovalStatus,
      ids: allId,
    };
    // console.log(obj);
    // return
    const data = await dispatch(approveAllProjection(obj));
    if (data?.payload?.success) {
      setComments("");
      onFinish();
    }
  };

  return (
    <div className="overview-card-modal-wrap emp-approval-modal">
      <div className="top-box">
        <p className="title">{`Do you wish to ${
          allApprovalStatus === "approved" ? `approve` : `reject`
        } all?`}</p>
        <div
          onClick={() => {
            setComments("");
            onCancel();
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      <div className="content-box">
        <div className="img-input-wrap">
          <figure className="img-box">
            <img
              style={{
                borderRadius: "20%",
                width: "4rem",
                height: "4rem",
              }}
              src={user?.profile_picture || userImg}
              alt=""
              className="img"
            />
          </figure>
          <textarea
            placeholder="type here your comment"
            style={{ resize: "unset" }}
            name=""
            id=""
            cols="30"
            rows="5"
            className="input-text"
            value={comments}
            onChange={(e) => setComments(e.target?.value)}
          ></textarea>
        </div>
      </div>
      <div className="bottom-box">
        <ButtonBlue
          onClick={() => {
            setComments("");
            onCancel();
          }}
          text={`Cancel`}
        />
        {!loading ? (
          <ButtonBlue
            bgColor={
              allApprovalStatus !== "approved" && allId.length > 0
                ? "#CC0905"
                : allApprovalStatus === "approved" && allId.length > 0
                ? "var(--blue-color)"
                : "#cccc"
            }
            onClick={() => allId.length > 0 && handleApproveAll()}
            text={
              allApprovalStatus === "approved"
                ? `Yes, Approve All `
                : "Yes, Reject All"
            }
          />
        ) : (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="40"
              width="100"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor :#CC0905 "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalApprovalModalExpense;
