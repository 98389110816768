import React from "react";
import EmployeeReinburstmentChart from "../chart/reinburstment";
// import Select from "react-select";
import { RiCheckboxBlankFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";

const EmployeeHomeChatTwo = ({
  ActualRemiburseVal,
  AwaitingReimburse,
  dataValue,
  actualValue,
  loading,
}) => {
  const history = useHistory();

  return (
    <div className="emp-reinburstment-wrap">
      {/* reibursement box start  */}
      <div className="emp-reinbursement-box">
        <div>
          <p className="reinbursement-head">
            Actual and Awaiting Reimbursement
          </p>
          <EmployeeReinburstmentChart
            loading={loading}
            dataValue={dataValue}
            actualValue={actualValue}
          />
        </div>
        <div className="right-content">
          {/* <div className="select-wrap" style={{ width: "18rem" }}>
            <Select options={optionList} placeholder="Current Fiscal Year" />
          </div> */}
          <div
            onClick={() =>
              history.push("/employee-dashboard-home-actual-reimbursement")
            }
            style={{ cursor: "pointer" }}
          >
            <p className="pending-text">Actual Reimbursement</p>
            <p className="chart-digit">{ActualRemiburseVal || "0.00"}</p>
          </div>
          <div
            onClick={() =>
              history.push("/employee-dashboard-home-awaiting-reimbursement")
            }
            style={{ cursor: "pointer" }}
          >
            <p className="active-text">Awaiting Reimbursement</p>
            <p className="chart-digit">{AwaitingReimburse || "0.00"}</p>
          </div>
          <div className="bottom-text">
            <div>
              <p>
                <RiCheckboxBlankFill className="icon" color="#fca549" />
                Actual Reimbursement
              </p>
            </div>
            <div>
              <p>
                <RiCheckboxBlankFill className="icon" color="#07a287" />
                Awaiting Reimbursement
              </p>
            </div>
          </div>
          {/* <hr /> */}
        </div>
      </div>
      {/* reibursement box end  */}
    </div>
  );
};

export default EmployeeHomeChatTwo;
