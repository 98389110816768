import React, { useEffect, useState } from "react";
import { FaAngleRight  } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../../redux/users/users";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../helper/Helpers";

const ViewEmployee = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [singleUser, setSingleUser] = useState({});
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Select_organization: "",
    Choose_a_department: "",
    Select_a_role: "",
    Choose_budget_approval: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  useEffect(() => {
    const id = localStorage.getItem("user-id");
    // console.log("yes");
    if (user?.organizations?.length > 0) {
      hanldGetUser(id);
    }
      // eslint-disable-next-line
  }, []);

  const hanldGetUser = async (id) => {
    const data = await dispatch(getUser(id));
    if (data?.payload?.success) {
      const userDetail = data?.payload?.data?.user;
      const submitToList = userDetail?.submit_to?.map((chi) => {
        return { label: chi?.full_name, value: chi?.id };
      });
      const deptList = userDetail?.department?.map((chi) => {
        return { label: chi?.name, value: chi?.id };
      });
      const newDetails = {
        ...userDetail,
        departments: deptList,
        subsidiary: {
          label: userDetail?.subsidiary?.name,
          value: userDetail?.subsidiary?.id,
        },
        submit_to: submitToList,
        role: { label: userDetail?.role, value: userDetail?.role },
      };
      // console.log(userDetail);
      setSingleUser(newDetails);
    }
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 5 of 6`}
        pageTitle={`view Employee`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings-employee")
            }
            className="text"
          >
            Users
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">view User</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          {/* <div className="title-text-box">
              <p className="title">Invite Users</p>
              <p className="text">
                Add department information and control the organisation
                structure of your account management
              </p>
            </div> */}
          {/* text tititl box end */}
          {/* button box start */}
          {/* <div className="btn-download-box">
              <button
                className={`btn-bulk ${
                  details.Subsidiary_Address ||
                  details.Subsidiary_Name ||
                  details.Phone_Number ||
                  details.country
                    ? "btn-bulk-inactive"
                    : ""
                }`}
              >
                {" "}
                <FaPlus className="icon" /> Bulk Upload
              </button>
              <p className="text">See and download template</p>
            </div> */}
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // history.push("/dashboard-home");
            }}
            action=""
            className="form"
          >
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1.User Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="First Name" className="label">
                    First Name
                  </label>
                  <input
                    disabled
                    type="text"
                    placeholder="First Name"
                    className={`input ${details.First_Name && "input-active"}`}
                    id="First Name"
                    name="First_Name"
                    onChange={handleChange}
                    value={singleUser?.first_name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <div className="form-group">
                    <label htmlFor="Last Name" className="label">
                      Last Name
                    </label>
                    <input
                      disabled
                      type="text"
                      placeholder="Last Name"
                      className={`input ${details.Last_Name && "input-active"}`}
                      id="Last Name"
                      name="Last Name"
                      onChange={handleChange}
                      value={singleUser?.last_name}
                    />
                  </div>
                  {/* group box end */}
                  {/* <select
                      name="Country"
                      onChange={handleChange}
                      value={details.country}
                      id=""
                      className={`input ${details.country && "input-active"}`}
                    >
                      <option value="Naira">Nigeria</option>
                    </select> */}
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Email" className="label">
                    Email
                  </label>
                  <input
                    disabled
                    name="Email"
                    onChange={handleChange}
                    value={singleUser?.email}
                    type="text"
                    placeholder="Email"
                    className={`input ${details.Email && "input-active"}`}
                    id="Email"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Phone Number" className="label">
                    Phone Number
                    {/* <figure
                        className="img-box"
                        style={{
                          filter: "saturate(0%)",
                          transform: "translateX(.5rem) translateY(.3rem)",
                        }}
                      >
                        <img src={noteIcon} alt="" className="img" />
                      </figure> */}
                  </label>
                  <input
                    disabled
                    name="Phone Number"
                    onChange={handleChange}
                    value={singleUser?.phone_number}
                    type="text"
                    placeholder="0909599***"
                    className={`input ${
                      details.Phone_Number && "input-active"
                    }`}
                    id="Phone Number"
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
             {/* wrap start */}
             <div className="wrapper">
              <p className="box-title">2. User Permission</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Select organization" className="label">
                    Select Subsidiary
                  </label>
                  <Select
                    isDisabled
                    className={`select-wrap ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    placeholder="Select Subsidiary"
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    value={singleUser?.subsidiary}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  {/* <label htmlFor="Last Name" className="label">
                      Last Name 
                    </label> */}
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="Choose a department" className="label">
                      Choose a department
                    </label>
                    <Select
                    isDisabled
                    isMulti
                      className={`select-wrap select-wrap-multi ${
                        details.department_id && "select-wrap-active"
                      }`}
                      styles={reactSelectStyle}
                      placeholder="choose department"
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, department_id: e };
                        });
                      }}
                      value={singleUser?.departments}
                    />
                  </div>
                  {/* group box end */}
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Select a role" className="label">
                    Select a role
                  </label>
                  <Select
                    isDisabled
                    className={`select-wrap ${
                      details.role && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    placeholder="select role"
                    // value={details.role}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, role: e };
                      });
                    }}
                    value={singleUser?.role}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Choose budget approval" className="label">
                    Submit to
                  </label>
                  <Select
                    className={`select-wrap select-wrap-multi ${
                      details?.submit_to?.length > 0 && "select-wrap-active"
                    }`}
                    isMulti
                    isDisabled
                    styles={reactSelectStyle}
                    placeholder="Submit to"
                    // value={details.submit_to}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, submit_to: e };
                      });
                    }}
                    value={singleUser?.submit_to}
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            {/* <div className="note-img-wrap">
                Important Notice:
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
              </div> */}
            {/* note box start */}
            {/* <div className="note-box">
                <p>
                  1. For organizations with shared budgets, you would need to
                  have created at least two subsidiaries to set up a shared
                  services
                </p>
              </div> */}
            {/* note box end */}

            {/* btn box start */}
            {/* <div className="btn-box-wrap">
                <button
                  onClick={() =>
                    history.push(
                      "/dashboard-administration-admin-settings-add-department"
                    )
                  }
                  className="btn-reuse"
                >
                  Go to previous step
                </button>
                <button
                  onClick={() =>
                    history.push(
                      "/dashboard-administration-admin-settings-employee"
                    )
                  }
                  className="btn-reuse btn-two"
                >
                  Complete Setup
                </button>
              </div> */}
            {/* btn box end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default ViewEmployee;
