import React, { useContext, useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { createBudgetAssumption } from "../../../../../../redux/budget-setting/budget-assumption/budgetAssumptions";
import ActionContext from "../../../../../../context/ActionContext";
import { getUser } from "../../../../../../redux/users/users";
// import ButtonBlue from "../../../../../buttons/ButtonBlue";

const NewAssumptionModal = ({ onCancel, onFinish }) => {
  // const dispatch = useDispatch();
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const { loading } = useSelector((state) => state.budgetAssumptions);
  const [details, setDetails] = useState({
    fiscal_year_id: "",
    assumption: "",
    attachment: "",
    organization_id: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...details,
      fiscal_year_id: details?.fiscal_year_id?.value,
      organization_id: actionCtx?.activeOrg?.id,
    };
    // console.log(obj);
    // return
    const formData = new FormData();
    formData.append("organization_id", obj?.organization_id);
    formData.append("fiscal_year_id", obj?.fiscal_year_id);
    formData.append("attachment", obj?.attachment);
    formData.append("assumption", obj?.assumption);
    const data = await dispatch(createBudgetAssumption(formData));
    if (data?.payload?.success) {
      dispatch(getUser())
      onFinish();
      setDetails({
        fiscal_year_id: "",
        assumption: "",
        attachment: "",
        organization_id: "",
      });
    }
  };

  return (
    <>
      <CommonInputModal
        loading={loading}
        btnActive={details?.assumption && details?.fiscal_year_id}
        onBtnClick={handleSubmit}
        btnDisable={!details?.assumption || !details?.fiscal_year_id}
        onCancel={onCancel}
        title={`New Assumption`}
        btnText={`Save`}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Fiscal Year <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="Select fiscal year"
            className="select "
            value={details.fiscal_year_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, fiscal_year_id: e };
              });
            }}
            options={fiscalYearDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Assumption <span>*</span>
          </label>
          <textarea
            className={`input ${details.assumption && "input-active"}`}
            name="assumption"
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
            value={details.assumption}
            type="text"
            placeholder="Enter your budget assumption"
          ></textarea>
        </div>
        {/* group box end */}
        {/* input file start */}
        <div className="file-input-box">
          <input
           
            onChange={(e) =>
              setDetails((prev) => {
                return {
                  ...prev,
                  attachment: e.target.files[0],
                };
              })
            }
            type="file"
            name=""
            id="file-assumption-add"
          />

          {details.attachment && (
            <p style={{ display: "flex", alignItems: "center" }}>
              {details?.attachment?.name}{" "}
              <span
              onClick={() => {
                setDetails((prev) => {
                  return {...prev, attachment: ""}
                })
                // document.getElementById("file-assumption-add").value = null
              }}
                style={{
                  color: "#CC0905",
                  display: "inline-block",
                  marginLeft: ".7rem",
                  transform: "translateY(.2rem)",
                  cursor: "pointer"   
                }}
              >
                <FaTrash />{" "} 
              </span>
            </p>
          )}
          <label htmlFor="file-assumption-add">
            <FaPlus className="icon" /> Attach document
          </label>
          {/* {details.attachment && <ButtonBlue btnStyle={`btn-reuse`} text="remove attachment" />} */}
        </div>
        {/* input file end */}
      </CommonInputModal>
    </>
  );
};

export default NewAssumptionModal;
