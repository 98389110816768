import React from "react";
import { useHistory } from "react-router-dom";
// import Modal from "react-awesome-modal";
// import EmployeeReportModal from "../external/support/modal/reportModal";

const EmployeeSupportDropDown = () => {
  const history = useHistory();
  return (
    <div>
      {/* employee support drop down start */}

      <p
        onClick={() =>
          history.push("/employee-dashboard-administration-support")
        }
        style={{ marginTop: "3rem" }}
      >
        Help Center
      </p>  
      <p>Report an Issue</p>
      <p>Chat with a support</p>
      <div className="emp-arrow-box"></div>

      {/* employee support drop down end */}

      <>
        {/* modal start */}
        {/* <Modal visible={reportModal} effect="fadeInLeft">
          <EmployeeReportModal />
          <p onClick={() => setReportModal(false)}>X</p>
        </Modal> */}
        {/* modal end */}
      </>
    </div>
  );
};
export default EmployeeSupportDropDown;
