import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import userImg from "../../../../../assets/hod-one-img.svg";
import CommonCheckBox from "./CommonCheckBox";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import {
  assignUserToRole,
  getPermissions,
  getRole,
  updateRole,
} from "../../../../../redux/role/role";
import { Dna } from "react-loader-spinner";
import { reactSelectStyle } from "../../../../../helper/Helpers";
import Select from "react-select";
import { getUsers } from "../../../../../redux/users/users";
import Modal from "react-awesome-modal";
import OwnerShipTransferModal from "./modal/OwnerShipTransferModal";

const UpdateRole = () => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  // const [refreshTable, setRefreshTable] = useState(false);
  const [showOwnership, setShowOwnerShip] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { loading,  permissionsList } = useSelector(
    (state) => state.role
  );
  const { usersDrop } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  // const []
  const [details, setDetails] = useState({
    organization_id: "",
    name: "",
    description: "",
    permissions: [],
    users: [],
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleAddPermissionToList = (permission) => {
    if (details?.permissions?.includes(permission)) {
      const newList = details?.permissions?.filter((chi) => chi !== permission);
      setDetails((prev) => {
        return { ...prev, permissions: newList };
      });
    } else {
      const newList = [...details?.permissions, permission];
      setDetails((prev) => {
        return { ...prev, permissions: newList };
      });
    }
  };
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(() => {
    dispatch(getPermissions());
    dispatch(getUsers(actionCtx?.activeOrg?.id));
    getRolesInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const getRolesInfo = async () => {
    const id = localStorage.getItem("role-id");
    const org_id = actionCtx?.activeOrg?.id
    const obj = {
      role_id: id,
      org_id: org_id,
    }
    const data = await dispatch(getRole(obj));
    if (data?.payload?.success) {
      const roleInfo = data?.payload?.data?.role;
      const perList = data?.payload?.data?.role?.permissions.map(
        (chi) => chi?.name
      );
      const newUsers = roleInfo?.users?.map((chi) => {
        const { full_name, id } = chi;
        return { ...chi, label: full_name, value: id };
      });
      const roleDetails = {
        ...roleInfo,
        permissions: perList,
        users: newUsers,
      };
      setDetails(roleDetails);
    }
  };
  const formatUserDrop = (list) => {
    const newList = list?.filter(
      (chi) => chi?.role !== "creator administrator"
    );
    return newList;
  };

  const handleSubmit = async () => {
    // const newList = details?.users?.map((chi) => chi?.id);
    const obj = {
      name: details?.name,
      description: details?.description,
      permissions: details?.permissions,
      //   users: newList,
      id: localStorage.getItem("role-id"),
    };
    // console.log(obj);
    dispatch(updateRole(obj));
    // if (data?.payload?.success) {
    //   setDetails({
    //     organization_id: "",
    //     name: "",
    //     description: "",
    //     permissions: [],
    //   });
    // }
  };

  // const handleChange = (e) => {}
  const handleAssignUser = (param) => {
    // console.log(param);
    const newList = param?.map((chi) => chi?.id);
    const obj = {
      user_ids: newList,
      id: localStorage.getItem("role-id"),
    };
    dispatch(assignUserToRole(obj));
  };

  //   const update

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 3 of 6`}
        pageTitle={`Admin Settings`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings-role")
            }
            className="text"
          >
            Role
          </p>
          <FaAngleRight className="icon" />
          <p
            // onClick={() => {
            //   console.log(permissionsList);
            // }}
            style={{ textTransform: "capitalize" }}
            className="active text"
          >
            {details?.name}
          </p>
        </div>
        {/* <p style={{width: "60%", marginBottom: "3rem"}} className="small-text">
            Roles are made up of permissions. Each user is assigned to a role to
            define what actions they can perform in. Create custom roles to
            configure specific permissions as needed by your organization.{" "}
          </p> */}
        {/* subtitle box end */}
        {/* btn box start */}
        <div className="btn-box-save">
          {/* <ButtonBlue text={`Cancel`} /> */}
          <div className=""></div>
          {!loading ? (
            <ButtonBlue
              bgColor={
                details?.permissions?.length < 1 ||
                !details?.description ||
                !details?.name
                  ? "#cccccc"
                  : "var(--blue-color)"
              }
              onClick={() => {
                details?.permissions?.length > 0 &&
                  details?.description &&
                  details?.name &&
                  handleSubmit();
              }}
              text={`Update role`}
            />
          ) : (
            <div
              className="dna-wrapper-box"
              style={{ marginLeft: "1rem", marginBottom: "-2.5rem" }}
            >
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
        </div>
        {/* btn box end */}

        {/* role information box start */}
        <div className="role-information-box">
          <p className="title">Role Information</p>
          {/* input two box sytart */}
          <div className="two-group-box">
            {/* group box start */}
            <div className="form-group">
              <label htmlFor="Name" className="label">
                role name <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Creator - Administrator"
                className={`input ${details.role_name && "input-active"}`}
                id="Name"
                name="name"
                onChange={handleChange}
                value={details.name}
              />
            </div>
            {/* group box end */}
            {/* group box start */}
            <div className="form-group">
              <label htmlFor="Name" className="label">
                description <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Create and edit core functions/features"
                className={`input ${details.Subsidiary_Name && "input-active"}`}
                id="Name"
                name="description"
                onChange={handleChange}
                value={details.description}
              />
            </div>
            {/* group box end */}
          </div>
          {/* input two box end */}
          <div className="underline"></div>
          {/* <p className="name-text title">User with this role</p> */}
          {/* three box start */}
          <div className="three-box title-three-box">
            <p className="text-title">Full Name</p>
            <p className="text-title">Email address</p>
          </div>
          {/* three box end */}
          {/* three box content start */}
          {user?.role === "creator administrator" && (
            <div className="three-box three-box-content">
              <div className="box">
                <figure className="img-box">
                  <img src={userImg} alt="" className="img" />
                </figure>
                <p className="name">Seyi Ajayi</p>
              </div>
              <div className="box">
                <p className="email">seyi@zojatech.com</p>
              </div>
              <div
                onClick={() => setShowOwnerShip(true)}
                className="box last-box"
              >
                <p className="transfer">Transfer ownership</p>
              </div>
            </div>
          )}
          {/* three box content end */}
          {/* three box start */}
          <p className="title">Assign User to role</p>
          <div className="three-box title-three-box">
            <p className="text-title">Select user by username</p>
            {/* <p className="text-title">Email address</p> */}
          </div>
          {/* three box end */}
          {/* three box content start */}
          <div className="three-box three-box-content">
            <div className="form-group">
              {/* <label htmlFor="" className="form-labe">Users</label> */}
              <Select
                className={`select-wrap ${
                  details?.users?.length > 0 && "select-wrap-active"
                }`}
                isMulti
                styles={reactSelectStyle}
                placeholder="Select user"
                value={details.users}
                onChange={(e) => {
                  handleAssignUser(e);
                  setDetails((prev) => {
                    return { ...prev, users: e };
                  });
                }}
                options={formatUserDrop(usersDrop)}
              />
            </div>
            {/* button assign  */}
          </div>
          {/* three box content end */}
          <p className="title">Defined Permissions</p>
          <div className="underline"></div>
          {/* permission box start */}
          {permissionsList?.map((chi, idx) => {
            const { name, permissions } = chi;
            return (
              <div className="permission-box" key={idx}>
                <p
                  style={{
                    color: "var(--blue-color)",
                    textTransform: "capitalize",
                  }}
                  className="blue-title three-box"
                >
                  {name}
                </p>
                <div
                  style={{ marginBottom: "1.5rem" }}
                  className="three-box title-three-box"
                >
                  <p>Permission</p>
                  <p>Description</p>
                </div>
                {/* end of three title box */}
                {/* common check box start */}
                {permissions?.map((chi, idx) => {
                  const { name, description, id } = chi;
                  return (
                    <CommonCheckBox
                      // noBorder={permissionList.length}
                      idx={id || idx}
                      per={name}
                      des={description}
                      key={id || idx}
                      checked={details?.permissions?.includes(name)}
                      onChange={() => {
                        handleAddPermissionToList(name);
                      }}
                    />
                  );
                })}
                {/* common check box end */}
              </div>
            );
          })}
          {/* permission box end */}
        </div>
        {/* role information box end */}
      </div>
      {/* </DashboardLayout> */}
      {/* ownership role add start */}
      <Modal effect="fadeInLeft" visible={showOwnership}>
        <OwnerShipTransferModal
          onFinish={() => {
            actionCtx?.setIsModalOut(false);
            setShowOwnerShip(false);
            setRefreshPage(!refreshPage);
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowOwnerShip(false);
          }}
        />
      </Modal>
      {/* ownership role add end */}
    </>
  );
};

export default UpdateRole;
