import EmployeeDashboardHome from "../../../component/pages/dashboard/home";
import EmployeeActualGrossProfitIndex from "../../../component/pages/dashboard/home/ActualGP/actualGrossProfit";
import EmployeeActualReimbursementIndex from "../../../component/pages/dashboard/home/ActualGP/Reimbursement/ActualReimbursement";
import EmployeeActualNetProfitIndex from "../../../component/pages/dashboard/home/ActualProfit/actualProfitIndex";
import EmployeePendingReimbursementIndex from "../../../component/pages/dashboard/home/pendingReimbursement/PendingReimbursement";
import EmployeeNetProfitProjectionIndex from "../../../component/pages/dashboard/home/ProfitProjection/netProfitIndex";
import EmployeeProjectionGrossProfitIndex from "../../../component/pages/dashboard/home/ProjectionGP/projectionGpIndex";

export const Employee_dashboard_home_route_group = [
  {
    path: "/employee-dashboard-home",
    component: EmployeeDashboardHome,
    goBack: false,
    organization: true,
    pageTitle: "Dashboard",
  },
  {
    path: "/employee-dashboard-home-net-profit-projection",
    component: EmployeeNetProfitProjectionIndex,
    goBack: true,
    organization: true,
    pageTitle: "Dashboard",
  },
  {
    path: "/employee-dashboard-home-actual-net-profit-projection",
    component: EmployeeActualNetProfitIndex,
    pageTitle: "Dashboard",
    organization: true,
    goBack: true,
  },
  {
    path: "/employee-dashboard-home-gross-profit-projection",
    component: EmployeeProjectionGrossProfitIndex,
    goBack: true,
    organization: true,
    pageTitle: "Dashboard",
  },
  {
    path: "/employee-dashboard-home-actual-gross-profit",
    component: EmployeeActualGrossProfitIndex,
    goBack: true,
    organization: true,
    pageTitle: "Dashboard",
  },
  {
    path: "/employee-dashboard-home-actual-reimbursement",
    component: EmployeeActualReimbursementIndex,
    goBack: true,
    organization: true,
    pageTitle: "Dashboard",
  },
  {
    path: "/employee-dashboard-home-awaiting-reimbursement",
    component: EmployeePendingReimbursementIndex,
    goBack: true,
    organization: true,
    pageTitle: "Dashboard",
  },
];
