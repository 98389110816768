import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectMain.css";
import Select from "react-select";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import Modal from "react-awesome-modal";
import OverviewCardExpenseModal from "./modals/OverviewCardExpenseModal";
import FinalApprovalModal from "./modals/FinalApprovalModal";
import ApprovalModal from "./modals/ApprovalModal";
import RejectModal from "./modals/RejectModal";
import ComentModal from "./modals/CommentModal";
import BigImgModal from "./modals/BigImgModal";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import {
  getActualExpenseChartByDepartment,
  getActualExpenseChartByDepartmentByStatus,
  getExpActualComments,
} from "../../../../../redux/actuals/expense";
import {
  checkCurrencyFormat,
  formatNumberWithComma,
  perPageOptions,
  reactSelectStyle,
  reactSelectStyleCommonSort,
  // FormatNumWithCurrency,
  reactSelectStyleHeaderNav,
  reactSelectStyleTable,
} from "../../../../../helper/Helpers";
import { getDepartments } from "../../../../../redux/department/deparment";
import { getProjectionDownload } from "../../../../../redux/projections/RevenueProjection";
import { Dna } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import { notifyNextApprover } from "../../../../../redux/projections/ExpenditureProjection";
import AllACtionWrapper from "../../../../fragments/AllACtionWrapper";
import { getFiscalYears } from "../../../../../redux/fiscal-year/fiscalYear";

const ExpenseActuallMain = () => {
  // actual_expense_single
  const history = useHistory();
  const [indexNo, setIndexNo] = useState("");
  const [singleChild, setSingleChild] = useState({});
  const { loading, actual_expense_single, loadingPage } = useSelector(
    (state) => state.actual_expense_chart
  );
  const { departmentDrop } = useSelector((state) => state.department);
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);

  const [filterValTwo, setFilterValTwo] = useState("");
  const [fiscalYearVal, setFiscalYearVal] = useState("");
  const [showModal, setShowModal] = useState({
    overview: false,
    approve: false,
    approve_all: false,
    reject: false,
    comment: false,
    big_img: false,
  });

  const [refreshTable, setRefreshTable] = useState(false);
  const handleFisnish = () => {
    actionCtx?.setIsModalOut(false);
    setShowModal({
      overview: false,
      approve: false,
      approve_all: false,
      reject: false,
      comment: false,
    });
    setRefreshTable(!refreshTable);
  };

  // const categoryList = [
  //   { label: "Current Fiscal Year", value: 1 },
  //   { label: "Previous Year", value: 2 },
  //   { label: "Today", value: 3 },
  //   { label: "Yesterday", value: 4 },
  //   { label: "Last 7 days", value: 5 },
  //   { label: "Monthly", value: 6 },
  // ];

  const [details, setDetails] = useState({
    name: "",
    category: "",
    class: "",
    code: "",
    description: "",
  });

  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartments(actionCtx?.activeOrg?.id));
    dispatch(getFiscalYears(actionCtx?.activeOrg?.id));
    getMainExpenseProjection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, actionCtx?.currencyValue]);

  const [currentPage, setCurrentPage] = useState(1);
  const getMainExpenseProjection = async (dept_id) => {
    const obj = {
      dept_id:
        dept_id ||
        filterValTwo?.value ||
        localStorage.getItem("actual-expense-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
      page: currentPage,
      status: filterVal?.value || "",
      fis_id: fiscalYearVal?.value || "",
    };
    const data = await dispatch(getActualExpenseChartByDepartment(obj));
    if (data?.payload?.success) {
      if (data?.payload?.data?.expense?.data?.length > 0) {
        const info = data?.payload?.data?.expense?.data[0]?.department;
        setFilterValTwo({ label: info?.name, value: info?.id });
      }
    }
  };

  const tableHeadList = [
    "Chart of Account",
    "Expenditure Class",
    "Description",
    "Quantity",
    `Units Price  ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    `Total Value ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    `Reimbursed amount ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    "Source",
    "Status",
  ];

  const tableFilterList = [
    { label: "All Projection", value: "" },
    { label: "Approved", value: "approved" },
    { label: "Pending", value: "pending" },
    { label: "Pending My Approval", value: "pending_my_current_approver" },
    { label: "Rejected", value: "rejected" },
  ];
  // const actionCtx = useContext(ActionContext);
  const [filterVal, setFilterVal] = useState("");
  const handleGetMainExpenseProjectionByStatus = async (param) => {
    const obj = {
      dept_id:
        filterValTwo?.value ||
        localStorage.getItem("actual-expense-chart-id") ||
        localStorage.getItem("actual-expense-report"),
      org_id: actionCtx?.activeOrg?.id,
      status: param,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
      fis_id: fiscalYearVal?.value || "",
    };

    if (obj?.status) {
      dispatch(getActualExpenseChartByDepartmentByStatus(obj));
    } else {
      dispatch(getActualExpenseChartByDepartment(obj));
    }
  };

  const handlePagination = (param) => {
    const num = param.split("=")[1];
    setCurrentPage(num);
    const obj = {
      dept_id:
        filterValTwo?.value || localStorage.getItem("actual-expense-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      status: filterVal?.value,
      page: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
      fis_id: fiscalYearVal?.value || "",
    };
    if (obj?.status) {
      dispatch(getActualExpenseChartByDepartmentByStatus(obj));
    } else {
      dispatch(getActualExpenseChartByDepartment(obj));
    }
  };

  const handlePaginationNum = (num) => {
    // const num = param.split("=")[1];
    setCurrentPage(num);
    const obj = {
      dept_id:
        filterValTwo?.value || localStorage.getItem("actual-expense-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      status: filterVal?.value,
      page: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
      fis_id: fiscalYearVal?.value || "",
    };
    if (obj?.status) {
      dispatch(getActualExpenseChartByDepartmentByStatus(obj));
    } else {
      dispatch(getActualExpenseChartByDepartment(obj));
    }
  };

  const [allApproveId, setAllAppproveId] = useState([]);
  const handleApproveAll = () => {
    const list = editList?.expense?.data?.filter(
      (chi) => chi?.status === "pending" && chi?.current_approver
    );
    const newList = list.map((chi) => chi?.id);
    setAllAppproveId(newList);
  };

  const exportType = [
    { label: "CSV", value: "excel" },
    { label: "PDF", value: "pdf" },
  ];
  const [formatSelect, setFormatSelect] = useState({
    label: "CSV",
    value: "excel",
  });
  const [loadingDownload, setLoadingDownload] = useState(false);
  const handleCsvDownload = async () => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      dept_id:
        filterValTwo?.value || localStorage.getItem("actual-expense-chart-id"),
      type: "actual_expense",
      format: formatSelect?.value,
      status: filterVal.value,
      cur_id: actionCtx?.currencyValue?.id || "",
      fis_id: fiscalYearVal?.value || "",
    };
    setLoadingDownload(true);
    const data = await dispatch(getProjectionDownload(obj));
    //  console.log(data);
    if (data?.payload?.status === 200) {
      downloadCsv(data.payload.data.url);
      setLoadingDownload(false);
    } else {
      setLoadingDownload(false);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const { loadingNotify } = useSelector(
    (state) => state.expenditure_projection_chart
  );
  const bellIconTwo = (
    <svg
      className="img"
      width="37"
      height="48"
      viewBox="0 0 37 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13465_384352)">
        <g clip-path="url(#clip1_13465_384352)">
          <path
            d="M18.0039 13.9951C22.0538 13.9951 25.3606 17.1899 25.4997 21.2442L25.5039 21.4951V25.5921L26.8839 28.7481C26.953 28.906 26.9886 29.0765 26.9886 29.2489C26.9886 29.9392 26.429 30.4989 25.7386 30.4989L21.0039 30.5004C21.0039 32.1572 19.6608 33.5004 18.0039 33.5004C16.4062 33.5004 15.1002 32.2515 15.009 30.6767L15.0035 30.4981L10.2788 30.4989C10.1074 30.4989 9.93792 30.4637 9.78077 30.3954C9.14757 30.1204 8.85722 29.3841 9.13226 28.7509L10.5039 25.593V21.495C10.5045 17.3402 13.856 13.9951 18.0039 13.9951ZM19.5035 30.4981L16.5039 30.5004C16.5039 31.3288 17.1755 32.0004 18.0039 32.0004C18.7836 32.0004 19.4244 31.4055 19.497 30.6448L19.5035 30.4981ZM18.0039 15.4951C14.6837 15.4951 12.0044 18.1694 12.0039 21.4951V25.9047L10.6599 28.9989H25.3564L24.0039 25.9057L24.004 21.508L24.0003 21.2828C23.8892 18.0493 21.2455 15.4951 18.0039 15.4951ZM27.0039 20.2489H29.0039C29.4181 20.2489 29.7539 20.5847 29.7539 20.9989C29.7539 21.3786 29.4718 21.6924 29.1057 21.742L29.0039 21.7489H27.0039C26.5897 21.7489 26.2539 21.4131 26.2539 20.9989C26.2539 20.6192 26.5361 20.3054 26.9021 20.2557L27.0039 20.2489ZM7.00391 20.2489H9.00391C9.41812 20.2489 9.75391 20.5847 9.75391 20.9989C9.75391 21.3786 9.47175 21.6924 9.10568 21.742L9.00391 21.7489H7.00391C6.58969 21.7489 6.25391 21.4131 6.25391 20.9989C6.25391 20.6192 6.53606 20.3054 6.90214 20.2557L7.00391 20.2489ZM28.6039 14.5489C28.8298 14.8501 28.7949 15.266 28.5377 15.5259L28.4539 15.5989L26.4539 17.0989C26.1225 17.3474 25.6524 17.2803 25.4039 16.9489C25.178 16.6476 25.2129 16.2317 25.4701 15.9719L25.5539 15.8989L27.5539 14.3989C27.8853 14.1504 28.3554 14.2175 28.6039 14.5489ZM8.45391 14.3989L10.4539 15.8989C10.7853 16.1474 10.8524 16.6175 10.6039 16.9489C10.3554 17.2803 9.88528 17.3474 9.55391 17.0989L7.55391 15.5989C7.22254 15.3504 7.15538 14.8803 7.40391 14.5489C7.65243 14.2175 8.12254 14.1504 8.45391 14.3989Z"
            fill="var(--blue-color)"
          />
        </g>
      </g>
      <rect
        x="0.503906"
        y="4.49902"
        width="35"
        height="39"
        rx="5.5"
        stroke="var(--blue-color)"
      />
      <defs>
        <clipPath id="clip0_13465_384352">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6.00391 11.999)"
          />
        </clipPath>
        <clipPath id="clip1_13465_384352">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6.00391 11.999)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const [editList, setEditList] = useState([]);
  const [headerCheck, setHeaderCheck] = useState(true);
  useEffect(() => {
    setEditList(actual_expense_single);
    // console.log(actual_expense_single);
  }, [actual_expense_single]);
  const handleEditListCheck = (id) => {
    const newList = editList?.expense?.data?.map((chi) =>
      chi?.id === id
        ? { ...chi, current_approver: !chi?.current_approver }
        : chi
    );
    const newObj = {
      ...editList,
      expense: { ...editList?.expense, data: newList },
    };
    setEditList(newObj);
  };

  return (
    <>
      {/* <DashboardLayout goBack pageTitle={`Actual Expenditure`}> */}
      {/* main wrap start */}
      <div className="expense-projection-main-wrap">
        {/* title arrow select box start */}
        <div className="title-arrow-select-box">
          <div className="subtitle-box">
            <p onClick={() => history.goBack()} className="text">
              All Actual Expenditure
            </p>
            <FaAngleRight className="icon" />
            <p
              onClick={() => {
                console.log(actual_expense_single);
              }}
              className="active text"
            >
              {actual_expense_single?.department}
            </p>
            {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
          </div>
          {/* end of subtitle box */}
          {/* select fiscal year wrap start */}
          <div className="select-wrap">
            <label className="label" htmlFor="">
              Fiscal Year
            </label>
            <Select
              styles={reactSelectStyleCommonSort}
              // isSearchable={true}
              // isMulti
              placeholder="Select Year"
              className="select"
              value={fiscalYearVal}
              onChange={(e) => {
                setFiscalYearVal(e);
                // const obj = { ...actionCtx?.sortOptions, fis_id: e };
                // actionCtx?.setSortOptions(obj);
              }}
              options={fiscalYearDrop}
            />
          </div>
          {/* select fiscal year wrap end */}
          {/* select fiscal year wrap start */}
          <div style={{ marginLeft: "3rem" }} className="select-wrap">
            <label className="label" htmlFor="">
              Export type
            </label>
            <Select
              // isSearchable={true}
              // isMulti
              placeholder="Select Option"
              className="select"
              value={formatSelect}
              onChange={(e) => {
                setFormatSelect(e);
              }}
              options={exportType}
              styles={reactSelectStyle}
            />
          </div>
          {/* select fiscal year wrap end */}
        </div>
        {/* title arrow select box end */}
        {/* total info detail box start */}
        <div className="total-info-detail-box">
          {/* detail-box statrt */}
          <div className="detail-box">
            <p className="title"> Expense Projected</p>
            {loading || loadingPage ? (
              <Skeleton width={`50%`} height={17} />
            ) : (
              <p className="value">
                {formatNumberWithComma(
                  String(actual_expense_single?.total_actual_expense || "0")
                ) || <>&nbsp:</>}
              </p>
            )}
          </div>
          {/* detail-box end */}
        </div>
        {/* total info detail box end */}
        {/* card detail wrap start */}
        <div className="card-detail-wrap">
          {/* card start */}
          <div
            onClick={() => {
              handleGetMainExpenseProjectionByStatus("approved");
              setFilterVal({
                label: "Approved",
                value: "approved",
              });
            }}
            className="card-box"
          >
            <p style={{ color: "#07A287" }} className="name">
              Total Approved
            </p>
            <p className="value">
              {formatNumberWithComma(
                String(actual_expense_single?.Approved || "0")
              )}
            </p>
          </div>
          {/* card end */}
          {/* card start */}
          <div
            onClick={() => {
              handleGetMainExpenseProjectionByStatus("pending");
              setFilterVal({
                label: "Pending",
                value: "pending",
              });
            }}
            className="card-box"
          >
            <p style={{ color: "#FCA549" }} className="name">
              Total Pending
            </p>
            <p className="value">
              {" "}
              {formatNumberWithComma(
                String(actual_expense_single?.awaiting_approval || "0")
              )}
            </p>
          </div>
          {/* card end */}
          {/* card start */}
          <div
            onClick={() => {
              handleGetMainExpenseProjectionByStatus(
                "pending_my_current_approver"
              );
              setFilterVal({
                label: "Pending My Approval",
                value: "pending_my_current_approver",
              });
            }}
            className="card-box"
          >
            <p style={{ color: "#FCA549" }} className="name">
              Total Pending My Approval
            </p>
            {loading || loadingPage ? (
              <Skeleton width={`50%`} height={17} />
            ) : (
              <p className="value">
                {formatNumberWithComma(
                  String(actual_expense_single?.pending_my_approver || "0")
                ) || <>&nbsp:</>}
              </p>
            )}
          </div>
          {/* card end */}
          {/* card start */}
          <div
            onClick={() => {
              handleGetMainExpenseProjectionByStatus("rejected");
              setFilterVal({
                label: "Rejected",
                value: "rejected",
              });
            }}
            className="card-box"
          >
            <p style={{ color: "#CC0905" }} className="name">
              Total Rejected
            </p>
            <p className="value">
              {" "}
              {formatNumberWithComma(
                String(actual_expense_single?.rejected || "0")
              )}
            </p>
          </div>
          {/* card end */}
        </div>
        {/* card detail wrap end */}
        {/* fitler and button box start */}
        <div className="filter-approve-btn-box">
          {/* perpage box start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              position: "relative",
              zIndex: "20",
              marginRight: "2rem",
            }}
            className="perpage-wrap"
          >
            <span style={{ fontWeight: "600" }}> All Actual Expense</span>{" "}
            <Select
              styles={reactSelectStyleTable}
              onChange={(e) => {
                actionCtx?.setPerpage(e);
              }}
              value={actionCtx?.perPage}
              options={perPageOptions}
            />
          </div>
          {/* perpage box end */}
          {/* select fiscal year wrap start */}
          <div
            style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}
            className="select-wrap"
          >
            {/* <label className="label" htmlFor="fitler-table">
                All Projection
              </label> */}
            <Select
              // isSearchable={true}
              // isMulti
              instanceId={`filter-table`}
              styles={reactSelectStyleHeaderNav}
              placeholder=" All Projection"
              className="select"
              value={filterVal}
              onChange={(e) => {
                setFilterVal(e);
                handleGetMainExpenseProjectionByStatus(e.value);
              }}
              options={tableFilterList}
            />
            <Select
              // isSearchable={true}
              // isMulti
              instanceId={`filter-table`}
              styles={reactSelectStyleHeaderNav}
              placeholder="Sort by department"
              className="select"
              value={filterValTwo}
              onChange={(e) => {
                setFilterValTwo(e);
                getMainExpenseProjection(e.value);
              }}
              options={departmentDrop}
            />
          </div>
          {/* select fiscal year wrap end */}
          {/* notify bell start */}
          <div className="notify-bell-box">
            {!loadingNotify ? (
              <figure
                onClick={() => {
                  const obj = {
                    department_id:
                      filterValTwo?.value ||
                      localStorage.getItem("actual-expense-chart-id"),
                    projection_type: "actual_expense",
                  };
                  dispatch(notifyNextApprover(obj));
                }}
                className="img-box"
              >
                {/* <img src={bellIcon} alt="" className="img" /> */}
                {bellIconTwo}
              </figure>
            ) : (
              <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                <Dna
                  visible={true}
                  height="60"
                  width="120"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
          </div>
          {/* notify bell end */}
          <AllACtionWrapper>
            {/* btn approve start */}
            {loadingDownload ? (
              <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                <Dna
                  visible={true}
                  height="60"
                  width="120"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <>
                {actual_expense_single?.expense?.data?.length > 0 && (
                  <ButtonBlue
                    onClick={handleCsvDownload}
                    // style={{ marginRight: "1.5rem" }}
                    bgColor={`var(--blue-color)`}
                    text={`Download ${formatSelect?.label}`}
                  />
                )}
              </>
            )}
            <ButtonBlue
              onClick={() => {
                setShowModal((prev) => {
                  return { ...prev, approve_all: true };
                });
                handleApproveAll();
              }}
              text={`Approve all`}
            />
            {/* btn approve end */}
          </AllACtionWrapper>
        </div>
        {/* fitler and button box end */}
        {/* table box start */}
        <ActualReuseableTable
          massSelectable={
            filterVal?.label === "Pending My Approval" &&
            editList?.expense?.data?.length > 0
          }
          checkValue={headerCheck}
          onMassCheck={(e) => {
            setHeaderCheck(!headerCheck);
            const newList = editList?.expense?.data?.map((chi) => {
              return { ...chi, current_approver: e.target.checked };
            });
            const newObj = {
              ...editList,
              expense: { ...editList?.expense, data: newList },
            };
            setEditList(newObj);
          }}
          checkId={`jysgmd`}
          loading={loading && editList?.length < 1}
          loadingNum={8}
          useNumPagination
          currentPage={editList?.expense?.current_page}
          totalTableLength={editList?.expense?.total}
          onNumPage={(e) => {
            handlePaginationNum(e);
          }}
          dontShowBnSearch
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          eight={tableHeadList[6]}
          nine={`Status`}
          seven={tableHeadList[5]}
          ten
          eleven
          // action
          prevPage={editList?.expense?.prev_page_url}
          nextPage={editList?.expense?.next_page_url}
          onNextPage={() => handlePagination(editList?.expense?.next_page_url)}
          onPrevPage={() => handlePagination(editList?.expense?.prev_page_url)}
          toPage={editList?.expense?.to}
          fromPage={editList?.expense?.from}
          totalPage={editList?.expense?.total}
        >
          {editList?.expense?.data?.map((chi, idx) => {
            const {
              chart_of_account,
              description,
              units,
              total_expense,
              unit_price,
              status,
              current_approver,
              id,
              // reimbursement,
            } = chi;
            return (
              <TableBodyRow
                checkBoxType={filterVal?.label === "Pending My Approval"}
                onCheck={(e) => {
                  handleEditListCheck(id);
                }}
                checkValue={current_approver}
                checkId={`${id}-${idx}`}
                loading={loading || loadingPage}
                key={idx}
                one={chart_of_account?.name}
                two={chart_of_account?.class}
                three={description}
                four={formatNumberWithComma(units)}
                progressType={
                  status === "approved"
                    ? "accept"
                    : status === "rejected"
                    ? "reject"
                    : "Pending"
                }
                statusStep
                statusList={chi?.approval_status}
                eight={
                  formatNumberWithComma(String(chi?.total_reimbursed)) || "---"
                }
                nine={
                  chi?.is_zoho_expense === 0 ? "Revvex" : "Zoho" || <>&nbsp;</>
                }
                five={formatNumberWithComma(String(unit_price || 0))}
                six={formatNumberWithComma(String(total_expense || 0))}
                action
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                //   updateText={` Update Branch`}
                onNextPage={() => {
                  dispatch(getExpActualComments(chi?.id));
                  setSingleChild(chi);
                  setShowModal((prev) => {
                    return { ...prev, overview: true };
                  });
                  actionCtx?.setIsModalOut(true);
                }}
                // progressType={status === "approved" ? "Approved" : "Pending"}
                //   onUpdate={() =>
                //     history.push(
                //       "/dashboard-administration-admin-settings-branch-update"
                //     )
                //   }
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
      </div>
      {/* main wrap end */}
      {/* </DashboardLayout> */}
      {/* approve modal start */}
      <Modal effect="fadeInDown" visible={showModal.overview}>
        <OverviewCardExpenseModal
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, overview: false };
            });
          }}
          onApprove={() =>
            setShowModal((prev) => {
              return { ...prev, approve: true, overview: false };
            })
          }
          onReject={() =>
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            })
          }
          onViewBigImg={() => {
            setShowModal((prev) => {
              return { ...prev, overview: false, big_img: true };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInRight" visible={showModal.approve_all}>
        <FinalApprovalModal
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve_all: false };
            });
          }}
          allId={allApproveId}
          onFinish={handleFisnish}
        />
      </Modal>
      {/* approve modal end */}

      {/* approve modal start */}
      <Modal effect="fadeInUp" visible={showModal.approve}>
        <ApprovalModal
          onFinish={handleFisnish}
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false, overview: true };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}

      {/* approve modal start */}
      <Modal effect="fadeInLeft" visible={showModal.reject}>
        <RejectModal
          onFinish={handleFisnish}
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false, overview: true };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInLeft" visible={showModal.comment}>
        <ComentModal
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, comment: false, overview: true };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* approve modal start */}
      <Modal visible={showModal.big_img}>
        <BigImgModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, overview: true, big_img: false };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
    </>
  );
};

export default ExpenseActuallMain;
