import CheckMail from "../../component/pages/auth/CheckMail";
import CreateNewPassword from "../../component/pages/auth/CreateNewPassword";
import EmailVerified from "../../component/pages/auth/EmailVerified";
import ForgetPassword from "../../component/pages/auth/ForgetPassword";
import GetStarted from "../../component/pages/auth/GetStarted";
import Login from "../../component/pages/auth/Login";
import RecoverPassword from "../../component/pages/auth/RecoverPassword";
import SignUp from "../../component/pages/auth/SignUp";
import VerifyEmail from "../../component/pages/auth/VerifyEmail";
import SetupOrganizationSelect from "../../component/pages/dashboard/SetupOrganizationSelect";
import SetupOrganizationSetting from "../../component/pages/dashboard/SetupOrganizationSetting";




export const Onboarding_route_group = [
  {path: "/get-started", component: GetStarted}, 
  {path: "/sign-up", component: SignUp}, 
  {path: "/check-mail", component: CheckMail}, 
  {path: "/verify-email", component: VerifyEmail}, 
  {path: "/email-verify", component: EmailVerified}, 
  {path: "/login", component: Login}, 
  {path: "/forget-password", component: ForgetPassword}, 
  {path: "/recover-password", component: RecoverPassword}, 
  {path: "/create-new-password", component: CreateNewPassword}, 
  {path: "/setup-org", component: SetupOrganizationSelect}, 
  {path: "/setup-org-setting", component: SetupOrganizationSetting}, 
]