import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getRevenueProjectionCharts = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/revenue?organization_id=${
          formData?.org_id
        }&currency_conversion_id=${formData?.cur_id}&perPage=${
          formData?.per_page || 20
        }`
      );
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        await thunkAPI.dispatch(setRevenueProjections(data?.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProJectionActivityLog = createAsyncThunk(
  "admin/get-exp-pro-activity-log",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`/revenue-project/${formData?.id}`);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        // const branchInfo = data.data.approval_type;
        // await thunkAPI.dispatch(setApprovals(data?.data?.approvals));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjectionChartsBySort = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/revenue?organization_id=${
          formData?.org_id
        }&department_id=${formData?.dept_id || ""}&branch_id=${
          formData?.bra_id || ""
        }&subsidiary_id=${formData?.sub_id || ""}&currency_conversion_id=${
          formData?.cur_id || ""
        }&month=${formData?.date || ""}&perPage=${
          formData?.per_page || 20
        }&fiscal_year_id=${formData?.fis_id || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        await thunkAPI.dispatch(setRevenueProjections(data?.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjectionChartsPagination = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/projections/revenue?organization_id=${formData?.org_id}&page=${
          formData?.num
        }&currency_conversion_id=${formData?.cur_id}&perPage=${
          formData?.per_page || 20
        }`
      );
      // console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries)); https://develop-api.revvex.io/api/admin/projections/revenue?page=1
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        await thunkAPI.dispatch(setRevenueProjections(data?.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevProjectionComments = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/comments/revenue/${formData?.id}`
      );
      console.log("branch", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data);
        const branchInfo = data.data.revenue_projection_comments;
        await thunkAPI.dispatch(setComments(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevProjectionPdf = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/downloads/get-pdf?id=${formData?.id}&type=${formData?.type}&currency_conversion_id=${formData?.cur_id}`
      );
      console.log("branch", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data);
        // const branchInfo = data.data.revenue_projection_comments;
        // await thunkAPI.dispatch(setComments(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getProjectionDownload = createAsyncThunk(
  "admin/get-pro-download",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(
        `/admin/report/download-report?organization_id=${
          formData?.org_id
        }&department_id=${formData?.dept_id}&report_type=${
          formData?.type
        }&report_format=${formData?.format}&status=${
          formData?.status || ""
        }&user_id=${formData?.user_id || ""}&currency_conversion_id=${
          formData?.cur_id || ""
        }&fiscal_year_id=${formData?.fis_id || ""}`
      );
      // console.log(data);
      if (data?.status === 200) {
        // console.log(data);
        return data;
      }
      return data;
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const addProjectionCommentRev = createAsyncThunk(
  "admin/approve-exp-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `employee/comments/revenue/add-comment?revenue_project_id=${formData?.id}`,
        formData
      );
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovalType(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteCommentRevProjection = createAsyncThunk(
  "admin/delete-delete",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `employee/comments/revenue/${formData?.exp_id}/${formData?.comment_id}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjectionChartByDepartment = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/projections/department-revenue?organization_id=${
          formData?.org_id
        }&filter[department_id]=${
          formData?.dept_id === "all" ? "" : formData?.dept_id || ""
        }&user_id=${
          formData?.status === "pending_my_current_approver"
            ? ""
            : formData?.user_id || ""
        }&filter[status]=${formData?.status || ""}&page=${
          formData?.page || "1"
        }&currency_conversion_id=${formData?.cur_id}&perPage=${
          formData?.per_page || 20
        }&fiscal_year_id=${formData?.fis_id}`
      );
      // console.log("approval-class", data.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        //   console.log(data.data);
        await thunkAPI.dispatch(setRevenueProjectionSingle(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjectionChartByDepartmentApproveCurrent =
  createAsyncThunk("admin/get-approval-class", async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `get-my-pending-projections-yet-to-be-approved?organization_id=${
          formData?.org_id
        }&filter[department_id]=${
          formData?.dept_id
        }&projection_type=revenue_projection&page=${
          formData?.page || "1"
        }&currency_conversion_id=${formData?.cur_id}&perPage=${
          formData?.per_page || 20
        }&fiscal_year_id=${formData?.fis_id || ""}`
      );
      // console.log("approval-class", data.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        //   console.log(data.data);
        await thunkAPI.dispatch(setAwaitingListApprove(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  });

export const getRevenueProjectionChartByProjection = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/revenue_projection/${formData?.projection_id}`
      );
      // console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setRevenueProjectionOverView(data.data.revenueproject)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovalType = createAsyncThunk(
  "admin/get-approval-type",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`admin/approvalType`);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovalType(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovalByClass = createAsyncThunk(
  "admin/get-approval-by-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/approvals/organization/${formData?.org_id}/${formData?.class_id}`
      );
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data.data.approvals);
        // const branchInfo = data.data.approval_type;
        // await thunkAPI.dispatch(setApprovals(data?.data?.approvals));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesWithPagination = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/branches/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesByFilter = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/branches/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        // const branchInfo = data.data.branches;
        // await thunkAPI.dispatch(setApprovals(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesPagination = createAsyncThunk(
  "admin/get-branches-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranch = createAsyncThunk(
  "admin/get-branch",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/branches/${formData}`);
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesBySubsidiary = createAsyncThunk(
  "admin/get-branch",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(
        `/admin/branches/subsidiary/${formData}`
      );
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // const branchInfo = data.data.branches;
        // await thunkAPI.dispatch(setBranchSubDrop(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const setUpApprovalFlow = createAsyncThunk(
  "admin/create-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { org_id } = formData;
      const data = await axios.post(
        `/admin/organizations/${org_id}/approval-setup`,
        formData
      );
      // console.log('set-up-org', data);
      if (data?.data?.success === false) {
        toast.error(data.data.message, {
          theme: "colored",
        });
        // console.log(data);
        // return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.success === true) {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // console.log(data);
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response?.status === 422) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "admin/delete-delete",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/branches/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateBranch = createAsyncThunk(
  "admin/update-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/branches/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const revenue_projection_chart = createSlice({
  name: "revenue_projection_chart",
  initialState: {
    revenue_projections: [],
    revenue_projection_single: {},
    revenue_projection_overview: {},
    approvalClass: [],
    comments: [],
    approvalType: [],
    branches: [],
    awaitingListApprove: [],
    links: {},
    meta: {},
    branch: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
    loadingDownload: false,
  },
  reducers: {
    setRevenueProjections: (state, action) => {
      state.isAuth = true;
      state.revenue_projections = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setAwaitingListApprove: (state, action) => {
      state.isAuth = true;
      state.awaitingListApprove = action.payload;
      state.links = action.payload.links;
    },
    setComments: (state, action) => {
      state.isAuth = true;
      state.comments = action.payload;
      state.links = action.payload.links;
    },
    setRevenueProjectionSingle: (state, action) => {
      state.isAuth = true;
      state.revenue_projection_single = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setRevenueProjectionOverView: (state, action) => {
      state.isAuth = true;
      state.revenue_projection_overview = action.payload;
    },
    setApprovalClass: (state, action) => {
      state.isAuth = true;
      state.approvalClass = action.payload;
    },
    setApprovalType: (state, action) => {
      state.isAuth = true;
      state.approvalType = action.payload;
    },
    setBranch: (state, action) => {
      state.isAuth = true;
      state.branch = action.payload;
    },
  },
  extraReducers: {
    [setUpApprovalFlow.pending]: (state) => {
      state.loading = true;
    },
    [setUpApprovalFlow.fulfilled]: (state) => {
      state.loading = false;
    },
    [setUpApprovalFlow.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [getRevProjectionComments.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevProjectionComments.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevProjectionComments.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [addProjectionCommentRev.pending]: (state) => {
      state.loading = true;
    },
    [addProjectionCommentRev.fulfilled]: (state) => {
      state.loading = false;
    },
    [addProjectionCommentRev.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteCommentRevProjection.pending]: (state) => {
      state.loading = true;
      state.loadingPage = true;
    },
    [deleteCommentRevProjection.fulfilled]: (state) => {
      state.loading = false;
      state.loadingPage = false;
    },
    [deleteCommentRevProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectionChartByDepartment.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevenueProjectionChartByDepartment.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevenueProjectionChartByDepartment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectionChartByProjection.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevenueProjectionChartByProjection.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevenueProjectionChartByProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectionChartsPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevenueProjectionChartsPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevenueProjectionChartsPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectionCharts.pending]: (state) => {
      state.loading = true;
    },
    [getRevenueProjectionCharts.fulfilled]: (state) => {
      state.loading = false;
    },
    [getRevenueProjectionCharts.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [setUpApprovalFlow.pending]: (state) => {
      state.loading = true;
    },
    [setUpApprovalFlow.fulfilled]: (state) => {
      state.loading = false;
    },
    [setUpApprovalFlow.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesBySubsidiary.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesBySubsidiary.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesBySubsidiary.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovalByClass.pending]: (state) => {
      state.loading = true;
    },
    [getApprovalByClass.fulfilled]: (state) => {
      state.loading = false;
    },
    [getApprovalByClass.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectionChartsBySort.pending]: (state) => {
      state.loading = true;
    },
    [getRevenueProjectionChartsBySort.fulfilled]: (state) => {
      state.loading = false;
    },
    [getRevenueProjectionChartsBySort.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getRevProjectionPdf.pending]: (state) => {
      state.loading = true;
    },
    [getRevProjectionPdf.fulfilled]: (state) => {
      state.loading = false;
    },
    [getRevProjectionPdf.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getProjectionDownload.pending]: (state) => {
      state.loadingDownload = true;
    },
    [getProjectionDownload.fulfilled]: (state) => {
      state.loadingDownload = false;
    },
    [getProjectionDownload.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingDownload = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBranch,
  setBranches,
  setApprovalClass,
  setRevenueProjectionOverView,
  setApprovalType,
  setRevenueProjections,
  setRevenueProjectionSingle,
  setComments,
  setAwaitingListApprove,
} = revenue_projection_chart.actions;

export default revenue_projection_chart.reducer;
