import React from "react";
import thumbsUp from "../../../../../../assets/thumbsUp-icon.svg";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import "../.././../../../../employee-folder/styles/dashboard/modals/finalReimbursementModal.scss";

const EmployeeActualExpenseFinalReimburseModal = ({ onCancel }) => {
  return (
    <div className="final-reimbursement-modal-wrap">
      {/* <div> */}
      <figure>
        <img src={thumbsUp} alt="" />
      </figure>
      {/* </div> */}
      <p className="title">Expense Reimbursed</p>
      <p className="text">
        This partial reimbursement has been marked as successful and expenses
        has been updated accordingly
      </p>
      {/* <button className="close-btn" onClick={onCancel}>Close</button> */}
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonBlue
          style={{ backgroundColor: "#FFEFEB", color: "#CC0905" }}
          text="close"
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
export default EmployeeActualExpenseFinalReimburseModal;
