import React from "react";
import "../../../../styles/dashboard/admin-settings/ApprovalSettingModal.css";
// import "../../../../../styles/dashboard/admin-settings/AdminSettingApproval.css"
import checkImg from "../../../../assets/add-org-complete-modal.png";
// import ButtonBlue from "../../../../buttons/ButtonBlue";
import { FaTimes } from "react-icons/fa";

const AccountSetUpSuccessModal = ({ children, onCancel }) => {
  return (
    <div style={{ width: "55rem" }} className="approval-setting-modal-wrap">
      <div onClick={onCancel} className="cancel-box">
        <FaTimes className="icon" />
      </div>
      <figure className="img-box">
        <img src={checkImg} alt="" className="img" />
      </figure>
      <p style={{color: `var(--blue-color)`}} className="title">Account Setup Completed !</p>
      <p className="text">
        You have successfully registered your account. Click on the button
      </p>
      <p className="text">
        to continue and completely setup your organization details
      </p>
      {children}
    </div>
  );
};

export default AccountSetUpSuccessModal;
