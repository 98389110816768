import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../helper/Helpers";
import { getSubsidiaries } from "../../../../../redux/subsidiary/subsidiary";
import ActionContext from "../../../../../context/ActionContext";
import { Dna } from "react-loader-spinner";
import { createBranch } from "../../../../../redux/branch/branch";
import { getCountries, getStates } from "../../../../../redux/user";

const AddBranch = () => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const [refreshTable ] = useState(false);
  const dispatch = useDispatch();
  const { user, countries, states, selectLoading } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.branch);
  // const { loading } = useSelector((state) => state.subsidiary);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const [details, setDetails] = useState({
    name: "",
    subsidiary_id: "",
    state: "",
    address: "",
    city: "",
    branch_code: "",
    country: "",
  });

  useEffect(() => {
    // dispatch(getCountries())
    user?.organizations?.length > 0 &&
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
       setDetails((prev) => {
      return { ...prev, subsidiary_id: "" };
    });
      // eslint-disable-next-line
  }, [refreshTable, actionCtx.activeOrg]);


  // const actionCtx = useContext(ActionContext)
  useEffect(() => {
    if(actionCtx?.activeOrg?.type !== "group"){
     setDetails((prev) => {
      return {...prev, subsidiary_id: subsidiariesDrop[0]}
     })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsidiariesDrop])

  // useEffect(() => {
  //   setDetails((prev) => {
  //     return { ...prev, subsidiary_id: "" };
  //   });
  // }, [actionCtx.setRemoveOptions])

  useEffect(() => {
    dispatch(getCountries());
      // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const selectListCountry = [
    { label: "Nigeria", value: "nigeria" },
    { label: "Egypt", value: "egypt" },
    { label: "Kenya", value: "kenya" },
  ];

  const handleGetState = async (param) => {
    dispatch(getStates(param.id))
    setDetails((prev) => {
      return {...prev, state: {label: "", value: ""}}
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(details);
    const obj = {
      ...details,
      organization_id: actionCtx?.activeOrg?.value,
      subsidiary_id: details?.subsidiary_id?.value,
      country: details?.country?.label,
      state: details?.state?.label,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(createBranch(obj));
    if (data?.payload?.success) {
      setDetails({
        name: "",
        subsidiary_id: "",
        state: "",
        address: "",
        city: "",
        branch_code: "",
        organization_id: actionCtx?.activeOrg?.id,
        country: "",
      });
      history.push("/dashboard-administration-admin-settings-branch")  
    }
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitleSpan={`Step 3 of 6`}
        pageTitle={`Add Branch`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings-branch")
            }
            className="text"
          >
            Branches
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Add Branch</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Add Branch</p>
            <p className="text">
              Set your organization branches . Once added, you can view and
              manage all created branches for the organization.
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          {/* <div className="btn-download-box">
            <button
              className={`btn-bulk ${
                details.address ||
                details.branch_code ||
                details.Name ||
                details.city ||
                details.state ||
                details.subsidiary
                  ? "btn-bulk-inactive"
                  : ""
              }`}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div> */}
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1.Branch Information</p>
              <div className="form-wrap form-wrap-six">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Name" className="label">
                    Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Branch Name"
                    className={`input ${details.name && "input-active"}`}
                    id="Name"
                    name="name"
                    onChange={handleChange}
                    value={details.name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${actionCtx?.activeOrg?.type !== "group" &&  "disable-select"} ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    isDisabled={actionCtx?.activeOrg?.type !== "group"}
                    placeholder="Choose a subsidiary"
                    value={actionCtx?.activeOrg?.type !== "group"
                    ? subsidiariesDrop || details?.subsidiary_id
                    : details.subsidiary_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    // isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Country" className="label">
                    Country <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.country && "select-wrap-active"
                    }`}
                    placeholder="Country"
                    value={details.country}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, country: e };
                      });
                      handleGetState(e);
                    }}
                    styles={reactSelectStyle}
                    options={countries || selectListCountry}
                    // isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="state" className="label">
                    State <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.state && "select-wrap-active"
                    }`}
                    placeholder="State"
                    value={details.state}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, state: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={states}
                    isLoading={selectLoading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="address" className="label">
                    Address <span>*</span>
                  </label>
                 <form autoComplete="off" action="">
                 <input
                    name="address"
                    onChange={handleChange}
                    value={details.address}
                    type="text"
                    placeholder="Address"
                    className={`input ${details.address && "input-active"}`}
                    id="address"
                  />
                 </form>
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="state" className="label">
                    City <span>*</span>
                  </label>
                  <input
                    name="city"
                    onChange={handleChange}
                    value={details.city}
                    type="text"
                    placeholder="City"
                    className={`input ${details.city && "input-active"}`}
                    id="city"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="address" className="label">
                    Branch Code
                    <figure
                      className="img-box"
                      style={{
                        filter: "saturate(0%)",
                        transform: "translateX(.5rem) translateY(.3rem)",
                      }}
                    >
                      <img src={noteIcon} alt="" className="img" />
                    </figure>
                  </label>
                  <input
                    name="branch_code"
                    onChange={handleChange}
                    value={details.branch_code}
                    type="text"
                    placeholder="Branch Code"
                    className={`input ${details.branch_code && "input-active"}`}
                    id="Branch Code"
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            {/* <div className="note-img-wrap">
                Important Notice:
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
              </div> */}
            {/* note box start */}
            {/* <div className="note-box">
                <p>
                  1. For organizations with shared budgets, you would need to
                  have created at least two subsidiaries to set up a shared
                  services
                </p>
              </div> */}
            {/* note box end */}

            {/* btn box start */}
            {/* <div className="btn-box-wrap"> */}
            {/* <button
                  onClick={() =>
                    history.push(
                      "/dashboard-administration-admin-settings-admin-invite"
                    )
                  }
                  className="btn-reuse"
                >
                  Go to previous step
                </button> */}
            {!loading ? (
              <input
                disabled={
                  !details.name ||
                  // !details.branch_code ||
                  !details.subsidiary_id ||
                  // !details.organization_id ||
                  !details.state ||
                  !details.city ||
                  !details.address
                }
                type="submit"
                value="Save & Continue"
                className={`form-group form-submit ${
                  details.name &&
                  details.address &&
                  details.country &&
                  // details.organization_id &&
                  details.city &&
                  // details.branch_code &&
                  details.state &&
                  details.subsidiary_id &&
                  "form-submit-active"
                }`}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* </div> */}
            {/* btn box end */}
          </form>
        </div>
        {/* form box end */}
        {/* skip text btn box start*/}
        <div className="skip-box">
          <p className="text">
            If you do not have a registered branch under your organization,
            click to next the process
          </p>
          <button className="btn">Skip this</button>
        </div>
        {/* skip text btn box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default AddBranch;
