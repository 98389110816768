import React, { useState, useRef, useEffect } from "react";
import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
import ReactPinField from "react-pin-field";
// import nameIcon from "../../../assets/name-input-icon.svg";
import "../../../styles/auth/AuthLayout.css";
import "../../../styles/auth/AuthForm.css";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import { resendverifyUserEmail, VerifyUserEmail } from "../../../redux/user";

const VerifyEmail = () => {
  const { loading, userEmail, user } = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showActive, setShowActive] = useState(false);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");

  const ref = useRef();

  useEffect(() => {
    // ref.current[0].focus();
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // history.push("/email-verify");
    // return;
    const obj = {
      otp: token,
    };
    const data = await dispatch(VerifyUserEmail(obj));
    //  console.log(data);

    if (data?.payload?.success) {
      // console.log(data.payload);
      history.push("/email-verify");
      // console.log(token);
    }
  };

  const handleSubmitDirect = async (num) => {
    // e.preventDefault();
    // history.push("/email-verify");
    // return;
    const obj = {
      otp: num,
    };
    const data = await dispatch(VerifyUserEmail(obj));
    //  console.log(data);

    if (data?.payload?.success) {
      // console.log(data.payload);
      history.push("/email-verify");
      // console.log(token);
      return;
    }
  };

  const handleResendOtp = async () => {
    dispatch(resendverifyUserEmail());
  };

  // useEffect(() => {
  //  const token = localStorage.getItem("token");
  //  console.log(token);
  // }, [])

  return (
    <>
      <AuthLayout
        getHelp
        title="Verify your email"
        small_text={`A four digit OTP code has been sent to your email `}
      >
        {/* get started */}
        <p style={{ fontWeight: "1.3rem", color: "#004BFF" }}>
          {/* {userEmail || user?.email} */}
          {userEmail}
        </p>
        {/* auth form start */}
        <form onSubmit={handleSubmit} action="" className="auth-form-wrap">
          <div className="form-group" style={{ border: "unset" }}>
            <ReactPinField
              length={4}
              className={`pin-field ${showActive && "pin-field-complete"}`}
              onChange={(num) => {
                setShowActive(false);
                setError("");
                setToken(num);
              }}
              onComplete={(num) => {
                setShowActive(true);
                handleSubmitDirect(num);
              }}
              // disabled={completed}
              validate="0123456789"
              autoFocus
              ref={ref}
            />
            {error && <p className="error">{error}</p>}
          </div>

          {/* submit form start */}
          {!loading ? (
            <input
              disabled={!showActive}
              type="submit"
              value="Confirm code"
              className={`form-group btn-reuse form-submit ${
                showActive && "form-submit-active"
              }`}
              style={{ alignSelf: "flex-start", padding: "1.2rem 4rem" }}
            />
          ) : (
            <div style={{ alignSelf: "flex-start" }}>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
          {/* submit form  end */}
        </form>
        {/* auth form end */}
        {/* already text start */}
        <div className="already-box">
          <p className="text">
            Didn’t get the mail? <span onClick={handleResendOtp}>Resend</span>
          </p>
        </div>
        {/* already text end */}
      </AuthLayout>
    </>
  );
};

export default VerifyEmail;
