import React, { useContext, useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
// import { FaCheck } from "react-icons/fa";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { createProduct } from "../../../../../../redux/budget-setting/revenue-projection/product";
import { NumericFormat } from "react-number-format";
import { FaCheck } from "react-icons/fa";

const AddBudgetRevProducts = ({ onCancel, onFinish }) => {
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  // const { verticalDrop } = useSelector((state) => state.vertical);
  const { portfolioDrop } = useSelector((state) => state.portfolio);
  const { loading } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    subsidiary_id: "",
    portfolio_id: "",
    name: "",
    description: "",
    gp_percentage: "",
    is_allowed: false,
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async () => {
    const newList = details?.portfolio_id?.map((chi) => chi?.value);
    const obj = {
      organization_id: actionCtx?.activeOrg?.id,
      ...details,
      gp_percentage: formatNumberWithoutComma(details?.gp_percentage),
      subsidiary_id: details?.subsidiary_id.value,
      portfolio_id: newList,
      is_allowed: details?.is_allowed,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(createProduct(obj));
    if (data?.payload?.success) {
      setDetails({
        subsidiary_id: "",
        portfolio_id: "",
        name: "",
        description: "",
        gp_percentage: "",
        is_allowed: false,
      });
      onFinish();
    }
  };

  return (
    <>
      <CommonInputModal
        longContent
        onCancel={onCancel}
        title={`New Product`}
        btnText={`Save`}
        loading={loading}
        btnActive={
          // details?.description &&
          details?.name &&
          details?.gp_percentage &&
          // details?.vertical_id &&
          details?.subsidiary_id
        }
        onBtnClick={handleSubmit}
        btnDisable={
          // !details?.description ||
          !details?.name ||
          !details?.gp_percentage ||
          !details?.subsidiary_id
        }
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={details.name}
            type="text"
            placeholder="Name products"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}

        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Subsidiary <span>*</span>
          </label>
          <Select
            placeholder="Select subsidiary"
            className="select "
            styles={reactSelectStyle}
            value={details.subsidiary_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, subsidiary_id: e };
              });
            }}
            options={subsidiariesDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Portfolio <span>*</span>
          </label>
          <Select
            isMulti
            styles={reactSelectStyle}
            placeholder="Select expense class"
            className={`select select-wrap select-wrap-multi ${
              details?.portfolio_id?.length > 0 && "select-wrap-active"
            }`}
            value={details?.portfolio_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, portfolio_id: e };
              });
            }}
            options={portfolioDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            GP (%) <span>*</span>
          </label>
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            type="text"
            name="gp_percentage"
            maxLength={3}
            thousandsGroupStyle={"lakh"}
            allowNegative

            onChange={(e) => {
              if(Number(e.target.value) <= 100){
                handleChange(e)
              }
            }}
            thousandSeparator={","}
            value={details.gp_percentage}
            className={`input ${details.gp_percentage && "input-active"}`}
            id="Department Code"
            placeholder="enter gp percentage"
          />
        </div>
        {/* group box end */}
        {/* group start */}
        <div className="form-group form-group-check">
          <input
            value={details?.is_allowed}
            checked={details?.is_allowed}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, is_allowed: e?.target?.checked };
              });
            }}
            id="input-check-percentage"
            type="checkbox"
            className="input-check-box"
          />
          <label htmlFor="input-check-percentage" className="label-check-box">
            <div className="new-check">
              <FaCheck className="icon" />
            </div>
            <p className="text">Allow edit of Gp percentage</p>
          </label>
        </div>
        {/* group end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description <span>*</span>
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="5"
            onChange={handleChange}
            value={details.description}
            type="text"
            placeholder="describe your product"
            style={{ resize: "none" }}
          ></textarea>
        </div>
        {/* group box end */}
      </CommonInputModal>
    </>
  );
};

export default AddBudgetRevProducts;
