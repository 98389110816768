import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import Select from "react-select";
import ActionContext from "../../../../../../context/ActionContext";
// import RevenueChart from "../RevenueChart";
// import userImg from "../../../../../../assets/hod-one-img.svg";
// import RevenueChartOne from "../RevenueChartOne";
// import { useHistory } from "react-router-dom";
import EmployeeNetProfitProjection from ".";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
// import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
// import EmployeeMonthlyExpenseChart from "../chart/monthlyChart";
import EmployeeNetProfitProjectionGraph from "../chart/profitProjection";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeActualGrossProfitProjection,
  getEmployeeActualGrossProfitProjectionPagination,
} from "../../../../../../redux/employee/dashboard";
import TableBodyRow from "../../../../../../component/tables/TableBodyRow";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";

const EmployeeActualGrossProfitIndex = ({ children, chartBox }) => {
  const actionCtx = useContext(ActionContext);
  // const history = useHistory();
  //   const [showChart, setShowChart] = useState(true);
  // const [indexNo, setIndexNo] = useState("");
  //   const [showChartBox, setShowChartBox] = useState(true);
  const [chartFilterVal, setChartFilterVal] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [totalNetProfit, setTotalNetProfit] = useState("");
  const [netProfitGraphVal, setNetProfotGraphVal] = useState([]);
  const { loading, actualGpProjectionGraph } = useSelector(
    (state) => state.employee_dashboard
  );
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );

  const department_id = localStorage.getItem("employee-department-id");

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    if (user?.organizations[0]?.id || department_id) {
      handleGetNetProfitGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id, department_id]);

  const handleGetNetProfitGraph = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: department_id || userDepartmentDrop[0]?.id,
      // user_id: user?.id,
      currency_conversion_id: "",
    };
    const data = await dispatch(getEmployeeActualGrossProfitProjection(obj));
    if (data?.payload?.success) {
      const totalNetProfitval = data?.payload?.data?.total_arevenue_actual_gp;
      const netProfitGraph = data?.payload?.data?.revenue_actual_gp_graph;

      let netProfitGraphValues = [];
      // console.log(netProfitGraph, "net profit graph");
      for (const netProfitObj of netProfitGraph) {
        netProfitGraphValues.push(parseInt(netProfitObj?.value));
        // console.log(netProfitObj, "net profit chart");
      }
      // for(const netProfitData p)
      setNetProfotGraphVal(netProfitGraphValues);
      setTotalNetProfit(totalNetProfitval);
    }
  };

  // console.log(netProfitGraphVal, "net profit graph values");

  const optionList = [
    {
      label: "Default",
      value: 1,
    },
    {
      label: "Current Fiscal Year",
      value: 2,
    },
    {
      label: "Previous Year",
      value: 3,
    },
    {
      label: "Today",
      value: 4,
    },
    {
      label: "Yesterday",
      value: 5,
    },
    {
      label: "Last 7 days",
      value: 6,
    },
    {
      label: "Monthly",
      value: 7,
    },
  ];

  const handlePagination = async (param) => {
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: department_id || userDepartmentDrop[0]?.id,
      // user_id: user?.id,
      page: linkTwo,
      currency_conversion_id: "",
    };
    dispatch(getEmployeeActualGrossProfitProjectionPagination(obj));
  };

  const tableHeadList = [
    "Product",
    "Total Expense (N)",
    "Total Revenue (N)",
    "Product Sold (N)",
    "Total GP (N)",
    "Total GP%",
  ];

  return (
    <>
      <EmployeeNetProfitProjection
        actualVal={formatNumber(
          String(
            FormatNumWithCurrency(
              totalNetProfit,
              actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
              actionCtx?.currencyValue
            ) || 0
          )
        )}
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={` ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          ></div>
        )}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Atual GP Graph</p>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Monthly"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={optionList}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              {/* <EmployeeMonthlyExpenseChart /> */}
              <EmployeeNetProfitProjectionGraph
                loading={loading}
                dataValue={netProfitGraphVal}
              />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            dontShowPagination={actualGpProjectionGraph?.data?.length < 1}
            fromPage={actualGpProjectionGraph?.from}
            toPage={actualGpProjectionGraph?.to}
            nextPage={actualGpProjectionGraph?.next_page_url}
            prevPage={actualGpProjectionGraph?.prev_page_url}
            onNextPage={() =>
              handlePagination(actualGpProjectionGraph?.next_page_url)
            }
            onPrevPage={() =>
              handlePagination(actualGpProjectionGraph?.prev_page_url)
            }
            totalPage={actualGpProjectionGraph?.total}
          >
            {actualGpProjectionGraph?.data?.map((chi, idx) => {
              const {
                product_name,
                total_actual_expense,
                total_gp,
                product,
                total_gp_project,
                total_gp_percent,

                // status,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={product_name}
                  loading={loading}
                  two={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        total_actual_expense,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  three={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        total_gp,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  four={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        product,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  five={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        total_gp_project,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  six={formatNumber(String(total_gp_percent) || 0) + "%"}
                  //   progressType={status}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </EmployeeNetProfitProjection>
    </>
  );
};

export default EmployeeActualGrossProfitIndex;
