import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../../../helper/Helpers";
// import { addLabelValueFunc } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getActualRevenues = createAsyncThunk(
  "employee/get-actual-revenues",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual_revenue/draft/${formData?.org_id || ""}?perPage=${
          formData?.per_page || ""
        }
      `
      );

      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("actuals-revenue", data?.data?.draft.data)
        await thunkAPI.dispatch(setActualRevenues(data?.data?.draft));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAllRevenueActualComment = createAsyncThunk(
  "employee/get-all-comment-revenue-actual",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `/employee/comments/actual-revenue/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        await thunkAPI.dispatch(setRevenueActualComments(data?.data?.comments));
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubmittedActualRevenue = createAsyncThunk(
  "employee/get-submitted-actual-revenue",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual_revenue/submitted/${
          formData?.org_id
        }?filter[department_id]=${formData?.dept_id || ""}&filter[status]=${
          formData?.status || ""
        }&currency_conversion_id=${formData?.currency_id || ""}&product_id=${
          formData?.product_id || ""
        }&vertical_id=${formData?.vertical_id || ""}&perPage=${
          formData?.per_page || ""
        }&fiscal_year_id=${formData?.fiscal_year_id || ""}
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list-actual", data?.data?.actualrevenue)
        await thunkAPI.dispatch(
          setSubmittedActualRevenue(data?.data?.actualrevenue)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubmittedApprovedActualRevenue = createAsyncThunk(
  "employee/get-submitted-actual-revenue",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual_revenue/submitted/${
          formData?.org_id || ""
        }?filter[is_submitted]=${
          formData?.filter_submitted || ""
        }&filter[status]=${formData?.status || ""}&filter[department_id]=${
          formData?.dept_id || ""
        }&product_id=${formData?.product_id || ""}&vertical_id=${
          formData?.vertical_id || ""
        }
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list-actual", data?.data?.actualrevenue)
        await thunkAPI.dispatch(
          setSubmittedActualRevenue(data?.data?.actualrevenue)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubmittedActualRevenuePagination = createAsyncThunk(
  "employee/get-submitted-actual-revenue-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`/employee/actual_revenue/submitted/${
        formData?.org_id || ""
      }?filter[is_submitted]=${
        formData?.filter_submitted || ""
      }&filter[status]=${formData?.status || ""}&filter[department_id]=${
        formData?.dept_id || ""
      }&page=${formData?.page || ""}&currency_conversion_id=${
        formData?.currency_id || ""
      }&perPage=${formData?.per_page || ""}&fiscal_year_id=${
        formData?.fiscal_year_id || ""
      }
    `);
      //   console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setSharedServices(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list-actual", data?.data?.actualrevenue.data)
        await thunkAPI.dispatch(
          setSubmittedActualRevenue(data?.data?.actualrevenue)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getActualRevenueBySearch = createAsyncThunk(
  "employee/get-actual-revenue-projection-search",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual_revenue/draft/${formData?.org_id}?search=${
          formData?.q || ""
        }&filter=${formData?.filter_by || ""}&sort=${
          formData?.sort_by || ""
        }&perPage=${formData?.per_page || ""}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("revenue-info", data[0]);
        const revenueInfo = data?.data?.draft;
        // console.log("revenueInfo", revenueInfo);
        await thunkAPI.dispatch(setActualRevenueSearch(revenueInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getActualSubmittedRevenueBySearch = createAsyncThunk(
  "employee/get-actual-submitted-revenue-projection-search",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual_revenue/submitted/${formData?.org_id || ""}?search=${
          formData?.search || ""
        }&filter=${formData?.filter_by || ""}&sort=${
          formData?.sort_by || ""
        }&filter[department_id]=${formData?.dept_id || ""}&filter[status]=${
          formData?.status || ""
        }&&currency_conversion_id=${formData?.currency_id || ""}perPage=${
          formData?.per_page || ""
        }&fiscal_year_id=${formData?.fiscal_year_id || ""}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("revenue-info", data[0]);
        const revenueInfo = data?.data?.actualrevenue;
        // console.log("revenueInfo", revenueInfo);
        await thunkAPI.dispatch(setActualSubmittedRevenueSearch(revenueInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getActualRevenuePagination = createAsyncThunk(
  "employee/get-actual-revenue-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual_revenue/draft/${formData?.org_id || ""}?perPage=${
          formData?.per_page || ""
        }&page=${formData?.page || ""}&search=${
          formData?.seach || ""
        }&filter_by=${formData?.filter || ""}`
      );
      if (!data?.success) {
        // await thunkAPI.dispatch(setSharedServices(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const actualRevenueInfo = data?.data?.draft;
        await thunkAPI.dispatch(setActualRevenues(actualRevenueInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getActualRevenue = createAsyncThunk(
  "employee/get-actual-revenue",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/employee/actual_revenue/${formData}`);
      // console.log("get-actual-revenue", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createActualRevenue = createAsyncThunk(
  "employee/create-actual-revenue",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/employee/actual_revenue", formData);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ActualRevenueProjectionDownload = createAsyncThunk(
  "employee/actual-revenue-projection-download",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual_revenue/worksheet
      `,
        formData
      );
      // const file = new Blob([data.data], { type: "application/xlsx" }); //Build a URL from the file
      // const fileURL = URL.createObjectURL(file); //Open the URL on new Window
      // window.open(fileURL);
      // console.log("data--->", data);
      window.open(data?.url, "_self").focus();
      // const downloadCsv = (url) => {
      //   // console.log(templateDownload?.url, "url");
      //   // dispatch(ExpenditureProjectionDownload());
      // };

      await thunkAPI.dispatch(setActualRevTemplateDownload(data));
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data, "data data")
        toast.success(data.message, {
          theme: "colored",
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ActualRevenueBulkUpload = createAsyncThunk(
  "employee/actual-revenue-bulk-upload",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `employee/actual_revenue/bulk_upload`,
        formData
      );
      // const { data } = await axios.post(
      //   `employee/expenditure_projection/bulk-update?organization_id=${
      //     formData?.org_id || ""
      //   }&file=${formData?.file || ""}`,
      //   formData
      // );
      // console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // if (err.response.data.message) {
      //   toast.error(err.response.data.message, {
      //     theme: "colored",
      // toastId: customErrorId,
      //   });
      // }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

// export const getExpenditureBulkUpload = createAsyncThunk(
//   "employee/expenditure-bulk-upload",
//   async (formData, thunkAPI) => {
//     try {
//       const token = localStorage.getItem("token");
//       await setAuthToken(token);
//       const { data } = await axios.post(
//         "employee/expenditure_projection/bulk-update",
//         formData
//       );
//       // console.log(data);
//       if (!data?.success) {
//         toast.error(data.message, {
//           theme: "colored",
// toastId: customErrorId,
//         });
//         // console.log(data);
//         return thunkAPI.rejectWithValue(data);
//       }

//       if (data?.success) {
//         toast.success(data.message, {
//           theme: "colored",
//         });
//         // console.log(data);
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (err) {
//       if (err.response.data.message) {
//         toast.error(err.response.data.message, {
//           theme: "colored",
// toastId: customErrorId,
//         });
//       }
//       if (err.response.status === 500) {
//         toast.error(err?.response?.data?.message, {
//           theme: "colored",
// toastId: customErrorId,
//         });
//       }
//       if (err) {
//         return thunkAPI.rejectWithValue(err);
//       }
//     }
//   }
// );

export const submitActualRevenueDraftForApproval = createAsyncThunk(
  "employee/submit-draft-for-approval",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/actual_revenue/multiple_submit",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const submitSingleActualRevenueDraftForApproval = createAsyncThunk(
  "employee/submit-draft-for-approval",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/actual_revenue/multiple_submit",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const commentEmpActualRevenue = createAsyncThunk(
  "employee/comment-actual-expense",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `/employee/comments/actual-revenue/add-comment?revenue_actual_id=${
          formData?.revenue_actual_id || ""
        }&status=${formData?.status || ""}&user_id=${
          formData?.user_id || ""
        }&comment=${formData?.comment || ""}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteActualRevenueComment = createAsyncThunk(
  "employee/delete-actual-revenue-comment",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `employee/comments/actual-expenditure/${formData?.id}/${formData?.comment_id}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteActualRevenue = createAsyncThunk(
  "employee/delete-actual-revenue",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `employee/actual_revenue/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateActualRevenues = createAsyncThunk(
  "employee/update-expenditure-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.patch(
        `employee/actual_revenue/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const actual_revenue = createSlice({
  name: "actual_revenue",
  initialState: {
    expenditureProjectionDrop: [],
    actualRevenues: [],
    submittedActualRevenues: [],
    actualRevenuesComments: [],
    loadingTwo: false,
    links: {},
    meta: {},
    expenditureProjection: {},
    actualtemplateDownload: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
    userDepartment: [],
    userDepartmentDrop: [],
  },
  reducers: {
    setActualRevenues: (state, action) => {
      state.isAuth = true;
      state.actualRevenues = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setRevenueActualComments: (state, action) => {
      state.isAuth = true;
      state.actualRevenuesComments = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setActualRevTemplateDownload: (state, action) => {
      state.isAuth = true;
      state.actualtemplateDownload = action.payload;
    },
    // setUserDepartment: (state, action) => {
    //   state.isAuth = true;
    //   state.userDepartment = action.payload;
    //   state.links = action.payload.links;
    //   state.meta = action.payload.meta;
    // },
    setSubmittedActualRevenue: (state, action) => {
      state.isAuth = true;
      state.submittedActualRevenues = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setActualRevenueSearch: (state, action) => {
      state.isAuth = true;
      state.actualRevenues = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setActualSubmittedRevenueSearch: (state, action) => {
      state.isAuth = true;
      state.submittedActualRevenues = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [createActualRevenue.pending]: (state) => {
      state.loading = true;
    },
    [createActualRevenue.fulfilled]: (state) => {
      state.loading = false;
    },
    [createActualRevenue.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateActualRevenues.pending]: (state) => {
      state.loading = true;
    },
    [updateActualRevenues.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateActualRevenues.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [ActualRevenueProjectionDownload.pending]: (state) => {
      state.loading = true;
    },
    [ActualRevenueProjectionDownload.fulfilled]: (state) => {
      state.loading = false;
    },
    [ActualRevenueProjectionDownload.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getAllRevenueActualComment.pending]: (state) => {
      state.loading = true;
    },
    [getAllRevenueActualComment.fulfilled]: (state) => {
      state.loading = false;
    },
    [getAllRevenueActualComment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteActualRevenueComment.pending]: (state) => {
      state.loading = true;
    },
    [deleteActualRevenueComment.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteActualRevenueComment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [commentEmpActualRevenue.pending]: (state) => {
      state.loading = true;
    },
    [commentEmpActualRevenue.fulfilled]: (state) => {
      state.loading = false;
    },
    [commentEmpActualRevenue.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [submitActualRevenueDraftForApproval.pending]: (state) => {
      state.loadingTwo = true;
    },
    [submitActualRevenueDraftForApproval.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [submitActualRevenueDraftForApproval.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [ActualRevenueBulkUpload.pending]: (state) => {
      state.loadingTwo = true;
    },
    [ActualRevenueBulkUpload.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [ActualRevenueBulkUpload.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [submitSingleActualRevenueDraftForApproval.pending]: (state) => {
      state.loadingTwo = true;
    },
    [submitSingleActualRevenueDraftForApproval.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [submitSingleActualRevenueDraftForApproval.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [deleteActualRevenue.pending]: (state) => {
      state.loadingTwo = true;
    },
    [deleteActualRevenue.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [deleteActualRevenue.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },

    [getSubmittedActualRevenuePagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSubmittedActualRevenuePagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSubmittedActualRevenuePagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getActualRevenueBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getActualRevenueBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getActualRevenueBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getActualSubmittedRevenueBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getActualSubmittedRevenueBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getActualSubmittedRevenueBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getActualRevenuePagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getActualRevenuePagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getActualRevenuePagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getActualRevenues.pending]: (state) => {
      state.loading = true;
      state.loadingPage = true;
    },
    [getActualRevenues.fulfilled]: (state) => {
      state.loading = false;
      state.loadingPage = false;
    },
    [getActualRevenues.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getActualRevenue.pending]: (state) => {
      state.loading = true;
    },
    [getActualRevenue.fulfilled]: (state) => {
      state.loading = false;
    },
    [getActualRevenue.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSubmittedActualRevenue.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSubmittedActualRevenue.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSubmittedActualRevenue.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getSubmittedApprovedActualRevenue.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSubmittedApprovedActualRevenue.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSubmittedApprovedActualRevenue.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setActualRevenues,
  setSubmittedActualRevenue,
  setUserDepartment,
  setUserDepartmentDrop,
  setActualRevenueSearch,
  setActualRevTemplateDownload,
  setActualSubmittedRevenueSearch,
  setRevenueActualComments,
} = actual_revenue.actions;

export default actual_revenue.reducer;
