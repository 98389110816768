import React, { useState } from "react";
// import DashboardLayout from "../../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/admin-settings/ApprovalDetailFlow.css";
// import DashboardLayout from "../../../../../layout/dashboard/DashboardLayout";
import CommonLinks from "../CommonLinks";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import Modal from "react-awesome-modal";
import ApprovalSuccessModal from "../ApprovalSuccessModal";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import { useHistory } from "react-router-dom";
// import { FaTimes } from "react-icons/fa";

const ExpenditureMain = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const tableHeadList = ["Subsidiary", "Departments", "Approval Level"];
  const tableBodyList = [
    {
      name: "IT Horizons",
      subsidiary: "Sales & Finance",
      branch: "Custom  Approval",
    },
    {
      name: "Product & Sales",
      subsidiary: "Zojatech",
      branch: "Zojapay",
    },
    {
      name: "IT Horizons",
      subsidiary: "Sales & Finance",
      branch: "Custom  Approval",
    },
  ];

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitle={`Setup Approval Flow`}
        pageTitleSpan={`Step 6 of 6`}
      > */}
        <div className="approval-flow-wrap">
          <p className="title">Approval</p>
          {/* comon link start */}
          <CommonLinks />
          {/* comon link end */}

          {/* table box start */}
          <ActualReuseableTable
            tableTitle={`All Approval `}
            tableTitleSpan={`(3)`}
            addBtntext={`New Approval`}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
          >
            {tableBodyList.map((chi, idx) => {
              const { name, subsidiary, branch } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={name}
                  two={subsidiary}
                  three={branch}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}

          {/* text btn box start */}  
          <div className="text-button-box">
            <p className="text">
              Click on the button when you’re done setting up 
            </p>
            <button onClick={() => setShowModal(true)} className="btn">
              Complete Setup
            </button>
          </div>
          {/* text btn box end */}
        </div>
      {/* </DashboardLayout> */}
      {/* modal start  */}
      <Modal visible={showModal} effect="fadeInUp">
        <ApprovalSuccessModal onCancel={() => setShowModal(false)}>
          <ButtonBlue
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-approval-flow-revenue-projection"
              )
            }
            text={`Continue`}
          />
        </ApprovalSuccessModal>
      </Modal>
      {/* modal end */}
    </>
  );
};

export default ExpenditureMain;
