import React, { useContext, useEffect, useState } from "react";
// import { FaAngleRight, FaPlus } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersPagination,
  getUsersWithPagination,
} from "../../../../../redux/users/users";
import ActionContext from "../../../../../context/ActionContext";
// import moment from "moment";

const AllUsers = () => {
  const history = useHistory();
  const { allUssers, loading, meta, links } = useSelector(
    (state) => state.users
  );
  const { user } = useSelector((state) => state.user);
  const [indexNo, setIndexNo] = useState("");
  const dispatch = useDispatch();
  const tableHeadList = [
    "Full Name",
    "Email",
    "Department",
    "Subsidiary",
    "Status",
    "Role",
    "Type",
    "Dated Added",
  ];

  const actionCtx = useContext(ActionContext);
  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    dispatch(getUsersWithPagination(obj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    user?.organizations?.length > 0 && dispatch(getUsersWithPagination(obj));
  };

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    const splitLinktwo = linkTwo.split("?")
    const newLink = `${splitLinktwo[0]}?paginated&${splitLinktwo[1]}`
    dispatch(getUsersPagination(newLink));
  };

  const getDepartMentNames = (list) => {
    const listval = list.map((chi, idx) =>
      idx === 0 ? chi?.name : `, ${chi?.name}`
    );
    const stringVal = listval.toString();
    // console.log(stringVal);
    return stringVal;
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitleSpan={`Step 5 of 6`}
        pageTitle={`Add Employee`}
      > */}
      <div className="admin-org-settings-wrap">
        {/* table box start */}
        <ActualReuseableTable
          tableTitle={`Users`}
          tableTitleSpan={`(4)`}
          addBtntext={`Manage User`}
          plusIcon
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          six={tableHeadList[5]}
          //   action
          seven={tableHeadList[6]}
          eight={tableHeadList[7]}
          onCreateNew={() =>
            history.push("/dashboard-administration-admin-settings-employee")
          }
          fromPage={meta?.from || 1}
          toPage={meta?.to || allUssers?.length}
          totalPage={meta?.total || allUssers?.length}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          perPageValue={actionCtx?.perPage}
          // dontShowBnSearch
          dontShowSearch
          dontShowSelect
          setPerPageValue={(e) => {
            // setPerpageVal(e);
            handleSortPerPage(e.value);
          }}
          loading={loading && allUssers?.length < 1}
          loadingNum={10}
        >
          {allUssers?.map((chi, idx) => {
            const {
              full_name,
              email,
              department,
              subsidiary,
              is_active,
              role,
              created_at,
              user_type,
            } = chi;
            return (
              <TableBodyRow
              loading={loading}
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                key={idx}
                one={full_name}
                two={email}
                removeCapitalize   
                three={
                  department?.length > 0 ? (
                    getDepartMentNames(department)
                  ) : (
                    <>&nbsp;</>
                  )
                }
                four={subsidiary?.name || <>&nbsp;</>}
                status={is_active ? "active" : "inActive"}
                eight={role}
                nine={user_type}
                ten={created_at}
                viewText={`view user`}
                onView={() =>
                  history.push(
                    "/dashboard-administration-admin-settings-view-employee"
                  )
                }
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default AllUsers;
