import React, { useContext, useState } from "react";
import BudgetMain from "..";
import CommonExpenditureLink from "./commonLink";
import "../../../../../../styles/dashboard/admin-settings/BudgetSetting.css";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
// import { useHistory } from "react-router-dom";
import Modal from "react-awesome-modal";
// import CommonInputModal from "../modals/CommonInputModal";
import AddBudgetExpClassModal from "../modals/AddBudgetExpClassModal";
import UpdateBudgetExpClassModal from "../modals/UpdateBudgetExpClassModal";
import DeleteBudgetExpClassModal from "../modals/DeleteBudgetExpClassModal";
import { useEffect } from "react";
import ActionContext from "../../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  getClassesByFilter,
  getClassesPagination,
  getClassesWithPagination,
} from "../../../../../../redux/budget-setting/expenditure-projection/class";

const tableHeadList = ["Class", "Description", "Action"];

const BudgetExpClasses = () => {
  const [indexNo, setIndexNo] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const { classes, loadingPage, meta, links } = useSelector(
    (state) => state.classes
  );
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  //   const history = useHistory();
  const [showModal, setShowModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      dispatch(getClassesWithPagination(obj));
    }
    // eslint-disable-next-line
  }, [refreshTable]);

  useEffect(() => {}, [refreshTable]);

  const handleUpdate = async (param) => {
    actionCtx?.setIsModalOut(true);
    setShowModal((prev) => {
      return { ...prev, edit: true };
    });
    setSingleChild(param);
  };

  const handleFinish = () => {
    actionCtx?.setIsModalOut(false);
    setShowModal((prev) => {
      return { add: false, edit: false, delete: false };
    });
    setRefreshTable(!refreshTable);
  };

  const handleDelete = (param) => {
    actionCtx?.setIsModalOut(true);
    setSingleChild(param);
    setShowModal((prev) => {
      return { ...prev, delete: true };
    });
  };

  const handleEditSingleChild = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    const data = { ...singleChild, [name]: value };
    setSingleChild(data);
  };

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getClassesPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    // console.log(obj);
    user?.organizations?.length > 0 && dispatch(getClassesWithPagination(obj));
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: "",
        org_id: actionCtx?.activeOrg?.id,
      };
      // console.log(obj);
      dispatch(getClassesByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink
          title={`Expenditure Projection`}
          textOne={`Classes`}
          textTwo={`Categories`}
          textThree={`Chart of account`}
          linkOne={`/dashboard-administration-admin-settings-budget-exp-classes`}
          linkTwo={`/dashboard-administration-admin-settings-budget-exp-categories`}
          linkThree={`/dashboard-administration-admin-settings-budget-exp-account`}
        />
        <div className="table-box-budget">
          {/* table box start */}
          <ActualReuseableTable
            fromPage={meta?.from || 1}
            toPage={meta?.to || classes?.length}
            totalPage={meta?.total || classes?.length}
            nextPage={links?.next}
            prevPage={links?.prev}
            onNextPage={() => handlePagination(links?.next)}
            onPrevPage={() => handlePagination(links?.prev)}
            onSearchTable={(e) => {
              setSearch(e);
            }}
            perPageValue={actionCtx?.perPage?.value}
            setPerPageValue={(e) => {
              // setPerpageVal(e);
              handleSortPerPage(e.value);
            }}
            tableTitle={classes?.length > 1 ? `Classes` : "Class"}
            tableTitleSpan={`(${classes?.length})`}
            dontShowSelect
            addBtntext={`new class`}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            action
            onCreateNew={() => {
              actionCtx?.setIsModalOut(true);
              setShowModal((prev) => {
                return { ...prev, add: true };
              });
            }}
            loading={loadingPage && classes?.length < 1}
            loadingNum={7}
          >
            {classes?.map((chi, idx) => {
              const { name, description } = chi;
              return (
                <TableBodyRow
                  // loading
                  key={idx}
                  one={name}
                  two={description}
                  loading={loadingPage}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  deleteText={`Delete`}
                  updateText={`Update`}
                  onUpdate={() => handleUpdate(chi)}
                  onDelete={() => handleDelete(chi)}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        <Modal effect="fadeInUp" visible={showModal.add}>
          <AddBudgetExpClassModal
            onFinish={handleFinish}
            onCancel={() => {
              actionCtx?.setIsModalOut(false);
              setShowModal((prev) => {
                return { ...prev, add: false };
              });
            }}
          />
        </Modal>
        <Modal effect="fadeInUp" visible={showModal.edit}>
          <UpdateBudgetExpClassModal
            onFinish={handleFinish}
            onEdit={handleEditSingleChild}
            detail={singleChild}
            onCancel={() => {
              actionCtx?.setIsModalOut(false);
              setShowModal((prev) => {
                return { ...prev, edit: false };
              });
              setSingleChild({});
            }}
          />
        </Modal>
        <Modal effect="fadeInUp" visible={showModal.delete}>
          <DeleteBudgetExpClassModal
            onFinish={handleFinish}
            detail={singleChild}
            onClose={() => {
              actionCtx?.setIsModalOut(false);
              setShowModal((prev) => {
                return { ...prev, delete: false };
              });
              setSingleChild({});
            }}
          />
        </Modal>
      </BudgetMain>
    </>
  );
};

export default BudgetExpClasses;
