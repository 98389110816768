import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { updateClass } from "../../../../../../redux/budget-setting/expenditure-projection/class";
import CommonInputModal from "./CommonInputModal";

const UpdateBudgetExpClassModal = ({ onCancel, onEdit, detail, onFinish }) => {
  const { loading } = useSelector((state) => state.classes);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    onEdit(e);
  };

  const handleSubmit = async () => {
    const obj = {
      id: detail?.id,
      name: details?.name || detail?.name,
      description: details?.description || detail?.description,
      organization_id: actionCtx?.activeOrg?.id,
    };
    const data = await dispatch(updateClass(obj));
    if (data?.payload?.success) {
      onFinish();
      setDetails({
        name: "",
        description: "",
      });
    }
  };

  return (
    <>
      <CommonInputModal
        loading={loading}
        btnActive={details?.description || details?.name ? true : false}
        onBtnClick={handleSubmit}
        btnDisable={!details?.description && !details?.name}
        onCancel={onCancel}
        title={`New Class`}
        btnText={`Update`}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={detail?.name}
            type="text"
            placeholder="Name expense class"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description <span>*</span>
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
            value={detail?.description}
            type="text"
            placeholder="describe your expenditure class"
          ></textarea>
        </div>
        {/* group box end */}
      </CommonInputModal>
    </>
  );
};

export default UpdateBudgetExpClassModal;
