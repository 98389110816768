import React, { useState } from "react";
import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
import nameIcon from "../../../assets/name-input-icon.svg";
import "../../../styles/auth/AuthLayout.css";
import "../../../styles/auth/AuthForm.css";
import workMailIcon from "../../../assets/work-mail-icon.svg";
import lockIcon from "../../../assets/password-icon.svg";
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../redux/user";
// import { Sp} from "react
import { Dna } from "react-loader-spinner";
import { validateEmail } from "../../../helper/Helpers";
import { toast } from "react-toastify";
import { validatePasswordLowercase, validatePasswordNumber, validatePasswordSpecialCharacter, validatePasswordUpperCase } from "../../../helper/ValidateInput";
// import 'bootstrap/dist/css/bootstrap.min.css';

const SignUp = () => {
  const { loading } = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShwoPassord] = useState(false);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [details, setDetails] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    const newError = { ...errors, [name]: "" };
    setErrors(newError);
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //  history.push("/check-mail");
    //  return;

    //  validations start ----------------
    if (!validateEmail(details?.email)) {
      toast.error("Invalid email address!", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (
      !validatePasswordLowercase(details?.password) &&
      !validatePasswordUpperCase(details?.password) &&
      !validatePasswordSpecialCharacter(details?.password) &&
      !validatePasswordNumber(details?.password) 
    ) {
      toast.error(
        "Password should at least be 8 characters long,contain 1 capital letter, 1 lowercase letter,  1 special character,  1 numeric character.",
        {
          theme: "colored",
          position: "top-right",
        }
      );
      return;
    }
    if (!validatePasswordLowercase(details?.password)) {
      toast.error("Password should have at least 1 lowercase letter", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordUpperCase(details?.password)) {
      toast.error("Password should have at least 1 capital letter", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordSpecialCharacter(details?.password)) {
      toast.error("Password should have at least 1 special character", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordNumber(details?.password)) {
      toast.error("Password should have at least  1 numeric character.", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    // validations end -----------------
    
    if (!validateEmail(details?.email)) {
      setErrors((prev) => {
        return { ...prev, email: "Please provide a valid email address" };
      });
      toast.error("Please provide a valid email address", {
        theme: "colored",
      });
      return;
    }
    // console.log(details);
    // return;
    const data = await dispatch(registerUser(details));
    const errors = data?.payload?.response?.data?.errors;
    // console.log(data);
    if (data.payload.success) {
      // console.log(data.payload.data);
      localStorage.setItem("email-to-receive-otp", details.email);
      history.push("/check-mail");
    }
    if (errors) {
      console.log(errors);
      const email = errors?.email;
      const first_name = errors?.first_name;
      const last_name = errors?.last_name;
      const password = errors?.password;
      if (email) {
        setErrors((prev) => {
          return { ...prev, email: email[0] };
        });
      }
      if (first_name) {
        setErrors((prev) => {
          return { ...prev, first_name: first_name[0] };
        });
      }
      if (last_name) {
        setErrors((prev) => {
          return { ...prev, last_name: last_name[0] };
        });
      }
      if (password) {
        setErrors((prev) => {
          return { ...prev, password: password[0] };
        });
      }
    }
  };

  return (
    <>
      <AuthLayout
        getHelp
        title="Register your account"
        small_text={`Proceed to create account and setup your organization`}
      >
        {/* get started */}
        {/* auth form start */}
        <form onSubmit={handleSubmit} action="" className="auth-form-wrap">
          {/* two form group start */}
          <div className="two-form-group-box">
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="first_name" className="form-label">
                <figure className="img-box">
                  <img src={nameIcon} className="img" alt="" />
                </figure>
              </label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                className="form-input white-black-color"
                placeholder="first name"
                onChange={handleChange}
                value={details.first_name}
              />
              <div
                className={`status ${details.first_name && "status-active"}`}
              ></div>
              <p
                className={`placeholder white-black-bg white-black-color ${
                  details.first_name && "placeholder-active"
                }`}
              >
                first name
              </p>
              {errors.first_name && (
                <p className="error">{errors?.first_name}</p>
              )}
            </div>
            {/* form group end */}
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="last_name" className="form-label">
                <figure className="img-box">
                  <img src={nameIcon} className="img" alt="" />
                </figure>
              </label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                className="form-input white-black-color"
                placeholder="last name"
                onChange={handleChange}
                value={details.last_name}
              />
              <div
                className={`status ${details.last_name && "status-active"}`}
              ></div>
              <p
                className={`placeholder ${
                  details.last_name && "placeholder-active"
                }`}
              >
                last name
              </p>
              {errors.last_name && <p className="error">{errors?.last_name}</p>}
            </div>
            {/* form group end */}
          </div>
          {/* two form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="work_email" className="form-label">
              <figure className="img-box">
                <img src={workMailIcon} className="img" alt="" />
              </figure>
            </label>
            <input
              type="work_email"
              name="email"
              id="work_email"
              className="form-input white-black-color"
              placeholder="Work email"
              onChange={handleChange}
              value={details.email}
            />
            <div className={`status ${details.email && "status-active"}`}></div>
            <p
              className={`placeholder white-black-bg white-black-color ${
                details.email && "placeholder-active"
              }`}
            >
              Work email
            </p>
            {validateEmail(details?.email) && (
              <FaCheck style={{ color: "green" }} />
            )}
            {errors.email && <p className="error">{errors?.email}</p>}
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Password" className="form-label">
              <figure className="img-box">
                <img src={lockIcon} className="img" alt="" />
              </figure>
            </label>
            <input
              type={showPassword ? "text" : "Password"}
              name="password"
              id="Password"
              className="form-input white-black-color"
              placeholder="password"
              onChange={handleChange}
              value={details.password}
            />
            <div
              className={`status ${details.password && "status-active"}`}
            ></div>
            <p
              className={`placeholder  white-black-bg white-black-color ${
                details.password && "placeholder-active"
              }`}
            >
              Password
            </p>
            <div
              onClick={() => setShwoPassord(!showPassword)}
              className="show-hide"
            >
              {showPassword ? (
                <FaEyeSlash className="icon" />
              ) : (
                <FaEye className="icon" />
              )}
            </div>
            {errors.password && <p className="error">{errors?.password}</p>}
          </div>
          {/* form group end */}
          {/* submit form start */}
          {!loading ? (
            <input
              disabled={
                !details?.email ||
                !details.first_name ||
                !details.last_name ||
                !details?.password
              }
              style={{ marginTop: "2rem" }}
              type="submit"
              value="Create My Account"
              className={`form-group btn-reuse form-submit ${
                details.email &&
                details.first_name &&
                details.password &&
                details.last_name &&
                "form-submit-active"
              }`}
            />
          ) : (
            <div style={{ alignSelf: "center" }}>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
              {/* <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#004BFF"
                barColor="#004BFF"
              /> */}
              {/* <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#004BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              /> */}
            </div>
          )}
          {/* submit form  end */}
        </form>
        {/* auth form end */}
        {/* terms and privacy text start */}
        <div className="term-privacy-box">
          <p className="text">
            By clicking the button above, you agree to our{" "}
            <span
              onClick={() => window.open("http://revvex.io/terms-of-service")}
            >
              Terms of Service
            </span>{" "}
          </p>
          <p className="text">
            {" "}
            and{" "}
            <span
              onClick={() => window.open("http://revvex.io/privacy-policy")}
            >
              Privacy Policy.
            </span>
          </p>
        </div>
        {/* terms and privacy text end */}

        {/* already text start */}
        <div className="already-box">
          <p className="text">
            Already have an account?{" "}
            <span onClick={() => history.push("/login")}>Login</span>
          </p>
        </div>
        {/* already text end */}
      </AuthLayout>
    </>
  );
};

export default SignUp;
