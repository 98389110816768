import React, { useState } from "react";
// import userimg from "../../../../../../assets/hod-one-img.svg";
import Select from "react-select";
import { FaMinus } from "react-icons/fa";

const userList = [
  { label: "Ade Jones", value: 1 },
  { label: "Loe Jones", value: 2 },
  { label: "Ade Popes", value: 3 },
  { label: "Ade Jones", value: 4 },
];

const timeList = [
  { label: "24 hours", value: 1 },
  { label: "18 hours", value: 2 },
  { label: "12 hours", value: 3 },
  { label: "6 hours", value: 4 },
];

const typeList = [
  { label: "Single User", value: 1 },
  { label: "Any User", value: 2 },
];

const RemovableGroup = ({
  num,
  list,
  handleRemoveChild,
  onSelectUser,
  onSelectUserType,
  onSelectUserTime,
  onSelectUserTwo,
}) => {
  const [userSelect, setuserSelect] = useState(null);
  const [userTwoSelect, setuserTwoSelect] = useState(null);
  const [userTypeSelect, setuserTypeSelect] = useState(null);
  const [timeeSelect, settimeeSelect] = useState(null);

  //   const handleRemoveChild = (param) => {
  //     console.log(param);
  //   }
  return (
    <div
      className="remove-group"
      style={{
        width: userTypeSelect?.label === "Any User" ? "100%" : "80%",
        gridTemplateColumns:
          userTypeSelect?.label === "Any User"
            ? " min-content 2fr 1fr 1fr 1fr 5rem"
            : " min-content 2fr 1fr 1fr",
      }}
    >
      <p className="step">{num + 1 || "1"}</p>
      {/* select box */}
      <div className="box">
        <label style={{ marginBottom: ".5rem" }} htmlFor="">
          {userTypeSelect?.label === "Any User" && "1st"} User
        </label>
        <Select
          className="select "
          value={userSelect}
          onChange={(e) => {
            setuserSelect(e);
            onSelectUser(e);
            //   console.log(e);
          }}
          options={userList}
        />
      </div>
      {/* select end */}
      {/* select type box start */}
      <div className="box">
        <label style={{ marginBottom: ".5rem" }} htmlFor="">
          Required
        </label>
        <Select
          className="select select-two"
          value={userTypeSelect}
          onChange={(e) => {
            setuserTypeSelect(e);
            onSelectUserType(e);
            //   console.log(e.label);
          }}
          options={typeList}
        />
      </div>
      {/* select type box end */}
      {/* select type box start */}
      {userTypeSelect?.label === "Any User" && (
        <div className="box">
          <label style={{ marginBottom: ".5rem" }} htmlFor="">
            Priority Timeframe
          </label>
          <Select
            className="select select-two"
            value={timeeSelect}
            onChange={(e) => {
              settimeeSelect(e);
              onSelectUserTime(e);
              //   console.log(e);
            }}
            options={timeList}
          />
        </div>
      )}
      {/* select type box end */}
      {/* select box */}
      {userTypeSelect?.label === "Any User" && (
        <div className="box">
          <label style={{ marginBottom: ".5rem" }} htmlFor="">
            2nd User
          </label>
          <Select
            className="select "
            value={userTwoSelect}
            onChange={(e) => {
              setuserTwoSelect(e);
              onSelectUserTwo(e);
              //   console.log(e);
            }}
            options={userList}
          />
        </div>
      )}
      {/* select end */}
      {/* cancel bos start */}
      <div className="cancel-box" onClick={handleRemoveChild}>
        <FaMinus className="icon" />
      </div>
      {/* cancel bos end */}
    </div>
  );
};

export default RemovableGroup;
