

import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/admin-settings/AdminSettings.css";
import { FaAngleRight, FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import SharedRemovableGroup from "./SharedRemovableGroup";
// import ButtonBlue from "../../../../buttons/ButtonBlue";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import { createSharedService } from "../../../../../redux/shared-service/shareService";
import { formatNumberWithoutComma } from "../../../../../helper/Helpers";

const AddSharedService = () => {
  const actionCtx = useContext(ActionContext);
  const { loading } = useSelector((state) => state.shared_service);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    title: "",
    apportions: [],
  });
  const [sharedList, setSharedList] = useState([]);
  const history = useHistory();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  useEffect(() => {
    if(actionCtx?.activeOrg?.type !== "group"){
      history?.goBack()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!actionCtx?.activeOrg?.shared_service_activated) {
      history.push("/dashboard-administration-admin-settings");
    }
      // eslint-disable-next-line
  }, [actionCtx.setActiveOrgManual]);

  useEffect(() => {
     setSharedList([])
  }, [actionCtx?.activeOrg])

  const handleAddToSharedList = () => {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const obj = {
      id: id,
      subsidiary_id: "",
      budget_percentage: "",
    };
    const newList = [...sharedList, obj];
    setSharedList(newList);
    // console.log(newList);
    // console.log(test);
  };

  const handleSelectUser = (param, child) => {
    const newList = sharedList.map((chi) =>
      chi.id === child.id ? { ...chi, subsidiary_id: param.value } : chi
    );
    // console.log(newList);
    setSharedList(newList);
  };

  const handleSelectPro = (param, child) => {
    const newList = sharedList.map((chi) =>
      chi.id === child.id ? { ...chi, budget_percentage: param } : chi
    );
    // console.log(newList);
    setSharedList(newList);
  };

  const handleRemoveChild = (child) => {
    const newList = sharedList.filter((chi) => chi.id !== child.id);

    setSharedList(newList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newList = sharedList.map((chi) => {
      return {
        budget_percentage: formatNumberWithoutComma(chi.budget_percentage),
        subsidiary_id: chi?.subsidiary_id,
      };
    });
    const obj = {
      ...details,
      apportions: newList,
      organization_id: actionCtx?.activeOrg?.id
    };
    const data = await dispatch(createSharedService(obj))
    if(data?.payload?.success){
      setDetails({
        title: "",
        apportions: [],
      })
      setSharedList([])
      history.push("/dashboard-administration-admin-settings-add-subsidary-shared-service-main")
    }
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitle={`Add Subsidiary`}
        pageTitleSpan={`Step 2 of 6`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Shared Service</p>
        </div>
        {/* text tititl box start */}
        <div className="title-text-box" style={{ width: "100%" }}>
          <p className="title">Shared Service</p>
          <p className="text">
            Set responsibility for the execution and the handling of specific
            operational tasks within your organization.
          </p>
        </div>
        {/* text tititl box end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "100%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1. Shared Service Information</p>
              <div
                className="form-wrap"
                style={{ width: "80%", gridTemplateRows: "unset" }}
              >
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary" className="label">
                    Title <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    className={`input ${details.title && "input-active"}`}
                    id="Subsidiary Name"
                    name="title"
                    onChange={handleChange}
                    value={details.title}
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title"> 2. Apportion %</p>

              {/* removable box start */}
              <div className="removable-wrap">
                {sharedList.map((chid, idx) => {
                  return (
                    <SharedRemovableGroup
                      // list={approvalList}
                      key={chid.id || idx}
                      num={idx}
                      handleRemoveChild={() => handleRemoveChild(chid)}
                      onSelectUser={(e) => handleSelectUser(e, chid)}
                      onSelectPor={(e) => handleSelectPro(e, chid)}
                    />
                  );
                })}
              </div>
              {/* removable box end */}

              {/* add more start */}
              <div className="add-approval-box" onClick={handleAddToSharedList}>
                <FaPlus className="icon" />
                <p className="text">Add Subsidiary</p>
              </div>
              {/* add more end */}
            </div>
            {/* wrap end */}

            {/* button start */}
            {!loading ? (
              <input
                disabled={
                  !details?.title ||
                  sharedList.length < 1 ||
                  !sharedList[0]?.subsidiary_id ||
                  !sharedList[0]?.budget_percentage
                }
                type="submit"
                value="Save & Continue"
                className={`form-group form-submit ${
                  details?.title &&
                  sharedList.length > 0 &&
                  sharedList[0]?.subsidiary_id &&
                  sharedList[0]?.budget_percentage &&
                  "form-submit-active"
                }`}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* button end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default AddSharedService;