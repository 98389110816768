import React, { useContext } from "react";
import { useEffect } from "react";
// import { Children } from "react";
import { useDispatch, useSelector } from "react-redux";
// import HeaderNav from "../../../../component/layout/dashboard/HeaderNav";
import NotificationModal from "../../../../component/layout/dashboard/NotificationModal";
import ActionContext from "../../../../context/ActionContext";
import EmployeeHeaderNav from "./EmpHeaderNav";
import EmployeeSidebarMenu from "./EmpSidebarMenu";
import RevvexActionBar from "../../../../component/fragments/ActionBar";
import moment from "moment";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getUserActivePlan } from "../../../../redux/users/users";

const EmployeeDashboardLayout = ({
  goBack,
  pageTitle,
  pageTitleSpan,
  children,
  organization,
}) => {
  const actionCtx = useContext(ActionContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { plan_details } = useSelector((state) => state.users);
  useEffect(() => {
    if (user?.organizations?.length > 0) {
      const brandcolor = localStorage.getItem("brand_color");
      document.documentElement.style.setProperty("--blue-color", brandcolor);
    }

    // console.log("yes");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
    };
    getUserPlanFunc(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserPlanFunc = async (obj) => {
    const data = await dispatch(getUserActivePlan(obj));
    // console.log(data);
    if (data?.payload?.success) {
      const obj = data?.payload?.data;
      if (Object.keys(obj)?.length > 0) {
        // console.log(obj);
        if (obj?.active_plan_details?.status === "active") {
          actionCtx.setPlanExpire(false);
        }
        if (obj?.active_plan_details?.status === "trail") {
          actionCtx.setPlanExpire(false);
        }
        if (
          obj?.active_plan_details?.status === "inactive" &&
          Object.keys(obj?.active_plan_details)?.length > 0 &&
          !obj?.active_plan_details?.is_trial
        ) {
          actionCtx.setPlanExpire(true);
        }
        // if( obj?.active_plan_details?.status !== "trail" && obj?.active_plan_details){
        //   setShowExpire(true);
        // }
      }
    }
    // if (!data?.payload?.response?.data?.success && !data?.payload?.success) {
    //   setShowExpire(true);
    // }
  };

  const getDaysLeft = (param) => {
    const currentDate = moment(); // Current date and time
    const targetDate = moment(param); // Replace with your target date
    // console.log(param);
    const daysLeft = targetDate.diff(currentDate, "days");
    return daysLeft;
  };

  return (
    <div className="dashboard-layout-wrap">
      <div
        onClick={() => actionCtx.profileBoxOpenFunc("close")}
        className={`sidebar ${actionCtx.isSidebarReduce && "sidebar-reduce"}`}
      >
        <EmployeeSidebarMenu />
      </div>
      <div
        // onClick={() => actionCtx.setShowSupportDropDown("close")}
        className={`header ${actionCtx.isSidebarReduce && "header-reduce"}`}
      >
        <EmployeeHeaderNav
          goBack={goBack}
          pageTitle={pageTitle}
          pageTitleSpan={pageTitleSpan}
          organization={organization}
        />
      </div>
      <div
        onClick={() => {
          actionCtx.profileBoxOpenFunc("close");
          actionCtx.setShowSupportDropDown("close");
        }}
        className={`main ${actionCtx.isSidebarReduce && "main-reduce"}`}
        style={{ zIndex: actionCtx?.isModalOut ? "4" : "1" }}
      >
        {/* epire less then show start */}

        {location.pathname !== "/dashboard-subscription-upgrade-plan" &&
          user?.role === "creator administrator" && (
            <RevvexActionBar
              msg={
                Number(
                  getDaysLeft(plan_details?.active_plan_details?.end_date)
                ) <= 5 &&
                Number(
                  getDaysLeft(plan_details?.active_plan_details?.end_date)
                ) !== 0
                  ? `Your subscription will expire in ${getDaysLeft(
                      plan_details?.active_plan_details?.end_date
                    )} days, click on "Change Plan" to renew or change plan.`
                  : Number(
                      getDaysLeft(plan_details?.active_plan_details?.end_date)
                    ) === 0
                  ? `Youre subscription has expired, click on "Change Plan" to renew or change plan.`
                  : ``
              }
              type={
                Number(
                  getDaysLeft(plan_details?.active_plan_details?.end_date)
                ) <= 5 &&
                Number(
                  getDaysLeft(plan_details?.active_plan_details?.end_date)
                ) > 2
                  ? `info`
                  : Number(
                      getDaysLeft(plan_details?.active_plan_details?.end_date)
                    ) <= 2 &&
                    Number(
                      getDaysLeft(plan_details?.active_plan_details?.end_date)
                    ) > 0
                  ? `warning`
                  : Number(
                      getDaysLeft(plan_details?.active_plan_details?.end_date)
                    ) === 0
                  ? `error`
                  : ""
              }
              visible={
                actionCtx.showInfoSubExpire &&
                Object.keys(plan_details)?.length > 0
              }
              actionText={
                user?.role === "creator administrator" ? "Change Plan" : ""
              }
              onCancel={() => {
                actionCtx.setShowInfoSubExpire(false);
              }}
              onAction={() => {
                user?.role === "creator administrator" &&
                  history.push("/dashboard-subscription-upgrade-plan");
              }}
            />
          )}
        {/* expire less than show end */}
        {children}
      </div>

      {/* notification start */}
      {actionCtx?.showNotification && <NotificationModal />}
      {/* notification end */}
    </div>
  );
};
export default EmployeeDashboardLayout;
