/* eslint-disable no-undef */
import React, { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import ActionContext from "../../../../context/ActionContext";
// import ButtonBlue from "../../../buttons/ButtonBlue";
import "./BudgetAssumptionModal.css";

const BudgetAssmptionModal = ({ onClose, onBigShow, budgeBtn }) => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const { budgetAssumptionDrop } = useSelector(
    (state) => state.budgetAssumptions
  );

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <div
      style={{
        padding: "3rem",
        width: "55rem",
        backgroundColor: "var(--bg-white-color)",
      }}
    >
      <div style={{ display: "grid", gridTemplateColumns: "2.7fr 4fr 0.1fr" }}>
        <div>
          <p style={{ fontWeight: "600", color: "var(--black-color)" }}>
            Budget Assumption
          </p>
        </div>
        <div style={{ marginLeft: "-4rem" }}>
          {!budgeBtn && (
            <button
              className="budget-assumption-modal-btn"
              onClick={
                () =>
                  history.push(
                    "/dashboard-administration-admin-settings-budget-budget-assumption"
                  )
                // console.log(actionCtx?.activeOrg)
              }
            >
              Manage
            </button>
          )}
        </div>
        <div>
          <FaTimes
            style={{
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </div>
      </div>
      <p style={{ marginTop: "2rem", fontWeight: "bold" }}>Description</p>
      <p>
        {" "}
        {
          actionCtx?.activeOrg?.active_fiscal_year?.budget_assumption
            ?.assumption
        }
      </p>
      {/*  */}
      <div className="budget-assumption-modal-home-page selected-content-box">
        <div
          style={{
            gridTemplateColumns: `repeat(${budgetAssumptionDrop?.length}, max-content)`,
          }}
          className="wrap"
        >
          {actionCtx?.activeOrg?.active_fiscal_year?.budget_assumption
            ?.attachment?.file_name ? (
            <>
              {actionCtx?.activeOrg?.active_fiscal_year?.budget_assumption?.attachment?.mime_type.includes(
                "image/"
              ) ? (
                <figure
                  style={{
                    // backgroundImage: `url(${actionCtx?.activeOrg?.active_fiscal_year?.budget_assumption?.attachment?.file_path})`,
                    width: "25rem",
                    cursor: "pointer",
                    // positio,
                  }}
                  className="box"
                  onClick={() => {
                    // console.log("yes");
                    onBigShow(
                      actionCtx?.activeOrg?.active_fiscal_year
                        ?.budget_assumption?.attachment?.file_path
                    );
                  }}
                >
                  <img
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                    src={
                      actionCtx?.activeOrg?.active_fiscal_year
                        ?.budget_assumption?.attachment?.file_path
                    }
                    alt={`${actionCtx?.activeOrg?.active_fiscal_year?.budget_assumption?.attachment?.file_name}`}
                  />
                </figure>
              ) : (
                <div
                  onClick={() => {
                    actionCtx?.activeOrg?.active_fiscal_year?.budget_assumption?.attachment?.mime_type.includes(
                      "image/"
                    )
                      ? onBigShow(
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.budget_assumption?.attachment?.file_path
                        )
                      : downloadCsv(
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.budget_assumption?.attachment?.file_path
                        );
                  }}
                  style={{ cursor: "pointer" }}
                  className="box box-file"
                >
                  <p className="name">
                    File name:{" "}
                    <span>
                      {/* red */}
                      {
                        actionCtx?.activeOrg?.active_fiscal_year
                          ?.budget_assumption?.attachment?.file_name
                      }
                    </span>
                  </p>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* budget end view */}
    </div>
  );
};
export default BudgetAssmptionModal;
