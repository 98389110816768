import React, { useContext, useEffect, useState } from "react";
import CommonSortBox from "../../../../fragments/CommonSortBox";
// import NetProfitChart from "../chart/chartOne";
import NetProfitProjectionChart from "../chart/netProfitProChart";
import ActionContext from "../../../../../context/ActionContext";
import Select from "react-select";
import {
  formatNumber,
  formatNumberWithComma,
  getChartStructureHomeActualGrossProfit,
  reactSelectStyleCommonSort,
} from "../../../../../helper/Helpers";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import DeleteNetProfitProjection from "../modals/deleteModal";
import Modal from "react-awesome-modal";
// import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardDetails } from "../../../../../redux/users/users";

const ActualGrossProfit = () => {
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const { loading, dashboard_details } = useSelector((state) => state.users);
  const [dataChartValueGrossVer, setDataChartValueGrossVer] = useState({
    labels: "",
    datasets: [
      {
        label: "Actual Gross Profit",
        data: [],
        backgroundColor: "#07A287",
      },
    ],
  });
  const categoryList = [
    { label: "Default", value: 1 },
    { label: "All Projection", value: 2 },
    { label: "Approved", value: 3 },
    { label: "Awaiting Approval", value: 4 },
    { label: "Rejected", value: 5 },
  ];
  const [chartFilterVal, setChartFilterVal] = useState("");
  // const [indexNo, setIndexNo] = useState("");

  const [showModal, setShowModal] = useState({ delete: false });


  const tableHeadList = [
    "Department",
    "Total Prj.Expense(N)",
    "Total Prj. Revenue(N)",
    "Product Sold(N)",
    "Total GP%",
    "Total Prj.Net Profit(N)",
  ];
  useEffect(() => {
    getNetProfit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNetProfit = async () => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
    };
    const data = await dispatch(getDashboardDetails(obj));
    if (data?.payload?.success) {
      // console.log(data.payload.data);
      const graphList =
        data?.payload?.data?.actual_gross_profit_details
          ?.actual_gross_profit_graph;
      // console.log(graphList);
      setDataChartValueGrossVer(
        getChartStructureHomeActualGrossProfit(graphList)
      );
    }
  };

  return (
    <>
      <div className="expense-projection-inde-wrap">
        <CommonSortBox />
        <div className="title-hide-box">
          <p
            onClick={() => {
              console.log(
                dashboard_details?.actual_gross_profit_details
                  ?.actual_gross_profit_by_department
              );
            }}
            className="title"
          >
            Total Gross Profit{" "}
            <span>
              {formatNumber(
                dashboard_details?.actual_gross_profit_details
                  ?.actual_gross_profit || "0"
              )}
            </span>
          </p>
          {/* hide box start */}
          <div className="hide-box">
            <input
              checked={actionCtx.isShowChart?.expense_projection}
              className="input"
              onChange={(e) => actionCtx.showChartFunc("expense-projection")}
              type="checkbox"
              name=""
              id="chart-check"
            />
            <p className="label-text">
              {actionCtx.isShowChart?.expense_projection
                ? "Hide Chart"
                : "Show Chart"}
            </p>
            <label htmlFor="chart-check" className="label">
              <span></span>
              <span></span>
            </label>
          </div>
          {/* hide box end */}
        </div>
        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Actual Gross Graph</p>
              <Select
                // isSearchable={true}
                // isMulti

                styles={reactSelectStyleCommonSort}
                placeholder="Fiscal Year"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <NetProfitProjectionChart dataToShow={dataChartValueGrossVer} />
            </div>
            {/* chart end */}
          </div>
        )}
        <ActualReuseableTable
          dontShowBnSearch
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          six={tableHeadList[5]}
          // action
        >
          {dashboard_details?.actual_gross_profit_details?.actual_gross_profit_by_department?.data?.map(
            (chi, idx) => {
              const {
                department,
                total_pro_expense,
                total_pro_revenue,
                products_sold,
                total_pro_gp_inper_cent,
                total_pro_net_profit,
              } = chi;
              return (
                <TableBodyRow
                loading={loading}
                  key={idx}
                  one={department?.name}
                  two={formatNumberWithComma(total_pro_expense) || "0"}
                  three={formatNumberWithComma(total_pro_revenue) || "0"}
                  four={formatNumberWithComma(products_sold) || "0"}
                  five={total_pro_gp_inper_cent || "0"}
                  six={formatNumberWithComma(total_pro_net_profit) || "0"}
                  // action
                  // setIndexNo={() => setIndexNo(idx)}
                  // //   viewText={`modify`}
                  // rowNo={idx}
                  // indexNo={indexNo}
                  // updateText={`modify`}
                  // deleteText={`delete`}
                  // onDelete={() =>
                  //   setShowModal((prev) => {
                  //     return { ...prev, delete: true };
                  //   })
                  // }
                  // onUpdate={() =>
                  //   history.push(
                  //     "/dashboard-administration-admin-settings-add-branch"
                  //   )
                  // }
                />
              );
            }
          )}
        </ActualReuseableTable>
      </div>
      {/* modal start */}
      <Modal effect="fadeInLeft" visible={showModal.delete}>
        <DeleteNetProfitProjection
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, delete: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
    </>
  );
};
export default ActualGrossProfit;
