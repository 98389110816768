import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc, customErrorId } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getBranches = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/branches/organization/${formData}`
      );
      // console.log("branch", data.data.branches);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
          //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranchesDrop(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
           toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesWithPagination = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/branches/organization/${formData?.org_id}?paginated=true&per_page=${formData?.per_page || 5}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
          //  toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
           toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesByFilter = createAsyncThunk(
    "admin/get-branches",
    async (formData, thunkAPI) => {
      try {
        const { data } = await axios.get(
          `admin/branches/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
        );
        // console.log("branch", data.data.branches.data);
        if (!data?.success) {
          // toast.error(data.message, {
          //   theme: "colored",
          //  toastId: customErrorId,
          // });
          // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
          return thunkAPI.rejectWithValue(data);
        }
        if (data?.success) {
            // console.log(data);
          const branchInfo = data.data.branches;
          await thunkAPI.dispatch(setBranchesFilter(branchInfo));
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 500) {
          toast.error(err.response?.data?.message, {
            theme: "colored",
           toastId: customErrorId,
          });
        }
        if (err) {
          return thunkAPI.rejectWithValue(err);
        }
      }
    }
  );

export const getBranchesPagination = createAsyncThunk(
  "admin/get-branches-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
           toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranch = createAsyncThunk(
  "admin/get-branch",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/branches/${formData}`);
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
           toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesBySubsidiary = createAsyncThunk(
    "admin/get-branch",
    async (formData, thunkAPI) => {
      //   console.log("yes");
      try {
        const { data } = await axios.get(`/admin/branches/subsidiary/${formData}`);
          console.log("currency", data);
        if (!data?.success) {
          return thunkAPI.rejectWithValue(data);
        }
        if (data?.success) {
            const branchInfo = data.data.branches;
            await thunkAPI.dispatch(setBranchSubDrop(branchInfo));
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        if (err.response.status === 500) {
          toast.error(err?.response?.data?.message, {
            theme: "colored",
           toastId: customErrorId,
          });
        }
        if (err) {
          return thunkAPI.rejectWithValue(err);
        }
      }
    }
  );

export const createBranch = createAsyncThunk(
  "admin/create-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/branches", formData);
      console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
           toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
           toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
           toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "admin/delete-delete",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/branches/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
           toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
           toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
           toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateBranch = createAsyncThunk(
  "admin/update-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/branches/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
           toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
           toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
           toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
           toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const branch = createSlice({
  name: "branch",
  initialState: {
    branchDrop: [],
    branchSubDrop: [],
    branches: [],
    links: {},
    meta: {},
    branch: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setBranches: (state, action) => {
      state.isAuth = true;
      state.branches = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setBranchesFilter: (state, action) => {
        state.isAuth = true;
        state.branches = action.payload;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      },
    setBranchesDrop: (state, action) => {
      state.isAuth = true;
      state.branchDrop = addLabelValueFunc(action.payload);
    },
    setBranchSubDrop: (state, action) => {
      state.isAuth = true;
      state.branchSubDrop = addLabelValueFunc(action.payload);
    },
    setBranch: (state, action) => {
      state.isAuth = true;
      state.branch = action.payload;
    },
  },
  extraReducers: {
    [createBranch.pending]: (state) => {
      state.loading = true;
    },
    [createBranch.fulfilled]: (state) => {
      state.loading = false;
    },
    [createBranch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateBranch.pending]: (state) => {
      state.loading = true;
    },
    [updateBranch.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateBranch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getBranches.pending]: (state) => {
      state.loading = true;
    },
    [getBranches.fulfilled]: (state) => {
      state.loading = false;
    },
    [getBranches.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesBySubsidiary.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesBySubsidiary.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesBySubsidiary.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteBranch.pending]: (state) => {
      state.loading = true;
    },
    [deleteBranch.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteBranch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBranch, setBranches, setBranchesDrop, setBranchesFilter, setBranchSubDrop } = branch.actions;

export default branch.reducer;
