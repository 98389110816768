import React from "react";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import imgOne from "../../../../../../assets/hod-two-img.svg";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";

const EmployeeRejectCommentModal = ({ onCancel }) => {
  return (
    <div style={{ width: "55rem" }} className="overview-card-modal-wrap">
      <div className="top-box">
        <p className="title">Reject</p>
      </div>
      <div className="content-box">
        <div className="img-input-wrap">
          <figure className="img-box">
            <img src={imgOne} alt="" className="img" />
          </figure>
          <textarea
            placeholder="type here your comment"
            style={{ resize: "unset" }}
            name=""
            id=""
            cols="30"
            rows="5"
            className="input-text"
          ></textarea>
        </div>
      </div>
      <div className="bottom-box">
        <ButtonBlue onClick={onCancel} text={`Cancel`} />
        <ButtonBlue bgColor={`#CC0905`} text={`Yes, Reject `} />
      </div>
    </div>
  );
};

export default EmployeeRejectCommentModal;
