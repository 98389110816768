import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
import CommonExpenseLayout from "./CommonLayout";
import Select from "react-select";
// import ExpenseChart from "./RevenueChart";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActionContext from "../../../../../context/ActionContext";
import RevenueChart from "./RevenueChart";
// import userImg from "../../../../../assets/hod-one-img.svg";
import RevenueChartOne from "./RevenueChartOne";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getActualRevenueChart,
  getActualRevenueChartPagination,
} from "../../../../../redux/actuals/revenue";
import {
  checkCurrencyFormat,
  formatNumberWithComma,
  // FormatNumWithCurrency,
  getChartStructureGross,
  getChartStructures,
  perPageOptions,
  reactSelectStyleTable,
} from "../../../../../helper/Helpers";

const RevenueActualIndex = ({ children, chartBox }) => {
  const actionCtx = useContext(ActionContext);
  //   const [showChart, setShowChart] = useState(true);
  const history = useHistory();
  const [indexNo, setIndexNo] = useState("");
  const dispatch = useDispatch();

  const { loading, actual_revenue, loadingPage } = useSelector(
    (state) => state.actual_revenue_chart
  );
  const [dataChartValue, setDataChartValue] = useState({
    labels: "",
    datasets: [
      {
        label: "Approved",
        data: [],
        backgroundColor: "#07A287",
      },
      {
        label: "Pending",
        data: [],
        backgroundColor: "#FCA549",
      },
      {
        label: "Rejected",
        data: [],
        backgroundColor: "#CC0905",
      },
    ],
  });
  const brandcolor = localStorage.getItem("brand_color");
  const [dataChartValueGross, setDataChartValueGross] = useState({
    labels: "",
    datasets: [
      {
        label: "Gross Profit",
        data: [],
        backgroundColor: brandcolor,
      },
    ],
  });
  // const [chartFilterVal, setChartFilterVal] = useState("");

  // const categoryList = [
  //   { label: "Zojapay", value: 1 },
  //   { label: "ITH", value: 2 },
  //   { label: "Zojatech", value: 3 },
  // ];
  const [dataChartValueGrossVer, setDataChartValueGrossVer] = useState({
    labels: "",
    datasets: [
      {
        label: "Gross Profit",
        data: [],
        backgroundColor: brandcolor,
      },
    ],
  });

  useEffect(() => {
    getChartValueHere();
    // eslint-disable-next-line
  }, [actionCtx?.activeOrg, actionCtx?.currencyValue, actionCtx?.perPage]);

  const vertProSelect = [
    { label: "Product", value: "product" },
    { label: "Vertical", value: "vertical" },
  ];

  const [verProVal, setVerProVal] = useState({
    label: "Product",
    value: "product",
  });

  const getChartValueHere = async () => {
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    const data = await dispatch(getActualRevenueChart(obj));
    if (data?.payload?.success) {
      // console.log(data?.payload?.data);
      const list = getChartStructures(data?.payload?.data?.graph_data);
      const listGross = getChartStructureGross(
        data?.payload?.data?.gross_per_product
      );
      const listGrossVer = getChartStructureGross(
        data?.payload?.data?.gross_per_vertical
      );
      setDataChartValue(list);
      setDataChartValueGross(listGross);
      setDataChartValueGrossVer(listGrossVer);
    }
  };

  const tableHeadList = [
    "Subsidiary",
    "Departments",
    "User",
    `Total Revenue ( ${checkCurrencyFormat(actionCtx?.currencyValue?.label?.split(" - ")[0])} )`,
    `Total GP  ( ${checkCurrencyFormat(actionCtx?.currencyValue?.label?.split(" - ")[0])} )`,
    "Status",
    "Action",
  ];

  const handlePagination = (url) => {
    const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getActualRevenueChartPagination(obj));
  };

  const handlePaginationNum = (num) => {
    // const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getActualRevenueChartPagination(obj));
  };

  return (
    <>
      <CommonExpenseLayout
        setDataChartValue={setDataChartValue}
        onReset={getChartValueHere}
        setDataChartValueGross={setDataChartValueGross}
        setDataChartValueGrossVer={setDataChartValueGrossVer}
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Actual Income Graph</p>
              {/* <Select
                // isSearchable={true}
                // isMulti
                placeholder="Sort By"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              /> */}
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <RevenueChart loading={loading || loadingPage} datatoShow={dataChartValue} />
            </div>
            {/* chart end */}
          </div>
        )}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">{`Gross Profit Per ${verProVal?.label}`}</p>
              <Select
                styles={reactSelectStyleTable}
                placeholder="Sort By"
                className="select"
                value={verProVal}
                onChange={(e) => {
                  setVerProVal(e);
                }}
                options={vertProSelect}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <RevenueChartOne
              loading={loading || loadingPage}
                datatoShow={
                  verProVal?.value === "product"
                    ? dataChartValueGross
                    : dataChartValueGrossVer
                }
              />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* perpage box start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              position: "relative",
              zIndex: "20",
              marginBottom: "-1rem",
            }}
            className="perpage-wrap"
          >
            <span style={{ fontWeight: "600" }}> All Actual Expense</span>{" "}
            <Select
              styles={reactSelectStyleTable}
              onChange={(e) => {
                actionCtx?.setPerpage(e);
              }}
              value={actionCtx?.perPage}
              options={perPageOptions}
            />
          </div>
          {/* perpage box end */}
          {/* table box start */}
          <ActualReuseableTable
            loading={loading && actual_revenue?.length < 1}
            loadingNum={7}
            useNumPagination
            currentPage={actual_revenue?.departments_projections?.current_page}
            totalTableLength={actual_revenue?.departments_projections?.total}
            onNumPage={(e) => {
              handlePaginationNum(e);
            }}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six
            // six={tableHea
            fromPage={actual_revenue?.departments_projections?.from}
            totalPage={actual_revenue?.departments_projections?.total}
            toPage={actual_revenue?.departments_projections?.to}
            prevPage={actual_revenue?.departments_projections?.prev_page_url}
            nextPage={actual_revenue?.departments_projections?.next_page_url}
            onPrevPage={() =>
              handlePagination(
                actual_revenue?.departments_projections?.prev_page_url
              )
            }
            onNextPage={() =>
              handlePagination(
                actual_revenue?.departments_projections?.next_page_url
              )
            }
          >
            {actual_revenue?.departments_projections?.data?.map((chi, idx) => {
              const { subsidiary, department, user, total_revenue, total_gp } =
                chi;
              return (
                <TableBodyRow
                  loading={loading || loadingPage}
                  key={idx}
                  one={subsidiary?.name}
                  two={department?.name}
                  user={{ img: user?.profile_picture, name: user?.full_name }}
                  five={
                    formatNumberWithComma(String(total_revenue || 0)) || "0"
                  }
                  six={formatNumberWithComma(total_gp || 0) || "0"}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  updateText={` Update Branch`}
                  onNextPage={() => {
                    console.log(chi);
                    localStorage.setItem(
                      "actual-revenue-chart-id",
                      chi?.department?.id
                    );
                    localStorage.setItem(
                      "actual-revenue-chart-user-id",
                      chi?.user?.id
                    );
                    history.push("/dashboard-actual-revenue-main");
                  }}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </CommonExpenseLayout>
    </>
  );
};

export default RevenueActualIndex;
