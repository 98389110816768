import React, { useContext, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../../styles/dashboard/admin-settings/PreferenceSettings.css";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  createCurrencyConversion,
  getCurriencies,
} from "../../../../../redux/currency/currency";
import ActionContext from "../../../../../context/ActionContext";
import { NumericFormat } from "react-number-format";
import { Dna } from "react-loader-spinner";

const AddCurrencyModal = ({ children, onCancel, onFinish }) => {
  const dispatch = useDispatch();
  const { currencyDrop } = useSelector((state) => state.currency);
  const [details, setDetails] = useState({
    organization_id: "",
    currency_id: "",
    base_currency_id: "",
    rate: "",
  });

  useEffect(() => {
    dispatch(getCurriencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionCtx = useContext(ActionContext);
  const { loading } = useSelector((state) => state.currency);

  const handleSubmit = async () => {
    const obj = {
      ...details,
      currency_id: details?.currency_id?.value,
      base_currency_id:
        actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.id,
      organization_id: actionCtx?.activeOrg?.id,
      rate: formatNumberWithoutComma(details?.rate),
    };
    const data = await dispatch(createCurrencyConversion(obj));
    if (data?.payload?.success) {
      onFinish();
      setDetails({
        organization_id: "",
        currency_id: "",
        base_currency_id: "",
        rate: "",
      });
    }
  };

  return (
    <div className="update-password-wrap">
      {children}
      <div className="title-box">
        <p className="title">Add New Currency</p>
        <div onClick={onCancel} className="cancel-box">
          <FaTimes className="icon" />
        </div>
      </div>
      {/* content box start */}
      <div className="content-box">
        <p className="text">
          Please note any changes made to your base currency would automatically
          affect your financial setting. Set other currency for a specific
          financial period
        </p>
        {/* form group start */}
        <div className="form-group-currency-modal">
          <label className="label" style={{ marginBottom: ".5rem" }} htmlFor="">
            Currency <span>*</span>
          </label>
          {/* select box */}
          <div className="box">
            <Select
              className="select "
              name="currency_id"
              placeholder="Sync with system settings"
              value={details?.currency_id}
              styles={reactSelectStyle}
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, currency_id: e };
                });
              }}
              options={currencyDrop}
            />
          </div>
          {/* select end */}
        </div>
        {/* form group end */}
        {/* group box start */}
        <div className="form-group-currency-modal">
          <label htmlFor="Name" className="label">
            Set Rate <span>*</span>
          </label>
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            type="text"
            name="currency_rate"
            thousandsGroupStyle={"lakh"}
            allowNegative
            onChange={(e) => {
              setDetails((prev) => {
                return {
                  ...prev,
                  rate: e.target.value,
                };
              });
            }}
            thousandSeparator={","}
            value={details?.rate}
            id="set_currency"
            placeholder="610.00"
            className={`input ${details?.rate && "input-active"}`}
          />
        </div>
        {/* group box end */}
      </div>
      {/* content box end */}
      {/* btn start */}
      <div className="btn-box">
        {!loading ? (
          <ButtonBlue
            bgColor={
              !details?.currency_id || !details?.rate
                ? "#cccccc"
                : "var(--blue-color"
            }
            onClick={() =>
              details?.currency_id && details?.rate && handleSubmit()
            }
            text={`Save`}
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "flex-end" }}>
            <Dna
              visible={true}
              height="70"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
      {/* btn end */}
    </div>
  );
};

export default AddCurrencyModal;
