import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteVertical } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import CommonDeleteModal from "./CommonDeleteModal";

const DeleteBudgetRevVerticalModal = ({ onClose, onFinish, detail }) => {
  const { loading } = useSelector((state) => state.vertical);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    const id = detail?.id;
    const data = await dispatch(deleteVertical(id));
    if (data?.payload?.success) {
      onFinish();
    }
  };
  return (
    <>
      <CommonDeleteModal
        loading={loading}
        onDelete={handleDelete}
        title={`Delete Vertical (${detail?.name})`}
        text={`Are you sure you want to delete this information. Deleting this, would affect your 
organizatiuon budget settings. Do you still want to delete?`}
        onClose={onClose}
      />
    </>
  );
};

export default DeleteBudgetRevVerticalModal;
