
import React, { Fragment, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Onboarding_route_group } from "./routes/auth";
import { Dashboard_route_group } from "./routes/dashboard";
import { Employee_onboarding_route_group } from "./employee-folder/routes/auth";
import PrivateRoute from "./routes/PrivateRoute";
import { Employee_dashboard_route_group } from "./employee-folder/routes/dashboard";
import EmployeePrivateRoute from "./employee-folder/routes/PrivateRoute";
import { ToastContainer } from "react-toastify";
import { Integration_redirect_page } from "./routes/dashboard/general/integration/zohoRedirect";
import ActionContext from "./context/ActionContext";
import ServerDownPage from "./component/pages/ServerDownPage";
import ServerDownPageTwo from "./component/pages/ServerDownTwoPage";

function App() {
  const actionCtx = useContext(ActionContext)

  if(actionCtx.serverDown){
    return(
      <ServerDownPage />
    )
  }

  if(actionCtx.serverDown){
    return(
      <ServerDownPageTwo />
    )
  }

  return (
    <Fragment>
    <ToastContainer />
      <Switch>
        <Route exact path="/">
          <Redirect to="/get-started" />
        </Route>

        {/* employee onboarding routes group start */}
        {Employee_onboarding_route_group.map((route, idx) => {
          return <Route key={idx} exact {...route} />;
        })}
        {/* employee onboarding route group end */}

        {Employee_dashboard_route_group.map((route, idx) => {
          return <EmployeePrivateRoute key={idx} exact {...route} />;
        })}
        {/* employee dashboard routes group end */}

        {/* onboarding routes group start */}
        {Onboarding_route_group.map((route, idx) => {
          return <Route key={idx} exact {...route} />;
        })}
        {/* onboarding routes group end */}

        {/* dashboard route group start */}
        {Dashboard_route_group.map((route, idx) => {
          return <PrivateRoute key={idx} exact {...route} />;
        })}
        {/* dashboard route group end */}
        {/* Integration redirect route group start */}
        {Integration_redirect_page.map((route, idx) => {
          return <Route key={idx} exact {...route} />;
        })}
        {/* Integration redirect route group end */}
      </Switch>
    </Fragment>
  );
}

export default App;
