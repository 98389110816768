import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { createVertical } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import Select from "react-select";
import CommonInputModal from "./CommonInputModal";
import { getBranchesBySubsidiary } from "../../../../../../redux/approvals";
import { createPortfolio, getPortfolios } from "../../../../../../redux/budget-setting/revenue-projection/portfolio";

const AddBudgetRevPortfolioModal = ({ onCancel, onFinish, onEdit }) => {
  const { loading } = useSelector((state) => state.portfolio);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { verticalDrop } = useSelector((state) => state.vertical);
  const [details, setDetails] = useState({
    organization_id: "",
    name: "",
    description: "",
    subsidiary_id: "",
    vertical_id: "",
  });
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  

  // const actionCtx = useContext(ActionContext)
  useEffect(() => {
    // const obj = {
    //   org_id: actionCtx?.activeOrg?.id,
    //   per_page: actionCtx.perPage.value,
    // };
    if (actionCtx?.activeOrg?.type !== "group") {
      setDetails((prev) => {
        return { ...prev, subsidiary_id: subsidiariesDrop[0] };
      });
      dispatch(getBranchesBySubsidiary(subsidiariesDrop[0]?.id));
      //   handleGetBranchBySub(subsidiariesDrop[0])
    }
    // dispatch(getPortfolios(obj))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsidiariesDrop]);

  const handleSubmit = async () => {
    const obj = {
      organization_id: actionCtx?.activeOrg?.id,
      name: details?.name,
      description: details?.description,
      subsidiary_id: details?.subsidiary_id?.value,
      vertical_id: details?.vertical_id?.value,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(createPortfolio(obj));
    if (data?.payload?.success) {
      setDetails({
        organization_id: "",
        name: "",
        description: "",
        subsidiary_id: "",
        vertical_id: "",
      });
      onFinish();
    }
  };

  return (
    <>
      <CommonInputModal
        onCancel={() => {
          setDetails({
            organization_id: "",
            name: "",
            description: "",
            subsidiary_id: "",
            vertical_id: "",
          });
          onCancel();
        }}
        title={`New Portfolio`}
        btnText={`Save`}
        loading={loading}
        btnActive={
          details?.description &&
          details?.name &&
          details?.subsidiary_id &&
          details?.vertical_id
        }
        onBtnClick={handleSubmit}
        btnDisable={
          !details?.description ||
          !details?.name ||
          !details?.subsidiary_id ||
          !details?.vertical_id
        }
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={details.name}
            type="text"
            placeholder="Name of portfolio"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Subsidiary <span>*</span>
          </label>
          <Select
            placeholder="Select subsidiary"
            className={`select ${
              actionCtx?.activeOrg?.type !== "group" && "disable-select"
            }`}
            styles={reactSelectStyle}
            value={details.subsidiary_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, subsidiary_id: e };
              });
            }}
            options={subsidiariesDrop}
            isDisabled={actionCtx?.activeOrg?.type !== "group"}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Vertical <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="Select expense class"
            className="select "
            value={details.vertical_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, vertical_id: e };
              });
            }}
            options={verticalDrop}
          />
        </div>
        {/* group box end
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="5"
            onChange={handleChange}
            value={details.description}
            type="text"
            placeholder="describe your portfolio"
          ></textarea>
        </div>
        {/* group box end */}
      </CommonInputModal>
    </>
  );
};

export default AddBudgetRevPortfolioModal;
