import React, { useEffect, useState } from "react";
// import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/admin-settings/AdminSettings.css";
import { FaAngleRight, FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { emailInvites } from "../../../../redux/employee/activate-account/process-invite";
import { Dna } from "react-loader-spinner";
import ButtonBlue from "../../../buttons/ButtonBlue";
import Select from "react-select";
import { reactSelectStyle, validateEmail } from "../../../../helper/Helpers";
import { toast } from "react-toastify";
import { useContext } from "react";
import ActionContext from "../../../../context/ActionContext";
import { getRoles } from "../../../../redux/role/role";
import { useHistory } from "react-router-dom";

const AdminInvite = () => {
  const [details, setDetails] = useState({
    email: "",
    role_permission: "",
  });
  const [emailToSubmit, setEmailToSubmit] = useState([]);
  const { roleDrop } = useSelector((state) => state.role);
  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);

  const { loading } = useSelector((state) => state.employee_accept_invite);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      dispatch(getRoles(actionCtx.activeOrg?.id));
    }
    // eslint-disable-next-line
  }, []);
  const history = useHistory()
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let val;
    if (emailToSubmit?.length > 0 || details?.email) {
      // if(details?.email && )
      const obj = {
        email: emailToSubmit.map((chi) => chi.label),
        role_permission: details?.role_permission,
        organization_id: user?.organizations[0]?.id,
      };
      val = obj;
    } else {
      if (validateEmail(details?.email)) {
        const obj = {
          email: [details?.email],
          role_permission: details?.role_permission,
          organization_id: user?.organizations[0]?.id,
        };
        val = obj;
      } else {
        toast.error("Invalid email address!", {
          theme: "colored",
          position: "top-right",
          toastId: "error-id",
        });
      }
      return val;
    }
    // console.log(val);
    const obj = {
      email:
        emailToSubmit?.length > 0
          ? emailToSubmit.map((chi) => chi.label)
          : [details?.email],
      role_permission: details?.role_permission,
      organization_id: user?.organizations[0]?.id,
    };
    const data = await dispatch(emailInvites(obj));
    console.log(obj, "email-invite");
    if (data?.payload?.success) {
      setDetails({
        email: [],
        role_permission: "",
      });
      setEmailToSubmit([]);
      history.push("/dashboard-administration-admin-settings-employee")
    }
  };

  const checkInList = (obj) => {
    const val = emailToSubmit.find((chi) => chi?.label === obj?.label);
    // console.log(val);
    return val;
  };

  const handleAddtoList = (param) => {
    if (validateEmail(details?.email)) {
      const obj = {
        label: details?.email,
        value: details?.email,
      };

      if (checkInList(obj)) {
        toast.info("Email address already exist in list", {
          theme: "colored",
          position: "top-right",
          toastId: "error-id",
        });
        // console.log("no");
      } else {
        const newList = [...emailToSubmit, obj];
        setEmailToSubmit(newList);
        setDetails((prev) => {
          return { ...prev, email: "" };
        });
        //  console.log("yes");
      }
    } else {
      toast.error("Invalid email address!", {
        theme: "colored",
        position: "top-right",
        toastId: "error-id",
      });
    }
  };

  const handleRemovefromList = (param) => {
    setEmailToSubmit(param);
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitle={`Set/Invite Admin`}
        pageTitleSpan={`Step 1 of 6`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p className="text">Organization</p>
          <FaAngleRight className="icon" />
          <p className="active text">Users</p>
        </div>
        {/* text tititl box start */}
        <div className="title-text-box">
          <p
            onClick={() => {
              console.log(roleDrop);
            }}
            className="title"
          >
            Admin Invite
          </p>
          <p className="text">
            Let another member perform managerial tasks, such as setting up
            departments, adding users - by giving them administrative roles.
            When a member with an admin role signs into his/her account via
            invite, they’ll automatically have access to additional management
            controls.
          </p>
        </div>
        {/* text tititl box end */}
        {/* form box start */}
        <div className="form-box">
          <p className="form-title">Invite via </p>
          <form
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   history.push("/dashboard-home");
            // }}
            onSubmit={handleSubmit}
            action=""
            className="form"
          >
            <input
              type="text"
              className="input-email"
              placeholder="Enter the email addresses"
              name="email"
              onChange={handleChange}
              value={details?.email}
              style={{ marginBottom: emailToSubmit?.length > 0 && "2rem" }}
            />
            {emailToSubmit?.length > 0 && (
              <Select
                className={`select-wrap`}              
                isMulti
                placeholder=""
                value={emailToSubmit}
                onChange={(e) => handleRemovefromList(e)}
                styles={reactSelectStyle}
              />
            )}
            <ButtonBlue
              bgColor={details.email ? "var(--blue-color)" : "#cccccc"}
              text={`Add another email`}
              onClick={(e) => {
                e.preventDefault();
                details?.email && handleAddtoList();
              }}
            />

            {/* form check bos start */}
            <div className="form-check-box">
              {/* check group start */}
              {roleDrop?.map((chi) => {
                const { name, id, description } = chi;
                return (
                  <div className="check-group" key={id}>
                    <input
                      type="radio"
                      className="check-input"
                      id={`check-admin-${name}`}
                      name="role-invite-select"
                      checked={details?.role_permission === name ? true : false}
                      // value="admin-manager"
                      onChange={() => {
                        setDetails((prev) => {
                          return { ...prev, role_permission: name };
                        });
                      }}
                    />
                    <label
                      htmlFor={`check-admin-${name}`}
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">{name}</p>
                      <span>-</span>
                      <p className="text-two">{description}</p>
                    </label>
                  </div>
                );
              })}
              {/* check group end */}
            </div>
            {/* form check bos end */}
            {/* submit form start */}
            <div style={{ marginTop: "3rem" }}>
              {loading ? (
                <div>
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <input
                  disabled={
                    emailToSubmit?.length > 0
                      ? !details?.role_permission
                      : !details?.email || !details?.role_permission
                  }
                  type="submit"
                  value="Save & Continue"
                  className={`form-group btn-reuse form-submit ${
                    emailToSubmit?.length > 0 && details?.role_permission
                      ? "form-submit-active"
                      : ""
                  }  ${
                    emailToSubmit?.length < 1 &&
                    details?.email &&
                    details?.role_permission
                      ? "form-submit-active"
                      : ""
                  }`}
                />
              )}
            </div>

            {/* submit form  end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default AdminInvite;
