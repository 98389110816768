import React from "react";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import { deleteRevenueProjection } from "../../../../../../../redux/employee/revenue-projection/revenue-projection";
import "../../../../../../../styles/dashboard/modals/DeleteSharedmodal.css";

const EmpRevenueProjectionDeleteModal = ({ onClose, onFinish, data }) => {
  const dispatch = useDispatch();
  const { loadingTwo } = useSelector((state) => state.revenue_projection);

  const handleDelete = async () => {
    const id = data?.id;
    const dataTwo = await dispatch(deleteRevenueProjection(id));
    if (dataTwo?.payload?.success) {
      onFinish();
    }
  };
  // console.log("details--------for--check", data);

  return (
    <div className="delete-shared-modal-wrap">
      <p className="title" style={{ textTransform: "capitalize" }}>
        {`Delete Projection (${data?.product?.name ||  data?.product})` }
      </p>
      <p className="text">
        {` Are you sure you want to delete this projection? you'll permanently lose
        this information and may not be able to recover it. Do you still want to
        delete data?`}
      </p>
      {/* <p className="text text-two">
        organizatiuon budget settings. Do you still want to delete?
    </p> */}

      {/* <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        <ButtonBlue bgColor={`#CC0905`} text={`Yes Delete`} />
      </div> */}
      <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        {!loadingTwo ? (
          <ButtonBlue
            bgColor={`#CC0905`}
            text={`Yes Delete`}
            onClick={handleDelete}
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmpRevenueProjectionDeleteModal;
