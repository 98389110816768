import EmployeeRevenueProjectionIndex from "../../../../component/pages/dashboard/projection/revenue";
import EmployeeProjectionRevenueApproveTable from "../../../../component/pages/dashboard/projection/revenue/approve";
// import EmployeeRevenueDraftProjection from "../../../../component/pages/dashboard/projection/revenue/draft-revenue";
import EmployeeNewRevenueProjection from "../../../../component/pages/dashboard/projection/revenue/NewRevProjection";
import EmployeeRevenueProjectionPendingTable from "../../../../component/pages/dashboard/projection/revenue/pending";
import EmployeeRevenueProjectionRejectTable from "../../../../component/pages/dashboard/projection/revenue/reject";
import EmployeeRevenueSubmitProjection from "../../../../component/pages/dashboard/projection/revenue/SubmitProjection";
import EmployeeProjectionRevenueTableIndex from "../../../../component/pages/dashboard/projection/revenue/table";
import UpdateEmployeeRevenueProjection from "../../../../component/pages/dashboard/projection/revenue/updateRevProjection";

export const Employee_revenue_projection_route_group = [
  {
    path: "/employee-dashboard-projection-revenue",
    component: EmployeeRevenueProjectionIndex,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/employee-dashboard-projection-revenue-submitted",
    component: EmployeeRevenueSubmitProjection,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  // {
  //   path: "/employee-dashboard-projection-revenue",
  //   component: EmployeeRevenueDraftProjection,
  //   pageTitle: "Revenue Projection",
  //   goBack: true,
  // },
  {
    path: "/employee-dashboard-projection-revenue-new",
    component: EmployeeNewRevenueProjection,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/employee-dashboard-projection-revenue-update",
    component: UpdateEmployeeRevenueProjection,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/employee-dashboard-projection-revenue-submitted-total",
    component: EmployeeProjectionRevenueTableIndex,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/employee-dashboard-projection-revenue-submitted-approved",
    component: EmployeeProjectionRevenueApproveTable,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/employee-dashboard-projection-revenue-submitted-pending",
    component: EmployeeRevenueProjectionPendingTable,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/employee-dashboard-projection-revenue-submitted-rejected",
    component: EmployeeRevenueProjectionRejectTable,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
];
