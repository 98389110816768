import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc, customErrorId } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getActualSupplementaryBudgets = createAsyncThunk(
  "employee/get-actual-supplementary-budgets",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/supplementary/show-draft/${formData?.org_id}?perPage=${
          formData?.per_page || ""
        }
      `
      );
      // console.log("data",data)

      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setActualSupplementaryBudgets(data?.data?.supplementary)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
// export const getUserDepartment = createAsyncThunk(
//   "employee/get-user-department",
//   async (formData, thunkAPI) => {
//     try {
//       const { data } = await axios.get(
//         `/employee/get-user-department?organization_id=${formData}
//       `
//       );
//       // console.log(data);
//       if (!data.success) {
//         return thunkAPI.rejectWithValue(data);
//       }
//       if (data.success) {
//         const userDepartmentsInfo = data?.data?.department;
//         await thunkAPI.dispatch(setUserDepartmentDrop(userDepartmentsInfo));
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (err) {
//       toast.error(err?.response?.data?.message, {
//         theme: "colored",
// toastId: customErrorId,
//       });
//       if (err) {
//         return thunkAPI.rejectWithValue(err);
//       }
//     }
//   }
// );

export const getAllSupplementaryBudgetComment = createAsyncThunk(
  "employee/get-all-supplementary-budget-actual",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `/employee/comments/supplementary-expense/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        await thunkAPI.dispatch(
          setSupplementaryBudgetComments(
            data?.data?.supplementary_budget_commentss
          )
        );
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const commentSupplementarBudget = createAsyncThunk(
  "employee/comment-supplementary-budget",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `/employee/comments/supplementary-expense/add-comment?supplementary_budget_id=${
          formData?.supplementary_budget_id || ""
        }`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubmittedSupplementaryBudget = createAsyncThunk(
  "employee/get-submitted-supplementary-budget",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/supplementary/fetch-submitted/${
          formData?.org_id || ""
        }?filter[department_id]=${formData?.dept_id || ""}&filter[status]=${
          formData?.status || ""
        }&currency_conversion_id=${formData?.currency_id || ""}&perPage=${
          formData?.per_page || ""
        }&chart_of_account=${
          formData?.chart_of_account_id || ""
        }&fiscal_year_id=${formData?.fiscal_year_id || ""}
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list-actual", data.data.actualrevenue.data)
        await thunkAPI.dispatch(
          setSubmittedSupplementaryBudget(data?.data?.subsidiary)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getSubmittedSupplementaryBudgetBySearch = createAsyncThunk(
  "employee/get-submitted-supplementary-budget",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/supplementary/fetch-submitted/${
          formData?.org_id || ""
        }filter=${formData?.filter_by || ""}&sort=${
          formData?.sort_by || ""
        }&search=${formData?.search || ""}&filter[department_id]=${
          formData?.dept_id || ""
        }&filter[status]=${formData?.status || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}&fiscal_year_id=${
          formData?.fiscal_year_id || ""
        }       
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list-actual", data.data.actualrevenue.data)
        await thunkAPI.dispatch(
          setSubmittedSupplementaryBudget(data?.data?.subsidiary)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovedSupplementaryBudget = createAsyncThunk(
  "employee/get-approved-supplementary-budget",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/supplementary/fetch-submitted/${
          formData?.org_id || ""
        }?filter[is_submitted]=${
          formData?.filter_submitted || ""
        }&filter[status]=${formData?.status || ""}&filter[department_id]=${
          formData?.dept_id || ""
        }
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list", data?.data?.subsidiary)
        await thunkAPI.dispatch(
          setSubmittedSupplementaryBudget(data?.data?.subsidiary)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubmittedSupplementaryBudgetPagination = createAsyncThunk(
  "employee/get-submitted-supplementary-budget-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/supplementary/fetch-submitted/${
          formData?.org_id || ""
        }?filter[department_id]=${formData?.dept_id || ""}&filter[status]=${
          formData?.status || ""
        }&page=${formData?.page || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.perPage || ""}&chart_of_account=${
          formData?.chart_of_account_id || ""
        }&fiscal_year_id=${formData?.fiscal_year_id || ""}`
      );
      //   console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setSharedServices(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setSubmittedSupplementaryBudget(data?.data?.subsidiary)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjectionPagination = createAsyncThunk(
  "employee/get-expenditure-projection-by-id-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        // await thunkAPI.dispatch(setSharedServices(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("owfa", data?.data?.supplementary);
        const actualRevenueInfo = data?.data?.draft?.data;
        await thunkAPI.dispatch(
          setSubmittedSupplementaryBudget(actualRevenueInfo)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getActualSupplementaryBudgetPagination = createAsyncThunk(
  "employee/get-actual-supplementary-budget-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/supplementary/show-draft/${formData?.org_id}?perPage=${
          formData?.per_page || ""
        }&page=${formData?.page || ""}&filter=${
          formData?.filter_by || ""
        }&sort=${formData?.sort_by || ""}&search=${formData?.search || ""}`
      );
      if (!data?.success) {
        // await thunkAPI.dispatch(setSharedServices(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("owfa", data?.data?.supplementary);
        const actualSupplementaryInfo = data?.data?.supplementary;
        await thunkAPI.dispatch(
          setActualSupplementaryBudgets(actualSupplementaryInfo)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getActualSupplementaryBudget = createAsyncThunk(
  "employee/get-actual-supplementary-budget",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(
        `/employee/supplementary/fetch-supplementary/${formData}`
      );
      // console.log("get-actual-revenue", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getApprovedPrice = createAsyncThunk(
  "employee/get-approved-price",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(
        `/employee/supplementary/get_approved_price?department_id=${
          formData?.dept_id || ""
        }&chart_of_account_id=${formData?.chat_id || ""}&fiscal_year_id=${
          formData?.fiscal_year || ""
        }`
      );
      // console.log("get-actual-revenue", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createActualSupplementaryBudget = createAsyncThunk(
  "employee/create-actual-supplementary-buget",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/supplementary/actual_supplementary",
        formData
      );
      // console.log('set-up-org', data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSupplementaryBudgetBySearch = createAsyncThunk(
  "employee/get-supplementary-budget-search",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/employee/supplementary/show-draft/${formData?.org_id}?filter=${
          formData?.q || ""
        }&sort=${formData?.filter_by || ""}&search=${
          formData?.sort_by || ""
        }&perPage=${formData?.per_page || ""}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("revenue-info", data[0]);
        const revenueInfo = data?.data?.supplementary;
        // console.log("revenueInfo", revenueInfo);
        await thunkAPI.dispatch(setSupplementaryBudgetSearch(revenueInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureBulkUpload = createAsyncThunk(
  "employee/expenditure-bulk-upload",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "employee/expenditure_projection/bulk-update",
        formData
      );
      // console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const submitSupplementaryDraftForApproval = createAsyncThunk(
  "employee/submit-supplementary-budget-draft-for-approval",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "employee/supplementary/submit-supplementary",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const submitSingleSupplementaryDraftForApproval = createAsyncThunk(
  "employee/submit-single-supplementary-budget-draft-for-approval",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "employee/supplementary/submit-supplementary",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteActualSupplementaryBudget = createAsyncThunk(
  "employee/delete-actual-supplementary-budget",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `/employee/supplementary/delete-supplementary/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateactualSupplementaryBudgets = createAsyncThunk(
  "employee/update-actual-supplementary-budgets",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.patch(
        `employee/supplementary/update-supplementary/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const actual_supplementary_budget = createSlice({
  name: "actual_supplementary_budget",
  initialState: {
    expenditureProjectionDrop: [],
    actualSupplementaryBudgets: [],
    actualSupplementaryBudgetComments: [],
    submittedactualSupplementaryBudgets: [],
    links: {},
    meta: {},
    expenditureProjection: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    loadingPage: false,
    userDepartment: [],
    userDepartmentDrop: [],
  },
  reducers: {
    setActualSupplementaryBudgets: (state, action) => {
      state.isAuth = true;
      state.actualSupplementaryBudgets = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setSupplementaryBudgetComments: (state, action) => {
      state.isAuth = true;
      state.actualSupplementaryBudgetComments = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    // setUserDepartment: (state, action) => {
    //   state.isAuth = true;
    //   state.userDepartment = action.payload;
    //   state.links = action.payload.links;
    //   state.meta = action.payload.meta;
    // },
    setUserDepartmentDrop: (state, action) => {
      state.isAuth = true;
      state.userDepartmentDrop = addLabelValueFunc(action.payload);
    },
    setSubmittedSupplementaryBudget: (state, action) => {
      state.isAuth = true;
      state.submittedactualSupplementaryBudgets = action.payload;
      state.links = action.payload;
      state.meta = action.payload.meta;
    },
    setSupplementaryBudgetSearch: (state, action) => {
      state.isAuth = true;
      state.actualSupplementaryBudgets = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setExpenditureProjectionFilter: (state, action) => {
      state.isAuth = true;
      state.actualSupplementaryBudgets = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setExpenditureProjectionDrop: (state, action) => {
      state.isAuth = true;
      state.sharedServiceDrop = addLabelValueFunc(action.payload);
    },
    setExpenditureProjecton: (state, action) => {
      state.isAuth = true;
      state.role = action.payload;
    },
  },
  extraReducers: {
    [createActualSupplementaryBudget.pending]: (state) => {
      state.loading = true;
    },
    [createActualSupplementaryBudget.fulfilled]: (state) => {
      state.loading = false;
    },
    [createActualSupplementaryBudget.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateactualSupplementaryBudgets.pending]: (state) => {
      state.loading = true;
    },
    [updateactualSupplementaryBudgets.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateactualSupplementaryBudgets.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [submitSupplementaryDraftForApproval.pending]: (state) => {
      state.loading = true;
      state.loadingTwo = true;
    },
    [submitSupplementaryDraftForApproval.fulfilled]: (state) => {
      state.loading = false;
      state.loadingTwo = false;
    },
    [submitSupplementaryDraftForApproval.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [commentSupplementarBudget.pending]: (state) => {
      state.loading = true;
    },
    [commentSupplementarBudget.fulfilled]: (state) => {
      state.loading = false;
    },
    [commentSupplementarBudget.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [submitSingleSupplementaryDraftForApproval.pending]: (state) => {
      state.loadingTwo = true;
      state.loadingPage = true;
    },
    [submitSingleSupplementaryDraftForApproval.fulfilled]: (state) => {
      state.loadingTwo = false;
      state.loadingPage = false;
    },
    [submitSingleSupplementaryDraftForApproval.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteActualSupplementaryBudget.pending]: (state) => {
      state.loadingTwo = true;
    },
    [deleteActualSupplementaryBudget.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [deleteActualSupplementaryBudget.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjectionPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjectionPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getActualSupplementaryBudgetPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getActualSupplementaryBudgetPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getActualSupplementaryBudgetPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getSupplementaryBudgetBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSupplementaryBudgetBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSupplementaryBudgetBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getSubmittedSupplementaryBudget.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSubmittedSupplementaryBudget.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSubmittedSupplementaryBudget.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getSubmittedSupplementaryBudgetBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSubmittedSupplementaryBudgetBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSubmittedSupplementaryBudgetBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovedSupplementaryBudget.pending]: (state) => {
      state.loadingPage = true;
    },
    [getApprovedSupplementaryBudget.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getApprovedSupplementaryBudget.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getSubmittedSupplementaryBudgetPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSubmittedSupplementaryBudgetPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSubmittedSupplementaryBudgetPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getActualSupplementaryBudgets.pending]: (state) => {
      state.loading = true;
      state.loadingPage = true;
    },
    [getActualSupplementaryBudgets.fulfilled]: (state) => {
      state.loading = false;
      state.loadingPage = false;
    },
    [getActualSupplementaryBudgets.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    // [getUserDepartment.pending]: (state) => {
    //   state.loading = true;
    // },
    // [getUserDepartment.fulfilled]: (state) => {
    //   state.loading = false;
    // },
    // [getUserDepartment.rejected]: (state) => {
    //   // localStorage.removeItem('token');
    //   state.loading = false;
    //   state.isAuth = false;
    //   state = null;
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  setExpenditureProjecton,
  setActualSupplementaryBudgets,
  setExpenditureProjectionDrop,
  setExpenditureProjectionFilter,
  setSubmittedSupplementaryBudget,
  setUserDepartment,
  setUserDepartmentDrop,
  setSupplementaryBudgetSearch,
  setSupplementaryBudgetComments,
} = actual_supplementary_budget.actions;

export default actual_supplementary_budget.reducer;
