import React from "react";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
import "../../../../../../employee-folder/styles/dashboard/settings/support/index.scss";
import { FaSearch } from "react-icons/fa";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import LogoIcon from "../../../../../../assets/logo-icon.svg";
import getHelpIcon from "../../../../../../assets/get-help-icon.svg";

const EmployeeSupport = () => {
  return (
    <>
      {/* <EmployeeDashboardLayout pageTitle={`Supports`} goBack> */}
        <div className="employee-support-index-wrap">
          <p className="support-header">Help Center</p>

          {/* first section start */}
          <div className="support-section-one">
            <p className="header-one">How can revvex help?</p>
            {/* search box start */}
            <input
              type="text"
              placeholder="Search for articles"
              id="search"
              className="input"
            />
            <label htmlFor="search  " className="label">
              <FaSearch className="icon" />
            </label>
            {/* search box end */}
          </div>
          {/*first section end */}
          {/* article  wrap start */}
          <div className="employee-support-article-wrap">
            {/* article start */}
            <div className="employee-support-article">
              <div>
                <p className="article-title">Popular Articles</p>
                <p>
                  Get more information insight with revvex business guidelines
                  and resources to help manage and fast track your business
                </p>
                <ButtonBlue text={`Learn More`} />
              </div>
            </div>
            {/* article end */}
            {/* article start */}
            <div className="employee-support-article">
              <div>
                <p className="article-title">Frequently Asked Question</p>
                <p>
                  Get more information insight with revvex business guidelines
                  and resources to help manage and fast track your business
                </p>
                <ButtonBlue text={`Read Now`} />
              </div>
            </div>
            {/* article end */}
          </div>
          {/* article wrap end */}
          {/* footer wrap start */}
          <div className="employee-support-footer-wrap">
            {/* first column start */}
            <div className="support-details">
              <p className="detail-title">Feature</p>
              <p>Budget</p>
              <p>Revenue</p>
              <p>Expenditure</p>
            </div>
            {/* first column end */}
            {/* second column start */}
            <div className="support-details">
              <p className="detail-title">Resources</p>
              <p>Blog</p>
            </div>
            {/* second column end */}
            {/* third column start */}
            <div className="support-details">
              <p className="detail-title">We are social</p>
              <p>Twitter</p>
              <p>Instagram</p>
              <p>Facebook</p>
              <p>Youtube</p>
            </div>
            {/* third column end */}
            {/* fourth column start */}
            <div className="support-details">
              <p className="detail-title">Support</p>
              <p>Help & Support</p>
              <p>Contact Us</p>
            </div>
            {/* fourth column end */}
            {/* fifth column start */}
            <div className="support-details">
              <p className="detail-title">Company</p>
              <p>Privacy Policy</p>
              <p>Terms of Service</p>
            </div>
            {/* fifth column end */}
          </div>
          {/* sixth column start */}
          <div className="support-copyright-wrap">
            <figure>
              <img src={LogoIcon} alt="" />
            </figure>
            <p>© 2022 Revvex. All rights reserved</p>
            {/* sixth column end */}
            {/* get help box start */}
            <div className="emp-support-get-help-box">
              <p className="  ">Get Help</p>
              <figure className="img-box">
                <img src={getHelpIcon} alt="" className="img" />
              </figure>
            </div>
            {/* get help box end */}
          </div>
        </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default EmployeeSupport;
