import React, { useState } from "react";
import EmployeeSupplementaryRemoveGroup from "./removeableGroup";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { NumericFormat } from "react-number-format";

const EmployeeSupplementaryMonthForm = ({
  unit_price,
  onInputChange,
  entry_type_value,
  entryType,
  loading,
  handleEntryTypeChange,
  onSelectvalueChange,
  Quantity,
  onInputValueChange,
  handleAddMonthList,
  onInputTotalChange,
  total_supplementary,
  Total_quantity_label,
  handleRemoveMonthList,
  total_quantity,
  list,
  quantity,
  // name
}) => {
  const [details] = useState({
    unit_price: "",
    entry_type: "",
    total_quantity: "",
    total_supplementary: "",
  });

  return (
    <>
      <div
        className="form-wrap"
        style={{ marginTop: "3rem", marginBottom: "-10rem" }}
      >
        {/* form-group start */}
        <div className="form-group">
          {/* unit price group start */}
          <label htmlFor="unit price" className="label">
            Unit Price <span>* </span>
          </label>
          <NumericFormat
            // type="text"
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            type="text"
            name="unit_price"
            id="unit_price"
            placeholder="Unit value"
            className={`input ${unit_price && "input-active"}`}
            value={details?.unit_price || unit_price}
            onChange={onInputChange}
          />
        </div>
        {/* unit price group end */}
        {/* entry_type start */}
        <div className="form-group">
          <label htmlFor="quantity" className="label">
            Entry Type <span>*</span>
          </label>
          <Select
            // type="number"
            name="entry_type"
            placeholder={"Select Entry Type"}
            className={`select-wrap ${
              entry_type_value && "select-wrap-active"
            }`}
            value={entry_type_value}
            options={entryType || entryType}
            styles={reactSelectStyle}
            onChange={handleEntryTypeChange}
            isLoading={loading}
          />
        </div>
        {/* entry_type end */}

        {/* form-group end */}
      </div>
      {/* <div className="form-box form-group"> */}
      <div className="wrapper emp-itemize">
        <p className="box-title">Itemize monthly</p>
        <div className="step-assign-box">
          <div
            className="step-assign-line"
            style={{
              display: "grid",
              // gap: "40rem",
              marginBottom: "-2rem",
              gridTemplateColumns: "1fr 1fr",
              marginTop: "2rem",
              // justifyContent: "space-between",
            }}
          >
            <p className="text">Months</p>
            <p className="text">Total value</p>

            {/* <p className="quantity" style={{ marginLeft: "10rem" }}>
              Quantity
            </p> */}
          </div>
        </div>
      </div>
      <div>
        <EmployeeSupplementaryRemoveGroup
          list={list}
          loading={loading}
          onSelectvalueChange={onSelectvalueChange}
          Quantity={Quantity}
          onInputValueChange={onInputValueChange}
          handleAddMonthList={handleAddMonthList}
          onInputTotalChange={onInputTotalChange}
          total_supplementary={total_supplementary}
          Total_quantity_label={Total_quantity_label}
          handleRemoveMonthList={handleRemoveMonthList}
          total_quantity={total_quantity}
          quantity={quantity}
          // name={name}
        />
      </div>
      {/* </div> */}
    </>
  );
};
export default EmployeeSupplementaryMonthForm;
