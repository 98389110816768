import Modal from "react-awesome-modal";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { Dna } from "react-loader-spinner";

const FilterModal = ({
  children,
  visible,
  onCancel,
  onSubmit,
  subLoading,
  bgColor,
  downloadBorderColor,
  downloadColor,
  onDownload,
  downloading,
}) => {
  return (
    <Modal visible={visible} effect="fadeInRight">
      <div className="emp-filter-wrap">
        <p className="title">FILTER BY</p>
        <div className="child-wrap">{children}</div>
        <div className="btn-wrap">
          {downloading ? (
            <div className="btn-box">
              <Dna
                visible={true}
                height="40"
                width="100"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <ButtonBlue
              style={{
                border: downloadBorderColor,
                color: downloadColor,
                marginTop: "2rem",
              }}
              text={"Download File"}
              bgColor="#ffff"
              onClick={onDownload}
            />
          )}

          {subLoading ? (
            <div className="btn-sub-box">
              <Dna
                visible={true}
                height="40"
                width="100"
                ariaLabel="dna-loading"
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <ButtonBlue
              style={{
                width: "10rem",
                marginTop: "2rem",
              }}
              text={"Filter"}
              bgColor={bgColor}
              onClick={onSubmit}
            />
          )}
          <ButtonBlue
            style={{
              border: ".1rem solid var(--blue-color)",
              color: "var(--blue-color)",
              width: "10rem",
              marginTop: "2rem",
            }}
            text={"Cancel"}
            bgColor={"var(--white-color)"}
            onClick={onCancel}
          />
        </div>
      </div>
    </Modal>
  );
};
export default FilterModal;
