import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useThemeDetector } from "../../../../../utils/getThemeColor";
import { useSelector } from "react-redux";
import { numberFormatChart } from "../../../../../helper/Helpers";
import NoBarContent from "../../../../fragments/NoBarContent";
// import faker from 'faker';
// ChartJS.pointWidth(10);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



// import React from 'react'

const RevenueChart = ({ dataOne, dataTwo, dataToShow, loading }) => {
  const isDarkTheme = useThemeDetector();
  const { preference } = useSelector((state) => state.preference);


  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        ticks: {
          color:"#1d1d18",
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: (val) => `${numberFormatChart(val)}`,
          color:"#1d1d18",
        },
      },
    },
  };

  if (loading) {
    return (
    <NoBarContent />
    );
  }

  return (
    <>
      <Bar
        // className="chart"
        redraw={false}
        width={50}
        height={100}
        options={options}
        data={dataToShow}
      />
    </>
  );
};

export default RevenueChart;
