import SupplementaryBudgetActualIndex from "../../../component/pages/dashboard/actuals/supplementary-budget";
import SupplementaryActuallMain from "../../../component/pages/dashboard/actuals/supplementary-budget/SupplementaryActuallMain";
import SupplementaryBudgetApproved from "../../../component/pages/dashboard/actuals/supplementary-budget/SupplementaryBudgetApproved";
import SupplementaryBudgetAwaitApproval from "../../../component/pages/dashboard/actuals/supplementary-budget/SupplementaryBudgetAwaitApproval";
import SupplementaryBudgetRejected from "../../../component/pages/dashboard/actuals/supplementary-budget/SupplementaryBudgetRejected";





export const Supplementary_Buget_Actual_route_group = [
    {path: "/dashboard-actual-supplementary-budget" , component: SupplementaryBudgetActualIndex, pageTitle: "Supplementary Budget", goBack: true},
    {path: "/dashboard-actual-supplementary-budget-approved" , component: SupplementaryBudgetApproved, pageTitle: "Supplementary Budget", goBack: true},
    {path: "/dashboard-actual-supplementary-budget-awaiting-approval" , component: SupplementaryBudgetAwaitApproval, pageTitle: "Supplementary Budget", goBack: true},
    {path: "/dashboard-actual-supplementary-budget-rejected" , component: SupplementaryBudgetRejected, pageTitle: "Supplementary Budget", goBack: true},
    {path: "/dashboard-actual-supplementary-budget-main" , component: SupplementaryActuallMain, pageTitle: "Supplementary Budget", goBack: true},
]