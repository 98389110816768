import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeRevenueComentModal from "../modals/comment";
import EmployeeSupplementaryLayout from "./links/CommonLayout";
import Modal from "react-awesome-modal";
import EmployeeRevenueApprovalModal from "../modals/approve";
import EmployeeRejectModal from "../modals/reject";
import EmployeeActualSupplementaryBudgetPendingOverview from "./modals/pendingOverview";
import EmployeeActualSupplementaryBudgetApproveOverview from "./modals/approveOverviewModal";
import EmployeeActualSupplementaryBudgetRejectOverview from "./modals/rejectOverview";

const EmployeeSupplementaryBudgetSubmitTable = ({ children }) => {
  const history = useHistory();

  const [indexNo, setIndexNo] = useState("");

  const [showModal, setShowModal] = useState({
    approve: false,
    comment: false,
    reject: false,
    overview: false,
    approveOverview: false,
    rejectOverview: false,
  });

  const cardHeader = ["Total Supplementary", "Approved", "Pending", "Rejected"];
  const cardValue = ["00.00", "0", "0", "0"];

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Proposed Price",
    "Approved Price",
    "Total Value",
    "status",
  ];

  const employeeTableBodyList = [
    {
      chart_of_account: "Office Equipment",
      classes: "Class",
      proposed_price: "250,000.00",
      approved_price: "200,000.00",
      total_value: "20,000.00",
      status: "accept",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Class",
      proposed_price: "250,000.00",
      approved_price: "200,000.00",
      total_value: "20,000.00",
      status: "reject",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Class",
      proposed_price: "250,000.00",
      approved_price: "200,000.00",
      total_value: "20,000.00",
      status: "pending",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Class",
      proposed_price: "250,000.00",
      approved_price: "200,000.00",
      total_value: "20,000.00",
      status: "accept",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Class",
      proposed_price: "250,000.00",
      approved_price: "200,000.00",
      total_value: "20,000.00",
      status: "pending",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Class",
      proposed_price: "250,000.00",
      approved_price: "200,000.00",
      total_value: "20,000.00",
      status: "reject",
    },
  ];

  const links = [
    "/employee-dashboard-actual-supplementary-budget-form",
    "/employee-dashboard-actual-supplementary-budget-submit",
    "/employee-dashboard-actual-supplementary-budget-approved",
    "/employee-dashboard-actual-supplementary-budget-pending",
    "/employee-dashboard-actual-supplementary-budget-rejected",
  ];

  return (
    <>
      <div>
        <EmployeeSupplementaryLayout draftLink={true}>
          <EmployeeReusableTable
            dontShowPagination={false}
            cardTitleOne={cardHeader[0]}
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            cardBodyOne={cardValue[0]}
            cardBodyTwo={cardValue[1]}
            cardBodyThree={cardValue[2]}
            cardBodyFour={cardValue[3]}
            showSearch={true}
            sort
            filter
            BtnTitle={`New Budget`}
            linkCardOne={links[1]}
            addButton={true}
            firstCard={true}
            secondCard={true}
            thirdCard={true}
            fourthCard={true}
            onPage={() => history.push(links[0])}
            onFirstCard={() => history.push(links[1])}
            onSecondCard={() => history.push(links[2])}
            onThirdCard={() => history.push(links[3])}
            onFourthCard={() => history.push(links[4])}
          />

          {children}
          <div className="table-box">
            {/* table box start */}
            <ActualReuseableTable
              dontShowPagination={false}
              dontShowBnSearch
              one={tableHeadList[0]}
              two={tableHeadList[1]}
              three={tableHeadList[2]}
              four={tableHeadList[3]}
              five={tableHeadList[4]}
              six={tableHeadList[5]}
              seven={tableHeadList[6]}
              action
              onNextPage
            >
              {employeeTableBodyList.map((chi, idx) => {
                const {
                  chart_of_account,
                  classes,
                  proposed_price,
                  approved_price,
                  total_value,
                  status,
                } = chi;
                return (
                  <EmpTableBodyRow
                    key={idx}
                    one={chart_of_account}
                    two={classes}
                    three={proposed_price}
                    four={approved_price}
                    five={total_value}
                    progressType={status}
                    onProgress={() => history.push("/")}
                    action
                    rowNo={idx}
                    indexNo={indexNo}
                    setIndexNo={() => setIndexNo(idx)}
                    updateText={`modify `}
                    approveText={"Approve"}
                    viewText={"View Comments"}
                    deleteText={`Reject`}
                    exportText={`Export as PDF`}
                    onUpdate={() => history.push(links[0])}
                    onApprove={() =>
                      setShowModal((prev) => {
                        return { ...prev, approve: true };
                      })
                    }
                    onView={() =>
                      setShowModal((prev) => {
                        return { ...prev, comment: true };
                      })
                    }
                    onDelete={() =>
                      setShowModal((prev) => {
                        return { ...prev, reject: true };
                      })
                    }
                    onAcceptStatus={() =>
                      setShowModal((prev) => {
                        return { ...prev, approveOverview: true };
                      })
                    }
                    onOverview={() =>
                      setShowModal((prev) => {
                        return { ...prev, overview: true };
                      })
                    }
                    onRejectStatus={() =>
                      setShowModal((prev) => {
                        return { ...prev, rejectOverview: true };
                      })
                    }
                  />
                );
              })}
            </ActualReuseableTable>
          </div>
        </EmployeeSupplementaryLayout>
      </div>
      {/* modal start */}
      <Modal visible={showModal.comment}>
        <EmployeeRevenueComentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, comment: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.approve}>
        <EmployeeRevenueApprovalModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approve: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmployeeRejectModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.overview}>
        <EmployeeActualSupplementaryBudgetPendingOverview
          nextPage
          rejectText
          onNextPage={() => history.push(links[0])}
          onReject={() =>
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            })
          }
          onApprove={() =>
            setShowModal((prev) => {
              return { ...prev, approve: true, overview: false };
            })
          }
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, overview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeActualSupplementaryBudgetApproveOverview
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            })
          }
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.rejectOverview}>
        <EmployeeActualSupplementaryBudgetRejectOverview
          nextPage
          onNextPage={() => history.push(links[0])}
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            })
          }
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            })
          }
          onApprove={() =>
            setShowModal((prev) => {
              return { ...prev, approve: true, rejectOverview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeSupplementaryBudgetSubmitTable;
