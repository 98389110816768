import React, { useContext, useEffect, useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { updateCategory } from "../../../../../../redux/budget-setting/expenditure-projection/categories";
import { reactSelectStyle } from "../../../../../../helper/Helpers";

const UpdateBudgetExpCategoryModal = ({
  onCancel,
  onEdit,
  onFinish,
  detail,
}) => {
  const { classDrop } = useSelector((state) => state.classes);
  const { loading } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    organization_id: "",
    projection_class_id: "",
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    onEdit(e);
  };

  useEffect(() => {
      setDetails((prev) => {
        return {...prev, projection_class_id: ""}
      })
      onEdit("", true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.activeOrg])

  const handleSubmit = async () => {
    const obj = {
      id: detail?.id,
      name: details?.name || detail?.name,
      description: details?.description || detail?.description,
      projection_class_id:
        details?.projection_class_id?.value || detail?.projection_class?.value,
      organization_id: actionCtx?.activeOrg?.id,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(updateCategory(obj));
    if (data?.payload?.success) {
      onFinish();
      setDetails({
        name: "",
        description: "",
      });
    }
  };

  return (
    <>
      <CommonInputModal
        loading={loading}
        btnActive={
          details?.description || details?.name || details?.projection_class_id
            ? true
            : false
        }
        onBtnClick={handleSubmit}
        btnDisable={
          !details?.description &&
          !details?.name &&
          !details?.projection_class_id
        }
        onCancel={onCancel}
        title={`Modify Category`}
        btnText={`Update`}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={detail?.name}
            type="text"
            placeholder="Name expense category"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Class <span>*</span>
          </label>
          <Select
          styles={reactSelectStyle}
            placeholder="Select expense class"
            className="select "
            value={detail?.projection_class}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, projection_class_id: e.value };
              });
              onEdit(e, true)
            }}
            options={classDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description <span>*</span>
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
            value={detail?.description}
            type="text"
            placeholder="describe your expenditure class"
          ></textarea>
        </div>
        {/* group box end */}
      </CommonInputModal>
    </>
  );
};

export default UpdateBudgetExpCategoryModal;
