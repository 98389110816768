import React, { useContext, useEffect, useState } from "react";
// import userimg from "../../../../../../assets/hod-one-img.svg";
import Select from "react-select";
import { FaMinus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../../../../redux/users/users";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import ActionContext from "../../../../../../context/ActionContext";
import { reactSelectStyleTable } from "../../../../../../helper/Helpers";

const timeList = [
  { label: "24 hours", value: "24 hours" },
  { label: "18 hours", value: "18 hours" },
  { label: "12 hours", value: "12 hours" },
  { label: "6 hours", value: "6 hours" },
];

const typeList = [
  { label: "Single User", value: "single" },
  { label: "Any User", value: "any" },
];

const RemovableGroup = ({
  num,
  list,
  handleRemoveChild,
  onSelectUser,
  onSelectUserType,
  onSelectUserTime,
  onSelectUserTwo,
  userTwoSelectVal,
  userTypeSelectVal,
  userSelectVal,
  timeeSelectVal,
  reUseableZindex,
  notifyChecked,
  onNotify,
  noBorder,
}) => {
  const [userSelect, setuserSelect] = useState(null);
  const [userTwoSelect, setuserTwoSelect] = useState(null);
  const [userTypeSelect, setuserTypeSelect] = useState(null);
  const [timeeSelect, settimeeSelect] = useState(null);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { usersDrop } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.user);
  // const { subsidiariesDrop } = useSelector((state) => state.subsidiary);

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      dispatch(getUsers(actionCtx?.activeOrg?.id));
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      // dispatch(getDepartments(actionCtx?.activeOrg?.id));
      // dispatch(getOrganizations());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`remove-group ${noBorder && "remove-group-no-border"}`}
      style={{
        position: "relative",
        zIndex: reUseableZindex,
      }}
    >
      <div className="top-wrap">
        <p className="step">{num + 1 || "1"}</p>
        {/* select box */}
        <div className="box">
          <label style={{ marginBottom: ".5rem" }} htmlFor="">
            {userTypeSelect?.label === "Any User" && "1st"} User
          </label>
          <Select
            className="select "
            value={userSelect || userSelectVal}
            onChange={(e) => {
              setuserSelect(e);
              onSelectUser(e);
              // console.log(e);
            }}
            options={usersDrop}
            styles={reactSelectStyleTable}
          />
        </div>
        {/* select end */}
        {/* notify user box start */}
        <div className="notify-user-box">
          <input
            type="checkbox"
            name=""
            id={`notify-check-${reUseableZindex}`}
            className="notify-check"
            checked={notifyChecked}
            onChange={() => {onNotify(notifyChecked ? 0 : 1)}}
          />
          <label
            htmlFor={`notify-check-${reUseableZindex}`}
            className="notify-label"
          >
            <div className="circle"></div>
            <p className="text-label">Notify User</p>
          </label>
        </div>
        {/* notify user box end */}
      </div>
      {/* top wrap end */}
      <div
        className="bottom-wrap"
        style={{
          width: userTypeSelect?.label === "Any User" ? "80%" : "35%",
          gridTemplateColumns:
            userTypeSelect?.label === "Any User"
              ? "  1.5fr 1fr  1fr 5rem"
              : " 1.5fr 5rem",
        }}
      >
        {/* select type box start */}
        <div className="box">
          <label style={{ marginBottom: ".5rem" }} htmlFor="">
            Required
          </label>
          <Select
            className="select select-two"
            value={userTypeSelect || userTypeSelectVal}
            onChange={(e) => {
              setuserTypeSelect(e);
              onSelectUserType(e);
              //   console.log(e);
            }}
            styles={reactSelectStyleTable}
            options={typeList}
          />
        </div>
        {/* select type box end */}
        {/* select type box start */}
        {userTypeSelect?.label === "Any User" && (
          <div className="box">
            <label style={{ marginBottom: ".5rem" }} htmlFor="">
              Priority Timeframe
            </label>
            <Select
              className="select select-two"
              value={timeeSelect || timeeSelectVal}
              onChange={(e) => {
                settimeeSelect(e);
                onSelectUserTime(e);
                //   console.log(e);
              }}
              options={timeList}
              styles={reactSelectStyleTable}
            />
          </div>
        )}
        {/* select type box end */}
        {/* select box */}
        {userTypeSelect?.label === "Any User" && (
          <div className="box">
            <label style={{ marginBottom: ".5rem" }} htmlFor="">
              2nd User
            </label>
            <Select
              className="select "
              value={userTwoSelect || userTwoSelectVal}
              onChange={(e) => {
                setuserTwoSelect(e);
                onSelectUserTwo(e);
                //   console.log(e);
              }}
              options={usersDrop}
              styles={reactSelectStyleTable}
            />
          </div>
        )}
        {/* select end */}
        {/* cancel bos start */}
        <div className="cancel-box" onClick={handleRemoveChild}>
          <FaMinus className="icon" />
        </div>
        {/* cancel bos end */}
      </div>
    </div>
  );
};

export default RemovableGroup;
