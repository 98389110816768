import React, { useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/admin-settings/ProfileSetting.css";
import { useHistory } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import cameraImg from "../../../../../assets/camera-icon.svg";
import userImg from "../../../../../assets/Unknown_person.jpeg";
import Select from "react-select";
import { PatternFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import {
  getCountries,
  getUserDetails,
  updateUser,
} from "../../../../../redux/user";
import { Dna } from "react-loader-spinner";
import { formatNumberWithoutComma, reactSelectStyle } from "../../../../../helper/Helpers";

const ProfileSetting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userDisplay, setUserDisplay] = useState({});
  const { user, loading, countries } = useSelector((state) => state.user);
  // const { refreshDetail, setRefreshDetail } = useState(false);
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    gender: "",
    country: "",
    profile_picture: "",
    role: "",
  });

  const genderList = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Any", value: "any" },
  ];

  useEffect(() => {
    dispatch(getCountries());
    const obj = {
      ...user,
      country: { label: user?.country, value: user?.country },
      gender: { label: user?.gender, value: user?.gender },
    };
    // console.log(obj);
    setUserDisplay(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    const dataTwo = { ...userDisplay, [name]: value };
    setUserDisplay(dataTwo);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      first_name: details?.first_name || userDisplay?.first_name,
      last_name: details?.last_name || userDisplay?.last_name,
      phone_number: details?.phone_number || userDisplay?.phone_number,
      gender: details?.gender?.label || userDisplay?.gender.label,
      country: details?.country?.label || userDisplay?.country.value,
      profile_picture: details?.profile_picture ? details?.profile_picture : "",
    };
    const formData = new FormData();
    formData.append("country", obj?.country);
    formData.append("first_name", obj?.first_name);
    formData.append("last_name", obj?.last_name);
    formData.append("phone_number", formatNumberWithoutComma(obj?.phone_number));
    formData.append("gender", obj?.gender);
    obj?.profile_picture &&
      formData.append("profile_picture", obj?.profile_picture);
    const data = await dispatch(updateUser(formData));
    if (data?.payload?.success) {
      // console.log(user);
      const newObj = data?.payload?.data[0];
      const combineObj = { ...user, ...newObj };
      localStorage.setItem("user", JSON.stringify(combineObj));
      dispatch(getUserDetails());
    }
  };

  return (
    <>
      {/* <DashboardLayout pageTitle={`Admin Settings`} goBack> */}
      <div className="profile-setting-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Admin Settings
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">My Profile</p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Profile Picture</p>
          </div>
        </div>
        {/* text tititl box end */}
        {/* form statrt */}
        <form onSubmit={handleSubmit} action="" className="form">
          {/* image profile start */}
          <div className="image-display-box">
            <div className="wrap">
              <figure className="img-display">
                <img
                  src={
                    details?.profile_picture
                      ? URL.createObjectURL(details?.profile_picture)
                      : user?.profile_picture
                      ? user?.profile_picture
                      : userImg
                  }
                  className="img"
                  alt=""
                />
              </figure>
              <input
                onChange={(e) => {
                  setDetails((prev) => {
                    return {
                      ...prev,
                      profile_picture: e.target.files[0],
                    };
                  });
                  setUserDisplay((prev) => {
                    return {
                      ...prev,
                      profile_picture: e.target.files[0],
                    };
                  });
                }}
                type="file"
                className="input"
                name=""
                id="img-user"
              />
              <label htmlFor="img-user" className="label">
                <figure className="img-box">
                  <img src={cameraImg} alt="" className="img" />
                </figure>
              </label>
            </div>
            <p className="text">
              Only standard format are allowed Jpg, png. File must not be more
              than 500kb
            </p>
          </div>
          {/* image profile start */}
          {/* 6 inputs form wrap start */}
          <div className="form-group-wrap">
            <p className="title">Personal Details</p>
            {/* wrap start */}
            <div className="wrap">
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="First Name" className="label">
                  First Name <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="First name"
                  className={`input ${details.first_name && "input-active"}`}
                  id="First Name"
                  name="first_name"
                  onChange={handleChange}
                  value={userDisplay?.first_name}
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Last Name" className="label">
                  Last Name <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Last name"
                  className={`input ${details.last_name && "input-active"}`}
                  id="Last Name"
                  name="last_name"
                  onChange={handleChange}
                  value={userDisplay?.last_name}
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="role" className="label">
                  Role
                </label>
                <input
                  type="text"
                  placeholder="Creator - Administrator"
                  className={`input ${details.role && "input-active"}`}
                  id="role"
                  name="role"
                  //   onChange={handleChange}
                  value={userDisplay?.role}
                  disabled
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="email" className="label">
                  Email
                </label>
                <input
                  type="text"
                  placeholder="ayeniKehin@revvex"
                  className={`input ${details.email && "input-active"}`}
                  id="email"
                  name="email"
                  //   onChange={handleChange}
                  value={user?.email}
                  disabled
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Phone Number" className="label">
                  Phone Number
                </label>
                {/* <input
                  type="text"
                  // placeholder="0908705060617"
                  className={`input ${details.phone_number && "input-active"}`}
                  id="Phone Number"
                  name="phone_number"
                  onChange={(e) =>
                    !e.target.value.match(/[A-Z]+/g) &&
                    !e.target.value.match(/[!@#$.%^&*_=+-]/g) &&
                    !e.target.value.match(/[a-z]+/g) &&
                    handleChange(e)
                  }
                  value={userDisplay?.phone_number}
                /> */}
                 <PatternFormat
                  type="text"
                  className={`input ${details.phone_number && "input-active"}`}
                  id="contact line"
                  onChange={handleChange}
                  value={userDisplay?.phone_number}
                  name="phone_number"
                  placeholder="Contact line"
                  format="### ### ### ###"
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="gender" className="label">
                  Gender
                </label>
                <Select
                  className="select-class"
                  styles={reactSelectStyle}
                  value={userDisplay?.gender}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, gender: e };
                    });
                    setUserDisplay((prev) => {
                      return { ...prev, gender: e };
                    });
                  }}
                  options={genderList}
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="country" className="label">
                  Country
                </label>
                <Select
                  className="select-class"
                  styles={reactSelectStyle}
                  value={userDisplay?.country}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, country: e };
                    });
                    setUserDisplay((prev) => {
                      return { ...prev, country: e };
                    });
                  }}
                  options={countries}
                />
              </div>
              {/* group box end */}
            </div>
            {/* wrap end */}
          </div>
          {/* 6 inputs form wrap end */}
          {/* submit form start */}
          {!loading ? (
            <input
              disabled={
                !details?.country &&
                !details?.gender &&
                !details?.profile_picture &&
                !details?.first_name &&
                !details?.last_name &&
                !details?.phone_number
              }
              type="submit"
              value="Save & Continue"
              className={`form-group btn-reuse form-submit ${
                details?.country ||
                details?.gender ||
                details?.profile_picture ||
                details?.first_name ||
                details?.last_name ||
                details?.phone_number
                  ? "form-submit-active"
                  : ""
              }`}
              style={{ marginTop: "3rem", color: details?.country ||
              details?.gender ||
              details?.profile_picture ||
              details?.first_name ||
              details?.last_name ||
              details?.phone_number ? "#ffffff" : "#020202" }}
            />
          ) : (
            <div
              className="dna-wrapper-box"
              style={{ alignSelf: "center", marginTop: "3rem" }}
            >
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
          {/* submit form  end */}
        </form>
        {/* form end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default ProfileSetting;
