import React from "react";
import BudgetMain from "..";
import CommonExpenditureLink from "../expenditure-rev/commonLink";
import { FileUploader } from "react-drag-drop-files";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
const fileTypes = ["CSV"];

const UpdateBulkUploadTemplate = () => {


  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink title={`Customize Bulk Upload Template`} />
        <div className="table-box-budget">
          <div className="drap-drop-box">
            <FileUploader
              label="Select a CSV file to import or drag and drop it here"
              // handleChange={handleChange}
              name="file"
              types={fileTypes}
            ></FileUploader>
          </div>

          <div className="btn-box-buget-template">
            <ButtonBlue text={`Cancel`} />
            <ButtonBlue text={`Save Template`} />
          </div>
        </div>
      </BudgetMain>
    </>
  );
};

export default UpdateBulkUploadTemplate;
