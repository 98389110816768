// import "../styles/subscription/subscription.css";
import "../../../../styles/dashboard/subscription/subscription.css";
const TopContent = () => {
  const items = [
    { title: "Flexible Contracts", text: "No lock in and price hike" },
    { title: "Intuitive UX", text: "50% faster implementation" },
    { title: "Minimal Learning Curve", text: "Better adoption, easy training" },
  ];
  return (
    <div>
      <div className="sub-top-items">
        {items.map((chi, idx) => {
          const { title, text } = chi;
          return (
            <div key={idx}>
              <div className="item-group">
                <div className="circle" />
                <div className="item-box">
                  <p className="title">{title}</p>
                  <p>{text}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default TopContent;
