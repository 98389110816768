import React from "react";
// import { useSelector } from 'react-redux';
import "../../styles/dashboard/Button.css";

const ButtonBlue = ({
  text,
  onClick,
  children,
  bgColor,
  style,
  btnClassName,
}) => {
  // const {  user } = useSelector((state) => state.user);
  return (
    <button
      style={{ backgroundColor: bgColor ? bgColor : "", ...style }}
      onClick={onClick}
      className={`button-blue-wrap btn-blue-wrap ${btnClassName}`}
      type="button"
    >
      {children} <span>{text}</span>
    </button>
  );
};

export default ButtonBlue;
