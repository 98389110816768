import React from "react";
import { Bar } from "react-chartjs-2";
import "../../../../../../employee-folder/styles/dashboard/home/chart/LineChart.scss";
// import Select from "react-select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import { useEffect } from "react";
// import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
// import { useDispatch, useSelector } from "react-redux";
// import { getEmployeeDashboard } from "../../../../../../redux/employee/dashboard";
import { numberFormatChart } from "../../../../../../helper/Helpers";
import { Oval } from "react-loader-spinner";
// import Select from "react-select/dist/declarations/src/Select";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EmployeeMonthlyExpenseChart = ({ children, dataValue, loading }) => {
  const data = {
    options: {
      //   maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
          // position: "left",
        },
        title: {
          // display: false,
          // text: "Chart.js Line Chart",
        },
      },
      scales: {
        y: {
          grid: {
            // display: false,
            // label: "hassan",
            // drawBorder: false,
          },
          title: {
            display: true,
            text: "Amount",
            // padding: 12,
          },
          ticks: {
            // min: 10,
            // max: 5,
            callback: (val) => `${numberFormatChart(val)}`,

            // padding: 20,

            // stepSize: 1000000,
          },
        },

        x: {
          grid: {
            display: false,
          },
          ticks: {
            // padding: 20,
            // gap:10,
            // margin: 20,
          },
        },
      },
    },
  };

  if (loading) {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "25rem",
            display: "grid",
            placeItems: "center",
          }}
          className=""
        >
          <Oval
            height={70}
            width={70}
            color={`#020202`}
            wrapperStyle={{}}
            wrapperClass="loader-spiner-react"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="rgba(0,0,0, .2)"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Bar
        width={100}
        height={30}
        data={dataValue}
        // data={dataValue || dataVal}
        // data={values}
        options={data?.options}
        ticks={data?.ticks}
      >
        {children}
      </Bar>
    </>
  );
};
export default EmployeeMonthlyExpenseChart;
