import React from "react";
import { Bar } from "react-chartjs-2";
import "../../../../../../employee-folder/styles/dashboard/home/chart/LineChart.scss";
// import Select from "react-select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { numberFormatChart } from "../../../../../../helper/Helpers";
import NoBarContent from "../../../../../../component/fragments/NoBarContent";
// import Select from "react-select/dist/declarations/src/Select";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EmployeeNetProfitProjectionGraph = ({
  children,
  dataLabel,
  dataValue,
  loading
}) => {
  const data = {
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          //   label: "First Dataset",
          // data: [8, 10, 24, 33, 75, 145, 87, 250, 450, 420, 425, 976 ],
          // data:  [
          //   55, 20, 90, 25, 78, 45, 56, 34, 89, 67, 79, 99, 120,
          // ],
          data: dataValue,
          backgroundColor: "#0E73F6",
          barPercentage: 0.5,
          label: "Net Profit Projection",
          display: false,
        },
      ],
    },
    options: {
      //   maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "left",
        },
        title: {
          display: false,
          text: "Chart.js Line Chart",
        },
      },
      scales: {
        y: {
          grid: {
            // display: false,
            drawBorder: false,
          },
          title: {
            display: true,
            text: "Amount",
            padding: 12,
          },
          ticks: {
            callback: (val) => `${numberFormatChart(val)}`,

            // stepSize: 18,
          },
          beginAtZero: true,
        },

        x: {
          grid: {
            display: false,
          },
          ticks: {
            padding: 20,
            // gap:10,
            // margin: 20,
          },
        },
      },
    },
  };

  if (loading) {
    return (
    <NoBarContent />
    );
  }


  return (
    <>
      <Bar
        width={100}
        height={30}
        data={data.data}
        options={data.options}
        ticks={data.ticks}
      >
        {children}
      </Bar>
    </>
  );
};
export default EmployeeNetProfitProjectionGraph;
