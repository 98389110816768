import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FaAngleRight, FaCheck, FaPlus } from "react-icons/fa";
// import DashboardLayout from "../../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/admin-settings/AdminSettingApproval.css";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import noteIcon from "../../../../../../assets/note-icon.svg";
import FormSummary from "./FormSummary";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovalClassById,
  getApprovalType,
  // setUpApprovalFlow,
  updateUpApprovalFlow,
} from "../../../../../../redux/approvals";
// import ActionContext from "../../../../../../context/ActionContext";
import { Dna } from "react-loader-spinner";
import { getDepartmentsBySubsidiary } from "../../../../../../redux/department/deparment";

const EditApproval = () => {
  const [expenditureType, setExpenditureType] = useState("");
  // const actionCtx = useContext(ActionContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showForm, setSHowForm] = useState(false);
  const { approvalType, loading } = useSelector((state) => state.approvals);
  const { plan_details } = useSelector((state) => state.users);
  const [activeId] = useState(
    localStorage.getItem("approval-select-class-id") || 1
  );
  const [getObj] = useState(false);
  const [objPartDetail, setObjPartDetail] = useState({
    approval_type_id: "",
    approval_class_id: "",
    subsidiary_id: "",
    department_id: "",
    flows: [],
  });
  // eslint-disable-next-line no-unused-vars
  const [submitState, setSUbmitState] = useState(false);
  const [triggerCancel, setTriggerCancel] = useState(false);

  useEffect(() => {
    // dispatch(getApprovalClass());
    dispatch(getApprovalType());
    // eslint-disable-next-line
  }, []);

  const checkListContain = (list) => {
    if (list[0]?.user_id && list[0]?.num_required) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (expenditureType === 1 || expenditureType === 2) {
      if (expenditureType) {
        setSUbmitState(true);
      } else {
        setSUbmitState(false);
      }
    }
    if (expenditureType === 3) {
      if (
        objPartDetail?.department_id &&
        objPartDetail?.subsidiary_id &&
        objPartDetail?.flows?.length > 0 &&
        checkListContain(objPartDetail?.flows)
      ) {
        setSUbmitState(true);
      } else {
        setSUbmitState(false);
      }
    }
  }, [objPartDetail, expenditureType]);

  useEffect(() => {
    getSingleApproval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleApproval = async () => {
    const obj = {
      id: localStorage.getItem("approval-single-id"),
    };
    const data = await dispatch(getApprovalClassById(obj));
    console.log(data);
    if (data?.payload?.success) {
      const approvals = data?.payload?.data;
      // console.log(approvals);
      const approveType = approvals?.approvals?.approval_type_id;
      setExpenditureType(approveType);
      let newList;
      if (approveType === 3) {
        setSHowForm(true);
        const flowList = approvals?.customs_approval?.map((chi) => {
          let obj;
          if (chi?.num_required === "single") {
            const val = {
              num_required: {
                value: chi?.num_required,
                label: "Single User",
              },
              user_id: { label: chi?.user_name, value: chi?.user_id },
              id: chi?.id,
              notify_approver: chi?.notify_approver,
            };
            obj = val;
          } else {
            const val = {
              num_required: {
                value: chi?.num_required,
                label: "Any User",
              },
              id: chi?.id,
              user_id: { label: chi?.user_name, value: chi?.user_id },
              time_frame: { label: chi?.time_frame, value: chi?.time_frame },
              alternate_user: {
                label: chi?.alternate_user,
                value: chi?.alternate_user_id,
              },
              notify_approver: chi?.notify_approver,
            };
            obj = val;
          }
          return obj;
        });
        newList = flowList;
      }
      const newObj = {
        ...approvals?.approvals,
        approval_type_id: approvals?.approvals?.approval_type_id,
        approval_class_id: approvals?.approvals?.approval_class_id,
        subsidiary_id: {
          label: approvals?.approvals?.subsidiary?.name,
          value: approvals?.approvals?.subsidiary?.id,
        },
        department_id: {
          label: approvals?.approvals?.department?.name,
          value: approvals?.approvals?.department?.id,
        },
        flows: newList,
      };
      dispatch(
        getDepartmentsBySubsidiary(approvals?.approvals?.subsidiary?.id)
      );
      // console.log(newObj);
      setObjPartDetail(newObj);
    }
  };

  const handleSubmit = async () => {
    const formatFlow = objPartDetail?.flows?.map((chi) =>
      chi?.num_required === "any"
        ? {
            user_id: chi?.user_id?.value || chi?.user_id,
            num_required: chi?.num_required?.value || chi?.num_required,
            time_frame: chi?.time_frame?.value || chi?.time_frame,
            alternate_user: chi?.alternate_user?.value || chi?.alternate_user,
            notify_approver: chi?.notify_approver === 1 ? true : false,
          }
        : {
            user_id: chi?.user_id?.value || chi?.user_id,
            num_required: chi?.num_required?.value || chi?.num_required,
            notify_approver: chi?.notify_approver === 1 ? true : false,
          }
    );
    let val;
    if (expenditureType === 3) {
      const obj = {
        subsidiary_id:
          objPartDetail?.subsidiary_id?.value || objPartDetail?.subsidiary_id,
        department_id:
          objPartDetail?.department_id?.value || objPartDetail?.department_id,
        approval_type_id: String(expenditureType),
        approval_class_id: String(activeId),
        flows: formatFlow,
        id: objPartDetail?.id,
      };
      val = obj;
    } else {
      const obj = {
        approval_type_id: String(expenditureType),
        approval_class_id: String(activeId),
        id: objPartDetail?.id,
        subsidiary_id: objPartDetail?.subsidiary?.id,
        department_id: objPartDetail?.department?.id,
      };
      val = obj;
    }
    // console.log(val);
    // return;
    const data = await dispatch(updateUpApprovalFlow(val));
    if (data?.payload?.data?.success) {
      // handleCancel();
      history.goBack();
    }
  };

  const handleCancel = () => {
    setExpenditureType("");
    setObjPartDetail({
      approval_type_id: "",
      approval_class_id: "",
      subsidiary_id: "",
      department_id: "",
      flows: [],
    });
    setSHowForm(false);
    setTriggerCancel(!triggerCancel);
    history.push("/dashboard-general-approvals")
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitle={`Setup Approval Flow`}
        pageTitleSpan={`Step 6 of 6`}
      > */}
      <div className="admin-approval-settings-wrap">
        <div className="subtitle-box">
          <p onClick={() => history.goBack()} className="text">
            Approval
          </p>
          <FaAngleRight className="icon" />
          <p
            // onClick={() =>
            //   history.push(
            //     "/dashboard-administration-admin-settings-approval-flow-expenditure-projection"
            //   )
            // }
            className="active text"
          >
            {activeId === "1"
              ? `Edit Revenue Projection`
              : activeId === "2"
              ? "Edit Actual Expense"
              : activeId === "3"
              ? "Edit Expenditure Projection"
              : activeId === "4"
              ? "Edit Actual Revenue"
              : "Edit Supplementary Budget"}
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>
        {/* subtitle box end */}
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">
              {" "}
              {activeId === "1"
                ? `Edit Revenue Projection`
                : activeId === "2"
                ? "Edit Actual Expense"
                : activeId === "3"
                ? "Edit Expenditure Projection"
                : activeId === "4"
                ? "Edit Actual Revenue"
                : "Edit Supplementary Budget"}{" "}
              Approval{" "}
            </p>
            <p className="text">
              Set up approval type and assign approvers to company budget and
              expense. Approvals are automatically assigned to users preference
              in the process of setting up account. Updates made to a set
              approval will be reflected on users to which it is applied.
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          <div className="btn-download-box">
            <button
              onClick={() => {
                handleCancel();
              }}
              className="btn"
            >
              Reset
            </button>
            {!loading ? (
              <button
                onClick={() => handleSubmit()}
                style={{
                  backgroundColor: `var(--blue-color)`,
                  color: "#ffffff",
                }}
                className={`btn btn-save `}
              >
                Update
              </button>
            ) : (
              <div
                className="dna-wrapper-box"
                style={{
                  alignSelf: "center",
                  marginTop: "0rem",
                  marginLeft: "2rem",
                }}
              >
                <Dna
                  visible={true}
                  height="60"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
          </div>
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* list box start  */}
        <div
          className="list-wrap"
          style={{ paddingBottom: "unset", marginBottom: "unset" }}
        >
          {approvalType.map((chi, idx) => {
            const { name, description, id } = chi;
            if (
              !plan_details?.privilages?.custom_approval_flow &&
              name === "Custom Approval"
            ) {
              return <React.Fragment key={idx}></React.Fragment>;
            }
            if (
              !plan_details?.privilages?.simple_approval_flow &&
              name === "Simple Approval"
            ) {
              return <React.Fragment key={idx}></React.Fragment>;
            }
            return (
              <div
                key={idx}
                onClick={() => {
                  expenditureType === id
                    ? setExpenditureType("")
                    : setExpenditureType(id);
                }}
                className={`item ${expenditureType === id && "item-active"}`}
              >
                <p className="title">{name}</p>
                <p className="text">{description}</p>
                {/* check box start */}
                <div className="check-box">
                  <FaCheck
                    className={`icon ${
                      expenditureType === id && "icon-active"
                    }`}
                  />
                </div>
                {/* check box end */}
              </div>
            );
          })}
        </div>
        {/* list box end */}
        {/* note wrap start */}
        {expenditureType === 3 && !showForm && (
          <div
            className="admin-org-settings-wrap"
            style={{ paddingBottom: "unset", padding: "0", marginTop: "-4rem" }}
          >
            <div className="note-wrap">
              <div className="note-img-wrap">
                Important Notice:
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
              </div>
              {/* note box start */}
              <div className="note-box">
                <p>
                  1. The submitted item for approval will be rejected by the
                  initial choice of preference and re-submitted to be approved
                  by the custom approvers.
                </p>
              </div>
            </div>
          </div>
        )}
        {/* note wrap end */}
        {/* button box start */}
        {expenditureType === 3 && !showForm && (
          <div className="btn-wrap">
            <ButtonBlue
              onClick={() => setSHowForm(true)}
              text={`Set Approval Flow`}
            >
              <FaPlus style={{ marginRight: ".8rem" }} />
            </ButtonBlue>
          </div>
        )}
        {/* button box end */}

        {/* form wrap start  */}
        {showForm && expenditureType === 3 && (
          <div className="form-wrap">
            <FormSummary
              getObj={getObj}
              setObjToSubmit={(param) => {
                // console.log(param);
                setObjPartDetail({ ...objPartDetail, ...param });
              }}
              triggerCancel={triggerCancel}
              defaultObj={objPartDetail}
              list={objPartDetail?.flows}
            />
          </div>
        )}
        {/* form wrap end  */}
        {/* button box start */}
        <div className="btn-download-box  btn-download-box-approval">
          <button
            onClick={() => {
              handleCancel();
            }}
            className="btn"
          >
            Reset
          </button>
          {!loading ? (
            <button
              onClick={() => handleSubmit()}
              style={{
                backgroundColor: `var(--blue-color)`,
                color: "#ffffff",
              }}
              className={`btn btn-save `}
            >
              Update
            </button>
          ) : (
            <div
              className="dna-wrapper-box"
              style={{
                alignSelf: "center",
                marginTop: "0rem",
                marginLeft: "2rem",
              }}
            >
              <Dna
                visible={true}
                height="60"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
        </div>
        {/* button box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default EditApproval;
