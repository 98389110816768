import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight, FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../../../../redux/role/role";
import ActionContext from "../../../../../context/ActionContext";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../helper/Helpers";
import { createUser, getUsers } from "../../../../../redux/users/users";
import { PatternFormat } from "react-number-format";
import { getDepartmentsBySubsidiary } from "../../../../../redux/department/deparment";
// import { getOrganizations } from "../../../../../redux/user";
import { Dna } from "react-loader-spinner";
import { getSubsidiaries } from "../../../../../redux/subsidiary/subsidiary";
import { toast } from "react-toastify";

const AddEmployee = () => {
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  const { roleDrop } = useSelector((state) => state.role);
  const { usersDrop, loading } = useSelector((state) => state.users);
  const [refreshPage, setRefreshPage] = useState(false);
  // const { organizations } = useSelector((state) => state.user);
  // const { branchSubDrop, loadingPage } = useSelector((state) => state.branch);
  const { departmentDrop, loadingPage } = useSelector(
    (state) => state.department
  );
  // const { loading } = useSelector((state) => state.subsidiary);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const history = useHistory();
  const [details, setDetails] = useState({
    subsidiary_id: "",
    department_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    role: "",
    submit_to: [],
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  useEffect(() => {
    if (actionCtx?.activeOrg?.type !== "group") {
      setDetails((prev) => {
        return { ...prev, subsidiary_id: subsidiariesDrop[0] };
      });
      // console.log(subsidiariesDrop);
      dispatch(getDepartmentsBySubsidiary(subsidiariesDrop[0]?.id));
      // handleGetBranchBySub(subsidiariesDrop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsidiariesDrop]);

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      dispatch(getRoles(actionCtx.activeOrg?.id));
      dispatch(getUsers(actionCtx?.activeOrg?.id));
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      // dispatch(getDepartments(actionCtx?.activeOrg?.id));
      // dispatch(getOrganizations());
    }
    // eslint-disable-next-line
  }, [refreshPage]);

  useEffect(() => {
    setDetails((prev) => {
      return { ...prev, department_id: [], subsidiary_id: {} };
    });
  }, [actionCtx?.activeOrg]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitList = details?.submit_to?.map((chi) => chi.id);
    const depList = details?.department_id?.map((chi) => chi?.id);
    const obj = {
      ...details,
      organization_id: actionCtx?.activeOrg?.id,
      subsidiary_id: details?.subsidiary_id?.id,
      department_ids: depList,
      role: details?.role?.label,
      submit_to: submitList,
      phone_number: formatNumberWithoutComma(details?.phone_number),
    };
    // console.log(obj);
    // return;
    const data = await dispatch(createUser(obj));
    if (data?.payload?.success) {
      setDetails({
        subsidiary_id: "",
        department_id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        role: "",
        submit_to: [],
      });
      setRefreshPage(!refreshPage);
      history.push("/dashboard-administration-admin-settings-employee");
    }
    if (data?.payload?.response?.status === 422) {
      const msg = data?.payload?.response?.data?.message;
      toast.error(msg, {
        theme: "colored",
      });
    }
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitleSpan={`Step 5 of 6`}
        pageTitle={`Add Employee`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings-employee")
            }
            className="text"
          >
            Users
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Add User</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Invite Users</p>
            <p className="text">
              Add department information and control the organisation structure
              of your account management
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          <div className="btn-download-box">
            <button
              className={`btn-bulk ${
                details.Subsidiary_Address ||
                details.Subsidiary_Name ||
                details.Phone_Number ||
                details.country
                  ? "btn-bulk-inactive"
                  : ""
              }`}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div>
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1.User Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="First Name" className="label">
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    className={`input ${details.first_name && "input-active"}`}
                    id="First Name"
                    name="first_name"
                    onChange={handleChange}
                    value={details.first_name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <div className="form-group">
                    <label htmlFor="Last Name" className="label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className={`input ${details.last_name && "input-active"}`}
                      id="Last Name"
                      name="last_name"
                      onChange={handleChange}
                      value={details?.last_name}
                    />
                  </div>
                  {/* group box end */}
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Email" className="label">
                    Email
                  </label>
                  <input
                    name="email"
                    style={{ textTransform: "lowercase" }}
                    onChange={handleChange}
                    value={details?.email}
                    type="text"
                    placeholder="Email"
                    className={`input ${details.email && "input-active"}`}
                    id="Email"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Phone Number" className="label">
                    Phone Number
                  </label>
                  <PatternFormat
                    type="text"
                    className={`input ${
                      details.phone_number && "input-active"
                    }`}
                    id="contact line"
                    onChange={handleChange}
                    value={details.phone_number}
                    name="phone_number"
                    placeholder="0909599***"
                    format="### ### ### ###"
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">2. User Permission</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Select organization" className="label">
                    Select Subsidiary
                  </label>
                  <Select
                    className={`select-wrap ${
                      actionCtx?.activeOrg?.type !== "group" && "disable-select"
                    } ${
                      Object.keys(details.subsidiary_id).length > 0 &&
                      "select-wrap-active"
                    }`}
                    isDisabled={actionCtx?.activeOrg?.type !== "group"}
                    placeholder="Select Subsidiary"
                    value={
                      actionCtx?.activeOrg?.type !== "group"
                        ? subsidiariesDrop || details?.subsidiary_id
                        : details.subsidiary_id
                    }
                    onChange={(e) => {
                      dispatch(getDepartmentsBySubsidiary(e.value));
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  {/* <label htmlFor="Last Name" className="label">
                      Last Name 
                    </label> */}
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="Choose a department" className="label">
                      Choose a department
                    </label>
                    <Select
                      className={`select-wrap select-wrap-multi ${
                        details?.department_id?.length > 0 &&
                        "select-wrap-active"
                      }`}
                      styles={reactSelectStyle}
                      placeholder="choose department"
                      value={details.department_id}
                      isMulti
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, department_id: e };
                        });
                      }}
                      options={departmentDrop}
                      isLoading={loadingPage}
                    />
                  </div>
                  {/* group box end */}
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Select a role" className="label">
                    Select a role
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.role && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    placeholder="select role"
                    value={details.role}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, role: e };
                      });
                    }}
                    options={roleDrop}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Choose budget approval" className="label">
                    Submit to
                  </label>
                  <Select
                    className={`select-wrap select-wrap-multi ${
                      details?.submit_to?.length > 0 && "select-wrap-active"
                    }`}
                    isMulti
                    styles={reactSelectStyle}
                    placeholder="Submit to"
                    value={details.submit_to}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, submit_to: e };
                      });
                    }}
                    options={usersDrop}
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}

            {/* submit form start */}
            {!loading ? (
              <input
                disabled={
                  !details?.first_name ||
                  !details?.last_name ||
                  !details?.phone_number ||
                  !details?.email ||
                  details?.department_id.length < 1 ||
                  !details?.subsidiary_id ||
                  !details?.submit_to ||
                  !details?.role
                }
                type="submit"
                value="Save & Continue"
                className={`form-group btn-reuse form-submit ${
                  details?.first_name &&
                  details?.last_name &&
                  details?.phone_number &&
                  details?.email &&
                  details?.department_id.length > 0 &&
                  details?.subsidiary_id &&
                  details?.role &&
                  details?.submit_to &&
                  "form-submit-active"
                }`}
                style={{ marginTop: "3rem" }}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "3rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* submit form  end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default AddEmployee;
