import React from "react";

const ApprovalContent = ({ headerOne, headerTwo, headerThree, children }) => {
  return (
    <div className="content-wrap">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(max-content, 1fr))",
          paddingBottom: "2rem",
          borderBottom: ".1rem solid var(--border-color)",
          fontWeight: "400",
          
        }}
      >
        {headerOne && <p>{headerOne}</p>}
        {headerTwo && <p>{headerTwo}</p>}
        {headerThree && <p>{headerThree}</p>}
      </div>

      {children}
    </div>
  );
};

export default ApprovalContent;
