import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/admin-settings/AdminSettings.css";
import { FaAngleRight  } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import SharedRemovableGroup from "./SharedRemovableGroup";
// import ButtonBlue from "../../../../buttons/ButtonBlue";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch } from "react-redux";
// import { Dna } from "react-loader-spinner";
import {
  getSharedService,
} from "../../../../../redux/shared-service/shareService";
import ButtonBlue from "../../../../buttons/ButtonBlue";

const ViewSharedService = () => {
  const actionCtx = useContext(ActionContext);
  // const { loading } = useSelector((state) => state.shared_service);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    title: "",
    apportions: [],
  });
  const [infoTitle, setInfoTitle] = useState("");
  const [sharedList, setSharedList] = useState([]);
  const history = useHistory();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  useEffect(() => {
    if (!actionCtx?.activeOrg?.shared_service_activated) {
      history.push("/dashboard-administration-admin-settings");
    }
      // eslint-disable-next-line
  }, [actionCtx.setActiveOrgManual]);

  useEffect(() => {
    setSharedList([]);
  }, [actionCtx?.activeOrg]);


  const handleSelectUser = (param, child) => {
    const newList = sharedList.map((chi) =>
      chi.id === child.id ? { ...chi, subsidiary_id: param.value } : chi
    );
    // console.log(newList);
    setSharedList(newList);
  };

  const handleSelectPro = (param, child) => {
    const newList = sharedList.map((chi) =>
      chi.id === child.id ? { ...chi, budget_percentage: param } : chi
    );
    setSharedList(newList);
  };

  const handleRemoveChild = (child) => {
    const newList = sharedList.filter((chi) => chi.id !== child.id);

    setSharedList(newList);
  };

  useEffect(() => {
    handleGetSharedService();
      // eslint-disable-next-line
  }, []);

  const handleGetSharedService = async () => {
    const id = localStorage.getItem("shared-service-id");
    const data = await dispatch(getSharedService(id));
    if (data?.payload?.success) {
      //   console.log(data?.payload.data.shared_service);
      const title = data?.payload?.data?.shared_service?.title;
      setInfoTitle(title);
      const apportions = data?.payload?.data?.shared_service?.apportions;
      const obj = apportions.map((chi) => {
        return {
          ...chi,
          subsidiary_id: { value: chi?.id, label: chi?.subsidiary?.name },
        };
      });
      setSharedList(obj);
    }
  };

  // const actionCtx = useContext(ActionContext)
  useEffect(() => {
    if(actionCtx?.activeOrg?.type !== "group"){
      history?.goBack()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Shared Service</p>
        </div>
        {/* text tititl box start */}
        <div className="title-text-box" style={{ width: "100%" }}>
          <p className="title">Shared Service</p>
          <p className="text">
            Set responsibility for the execution and the handling of specific
            operational tasks within your organization.
          </p>
        </div>
        {/* text tititl box end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "100%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form action="" className="form">
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1. Shared Service Information</p>
              <div
                className="form-wrap"
                style={{ width: "80%", gridTemplateRows: "unset" }}
              >
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary" className="label">
                    Title <span>*</span>
                  </label>
                  <input
                    disabled
                    type="text"
                    placeholder="IT Horizon Holdings"
                    className={`input ${details.title && "input-active"}`}
                    id="Subsidiary Name"
                    name="title"
                    onChange={handleChange}
                    value={infoTitle}
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title"> 2. Apportion %</p>

              {/* removable box start */}
              <div className="removable-wrap">
                {sharedList.map((chid, idx) => {
                  return (
                    <SharedRemovableGroup
                      selectValue={chid?.subsidiary_id}
                      InputValue={chid?.budget_percentage}
                      dontShowRemoveIcon={true}
                      disable={true}
                      key={chid.id || idx}
                      num={idx}
                      handleRemoveChild={() => handleRemoveChild(chid)}
                      onSelectUser={(e) => handleSelectUser(e, chid)}
                      onSelectPor={(e) => handleSelectPro(e, chid)}
                    />
                  );
                })}
              </div>
              {/* removable box end */}

              {/* add more start */}
              {/* <div className="add-approval-box" onClick={handleAddToSharedList}>
                <FaPlus className="icon" />
                <p className="text">Add Subsidiary</p>
              </div> */}
              {/* add more end */}
            </div>
            {/* wrap end */}

            {/* button start */}
            <ButtonBlue
              text={`Go back`}
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-add-subsidary-shared-service-main"
                )
              }
            />
            {/* button end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default ViewSharedService;
