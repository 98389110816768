import React from "react";
// import CommonSortBox from "../../../../../../component/fragments/CommonSortBox";
// import CommonNavLink from "../../../../../../component/pages/dashboard/actuals/expense/CommonNavLink";
// import ActionContext from "../../../../../../context/ActionContext";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
// import ActionContext from "../../../../../context/ActionContext";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeCommonNavLink from "./CommonNavLink";
import EmployeeExpProjectionLink from "./layout/AllProjection";
import ApproveProjectionLink from "./layout/ApproveLink";
import ProjectionPendingLink from "./layout/pendingLink";
import ProjectionRejectLink from "./layout/rejectLink";
import Select from "react-select";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import {
  reactSelectStyle,
  // reactSelectStyleCommonSort,
  // reactSelectStyleTable,
} from "../../../../../../helper/Helpers";
import { Dna } from "react-loader-spinner";
import EmployeeDraftProjection from "./EmpDraftProjection";
import { FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EmployeeCommonExpenseLayout = ({
  children,
  chartBox,
  allprojection,
  commonlink,
  approveLink,
  pendingLink,
  rejectLink,
  options,
  exportVal,
  onExport,
  exportDrop,
  onGetDept,
  exportSelect,
  onFileDownload,
  loading,
  showCommonLink,
}) => {
  // const actionCtx = useContext(ActionContext);

  const history = useHistory();

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Expenditure Projection`}> */}

      <div className="expense-projection-inde-wrap">
        {/* {exportDrop && (
            // <div className="header-submit-filter">
            //   <label>Export</label>
            //   <Select
            //     styles={reactSelectStyle}
            //     className="select-filter-by"
            //     options={options}
            //     placeholder="File Type"
            //     value={exportVal}
            //     onChange={(e) => onExport(e)}
            //   />
            // </div>
          )} */}

        <>
          {commonlink && <EmployeeCommonNavLink link={"/dashboard-home"} />}
          {allprojection && (
            <EmployeeExpProjectionLink link={"/dashboard-home"} />
          )}
          {approveLink && <ApproveProjectionLink link={"/dashboard-home"} />}
          {pendingLink && <ProjectionPendingLink link={"/dashboard-home"} />}
          {rejectLink && <ProjectionRejectLink link={"/dashboard-home"} />}
        </>
        {/* common links end */}
        {/* chart and table box start */}
        <>{chartBox}</>
        {/* chart  box end */}
        {/* children box start */}
        <>{children}</>
        {/* children box end */}
        {showCommonLink && (
          <EmployeeDraftProjection>
            <ButtonBlue
              onClick={() =>
                history.push("/employee-dashboard-projection-expenditure-new")
              }
              text={`Create Expenditure`}
            >
              <FaPlus style={{ marginRight: "15px" }} />
            </ButtonBlue>
          </EmployeeDraftProjection>
        )}
      </div>

      {/* </EmployeeDashboardLayout> */}
    </>
  );
};

export default EmployeeCommonExpenseLayout;
