import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import EmployeeExpenseProjectionIndex from ".";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import ActionContext from "../../../../../../context/ActionContext";
import {
  checkCurrencyFormat,
  formatNumber,
  formatToWholeNum,
  perPageOptions,
  trimLongString,
} from "../../../../../../helper/Helpers";
import {
  getAllExpenditureProjectionComment,
  getEmployeeProjectionDownload,
  getExpenditureProjectionById,
  getExpenditureProjectionPagination,
  getExpenditureProjectionSubmittedBySearch,
  getExpenditureProjections,
  getExportToPdf,
  getUserDepartment,
  notifyNextApprover,
  recallProjections,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import "../../../../../styles/dashboard/projection/expense/SubmitProjection.scss";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeCommonExpenseLayout from "./CommonLayout";
import EmployeeDraftProjection from "./EmpDraftProjection";
// import EmployeeExpenditurePending from "./pending";
import Modal from "react-awesome-modal";
// import EmpExpenditureOverviewPending from "./modals/pendingOverview";
import EmpExpProjectionOverview from "./modals/pendingOverview";
import EmpExpProRejectModal from "./modals/rejectModal";
import EmpExpProApproveOverview from "./modals/approveModal";
import EmpExpProjectionComentModal from "./modals/comments";
import useDebounce from "../../../../../../helper/UseDebounce";
import EmpExpApprovedProjectionOverview from "./modals/approveOverview";
import EmpExpRejectProjectionOverview from "./modals/rejectOverview";
import moment from "moment";
import FinalApprovalModal from "../../../../../../component/pages/dashboard/projection/expense/modals/FinalApprovalModal";
import {
  getDepartment,
  getDepartments,
} from "../../../../../../redux/department/deparment";
import { getVerticals } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import { getProducts } from "../../../../../../redux/budget-setting/revenue-projection/product";
import ExpenditureRecallModal from "./modals/recallModal";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import { Oval } from "react-loader-spinner";
// import Papa from "papaparse";
// import { useRef } from "react";
// import { useReactToPrint } from "react-to-print";

const EmployeeSubmitProjection = () => {
  const cardHeader = [
    "Expense Projected",
    "Approved",
    "Pending",
    "Rejected",
    "Pending My Approval",
  ];
  const actionCtx = useContext(ActionContext);

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Units",
    `Unit Price (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Total Value (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    "Date",
    "status",
    "Action",
  ];
  const link = [
    "/employee-dashboard-projection-expenditure-new",
    "/employee-dashboard-projection-expenditure-submitted",
    "/employee-dashboard-projection-expenditure-submitted-approved",
    "/employee-dashboard-projection-expenditure-submitted-pending",
    "employee-dashboard-projection-expenditure-submitted-rejected",
  ];

  const [showModal, setShowModal] = useState({
    pendingOverview: false,
    approveOverview: false,
    rejectOverview: false,
    approve: false,
    reject: false,
    comment: false,
    approve_all: false,
    recall: false,
  });
  const [modalView, setModalView] = useState(false);
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const { productDrop } = useSelector((state) => state.product);
  const { verticalDrop } = useSelector((state) => state.vertical);
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);
  // const [refreshTable, setRefreshTable] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const {
    loadingPage,
    loading,
    recallLoading,
    expenditureProjectionById,
    expenditureProjctions,
  } = useSelector((state) => state.expenditure_projection);
  const [singleChild, setSingleChild] = useState({});
  const [showTable, setShowTable] = useState(false);

  // console.lo, "check");
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [filterByVal, setFilterByVal] = useState("");
  const [productVal, setProductVal] = useState("");
  const [verticalVal, setVerticalVal] = useState("");
  const [chartOfAcctVal, setChartOfAcct] = useState("");
  const [fiscalYearVal, setFiscalYearVal] = useState("");

  // const [sortByVal, setSortByVal] = useState({ label: "Sort by" });
  const [singleItem, setSingleItem] = useState({});
  const [downloading, setDownloading] = useState(false);
  // const [perPageVal, setPerPageVal] = useState({
  //   label: singleItem?.exenditure?.per_page,
  // });

  // const SortByOptions = [
  //   { label: "Ascending", value: "created_at" },
  //   { label: "Descending", value: "-created_at" },
  // ];
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart Of Account", value: "chart_of_account" },
    { label: "Class", value: "class" },
    { label: "Description", value: "description" },
  ];
  const [activeStat, setActiveStat] = useState({
    one: true,
    two: false,
    three: false,
    four: false,
    five: false,
  });
  const [dept, setDept] = useState("");
  const [selectStat, setSelectStat] = useState("");

  const debouncedSearchTerm = useDebounce(search, 1000);
  const handleSearch = (param) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      search: search,
      dept: dept?.id,
      filter: filterByVal?.value,
      status: param || selectStat?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      fiscal_year_id: fiscalYearVal?.value,
      // sort_by: sortByVal?.value,
    };
    setIndexNo(false);
    dispatch(getExpenditureProjectionSubmittedBySearch(obj));
  };

  // const [refreshTable, setRefreshTable] = useState(false);

  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      (activeStat?.one && handleSearch("")) ||
        (activeStat?.two && handleSearch("approved")) ||
        (activeStat?.three && handleSearch("pending")) ||
        (activeStat?.five && handleSearch("pending_my_current_approver")) ||
        (activeStat?.four && handleSearch("rejected"));
      setShowTable(true);
    } else if (isMount && debouncedSearchTerm?.length < 1) {
      // handleGetExpenditureData();
      (activeStat?.one && handleGetExpenditureData("")) ||
        (activeStat?.two && handleGetExpenditureData("approved")) ||
        (activeStat?.three && handleGetExpenditureData("pending")) ||
        (activeStat?.five &&
          handleGetExpenditureData("pending_my_current_approver")) ||
        (activeStat?.four && handleGetExpenditureData("rejected"));
    } else if (
      isMount &&
      debouncedSearchTerm?.length < 1 &&
      expenditureProjectionById?.data?.length < 1
    ) {
      setShowTable(true);
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const [allApproveId, setAllAppproveId] = useState([]);
  const handleApproveAll = () => {
    const list = singleItem?.expenditure?.data?.filter(
      (chi) => chi?.status === "pending" && chi?.current_approver
    );
    const newList = list.map((chi) => chi?.id);
    setAllAppproveId(newList);
  };

  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const { departmentDrop } = useSelector((state) => state.department);
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);

  const handlePagination = async (param, statusParam, num) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      filter_submitted: true,
      dept: dept?.id,
      page: linkTwo || num,
      status: statusParam || selectStat?.value,
      filter: filterByVal?.value,
      search: search,
      fiscal_year_id: fiscalYearVal?.value,
      // sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,

      // status: "approved",
    };
    // console.log("linkTwo", obj);
    setIndexNo(false);
    const data = await dispatch(getExpenditureProjectionPagination(obj));
    if (data?.payload?.success) {
      // setIndexNo(false);
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);

      // // console.log(data?.payload?.data);
    }
  };

  const handlePaginationNum = async (num, statusParam) => {
    // const splitLink = param.split("/api");
    // const splitLink = param.split("?page=");
    // const linkTwo = splitLink[1];
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      filter_submitted: true,
      dept: dept?.id,
      page: num,
      status: statusParam || selectStat?.value,
      filter: filterByVal?.value,
      search: search,
      // sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
      fiscal_year_id: fiscalYearVal?.value,
      // status: "approved",
    };
    // console.log("linkTwo", obj);
    setIndexNo(false);
    setShowActionDropDown(false);
    const data = await dispatch(getExpenditureProjectionPagination(obj));
    if (data?.payload?.success) {
      // setIndexNo(false);
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);

      // // console.log(data?.payload?.data);
    }
  };

  //update expenditure projection
  const handleUpdate = (param) => {
    actionCtx?.setIsModalOut(false);
    localStorage.setItem("single-expenditure-projection-id", param?.id);
    // return;
    history.push("/employee-dashboard-projection-expenditure-update");
  };

  const [exportLoader, setExportLoader] = useState(false);
  const handleExport = async (param) => {
    const obj = {
      id: param?.id,
      type: "expenditure_projection",
      currency_id: actionCtx?.currencyValue?.id,
    };
    setExportLoader(true);
    const data = await dispatch(getExportToPdf(obj));
    if (data?.payload?.url) {
      setExportLoader(false);
      actionCtx?.setIsModalOut(false);
      window.open(data?.payload?.url, "_blank").focus();
      setShowModal((prev) => {
        return {
          ...prev,
          approveOverview: false,
          pendingOverview: false,
          rejectOverview: false,
        };
      });
    } else {
      setExportLoader(false);
    }
  };

  useEffect(() => {
    const obj = {
      reason: true,
      id: actionCtx?.activeOrg?.id,
    };
    if (actionCtx?.activeOrg?.id) {
      dispatch(getUserDepartment(actionCtx?.activeOrg?.id));
      dispatch(getDepartments(actionCtx?.activeOrg?.id));
      dispatch(getChatAccounts(obj));
      dispatch(getVerticals(obj));
      dispatch(getProducts(obj));
      dispatch(getFiscalYears(obj));
    }
  }, [actionCtx?.activeOrg?.id]);

  useEffect(() => {
    if (actionCtx?.perPage || actionCtx?.currencyValue) {
      dispatch(getExpenditureProjections({ org_id: actionCtx?.activeOrg?.id }));
      handleGetExpenditureData();
    }
  }, [actionCtx?.perPage, actionCtx?.currencyValue]);

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterLoad, setFilterLoad] = useState(false);
  const handleGetExpenditureData = async (param, deptParam) => {
    const obj = {
      dept:
        deptParam ||
        dept?.id ||
        localStorage.getItem("expenditure-projection-report") ||
        "",
      org_id: actionCtx?.activeOrg?.id,
      status: param || selectStat?.value,
      currency: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
      fiscal_year_id: fiscalYearVal?.value,
    };
    const data = await dispatch(getExpenditureProjectionById(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
    }
  };
  const [activeBell, setActiveBell] = useState(true);

  const handleFilterSubmit = async (param, deptParam) => {
    const obj = {
      dept: deptParam || dept?.id,
      org_id: actionCtx?.activeOrg?.id,
      status: param || selectStat?.value,
      currency: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
      fiscal_year_id: fiscalYearVal?.value,
    };
    setFilterLoad(true);
    const data = await dispatch(getExpenditureProjectionById(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      setFilterLoad(false);
      setShowFilterModal(false);
      // setSelectStat("");
      // setChartOfAcct("");
      localStorage.setItem("expenditure-notify-dept", dept?.id);
      // setVerticalVal("");
      // setProductVal("");
      // setPdfExport("");
      // setFilterByVal("");
      dept?.id && setActiveBell(false);
      // setDept("");
      actionCtx?.setIsModalOut(false);
      setActiveStat((prev) => {
        return {
          ...prev,
          one: obj.status === "",
          two: obj?.status === "approved" && true,
          three: obj?.status === "pending" && true,
          five: obj?.status === "pending_my_current_approver" && true,
          four: obj?.status === "rejected" && true,
        };
      });
    } else {
      setFilterLoad(false);
    }
  };

  const handleEditListCheck = (id) => {
    const newList = singleItem?.expenditure?.data?.map((chi) =>
      chi?.id === id
        ? { ...chi, current_approver: !chi?.current_approver }
        : chi
    );
    const newObj = {
      ...singleItem,
      expenditure: { ...singleItem?.expenditure, data: newList },
    };
    setSingleItem(newObj);
  };

  const [allApprovalStatus, setAllApprovalStatus] = useState("");
  const [headerCheck, setHeaderCheck] = useState(true);

  // status dropdown
  const statusDropdown = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Pending My Approval",
      value: "pending_my_current_approver",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const statusDropDownTwo = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  // console.log(deptId, "departmentId");

  // console.log("user", user);

  const cardValue = [
    singleItem?.total_projection || 0,
    singleItem?.approved || 0,
    singleItem?.awaiting_approval || 0,
    singleItem?.rejected || 0,
    singleItem?.pending_my_approver || 0,
    singleItem?.total_gp || 0,
  ];

  const handleGetCommentData = (param) => {
    // setSingleChild(param);
    dispatch(getAllExpenditureProjectionComment(param));
  };

  const handleSingleCommentData = (param) => {
    setSingleChild(param);
    if (param) {
      dispatch(getAllExpenditureProjectionComment(param?.id));
    }
  };

  // export dropdown
  const [pdfExport, setPdfExport] = useState("");
  const exportOption = [
    { label: "CSV", value: "excel" },
    { label: "PDF", value: "pdf" },
  ];

  const handleFileDownload = async (param) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      dept_id: dept?.id,
      type: "expense_projection",
      format: pdfExport?.value,
      user_id: user?.id,
      chart_of_account_id: chartOfAcctVal?.value,
      currency: actionCtx?.currencyValue?.id || "",
      status: param || selectStat?.value,
      fiscal_year_id: fiscalYearVal?.value,
    };
    setDownloading(true);
    const data = await dispatch(getEmployeeProjectionDownload(obj));
    if (data?.payload?.status === 200) {
      setDownloading(false);
      window.open(data?.payload?.data?.url, "_blank").focus();
      setIndexNo(false);
      setShowFilterModal(false);
      setSelectStat("");
      setDept("");
      setVerticalVal("");
      setProductVal("");
      setPdfExport("");
      setFilterByVal("");
      actionCtx?.setIsModalOut(false);
    } else {
      setDownloading(false);
    }
  };

  const [selectedRecall, setSelectedRecall] = useState([]);
  const [checkHeader, setCheckHeader] = useState(!true);
  const [recallLoader, setRecallLoader] = useState(false);
  const handleRecallProjection = async (param, singleParam) => {
    const obj = {
      id: param || [singleParam],
      type: "expenditure_projection",
    };
    setRecallLoader(true);
    const data = await dispatch(recallProjections(obj));
    if (data?.payload?.success) {
      // setIndexNo(false);
      setRecallLoader(false);
      actionCtx?.setIsModalOut(false);
      history.push("/employee-dashboard-projection-expenditure");
    } else {
      setRecallLoader(false);
    }
  };

  const handleCheckRecall = (id) => {
    const checkedList = selectedRecall.indexOf(id);
    if (checkedList > -1) {
      setSelectedRecall(selectedRecall.filter((chi) => chi !== id));
    } else {
      setSelectedRecall([...selectedRecall, id]);
    }
  };

  const handleAllRecallCheck = (e) => {
    if (e.target.checked) {
      setSelectedRecall(singleItem?.expenditure?.data?.map((chi) => chi.id));
    } else {
      setSelectedRecall([]);
    }
    setCheckHeader(e.target.checked);
  };

  const deptId = localStorage.getItem("expenditure-notify-dept");

  const [notifyLoader, setNotifyLoader] = useState(false);
  const handleNotifyApprover = async () => {
    const obj = {
      department_id: deptId,
      projection_type: "expenditure_projection",
    };
    setNotifyLoader(true);
    setShowActionDropDown(false);
    const data = await dispatch(notifyNextApprover(obj));
    if (data.payload?.success) {
      setNotifyLoader(false);
      setDept("");
      setActiveBell(true);
    } else {
      setNotifyLoader(false);
    }
  };
  const [showActionDropDown, setShowActionDropDown] = useState(false);

  return (
    <>
      {expenditureProjectionById?.data?.length > 0 ||
      expenditureProjctions?.data?.length > 0 ||
      showTable ? (
        <EmployeeCommonExpenseLayout
          // exportDrop
          // exportVal={pdfExport}
          // options={exportOption}
          // onExport={(e) => {
          //   setPdfExport(e);
          //   setIndexNo(false);
          // }}
          commonlink={true}
          showCommonLink={expenditureProjectionById?.data?.length < 1}
          // onFileDownload={pdfExport?.value && handleFileDownload}
        >
          {/* total info detail box end */}
          {(expenditureProjectionById?.data?.length > 0 ||
            (debouncedSearchTerm?.length > 0 &&
              expenditureProjectionById?.data?.length < 1) ||
            showTable) && (
            <EmployeeReusableTable
              downloading={downloading}
              onDownload={() => {
                handleFileDownload(
                  (activeStat?.one && "") ||
                    (activeStat?.two && "approved") ||
                    (activeStat?.three && "pending") ||
                    (activeStat?.four && "rejected")
                );
              }}
              exportDrop
              exportVal={pdfExport}
              options={exportOption}
              showDropDown={showActionDropDown}
              setShowDropDown={() => {
                setShowActionDropDown(!showActionDropDown);
                setIndexNo(false);
              }}
              onExport={(e) => {
                setPdfExport(e);
                setIndexNo(false);
              }}
              onOpenFilter={() => {
                setIndexNo(false);
                setShowActionDropDown(false);
                setShowFilterModal(true);
                actionCtx?.setIsModalOut(true);
              }}
              showFilter={showFilterModal}
              onCloseFilter={() => {
                actionCtx?.setIsModalOut(false);
                setShowFilterModal(false);
                setSelectStat("");
                setVerticalVal("");
                setProductVal("");
                setPdfExport("");
                setFilterByVal("");
                setDept("");
              }}
              filterLoading={filterLoad}
              onFilterSubmit={() => {
                handleFilterSubmit();
              }}
              filterIcon
              // vertical
              verticalVal={verticalVal}
              onVerticalChange={(e) => {
                setVerticalVal(e);
              }}
              verticalDrop={verticalDrop}
              // chart of  account
              chartAcct
              chartAcctVal={chartOfAcctVal}
              chartAcctDrop={chatAccountDrop}
              onChartAcctChange={(e) => {
                setChartOfAcct(e);
              }}
              // fiscal year
              fiscalYear
              fiscalYearVal={fiscalYearVal}
              onFiscalChange={(e) => {
                setFiscalYearVal(e);
              }}
              fiscalYearDrop={fiscalYearDrop}
              // product
              productVal={productVal}
              onProductChange={(e) => {
                setProductVal(e);
              }}
              productDrop={productDrop}
              fileDownload
              onRejectAll={() => {
                actionCtx?.setIsModalOut(true);
                setShowModal((prev) => {
                  return { ...prev, approve_all: true };
                });
                setAllApprovalStatus("rejected");
                handleApproveAll();
              }}
              onApproveAll={() => {
                actionCtx?.setIsModalOut(true);
                setShowModal((prev) => {
                  return { ...prev, approve_all: true };
                });
                setAllApprovalStatus("approved");
                handleApproveAll();
              }}
              rejectAll={activeStat?.five}
              approveAll={activeStat?.five}
              allActionBtn
              downloadLoader={downloading}
              onFileDownload={() => {
                pdfExport?.value &&
                  handleFileDownload(
                    (activeStat?.one && "") ||
                      (activeStat?.two && "approved") ||
                      (activeStat?.three && "pending") ||
                      (activeStat?.four && "rejected")
                  );
                setIndexNo(false);
                // setShowActionDropDown(false);
              }}
              exportSelect={pdfExport?.label || "File"}
              ApprovalBell={!activeStat?.four}
              disableBell={activeBell}
              onNotify={handleNotifyApprover}
              totalTitle={"Expenditure Projected"}
              totalValue={formatNumber(String(cardValue[0] || 0))}
              // totalTitleTwo={"Total GP"}
              // totalValueTwo={formatNumber(String(cardValue[5] || 0))}
              perPage
              optionPerPage={perPageOptions}
              setPerPage={(e) => {
                actionCtx?.setPerpage(e);
              }}
              recallAllBtn={
                ((activeStat?.three || activeStat?.one || activeStat?.five) &&
                  singleItem?.expenditure?.data?.length > 0) ||
                !activeStat?.one
              }
              loading={loadingPage}
              notifyLoader={notifyLoader}
              recallLoader={recallLoading}
              onRecallAll={() => {
                selectedRecall?.length > 0 &&
                  handleRecallProjection(selectedRecall, "");
              }}
              recallBgColor={selectedRecall?.length < 1 && "#cccccc"}
              perPageVal={actionCtx?.perPage}
              statusDrop
              statusDropdown={
                user?.role !== "budget rep" ? statusDropdown : statusDropDownTwo
              }
              setStatusVal={(e) => {
                setSelectStat(e);
              }}
              statusVal={selectStat}
              dept
              departmentDrop={
                user?.user_type === "ADMIN"
                  ? departmentDrop
                  : userDepartmentDrop
              }
              deptVal={dept}
              setDepartmentVal={(e) => {
                setDept(e);
              }}
              filter
              optionFilter={filterByOptions}
              optionFilterVal={filterByVal}
              setOptionFilterVal={(e) => {
                setFilterByVal(e);
                setIndexNo(false);
                // console.log(e);
              }}
              onSearchTable={(e) => {
                setSearch(e);
                setIndexNo(false);
              }}
              addButton
              BtnTitle={`New Expenditure`}
              onPage={() => history.push(link[0])}
              // firstCard
              secondCard
              thirdCard
              fourthCard
              fifthCard={user?.role !== "budget rep"}
              showSearch
              cardTitleOne={cardHeader[0]}
              cardTitleTwo={cardHeader[1]}
              cardTitleThree={cardHeader[2]}
              cardTitleFour={cardHeader[3]}
              cardTitleFive={cardHeader[4]}
              cardBodyOne={formatNumber(String(cardValue[0] || 0))}
              cardBodyTwo={formatNumber(String(cardValue[1] || 0))}
              cardBodyThree={formatNumber(String(cardValue[2] || 0))}
              cardBodyFour={formatNumber(String(cardValue[3] || 0))}
              cardBodyFive={formatNumber(String(cardValue[4] || 0))}
              // linkCardOne={link[1]}
              onFirstCard={() => {
                setIndexNo(false);
                handleGetExpenditureData("");
                setActiveStat((prev) => {
                  return {
                    ...prev,
                    one: true,
                    two: false,
                    three: false,
                    four: false,
                    five: false,
                  };
                });
              }}
              onSecondCard={() => {
                setIndexNo(false);
                setShowActionDropDown(false);
                expenditureProjectionById?.data?.length > 0 &&
                  setShowTable(true);
                handleGetExpenditureData("approved");
                setSelectStat({ label: "Approved", value: "approved" });
                setActiveStat((prev) => {
                  return {
                    ...prev,
                    one: false,
                    two: true,
                    three: false,
                    four: false,
                    five: false,
                  };
                });
              }}
              onFifthCard={() => {
                setIndexNo(false);
                setShowActionDropDown(false);
                expenditureProjectionById?.data?.length > 0 &&
                  setShowTable(true);
                setSelectStat({
                  label: "Pending My Approval",
                  value: "pending_my_current_approver",
                });
                handleGetExpenditureData("pending_my_current_approver");
                setActiveStat((prev) => {
                  return {
                    ...prev,
                    one: false,
                    two: false,
                    three: false,
                    four: false,
                    five: true,
                  };
                });
              }}
              onThirdCard={() => {
                setIndexNo(false);
                setShowActionDropDown(false);
                expenditureProjectionById?.data?.length > 0 &&
                  setShowTable(true);
                handleGetExpenditureData("pending");
                setSelectStat({
                  label: "Pending",
                  value: "pending",
                });
                setActiveStat((prev) => {
                  return {
                    ...prev,
                    one: false,
                    two: false,
                    three: true,
                    four: false,
                    five: false,
                  };
                });
              }}
              onFourthCard={() => {
                setIndexNo(false);
                setShowActionDropDown(false);
                expenditureProjectionById?.data?.length > 0 &&
                  setShowTable(true);
                setSelectStat({ label: "Rejected", value: "rejected" });
                handleGetExpenditureData("rejected");
                setActiveStat((prev) => {
                  return {
                    ...prev,
                    one: false,
                    two: false,
                    three: false,
                    four: true,
                    five: false,
                  };
                });
              }}
              linkCardOne={activeStat?.one}
              linkCardTwo={activeStat?.two}
              linkCardThree={activeStat?.three}
              linkCardFour={activeStat?.four}
              linkCardFive={activeStat?.five}
            />
          )}

          {(expenditureProjectionById?.data?.length > 0 ||
            (debouncedSearchTerm?.length > 0 &&
              expenditureProjectionById?.data?.length < 1) ||
            showTable) && (
            <ActualReuseableTable
              key={`dffgjsh`}
              massSelectable={
                (activeStat?.five &&
                  singleItem?.expenditure?.data?.length > 0) ||
                !activeStat?.one ||
                (activeStat?.three &&
                  singleItem?.expenditure?.data?.length > 0) ||
                !activeStat?.one
              }
              checkValue={
                (activeStat?.five && headerCheck) ||
                (activeStat?.three && checkHeader)
              }
              onMassCheck={(e) => {
                setHeaderCheck(!headerCheck);
                const newList = singleItem?.expenditure?.data?.map((chi) => {
                  return { ...chi, current_approver: e.target.checked };
                });
                const newObj = {
                  ...singleItem,
                  expenditure: { ...singleItem?.expenditure, data: newList },
                };
                setSingleItem(newObj);
                // pending
                activeStat?.three && handleAllRecallCheck(e);
              }}
              useNumPagination
              currentPage={singleItem?.expenditure?.current_page}
              totalTableLength={singleItem?.expenditure?.total}
              onNumPage={(e) => {
                handlePaginationNum(
                  e,
                  (activeStat?.one && "") ||
                    (activeStat?.two && "approved") ||
                    (activeStat?.three && "pending") ||
                    (activeStat?.five && "pending_my_current_approver") ||
                    (activeStat?.four && "rejected")
                );
              }}
              dontShowPagination={expenditureProjectionById?.data?.length < 1}
              dontShowBnSearch
              one={tableHeadList[0]}
              two={tableHeadList[1]}
              three={tableHeadList[2]}
              four={tableHeadList[3]}
              five={tableHeadList[4]}
              six={tableHeadList[5]}
              seven={tableHeadList[6]}
              eight={tableHeadList[7]}
              nine={tableHeadList[8]}
              // action
              nextPage={singleItem?.expenditure?.next_page_url}
              prevPage={singleItem?.expenditure?.prev_page_url}
              fromPage={singleItem?.expenditure?.from}
              toPage={singleItem?.expenditure?.to}
              totalPage={singleItem?.expenditure?.total}
              onNextPage={() => {
                setShowActionDropDown(false);
                (activeStat?.one &&
                  handlePagination(
                    singleItem?.expenditure?.next_page_url,
                    ""
                  )) ||
                  (activeStat?.two &&
                    handlePagination(
                      singleItem?.expenditure?.next_page_url,
                      "approved"
                    )) ||
                  (activeStat?.three &&
                    handlePagination(
                      singleItem?.expenditure?.next_page_url,
                      "pending"
                    )) ||
                  (activeStat?.five &&
                    handlePagination(
                      singleItem?.expenditure?.next_page_url,
                      "pending_my_current_approver"
                    )) ||
                  (activeStat?.one &&
                    handlePagination(
                      singleItem?.expenditure?.next_page_url,
                      "rejected"
                    ));
              }}
              onPrevPage={() => {
                setShowActionDropDown(false);
                (activeStat?.one &&
                  handlePagination(
                    singleItem?.expenditure?.prev_page_url,
                    ""
                  )) ||
                  (activeStat?.two &&
                    handlePagination(
                      singleItem?.expenditure?.prev_page_url,
                      "approved"
                    )) ||
                  (activeStat?.three &&
                    handlePagination(
                      singleItem?.expenditure?.prev_page_url,
                      "pending"
                    )) ||
                  (activeStat?.five &&
                    handlePagination(
                      singleItem?.expenditure?.prev_page_url,
                      "pending_my_current_approver"
                    )) ||
                  (activeStat?.one &&
                    handlePagination(
                      singleItem?.expenditure?.prev_page_url,
                      "rejected"
                    ));
              }}
            >
              <>
                {expenditureProjectionById?.data?.map((chi, idx) => {
                  const {
                    chart_of_account,
                    description,
                    units,
                    unit_price,
                    total_value,
                    date,
                    status,
                    current_approver,
                    id,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      checkBoxType={
                        activeStat?.five || activeStat?.three || activeStat?.one
                      }
                      onCheck={(e) => {
                        handleEditListCheck(id);
                        handleCheckRecall(id);
                      }}
                      checkValue={
                        // ((activeStat?.three || activeStat?.one) &&
                        (activeStat?.five && current_approver) ||
                        (status === "pending" && selectedRecall.includes(id))
                      }
                      // (activeStat?.one && selectedRecall.includes(id))
                      checkId={`${id}-${idx}`}
                      item={chi}
                      key={idx}
                      one={trimLongString(chart_of_account?.name, "15")}
                      two={trimLongString(chart_of_account?.class, "25")}
                      three={trimLongString(description, "25")}
                      four={formatToWholeNum(units || 0)}
                      five={formatNumber(String(unit_price || 0))}
                      six={formatNumber(String(total_value || 0))}
                      seven={moment(date).format("YYYY-MM-DD")}
                      action
                      progressType={status}
                      statusStep
                      statusList={chi?.approval_status}
                      loading={loadingPage || recallLoading}
                      rowNo={idx}
                      indexNo={indexNo}
                      recallText={"recall"}
                      onRecall={() => {
                        setIndexNo(false);
                        setShowActionDropDown(false);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, recall: true };
                        });
                        actionCtx?.setIsModalOut(true);
                      }}
                      setIndexNo={() => {
                        setIndexNo(idx);
                        setShowActionDropDown(false);
                      }}
                      // updateText={`Update`}
                      deleteText={
                        user?.permissions?.includes(
                          "approve/reject expense projection"
                        ) && "reject"
                      }
                      // rejectedText={"Reject"}
                      approveText={
                        status === "pending" &&
                        user?.permissions?.includes(
                          "approve/reject expense projection"
                        ) &&
                        current_approver &&
                        "approve"
                      }
                      // approveText={'approve'}
                      viewText={"View Comments"}
                      onExport={() => handleExport(chi)}
                      exportLoader={exportLoader}
                      exportText={`Export as PDF`}
                      onUpdate={() => handleUpdate(chi)}
                      updateText={
                        status === "pending" &&
                        user?.permissions?.includes(
                          "edit expense projection"
                        ) &&
                        "update"
                      }
                      onOverview={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setShowActionDropDown(false);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, pendingOverview: true };
                          });
                          setIndexNo(false);
                        }
                      }}
                      onAcceptStatus={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setShowActionDropDown(false);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, approveOverview: true };
                          });
                          setIndexNo(false);
                        }
                      }}
                      onRejectStatus={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setShowActionDropDown(false);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, rejectOverview: true };
                          });
                          setIndexNo(false);
                        }
                      }}
                      onApprove={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setShowActionDropDown(false);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, approve: true };
                          });
                          setIndexNo(false);
                        }
                      }}
                      onView={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setShowActionDropDown(false);
                          handleSingleCommentData(chi);
                          setShowModal((prev) => {
                            return { ...prev, comment: true };
                          });
                          setIndexNo(false);
                        }
                      }}
                      onDelete={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setShowActionDropDown(false);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, reject: true };
                          });
                          setIndexNo(false);
                        }
                      }}
                      onRejected={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setShowActionDropDown(false);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, reject: true };
                          });
                          setIndexNo(false);
                        }
                      }}
                    />
                  );
                })}
              </>
            </ActualReuseableTable>
          )}
          {/* </div> */}
        </EmployeeCommonExpenseLayout>
      ) : (
        <div style={{ marginTop: "20rem" }}>
          {loadingPage ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Oval
                height={80}
                width={80}
                color="var(--blue-color)"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--blue-color)"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <EmployeeDraftProjection>
              <ButtonBlue
                onClick={() =>
                  history.push("/employee-dashboard-projection-expenditure-new")
                }
                text={`Create Expenditure`}
              >
                <FaPlus style={{ marginRight: "15px" }} />
              </ButtonBlue>
            </EmployeeDraftProjection>
          )}
        </div>
      )}
      {/* </EmployeeExpenseProjectionIndex> */}

      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.pendingOverview}>
        <EmpExpProjectionOverview
          setShowModal={setShowModal}
          updateText={
            user?.permissions?.includes("edit expense projection") && "update"
          }
          onUpdate={() => {
            handleUpdate(singleChild);
          }}
          onRecall={() => {
            setModalView(true);
            setIndexNo(false);
            actionCtx?.setIsModalOut(true);
            setSingleChild(singleChild);
            setShowModal((prev) => {
              return { ...prev, recall: true, pendingOverview: false };
            });
          }}
          details={singleChild}
          rejectText={user?.permissions?.includes(
            "approve/reject expense projection"
          )}
          onReject={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, reject: true, pendingOverview: false };
            });
          }}
          onComment={() => {
            // // console.log(singleChild?.id);
            setModalView(true);
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, pendingOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          onApprove={() => {
            setModalView(true);
            setShowModal((prev) => {
              actionCtx?.setIsModalOut(true);
              return { ...prev, approve: true, pendingOverview: false };
            });
          }}
          approveBtn={user?.permissions?.includes(
            "approve/reject expense projection"
          )}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, pendingOverview: false };
            });
            setIndexNo(false);
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmpExpProRejectModal
          // comments
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return {
                ...prev,
                reject: false,
                pendingOverview: modalView && true,
              };
            });
          }}
          onClose={() =>
            setShowModal((prev) => {
              actionCtx?.setIsModalOut(false);
              return { ...prev, reject: false };
            })
          }
          // onRefreshCard={handleGetExpenditureData}
          // onDispatch={() => {
          //   const obj = {
          //     org_id: actionCtx?.activeOrg?.id,
          //     dept: dept?.id,
          //     status: selectStat?.value,
          //     // filter_submitted: true,
          //   };
          //   dispatch(getExpenditureProjectionById(obj));
          // }}
          onDispatch={() => {
            (activeStat?.one && handleGetExpenditureData("")) ||
              (activeStat?.two && handleGetExpenditureData("approved")) ||
              (activeStat?.three && handleGetExpenditureData("pending")) ||
              (activeStat?.four && handleGetExpenditureData("rejected"));
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      {/* <Modal visible={showModal.rejectTwo}>
        <EmpExpProRejectModal
          // comments
          details={singleChild}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false };
            })
          }
        />
      </Modal> */}
      {/* modal end */}
      <Modal visible={showModal.approve}>
        <EmpExpProApproveOverview
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                approve: false,
                pendingOverview: modalView && true,
              };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
          }}
          // onRefreshCard={handleGetExpenditureData}
          // onDispatch={() => {
          //   const obj = {
          //     org_id: actionCtx?.activeOrg?.id,
          //     dept: dept?.id,
          //     status: selectStat?.value,
          //     // filter_submitted: true,
          //   };
          //   dispatch(getExpenditureProjectionById(obj));
          // }}
          onDispatch={() => {
            (activeStat?.one && handleGetExpenditureData("")) ||
              (activeStat?.two && handleGetExpenditureData("approved")) ||
              (activeStat?.three && handleGetExpenditureData("pending")) ||
              (activeStat?.four && handleGetExpenditureData("rejected"));
          }}
          // onRefreshCard
        />
      </Modal>
      {/* modal end */}
      {/* modal end */}
      <Modal visible={showModal.comment}>
        <EmpExpProjectionComentModal
          details={singleChild}
          onCancel={() => {
            setModalView(false);
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                comment: false,
                pendingOverview:
                  singleChild?.status === "pending" && modalView && true,
                approveOverview:
                  singleChild?.status === "approved" && modalView && true,
                rejectOverview:
                  singleChild?.status === "rejected" && modalView && true,
              };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmpExpApprovedProjectionOverview
          details={singleChild}
          setShowModal={setShowModal}
          rejectText={
            user?.permissions?.includes("approve/reject expense projection")
              ? true
              : false
          }
          onReject={() => {
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, reject: true, approveOverview: false };
            });
          }}
          onComment={() => {
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            });
            setModalView(true);
            handleGetCommentData(singleChild?.id);
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
            setModalView(false);
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.rejectOverview}>
        <EmpExpRejectProjectionOverview
          details={singleChild}
          setShowModal={setShowModal}
          onUpdate={() => {
            localStorage.setItem(
              "single-expenditure-projection-id",
              singleChild?.id
            );
            history.push("/employee-dashboard-projection-expenditure-update");
          }}
          rejectText
          onComment={() => {
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          onApprove={() =>
            setShowModal((prev) => {
              return { ...prev, approve: true, rejectOverview: false };
            })
          }
          onCancel={() => {
            setModalView(false);
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInRight" visible={showModal.approve_all}>
        <FinalApprovalModal
          allApprovalStatus={allApprovalStatus}
          // detail={expenditure_projection_single?.expenditure?.data[0]}
          allId={allApproveId}
          onFinish={() => {
            actionCtx?.setIsModalOut(false);
            handleGetExpenditureData();
            setShowModal({
              pendingOverview: false,
              approveOverview: false,
              rejectOverview: false,
              approve: false,
              reject: false,
              comment: false,
              approve_all: false,
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve_all: false };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* modal start */}
      <Modal effect="fadeInRight" visible={showModal.recall}>
        <ExpenditureRecallModal
          details={singleChild}
          loading={recallLoader}
          onClose={() => {
            setModalView(false);
            setShowModal((prev) => {
              return {
                ...prev,
                recall: false,
                pendingOverview: modalView && true,
              };
            });
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
          }}
          onSubmit={() => {
            handleRecallProjection("", singleChild?.id);
          }}
        />
      </Modal>
      {/* modal end */}
      {/*EXPORT TO CSV  */}
    </>
  );
};

export default EmployeeSubmitProjection;
