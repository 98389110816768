import React, { useContext, useEffect, useState } from "react";
import BudgetMain from "..";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import CommonExpenditureLink from "../expenditure-rev/commonLink";
import Modal from "react-awesome-modal";
import AddExpenseLimitModal from "../modals/AddExpenseLimitModal";
import UpdateExpenseLimitModal from "../modals/UpdateExpenseLimitModal";
import DeleteExpenseLimitModal from "../modals/DeleteExpenseLimitModal";
import ActionContext from "../../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import {
  getExpenseLimitByFilter,
  getExpenseLimitPagination,
  getExpenseLimitWithPagination,
} from "../../../../../../redux/budget-setting/expense-limit/expenseLimit";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  formatNumber,
  // FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import { getDepartmentsBySubsidiary } from "../../../../../../redux/department/deparment";

const tableHeadList = [
  "Fiscal Year",
  "Subsidiary",
  "Department",
  "Limit",
  "Action",
];

const ExpenseLimit = () => {
  const [indexNo, setIndexNo] = useState("");
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  const { meta, links, loadingPage, expense_limits } = useSelector(
    (state) => state.expense_limit
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const handleFinish = () => {
    actionCtx?.setIsModalOut(false);
    setShowModal((prev) => {
      return { add: false, edit: false, delete: false };
    });
    setRefreshTable(!refreshTable);
  };

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
      cur_id: actionCtx?.currencyValue?.id  || "",
    };
    if (user?.organizations?.length > 0) {
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      dispatch(getFiscalYears(actionCtx?.activeOrg?.id));
      dispatch(getExpenseLimitWithPagination(obj));
    }
    // eslint-disable-next-line
  }, [refreshTable, actionCtx?.currencyValue]);

  const handleUpdate = (param) => {
    // console.log(param);
    // return;
    const obj = {
      ...param,
      subsidiary_id: {
        label: param?.subsidiary?.name,
        value: param?.subsidiary?.id,
      },
      department_id: {
        label: param?.department?.name,
        value: param?.department?.id,
      },
      fiscal_year_id: {
        label: param?.fiscal_year?.title,
        value: param?.fiscal_year?.id,
      },
    };
    setSingleChild(obj);
    setShowModal((prev) => {
      return { ...prev, edit: true };
    });
  };

  const handleEditSingleChild = (e, param) => {
    // e.preventDefault();
    if (param === "subsidiary") {
      setSingleChild((prev) => {
        return { ...prev, subsidiary_id: e };
      });
    }
    if (param === "department") {
      setSingleChild((prev) => {
        return { ...prev, department_id: e };
      });
    }
    if (param === "fiscal") {
      // console.log(e);
      setSingleChild((prev) => {
        return { ...prev, fiscal_year_id: e };
      });
    }
    if (param === "limit") {
      // console.log(e);
      setSingleChild((prev) => {
        return { ...prev, limit: e };
      });
    }
  };

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getExpenseLimitPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
      cur_id: actionCtx?.currencyValue?.id  || "",
    };
    // console.log(obj);
    user?.organizations?.length > 0 &&
      dispatch(getExpenseLimitWithPagination(obj));
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: "",
        org_id: actionCtx?.activeOrg?.id,
        cur_id: actionCtx?.currencyValue?.id  || "",
      };
      // console.log(obj);
      dispatch(getExpenseLimitByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink title={`Expense Limit`} />
        <div className="table-box-budget">
          {/* table box start */}
          <ActualReuseableTable
            fromPage={meta?.from || 1}
            toPage={meta?.to || expense_limits?.length}
            totalPage={meta?.total || expense_limits?.length}
            nextPage={links?.next}
            prevPage={links?.prev}
            onNextPage={() => handlePagination(links?.next)}
            onPrevPage={() => handlePagination(links?.prev)}
            onSearchTable={(e) => {
              setSearch(e);
            }}
            perPageValue={actionCtx?.perPage?.value}
            setPerPageValue={(e) => {
              // setPerpageVal(e);
              handleSortPerPage(e.value);
            }}
            dontShowSelect
            tableTitle={`Limit`}
            tableTitleSpan={`(2)`}
            addBtntext={`New Limit`}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            action
            onCreateNew={() => {
              actionCtx?.setIsModalOut(true);
              setShowModal((prev) => {
                return { ...prev, add: true };
              });
            }}
            loading={loadingPage && expense_limits?.length < 1}
            loadingNum={6}
          >
            {expense_limits.map((chi, idx) => {
              const { fiscal_year, subsidiary, department, limit } = chi;
              return (
                <TableBodyRow
                  loading={loadingPage}
                  key={idx}
                  one={fiscal_year?.title}
                  two={subsidiary?.name}
                  three={department?.name}
                  four={formatNumber(String(limit || 0))}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  deleteText={`delete`}
                  updateText={`modify`}
                  onUpdate={() => {
                    actionCtx?.setIsModalOut(true);
                    handleUpdate(chi);
                    dispatch(getDepartmentsBySubsidiary(chi?.subsidiary?.id));
                    setShowModal((prev) => {
                      return { ...prev, edit: true };
                    });
                  }}
                  onDelete={() => {
                    setSingleChild(chi);
                    setShowModal((prev) => {
                      return { ...prev, delete: true };
                    });
                  }}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
      </BudgetMain>

      <Modal effect="fadeInUp" visible={showModal.add}>
        <AddExpenseLimitModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, add: false };
            });
          }}
          onFinish={handleFinish}
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.edit}>
        <UpdateExpenseLimitModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, edit: false };
            });
            setSingleChild({});
          }}
          onFinish={handleFinish}
          onEdit={handleEditSingleChild}
          detail={singleChild}
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.delete}>
        <DeleteExpenseLimitModal
          onFinish={handleFinish}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
            setSingleChild({});
          }}
          detail={singleChild}
        />
      </Modal>
    </>
  );
};

export default ExpenseLimit;
