import React, { useContext, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH, FaTimes } from "react-icons/fa";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import imgOne from "../../../../../../assets/Unknown_person.jpeg";
import {
  formatNumber,
  formatNumberWithComma,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import EmployeeOverviewTableBodyRow from "../../../../../../employee-folder/component/pages/dashboard/home/modals/Reimbursement/overviewTableRow";
import ActionContext from "../../../../../../context/ActionContext";
// import html2canvas from "html2canvas";
import {
  getRevenueProJectionActivityLog,
  getRevProjectionPdf,
} from "../../../../../../redux/projections/RevenueProjection";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

const OverviewCardExpenseModal = ({
  onCancel,
  onApprove,
  onReject,
  onComment,
  detail,
}) => {
  const { revenue_projection_overview } = useSelector(
    (state) => state.revenue_projection_chart
  );
  const actionCtx = useContext(ActionContext);
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });
  const [showActions, setShowActions] = useState(false);

  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  const screenShotRef = useRef();
  const dispatch = useDispatch();
  const handleDowload = async () => {
    // html2canvas(screenShotRef.current).then((canvas) => {
    //   const link = document?.createElement("a");
    //   link.download = "expenditure_projection.png";
    //   link.href = canvas.toDataURL("image/png");
    //   link.click();
    // });

    const obj = {
      id: detail?.id,
      type: "revenue_projection",
      cur_id: actionCtx?.currencyValue?.id || "",
      // user_id: 
    };
    const data = await dispatch(getRevProjectionPdf(obj));
    // console.log(data);
    downloadCsv(data.payload.url);
    if (data?.payload?.status === 200) {
      downloadCsv(data.payload.data.url);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const [detailToShow, setDetailToShow] = useState("overview");

  const approvedIcon = (
    <svg
      className="img"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="15.665"
        fill="#EBF8F5"
        stroke="#EBFFF1"
        stroke-width="0.67"
      />
      <path
        d="M13.5001 19.475L10.0251 16L8.8418 17.175L13.5001 21.8333L23.5001 11.8333L22.3251 10.6583L13.5001 19.475Z"
        fill="#119C2B"
      />
    </svg>
  );

  const rejectIcon = (
    <svg
      className="img"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="15.665"
        fill="#FFEFEB"
        stroke="#FFEFEB"
        stroke-width="0.67"
      />
      <path
        d="M21.8327 11.3417L20.6577 10.1667L15.9993 14.825L11.341 10.1667L10.166 11.3417L14.8243 16L10.166 20.6583L11.341 21.8333L15.9993 17.175L20.6577 21.8333L21.8327 20.6583L17.1743 16L21.8327 11.3417Z"
        fill="#CC0905"
      />
    </svg>
  );

  // const commentIcon = (
  //   <svg
  //     className="img"
  //     width="32"
  //     height="32"
  //     viewBox="0 0 32 32"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <circle cx="16" cy="16" r="16" fill="#ECF7FF" />
  //     <circle
  //       cx="16"
  //       cy="16"
  //       r="15.665"
  //       stroke="#D7EDFF"
  //       stroke-opacity="0.5"
  //       stroke-width="0.67"
  //     />
  //     <path
  //       d="M22.3506 20.2994C22.3056 20.1388 22.4047 19.9158 22.4993 19.7552C22.5282 19.7075 22.5598 19.6613 22.5939 19.617C23.4029 18.4265 23.8357 17.0244 23.837 15.5894C23.8506 11.4771 20.3462 8.1409 16.0132 8.1409C12.2341 8.1409 9.08113 10.6877 8.34244 14.0685C8.23193 14.5696 8.17606 15.081 8.17578 15.5939C8.17578 19.7106 11.545 23.1361 15.878 23.1361C16.5672 23.1361 17.4951 22.9309 18.004 22.7926C18.513 22.6544 19.0175 22.4715 19.1481 22.4224C19.282 22.3724 19.4239 22.3468 19.567 22.3466C19.7231 22.346 19.8778 22.3763 20.0219 22.4358L22.5759 23.3323C22.6318 23.3558 22.691 23.3708 22.7515 23.3769C22.8471 23.3769 22.9387 23.3393 23.0063 23.2724C23.0739 23.2055 23.1119 23.1147 23.1119 23.0201C23.1087 22.9793 23.1012 22.9389 23.0893 22.8997L22.3506 20.2994Z"
  //       stroke="#004BFF"
  //       stroke-width="1.62"
  //       stroke-miterlimit="10"
  //       stroke-linecap="round"
  //     />
  //   </svg>
  // );

  // const reimbursedIcon = (
  //   <svg
  //     className="img"
  //     width="32"
  //     height="32"
  //     viewBox="0 0 32 32"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <circle cx="16" cy="16" r="16" fill="#F1F2F3" />
  //     <circle
  //       cx="16"
  //       cy="16"
  //       r="15.665"
  //       stroke="#D7EDFF"
  //       stroke-opacity="0.5"
  //       stroke-width="0.67"
  //     />
  //     <g clip-path="url(#clip0_13428_391050)">
  //       <path
  //         d="M16 6C13.2417 6 10.7575 7.1275 8.95083 8.935L6.88417 6.86833L6 12.9858L12.1975 12.1808L10.1233 10.1075C11.63 8.60083 13.7058 7.66667 16 7.66667C20.595 7.66667 24.3333 11.405 24.3333 16C24.3333 20.595 20.595 24.3333 16 24.3333C12.7733 24.3333 9.97583 22.4867 8.59167 19.7975L7.16083 20.6692C8.8375 23.8383 12.165 26 16 26C21.5225 26 26 21.5225 26 16C26 10.4775 21.5225 6 16 6Z"
  //         fill="#5552D9"
  //       />
  //     </g>
  //     <defs>
  //       <clipPath id="clip0_13428_391050">
  //         <rect
  //           width="20"
  //           height="20"
  //           fill="white"
  //           transform="translate(6 6)"
  //         />
  //       </clipPath>
  //     </defs>
  //   </svg>
  // );

  // const reminderIcon = (
  //   <svg
  //     className="img"
  //     width="32"
  //     height="32"
  //     viewBox="0 0 32 32"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <circle cx="16" cy="16" r="16" fill="#FBF6D9" />
  //     <circle
  //       cx="16"
  //       cy="16"
  //       r="15.665"
  //       stroke="#D7EDFF"
  //       stroke-opacity="0.5"
  //       stroke-width="0.67"
  //     />
  //     <path
  //       d="M16.0007 7.66351C19.3756 7.66351 22.1312 10.3258 22.2472 13.7044L22.2507 13.9135V17.3277L23.4007 19.9577C23.4582 20.0893 23.4879 20.2314 23.4879 20.375C23.4879 20.9503 23.0215 21.4167 22.4462 21.4167L18.5007 21.4179C18.5007 22.7986 17.3814 23.9179 16.0007 23.9179C14.6693 23.9179 13.5809 22.8771 13.5049 21.5648L13.5003 21.416L9.56304 21.4167C9.42026 21.4167 9.279 21.3873 9.14804 21.3304C8.62037 21.1012 8.37841 20.4877 8.60761 19.96L9.75065 17.3284V13.9134C9.75115 10.4511 12.5441 7.66351 16.0007 7.66351ZM17.2503 21.416L14.7507 21.4179C14.7507 22.1083 15.3103 22.6679 16.0007 22.6679C16.6504 22.6679 17.1844 22.1722 17.2449 21.5383L17.2503 21.416ZM16.0007 8.91351C13.2339 8.91351 11.001 11.1421 11.0007 13.9135V17.5882L9.88067 20.1667H22.1278L21.0007 17.589L21.0007 13.9242L20.9976 13.7366C20.9051 11.042 18.702 8.91351 16.0007 8.91351ZM23.5007 12.875H25.1673C25.5125 12.875 25.7923 13.1548 25.7923 13.5C25.7923 13.8164 25.5572 14.0779 25.2521 14.1193L25.1673 14.125H23.5007C23.1555 14.125 22.8757 13.8452 22.8757 13.5C22.8757 13.1836 23.1108 12.9221 23.4158 12.8807L23.5007 12.875ZM6.83398 12.875H8.50065C8.84583 12.875 9.12565 13.1548 9.12565 13.5C9.12565 13.8164 8.89052 14.0779 8.58546 14.1193L8.50065 14.125H6.83398C6.48881 14.125 6.20898 13.8452 6.20898 13.5C6.20898 13.1836 6.44411 12.9221 6.74918 12.8807L6.83398 12.875ZM24.834 8.12499C25.0223 8.37603 24.9931 8.72261 24.7788 8.93916L24.709 8.99999L23.0423 10.25C22.7662 10.4571 22.3744 10.4011 22.1673 10.125C21.979 9.87395 22.0082 9.52737 22.2225 9.31083L22.2923 9.24999L23.959 7.99999C24.2351 7.79289 24.6269 7.84885 24.834 8.12499ZM8.04232 7.99999L9.70898 9.24999C9.98513 9.4571 10.0411 9.84885 9.83398 10.125C9.62688 10.4011 9.23513 10.4571 8.95898 10.25L7.29232 8.99999C7.01618 8.79289 6.96021 8.40114 7.16732 8.12499C7.37442 7.84885 7.76618 7.79289 8.04232 7.99999Z"
  //       fill="#FFC700"
  //     />
  //   </svg>
  // );

  // const createIcon = (
  //   <svg
  //     className="img"
  //     width="32"
  //     height="32"
  //     viewBox="0 0 32 32"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <circle cx="16" cy="16" r="16" fill="#F1F2F3" />
  //     <circle
  //       cx="16"
  //       cy="16"
  //       r="15.665"
  //       stroke="#D7EDFF"
  //       stroke-opacity="0.5"
  //       stroke-width="0.67"
  //     />
  //     <path
  //       d="M13.5 12L20 16L13.5 20V12ZM12.0176 9.51852V22.4815L22.2028 16L12.0176 9.51852Z"
  //       fill="#07A287"
  //     />
  //   </svg>
  // );

  const [logList, setLogList] = useState([]);
  const [loadingLog, setLoadingLog] = useState(false);
  const handleGetActivityLog = async (id) => {
    setLoadingLog(true);
    const obj = {
      id: id,
    };
    const data = await dispatch(getRevenueProJectionActivityLog(obj));
    if (data?.payload?.success) {
      setLoadingLog(false);
      setLogList(data?.payload?.data);
    }
  };

  return (
    <div className="overview-card-modal-wrap">
      <div className="top-box">
        <div className="title-box">
          <p
            onClick={() => {
              setDetailToShow("overview");
            }}
            className={`title ${detailToShow === "overview" && "title-active"}`}
          >
            Overview
          </p>
          <p
            onClick={() => {
              setDetailToShow("activity");
              handleGetActivityLog(detail?.id);
            }}
            className={`title ${detailToShow === "activity" && "title-active"}`}
          >
            Activity Log
          </p>
        </div>
        {detail?.status !== "approved" && detail?.current_approver && (
          <ButtonBlue onClick={onApprove} text={`Approve`} />
        )}
        <div
          onClick={() => setShowActions(!showActions)}
          className="ellipse-box"
        >
          <FaEllipsisH className="icon" />
          {/* action box start */}
          <div className={`action-box ${showActions && "action-box-active"}`}>
            {detail?.status !== "rejected" && (
              <p onClick={onReject}>
                <span>Reject</span>
              </p>
            )}
            <p onClick={onComment}>
              <span>View Comments</span>
            </p>
            <p
              onClick={() => {
                handleDowload();
                setShowActions(!showActions);
              }}
            >
              <span>Export as PDF</span>
            </p>
          </div>
          {/* action box end */}
        </div>
        <div
          onClick={() => {
            setShowActions(false);
            onCancel();
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      {/* overview start */}
      <div ref={screenShotRef} className="content-box">
        {detailToShow === "overview" && (
          <>
            {" "}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("one")}
                className="title-drop-control-box"
              >
                <p className="title">Budget Owner Details</p>
                <div className="icon-box">
                  {!showBox.one && <FaAngleDown className="icon" />}
                  {showBox.one && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.one && (
                <div className="content-wrap">
                  <div className="three-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Product</p>
                      <p className="value">
                        {revenue_projection_overview?.product?.name}
                      </p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Verticals</p>
                      <p className="value">
                        {revenue_projection_overview?.vertical?.name}
                      </p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Units</p>
                      <p className="value">
                        {formatNumberWithComma(
                          String(revenue_projection_overview?.unit)
                        )}
                      </p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="two-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Unit Price</p>
                      <p className="value">
                        {formatNumberWithComma(
                          String(revenue_projection_overview?.unit_price || 0)
                        )}
                      </p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Total Gp</p>
                      <p className="value">
                        {formatNumberWithComma(
                          String(
                            revenue_projection_overview?.total_gross_profit || 0
                          )
                        )}
                      </p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="one-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Description</p>
                      <p className="value">
                        {revenue_projection_overview?.description}
                      </p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="two-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Date Created</p>
                      <p className="value">
                        {revenue_projection_overview?.created_at}
                      </p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Last Updated</p>
                      <p className="value">
                        {revenue_projection_overview?.updated_at}
                      </p>
                    </div>
                    {/* box end */}
                  </div>
                  {/* created by wrap start */}
                  <div className="one-box">
                    <div className="box">
                      <p className="text">Created by</p>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="img-text-box"
                      >
                        {revenue_projection_overview?.createdBy
                          ?.profile_picture && (
                          <figure
                            style={{
                              marginRight: "1rem",
                              width: "3rem",
                              height: "3rem",
                            }}
                            className="img-box"
                          >
                            <img
                              src={
                                revenue_projection_overview?.createdBy
                                  ?.profile_picture
                              }
                              alt=""
                              className="img"
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </figure>
                        )}
                        <div className="text-box">
                          <p className="value">
                            {revenue_projection_overview?.createdBy?.full_name}
                          </p>
                          <p style={{ marginBottom: "unset" }} className="text">
                            {revenue_projection_overview?.createdBy?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* created by wrap end */}
                </div>
              )}
            </>
            {/* title drop control box end */}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("two")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem" }}
              >
                <p className="title">Monthly Breakdown</p>
                <div className="icon-box">
                  {!showBox.two && <FaAngleDown className="icon" />}
                  {showBox.two && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.two && (
                <div className="content-wrap">
                  {detail?.monthly_breakdown[0]?.map((chi, idx) => {
                    const { month_name, value } = chi;
                    return (
                      <>
                        <EmployeeOverviewTableBodyRow
                          key={idx}
                          one={month_name}
                          two={formatNumber(
                            String(value)
                          )}
                          three={formatNumber(String(value))}
                        />
                      </>
                    );
                  })}
                </div>
              )}
            </>
            {/* title drop control box end */}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("three")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem" }}
              >
                <p className="title">Approval Flow</p>
                <div className="icon-box">
                  {!showBox.three && <FaAngleDown className="icon" />}
                  {showBox.three && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.three && (
                <div className="content-wrap">
                  {detail?.approval_flow?.map((chi, idx) => {
                    const { step, user, status } = chi;
                    return (
                      <>
                        <EmployeeOverviewTableBodyRow
                          key={idx}
                          one={step?.toString().padStart(2, "0")}
                          userOne
                          userName={
                            user?.first_name
                              ? user?.first_name + " " + user?.last_name
                              : "----"
                          }
                          userEmail={user?.email}
                          userImg={user?.profile_picture || imgOne}
                          status={
                            status === "approved"
                              ? "Approved"
                              : status === "pending"
                              ? "Pending"
                              : status === "rejected"
                              ? "Rejected"
                              : "pending"
                          }
                        />
                      </>
                    );
                  })}
                </div>
              )}
            </>
            {/* title drop control box end */}
          </>
        )}
        {/* over view end */}
        {/* activity log start -------- */}
        {detailToShow === "activity" && (
          <>
            <div className="activity-log-wrap-box">
                {/* box start */}
                {detail?.activities?.map((chi, idx) => {
                const { causer, title, type, created_at, icon } = chi;
                if (logList?.length < 1 ) {
                  return(
                    <div key={idx} className="log-box">
                    <>
                      <Skeleton width={35} height={35} circle />
                    </>
                    <div className="img-wrap">
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                    </div>
                    <div className="dash"></div>
                    {/* detail wrap start */}
                    <div className="log-detail-wrap">
                      <>
                        <Skeleton width={100} height={15} />
                      </>
                      <>
                        <Skeleton width={170} height={12} />
                      </>
                      <>
                        <Skeleton width={100} height={12} />
                      </>
                      <>
                        <Skeleton width={100} height={12} />
                      </>
                    </div>
                    {/* detail wrap end */}
                  </div>
                  )
                }
                return (
                  <div key={idx} className="log-box">
                    {loadingLog ? (
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                    ) : (
                      <div className="num">
                        <span>
                        {(detail?.activities.length - idx )  < 10
                            ? `0${detail?.activities.length - idx }`
                            : detail?.activities.length - idx }
                        </span>
                      </div>
                    )}
                    <div className="img-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={35} height={35} circle />
                        </>
                      ) : (
                        <figure className="img-box">
                          <img src={icon} alt="" className="img" />
                        </figure>
                      )}
                    </div>
                    <div className="dash"></div>
                    {/* detail wrap start */}
                    <div className="log-detail-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={15} />
                        </>
                      ) : (
                        <p className="title">{title || "---"}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={170} height={12} />
                        </>
                      ) : (
                        <p className="initiator">{`${type || ""} by: ${
                          causer?.full_name
                        }`}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="date">{moment(created_at).format("MM-DD-YYYY HH:mm:ss A")}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="time">{created_at?.split(" ")[1]}</p>
                      )}
                    </div>
                    {/* detail wrap end */}
                  </div>
                );
              })}
              {/* box end */}
            </div>
          </>
        )}
        {/* activity log end ----------- */}
      </div>
      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default OverviewCardExpenseModal;
