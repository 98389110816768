import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import "../../../../../styles/dashboard/admin-settings/PreferenceSettings.css";
// import ButtonBlue from "../../../../buttons/ButtonBlue";
import lockIcon from "../../../../../assets/password-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import { ResetPasswordDashboard } from "../../../../../redux/user";

const UpdatePasswordModal = ({ children, onCancel }) => {
  const [showPassword, setShwoPassord] = useState(false);
  const [details, setDetails] = useState({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const { loading } = useSelector((state) => state.user);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    // console.log(details);
    const data = await dispatch(ResetPasswordDashboard(details));
    if (data?.payload?.success) {
      onCancel();
    }
  };

  return (
    <div className="update-password-wrap">
      {children}
      <div className="title-box">
        <p className="title">Update Password</p>
        <div
          onClick={() => {
            setDetails({
              old_password: "",
              new_password: "",
              new_password_confirmation: "",
            });
            onCancel();
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      {/* content box start */}
      <div className="content-box">
        <p className="text">
          Any change or altercation in password, would require users to login
          with the new or updated password in order to access their dashboard.
        </p>
        {/* form group start */}
        <div className="form-group">
          <label htmlFor="current_password" className="form-label">
            <figure className="img-box">
              <img src={lockIcon} className="img" alt="" />
            </figure>
          </label>
          <input
            type={showPassword ? "text" : "Password"}
            name="old_password"
            id="current_password"
            className="form-input white-black-color"
            placeholder="*************"
            onChange={handleChange}
            value={details.old_password}
          />
          <div
            className={`status ${details.old_password && "status-active"}`}
          ></div>
          <p
            className={`placeholder  white-black-bg white-black-color ${
              details.old_password && "placeholder-active"
            }`}
          >
            Current Password
          </p>
          <div
            onClick={() => setShwoPassord(!showPassword)}
            className="show-hide"
          >
            {showPassword ? (
              <FaEyeSlash className="icon" />
            ) : (
              <FaEye className="icon" />
            )}
          </div>
        </div>
        {/* form group end */}
        {/* form group start */}
        <div className="form-group">
          <label htmlFor="new_password" className="form-label">
            <figure className="img-box">
              <img src={lockIcon} className="img" alt="" />
            </figure>
          </label>
          <input
            type={showPassword ? "text" : "Password"}
            name="new_password"
            id="new_password"
            className="form-input white-black-color"
            placeholder="*************"
            onChange={handleChange}
            value={details.new_password}
          />
          <div
            className={`status ${details.new_password && "status-active"}`}
          ></div>
          <p
            className={`placeholder  white-black-bg white-black-color ${
              details.new_password && "placeholder-active"
            }`}
          >
            New Password
          </p>
          <div
            onClick={() => setShwoPassord(!showPassword)}
            className="show-hide"
          >
            {showPassword ? (
              <FaEyeSlash className="icon" />
            ) : (
              <FaEye className="icon" />
            )}
          </div>
        </div>
        {/* form group end */}
        {/* form group start */}
        <div className="form-group">
          <label htmlFor="confirm_password" className="form-label">
            <figure className="img-box">
              <img src={lockIcon} className="img" alt="" />
            </figure>
          </label>
          <input
            type={showPassword ? "text" : "Password"}
            name="new_password_confirmation"
            id="confirm_password"
            className="form-input white-black-color"
            placeholder="*************"
            onChange={handleChange}
            value={details.new_password_confirmation}
          />
          <div
            className={`status ${
              details.new_password_confirmation && "status-active"
            }`}
          ></div>
          <p
            className={`placeholder  white-black-bg white-black-color ${
              details.new_password_confirmation && "placeholder-active"
            }`}
          >
            confirm Password
          </p>
          <div
            onClick={() => setShwoPassord(!showPassword)}
            className="show-hide"
          >
            {showPassword ? (
              <FaEyeSlash className="icon" />
            ) : (
              <FaEye className="icon" />
            )}
          </div>
        </div>
        {/* form group end */}
      </div>
      {/* content box end */}
      {/* btn start */}
      <div className="btn-box">
        {!loading ? (
          // <ButtonBlue onClick={handleSubmit} text={`Update Password`} />
          <input
            onClick={handleSubmit}
            disabled={
              !details?.new_password ||
              !details?.old_password ||
              !details?.new_password_confirmation
            }
            type="submit"
            value="Update Password"
            className={`form-group form-submit ${
              details?.new_password &&
              details?.old_password &&
              details?.new_password_confirmation &&
              "form-submit-active"
            }`}
          />
        ) : (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
      {/* btn end */}
    </div>
  );
};

export default UpdatePasswordModal;
