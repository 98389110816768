import React, { useContext } from "react";
// import { useEffect } from "react";
import { FaAngleDoubleUp, FaCheck } from "react-icons/fa";
// import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ActionContext from "../../context/ActionContext";
import "../../styles/dashboard/WelcomeBox.css";
import ProgressBar from "./ProgressBar";

const WelcomeBox = ({ onClose }) => {
  // const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const list = [
    {
      name: "Add Subsidiary",
      link: "/dashboard-administration-admin-settings-add-subsidary",
    },
    {
      name: "Add Branch",
      link: "/dashboard-administration-admin-settings-add-branch",
    },
    {
      name: "Add Department",
      link: "/dashboard-administration-admin-settings-add-department",
    },
    {
      name: "Add Employee",
      link: "/dashboard-administration-admin-settings-add-employee",
    },
    {
      name: "Set Approval Flow",
      link: "/dashboard-general-approvals-revenue-projection",
    },
  ];

  // useEffect(() => {
  //   getUserDetailsInfo()
  // }, [])

  // const getUserDetailsInfo = async() => {
  //    const subData = await dispatch(getSubsidiaries(actionCtx?.activeOrg?.id))
  //   if(subData?.payload?.sussess){
  //     console.log(subData);
  //     console.log(subData?.payload?.data?.subsidiaries);
  //   }
  // }

  return (
    <div className="welcome-box-wrap">
      {/* arrow box start */}
      <div className="arrow-box">
        <FaAngleDoubleUp className="icon" onClick={onClose} />
      </div>
      {/* arrow box end */}
      <p className="title">Welcome to Revvex !</p>
      <p className="text">
        Get your account running by completing the following steps:
      </p>
      {/* progress box start */}
      <div className="progress-box">
        <p className="percentage">{`${actionCtx?.activeOrg?.check_list?.percentage || 0}%`}</p>
        <ProgressBar
          bgcolor={`var(--blue-color)`}
          completed={actionCtx?.activeOrg?.check_list?.percentage || 0}
        />
      </div>
      {/* progress box end */}
      {/* list box start */}
      <div className="list-box">
        {list.map((child, index) => {
          const { name, link } = child;
          if(actionCtx?.activeOrg?.type === "single" && name === "Add Subsidiary"){
            return <React.Fragment key={index}></React.Fragment>
          }
          return (
            <div key={index} className="item">
              <span
                className={`check-box ${
                  name === "Add Subsidiary" &&
                  actionCtx?.activeOrg?.check_list?.add_subsidiary
                    ? "check-box-active" :  name === "Add Branch" &&
                    actionCtx?.activeOrg?.check_list?.add_branch
                      ? "check-box-active" :  name === "Add Department" &&
                      actionCtx?.activeOrg?.check_list?.add_department
                        ? "check-box-active" :  name === "Add Employee" &&
                        actionCtx?.activeOrg?.check_list?.add_employee
                          ? "check-box-active" :  name === "Set Approval Flow" &&
                          actionCtx?.activeOrg?.check_list?.set_approval
                            ? "check-box-active"
                    : ""
                }`}
              >
                <FaCheck className="icon" />
              </span>
              <Link className="link" to={link}>
                {name}
              </Link>
            </div>
          );
        })}
      </div>
      {/* list box end */}
      {/* dismiss box start */}
      <p onClick={onClose} className="dismiss">
        dismiss
      </p>
      {/* dismiss box end */}
    </div>
  );
};

export default WelcomeBox;
