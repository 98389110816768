import React, { useEffect, useState } from "react";
import { FaAngleRight, FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import "../../../../../styles/dashboard/admin-settings/PreferenceSettings.css";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import Select from "react-select";
import lockIcon from "../../../../../assets/password-icon.svg";
import Modal from "react-awesome-modal";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { useContext } from "react";
import ActionContext from "../../../../../context/ActionContext";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import { reactSelectStyle } from "../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import {
  getUserPreference,
  updateUserPreference,
} from "../../../../../redux/preference/preference";
// import { getUserDetails } from "../../../../../redux/user";

const selectListOne = [
  { label: "Sync with system settings", value: "Sync with system settings" },
  { label: "Light ", value: "Light" },
  { label: "Dark", value: "Dark" },
];

const selectLisTwo = [
  { label: "English (UK)", value: "English (UK)" },
  { label: "English (American)", value: "English (American)" },
  { label: "Chinese (Mandarin)", value: "Chinese (Mandarin)" },
];

const PreferenceSettings = () => {
  const { preference, loading } = useSelector((state) => state.preference);
  const actionCtx = useContext(ActionContext);
  // const { loading } = useSelector((state) => state.preference);
  const dispatch = useDispatch();
  const [selectOne, setSelectOne] = useState(null);
  const [selectTwo, setSelectTwo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [showPassword, setShwoPassord] = useState(false);
  const [details, setDetails] = useState({
    organization_id: "",
    display: "",
    language: "",
    user_email: false,
    user_is_deleted_email: false,
    organization_is_created_email: false,
    organization_is_deleted_email: false,
    user_is_created: false,
    updated_userprofile: false,
    user_is_deleted: false,
    role_is_created: false,
    permission_is_created: false,
  });

  useEffect(() => {
    getPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.activeOrg]);

  const getPreferences = async () => {
    const data = await dispatch(getUserPreference(actionCtx?.activeOrg?.id));
    // console.log(data.payload);
    if (data?.payload) {
      if (data?.payload?.data?.success) {
        const preferVal = data?.payload.data?.data;
        // console.log(preferVal);
        if (Object.keys(preferVal)?.length > 0) {
          // console.log("yes");
          const emailPref = preferVal?.email_preference[0];
          const userPref = preferVal?.user_preference[0];
          // console.log(userPref);
          const obj = {
            ...details,
            display: { label: preferVal?.display, value: preferVal?.display },
            language: {
              label: preferVal?.language,
              value: preferVal?.language,
            },
            user_email: emailPref?.user_email,
            user_is_deleted_email: emailPref?.user_is_deleted,
            organization_is_created_email: emailPref?.organization_is_created,
            organization_is_deleted_email: emailPref?.organization_is_deleted,
            user_is_created: userPref?.user_is_created,
            updated_userprofile: userPref?.updated_userprofile,
            user_is_deleted: userPref?.user_is_deleted,
            role_is_created: userPref?.role_is_created,
            permission_is_created: userPref?.permission_is_created,
          };
          setDetails(obj);
          setSelectOne(obj?.display);
          setSelectTwo(obj?.language);
        }
      }
      return;
    }
    // console.log("no");
    setDetails({
      organization_id: "",
      display: "",
      language: "",
      user_email: false,
      user_is_deleted_email: false,
      organization_is_created_email: false,
      organization_is_deleted_email: false,
      user_is_created: false,
      updated_userprofile: false,
      user_is_deleted: false,
      role_is_created: false,
      permission_is_created: false,
    });
    setSelectOne({
      label: "Sync with system settings",
      value: "Sync with system settings",
    });
    setSelectTwo({ label: "English (UK)", value: "English (UK)" });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      organization_id: actionCtx?.activeOrg?.id,
      display: selectOne?.label,
      language: selectTwo?.label,
      email_preferences: [
        {
          user_email: details?.user_email,
          user_is_deleted: details?.user_is_deleted_email,
          organization_is_created: details?.organization_is_created_email,
          organization_is_deleted: details?.organization_is_deleted_email,
        },
      ],
      user_preferences: [
        {
          user_is_created: details?.user_is_created,
          updated_userprofile: details?.updated_userprofile,
          user_is_deleted: details?.user_is_deleted,
          role_is_created: details?.role_is_created,
          permission_is_created: details?.permission_is_created,
        },
      ],
    };
    // setSelectOne()
    // console.log(obj);
    const data = await dispatch(updateUserPreference(obj));
    if (data?.payload?.success) {
      dispatch(getUserPreference(actionCtx?.activeOrg?.id));
    }
  };

  return (
    <>
      {/* <DashboardLayout goBack pageTitle={`Admin Settings`}> */}
      <div className="profile-setting-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Admin Settings
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() => {
              console.log(preference);
            }}
            className="active text"
          >
            Preferences
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>
        {/* sub title box end */}
        <form action="" className="form" autoComplete="off">
          {/* form submit button start */}
          <div className="submit-box" style={{ marginBottom: "2rem" }}>
            {!loading ? (
              <ButtonBlue onClick={handleSubmit} text={`Update Preference`} />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
          </div>
          {/* form submit button end */}
          {/* form box start */}
          <div className="form-box-preference">
            <p className="title">Display</p>
            <div className="wrap">
              {/* form group start */}
              {/* select box */}
              <div className="box">
                <label style={{ marginBottom: ".5rem" }} htmlFor="">
                  Theme
                </label>
                <Select
                  styles={reactSelectStyle}
                  className="select "
                  placeholder="Sync with system settings"
                  value={selectOne}
                  onChange={(e) => {
                    setSelectOne(e);
                  }}
                  options={selectListOne}
                  isDisabled={true}
                />
              </div>
              {/* select end */}
              {/* form group end */}
              <div className="underline"></div>
              {/* form group start */}
              {/* select box */}
              <div className="box">
                <label style={{ marginBottom: ".5rem" }} htmlFor="">
                  Language
                </label>
                <Select
                  styles={reactSelectStyle}
                  className="select "
                  placeholder="English"
                  value={selectTwo}
                  onChange={(e) => {
                    setSelectTwo(e);
                  }}
                  options={selectLisTwo}
                />
              </div>
              {/* select end */}
              {/* form group end */}
            </div>
          </div>
          {/* form box end */}
          {/* form box start */}
          <div className="form-box-preference">
            <p className="title">Notifications</p>
            <div className="wrap">
              <p className="subtitle">Email Preferences</p>
              <div className="underline"></div>
              {/* check start */}
              <div className="check-label-box">
                <input
                  onChange={(e) =>
                    setDetails((prev) => {
                      return { ...prev, user_email: e.target.checked };
                    })
                  }
                  value={details?.user_email}
                  checked={details?.user_email}
                  type="checkbox"
                  className="check"
                  id="check-email"
                />
                <label htmlFor="check-email" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send me an email notification when i changed my password{" "}
                </label>
              </div>
              {/* check end */}
              {/* check start */}
              <div className="check-label-box">
                <input
                  onChange={(e) =>
                    setDetails((prev) => {
                      return {
                        ...prev,
                        user_is_deleted_email: e.target.checked,
                      };
                    })
                  }
                  value={details?.user_is_deleted_email}
                  checked={details?.user_is_deleted_email}
                  type="checkbox"
                  className="check"
                  id="check-user-delete"
                />
                <label htmlFor="check-user-delete" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send me an email notification when a created user is deleted
                  from the database{" "}
                </label>
              </div>
              {/* check end */}
              {/* check start */}
              <div className="check-label-box">
                <input
                  onChange={(e) =>
                    setDetails((prev) => {
                      return {
                        ...prev,
                        organization_is_created_email: e.target.checked,
                      };
                    })
                  }
                  value={details?.organization_is_created_email}
                  checked={details?.organization_is_created_email}
                  type="checkbox"
                  className="check"
                  id="check-org-created"
                />
                <label htmlFor="check-org-created" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send me an email notification when a new organization is
                  created{" "}
                </label>
              </div>
              {/* check end */}
              {/* check start */}
              <div className="check-label-box">
                <input
                  onChange={(e) =>
                    setDetails((prev) => {
                      return {
                        ...prev,
                        organization_is_deleted_email: e.target.checked,
                      };
                    })
                  }
                  value={details?.organization_is_deleted_email}
                  checked={details?.organization_is_deleted_email}
                  type="checkbox"
                  className="check"
                  id="check-org-delete"
                />
                <label htmlFor="check-org-delete" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send me an email notification when an organization is deleted
                  from the database{" "}
                </label>
              </div>
              {/* check end */}
              <p style={{ marginTop: "3rem" }} className="subtitle sub-two">
                User & Permission Preferences
              </p>
              <div className="underline"></div>
              {/* check start */}
              <div className="check-label-box">
                <input
                  type="checkbox"
                  className="check"
                  id="check-user-created"
                  onChange={(e) =>
                    setDetails((prev) => {
                      return { ...prev, user_is_created: e.target.checked };
                    })
                  }
                  value={details?.user_is_created}
                  checked={details?.user_is_created}
                />
                <label htmlFor="check-user-created" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send me a notification when a new user is created{" "}
                </label>
              </div>
              {/* check end */}
              {/* check start */}
              <div className="check-label-box">
                <input
                  type="checkbox"
                  className="check"
                  id="check-profile-update"
                  onChange={(e) =>
                    setDetails((prev) => {
                      return { ...prev, updated_userprofile: e.target.checked };
                    })
                  }
                  value={details?.updated_userprofile}
                  checked={details?.updated_userprofile}
                />
                <label htmlFor="check-profile-update" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send me a notification when a created user profile is updated{" "}
                </label>
              </div>
              {/* check end */}
              {/* check start */}
              <div className="check-label-box">
                <input
                  onChange={(e) =>
                    setDetails((prev) => {
                      return { ...prev, user_is_deleted: e.target.checked };
                    })
                  }
                  value={details?.user_is_deleted}
                  checked={details?.user_is_deleted}
                  type="checkbox"
                  className="check"
                  id="check-user-del"
                />
                <label htmlFor="check-user-del" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send me a notification when a created user is deleted from the
                  database{" "}
                </label>
              </div>
              {/* check end */}
              {/* check start */}
              <div className="check-label-box">
                <input
                  type="checkbox"
                  className="check"
                  id="check-role-created"
                  onChange={(e) =>
                    setDetails((prev) => {
                      return { ...prev, role_is_created: e.target.checked };
                    })
                  }
                  value={details?.role_is_created}
                  checked={details?.role_is_created}
                />
                <label htmlFor="check-role-created" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send me a notification when a new role is created{" "}
                </label>
              </div>
              {/* check end */}
              {/* check start */}
              <div className="check-label-box">
                <input
                  type="checkbox"
                  className="check"
                  id="check-permission-created"
                  onChange={(e) =>
                    setDetails((prev) => {
                      return {
                        ...prev,
                        permission_is_created: e.target.checked,
                      };
                    })
                  }
                  value={details?.permission_is_created}
                  checked={details?.permission_is_created}
                />
                <label htmlFor="check-permission-created" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send me a notification when a new permission is created{" "}
                </label>
              </div>
              {/* check end */}
            </div>
          </div>
          {/* form box end */}
          {/* form box start */}
          <div className="form-box-preference">
            <p className="title">Security Settings</p>
            <div className="wrap">
              <p className="subtitle sub-two">Manage Password</p>
              <div className="underline"></div>
              {/* form group start */}
              <div className="form-group">
                <label htmlFor="Password" className="form-label">
                  <figure className="img-box">
                    <img src={lockIcon} className="img" alt="" />
                  </figure>
                </label>
                <input
                  type={showPassword ? "text" : "Password"}
                  name="password"
                  id="Password"
                  className="form-input white-black-color"
                  placeholder="*************"
                  onChange={handleChange}
                  value={details.password}
                  disabled
                />
                <div
                  className={`status ${details.password && "status-active"}`}
                ></div>
                <p
                  className={`placeholder  white-black-bg white-black-color ${
                    details.password && "placeholder-active"
                  }`}
                >
                  Password
                </p>
                <div
                  onClick={() => setShwoPassord(!showPassword)}
                  className="show-hide"
                >
                  {showPassword ? (
                    <FaEyeSlash className="icon" />
                  ) : (
                    <FaEye className="icon" />
                  )}
                </div>
              </div>
              {/* form group end */}
              {/* update modat text start */}
              <p onClick={() => setShowModal(true)} className="update-text">
                Update Password
              </p>
              {/* update modat text end */}
            </div>
          </div>
          {/* form box end */}
          {/* form box start */}
          <div className="form-box-preference">
            <p className="title">Danger Zone</p>
            <div className="wrap">
              <p className="subtitle sub-two">Danger Zone</p>
              <div className="underline"></div>
              <p className="small-text">
                Once you close this account, there is no going back. Please be
                certain.
              </p>
              {/* delete btn start */}
              <div className="delete-btn">Close this account</div>
              {/* delete btn end */}
            </div>
          </div>
          {/* form box end */}
        </form>
      </div>
      {/* </DashboardLayout> */}

      {/* update password modal start */}
      <Modal effect="fadeInUp" visible={showModal}>
        <UpdatePasswordModal
          onCancel={() => setShowModal(false)}
        ></UpdatePasswordModal>
      </Modal>
      {/* update password modal end */}
    </>
  );
};

export default PreferenceSettings;
