import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import Modal from "react-awesome-modal";
import EmployeeCommonRevenueLayout from "./layout/CommonLayout";
import EmployeeProjectionApprovalModal from "./modals/approve";
import EmployeeProjectionComentModal from "./modals/comment";
// import EmployeeDeleteProjectionModal from "./modals/reject";
import EmployeeRejectCommentModal from "../../actual/modals/rejectComment";
// import EmployeeApprovedOverviewModal from "../../actual/modals/submitted revenue/overview";
// import EmployeeOverviewActualReinbursementModal from "../../home/modals/Reimbursement/EmployeeOverviewModal";
// import EmployeeOverviewRejectModal from "../modals/rejectModal";
import EmployeeRevenuePendingOverviewModal from "./modals/pendingModal";
import EmployeeOverviewRevenueApprovedModal from "./modals/approveOverviewModal";
import EmployeeRevenueOverviewRejectModal from "./modals/rejectedOverviewModal";

const EmployeeProjectionRevenueTableIndex = ({ children }) => {
  const history = useHistory();

  const [indexNo, setIndexNo] = useState("");

  const [showModal, setShowModal] = useState({
    overview: false,
    rejectOverview: false,
    approveOverview: false,
    comment: false,
    reject: false,
    approve: false,
  });

  const cardHeader = ["Total Revenue", "Approved", "Pending", "Rejected"];
  const cardValue = ["220,000.00", "20", "10", "5"];

  const tableHeadList = [
    "Product",
    "Units",
    "Unit Price",
    "Revenue",
    "GP",
    "GP %",
    "Status",
  ];

  const employeeTableBodyList = [
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "pending",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "accept",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "pending",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "reject",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "pending",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "accept",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "pending",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "reject",
    },
  ];

  const links = [
    "/employee-dashboard-projection-revenue-new",
    "/employee-dashboard-projection-revenue-submitted-total",
    "/employee-dashboard-projection-revenue-submitted-approved",
    "/employee-dashboard-projection-revenue-submitted-pending",
    "/employee-dashboard-projection-revenue-submitted-rejected",
  ];

  return (
    <>
      <div>
        <EmployeeCommonRevenueLayout totalRevenueNavLink={true}>
          <EmployeeReusableTable
            dontShowPagination={false}
            cardTitleOne={cardHeader[0]}
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            cardBodyOne={cardValue[0]}
            cardBodyTwo={cardValue[1]}
            cardBodyThree={cardValue[2]}
            cardBodyFour={cardValue[3]}
            showSearch={true}
            linkCardOne={links[1]}
            sort
            filter
            BtnTitle={`New Projection`}
            addButton={true}
            firstCard={true}
            secondCard={true}
            thirdCard={true}
            fourthCard={true}
            onPage={() => history.push(links[0])}
            onFirstCard={() => history.push(links[1])}
            onSecondCard={() => history.push(links[2])}
            onThirdCard={() => history.push(links[3])}
            onFourthCard={() => history.push(links[4])}
          />

          {children}
          <div className="table-box">
            {/* table box start */}
            <ActualReuseableTable
              dontShowPagination={false}
              dontShowBnSearch
              one={tableHeadList[0]}
              two={tableHeadList[1]}
              three={tableHeadList[2]}
              four={tableHeadList[3]}
              five={tableHeadList[4]}
              six={tableHeadList[5]}
              seven={tableHeadList[6]}
              action
              onNextPage
            >
              {employeeTableBodyList.map((chi, idx) => {
                const {
                  product,
                  units,
                  unit_price,
                  revenue,
                  gross_profit,
                  gross_percentage,
                  status,
                } = chi;
                return (
                  <EmpTableBodyRow
                    key={idx}
                    one={product}
                    two={units}
                    three={unit_price}
                    four={revenue}
                    five={gross_profit}
                    six={gross_percentage}
                    progressType={status}
                    action
                    rowNo={idx}
                    indexNo={indexNo}
                    setIndexNo={() => setIndexNo(idx)}
                    updateText={`modify`}
                    approveText={"Approve"}
                    viewText={"View Comments"}
                    deleteText={`Reject`}
                    exportText={`Export as PDF`}
                    onUpdate={() => history.push(links[0])}
                    onApprove={() =>
                      setShowModal((prev) => {
                        return { ...prev, approve: true };
                      })
                    }
                    onView={() =>
                      setShowModal((prev) => {
                        return { ...prev, comment: true };
                      })
                    }
                    onDelete={() =>
                      setShowModal((prev) => {
                        return { ...prev, reject: true };
                      })
                    }
                    onOverview={() =>
                      setShowModal((prev) => {
                        return { ...prev, overview: true };
                      })
                    }
                    onRejectStatus={() =>
                      setShowModal((prev) => {
                        return {
                          ...prev,
                          rejectOverview: true,
                        };
                      })
                    }
                    onAcceptStatus={() =>
                      setShowModal((prev) => {
                        return { ...prev, approveOverview: true };
                      })
                    }
                  />
                );
              })}
            </ActualReuseableTable>
          </div>
        </EmployeeCommonRevenueLayout>
      </div>

      {/* modal start */}
      <Modal visible={showModal.approve}>
        <EmployeeProjectionApprovalModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approve: false };
            })
          }
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={showModal.comment}>
        <EmployeeProjectionComentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, comment: false };
            })
          }
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmployeeRejectCommentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal Effect={"fadeInOut"} visible={showModal.rejectOverview}>
        <EmployeeRevenueOverviewRejectModal
          units
          approveBtn
          nextPage
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            })
          }
          onAprove={() =>
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            })
          }
          onNextPage={() => history.push(links[0])}
        
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeOverviewRevenueApprovedModal
          units
          unit_price
          total_value
          date_text={`Date Created`}
          last_updated
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal effect={"fadeInDown"} visible={showModal.overview}>
        <EmployeeRevenuePendingOverviewModal
          onApprove={() =>
            setShowModal((prev) => {
              return { ...prev, overview: false };
            })
          }
          nextPage
          rejectText
          onNextPage={() => history.push(links[0])}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, overview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            })
          }
          onReject={() =>
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeProjectionRevenueTableIndex;
