import React from "react";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { deleteActualExpenseEmployee } from "../../../../../../redux/employee/actual-expense";
import "../../../../../../styles/dashboard/modals/DeleteSharedmodal.css";

const EmployeeActualExpenseDeleteModal = ({ onClose, detail, onFinish }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.actual_expense_employee);
  const handleSubmit = async () => {
    const id = detail?.id;
    const data = await dispatch(deleteActualExpenseEmployee(id));
    if (data?.payload?.data?.success) {
      // console.log(data);
      toast.success(data?.payload?.data?.message, {
        theme: "colored",
      });
      onFinish();
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="delete-shared-modal-wrap">
        <p
          style={{ textTransform: "capitalize" }}
          className="title"
        >{`Delete Expense (${detail?.chart_of_account?.name})`}</p>
        <p className="text">
          Are you sure you want to delete this expense? you'll permanently lose
          this information and may not be able to recover it. Do you still want
          to delete data?
        </p>
        <div className="btn-box">
          <ButtonBlue onClick={onClose} text={`Cancel`} />
          {!loading ? (
            <ButtonBlue
              onClick={handleSubmit}
              bgColor={`#CC0905`}
              text={`Yes Delete`}
            />
          ) : (
            <div style={{ alignSelf: "center", marginLeft: "1rem" }}>
              <Dna
                visible={true}
                height="80"
                width="100"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeActualExpenseDeleteModal;
