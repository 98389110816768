import React, { useEffect, useState } from "react";
import { FaCheck, FaMinus } from "react-icons/fa";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeSpreadForm from "./SpreadForm";
// import "aos/dist/aos.css";
// import Aos from "aos";
import Modal from "react-awesome-modal";
import EmployeeMonthlyForm from "./MonthlyForm";
// import { useHistory } from "react-router-dom";
import EmployeeBulkModal from "./modals/upload";
import Select from "react-select";
import {
  formatNumber,
  // formatNumberWithComma,
  formatNumberWithoutComma,
  handleNumberFormatInput,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
// import { useContext } from "react";
// import ActionContext from "../../../../../../context/ActionContext";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/airbnb.css";
import {
  // createExpenditureProjection,
  // getExpenditureProjections,
  getExpenditureProjection,
  getUserDepartment,
  updateExpenditureProjections,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { Dna } from "react-loader-spinner";
import moment from "moment";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import { useThemeDetector } from "../../../../../../utils/getThemeColor";
import { useHistory } from "react-router-dom";
import "../../../../../styles/dashboard/projection/expense/expenditureProjection.css";
import {
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import { toast } from "react-toastify";

const ExpenditureProjectionUpdate = () => {
  const [spreadForm, setSpreadForm] = useState(false);
  // const [btnChange, setBtnChange] = useState(false);
  const [monthlyForm, setMonthlyForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [totalQuantity, setTotalQuatity] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [spreadTotalVal, setSpreadTotalVal] = useState("");
  const [changeLabel, setChangeLabel] = useState({
    inputLabel: "Quantity",
    totalLabel: "Total Quantity",
  });
  const [details, setDetails] = useState({
    projection_type: "",
    date: "",
    user_id: "",
    chart_of_account_id: "",
    organization_id: "",
    description: "",
    unit_price: "",
    units: "",
    total_value: "",
    is_submitted: "",
    monthly_breakdown: [{ month_name: { label: "" }, value: "" }],
    departments_aportions: [
      { department_id: "", subsidiary_id: "", apportions: "" },
    ],
    // status: "",
  });
  const [singleExpenditure, setSingleExpenditure] = useState({});
  const [startDate, setstartDate] = useState("");
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { departmentDrop } = useSelector((state) => state.department);
  const { user } = useSelector((state) => state.user);
  const { loadingPage } = useSelector((state) => state.department);
  // const [refreshData, setRefreshData] = useState(false);
  // const actionCtx = useContext(ActionContext);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  // const [subOption, setSubOption] = useState([]);
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const { loading } = useSelector((state) => state.expenditure_projection);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...singleExpenditure, [name]: value };
    setSingleExpenditure(data);
  };
  // const handleTextChange = async (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   const data = { ...details, [name]: value };
  //   setDetails(data);
  //   const dataExp = { ...singleExpenditure, [name]: value };
  //   setSingleExpenditure(dataExp);
  // };

  const [isSharedCheck, setIsSharedCheck] = useState(false);

  const entryType = [
    {
      label: "Quantity",
      value: "Quantity",
    },
    {
      label: "Amount",
      value: "Amount",
    },
  ];

  useEffect(() => {
    const empObj = {
      reason: true,
      id: user?.organizations[0]?.id,
    };
    dispatch(getChatAccounts(empObj));
    dispatch(getFiscalYears(empObj));
    // getDepartments(user?.organizations[0]?.id);
    dispatch(getUserDepartment(empObj.id));
    dispatch(getSubsidiaries(empObj));

    const id = localStorage.getItem("single-expenditure-projection-id");
    if (user?.organizations?.length > 0) {
      handleGetExpenditureProjection(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id]);

  // let departmentOption = [];
  // const [departmentList, setDepartmentList] = useState([]);
  // const handleGetDeptDrop = async () => {
  //   const obj = {
  //     reason: true,
  //     id: singleExpenditure?.subsidiary?.value,
  //   };
  //   const data = await dispatch(getDepartmentsBySubsidiary(obj));
  //   if (data) {
  //     const deptDrop = data?.payload?.data?.departments;
  //     // setDepartmentList(deptDrop);
  //     let departmentOption = deptDrop;
  //     console.log(departmentOption, "drop-it");
  //     return departmentOption;
  //     // return departmentOption;
  //   }
  // };
  // console.log(departmentOption, "option");

  // useEffect(() => {
  //   handleGetDeptDrop();
  // }, []);

  useEffect(() => {
    if (
      singleExpenditure?.projection_type === "monthly" &&
      singleExpenditure?.entry_type?.label === "Quantity"
    ) {
      const totalQua = singleExpenditure?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      setTotalValue(totalQuaAddList);

      const totalQuaVal =
        totalQuaAddList *
        formatNumberWithoutComma(String(singleExpenditure?.unit_price || 0));
      setTotalQuatity(totalQuaVal);
      // setTotalValue(totalQuaVal);
      // const totalQuaAddListVal = totalQuaAddList;
    }
    if (
      singleExpenditure?.entry_type?.label === "Amount" &&
      singleExpenditure?.unit_price
    ) {
      const totalQua = singleExpenditure?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      setTotalValue(totalQuaAddList);
      const totalQuaAddListVal = totalQuaAddList;

      setTotalQuatity(totalQuaAddListVal);
      const totalAmtVal =
        totalQuaAddListVal /
        formatNumberWithoutComma(String(singleExpenditure?.unit_price));
      const round = totalAmtVal || 0;
      setTotalValue(round);
    }
  }, [singleExpenditure]);

  // added changes to the calculation

  // spread total value
  useEffect(() => {
    if (singleExpenditure?.projection_type === "spread") {
      const totalSpreadVal =
        formatNumberWithoutComma(String(singleExpenditure?.unit_price)) *
        formatNumberWithoutComma(String(singleExpenditure?.units));
      // const totalSpreadValString = totalSpreadVal;
      setSpreadTotalVal(totalSpreadVal);
    }
  }, [singleExpenditure]);

  const handleSelectOptionToMonthList = (e, item) => {
    const newList = singleExpenditure?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id ? { ...chi, month_name: e } : chi
    );
    setSingleExpenditure((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };

  const handleInputOptionToMonthList = (e, item) => {
    const newList = details?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id
        ? {
            ...chi,
            value: handleNumberFormatInput(e),
          }
        : chi
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
    const newExpList = singleExpenditure?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id ? { ...chi, value: handleNumberFormatInput(e) } : chi
    );
    setSingleExpenditure((prev) => {
      return { ...prev, monthly_breakdown: newExpList };
    });
  };

  const handleRemoveOptionMonthList = (item) => {
    const newList = singleExpenditure?.monthly_breakdown?.filter(
      (chi) => chi?.id !== item?.id
    );
    setSingleExpenditure((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };
  const history = useHistory();

  const [isApportion, setIsApportion] = useState([]);

  const formatApportionToSubmit = (obj, e) => {
    const newList = singleExpenditure?.shared_service_details?.map((chi) =>
      obj === chi ? { ...chi, apportions: e } : chi
    );
    setSingleExpenditure((prev) => {
      return { ...prev, shared_service_details: newList };
    });
  };

  // useEffect(() => {
  //   handleApportionSum();
  // }, [singleExpenditure?.shared_service_details]);

  // const [apportionSum, setApportionSum] = useState("");
  // const handleApportionSum = () => {
  //   const totalApportion = singleExpenditure?.shared_service_details
  //     ?.map((chi) => chi.apportions)
  //     .reduce((a, b) => Number(a) + Number(b), 0);
  //   setApportionSum(totalApportion);
  // };

  // const [deptDrop, setDeptDrop] = useState([]);
  // const [deptLoader, setDeptLoader] = useState(false);
  // const handleDeptDrop = async (id) => {
  //   const obj = {
  //     reason: true,
  //     id: id,
  //   };
  //   setDeptLoader(true);
  //   const data = await dispatch(getDepartmentsBySubsidiary(obj));
  //   if (data?.payload?.success) {
  //     const dropItem = data?.payload?.data?.departments?.map((chi) => {
  //       return {
  //         // ...chi,
  //         branch: chi?.branch,
  //         label: chi?.name,
  //         value: chi?.id,
  //         // apportion:
  //       };
  //     });
  //     const newDropItem = dropItem?.find(
  //       (chi) =>
  //         chi?.branch?.subsidiary?.id === singleExpenditure?.subsidiary?.value
  //     );
  //     // setDeptDrop(newDropItem);
  //     setDeptLoader(false);
  //   } else {
  //     setDeptLoader(false);
  //   }
  // };

  // const apportionData = deptDrop?.branch?.subsidiary?.shared_service;

  // useEffect(() => {
  //   if (singleExpenditure?.subsidiary?.value) {
  //     handleDeptDrop();
  //   }
  // }, [user?.organizations[0]?.id, singleExpenditure?.subsidiary?.value]);

  // const apportionData

  // const editApportionToSubmit = async (list, dept) => {
  //   // console.log(list, "list");
  //   if (list?.length > 0) {
  //     // console.log(singleExpenditure?.shared_service_details, "new-list");
  //     const subsidiaryOptions = list?.map((chi) => {
  //       return {
  //         // ...chi,
  //         value: chi?.value,
  //         label: chi?.label,
  //         apportions: chi?.apportions,
  //       };
  //     });
  //     setSubOption(subsidiaryOptions);
  //   }
  // };

  // const handleBudgetSubOptions = (list) => {
  //   if (list?.length > 0) {
  //     const option = list?.map((chi) => {
  //       return {
  //         ...chi,
  //         value: chi?.value,
  //         label: chi?.label,
  //       };
  //     });
  //     console.log(option, "option");
  //     return option;
  //   }
  // };

  // useEffect(() => {
  //   chckDept();
  //   // editApportionToSubmit(
  //   //   singleExpenditure?.shared_service_details,
  //   //   singleExpenditure?.department
  //   // );
  // }, []);

  const handleMultiSelectChange = (
    selectedValues,
    { action, removedValue },
    list
  ) => {
    const addSharedPortionToList = (param) => {
      const newList = param?.map((chi, idx) => {
        return { ...chi, apportion: "" };
      });
      return newList;
    };

    if (action === "select-option") {
      const newlyAddedOptionList = selectedValues.map((option) =>
        !singleExpenditure?.shared_service_details?.includes(option)
          ? {
              ...option,
              departments: addSharedPortionToList(list),
              departments_shared: [],
            }
          : option
      );
      // console.log("Newly added option:", newlyAddedOptionList);
      // setAllSelectedSubsidiary(newlyAddedOptionList);
      setSingleExpenditure((prev) => {
        return {
          ...prev,
          shared_service_details: newlyAddedOptionList,
        };
      });
    }
  };

  const handleNewBudgetPercentage = (value, valId) => {
    const newValue = singleExpenditure?.shared_service_details?.map((chi) =>
      chi?.value === valId ? { ...chi, apportions: value } : chi
    );
    setSingleExpenditure((prev) => {
      return { ...prev, shared_service_details: newValue };
    });
  };

  const handleMultiSelectChangeTwo = (
    selectedValues,
    { action, removedValue },
    list,
    id
  ) => {
    const getObjChild = singleExpenditure?.shared_service_details?.find(
      (chi) => chi?.value === id
    );
    const addSelectedToObjChild = {
      ...getObjChild,
      departments_shared: selectedValues,
    };
    // console.log(addSelectedToObjChild);
    const addBackToAllList = singleExpenditure?.shared_service_details?.map(
      (chi) => (chi?.value === id ? addSelectedToObjChild : chi)
    );
    setSingleExpenditure((prev) => {
      return { ...prev, shared_service_details: addBackToAllList };
    });
  };

  const handleFormatDepartmentDrop = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          value: chi?.id,
          label: chi?.name,
        };
      });
      return newList;
    }
  };

  const handleApportionDeptChange = (sudId, deptId, value) => {
    const getObjChild = singleExpenditure?.shared_service_details?.find(
      (chi) => chi?.value === sudId
    );

    const addSelectedToObjChild = getObjChild?.departments_shared?.map((chi) =>
      chi?.value === deptId ? { ...chi, apportion: value } : chi
    );
    const addBackToAllList = singleExpenditure?.shared_service_details?.map(
      (chi) =>
        chi?.value === sudId
          ? { ...chi, departments_shared: addSelectedToObjChild }
          : chi
    );
    setSingleExpenditure((prev) => {
      return { ...prev, shared_service_details: addBackToAllList };
    });
  };

  const handleDeleteChildFromAll = (sudId, deptId) => {
    const getObjChild = singleExpenditure?.shared_service_details?.find(
      (chi) => chi?.value === sudId
    );
    // console.log(getObjChild, "get-obj");
    const addSelectedToObjChild = getObjChild?.departments_shared?.filter(
      (chi) => chi?.value !== deptId
    );
    // console.log(deptId, "dept-id");
    // console.log(addSelectedToObjChild, "add-selected");
    const addBackToAllList = singleExpenditure?.shared_service_details?.map(
      (chi) =>
        chi?.value === sudId
          ? { ...chi, departments_shared: addSelectedToObjChild }
          : chi
    );
    // setAllSelectedSubsidiary(addBackToAllList);
    setSingleExpenditure((prev) => {
      return { ...prev, shared_service_details: addBackToAllList };
    });
  };

  const handleGetExpenditureProjection = async (id) => {
    const data = await dispatch(getExpenditureProjection(id));
    if (data?.payload?.success) {
      const expenditureDetail = data?.payload?.data?.expense;
      const newBreakDown = expenditureDetail?.monthly_breakdown?.map((chi) => {
        return {
          ...chi,
          month_name: { label: chi?.month_name, value: chi?.month_name },
          value: formatNumber(String(chi?.value)),
        };
      });
      // const getSharedDetails = async () => {
      const getApportionListForShared =
        expenditureDetail?.shared_service_details?.map(async (chi) => {
          const obj = {
            reason: true,
            id: chi?.subsidiary?.id,
          };
          const data = await dispatch(getDepartmentsBySubsidiary(obj));
          return {
            ...chi,
            value: chi?.subsidiary?.id,
            label: chi?.subsidiary?.name,
            departments: data?.payload?.data?.departments,
            departments_shared: chi?.departments_shared?.map((chil) => {
              return {
                ...chil,
                apportion: chil?.apportion,
                value: chil?.department?.id,
                label: chil?.department?.name,
              };
            }),
            // options: dropData,
          };
        });
      let sharedList = [];
      if (getApportionListForShared) {
        const newData = await Promise.all(getApportionListForShared);

        sharedList = newData;
      }

      let departmentOptionDrop = [];
      const obj = {
        id: expenditureDetail?.subsidiary?.id,
        reason: true,
      };
      const drop = await dispatch(getDepartmentsBySubsidiary(obj));
      if (drop?.payload?.success) {
        const newDropData = drop?.payload.data?.departments;
        departmentOptionDrop = newDropData;
      }

      let subsdiaryBudgetDropdown = [];
      const deptBudgetDrop = await dispatch(
        getDepartments(user?.organizations[0]?.id)
      );
      if (deptBudgetDrop?.payload?.success) {
        const newDeptBudgetDrop = deptBudgetDrop?.payload?.data?.departments;
        const deptValue = newDeptBudgetDrop?.find((chi, idx) => {
          if (chi?.id === expenditureDetail?.department?.id) {
            return {
              ...chi,
            };
          }
        });
        const newDeptValue =
          deptValue?.branch?.subsidiary?.shared_service[0]?.apportions?.map(
            (chi) => {
              return {
                ...chi,
                label: chi?.subsidiary?.name,
                value: chi?.subsidiary?.id,
              };
            }
          );
        if (deptValue) {
          subsdiaryBudgetDropdown = newDeptValue;
        }
      }

      const newExpenditureDetail = {
        ...expenditureDetail,
        chart_of_account: {
          label: expenditureDetail?.chart_of_account?.name,
          value: expenditureDetail?.chart_of_account?.id,
        },
        deptOptions: departmentOptionDrop,
        subBudgetOption: subsdiaryBudgetDropdown,
        department: {
          label: expenditureDetail?.department?.name,
          value: expenditureDetail?.department?.id,
        },
        check_shared: false,
        subsidiary: {
          label: expenditureDetail?.subsidiary?.name,
          value: expenditureDetail?.subsidiary?.id,
        },
        fiscal_year: {
          label: expenditureDetail?.fiscal_year?.name,
          value: expenditureDetail?.fiscal_year?.id,
        },
        entry_type: {
          label: expenditureDetail?.entry_type,
          value: expenditureDetail?.entry_type,
        },
        monthly_breakdown: newBreakDown,
        shared_service_details: sharedList,
        unit_price: formatNumber(String(expenditureDetail?.unit_price)),
        units: formatNumber(String(expenditureDetail?.units)),
      };

      setSingleExpenditure(newExpenditureDetail);
    }
  };

  const { preference } = useSelector((state) => state.preference);
  const isDarkTheme = useThemeDetector();
  require(`flatpickr/dist/themes/${
    preference?.display === "Light"
      ? "airbnb"
      : preference?.display === "Dark"
      ? "dark"
      : isDarkTheme
      ? "dark"
      : "airbnb"
  }.css`);

  useEffect(() => {
    if (singleExpenditure?.projection_type === "spread") {
      setSpreadForm(true);
    } else if (singleExpenditure?.projection_type === "monthly") {
      setMonthlyForm(true);
    }
  }, [details, singleExpenditure]);

  const handleAddToMonthList = () => {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const obj = {
      id: id,
      name: { label: "", value: "" },
      value: "",
    };

    const newExpList = {
      ...singleExpenditure,
      monthly_breakdown: [...singleExpenditure?.monthly_breakdown, obj],
    };
    setSingleExpenditure(newExpList);
  };

  const [isApportionAlert, setIsApportionAlert] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const handleSharedSum = () => {
    const newApportionListForShared =
      singleExpenditure?.shared_service_details?.map((chi) => {
        return {
          subsidiary_id: chi?.value,
          apportions: chi?.apportions,
          label: chi?.label,
          subsidiary_departments: chi?.departments_shared?.map((child) => {
            return {
              department_id: child?.value,
              apportion: child?.apportion,
            };
          }),
        };
      });

    const newInvalidBudgetLength = [];

    newApportionListForShared?.forEach((chi) => {
      const totalApportion = chi?.subsidiary_departments
        ?.map((chil) => chil?.apportion)
        ?.reduce((a, b) => Number(a) + Number(b), 0);

      if (totalApportion !== 100) {
        newInvalidBudgetLength.push({
          ...chi,
          subsidiary_id: chi.subsidiary_id,
          label: chi?.label,
          department_id: chi.subsidiary_departments.find(
            (chil) => chil.apportion !== 100
          ),
        });
      }
    });

    if (newInvalidBudgetLength.length > 0) {
      setIsApportionAlert(true);
      const errorMessage = newInvalidBudgetLength
        .map((dept) => {
          return `Subsidiary: ${dept.label}.`;
        })
        .join("\n");

      setTimeout(() => {
        toast.error(
          `Budget Allocation Error: The total allocation across all categories must add up to 100% of \n${errorMessage}`,
          {
            theme: "colored",
          }
        );
      }, 1500);
    } else {
      setIsApportionAlert(false);
    }
  };

  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newList = singleExpenditure?.monthly_breakdown.map((chi) => {
      return {
        value: formatNumberWithoutComma(String(chi.value)),
        name: chi.month_name?.value,
      };
    });

    const newApportionListForSHared =
      singleExpenditure?.shared_service_details?.map((chi) => {
        return {
          subsidiary_id: chi?.value,
          // id: chi?.id,
          apportions: Number(chi?.apportions),
          subsidiary_departments: chi?.departments_shared?.map((child) => {
            return {
              department_id: child?.value,
              apportion: Number(child?.apportion),
            };
          }),
        };
      });

    const obj = {
      // ...details
      id: singleExpenditure?.id,
      date: moment(startDate || singleExpenditure?.date).format("YYYY-MM-DD"),
      description: details?.description || singleExpenditure?.description,
      // unit_price: details?.unit_price || singleExpenditure?.unit_price,
      organization_id: user?.organizations[0]?.id,
      total_quantity: totalValue,
      is_shared_service:
        singleExpenditure?.shared_service_details?.length > 0 ? true : false,
      subsidiary_id: singleExpenditure?.subsidiary?.value,
      departments_aportions: newApportionListForSHared,
      shared_service_details: newApportionListForSHared,
      // apportion_length: apportionSum,
      entry_type:
        singleExpenditure?.entry_type?.label || details?.entry_type?.label,
      total_value:
        singleExpenditure?.projection_type === "spread"
          ? spreadTotalVal
          : formatNumberWithoutComma(String(totalQuantity)),
      monthly_breakdown: newList,
      is_submitted: singleExpenditure?.is_submitted,
      unit_price:
        // formatNumberWithoutComma(String(details?.unit_price)) ||
        formatNumberWithoutComma(String(singleExpenditure?.unit_price)),
      units:
        singleExpenditure?.projection_type === "monthly"
          ? totalValue
          : formatNumberWithoutComma(String(singleExpenditure?.units)),
      projection_type:
        singleExpenditure?.projection_type === "monthly" ? "monthly" : "spread",
      user_id: user?.id,
      chart_of_account_id:
        details?.chart_of_account_id?.value ||
        singleExpenditure?.chart_of_account?.value,
      department_id:
        details?.department_id?.value || singleExpenditure?.department?.value,
    };
    if (details.projection_type === "spread") {
      delete obj["monthly_breakdown"];
      obj["total_value"] = spreadTotalVal;
    }

    if (obj?.is_submitted === 0 || obj.is_submitted === 1) {
      setUpdateLoader(true);
    }

    if (isApportionAlert) {
      setTimeout(() => {
        setUpdateLoader(false);
      }, 2000);
      return;
    }

    const data = await dispatch(updateExpenditureProjections(obj));
    if (data?.payload?.success && obj?.is_submitted === 0) {
      setUpdateLoader(false);
      // setDetails({
      //   projection_type: "",
      //   date: "",
      //   user_id: "",
      //   chart_of_account_id: "",
      //   organization_id: "",
      //   description: "",
      //   unit_price: "",
      //   units: "",
      //   total_value: "",
      //   monthly_breakdown: [{ name: { label: "" }, value: "" }],
      //   status: "",
      // });
      // setstartDate("");
      history.push("/employee-dashboard-projection-expenditure");
    } else if (data?.payload?.success && obj?.is_submitted === 1) {
      setUpdateLoader(false);
      history.push("/employee-dashboard-projection-expenditure-submitted");
    } else {
      setUpdateLoader(false);
    }
  };

  const handleSpreadForm = () => {
    setSpreadForm(true);
    setMonthlyForm(false);
    // setBtnChange(true);
  };

  const handleMonthlyForm = () => {
    setMonthlyForm(true);
    setSpreadForm(false);
    // setBtnChange(true);
  };

  const handleDeptDropOption = (list) => {
    if (list?.length > 0) {
      const listDrop = list?.map((chi) => {
        return {
          ...chi,
          label: chi?.name,
          value: chi?.id,
        };
      });

      return listDrop;
    }
  };

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Expenditure Projection`}> */}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">Update Expenditure Projection</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          <div className="btn-download-box" onClick={() => setShowModal(true)}>
            {/* <button className={` btn-bulk `}>
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p> */}
          </div>
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container */}

        <div className="form-box" style={{ width: "80%" }}>
          <form onSubmit={handleSubmit} action="" className="form">
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Projection Information</p>
              <div className="form-wrap">
                {/* group box start */}

                <div className="form-group">
                  <label htmlFor="date" className="label">
                    Date <span>*</span>
                  </label>
                  <Flatpickr
                    id="date"
                    name="date"
                    className={`input ${
                      singleExpenditure?.date && "input-active"
                    }`}
                    autoComplete="off"
                    // value={moment(singleExpenditure?.date).format(
                    //   "YYYY-MMMM-DD"
                    // )}
                    onChange={(date) => {
                      setstartDate(moment(date[0]).format("YYYY-MM-DD"));
                    }}
                    placeholder={
                      moment(singleExpenditure?.date).format("YYYY-MM-DD") ||
                      "Enter Date"
                    }
                  />
                </div>
                {/* group box end */}

                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Chart of account <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleExpenditure?.chart_of_account &&
                      "select-wrap-active"
                    }`}
                    placeholder="Chart of account"
                    value={singleExpenditure?.chart_of_account}
                    onChange={(e) => {
                      // handleGetChartOfAcct(e)
                      setDetails((prev) => {
                        return { ...prev, chart_of_account_id: e };
                      });
                      setSingleExpenditure((prev) => {
                        return { ...prev, chart_of_account: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={chatAccountDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleExpenditure?.subsidiary && "select-wrap-active"
                    }`}
                    placeholder="Select Subsidiary"
                    value={singleExpenditure?.subsidiary}
                    onChange={async (e) => {
                      setSingleExpenditure((prev) => {
                        return { ...prev, subsidiary: e };
                      });
                      const obj = {
                        reason: true,
                        id: e?.value,
                      };
                      const data = await dispatch(
                        getDepartmentsBySubsidiary(obj)
                      );
                      if (data?.payload?.success) {
                        setSingleExpenditure((prev) => {
                          return {
                            ...prev,
                            deptOptions: data?.payload?.data?.departments,
                          };
                        });
                      }
                      // handleDeptDrop(obj);
                    }}
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    // isLoading={isLoading}
                  />
                </div>
                {/* group box end */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Department <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleExpenditure?.department && "select-wrap-active"
                    }`}
                    placeholder="Select Department"
                    // placeholder={singleExpenditure?.department_id}
                    value={singleExpenditure?.department}
                    onChange={(e) => {
                      // setDetails((prev) => {
                      //   return { ...prev, department_id: e };
                      // });
                      setSingleExpenditure((prev) => {
                        return {
                          ...prev,
                          department: e,
                          is_shared_service:
                            e?.branch?.subsidiary?.shared_service?.length < 1
                              ? false
                              : true,
                          check_shared:
                            e?.branch?.subsidiary?.shared_service?.length < 1
                              ? false
                              : true,
                        };
                      });

                      // setIsCheckShared();
                      // console.log(e, "e");
                    }}
                    styles={reactSelectStyle}
                    options={handleDeptDropOption(
                      singleExpenditure?.deptOptions
                    )}
                    // options={
                    //   user?.user_type === "ADMIN" &&
                    //   singleExpenditure?.subsidiary?.value
                    //     ? departmentDrop
                    //     : user?.user_type === "EMPLOYEE" &&
                    //       details?.subsidiary_id
                    //     ? userDepartmentDrop
                    //     : []
                    // }
                    isLoading={loadingPage}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Fiscal Year <span>*</span>
                  </label>
                  <Select
                    placeholder="Select fiscal year"
                    className={`select-wrap ${
                      singleExpenditure?.fiscal_year && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    value={singleExpenditure?.fiscal_year}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, fiscal_year_id: e };
                      });
                      setSingleExpenditure((prev) => {
                        return { ...prev, fiscal_year: e };
                      });
                    }}
                    options={fiscalYearDrop}
                    isLoading={loading}
                  />
                </div>

                {/* group box start */}
                <br />
                <div className="form-group">
                  <label htmlFor="description" className="label">
                    Description <span>*</span>
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={handleChange}
                    style={{
                      width: "207%",
                      padding: "3rem",
                      // height:"25vh",
                      resize: "none",
                    }}
                    cols="20"
                    rows="8"
                    placeholder="Enter your description here"
                    className={`input ${
                      singleExpenditure?.description && "input-active"
                    }`}
                    value={singleExpenditure?.description}
                  />
                </div>
                {/* group box end */}
              </div>
              <div className="">
                {/* second wrap start */}
                {/* <div className="wrapper"></div> */}
                <div>
                  <div className="wrapper"></div>
                  <p className="box-title">2. Projection Format</p>
                </div>
                {/* form check bos start */}
                <div className="form-check-box" style={{ width: "70vh" }}>
                  {/* check group start */}

                  <div className="check-group" onChange={handleSpreadForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-spread-evenly"
                      value="spread"
                      checked={
                        singleExpenditure?.projection_type === "spread" ||
                        details?.projection_type === "spread"
                      }
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, projection_type: e.target.value };
                        });
                        setSingleExpenditure((prev) => {
                          return { ...prev, projection_type: e.target.value };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-spread-evenly"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Spread Evenly</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (share units and value accross all items)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                  {/* check group start */}
                  {/* <input type="radio"/> */}

                  <div className="check-group" onChange={handleMonthlyForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-monthly-entry-one"
                      value="monthly"
                      checked={singleExpenditure?.projection_type === "monthly"}
                      // checked={
                      //   singleExpenditure?.projection_type === "monthly" ||
                      //   details?.projection_type === "monthly"
                      // }
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, projection_type: e.target.value };
                        });
                        setSingleExpenditure((prev) => {
                          return { ...prev, projection_type: e.target.value };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-monthly-entry-one"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Monthly Entry</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (specify type and unit value for each item)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                </div>
                {/* spread-evenly form start */}
                {spreadForm && (
                  <EmployeeSpreadForm
                    onInputChange={handleChange}
                    // unit_price={formatNumberWithComma(
                    //   String(singleExpenditure?.unit_price)
                    // )}
                    // unit_price={unitPrice}
                    // units={formatNumberWithComma(
                    //   String(singleExpenditure?.units)
                    // )}
                    unit_price={singleExpenditure?.unit_price}
                    units={singleExpenditure?.units}
                    total_value={spreadTotalVal}
                  />
                )}
                {/* spread-evenly form end */}
                {monthlyForm && (
                  <EmployeeMonthlyForm
                    onInputChange={handleChange}
                    unit_price={singleExpenditure?.unit_price}
                    // units={formatNumberWithComma(
                    //   String(singleExpenditure?.units)
                    // )}
                    list={singleExpenditure?.monthly_breakdown}
                    total_quantity={totalValue}
                    total_value={totalQuantity}
                    handleAddMonthList={handleAddToMonthList}
                    onSelectvalueChange={handleSelectOptionToMonthList}
                    onInputValueChange={handleInputOptionToMonthList}
                    handleRemoveMonthList={handleRemoveOptionMonthList}
                    loading={loading}
                    Quantity={changeLabel.inputLabel}
                    handleEntryTypeChange={(e) => {
                      e.value === "Amount"
                        ? setChangeLabel((prev) => {
                            return {
                              ...prev,
                              inputLabel: "Amount",
                              totalLabel: "Total Amount",
                            };
                          })
                        : setChangeLabel(
                            !changeLabel.inputLabel,
                            !changeLabel.totalLabel
                          );
                      // setDetails({ ...details, entry_type: e });
                      setSingleExpenditure({
                        ...singleExpenditure,
                        entry_type: e,
                      });
                      // // // console.log("e", e)
                    }}
                    entry_type_value={
                      singleExpenditure?.entry_type?.label === null
                        ? ""
                        : singleExpenditure?.entry_type
                    }
                    entryType={entryType}
                    // name={singleExpenditure?.monthly_bre akdown}
                    total_value_label={
                      singleExpenditure?.entry_type?.label === "Amount" &&
                      "Total Amount"
                    }
                  />
                )}

                {/* form check bos end */}
              </div>{" "}
              <div className="emp-expenditure-shared-service-budget-wrap">
                {/* shared budget wrap start */}
                {/* {singleExpenditure?.shared_service_details?.length > 0 && ( */}
                <div>
                  <div className="wrapper" />
                  <p className="shared-percent-title">
                    3. Set Sharing Percentage
                  </p>
                  {/* set sharing percentage note wrap start */}
                  <div>
                    <p>
                      Set cost sharing percentage or retain default shared
                      service settings.
                    </p>
                    <p
                      style={{
                        marginTop: ".5rem",
                        marginBottom: "2rem",
                        fontWeight: "400",
                      }}
                    >
                      Note: Percentage for all subsidiaries add up to 100%
                    </p>
                  </div>
                  {/* set sharing percentage note wrap end */}
                  <div className="hide-box">
                    <input
                      checked={singleExpenditure?.is_shared_service}
                      type="checkbox"
                      name=""
                      disabled={singleExpenditure?.check_shared}
                      id="is_shared_toggle"
                      className="input"
                      onChange={(e) => {
                        setSingleExpenditure((prev) => {
                          return {
                            ...prev,
                            is_shared_service:
                              !singleExpenditure?.is_shared_service,
                            shared_service_details:
                              !singleExpenditure?.is_shared_service
                                ? []
                                : singleExpenditure?.shared_service_details,
                          };
                        });

                        // setAllSelectedSubsidiary([]);
                        // handleSubOptions();
                      }}
                    />
                    <label className="label" htmlFor="is_shared_toggle">
                      <span></span>
                      <span></span>
                    </label>
                  </div>
                </div>
                {/* )} */}
                {/* shared budget wrap end */}
                {/* shared budget form wrap start */}

                {singleExpenditure?.is_shared_service && (
                  <div className="shared-budget-form-wrap">
                    {/* new  design box start -------------------- */}
                    {/* select subsidiary start */}
                    <div className="shared-form-group">
                      <label className="">Select Subsidiary</label>
                      <Select
                        name="subsidiary_id"
                        id="subsidiary_name"
                        className={"select-wrap select-wrap-multi"}
                        placeholder="Select Subsidiary"
                        value={singleExpenditure?.shared_service_details}
                        isMulti
                        // onSe
                        onChange={(e, objAction) => {
                          // setAllSelectedSubsidiary(e);
                          setSingleExpenditure((prev) => {
                            return { ...prev, shared_service_details: e };
                          });
                          const obj = {
                            reason: true,
                            id: objAction.option?.value,
                          };

                          const getDeptBySub = async (objVal) => {
                            // console.log(objVal);
                            // return
                            const data = await dispatch(
                              getDepartmentsBySubsidiary(objVal)
                            );
                            if (data?.payload?.success) {
                              const departMentss =
                                data?.payload?.data?.departments;
                              handleMultiSelectChange(
                                e,
                                objAction,
                                departMentss
                              );
                            }
                          };
                          objAction?.option && getDeptBySub(obj);
                        }}
                        options={singleExpenditure?.subBudgetOption}
                        // options={subOption}
                        styles={reactSelectStyle}
                      />
                    </div>
                    {/* select subsidiary end */}
                    {/* selected main wrapper bix show -------- */}
                    <div className="selected-main-wrapper-box">
                      {singleExpenditure?.shared_service_details?.map(
                        (chi, idx) => {
                          const {
                            apportions,
                            departments_shared,
                            label,
                            value,
                            department_drop,
                            departments,
                          } = chi;
                          // console.log(chi, "chi");
                          return (
                            <div className="selected-item-sub" key={idx}>
                              <p className="title">
                                <span>{label} Subsidiary</span> with budget
                                percentage of
                                <input
                                  id="budget_percentage"
                                  name="budget_percentage"
                                  className="shared-form-input budget-input"
                                  value={apportions}
                                  onChange={(e) => {
                                    handleNewBudgetPercentage(
                                      e?.target?.value <= 100
                                        ? e?.target?.value
                                        : apportions,
                                      chi?.value
                                    );
                                  }}
                                />
                              </p>
                              {/* selected box start --- */}
                              {/* form-group start */}
                              <div
                                className="shared-item-box"
                                style={{ margin: "1.5rem 0rem" }}
                              >
                                <label className="shared-budget-label">
                                  Select Department<span>*</span>
                                </label>
                                <Select
                                  isMulti
                                  styles={reactSelectStyle}
                                  isLoading={loadingPage}
                                  className="select-wrap select-wrap-multi"
                                  value={departments_shared}
                                  options={handleFormatDepartmentDrop(
                                    departments
                                  )}
                                  // options={departmentDrop}
                                  onChange={(e, objAction) => {
                                    handleMultiSelectChangeTwo(
                                      e,
                                      objAction,
                                      departments,
                                      value
                                    );
                                  }}
                                  // isDisabled
                                />
                              </div>
                              {/* form-group end */}
                              {/* selected box ebd ----- */}
                              {/* depart and shared list start */}
                              {departments_shared?.length > 0 && (
                                <div className="depart-shared-list-box">
                                  {departments_shared?.map((child, indx) => {
                                    const {
                                      apportion,
                                      department,
                                      label,
                                      value,
                                    } = child;
                                    return (
                                      <div
                                        key={indx}
                                        className="share-budget-form-box"
                                      >
                                        <p className="shared-budget-step">
                                          {indx + 1 || "1"}
                                        </p>
                                        {/* form-group start */}
                                        <div className="shared-budget-box ">
                                          <label className="shared-budget-label">
                                            Select Department<span>*</span>
                                          </label>
                                          <Select
                                            className="shared-select-wrap"
                                            value={{
                                              label,
                                              value,
                                            }}
                                            // options={departmentDrop}
                                            // onChange={(e) => setSelectSharedDept(e, child)}
                                            isDisabled
                                          />
                                        </div>
                                        {/* form-group end */}
                                        {/* form group start */}
                                        <div
                                          // style={{
                                          //   display: "flex",
                                          //   flexDirection: "column",
                                          //   gap: ".2rem",
                                          // }}
                                          className="shared-budget-box"
                                        >
                                          <label className="shared-budget-label">
                                            Apportion Budget( % )<span>*</span>
                                          </label>

                                          <input
                                            className={`shared-form-input ${
                                              apportion &&
                                              "shared-form-input-active"
                                            }`}
                                            // decimalScale={3}
                                            // decimalSeparator="."
                                            type="number"
                                            name="apportion_budget"
                                            // thousandsGroupStyle={"lakh"}
                                            // allowNegative
                                            onChange={(e) => {
                                              const newValue = e.target.value;
                                              handleApportionDeptChange(
                                                chi?.value,
                                                child?.value,
                                                newValue <= 100
                                                  ? newValue
                                                  : apportion
                                              );
                                            }}
                                            value={apportion}
                                            id="apportion_budget"
                                            // disabled={disable}
                                            // placeholder="Specify Budget"
                                          />
                                        </div>
                                        {/* form group end */}
                                        <div
                                          className="cancel-icon-wrap"
                                          onClick={() => {
                                            // handleRemoveDeptBudget(child);
                                            handleDeleteChildFromAll(
                                              chi?.value,
                                              child?.value
                                            );
                                          }}
                                        >
                                          <FaMinus className="minus-icon" />
                                        </div>
                                        <br />
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {/* depart and shared list end */}
                            </div>
                          );
                        }
                      )}
                    </div>
                    {/* selected main wrapper bix show end -------- */}
                    {/* new  design box end -------------------- */}

                    {/* <div> */}

                    {/* sharing percentage form wrap end*/}
                    {/* <ButtonBlue text={"Submit"} /> */}
                    {/* </div> */}
                  </div>
                )}

                {/* shared budget form wrap end */}
              </div>
            </div>
            {/* second wrap end*/}
            <div className="wrapper"></div>
            {/* btn box start */}

            {/* button start */}
            <div style={{ display: "flex", gap: "3rem" }}>
              {loading || updateLoader ? (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <input
                  // disabled={!enableSubmit()}
                  onClick={handleSharedSum}
                  type="submit"
                  value={
                    singleExpenditure?.is_submitted === 0 ? "Update" : "Update"
                  }
                  className={"form-group form-submit form-submit-active"}
                  // className={`form-group form-submit ${
                  //   enableSubmit() && "form-submit-active"
                  // }`}
                  style={{ height: "5rem" }}
                />
              )}
              {/* button end */}
            </div>
            {/* button end */}

            {/* btn box end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
      {/* modal start */}
      <Modal effect="fadeInUp" visible={showModal}>
        <EmployeeBulkModal onCancel={() => setShowModal(false)} />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default ExpenditureProjectionUpdate;
