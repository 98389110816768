import React, { useState, useEffect, useContext } from "react";
// import ZojatechContext from "../store/ZojatechContext"
import { Route, Redirect, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../component/layout/dashboard/DashboardLayout";
import ActionContext from "../context/ActionContext";
import { customErrorId } from "../helper/Helpers";
import instance from "../utils/axios";
// import DashboardLayout from "../component/dashboard/layout";
// import DashboardLayout from "../components/DashboardLayout";
// import {  useSelector } from "react-redux";

const PrivateRoute = ({
  component: Component,
  pageTitle,
  pageTitleSpan,
  goBack,
  ...rest
}) => {
  const location = useLocation();
  const actionCtx = useContext(ActionContext);
  const [pagetitle, setPageTitle] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [psmallTitle, setSmallTitle] = useState("");

  const login = JSON.parse(localStorage.getItem("login")) || false;
  const token = localStorage.getItem("token");

  useEffect(() => {
    setPageTitle(pageTitle);
    setSmallTitle(pageTitleSpan);
    // if(l)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, pagetitle, pageTitleSpan]);

  const urlSave = location.pathname.split("/")[1];

  instance.interceptors.response.use(
    async (response) => {
      // console.log(response);
      if (
        response?.response?.status === 500) {
          toast.error(`${response?.response?.data?.message}`, {
            theme: "colored",
            toastId: customErrorId,
          });
        return ;
      }
      if (
        response?.response?.status === 403 ||    response?.response?.status === 503
      ) {
        actionCtx?.setServerDown(true);
        return ;
      }
    
      if (
        response?.response?.status === 401 &&
        response?.response?.data?.message === "Not authorized!"
      ) {
        actionCtx?.setTokenCrashed(true);
        actionCtx?.setServerDown(false);
        return ;
      }
      actionCtx?.setServerDown(false);
      return response;
    },
    async (error) => {
      // console.log(error);
      if (error?.response?.status === 422) {
        actionCtx?.setServerDown(false);
        toast.error(`${error?.response?.data?.message}`, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(error);
        // return;
      }
      if (error?.response?.status !== 401) {
        // console.log(error);
        actionCtx?.setServerDown(false);
        return error;
      }
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.message === "Not authorized!"
      ) {
        actionCtx?.setServerDown(false);
        actionCtx?.setTokenCrashed(true);
        return error;
      }
      return error;
    }
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        login && token ? (
          <DashboardLayout
            goBack={goBack}
            pageTitleSpan={pageTitleSpan}
            pageTitle={pageTitle}
          >
            <Component
              {...props}
              pageTitleSpan={pageTitleSpan}
              pageTitle={pagetitle}
            />
          </DashboardLayout>
        ) : location.pathname.includes("/dashboard-single-approve") ? (
          <Redirect to={`/login?${urlSave}`} />
        ) : (
          <Redirect to={`/login`} />
        )
      }
    />
  );
};

export default PrivateRoute;
