import React, { useContext, useEffect, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import EmployeeRevenueProjectionMonthlyForm from "./MonthlyForm";
import EmployeeRevenueProjectionSpreadForm from "./SpreadForm";
import Modal from "react-awesome-modal";
import { useHistory } from "react-router-dom";
import { HiInformationCircle } from "react-icons/hi";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import { getProducts } from "../../../../../../redux/budget-setting/revenue-projection/product";
import { getVerticals } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
// import ActionContext from "../../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  createRevenueProjection,
  getProductByPortfolio,
  RevenueProjectionDownload,
} from "../../../../../../redux/employee/revenue-projection/revenue-projection";
import {
  formatNumber,
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { Dna } from "react-loader-spinner";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import EmployeeRevBulkModal from "./modals/bulkUpload";
import { getPortfolios } from "../../../../../../redux/budget-setting/revenue-projection/portfolio";
import {
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { checkDepartmentApprovalFlow } from "../../../../../../redux/employee/approval-flow";
import RevvexActionBar from "../../../../../../component/fragments/ActionBar";
import ActionContext from "../../../../../../context/ActionContext";

const EmployeeNewRevenueProjection = () => {
  const [spreadForm, setSpreadForm] = useState(false);
  // const [btnChange, setBtnChange] = useState(false);
  const [monthlyForm, setMonthlyForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { loading, productByPort, portLoading } = useSelector(
    (state) => state.revenue_projection
  );
  const { user } = useSelector((state) => state.user);
  // const { productDrop } = useSelector((state) => state.product);
  const { verticalDrop } = useSelector((state) => state.vertical);
  // const { isLoading } = useSelector((state) => state.portfolio);
  const { portfolioDrop, isLoading } = useSelector((state) => state.portfolio);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const [refreshTable] = useState(false);
  const [totalRev, setTotalRev] = useState("");
  const [totalGp, setTotalGp] = useState("");
  const [totalQuatity, setTotalQuatity] = useState("");
  const [totalRevProject, settotalRevProject] = useState("");
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const { departmentDrop, loadingPage } = useSelector(
    (state) => state.department
  );
  const [approvalFlowAlert, setApprovalFlowAlert] = useState("");
  const [changeLabel, setChangeLabel] = useState({
    inputLabel: "Quantity",
    totalLabel: "Total Quantity",
  });

  const [draftLoader, setDraftLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);

  const [monthTotalGp, setMonthTotalGp] = useState("");
  const [details, setDetails] = useState({
    portfolio_id: "",
    product_id: "",
    organization_id: "",
    subsidiary_id: "",
    vertical_id: "",
    fiscal_year_id: "",
    clientele_account: "",
    projection_type: "",
    description: "",
    unit_price: "",
    unit: "",
    total_revenue_project: "",
    gpp: "",
    total_gross_profit: "",
    // status: "",
    is_submitted: "",
    month: [{ name: { label: "" }, value: "" }],
    total_quantity: "",
    entry_type: "",
    department_id: "",
    is_allowed: 0,
  });

  const entryType = [
    {
      label: "Quantity",
      value: 1,
    },
    {
      label: "Amount",
      value: 2,
    },
  ];

  const enableSubmit = () => {
    let allowSubmit = false;

    allowSubmit =
      details?.product_id &&
      details?.vertical_id &&
      details?.portfolio_id &&
      details?.fiscal_year_id &&
      details?.subsidiary_id &&
      // details?.clientele_account &&
      details?.department_id &&
      approvalFlowAlert == "" &&
      details?.description;
    if (details?.projection_type === "yearly_spread") {
      allowSubmit =
        allowSubmit && details?.unit && details?.unit_price && details?.gpp;
    } else {
      allowSubmit =
        allowSubmit &&
        details?.unit_price &&
        details?.entry_type &&
        details?.month[0] &&
        details?.gpp;
    }
    return allowSubmit;
  };

  // console.log("details?.projection_type", details?.projection_type);
  // console.log("details?.month", details?.month);

  useEffect(() => {
    if (details?.projection_type === "yearly_spread") {
      const totalRevPro =
        formatNumberWithoutComma(String(details?.unit_price)) *
        formatNumberWithoutComma(String(details?.unit));
      const round = totalRevPro || 0;
      setTotalRev(round);
      const totalRevProVal =
        formatNumberWithoutComma(String(details?.unit_price)) *
        formatNumberWithoutComma(String(details?.unit));
      const totalGrossProfit =
        (totalRevProVal * formatNumberWithoutComma(String(details?.gpp))) / 100;
      const rounded = totalGrossProfit || 0;
      setTotalGp(rounded);
    }
    // if (details?.projection_type === "yearly_spread") {
    // }
    if (
      details?.projection_type === "monthly_spread" &&
      details?.entry_type?.label === "Quantity"
    ) {
      const totalQua = details?.month?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      // setTotalValue(totalQuaAddList);
      // const rounded = totalQuaAddList || 0;
      setTotalQuatity(totalQuaAddList);
      let totalVal = totalQuaAddList;
      if (details.entry_type?.label === "Quantity") {
        totalVal =
          totalQuaAddList *
          formatNumberWithoutComma(String(details.unit_price || 0));
      }
      // const totalRoundVal = totalVal || 0;
      settotalRevProject(totalVal);
      // console.log(totalVal);
      // const totalGP =
      //   (totalVal * formatNumberWithoutComma(String(details.gpp || 0))) / 100;
      // const totalRoundGp = totalGP || 0;
      // setMonthTotalGp(totalRoundGp);
      const totalGp =
        (formatNumberWithoutComma(String(details?.gpp || 0)) / 100) * totalVal;
      // const totalRoundGp = totalGp || 0;
      setMonthTotalGp(totalGp);
    }

    if (
      details?.projection_type === "monthly_spread" &&
      details?.entry_type?.label === "Amount"
    ) {
      // sum of all month values start
      const totalQua = details?.month?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      const rounded = totalQuaAddList || 0;
      settotalRevProject(rounded);
      // sum of all month values end
      // total gp sum start
      const totalGp =
        (formatNumberWithoutComma(String(details?.gpp || 0)) / 100) *
        totalQuaAddList;
      const totalRoundGp = totalGp || 0;
      setMonthTotalGp(totalRoundGp);
      // total gp sum end
      // total quantity calc start
      if (details?.unit_price) {
        const totalQuantityVal =
          totalQuaAddList /
          formatNumberWithoutComma(String(details?.unit_price || 0));
        const totalQuatityRound = totalQuantityVal || 0;
        setTotalQuatity(totalQuatityRound);
      }
    }
  }, [details]);

  useEffect(() => {
    const chartAccObj = {
      id: actionCtx?.activeOrg?.id,
      reason: true,
    };
    dispatch(getDepartments(actionCtx?.activeOrg?.id));
    dispatch(getFiscalYears(chartAccObj));
    // dispatch(getPortfolios(chartAccObj));
    dispatch(getUserDepartment(actionCtx?.activeOrg?.id));
    dispatch(getPortfolios({ org_id: actionCtx?.activeOrg?.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, actionCtx?.activeOrg?.id]);

  // useEffect(() => {
  //   handleGetProduct();
  // }, [details?.portfolio_id]);

  const handleSelectOptionToMonthList = (e, item) => {
    // // console.log(e);
    // // console.log(item);
    const newList = details?.month?.map((chi) =>
      chi?.id === item?.id ? { ...chi, month_name: e } : chi
    );
    setDetails((prev) => {
      return { ...prev, month: newList };
    });
  };

  const handleInputOptionToMonthList = (e, item) => {
    const newList = details?.month?.map((chi) =>
      chi?.id === item?.id ? { ...chi, value: e?.target?.value } : chi
    );
    setDetails((prev) => {
      return { ...prev, month: newList };
    });
  };

  const handleRemoveOptionMonthList = (item) => {
    const newList = details?.month?.filter((chi) => chi?.id !== item?.id);
    setDetails((prev) => {
      return { ...prev, month: newList };
    });
  };

  const handleAddToMonthList = () => {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const obj = {
      id: id,
      name: { label: "", value: "" },
      value: "",
    };
    const newList = {
      ...details,
      month: [...details.month, obj],
    };
    setDetails(newList);
    // console.log(newList);
    // // console.log(test);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  useEffect(() => {
    // dispatch(getCountries())
    const empObj = {
      id: actionCtx?.activeOrg?.id || actionCtx?.activeOrg?.id,
      reason: true,
    };
    user?.organizations?.length > 0 && dispatch(getSubsidiaries(empObj));
    dispatch(getProducts(empObj));
    dispatch(getVerticals(empObj));

    // eslint-disable-next-line
  }, [refreshTable, actionCtx?.activeOrg?.id]);

  // const department_id =
  const history = useHistory();

  const handleApprovalFlowCheckByDept = async () => {
    const checkObj = {
      projection_type: "Revenue Projection",
      department_id: details?.department_id?.value,
    };
    const checkData = await dispatch(checkDepartmentApprovalFlow(checkObj));
    // console.log(checkData, "data");
    if (checkData?.payload?.status !== 201) {
      setApprovalFlowAlert(checkData?.payload?.message);
    } else {
      setApprovalFlowAlert("");
    }
  };

  useEffect(() => {
    if (details?.department_id?.value) {
      handleApprovalFlowCheckByDept();
    }
  }, [details?.department_id?.value]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const checkObj = {
    //   projection_type: "Revenue Projection",
    //   department_id: details?.department_id?.value,
    // };
    // details?.is_submitted === 0 && setDraftLoader(true);
    // details?.is_submitted === 1 && setApproveLoader(true);
    // const checkData = await dispatch(checkDepartmentApprovalFlow(checkObj));
    // if (checkData?.payload?.status !== 201) {
    //   details?.is_submitted === 0 && setDraftLoader(false);
    //   details?.is_submitted === 1 && setApproveLoader(false);
    //   return;
    // }
    const newList = details.month.map((chi) => {
      return {
        value: formatNumberWithoutComma(String(chi.value)),
        month_name: chi?.month_name?.label,
      };
    });
    // // console.log("tot rev: ", totalRev);
    const obj = {
      ...details,
      product_id: details?.product_id?.id,
      subsidiary_id: details.subsidiary_id?.value,
      vertical_id: details.vertical_id?.value,
      department_id: details?.department_id?.value,
      fiscal_year_id: details?.fiscal_year_id?.value,
      portfolio_id: details?.portfolio_id?.value,
      organization_id: actionCtx?.activeOrg?.id,
      month: details?.projection_type === "monthly_spread" ? newList : null,
      total_revenue_project: !totalRev ? totalRevProject : totalRev,
      total_gross_profit: !totalGp ? monthTotalGp : totalGp,
      // department_id: details?.department_id?.value,
      // fiscal_year_id: details?.fiscal_year_id?.value,
      is_submitted: details?.is_submitted === 1 ? 1 : 0,
      gpp: formatNumberWithoutComma(String(details?.gpp)),
      total_quantity:
        details?.projection_type === "monthly_spread"
          ? formatNumberWithoutComma(String(parseInt(totalQuatity)))
          : null,
      // unit:
      //   details?.projection_type === "monthly_spread"
      //     ? totalQuatity
      //     : formatNumberWithoutComma(String(details?.unit)),
      unit: !!formatNumberWithoutComma(String(details?.unit))
        ? formatNumberWithoutComma(String(details?.unit))
        : formatNumberWithoutComma(String(totalQuatity)),
      unit_price: Math.trunc(formatNumberWithoutComma(details?.unit_price)),
      entry_type:
        details?.projection_type === "monthly_spread"
          ? details?.entry_type?.label.toLowerCase()
          : null,
    };

    if (obj?.is_submitted === 0) {
      setDraftLoader(true);
    }
    if (obj?.is_submitted === 1) {
      setApproveLoader(true);
    }
    const data = await dispatch(createRevenueProjection(obj));
    if (data?.payload?.success && obj?.is_submitted === 0) {
      setDraftLoader(false);
      history.push("/employee-dashboard-projection-revenue");
    } else if (data?.payload?.success && obj?.is_submitted === 1) {
      setApproveLoader(false);
      history.push("/employee-dashboard-projection-revenue-submitted");
    } else {
      setDraftLoader(false);
      setApproveLoader(false);
    }
    // console.log(obj);
  };

  const handleSpreadForm = () => {
    setSpreadForm(true);
    setMonthlyForm(false);
    // setBtnChange(true);
  };

  const handleMonthlyForm = () => {
    setMonthlyForm(true);
    setSpreadForm(false);
    // setBtnChange(true);
  };

  const formatPercentage = (param) => {
    const splitVal = param.split("%");
    return Number(splitVal[0]);
  };

  // const handleGetProductDrop = async (param) => {
  //   const data = await dispatch(getProductByPortfolio(param));
  //   console.log(data);
  // };

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Revenue Projection`}> */}
      {approvalFlowAlert && (
        <RevvexActionBar
          type={"warning"}
          visible={approvalFlowAlert}
          msg={approvalFlowAlert}
          onCancel={() => {
            setApprovalFlowAlert("");
          }}
          // actionText={"Submitted Projections"}
        />
      )}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">New Revenue Projection</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          <div className="btn-download-box">
            <button className={`btn-bulk `} onClick={() => setShowModal(true)}>
              <FaPlus className="icon" /> Bulk Upload
            </button>
            {loading ? (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <p
                className="text"
                onClick={() => dispatch(RevenueProjectionDownload())}
              >
                See and download template
              </p>
            )}
          </div>
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container */}

        <div className="form-box" style={{ width: "80%" }}>
          <form onSubmit={handleSubmit} action="" className="form">
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Projection Information</p>
              <div className="form-wrap">
                {/* group box start */}

                {/* group start */}
                <div className="form-group">
                  <label htmlFor="portfolio" className="label">
                    Portfolio <span>*</span>
                  </label>

                  <Select
                    className={`select-wrap ${
                      details?.portfolio_id && "select-wrap-active"
                    }`}
                    placeholder="Select Portfolio"
                    // isDisabled={true}
                    value={details?.portfolio_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          portfolio_id: e,
                        };
                      });
                      dispatch(getProductByPortfolio(e?.id));
                    }}
                    styles={reactSelectStyle}
                    options={portfolioDrop}
                    isLoading={isLoading}
                  />
                </div>
                {/* group end */}

                <div className="form-group">
                  <label htmlFor="product" className="label">
                    Product <span>*</span>
                  </label>

                  <Select
                    className={`select-wrap ${
                      details?.product_id && "select-wrap-active"
                    }`}
                    placeholder="Select Product"
                    value={details?.product_id}
                    // isDisabled={true}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          product_id: e,
                          gpp: formatNumber(
                            String(formatPercentage(e.gp_percentage))
                          ),
                          is_allowed: e.is_allowed,
                        };
                      });
                    }}
                    isLoading={portLoading}
                    styles={reactSelectStyle}
                    options={productByPort}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="vertical" className="label">
                    vertical <span>*</span>{" "}
                    <HiInformationCircle
                      style={{ marginLeft: "3px", fontSize: "16px" }}
                    />
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.vertical_id && "select-wrap-active"
                    }`}
                    placeholder="Select Vertical"
                    value={details.vertical_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, vertical_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={verticalDrop}
                    isLoading={isLoading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    placeholder="Select Subsidiary"
                    value={details?.subsidiary_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                      const obj = {
                        reason: true,
                        id: e?.value,
                      };
                      dispatch(getDepartmentsBySubsidiary(obj));
                    }}
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    isLoading={isLoading}
                  />
                </div>
                {/* group box end */}

                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Department <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.department_id && "select-wrap-active"
                    }`}
                    placeholder="Select Department"
                    value={details?.department_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, department_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    isLoading={loadingPage}
                    options={details?.subsidiary_id ? departmentDrop : []}
                  />
                </div>
                {/* group box start */}
                {/* group box start */}
                <div className="form-group">
                  <label
                    htmlFor="subsidiary"
                    className="label"
                    style={{ marginTop: "3.6px" }}
                  >
                    Company Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="e.g Toyota"
                    id="clientele_account"
                    value={details?.clientele_account}
                    name="clientele_account"
                    onChange={handleChange}
                    className={`input ${
                      details?.clientele_account && "input-active"
                    }`}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Fiscal Year <span>*</span>
                  </label>
                  <Select
                    placeholder="Select fiscal year"
                    className={`select-wrap ${
                      details.fiscal_year_id && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    value={details?.fiscal_year_id}
                    isLoading={isLoading}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, fiscal_year_id: e };
                      });
                    }}
                    options={fiscalYearDrop}
                  />
                </div>
                {/* group box end */}
                <br />
                <div className="form-group">
                  <label htmlFor="description" className="label">
                    Description <span>*</span>
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={handleChange}
                    style={{ width: "206%", resize: "none", padding: "30px" }}
                    cols="30"
                    rows="7"
                    placeholder="Enter description..."
                    className={`input ${details.description && "input-active"}`}
                    value={details.description}
                  />
                </div>
                {/* group box end */}
              </div>
              <div className="">
                {/* second wrap start */}
                {/* <div className="wrapper"></div> */}
                <div>
                  <div className="wrapper"></div>
                  <p className="box-title">2. Projection Format</p>
                </div>
                {/* form check bos start */}
                <div className="form-check-box" style={{ width: "50rem" }}>
                  {/* check group start */}
                  <div className="check-group" onChange={handleSpreadForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-spread-evenly"
                      value="yearly_spread"
                      checked={details?.projection_type === "yearly_spread"}
                      onChange={(e) => {
                        setDetails((prev) => {
                          setMonthTotalGp("");
                          settotalRevProject("");
                          setTotalQuatity("");
                          return {
                            ...prev,
                            projection_type: e.target.value,
                            unit_price: "",
                            entry_type: "",
                            month: [{ name: { label: "" }, value: "" }],
                            // gpp: "",
                          };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-spread-evenly"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Spread Evenly </p>
                      <span>{""}</span>
                      <p className="text-two">
                        (share units and value accross all items)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                  {/* check group start */}
                  <div className="check-group" onChange={handleMonthlyForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-monthly-entry"
                      value="monthly_spread"
                      checked={details?.projection_type === "monthly_spread"}
                      onChange={(e) => {
                        setDetails((prev) => {
                          setTotalRev("");
                          setTotalGp("");
                          return {
                            ...prev,
                            projection_type: e.target.value,
                            unit: "",
                            unit_price: "",
                            entry_type: "",
                            // gpp: "",
                          };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-monthly-entry"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Monthly Entry</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (specify type and unit value for each item)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                </div>

                {spreadForm && (
                  <EmployeeRevenueProjectionSpreadForm
                    totalDisabled={details?.is_allowed === 0}
                    onInputChange={handleChange}
                    price_unit={details?.unit_price}
                    units={details?.unit}
                    gross_profit_per={details?.gpp}
                    total_rev_pro={totalRev || ""}
                    total_gross_pro={totalGp || ""}
                    onGpPercentChange={(e) => {
                      if (!isNaN(e.target.value) && e.target.value <= 100) {
                        setDetails((prev) => {
                          return {
                            ...prev,
                            gpp: e?.target?.value,
                          };
                        });
                      } else {
                        setDetails((prev) => {
                          return {
                            ...prev,
                            gpp: "",
                          };
                        });
                      }
                      console.log(details?.gross_profit_percent, "gross");
                    }}
                  />
                )}
                {monthlyForm && (
                  <EmployeeRevenueProjectionMonthlyForm
                    totalDisabled={details?.is_allowed === 0}
                    onSelectvalueChange={handleSelectOptionToMonthList}
                    onInputChange={handleChange}
                    entryType={entryType}
                    // quantity={details?.unit}
                    price_unit={details?.unit_price}
                    handleEntryTypeChange={(e) => {
                      // console.log("check", e);
                      // console.log(e.value);
                      e.value === 2
                        ? setChangeLabel((prev) => {
                            return {
                              ...prev,
                              inputLabel: "Amount",
                              totalLabel: "Total Quantity",
                            };
                          })
                        : setChangeLabel(
                            !changeLabel.inputLabel,
                            !changeLabel.totalLabel
                          );
                      setDetails({ ...details, entry_type: e });
                    }}
                    list={details?.month}
                    handleAddToList={handleAddToMonthList}
                    onInputValueChange={handleInputOptionToMonthList}
                    handleRemoveMonthList={handleRemoveOptionMonthList}
                    total_rev_project={totalRevProject || ""}
                    total_gross_pro={monthTotalGp || ""}
                    Total_quantity_label={changeLabel?.totalLabel}
                    quantity_label={changeLabel?.inputLabel}
                    gross_profit_percent={details?.gpp}
                    totalQuantity={totalQuatity || ""}
                    entry_type_value={details?.entry_type}

                    // totalQuantity={}
                  />
                )}
                {/* form check bos end */}
              </div>{" "}
            </div>
            {/* second wrap end*/}
            <div className="wrapper"></div>

            <div style={{ display: "flex", gap: "3rem" }}>
              {/* button start */}
              {!approveLoader ? (
                <input
                  onClick={() => setDetails({ ...details, is_submitted: 1 })}
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Submit for Approval"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{ height: "5rem" }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="40"
                    width="100"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
              {!draftLoader ? (
                <input
                  onClick={() => {
                    setDetails({ ...details, is_submitted: 0 });
                  }}
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Save To Draft"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{
                    height: "5rem",
                    background: "#ffff",
                    color: !enableSubmit() ? "#cccc" : "var(--blue-color)",
                    border: !enableSubmit()
                      ? ".1rem solid #cccc"
                      : ".1rem solid var(--blue-color)",
                  }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="40"
                    width="40"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
              {/* button end */}
            </div>
            {/* button end */}

            {/* btn box end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout>   */}
      {/* modal start */}
      <Modal effect="fadeInUp" visible={showModal}>
        <EmployeeRevBulkModal onCancel={() => setShowModal(false)} />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeNewRevenueProjection;
