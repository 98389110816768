import RevenueProjectionIndex from "../../../component/pages/dashboard/projection/revenue";
import AwaitingCurrentrevProRevenue from "../../../component/pages/dashboard/projection/revenue/AwaitingCurrentrevPro";
import RevenueApproved from "../../../component/pages/dashboard/projection/revenue/RevenueApproved";
import RevenueAwaitApproval from "../../../component/pages/dashboard/projection/revenue/RevenueAwaitApproval";
import RevenueProjectionMain from "../../../component/pages/dashboard/projection/revenue/RevenueProjectionMain";
import RevenueRejected from "../../../component/pages/dashboard/projection/revenue/RevenueRejected";

export const Revenue_projection_route_group = [
  {
    path: "/dashboard-projection-revenue",
    component: RevenueProjectionIndex,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/dashboard-projection-revenue-approved",
    component: RevenueApproved,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/dashboard-projection-revenue-awaiting-approval",
    component: RevenueAwaitApproval,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/dashboard-projection-revenue-rejected",
    component: RevenueRejected,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/dashboard-projection-revenue-main",
    component: RevenueProjectionMain,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
  {
    path: "/dashboard-projection-revenue-approve-cur",
    component: AwaitingCurrentrevProRevenue,
    pageTitle: "Revenue Projection",
    goBack: true,
  },
];
