import React from "react";
// import { ToastContainer } from "react-toastify";
// import CommonSortBox from "../../../../../../component/fragments/CommonSortBox";
// import CommonNavLink from "../../../../../../component/pages/dashboard/actuals/expense/CommonNavLink";
// import ActionContext from "../../../../../../context/ActionContext";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
// import ActionContext from "../../../../../context/ActionContext";
import EmployeeCommonNavLink from "./CommonNavLink";
import EmployeeActualSubmitApproved from "./links/approveNavLink";
import EmployeeActualDraftNavLink from "./links/draftExpenseLink";
import EmployeeActualSubmitPending from "./links/pendingLink";
import EmployeeActualSubmitReject from "./links/rejectLink";
// import EmployeeActualDraftExpenseNavLink from "./links/draftExpenseLink";
// import EmployeeCommonNavLink from "./CommonNavLink";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { Dna } from "react-loader-spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EmployeeActualDraftExpense from "../actualDraftExpense";
import { FaPlus } from "react-icons/fa";

const EmployeeCommonExpenseLayout = ({
  children,
  chartBox,
  draftExpense,
  commonLink,
  approvedLink,
  pendingLink,
  rejectLink,
  options,
  exportVal,
  onExport,
  exportDrop,
  onGetDept,
  supplementaryLink,
  exportSelect,
  onFileDownload,
  loading,
  showCommonLink,
}) => {
  // const actionCtx = useContext(ActionContext);
  const history = useHistory();
  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Actual Expense`}> */}
      <div className="expense-projection-inde-wrap">
        {/* {exportDrop && (
          <div className="header-submit-filter">
            <label>Export</label>
            <Select
              styles={reactSelectStyle}
              className="select-filter-by"
              options={options}
              placeholder="File Type"
              value={exportVal}
              onChange={(e) => onExport(e)}
            />
          </div>
        )} */}
        <>
          {commonLink && (
            <EmployeeCommonNavLink link={"/employee-dashboard-home"} />
          )}
          {draftExpense && (
            <EmployeeActualDraftNavLink link={"/employee-dashboard-home"} />
          )}
          {approvedLink && (
            <EmployeeActualSubmitApproved link={"/employee-dashboard-home"} />
          )}
          {pendingLink && (
            <EmployeeActualSubmitPending link={"/employee-dashboard-home"} />
          )}
          {rejectLink && (
            <EmployeeActualSubmitReject link={"/employee-dashboard-home"} />
          )}
        </>
        {/* common links end */}
        {/* chart and table box start */}
        <>{chartBox}</>
        {/* chart  box end */}
        <>{children}</>
        {/* children box start */}
        {showCommonLink && (
          <EmployeeActualDraftExpense>
            <ButtonBlue
              onClick={() =>
                history.push("/employee-dashboard-actual-expense-new")
              }
              text={`Create Expense`}
            >
              <FaPlus style={{ marginRight: "15px" }} />
            </ButtonBlue>
          </EmployeeActualDraftExpense>
        )}

        {/* children box end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};

export default EmployeeCommonExpenseLayout;
