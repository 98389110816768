import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../../../../../../styles/dashboard/tables/ActualReuseableTable.css";
// import Select from "react-select";

const ProjectionPendingLink = () => {
  const history = useHistory();
  const location = useLocation();

  const links = [
    {
      name: "Draft Projections",
      link: "/employee-dashboard-projection-expenditure",
    },
    {
      name: "Submitted Projections",
      link: "/employee-dashboard-projection-expenditure-submitted-pending",
    },
  ];

  return (
    <div className="common-link-wrap" style={{ position: "relative" }}>
      {links.map((child, idx) => {
        const { name, link } = child;
        return (
          <p
            onClick={() => history.push(link)}
            key={idx}
            className={`link ${location.pathname === link && "link-active"}`}
          >
            {" "}
            <span>{name}</span>
          </p>
        );
      })}
    </div>
  );
};

export default ProjectionPendingLink;
