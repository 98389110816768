import React, { useState, useEffect, useContext } from "react";
import { FaAngleRight  } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { getCountries, getOrganizations } from "../../../../../redux/user";
import Select from "react-select";
import { Dna } from "react-loader-spinner";
import { createSubsidiary } from "../../../../../redux/subsidiary/subsidiary";
import { reactSelectStyle } from "../../../../../helper/Helpers";
// import {  useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { getCountries, getUserDetails } from "../../../../../redux/user";
import { getUsers } from "../../../../../redux/users/users";
import ActionContext from "../../../../../context/ActionContext";

const AddSubsidiary = () => {
  const actionCtx = useContext(ActionContext)
  // const [color, setColor] = useColor("hex", "#004BFF");
  const { organizations , countries} = useSelector((state) => state.user);
  // const history = useHistory()
  const { loading } = useSelector((state) => state.subsidiary);
  const history = useHistory();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    name: "",
    country: "",
    address: "",
    organization_id: "",
    brand_color: "",
  });

  useEffect(() => {
    if(actionCtx?.activeOrg?.type !== "group"){
      history?.goBack()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...details,
      country: details.country.label,
      organization_id: details.organization_id.value,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(createSubsidiary(obj));
    // console.log(data);
    if (data?.payload.success) {
      setDetails({
        name: "",
        country: "",
        address: "",
        organization_id: "",
        brand_color: "",
      });
      dispatch(getUserDetails());
      if(actionCtx?.activeOrg?.check_list?.percentage !== 100 ){
        dispatch(getUsers())  
      }
      history.push("/dashboard-administration-admin-settings-subsidaries-main")
    }
  };

  useEffect(() => {
    // dispatch(getOrganizations());
    dispatch(getCountries())
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitleSpan={`Step 2 of 6`}
        pageTitle={`Add Subsidiary`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-subsidaries-main"
              )
            }
            className="text"
          >
            Subsidiary
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Add Subsidiaries</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Add Subsidiary</p>
            <p className="text">
              Set your organization subsidiary . Once added, you can easily
              switch between created subsidiaries of the organization.
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          {/* <div className="btn-download-box">
            <button
              className={`btn-bulk ${
                details.address ||
                details.name ||
                details.organization_id ||
                details.organization_id
                  ? "btn-bulk-inactive"
                  : ""
              }`}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div> */}
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1.Subsidiary Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary Name" className="label">
                    Subsidiary Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Subsidiary Name"
                    className={`input ${details.name && "input-active"}`}
                    id="Subsidiary Name"
                    name="name"
                    onChange={handleChange}
                    value={details.name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Country" className="label">
                    Country <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.country && "select-wrap-active"
                    }`}
                    placeholder="Country"
                    value={details.country}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, country: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={countries}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary Address" className="label">
                    Subsidiary Address <span>*</span>
                  </label>
                  <input
                    name="address"
                    onChange={handleChange}
                    value={details.address}
                    type="text"
                    placeholder="Subsidiary Address"
                    className={`input ${details.address && "input-active"}`}
                    id="Subsidiary Address"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary of" className="label">
                    Subsidiary of
                    <figure
                      className="img-box"
                      style={{
                        filter: "saturate(0%)",
                        transform: "translateX(.5rem) translateY(.3rem)",
                      }}
                    >
                      <img src={noteIcon} alt="" className="img" />
                    </figure>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.organization_id && "select-wrap-active"
                    }`}
                    placeholder="Select Organization"
                    value={details.organization_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, organization_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={organizations}
                  />
                  {/* <input
                      name="contact_line"
                      onChange={handleChange}
                      value={details.organization_id}
                      type="text"
                      placeholder="0909599***"
                      className={`input ${
                        details.organization_id && "input-active"
                      }`}
                      id="Subsidiary of"
                    /> */}
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            <div className="note-img-wrap">
              Important Notice:
              <figure className="img-box">
                <img src={noteIcon} alt="" className="img" />
              </figure>
            </div>
            {/* note box start */}
            <div className="note-box">
              <p>
                1. For organizations with shared budgets, you would need to have
                created at least two subsidiaries to set up a shared services
              </p>
            </div>
            {/* note box end */}

            {/* btn box start */}
            {/* <div className="btn-box-wrap"> */}
            {/* <button
                onClick={() =>
                  history.push(
                    "/dashboard-administration-admin-settings-admin-invite"
                  )
                }
                className="btn-reuse"
              >
                Go to previous step
              </button> */}
            {/* submit form start */}
            {!loading ? (
              <input
                disabled={
                  !details?.name ||
                  !details?.address ||
                  !details?.country ||
                  !details?.organization_id
                }
                type="submit"
                value="Save & Continue"
                className={`form-group btn-reuse form-submit ${
                  details?.name &&
                  details?.address &&
                  details?.country &&
                  details?.organization_id &&
                  "form-submit-active"
                }`}
                style={{ marginTop: "3rem" }}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "3rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* submit form  end */}
            {/* <button className="btn-reuse btn-two">Save & Continue</button> */}
            {/* </div> */}
            {/* btn box end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default AddSubsidiary;
