import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import BudgetMain from "..";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import CommonExpenditureLink from "../expenditure-rev/commonLink";

const AddBulkUploadTemplate = () => {
  const [details, setDetails] = useState({
    name: "",
    csv: "",
  });
  const history = useHistory();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink title={`Template For Budgets`} />
        <div className="table-box-budget">
          {/* wrap start */}
          <div className="wrapper">
            <p className="box-title">1. Template Information</p>
            <div
              className="form-wrap"
              style={{ width: "80%", gridTemplateRows: "unset" }}
            >
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="name" className="label">
                  Name <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="title your template"
                  className={`input ${details.name && "input-active"}`}
                  id="name"
                  name="name"
                  onChange={handleChange}
                  value={details.name}
                />
              </div>
              {/* group box end */}
            </div>
          </div>
          {/* wrap end */}
          {/* wrap start */}
          <div className="wrapper">
            <p className="box-title">2. Template Data</p>
            <div
              className="form-wrap"
              style={{
                width: "100%",
                gridTemplateRows: "unset",
                gridTemplateColumns: "unset",
              }}
            >
              {/* group box start */}
              {/* check start */}
              <div className="check-label-box">
                <input
                  type="checkbox"
                  className="check"
                  id="check-permission-created"
                />
                <label htmlFor="check-permission-created" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Select a CSV file to import{" "}
                </label>
              </div>
              {/* check end */}
              {/* group box end */}
            </div>
          </div>
          {/* wrap end */}
          {/* btn start */}
          <ButtonBlue
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-budget-bulk-upload-template-customize"
              )
            }
            text={`Save & Continue`}
          />
          {/* btn end */}
        </div>
      </BudgetMain>
    </>
  );
};

export default AddBulkUploadTemplate;
