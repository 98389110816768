import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectMain.css";
import Select from "react-select";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import Modal from "react-awesome-modal";
import OverviewCardExpenseModal from "./modals/OverviewCardExpenseModal";
import FinalApprovalModal from "./modals/FinalApprovalModal";
import ApprovalModal from "./modals/ApprovalModal";
import RejectModal from "./modals/RejectModal";
import ComentModal from "./modals/CommentModal";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../context/ActionContext";
import {
  getProjectionDownload,
  getRevenueProjectionChartByDepartment,
  getRevenueProjectionChartByDepartmentApproveCurrent,
  getRevenueProjectionChartByProjection,
  getRevProjectionComments,
} from "../../../../../redux/projections/RevenueProjection";
import {
  checkCurrencyFormat,
  checkCurrentApproval,
  formatNumberWithComma,
  perPageOptions,
  reactSelectStyle,
  reactSelectStyleTable,
  // FormatNumWithCurrency,
} from "../../../../../helper/Helpers";
import { getDepartments } from "../../../../../redux/department/deparment";
import { Dna } from "react-loader-spinner";

const AwaitingCurrentrevProRevenue = () => {
  const history = useHistory();
  // const [singleChild, setSingleChild] = useState({});
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { revenue_projection_single, loading, awaitingListApprove } =
    useSelector((state) => state.revenue_projection_chart);
  const [singleChild, setSingleChild] = useState({});
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    overview: false,
    approve: false,
    approve_all: false,
    reject: false,
    comment: false,
  });

  const { departmentDrop } = useSelector((state) => state.department);
  const [filterValTwo, setFilterValTwo] = useState("");
  const categoryList = [
    { label: "Current Fiscal Year", value: 1 },
    { label: "Previous Year", value: 2 },
    { label: "Today", value: 3 },
    { label: "Yesterday", value: 4 },
    { label: "Last 7 days", value: 5 },
    { label: "Monthly", value: 6 },
  ];

  const [details, setDetails] = useState({
    name: "",
    category: "",
    class: "",
    code: "",
    description: "",
  });

  const tableHeadList = [
    "Product",
    "Verticals",
    "Description",
    "Quantity",
    `Units Price ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    "Total GP",
  ];

  const [refreshTable, setRefreshTable] = useState(false);
  useEffect(() => {
    // getMainExpenseProjection();
    getPendingMyApprovalList();
    dispatch(getDepartments(actionCtx?.activeOrg?.id));
    // eslint-disable-next-line
  }, [refreshTable, actionCtx?.currencyValue, actionCtx?.perPage]);
  const [currentPage, setCurrentPage] = useState(1);
  const getMainExpenseProjection = async (dept_id) => {
    const obj = {
      dept_id:
        dept_id ||
        filterValTwo?.value ||
        localStorage.getItem("revenue-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      user_id: localStorage.getItem("revenue-pro-chart-user-id"),
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
      page: currentPage,
    };
    const data = await dispatch(getRevenueProjectionChartByDepartment(obj));
    if (data?.payload?.success) {
      // console.log(data);
    }
  };

  const getPendingMyApprovalList = async (dept_id) => {
    const obj = {
      dept_id:
        dept_id ||
        filterValTwo?.value ||
        localStorage.getItem("revenue-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      user_id: localStorage.getItem("revenue-pro-chart-user-id"),
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
      page: currentPage,
    };
    const data = await dispatch(
      getRevenueProjectionChartByDepartmentApproveCurrent(obj)
    );
    if (data?.payload?.success) {
      // console.log(data);
    }
  };

  const getSingleProjection = async (id) => {
    const obj = {
      dept_id: localStorage.getItem("revenue-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      projection_id: id,
      cur_id: actionCtx?.currencyValue?.id || "",
      // per_page: actionCtx?.perPage?.value,
    };
    dispatch(getRevenueProjectionChartByProjection(obj));
  };

  const tableFilterList = [
    { label: "All Projection", value: "" },
    { label: "Approved", value: "approved" },
    { label: "Awaiting Approval", value: "pending" },
    { label: "Rejected", value: "rejected" },
  ];
  const [filterVal, setFilterVal] = useState("");
  const handleGetMainExpenseProjectionByStatus = async (param) => {
    const obj = {
      dept_id:
        filterValTwo?.value || localStorage.getItem("revenue-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      status: param,
      cur_id: actionCtx?.currencyValue?.id || "",
      user_id: localStorage.getItem("revenue-pro-chart-user-id"),
      per_page: actionCtx?.perPage?.value,
    };
    // console.log(expenditure_projection_single);
    dispatch(getRevenueProjectionChartByDepartment(obj));
  };

  const [editList, setEditList] = useState({});
  useEffect(() => {
    setEditList(awaitingListApprove);
    // console.log(awaitingListApprove);
  }, [awaitingListApprove]);

  const handleEditListCheck = (id) => {
    const newList = editList?.data?.map((chi) =>
      chi?.id === id
        ? { ...chi, current_approver: !chi?.current_approver }
        : chi
    );
    const newObj = { ...editList, data: newList };
    setEditList(newObj);
  };

  const handleApproveAll = () => {
    const list = editList?.data?.filter(
      (chi) => chi?.status === "pending" && chi?.current_approver
    );
    const newList = list.map((chi) => chi?.id);
    // console.log(newList);
    setAllAppproveId(newList);
  };

  const handlePagination = (param) => {
    const num = param.split("=")[1];
    setCurrentPage(num);
    const obj = {
      dept_id:
        filterValTwo?.value || localStorage.getItem("expense-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id || "",
      status: filterVal.value || "",
      page: num,
      user_id: localStorage.getItem("revenue-pro-chart-user-id"),
      per_page: actionCtx?.perPage?.value,
      cur_id: actionCtx?.currencyValue?.id || "",
    };
    // console.log(obj);
    // return
    dispatch(getRevenueProjectionChartByDepartmentApproveCurrent(obj));
  };

  const handlePaginationNum = (num) => {
    // const num = param.split("=")[1];
    setCurrentPage(num);
    const obj = {
      dept_id:
        filterValTwo?.value || localStorage.getItem("expense-pro-chart-id"),
      org_id: actionCtx?.activeOrg?.id,
      status: filterVal,
      page: num,
      per_page: actionCtx?.perPage?.value,
    };
    // console.log(obj);
    dispatch(getRevenueProjectionChartByDepartmentApproveCurrent(obj));
  };

  const [allApproveId, setAllAppproveId] = useState([]);
  //   const handleApproveAll = () => {
  //     const list =
  //       revenue_projection_single?.submitted_revenueprojection?.data?.filter(
  //         (chi) => chi?.status === "pending" && chi?.id
  //       );
  //     const newList = list.map((chi) => chi?.id);
  //     setAllAppproveId(newList);
  //   };

  const handleFisnish = () => {
    actionCtx?.setIsModalOut(false);
    setShowModal({
      overview: false,
      approve: false,
      approve_all: false,
      reject: false,
      comment: false,
    });
    setRefreshTable(!refreshTable);
  };

  const exportType = [
    { label: "CSV", value: "excel" },
    { label: "PDF", value: "pdf" },
  ];
  const [formatSelect, setFormatSelect] = useState({
    label: "CSV",
    value: "excel",
  });
  const [loadingDownload, setLoadingDownload] = useState(false);
  const handleCsvDownload = async () => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      dept_id:
        filterValTwo?.value || localStorage.getItem("revenue-pro-chart-id"),
      type: "revenue_projection",
      format: formatSelect?.value,
      status: filterVal.value,
    };
    setLoadingDownload(true);
    // console.log(obj);
    // return
    const data = await dispatch(getProjectionDownload(obj));
    //  console.log(data);
    if (data?.payload?.status === 200) {
      downloadCsv(data.payload.data.url);
      setLoadingDownload(false);
    } else {
      setLoadingDownload(false);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <>
      {/* <DashboardLayout goBack pageTitle={`Revenue Projection`}> */}
      {/* main wrap start */}
      <div className="expense-projection-main-wrap">
        {/* title arrow select box start */}
        <div className="title-arrow-select-box">
          <div className="subtitle-box">
            <p onClick={() => history.goBack()} className="text">
              {revenue_projection_single?.department || ""}
            </p>
            <FaAngleRight className="icon" />
            <p
              onClick={() => {
                console.log(revenue_projection_single);
              }}
              className="active text"
            >
              {revenue_projection_single?.user || ""}
            </p>
            {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
          </div>
          {/* end of subtitle box */}
          {/* select fiscal year wrap start */}
          <div className="select-wrap">
            <label className="label" htmlFor="">
              Fiscal Year
            </label>
            <Select
              // isSearchable={true}
              // isMulti
              placeholder="Select Year"
              className="select"
              value={details.class.value}
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, class: e.value };
                });
              }}
              options={categoryList}
            />
          </div>
          {/* select fiscal year wrap end */}
          {/* select fiscal year wrap start */}
          <div style={{ marginLeft: "3rem" }} className="select-wrap">
            <label className="label" htmlFor="">
              Export type
            </label>
            <Select
              // isSearchable={true}
              // isMulti
              placeholder="Select Option"
              className="select"
              value={formatSelect}
              onChange={(e) => {
                setFormatSelect(e);
              }}
              options={exportType}
              styles={reactSelectStyle}
            />
          </div>
          {/* select fiscal year wrap end */}
        </div>
        {/* title arrow select box end */}
        {/* card detail wrap start */}

        {/* card detail wrap end */}
        {/* fitler and button box start */}
        <div className="filter-approve-btn-box">
          {/* perpage box start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              position: "relative",
              zIndex: "20",
              marginRight: "2rem",
            }}
            className="perpage-wrap"
          >
            <span style={{ fontWeight: "600" }}> All Revenue Projection</span>{" "}
            <Select
              styles={reactSelectStyleTable}
              onChange={(e) => {
                actionCtx?.setPerpage(e);
              }}
              value={actionCtx?.perPage}
              options={perPageOptions}
            />
          </div>
          {/* perpage box end */}
          {/* select fiscal year wrap start */}
          <div
            style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}
            className="select-wrap"
          >
            {/* <label className="label" htmlFor="fitler-table">
                All Projection
              </label> */}
            <Select
              // isSearchable={true}
              // isMulti
              instanceId={`filter-table`}
              // inputId="fitler-table"
              //  isLoading={true}
              placeholder=" All Projection"
              className="select"
              value={filterVal}
              onChange={(e) => {
                setFilterVal(e);
                handleGetMainExpenseProjectionByStatus(e.value);
              }}
              options={tableFilterList}
            />
            <Select
              // isSearchable={true}
              // isMulti
              instanceId={`filter-table`}
              // inputId="fitler-table"
              //  isLoading={true}
              placeholder="Filter by department"
              className="select"
              value={filterValTwo}
              onChange={(e) => {
                setFilterValTwo(e);
                getMainExpenseProjection(e.value);
              }}
              options={departmentDrop}
            />
          </div>
          {/* select fiscal year wrap end */}
          {/* btn approve start */}
          {loadingDownload ? (
            <div style={{ alignSelf: "center", marginTop: "0rem" }}>
              <Dna
                visible={true}
                height="60"
                width="120"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <>
              {revenue_projection_single?.submitted_revenueprojection?.data
                ?.length > 0 && (
                <ButtonBlue
                  onClick={handleCsvDownload}
                  style={{ marginRight: "1.5rem" }}
                  bgColor={`#cccccc`}
                  text={`Download ${formatSelect?.label}`}
                />
              )}
            </>
          )}
          <ButtonBlue
            onClick={() => {
              setShowModal((prev) => {
                return { ...prev, approve_all: true };
              });
              handleApproveAll();
            }}
            text={`Approve all`}
          />
          {/* btn approve end */}
        </div>
        {/* fitler and button box end */}
        {/* table box start */}
        <ActualReuseableTable
          useNumPagination
          currentPage={awaitingListApprove?.current_page}
          totalTableLength={awaitingListApprove?.total}
          onNumPage={(e) => {
            handlePaginationNum(e);
          }}
          dontShowBnSearch
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          // three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          six={tableHeadList[5]}
          seven
          eight
          prevPage={awaitingListApprove?.prev_page_url}
          nextPage={awaitingListApprove?.next_page_url}
          onNextPage={() =>
            handlePagination(awaitingListApprove?.next_page_url)
          }
          onPrevPage={() =>
            handlePagination(awaitingListApprove?.prev_page_url)
          }
          toPage={awaitingListApprove?.to}
          fromPage={awaitingListApprove?.from}
          totalPage={awaitingListApprove?.total}
        >
          {editList?.data?.map((chi, idx) => {
            const {
              product,
              vertical,
              unit,
              total_gross_profit,
              unit_price,
              id,
              status,
              current_approver,
            } = chi;
            return (
              <TableBodyRow
                checkBoxType
                onCheck={(e) => {
                  handleEditListCheck(id);
                }}
                checkValue={current_approver}
                checkId={`${id}-${idx}`}
                loading={loading}
                key={idx}
                one={product?.name}
                two={vertical?.name}
                progressType={status}
                four={formatNumberWithComma(String(unit))}
                five={formatNumberWithComma(String(unit_price || 0))}
                six={formatNumberWithComma(String(total_gross_profit || 0))}
                action
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                //   updateText={` Update Branch`}
                onNextPage={() => {
                  actionCtx?.setIsModalOut(true);
                  const obj = {
                    id: chi?.id,
                    cur_id: actionCtx?.currencyValue?.id || "",
                  };
                  // console.log(chi);
                  // return
                  dispatch(getRevProjectionComments(obj));
                  setSingleChild(chi);
                  setShowModal((prev) => {
                    return { ...prev, overview: true };
                  });
                  getSingleProjection(id);
                }}
                //   onUpdate={() =>
                //     history.push(
                //       "/dashboard-administration-admin-settings-branch-update"
                //     )
                //   }
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
      </div>
      {/* main wrap end */}
      {/* </DashboardLayout> */}
      {/* approve modal start */}
      <Modal effect="fadeInDown" visible={showModal.overview}>
        <OverviewCardExpenseModal
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, overview: false };
            });
          }}
          onApprove={() =>
            setShowModal((prev) => {
              return { ...prev, approve: true, overview: false };
            })
          }
          onReject={() =>
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            })
          }
        />
      </Modal>
      {/* approve modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInRight" visible={showModal.approve_all}>
        <FinalApprovalModal
          allId={allApproveId}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approve_all: false };
            })
          }
          onFinish={handleFisnish}
        />
      </Modal>
      {/* approve modal end */}

      {/* approve modal start */}
      <Modal effect="fadeInUp" visible={showModal.approve}>
        <ApprovalModal
          detail={singleChild}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approve: false, overview: true };
            })
          }
        />
      </Modal>
      {/* approve modal end */}

      {/* approve modal start */}
      <Modal effect="fadeInLeft" visible={showModal.reject}>
        <RejectModal
          detail={singleChild}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false, overview: true };
            })
          }
        />
      </Modal>
      {/* approve modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInLeft" visible={showModal.comment}>
        <ComentModal
          detail={singleChild}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, comment: false, overview: true };
            })
          }
        />
      </Modal>
      {/* approve modal end */}
    </>
  );
};

export default AwaitingCurrentrevProRevenue;
