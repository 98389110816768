import React from "react";
import { useHistory } from "react-router-dom";
// import EmployeeExpenseProjectionIndex from ".";
// import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import "../../../../../styles/dashboard/projection/expense/SubmitProjection.scss";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeExpenseSuccessTable from "./successTable";

const EmployeeFinalSubmitProjection = () => {
  const cardHeader = ["Expense Projected", "Approved", "Pending", "Rejected"];
  const cardValue = ["220,000.00", "20", "10", "5"];

  const history = useHistory();

  const link = [
    "/employee-dashboard-projection-expenditure-new",
    // "/employee-dashboard-projection-expenditure-submitted-success-approve",
    // "/employee-dashboard-projection-expenditure-submitted-success-pending",
    // "/employee-dashboard-projection-expenditure-submitted-success-rejected",
  ];

  return (
    <div>
      <EmployeeExpenseSuccessTable>
        <EmployeeReusableTable
          sort={true}
          filter={true}
          addButton={true}
          cardTitleOne={cardHeader[0]}
          cardTitleTwo={cardHeader[1]}
          cardTitleThree={cardHeader[2]}
          cardTitleFour={cardHeader[3]}
          cardBodyOne={cardValue[0]}
          cardBodyTwo={cardValue[1]}
          cardBodyThree={cardValue[2]}
          cardBodyFour={cardValue[3]}
          onPage={() => history.push(link[0])}
          cardTwo={() => history.push(link[1])}
          cardThree={() => history.push(link[2])}
          cardFour={() => history.push(link[3])}
        />
      </EmployeeExpenseSuccessTable>
    </div>
  );
};

export default EmployeeFinalSubmitProjection;
