import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import "../../../../../styles/dashboard/projection/expense/removebtn.scss";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { NumericFormat } from "react-number-format";

const EmployeeRevenueRemovableGroup = ({
  list,
  handleAddToList,
  onInputValueChange,
  onSelectvalueChange,
  handleRemoveMonthList,
  total_rev_project,
  total_gross_pro,
  gross_profit_percent,
  onInputChange,
  Total_quantity_label,
  quantity_label,
  totalQuantity,
  loading,
  totalDisabled,
}) => {
  // const [details, setDetails] = useState({ month: "", value: "" });
  // const [canEditPer, setCanEditPer] = useState(false);
  const [details] = useState({
    total_quantity: "",
    total_revenue_projection: "",
    gross_profit_percentage: "",
    total_gp: "",
  });

  const monthOption = [
    {
      label: "January",
      name: "January",
      value: "January",
    },
    {
      label: "February",
      name: "February",
      value: "February",
    },
    {
      label: "March",
      name: "March",
      value: "March",
    },
    {
      label: "April",
      name: "April",
      value: "April",
    },
    {
      label: "May",
      name: "May",
      value: "May",
    },
    {
      label: "June",
      name: "June",
      value: "June",
    },
    {
      label: "July",
      name: "July",
      value: "July",
    },
    {
      label: "August",
      name: "August",
      value: "August",
    },
    {
      label: "September",
      name: "September",
      value: "September",
    },
    {
      label: "October",
      name: "October",
      value: "October",
    },
    {
      label: "November",
      name: "November",
      value: "November",
    },
    {
      label: "December",
      name: "December",
      value: "December",
    },
  ];

  return (
    <div>
      {list?.map((item, idx) => {
        const { month_name: name, value } = item;
        return (
          <div
            key={idx}
            className="form-wrap"
            style={{
              marginBottom: "-10rem",
              marginTop: "-2rem",
              gap: "5rem",
              width: "110%",
              // display:"grid",
              gridTemplateColumns: "1fr 1fr .1fr",
              // display: "grid",
            }}
          >
            <div className="form-group">
              <label htmlFor="" className="label">
                Select Month <span>*</span>
              </label>
              <Select
                className={`select-wrap ${name && "select-wrap-active"}`}
                placeholder="Select Month"
                // defaultValue="Select Month"
                value={name}
                isLoading={loading}
                onChange={(e) => {
                  // setMonthSelect((prev) => {
                  //   return { ...prev, monthSelect[0]: e };
                  // });
                  onSelectvalueChange(e, item);
                  // console.log(e);
                  // console.log(item);
                }}
                styles={reactSelectStyle}
                options={monthOption}
                // options={productDrop}
              />
            </div>

            <div className="form-group">
              <label htmlFor="" className="label">
                {quantity_label || "Quantity"} <span>*</span>
              </label>
              <NumericFormat
                // disabled
                // type="number"
                decimalScale={3}
                decimalSeparator="."
                allowNegative
                thousandSeparator={","}
                id=""
                name="value"
                placeholder="value populated"
                value={value}
                onChange={(e) => {
                  onInputValueChange(e, item);
                }}
                className={`input ${value && "input-active"}`}
              />
            </div>

            <div
              className="employee-remove-btn"
              style={{ display: "flex", alignItems: "center" }}
            >
              <FaMinus
                className="icon"
                onClick={() => handleRemoveMonthList(item)}
              />
            </div>
          </div>
        );
      })}
      {/* form-wrap end */}
      <div
        className="emp-add-months-wrap"
        // style={{ marginTop: "-1rem" }}
        onClick={handleAddToList}
      >
        <FaPlus className="icon" />
        <p className="text">Add more months</p>
      </div>

      {/* form-wrap start */}
      <div
        className="form-wrap"
        style={{
          marginTop: "3rem",
          marginBottom: "-10rem",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          // gap: "5.7rem",
        }}
      >
        {/* form-group start */}
        <div className="form-group">
          <label htmlFor="total quantity" className="label">
            {Total_quantity_label || "Total Quantity"}
          </label>
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            disabled
            // type="number"
            placeholder="quantity populated"
            id="total_quantity"
            name="total_quantity"
            style={{
              background: "#ECEDED",
            }}
            className={`input`}
            value={details.total_quantity || totalQuantity}
            onChange={onInputChange}
          />
        </div>
        {/* form-group end */}
        {/* form-group start */}
        <div className="form-group">
          <label
            style={{ width: "150%" }}
            htmlFor="total_revenue_projection"
            className="label"
          >
            Total Revenue Projection
          </label>
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            disabled
            // type="number"
            placeholder="Input Value"
            id="input_value"
            name="total_revenue_project"
            style={{
              background: "#ECEDED",
            }}
            className={`input`}
            value={details.total_revenue_projection || total_rev_project}
            onChange={onInputChange}
          />
        </div>
        {/* form-group end */}
        {/* form-group start */}

        <div className="form-group">
          <label
            style={{ width: "150%" }}
            htmlFor="gross_profit_percentage"
            className="label"
          >
            GP %
          </label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              cursor: "pointer",
            }}
            className=""
          >
            {/* <input
              type="checkbox"
              name=""
              value={canEditPer}
              onChange={(e) => {
                setCanEditPer(e.target?.checked);
              }}
              id=""
            /> */}
            <NumericFormat
              decimalScale={3}
              decimalSeparator="."
              allowNegative
              thousandSeparator={","}
              // disabled={canEditPer ? false : true}
              disabled={totalDisabled}
              // type="number"
              max="100.00"
              min="0.00"
              step="0.01"
              placeholder="Input Value"
              id="gross_profit_percentage"
              name="gpp"
              style={{
                background: totalDisabled && "#ECEDED",
              }}
              className={`input ${!totalDisabled && "input-active"}`}
              value={details.gross_profit_percentage || gross_profit_percent}
              onChange={onInputChange}
            />
          </div>
        </div>
        {/* form-group end */}
        {/* form-group start */}
        <div className="form-group">
          <label htmlFor="total_gp" className="label">
            Estimated GP
          </label>
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            disabled
            // type="number"
            placeholder="shows total value"
            id="total_gp"
            name="total_gross_profit"
            style={{
              background: "#ECEDED",
            }}
            className={`input`}
            value={details.total_gp || total_gross_pro}
            onChange={onInputChange}
          />
        </div>
        {/* form-group end */}
      </div>
      {/* form-wrap end */}
    </div>
  );
};

export default EmployeeRevenueRemovableGroup;
