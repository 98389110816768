import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../../helper/Helpers";
// import { addLabelValueFunc, formatDate } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getAdminConsolidatedBudgetReport = createAsyncThunk(
  "admin/get-admin-consolidated-budget-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/report/details?report_type=${
          formData?.report_type || ""
        }&organization_id=${formData?.org_id || ""}&department_id=${
          formData?.dept_id || ""
        }&currency_conversion_id=${formData?.cur_id || ""}&status=${
          formData?.status || ""
        }&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setAdminConsolidatedReports(data?.data?.consolidated_budget)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAdminProjectionDownload = createAsyncThunk(
  "admin/report-download",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(
        `/admin/report/download-report?report=1&organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&status=${
          formData?.status || ""
        }&report_type=${formData?.type || ""}&report_format=${
          formData?.format || ""
        }&status=${formData?.status || ""}&currency_conversion_id=${
          formData?.currency || ""
        }`
      );
      // console.log(data);
      if (data?.status === 200) {
        // console.log(data);
        return data;
      }
      return data;
      // if (!data?.success) {
      //   return thunkAPI.rejectWithValue(data);
      // }
      // if (data?.success) {
      //   return thunkAPI.rejectWithValue(data);
      // }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
// export const getAnyAdminReport = createAsyncThunk(
//   "admin/report-download",
//   async (formData, thunkAPI) => {
//     try {
//       const data = await axios.get(
//         `/admin/report/download-report?organization_id=${
//           formData?.org_id || ""
//         }&department_id=${formData?.dept_id || ""}&report_type=${
//           formData?.type || ""
//         }&report_format=${formData?.format || ""}&user_id=${
//           formData?.user_id
//         }&currency_conversion_id=${formData?.currency || ""}&status=${
//           formData?.status || ""
//         }`
//       );
//       // console.log(data);
//       if (data?.status === 200) {
//         // console.log(data);
//         return data;
//       }
//       return data;
//       // if (!data?.success) {
//       //   return thunkAPI.rejectWithValue(data);
//       // }
//       // if (data?.success) {
//       //   return thunkAPI.rejectWithValue(data);
//       // }
//     } catch (err) {
//       // console.log(err);
//       if (err.response.status === 500) {
//         toast.error(err.response?.data?.message, {
//           theme: "colored",
//         });
//       }
//       if (err) {
//         return thunkAPI.rejectWithValue(err);
//       }
//     }
//   }
// );

export const getAdminConsolidatedBudgetReportPagination = createAsyncThunk(
  "admin/get-consolidated-budget-report-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/report/details?report_type=${
          formData?.report_type || ""
        }&organization_id=${formData?.org_id || ""}&page=${
          formData?.page
        }&currency_conversion_id=${formData?.cur_id || ""}&status=${
          formData?.status || ""
        }`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      // /api/employee/reports/consolidated-report?page=2
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setAdminConsolidatedReports(data?.data?.consolidated_budget)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Bad Network, check your network settings", {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAdminEngDeptConsolidatedBudgetReport = createAsyncThunk(
  "admin/get-engineering-dept-consolidated-budget-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/report/individual-details?report_type=${
          formData?.report_type || ""
        }&organization_id=${formData?.org_id || ""}&department_id=${
          formData?.dept_id || ""
        }`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setAdminConsolidatedReports(data?.data?.consolidated_budget)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAdminEngDeptConsolidatedBudgetReportPagination =
  createAsyncThunk(
    "admin/get-engineering-dept-consolidated-budget-report-pagination",
    async (formData, thunkAPI) => {
      try {
        const { data } = await axios.get(
          `/admin/report/individual-details?report_type=${
            formData?.report_type || ""
          }&organization_id=${formData?.org_id || ""}&department_id=${
            formData?.dept_id || ""
          }&page=${formData?.page}`
        );
        if (!data?.success) {
          return thunkAPI.rejectWithValue(data);
        }
        // /api/employee/reports/consolidated-report?page=2
        if (data?.success) {
          // console.log("data-check", data?.data?.consolidated_budget);
          await thunkAPI.dispatch(
            setAdminConsolidatedReports(data?.data?.consolidated_budget)
          );
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        if (err.response.status === 500) {
          toast.error("Bad Network, check your network settings", {
            theme: "colored",
            toastId: customErrorId,
          });
        }
        if (err) {
          return thunkAPI.rejectWithValue(err);
        }
      }
    }
  );

export const admin_consolidated_budget_report = createSlice({
  name: "admin_consolidated_budget_report",
  initialState: {
    adminConsolidatedBudgetReports: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
  },

  reducers: {
    setAdminConsolidatedReports: (state, action) => {
      state.isAuth = true;
      state.adminConsolidatedBudgetReports = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getAdminConsolidatedBudgetReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminConsolidatedBudgetReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminConsolidatedBudgetReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getAdminConsolidatedBudgetReportPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminConsolidatedBudgetReportPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminConsolidatedBudgetReportPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getAdminEngDeptConsolidatedBudgetReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminEngDeptConsolidatedBudgetReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminEngDeptConsolidatedBudgetReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getAdminEngDeptConsolidatedBudgetReportPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminEngDeptConsolidatedBudgetReportPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminEngDeptConsolidatedBudgetReportPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAdminConsolidatedReports } =
  admin_consolidated_budget_report.actions;

export default admin_consolidated_budget_report.reducer;
