import { Employee_dashboard_actual_route_group } from "./actuals";
import { Employee_dashboard_external_administration_route_group } from "./external";
import { Employee_dashboard_home_route_group } from "./home";
import { Employee_dashboard_projection_route_group } from "./projection";
import { Employee_dashboard_general_report_route_group } from "./report";
// import { Dashboard_subscription_route_group } from "./subscription";

export const Employee_dashboard_route_group = [
  ...Employee_dashboard_home_route_group,
  ...Employee_dashboard_projection_route_group,
  ...Employee_dashboard_actual_route_group,
  ...Employee_dashboard_general_report_route_group,
  ...Employee_dashboard_external_administration_route_group,
  // ...Dashboard_subscription_route_group
];
