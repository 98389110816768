import React from "react";
import revvexErrorImg from "../../assets/https___lottiefiles.com_animations_lost-connections-wbyJoJVPvb (1).gif";

const ServerDownPageTwo = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "grid",
        placeItems: "center",
      }}
    >
      <div className="" style={{ display: "flex", flexDirection: "column" }}>
        <div className="img-wrap" style={{ marginBottom: "3rem" }}>
          <figure
            className="img-box"
            style={{ width: "55rem", height: "25rem" }}
          >
            <img
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              src={revvexErrorImg}
              alt=""
              className="img"
            />
          </figure>
        </div>
        <p
          style={{
            textAlign: "center",
            fontSize: "3.5rem",
            color: "rgba(29, 29, 24, 1)",
            fontWeight: "600",
          }}
          className="title"
        >
          Service Unavailable
        </p>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.35rem",
            color: "rgba(91, 104, 113, 1)",
            marginTop: "1rem",
          }}
          className="text"
        >
          We’re temporarily down for maintenance.
        </p>
      </div>
    </div>
  );
};

export default ServerDownPageTwo;
