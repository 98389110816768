import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
// import EmployeeRevenueProjectionMonthlyForm from "./MonthlyForm";
// import EmployeeRevenueProjectionSpreadForm from "./SpreadForm";
// import Modal from "react-awesome-modal";
// import EmployeeBulkModal from "../../actual/modals/upload";
// import { useHistory } from "react-router-dom";
// import EmployeeSupplementarySpreadForm from "./SpreadForm";
import EmployeeSupplementaryMonthForm from "./monthForm";
import EmployeeSupplementarySpreadForm from "./spreadForm";
// import EmployeeBulkModal from "../modals/upload";
import Select from "react-select";
import {
  formatNumber,
  formatNumberWithoutComma,
  handleNumberFormatInput,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import moment from "moment";
import {
  getActualSupplementaryBudget,
  getApprovedPrice,
  updateactualSupplementaryBudgets,
} from "../../../../../../redux/employee/actual-supplementary-budget/actual-supplementary-budget";
import { Dna } from "react-loader-spinner";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { NumericFormat } from "react-number-format";
// import id from "faker/lib/locales/id_ID";

const EmployeeActualSupplementaryFormUpdate = () => {
  const [details, setDetails] = useState({
    date: "",
    chart_of_account_id: "",
    user_id: "",
    organization_id: "",
    monthly_breakdown: [{ name: { label: "" }, value: "" }],
    approved_price: "",
    new_price: "",
    unit_price: "",
    description: "",
    units: "",
    // status: "",
    is_submitted: "",
    entry_type: "",
    total_supplementary: "",
    total_qunatity: "",
    department_id: "",
    fiscal_year_id: "",
    supplementary_type: "",
  });
  const [singleSupplementary, setSingleSupplementary] = useState("");
  const [spreadForm, setSpreadForm] = useState(false);
  // const [ setBtnChange] = useState(false);
  const [monthlyForm, setMonthlyForm] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [changeLabel, setChangeLabel] = useState({
    inputLabel: "Quantity",
    totalLabel: "Total Quantity",
  });
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const { loading } = useSelector((state) => state.actual_supplementary_budget);
  const [startDate, setstartDate] = useState("");
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);
  const [spreadTotalVal, setSpreadTotalVal] = useState("");
  const [refreshTable] = useState(false);
  const [totalQuantity, setTotalQuatity] = useState("");
  const [totalValue, setTotalValue] = useState("");
  //   const [showModal, setShowModal] = useState(false);

  // const history = useHistory();
  const dispatch = useDispatch();

  // console.log("supplementary-type", singleSupplementary?.unit_price);

  useEffect(() => {
    if (singleSupplementary?.supplementary_type === "spread") {
      const totalSpreadVal =
        formatNumberWithoutComma(String(singleSupplementary?.unit_price || 0)) *
        formatNumberWithoutComma(String(singleSupplementary?.units || 0));
      setSpreadTotalVal(totalSpreadVal);
      if (singleSupplementary?.approved_price && spreadTotalVal) {
        const newAmountVal =
          formatNumberWithoutComma(
            String(singleSupplementary?.approved_price)
          ) + spreadTotalVal;
        setSingleSupplementary((prev) => {
          return { ...prev, new_price: newAmountVal };
        });
      }
    }

    if (
      singleSupplementary?.supplementary_type === "monthly" &&
      singleSupplementary?.entry_type?.label === "Quantity"
    ) {
      const totalQua = singleSupplementary?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      const totalQuaAddListVal = totalQuaAddList || 0;
      setTotalQuatity(totalQuaAddListVal);
      const totalVal =
        totalQuaAddList *
        formatNumberWithoutComma(String(singleSupplementary.unit_price || 0));
      const totalValRound = totalVal || 0;
      setTotalValue(totalValRound);
      const newAmountVal =
        formatNumberWithoutComma(String(singleSupplementary?.approved_price)) +
        totalValue;
      setSingleSupplementary((prev) => {
        return { ...prev, new_price: newAmountVal };
      });
    }
    if (
      singleSupplementary?.supplementary_type === "monthly" &&
      singleSupplementary?.entry_type?.label === "Amount"
    ) {
      const totalQua = singleSupplementary?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      const totalQuaAddListVal = totalQuaAddList || 0;
      setTotalValue(totalQuaAddListVal);

      if (
        singleSupplementary?.supplementary_type === "monthly" &&
        singleSupplementary?.entry_type?.label === "Amount"
      ) {
        const totalApprovedVal =
          totalQuaAddListVal /
          formatNumberWithoutComma(String(singleSupplementary?.unit_price));
        // const totalApprovedValue = totalApprovedVal;
        setTotalQuatity(totalApprovedVal);
      }
      if (singleSupplementary?.approved_price && totalValue) {
        const newAmountVal =
          formatNumberWithoutComma(
            String(singleSupplementary?.approved_price)
          ) + totalValue;
        setSingleSupplementary((prev) => {
          return { ...prev, new_price: newAmountVal };
        });
      }
    }

    //eslint-disable-next-line
  }, [singleSupplementary, singleSupplementary?.unit_price]);

  useEffect(() => {
    // dispatch(getCountries())
    const empObj = {
      id: user?.organizations[0]?.id,
      reason: true,
    };
    dispatch(getChatAccounts(empObj));
    dispatch(getFiscalYears(empObj));
    dispatch(getUserDepartment(empObj?.id));
    setDetails((prev) => {
      return {
        ...prev,
        chart_of_account_id: "",
      };
    });
    const id = localStorage.getItem("single-actual-supplementary-budget-id");
    if (user?.organizations?.length > 0) {
      // handleGetBranch(id);
      handleGetSupplementaryBudget(id);
      // handleGetChartOfAcct(id)
    }

    // eslint-disable-next-line
  }, [refreshTable]);

  useEffect(() => {
    if (
      singleSupplementary?.department?.value &&
      singleSupplementary?.chartofaccount?.value &&
      singleSupplementary?.fiscal_year?.value
    ) {
      handleGetApprovedData();
    }
    //eslint-disable-next-line
  }, [
    singleSupplementary?.department?.value,
    singleSupplementary?.chartofaccount?.value,
    singleSupplementary?.fiscal_year?.value,
  ]);

  const handleGetApprovedData = async () => {
    const obj = {
      dept_id: singleSupplementary?.department?.value,
      chat_id: singleSupplementary?.chartofaccount?.value,
      fiscal_year: singleSupplementary?.fiscal_year?.value,
    };
    // console.log(obj?.chat_id);
    const data = await dispatch(getApprovedPrice(obj));
    if (data?.payload?.success) {
      const approvedCalc = data?.payload?.data?.expenditure_sum;
      // const approvedCalcRound = approvedCalc || 0;
      setSingleSupplementary((prev) => {
        return { ...prev, approved_price: approvedCalc };
      });
      // console.log(approvedCalc, "approved-price");
    }
  };

  const handleGetSupplementaryBudget = async (id) => {
    const data = await dispatch(getActualSupplementaryBudget(id));
    if (data?.payload?.success) {
      const supplementaryDetail = data?.payload?.data?.Supplmentary;
      const newBreakDown = supplementaryDetail?.monthly_breakdown?.map(
        (chi) => {
          return {
            ...chi,
            month_name: { label: chi?.month_name, value: chi?.month_name },
            value: formatNumber(String(chi?.value)),
          };
        }
      );
      const newSupplementaryDetail = {
        ...supplementaryDetail,
        chartofaccount: {
          label: supplementaryDetail?.chartofaccount?.name,
          value: supplementaryDetail?.chartofaccount?.id,
        },
        department: {
          label: supplementaryDetail?.department?.name,
          value: supplementaryDetail?.department?.id,
        },
        fiscal_year: {
          label: supplementaryDetail?.fiscal_year?.name,
          value: supplementaryDetail?.fiscal_year?.id,
        },
        entry_type: {
          label: supplementaryDetail?.entry_type,
          value: supplementaryDetail?.entry_type,
        },
        monthly_breakdown: newBreakDown,
        approved_price: formatNumber(
          String(supplementaryDetail?.approved_price)
        ),
        new_price: formatNumber(String(supplementaryDetail?.new_price)),
        unit_price: formatNumber(String(supplementaryDetail?.unit_price)),
        units: formatNumber(String(supplementaryDetail?.units)),
      };

      setSingleSupplementary(newSupplementaryDetail);
    }
  };
  // console.log("approved_price", singleSupplementary?.approved_price);

  useEffect(() => {
    if (singleSupplementary?.supplementary_type === "spread") {
      setSpreadForm(true);
      setMonthlyForm(false);
    } else {
      setMonthlyForm(true);
      setSpreadForm(false);
    }
  }, [singleSupplementary]);

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name } = e.target;
  //   // const data = { ...details, [name]: value };
  //   // setDetails(data);
  //   const singleSupplementarydata = {
  //     ...singleSupplementary,
  //     [name]: handleNumberFormatInput(e),
  //   };
  //   setSingleSupplementary(singleSupplementarydata);
  // };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // const data = { ...details, [name]: value };
    // setDetails(data);
    const singleSupplementarydata = {
      ...singleSupplementary,
      [name]: value,
    };
    setSingleSupplementary(singleSupplementarydata);
  };

  const handleInputOptionToMonthList = (e, item) => {
    const newList = details?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id ? { ...chi, value: e } : chi
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
    const newSupplementaryList = singleSupplementary?.monthly_breakdown?.map(
      (chi) =>
        chi?.id === item?.id
          ? { ...chi, value: handleNumberFormatInput(e) }
          : chi
    );
    setSingleSupplementary((prev) => {
      return { ...prev, monthly_breakdown: newSupplementaryList };
    });
    // if()
    // const totalQua = details?.monthly_breakdown?.map((chi) => chi?.value)
    // // setTotalQuatity(totalQua)
    // const totalQuaAddList = totalQua.reduce((a, b) => Number(a) + Number(b), 0)
    // const result = totalQuaAddList + Number(e)
    // console.log(result);
  };

  const handleAddToMonthList = () => {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const obj = {
      id: id,
      name: { label: "", value: "" },
      value: "",
    };
    const newList = {
      ...details,
      monthly_breakdown: [...details.monthly_breakdown, obj],
    };
    const newSupplementaryList = {
      ...singleSupplementary,
      monthly_breakdown: [...singleSupplementary?.monthly_breakdown, obj],
    };
    setDetails(newList);
    setSingleSupplementary(newSupplementaryList);
    // console.log(newList);
    // console.log(test);
  };

  const enableSubmit = () => {
    let allowSubmit = false;

    allowSubmit =
      singleSupplementary?.date &&
      singleSupplementary?.chartofaccount &&
      singleSupplementary?.approved_price &&
      singleSupplementary?.new_price &&
      singleSupplementary?.department &&
      singleSupplementary?.fiscal_year &&
      singleSupplementary?.description &&
      singleSupplementary?.unit_price;

    if (singleSupplementary?.supplementary_type === "spread") {
      allowSubmit = allowSubmit && singleSupplementary?.units;
    } else {
      allowSubmit =
        totalValue &&
        totalQuantity &&
        singleSupplementary?.monthly_breakdown &&
        singleSupplementary?.monthly_breakdown[0] &&
        singleSupplementary?.entry_type &&
        allowSubmit;
    }

    return allowSubmit;
  };

  const handleSelectOptionToMonthList = (e, item) => {
    // console.log(e);
    // console.log(item);
    const newList = details?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id ? { ...chi, month_name: e } : chi
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
    const newSupplementaryList = singleSupplementary?.monthly_breakdown?.map(
      (chi) => (chi?.id === item?.id ? { ...chi, month_name: e } : chi)
    );
    setSingleSupplementary((prev) => {
      return { ...prev, monthly_breakdown: newSupplementaryList };
    });
  };

  const handleRemoveOptionMonthList = (item) => {
    const newList = details?.monthly_breakdown?.filter(
      (chi) => chi?.id !== item?.id
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
    const newSupplementaryList = singleSupplementary?.monthly_breakdown?.filter(
      (chi) => chi?.id !== item?.id
    );
    setSingleSupplementary((prev) => {
      return { ...prev, monthly_breakdown: newSupplementaryList };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(details);
    // const newList = details?.monthly_breakdown.map((chi) => {
    //   return { month_name: chi.month_name?.label, value: chi.value };
    // });
    const newSupplementaryList = singleSupplementary.monthly_breakdown.map(
      (chi) => {
        return {
          // id: 127,
          // supplementary_budget_id: singleSupplementary?.id,
          month_name: chi.month_name?.label,
          value: formatNumberWithoutComma(String(chi.value)),
        };
      }
    );

    const obj = {
      //   ...details,
      id: singleSupplementary?.id,
      date: startDate || singleSupplementary?.date,
      chart_of_account_id:
        details?.chart_of_account_id?.value ||
        singleSupplementary?.chartofaccount?.value,
      approved_price: formatNumberWithoutComma(
        String(singleSupplementary?.approved_price)
      ),
      new_price: formatNumberWithoutComma(
        String(singleSupplementary?.new_price)
      ),
      department_id:
        details?.department_id?.value || singleSupplementary?.department?.value,
      fiscal_year_id:
        details?.fiscal_year_id?.value ||
        singleSupplementary?.fiscal_year?.value,
      user_id: user?.id,
      organization_id: user?.organizations[0]?.id,
      description: singleSupplementary?.description,
      is_submitted: singleSupplementary?.is_submitted === true ? true : false,
      // status:
      //   singleSupplementary?.status === "pending"
      //     ? "pending"
      //     : "draft" || singleSupplementary?.status,

      monthly_breakdown: newSupplementaryList,
      total_supplementary:
        singleSupplementary?.supplementary_type === "spread"
          ? spreadTotalVal
          : totalValue,
      total_quantity: totalQuantity,
      // total_qunatity totalQuantity,
      entry_type: singleSupplementary?.entry_type?.value,
      supplementary_type: singleSupplementary?.supplementary_type,
      unit_price: formatNumberWithoutComma(
        String(singleSupplementary?.unit_price)
      ),
      // // units: Number(totalQuantity),
      units: !!formatNumberWithoutComma(String(singleSupplementary?.units))
        ? formatNumberWithoutComma(String(singleSupplementary?.units))
        : formatNumberWithoutComma(String(totalQuantity)),
    };

    // console.log("departent_id", obj);
    // return
    // if (details?.supplementary_type === "spread") {
    //   delete obj["monthly_breakdown"];
    //   obj["total_value"] = spreadTotalVal;
    // }
    // if (singleSupplementary?.supplementary_type === "spread") {
    //   delete obj["monthly_breakdown"];
    //   obj["total_value"] = spreadTotalVal;
    // }
    // console.log(obj);
    // return;
    const data = await dispatch(updateactualSupplementaryBudgets(obj));

    if (data?.payload?.success) {
      setDetails({
        date: "",
        chartofaccount: "",
        user_id: "",
        organization_id: "",
        monthly_breakdown: [{ name: { label: "" }, value: "" }],
        approved_price: "",
        new_price: "",
        unit_price: "",
        units: "",
        description: "",
        status: "",
        entry_type: "",
        total_supplementary: "",
        total_quantity: "",
        department_id: "",
        fiscal_year: "",
        supplementary_type: "",
      });
      setstartDate("");
      setSpreadTotalVal("");
      setTotalQuatity("");
      setTotalValue("");
      setSpreadForm(false);
      setMonthlyForm(false);

      // history.push("/employee-dashboard-projection-expenditure");
    }
    // console.log(obj);
  };

  const entryType = [
    {
      label: "Quantity",
      value: "Quantity",
    },
    {
      label: "Amount",
      value: "Amount",
    },
  ];

  const handleSpreadForm = () => {
    setSpreadForm(true);
    setMonthlyForm(false);
    // setBtnChange(true);
  };

  const handleMonthlyForm = () => {
    setMonthlyForm(true);
    setSpreadForm(false);
    // setBtnChange(true);
  };
  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Supplementary Budget`}> */}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">Update Supplementary Budget</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          {/* <div
              className="btn-download-box"
              onClick={() => setShowModal(true)}
            >
              <button className={`btn-bulk `}>
                {" "}
                <FaPlus className="icon" /> Bulk Upload
              </button>
              <p className="text">See and download template</p>
            </div> */}
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container */}

        <div className="form-box" style={{ width: "80%" }}>
          <form onSubmit={handleSubmit} action="" className="form">
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Supplementary Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="date" className="label">
                    Date <span>*</span>
                  </label>
                  <Flatpickr
                    id="date"
                    name="date"
                    className={`input ${
                      singleSupplementary?.date && "input-active"
                    }`}
                    autoComplete="off"
                    placeholder={singleSupplementary?.date || "Enter Date"}
                    value={moment(singleSupplementary?.date).format(
                      "YYYY-MMMM-DD"
                    )}
                    // value={singleSupplementary?.date}
                    onChange={(date) => {
                      setstartDate(moment(date[0]).format("YYYY-MM-DD"));
                    }}
                    // placeholder="DD/MM/YYYY"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Chart of account <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleSupplementary?.chartofaccount &&
                      "select-wrap-active"
                    }`}
                    placeholder="Select chart of account"
                    value={singleSupplementary?.chartofaccount}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, chart_of_account_id: e };
                      });
                      setSingleSupplementary((prev) => {
                        return { ...prev, chartofaccount: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={chatAccountDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="department" className="label">
                    Department <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      singleSupplementary?.department && "select-wrap-active"
                    }`}
                    placeholder="Select Department"
                    value={singleSupplementary?.department}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, department_id: e };
                      });
                      setSingleSupplementary((prev) => {
                        return { ...prev, department: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={userDepartmentDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Fiscal Year <span>*</span>
                  </label>
                  <Select
                    placeholder="Select fiscal year"
                    className={`select-wrap ${
                      singleSupplementary?.fiscal_year && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    value={singleSupplementary?.fiscal_year}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, fiscal_year_id: e };
                      });
                      setSingleSupplementary((prev) => {
                        return { ...prev, fiscal_year: e };
                      });
                    }}
                    options={fiscalYearDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="approved_price" className="label">
                    Approved Amount <span>*</span>
                  </label>
                  {/* <input
                    // disabled
                    name="approved_price"
                    // type="number"
                    placeholder="Approved Price"
                    onChange={handleChange}
                    value={singleSupplementary?.approved_price}
                    // style={{ background: "#ECEDED", marginTop: "3px" }}
                    id="approved_price"
                    className={`input ${
                      singleSupplementary?.approved_price && "input-active"
                    }`}
                  /> */}
                  <NumericFormat
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    disabled
                    name="approved_price"
                    // type="number"
                    placeholder="Approved Price"
                    onChange={handleChange}
                    value={singleSupplementary?.approved_price}
                    style={{ background: "#ECEDED" }}
                    id="approved_price"
                    className={`input`}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="new_price" className="label">
                    New Amount <span>*</span>
                  </label>
                  {/* <input
                    // type="number"
                    name="new_price"
                    onChange={handleChange}
                    value={singleSupplementary?.new_price}
                    id="new_price"
                    placeholder="Enter new price"
                    className={`input ${
                      singleSupplementary?.new_price && "input-active"
                    }`}
                  /> */}
                  <NumericFormat
                    // type="number"
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    disabled
                    name="new_price"
                    onChange={handleChange}
                    style={{ background: "#ECEDED" }}
                    // value={details?.new_price}
                    value={singleSupplementary?.new_price}
                    id="new_price"
                    placeholder="Enter new price"
                    className={`input`}
                  />
                </div>
                {/* group box end */}

                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="description" className="label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={handleChange}
                    style={{ width: "206%", resize: "none", padding: "30px" }}
                    cols="30"
                    rows="7"
                    placeholder="Enter your description here..."
                    className={`input ${
                      singleSupplementary?.description && "input-active"
                    }`}
                    value={singleSupplementary?.description}
                  />
                </div>
                {/* group box end */}
              </div>
              <div className="">
                {/* second wrap start */}
                {/* <div className="wrapper"></div> */}
                <div>
                  <div className="wrapper"></div>
                  <p className="box-title">2. Supplementary Format</p>
                </div>
                {/* form check bos start */}
                <div className="form-check-box" style={{ width: "65%" }}>
                  {/* check group start */}
                  <div className="check-group" onChange={handleSpreadForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-spread-evenly"
                      value="spread"
                      checked={
                        singleSupplementary?.supplementary_type === "spread"
                      }
                      onChange={(e) => {
                        setDetails((prev) => {
                          return {
                            ...prev,
                            supplementary_type: e.target.value,
                          };
                        });
                        setSingleSupplementary((prev) => {
                          return {
                            ...prev,
                            supplementary_type: e.target.value,
                          };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-spread-evenly"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Spread Evenly</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (share value accross all months)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                  {/* check group start */}
                  <div className="check-group" onChange={handleMonthlyForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-monthly"
                      value="monthly"
                      checked={
                        singleSupplementary?.supplementary_type === "monthly"
                      }
                      onChange={(e) => {
                        setDetails((prev) => {
                          return {
                            ...prev,
                            supplementary_type: e.target.value,
                          };
                        });
                        setSingleSupplementary((prev) => {
                          return {
                            ...prev,
                            supplementary_type: e.target.value,
                          };
                        });
                      }}
                    />
                    <label htmlFor="check-monthly" className="check-label">
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Monthly Entry</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (specify value for each months)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                </div>

                {spreadForm && (
                  <EmployeeSupplementarySpreadForm
                    onInputChange={handleChange}
                    unit_price={singleSupplementary?.unit_price}
                    units={singleSupplementary?.units}
                    total_value={spreadTotalVal}
                  />
                )}
                {monthlyForm && (
                  <EmployeeSupplementaryMonthForm
                    unit_price={singleSupplementary?.unit_price}
                    onInputChange={handleChange}
                    handleEntryTypeChange={(e) => {
                      // console.log("check", e);
                      // console.log(e.value);
                      e.value === 2
                        ? setChangeLabel((prev) => {
                            return {
                              ...prev,
                              inputLabel: "Amount",
                              totalLabel: "Total Amount",
                            };
                          })
                        : setChangeLabel(
                            !changeLabel.inputLabel,
                            !changeLabel.totalLabel
                          );
                      setDetails({ ...details, entry_type: e });
                      setSingleSupplementary({
                        ...singleSupplementary,
                        entry_type: e,
                      });
                    }}
                    list={singleSupplementary?.monthly_breakdown}
                    // value={}
                    Quantity={changeLabel?.inputLabel}
                    Total_quantity_label={changeLabel?.totalLabel}
                    onSelectvalueChange={handleSelectOptionToMonthList}
                    onInputValueChange={handleInputOptionToMonthList}
                    handleAddMonthList={handleAddToMonthList}
                    handleRemoveMonthList={handleRemoveOptionMonthList}
                    entryType={entryType}
                    quantity={singleSupplementary?.units}
                    entry_type_value={
                      singleSupplementary?.entry_type?.label === null
                        ? ""
                        : singleSupplementary?.entry_type
                    }
                    total_quantity={totalQuantity}
                    total_supplementary={totalValue}
                    loading={loading}
                  />
                )}
                {/* form check bos end */}
              </div>{" "}
            </div>
            {/* second wrap end*/}
            <div className="wrapper"></div>
            {/* btn box start */}
            {/* <div className="btn-box-wrap">
              {!btnChange && (
                <button
                  className="btn-reuse btn-two"
                  style={{ display: "none" }}
                  onClick={() =>
                    history.push(
                      "/employee-dashboard-actual-supplementary-budget-submit"
                    )
                  }
                >
                  Submit for approval
                </button>
              )}
              <>
                {" "}
                <button
                  className="btn-reuse btn-two"
                  style={{ backgroundColor: "#ffff", marginRight: "3rem" }}
                  onClick={() =>
                    history.push(
                      "/employee-dashboard-actual-supplementary-budget-submit"
                    )
                  }
                >
                  Submit for approval
                </button>
                {btnChange && (
                  <button
                    className="btn-reuse btn-two"
                    style={{
                      backgroundColor: "var(--blue-color)",
                      color: "#fff",
                    }}
                    onClick={() =>
                      history.push(
                        "/employee-dashboard-actual-supplementary-budget-draft"
                      )
                    }
                  >
                    Save Budget
                  </button>
                )}
                {!btnChange && (
                  <button
                    className="btn-reuse btn-two"
                    onClick={() =>
                      history.push(
                        "/employee-dashboard-actual-supplementary-budget-draft"
                      )
                    }
                  >
                    Save Budget
                  </button>
                )}
              </>
            </div> */}

            {/* btn box end */}
            {/* btn start */}

            <div style={{ display: "flex", gap: "3rem" }}>
              {!loading ? (
                <input
                  // disabled={!enableSubmit()}
                  type="submit"
                  // value={
                  //   singleSupplementary?.is_submitted === false
                  //     ? "Update to Draft"
                  //     : "Update For Approval"
                  // }
                  value={"Update"}
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{ height: "5rem" }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
              {/* button end */}
              {/* button start */}
              {/* {!loading ? ( */}
              {/* {!loading ? (
                <input
                  onClick={() => setDetails({ ...details, status: "pending" })}
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Submit for Approval"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{ height: "5rem" }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )} */}
            </div>
            {/* btn end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
      {/* modal start */}
      {/* <Modal effect="fadeInUp" visible={showModal}>
        <EmployeeBulkModal onCancel={() => setShowModal(false)} />
      </Modal> */}
      {/* modal end */}
    </>
  );
};

export default EmployeeActualSupplementaryFormUpdate;
