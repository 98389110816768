import React, {  useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
// import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import { getDepartment } from "../../../../../redux/department/deparment";
import { Dna } from "react-loader-spinner";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../helper/Helpers";

const ViewDepartment = () => {
  const history = useHistory();
  // const actionCtx = useContext(ActionContext);
  // const [refreshTable, setRefreshTable] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { usersDrop } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { branchDrop } = useSelector((state) => state.branch);
  const { loading } = useSelector((state) => state.department);
  const [singleDept, setSingleDept] = useState({});
  // const { loading } = useSelector((state) => state.subsidiary);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const [details ] = useState({
    name: "",
    subsidiary_id: "",
    branch_id: "",
    department_code: "",
    head_of_department_id: "",
    submit_to_id: "",
    organization_id: "",
    description: "",
  });

  useEffect(() => {

    const id = localStorage.getItem("department_id");
    // console.log("yes");
    if (user?.organizations?.length > 0) {
      hanldGetDepartment(id);
    }
      // eslint-disable-next-line
  }, []);

  const hanldGetDepartment = async (id) => {
    const data = await dispatch(getDepartment(id));
    if (data?.payload?.success) {
      const deptDetail = data?.payload?.data?.department;
      const newDetails = {
        ...deptDetail,
        branch_id: {
          label: deptDetail?.branch?.name,
          value: deptDetail?.branch?.id,
        },
        subsidiary_id: {
          ...deptDetail?.branch?.subsidiary,
          label: deptDetail?.branch?.subsidiary?.name,
          value: deptDetail?.branch?.subsidiary?.id,
        },
        head_of_department_id: {
          value: deptDetail?.head_of_department?.id,
          label: deptDetail?.head_of_department?.full_name,
        },
        submit_to_id: {
          value: deptDetail?.submit_to?.id,
          label: deptDetail?.submit_to?.full_name,
        },
      };
      // console.log(newDetails);
      setSingleDept(newDetails);
    }
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 4 of 6`}
        pageTitle={`View Department`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-department"
              )
            }
            className="text"
          >
            Departments
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">View Department</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          {/* <div className="title-text-box">
              <p className="title">edit Departments</p>
              <p className="text">
                Add department information and control the organisation
                structure of your account management
              </p>
            </div> */}
          {/* text tititl box end */}
          {/* button box start */}
          {/* <div className="btn-download-box">
              <button
                className={`btn-bulk ${
                  details.Address ||
                  details.Branch_Code ||
                  details.Name ||
                  details.city ||
                  details.Branch ||
                  details.subsidiary
                    ? "btn-bulk-inactive"
                    : ""
                }`}
              >
                {" "}
                <FaPlus className="icon" /> Bulk Upload
              </button>
              <p className="text">See and download template</p>
            </div> */}
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              history.goBack();
            }}
            action=""
            className="form"
          >
            {/* wrap start */}
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Department Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Name" className="label">
                    Department Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Department Name"
                    className={`input ${details.name && "input-active"}`}
                    id="Name"
                    name="name"
                    disabled
                    value={singleDept?.name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    placeholder="Sync with system settings"
                    value={singleDept?.subsidiary_id}
                    isDisabled
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    // isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Branch" className="label">
                    Branch <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.branch_id && "select-wrap-active"
                    }`}
                    placeholder="Sync with system settings"
                    value={singleDept?.branch_id}
                    isDisabled
                    styles={reactSelectStyle}
                    options={branchDrop}
                    // isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Department Code" className="label">
                    Department Code <span>*</span>
                  </label>
                  <input
                    name="department_code"
                    disabled
                    value={singleDept?.department_code}
                    type="text"
                    placeholder="001"
                    className={`input ${
                      details.department_code && "input-active"
                    }`}
                    id="Department Code"
                  />
                </div>
                {/* group box end */}
              </div>

              <div
                style={{
                  gridTemplateColumns: "1fr",
                  margin: "3rem 0rem 0rem 0rem",
                  gridTemplateRows: "1fr",
                }}
                className="form-wrap"
              >
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Department Code" className="label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    value={singleDept?.description}
                    disabled
                    //   name=""
                    style={{
                      width: "100%",
                      resize: "none",
                      textTransform: "unset",
                    }}
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Dedicated support to the product development and product inquiries"
                    className={`input ${details.description && "input-active"}`}
                  ></textarea>
                </div>
                {/* group box end */}
              </div>
              {/* another warpa start */}

              <div className="wrapper">
                <p className="box-title">2. Department Personnel</p>
                <div className="form-wrap" style={{ gridTemplateRows: "1fr" }}>
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="head of dept" className="label">
                      Head of department <span>*</span>
                    </label>
                    <Select
                      className={`select-wrap ${
                        details.head_of_department_id && "select-wrap-active"
                      }`}
                      placeholder="Sync with system settings"
                      value={singleDept?.head_of_department_id}
                      isDisabled
                      styles={reactSelectStyle}
                      options={usersDrop}
                      // isLoading={loading}
                    />
                  </div>
                  {/* group box end */}
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="submit to" className="label">
                      Submit to
                      <figure
                        className="img-box"
                        style={{
                          filter: "saturate(0%)",
                          transform: "translateX(.5rem) translateY(.3rem)",
                        }}
                      >
                        <img src={noteIcon} alt="" className="img" />
                      </figure>
                    </label>
                    <Select
                      className={`select-wrap ${
                        details.head_of_department_id && "select-wrap-active"
                      }`}
                      placeholder="Sync with system settings"
                      value={singleDept?.submit_to_id}
                      isDisabled
                      styles={reactSelectStyle}
                      options={usersDrop}
                      // isLoading={loading}
                    />
                  </div>
                  {/* group box end */}
                </div>
              </div>
              {/* another wrap end */}
            </div>
            {/* wrap end */}
            {/* <div className="note-img-wrap">
                Important Notice:
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
              </div> */}
            {/* note box start */}
            {/* <div className="note-box">
                <p>
                  1. For organizations with shared budgets, you would need to
                  have created at least two subsidiaries to set up a shared
                  services
                </p>
              </div> */}
            {/* note box end */}

            {/* btn box start */}
            {!loading ? (
              <input
                
                type="submit"
                value="Go back"
                className={`form-group form-submit ${"form-submit-active"}`}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* btn box end */}
            
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default ViewDepartment;
