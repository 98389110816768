import React from "react";
import { Dna } from "react-loader-spinner";
import { useSelector } from "react-redux";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
// import { getEmpActualExpenseReimburse } from "../../../../../../redux/employee/actual-expense";
import "../../../../../../styles/dashboard/modals/DeleteSharedmodal.css";

const EmployeeActualExpenseReimburseModal = ({
  onClose,
  onReimburse,
  expense,
  handleSubmit,
}) => {
  // const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.actual_expense_employee);


  // console.log(details, "details....")
  return (
    <div className="delete-shared-modal-wrap">
      <p className="title">{`Reimburse Expense (${expense?.chart_of_account?.name})`}</p>
      <p className="text">
        Are you sure you want to reimburse this expense. 
      </p>
      {/* <p className="text text-two">
        organizatiuon budget settings. Do you still want to delete?
    </p> */}

      <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        {loading ? (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue
            // onClick={onReimburse}
            onClick={handleSubmit}
            bgColor={`var(--blue-color)`}
            text={`Yes, Reimburse`}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeActualExpenseReimburseModal;
