import HelpCenter from "../../../component/pages/dashboard/administration/dropdown/helpCenter";

export const Dashboard_admin_support_help_center_route_group = [
  {
    path: "/dashboard-administration-support",
    component: HelpCenter,
    pageTitle: "Supports",
    goBack: true,
  },
];
