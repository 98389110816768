import AdminSettings from "../../../component/pages/dashboard/administration";
import { organization_routes_group } from "./OrganizationSettings";

export const Dashboard_admin_setting_route_group = [
  {
    path: "/dashboard-administration-admin-settings",
    component: AdminSettings,
    pageTitle: "Admin Settings",
    goBack: true,
  },
  ...organization_routes_group,
];
