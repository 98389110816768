import React, { useState } from "react";
import "../../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import EmployeeCommonExpenseLayout from "../../expense/CommonLayout";
// import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
import Modal from "react-awesome-modal";
import DeleteSharedModal from "../../../../../../../component/pages/dashboard/administration/subsidiary/DeleteSharedModal";
import EmpTableBodyRow from "../../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../../table/EmployeeReusableTable";

const EmployeeExpensePendingTable = ({ children, chartBox }) => {
  const [showModal, setShowModal] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  // const history = useHistory;

  const cardHeader = ["Expense Projected", "Approved", "Pending", "Rejected"];
  const cardValue = ["220,000.00", "20", "10", "5"];

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Units",
    "Unit Price",
    "Status",
    "Action",
  ];

  const employeeTableBodyList = [
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "pending",
      action: "",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "pending",
      action: "",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "pending",
      action: "",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "pending",
      action: "",
    },
  ];

  return (
    <>
      <EmployeeCommonExpenseLayout approveLink={true}>
        <EmployeeReusableTable
          filter={true}
          addButton={true}
          sort={true}
          cardTitleOne={cardHeader[0]}
          cardTitleTwo={cardHeader[1]}
          cardTitleThree={cardHeader[2]}
          cardTitleFour={cardHeader[3]}
          cardBodyOne={cardValue[0]}
          cardBodyTwo={cardValue[1]}
          cardBodyThree={cardValue[2]}
          cardBodyFour={cardValue[3]}
          cardThree={true}

          //   cardThree={() => history.push(link[0])}
        />
        {/* graph wrap end */}
        {/* table start  */}
        {children}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowPagination={false}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            onNextPage
          >
            {/* // action */}
            {employeeTableBodyList.map((chi, idx) => {
              const {
                chart_of_account,
                classes,
                description,
                units,
                unit_price,
                status,
              } = chi;
              return (
                <EmpTableBodyRow
                  key={idx}
                  one={chart_of_account}
                  two={classes}
                  three={description}
                  four={units}
                  five={unit_price}
                  progressType={status}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  updateText={`modify`}
                  approveText={"Approve"}
                  viewText={"View Comments"}
                  deleteText={`Reject`}
                  exportText={`Export as PDF`}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        <Modal visible={showModal} effect="fadeInUp">
          <DeleteSharedModal
            onClose={() => setShowModal(false)}
          ></DeleteSharedModal>
        </Modal>
      </EmployeeCommonExpenseLayout>
    </>
  );
};

export default EmployeeExpensePendingTable;
