import React from "react";
import { useHistory } from "react-router-dom";
import oldRevvexLogo from "../../../../assets/old-revvex-logo.svg";
import profileImg from "../../../../assets/profile-img.png";
import ButtonBlue from "../../../../component/buttons/ButtonBlue";
// import "../../../../employee-folder/styles/employee-auth.css";
import "../../../styles/auth/employee-auth.css"

const EmployeeActivateMessage = () => {
    const history = useHistory();
  const employeeName = "Bryan";
  const inviteeName = "Seyi Ajayi";
  const employeeEmail = "bryan@zojatech.com";


  return (
    <>
      {/* employee activate message start */}
      <div className="employee-activate-message">
        <figure className="employee-old-revvex-logo">
          <img src={oldRevvexLogo} alt="" />
        </figure>

        {/* employee name start */}
        <p className="employee-salutation">Hello {employeeName},</p>
        {/* employee name end */}

        {/* line-paragraph start */}
        <div className="employee-p-2">
          <div className="employee-profile">
            <figure>
              <img src={profileImg} alt="" className="employee-profile-img" />
            </figure>
            <p className="">
              {inviteeName} invited you as a user to revvex account.
            </p>
          </div>
          <p>
            Your email address{" "}
            <span className="employee-email-span">{employeeEmail}</span> has
            been registered as a user from the Zojatech Admin account.
          </p>
          {/* line-paragraph end */}

          <p>
            Click on the button below to set a password and activate your
            account.
          </p>
        </div>
        <ButtonBlue
          onClick={()=>{history.push('/employee-activate-account')}}
          text="Accept Invite"
        />

        {/* active message link message start */}
        <div className="employee-link-msg">
          <p>
            Trouble with the link above? Copy and paste the following into your
            browser:
          </p>

          <p>
            https://app.revvex.com/app/revvex/-/register?invite=1204567890999978_d81695b42c01b192a3c16cc192dmmff2
          </p>
        </div>
        {/* active message link message end */}
      </div>
      {/* employee activate messaege end */}
    </>
  );
};

export default EmployeeActivateMessage;
