import React from "react";
import { ThreeDots } from "react-loader-spinner";

const DotLoader = () => {
  return (
    <>
      <ThreeDots
        height="10"
        width="20"
        radius="9"
        color="dark"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </>
  );
};
export default DotLoader;
