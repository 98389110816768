import React from "react";
import "../../../../../styles/dashboard/admin-settings/ApprovalSettingModal.css";
// import "../../../../../styles/dashboard/admin-settings/AdminSettingApproval.css"
import checkImg from "../../../../../assets/check-img.png";
// import ButtonBlue from "../../../../buttons/ButtonBlue";
import { FaTimes } from "react-icons/fa";

const ApprovalSuccessModal = ({children, onCancel}) => {
  return (
    <div className="approval-setting-modal-wrap">
    <div onClick={onCancel} className="cancel-box">
        <FaTimes className="icon" />
    </div>
      <figure className="img-box">
        <img src={checkImg} alt="" className="img" />
      </figure>
      <p className="title">Checklist Completed !</p>
      <p className="text">
        Congratulations! your account is up and running ready for use.
      </p>
      <p className="text">Click on the button below to continue.</p>
     {children}
    </div>
  );
};

export default ApprovalSuccessModal;
