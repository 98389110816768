// import { useEffect } from "react";
import { useState } from "react";
import { cancelIcon } from "./modals/upgrade";

const PaymentGateway = ({ url, onCancel }) => {

  return (
    <div className="paystack-wrap">
      <p onClick={onCancel} className="cancel">
        {cancelIcon}
      </p>
      <iframe
        src={url}
        title="Paystack Payment Form"
        width="100%"
        height="500px"
        frameBorder="0"
      ></iframe>
    </div>
  );
};
export default PaymentGateway;
