import React, { useContext } from "react";
import "../../styles/dashboard/SubscriptionEndModal.css";
import imgLoad from "../../assets/https___lottiefiles.com_animations_error-5Dbr8RmAan.gif";
import ButtonBlue from "../buttons/ButtonBlue";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import ActionContext from "../../context/ActionContext";

const SubscriptionEndModal = () => {
  const actionCtx = useContext(ActionContext);
  const { plan_details } = useSelector((state) => state.users);
  return (
    <div className="subscription-end-general-modal">
      {/* close start */}
      <div
        onClick={() => {
          actionCtx.setPlanExpire(false);
        }}
        className="cancel-box"
      >
        <FaTimes className="icon" />
      </div>
      {/* close end */}
      <div className="wrap">
        <div className="img-wrap">
          <figure className="img-box">
            <img src={imgLoad} alt="" className="img" />
          </figure>
        </div>
        <p className="title">Subscription expired</p>
        <p className="text">Your subscription has expired</p>
        <ButtonBlue
          onClick={() => {
            // history.push("/dashboard-administration-admin-settings-billing");
            console.log(plan_details);
          }}
          btnClassName={`btn`}
          text={`Go back to billing`}
        />
      </div>
    </div>
  );
};

export default SubscriptionEndModal;
