import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
// import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../../../helper/Helpers";
// import { addLabelValueFunc } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getCashFlowReport = createAsyncThunk(
  "employee/get-cash-flow-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/cash-flow-report?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&date=${
          formData?.date || ""
        }&status=${formData?.status || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check",data?.data?.actual_expense )
        await thunkAPI.dispatch(setCashFlowReports(data?.data?.actual_expense));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error("Bad Network, check your network settings", {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCashFlowReportPagination = createAsyncThunk(
  "employee/get-cash-flow-report-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/cash-flow-report?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&status=${
          formData?.status || ""
        }&page=${formData?.page}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", )
        await thunkAPI.dispatch(setCashFlowReports(data?.data?.actual_expense));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Bad Network, check your network settings", {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const cash_flow_report = createSlice({
  name: "cash_flow_report",
  initialState: {
    cashFlowReports: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
  },

  reducers: {
    setCashFlowReports: (state, action) => {
      state.isAuth = true;
      state.cashFlowReports = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getCashFlowReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getCashFlowReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getCashFlowReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getCashFlowReportPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getCashFlowReportPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getCashFlowReportPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCashFlowReports } = cash_flow_report.actions;

export default cash_flow_report.reducer;
