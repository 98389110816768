import { useDispatch } from "react-redux";
import Select from "react-select";
import { getDepartmentsBySubsidiary } from "../../../../../../redux/department/deparment";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { FaMinus, FaTimes } from "react-icons/fa";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { Dna } from "react-loader-spinner";

const SharedBudgetModal = ({
  allSubisidiarySelected,
  setAllSelectedSubsidiary,
  handleNewBudgetPercentage,
  handleMultiSelectChange,
  subOption,
  loadingPage,
  handleFormatDepartmentDrop,
  handleMultiSelectChangeTwo,
  departmentDrop,
  handleApportionDeptChange,
  handleDeleteChildFromAll,
  onSetPercent,
  onDiscard,
  sharedBtnLoad,
  onClose,
}) => {
  const dispatch = useDispatch();

  const activeButton = () => {
    let showBtn = false;
    if (allSubisidiarySelected?.length > 0) {
      const checkSelectedDept = allSubisidiarySelected?.map(
        (chi) => chi?.selected_departments || ""
      );
      showBtn = checkSelectedDept[0][0]?.shared_apportion || "";
      return showBtn;
    }
  };

  return (
    <div className="shared-budget-percentage-wrap">
      {/* <div className="shard-budget-cancel-icon-wrap">
       
      </div> */}
      <div className="shared-budget-percentage-header-wrap">
        <p className="shared-budget-percentage-title">Set Sharing Percentage</p>
        <div className="shared-budget-cancel-wrap">
          <FaTimes onClick={onClose} className="shared-budget-cancel-icon" />
        </div>
      </div>
      <div className="shared-budget-form-wrap shared-budget-percentage-box">
        {/* new  design box start -------------------- */}
        {/* select subsidiary start */}
        <div className="shared-form-group">
          <label className="">Select Subsidiary</label>
          <Select
            name="subsidiary_id"
            id="subsidiary_name"
            className={"select-wrap select-wrap-multi"}
            placeholder="Select Subsidiary"
            value={allSubisidiarySelected}
            isMulti
            // onSe

            onChange={(e, objAction) => {
              setAllSelectedSubsidiary(e);
              const obj = {
                reason: true,
                id: objAction.option?.value,
              };

              const getDeptBySub = async (objVal) => {
                // console.log(objVal);
                // return
                const data = await dispatch(getDepartmentsBySubsidiary(objVal));
                if (data?.payload?.success) {
                  const departMentss = data?.payload?.data?.departments;
                  handleMultiSelectChange(e, objAction, departMentss);
                }
              };
              objAction?.option && getDeptBySub(obj);
            }}
            options={subOption}
            styles={reactSelectStyle}
          />
        </div>
        {/* select subsidiary end */}
        {/* selected main wrapper bix show -------- */}
        <div className="selected-main-wrapper-box">
          {allSubisidiarySelected?.map((chi, idx) => {
            // console.log(allSubisidiarySelected, "all-selected");
            const {
              budget_percentage,
              label,
              departments,
              selected_departments,
            } = chi;

            return (
              <div className="selected-item-sub" key={idx}>
                <p className="title">
                  <span>{label} Subsidiary</span> with budget percentage of
                  <input
                    id="budget_percentage"
                    name="budget_percentage"
                    className="shared-form-input budget-input"
                    value={budget_percentage}
                    onChange={(e) => {
                      handleNewBudgetPercentage(
                        e?.target?.value <= 100
                          ? e?.target?.value
                          : budget_percentage,
                        chi?.value
                      );
                    }}
                  />
                </p>
                {/* selected box start --- */}
                {/* form-group start */}
                <div
                  className="shared-item-box"
                  style={{ margin: "1.5rem 0rem" }}
                >
                  <label className="shared-budget-label">
                    Select Department<span>*</span>
                  </label>

                  <Select
                    isMulti
                    styles={reactSelectStyle}
                    isLoading={loadingPage}
                    className="select-wrap select-wrap-multi"
                    value={selected_departments}
                    options={handleFormatDepartmentDrop(departments)}
                    onChange={(e, objAction) => {
                      handleMultiSelectChangeTwo(
                        e,
                        objAction,
                        departments,
                        chi?.value
                      );
                    }}
                    // isDisabled
                  />
                </div>
                {/* form-group end */}
                {/* selected box ebd ----- */}
                {/* depart and shared list start */}
                {selected_departments?.length > 0 && (
                  <div className="depart-shared-list-box">
                    {selected_departments?.map((child, indx) => {
                      const { shared_apportion } = child;
                      return (
                        <div key={indx} className="share-budget-form-box">
                          <p className="shared-budget-step">
                            {indx + 1 || "1"}
                          </p>
                          {/* form-group start */}
                          <div className="shared-budget-box ">
                            <label className="shared-budget-label">
                              Select Department
                              <span>*</span>
                            </label>
                            <Select
                              className="shared-select-wrap"
                              value={{
                                label: child?.name,
                                value: child?.id,
                              }}
                              options={departmentDrop}
                              // onChange={(e) => setSelectSharedDept(e, child)}
                              isDisabled
                            />
                          </div>
                          {/* form-group end */}
                          {/* form group start */}
                          <div
                            // style={{
                            //   display: "flex",
                            //   flexDirection: "column",
                            //   gap: ".2rem",
                            // }}
                            className="shared-budget-box"
                          >
                            <label className="shared-budget-label">
                              Apportion Budget( % )<span>*</span>
                            </label>

                            <input
                              className={`shared-form-input ${
                                shared_apportion && "shared-form-input-active"
                              }`}
                              // decimalScale={3}
                              // decimalSeparator="."
                              type="number"
                              name="apportion_budget"
                              // thousandsGroupStyle={"lakh"}
                              // allowNegative
                              onChange={(e) => {
                                const newValue = e.target.value;
                                handleApportionDeptChange(
                                  chi?.value,
                                  child?.value,
                                  newValue <= 100 ? newValue : shared_apportion
                                );
                              }}
                              value={shared_apportion}
                              id="apportion_budget"
                              // disabled={disable}
                              // placeholder="Specify Budget"
                            />
                          </div>
                          {/* form group end */}
                          <div
                            className="cancel-icon-wrap"
                            onClick={() => {
                              // handleRemoveDeptBudget(child);
                              handleDeleteChildFromAll(
                                chi?.value,
                                child?.value
                              );
                            }}
                          >
                            <FaMinus className="minus-icon" />
                          </div>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* depart and shared list end */}
              </div>
            );
          })}
        </div>
        {/* selected main wrapper bix show end -------- */}
        {/* new  design box end -------------------- */}

        {/* <div> */}

        {/* sharing percentage form wrap end*/}
        {/* <ButtonBlue text={"Submit"} /> */}
        {/* </div> */}
      </div>
      <div className="shared-budget-wrap-btn">
        {sharedBtnLoad ? (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="40"
              width="40"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue
            text={"Submit Budget Percentage"}
            bgColor={activeButton() ? "var(--blue-color)" : "#cccc"}
            onClick={activeButton() ? onSetPercent : null}
          />
        )}

        <ButtonBlue
          text={"Discard"}
          onClick={onDiscard}
          btnClassName={
            activeButton() ? "shared-discard-btn" : "shared-discard-disable-btn"
          }
        />
      </div>
    </div>
  );
};
export default SharedBudgetModal;
