import React from "react";
// import DashboardLayout from "../../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/admin-settings/ApprovalDetailFlow.css";
import CommonLinks from "../CommonLinks";
import settingImg from "../../../../../../assets/setting-three-icon.svg";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import { useHistory } from "react-router-dom";

const RevenueProjectApprovalIdex = () => {
  const history = useHistory();
  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitle={`Setup Approval Flow`}
        pageTitleSpan={`Step 6 of 6`}
      > */}
      <div className="approval-flow-wrap">
        <p className="title">Approval</p>
        {/* comon link start */}
        <CommonLinks />
        {/* comon link end */}

        <div className="img-text-wrap">
          <div className="content-box">
            <figure className="img-box">
              <img src={settingImg} alt="" className="img" />
            </figure>
            <p className="text">
              No approval flow is yet to be configured. Click on the
            </p>
            <p style={{ marginTop: "1rem" }} className="text">
              button below to set approval flow.
            </p>

            <ButtonBlue
              onClick={() =>
                history.push(
                  // "/dashboard-administration-admin-settings-approval-flow-revenue-projection-main"
                  "/dashboard-general-approvals-setup"
                )
              }
              text={`Setup Approval`}
            />
          </div>
        </div>
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default RevenueProjectApprovalIdex;
