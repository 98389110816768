import React, { useState } from "react";
import CommonCustomizationScreen from "./CommonCustomizationScreen";
import Select from "react-select";
// import Editor from "./TextEditor";
// import Editor from "./TextEditor";

const AddCustomization = () => {
  const [selectOne, setSelectOne] = useState(null);
  //   const [selectTwo, setSelectTwo] = useState(null);
  const [details, setDetails] = useState({
    Subsidiary_Name: "",
    country: "",
    Subsidiary_Address: "",
    Subsidiary_of: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const selectListOne = [
    { label: "Naira", value: "1" },
    { label: "USD", value: "2" },
    { label: "Euro", value: "3" },
  ];



  return (
    <>
      <CommonCustomizationScreen>
        <div className="table-wrap-body">
          {/* form box start */}
          <div className="form-box" style={{ width: "80%" }}>
            {/* <p className="form-title">Invite via </p> */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // history.push("/dashboard-home");
              }}
              action=""
              className="form"
            >
              {/* wrap start */}
              <div className="wrapper" style={{ gridTemplateRows: "unset" }}>
                <p className="box-title">1. Template Information</p>
                <div
                  className="form-wrap"
                  style={{ gridTemplateRows: "unset" }}
                >
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="Subsidiary Name" className="label">
                      Title <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="title your template"
                      className={`input ${
                        details.Subsidiary_Name && "input-active"
                      }`}
                      id="Subsidiary Name"
                      name="Subsidiary_Name"
                      onChange={handleChange}
                      value={details.Subsidiary_Name}
                    />
                  </div>
                  {/* group box end */}
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="Country" className="label">
                      Type <span>*</span>
                    </label>
                    <Select
                      className="select "
                      placeholder="Select notification type"
                      value={selectOne}
                      onChange={(e) => {
                        setSelectOne(e);
                      }}
                      options={selectListOne}
                    />
                  </div>
                  {/* group box end */}
                </div>
              </div>
              {/* wrap end */}

              {/* wrap start */}
              <div className="wrapper">
                <p className="box-title">2. Notification Message (Body)</p>
                {/* <div
                  className="form-wrap"
                  style={{ gridTemplateRows: "unset" }}
                >
               
                </div> */}
                {/* editor start */}
               <div style={{height: "30rem"}}>
               {/* <Editor /> */}
               </div>
                {/* editor end */}
              </div>
              {/* wrap end */}

              {/* btn box start */}
              <div className="btn-box-wrap">
                <button
                  //   onClick={() =>
                  //     history.push(
                  //       "/dashboard-administration-admin-settings-admin-invite"
                  //     )
                  //   }
                  className="btn-reuse"
                >
                  Cancel
                </button>
                <button className="btn-reuse btn-two">Save Group</button>
              </div>
              {/* btn box end */}
            </form>
          </div>
          {/* form box end */}
        </div>
      </CommonCustomizationScreen>
    </>
  );
};

export default AddCustomization;
