// import ApprovalsIndex from "../../../../component/pages/dashboard/general/approvals";
import ExpenditureProjectionSettings from "../../../../component/pages/dashboard/administration/approval/expenditure-projection";
import RevenueProjectionApprovalSettings from "../../../../component/pages/dashboard/administration/approval/revenue-projection";
import RevenueProjectApprovalIdex from "../../../../component/pages/dashboard/administration/approval/revenue-projection/RevenueProjectApprovalIndex";
import ExpenditureMain from "../../../../component/pages/dashboard/administration/approval/expenditure-projection/ExpenditureMain";
import RevenueProjectionApproval from "../../../../component/pages/dashboard/administration/approval/pages/RevenueProjectionApproval";
import ExpenseProjectionApproval from "../../../../component/pages/dashboard/administration/approval/pages/ExpenseProjectionApproval";
import ActualExpenseApproval from "../../../../component/pages/dashboard/administration/approval/pages/ActualExpenseApproval";
import SupplementaryBudgetApproval from "../../../../component/pages/dashboard/administration/approval/pages/SupplementaryBudgetApproval";
import EditApproval from "../../../../component/pages/dashboard/administration/approval/revenue-projection/EditApproval";

export const Dashboard_general_approvals_route_group = [
  {
    path: "/dashboard-general-approvals",
    component: RevenueProjectionApproval,
    goBack: true,
    pageTitle: "Approval",
  },
  {
    path: "/dashboard-general-approvals-revenue-projection",
    component: RevenueProjectionApproval,
    goBack: true,
    pageTitle: "Approval",
  },
  {
    path: "/dashboard-general-approvals-expenditure-projection",
    component: ExpenseProjectionApproval,
    goBack: true,
    pageTitle: "Approval",
  },
  {
    path: "/dashboard-general-approvals-actual-expense",
    component: ActualExpenseApproval,
    goBack: true,
    pageTitle: "Approval",
  },
  {
    path: "/dashboard-general-approvals-actual-revenue",
    component: ActualExpenseApproval,
    goBack: true,
    pageTitle: "Approval",
  },
  {
    path: "/dashboard-general-approvals-supplementary-budget",
    component: SupplementaryBudgetApproval,
    goBack: true,
    pageTitle: "Approval",
  },
  {
    path: "/dashboard-general-approvals-expenditure-projection-setup",
    component: ExpenditureProjectionSettings,
    pageTitle: "Approval",
    goBack: true,
  },
  {
    path: "/dashboard-general-approvals-revenue-projection-index",
    component: RevenueProjectApprovalIdex,
    pageTitle: "Approval",
    goBack: true,
  },
  {
    path: "/dashboard-general-approvals-setup",
    component: RevenueProjectionApprovalSettings,
    pageTitle: "Approval",
    goBack: true,
  },
  {
    path: "/dashboard-general-approvals-setup-edit",
    component: EditApproval,
    pageTitle: "Approval",
    goBack: true,
  },
  {
    path: "/dashboard-general-approvals-revenue-projection-settings",
    component: ExpenditureMain,
    pageTitle: "Approval",
    goBack: true,
  },
];
