
import React from "react";
import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
import "../../../styles/auth/AuthLayout.css";
import checkMailImg from "../../../assets/email-verified-icon.svg";

const EmailVerified = () => {
  const history = useHistory();
  return (
    <>
      <AuthLayout>
        {/* check mail wrap start */}
        <div className="check-mail-wrap">
          <figure className="img-box img-box-email">
            <img src={checkMailImg} alt="" className="img" />
          </figure>
          <p className="title">Email verified !</p>
          <p className="small-text">
            The verified email address will be associated with your
          </p>
          <p className="small-text">
            account. Click on the button below to continue
          </p>

          {/* button start */}
          <button className="btn" onClick={() => history.push("/setup-org")}>
          Continue
          </button>
          {/* button end */}
          {/* already text start */}
          {/* <div className="already-box">
            <p className="text">
              Didn’t get the mail? <span>Resend</span>
            </p>
          </div> */}
          {/* already text end */}
        </div>
        {/* check mail wrap end */}
      </AuthLayout>
    </>
  );
};

export default EmailVerified;
