import React, { useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import imgOne from "../../../../../../assets/hod-two-img.svg";
import { useDispatch, useSelector } from "react-redux";
import { approveExpenditureProjection } from "../../../../../../redux/projections/ExpenditureProjection";
import { Dna } from "react-loader-spinner";

const ApprovalModal = ({ onCancel, onFinish }) => {
  const {  revenue_projection_overview } = useSelector(
    (state) => state.revenue_projection_chart
  );
  const [comments, setComments ] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.expenditure_projection_chart
  );
  const handleApprove = async () => {
    const obj = {
      organization_id: revenue_projection_overview?.organization?.id,
      department_id: revenue_projection_overview?.department?.id,
      // subsidiary_id: 17,
      projection_type: "revenue_projection",
      comment: comments,
      status: "approved",
      projection_id: revenue_projection_overview?.id,
    };
    // console.log(obj);
    const data = await dispatch(approveExpenditureProjection(obj));
    if (data?.payload?.success) {
      setComments("");
      onFinish();
    }
  };
  return (
    <div style={{ width: "55rem" }} className="overview-card-modal-wrap">
      <div className="top-box">
        <p className="title">Approve</p>
      </div>
      <div className="content-box">
        <div className="img-input-wrap">
          <figure className="img-box">
            <img src={imgOne} alt="" className="img" />
          </figure>
          <textarea
            placeholder="type here your comment"
            style={{ resize: "unset" }}
            name=""
            id=""
            cols="30"
            rows="5"
            className="input-text"
          ></textarea>
        </div>
      </div>
      <div className="bottom-box">
        <ButtonBlue onClick={onCancel} text={`Cancel`} />
        {!loading ? (
          <ButtonBlue onClick={handleApprove} text={`Yes, Approve `} />
        ) : (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="70"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovalModal;
