import Comparison from "./comparison";
import PricingContents from "./subscription";
import TopContent from "./topContent";

const Subscription = () => {
  return (
    <div>
      <PricingContents>
        <TopContent />
      </PricingContents>
      <Comparison />
    </div>
  );
};
export default Subscription;