import React, { useContext, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import Select from "react-select";
import ActionContext from "../../../../../../context/ActionContext";
// import RevenueChart from "../RevenueChart";
// import userImg from "../../../../../../assets/hod-one-img.svg";
// import RevenueChartOne from "../RevenueChartOne";
// import { useHistory } from "react-router-dom";
import EmployeeNetProfitProjection from ".";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
// import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
// import EmployeeMonthlyExpenseChart from "../chart/monthlyChart";
import EmployeeNetProfitProjectionGraph from "../chart/profitProjection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getEmployeeNetProfitProjection,
  getEmpNetProfitProjectionPagination,
} from "../../../../../../redux/employee/dashboard";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import TableBodyRow from "../../../../../../component/tables/TableBodyRow";
import {
  checkCurrencyFormat,
  formatNumber,
  // FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";

const EmployeeNetProfitProjectionIndex = ({ children, chartBox }) => {
  const actionCtx = useContext(ActionContext);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  // const history = useHistory();
  //   const [showChart, setShowChart] = useState(true);
  // const [indexNo, setIndexNo] = useState("");
  //   const [showChartBox, setShowChartBox] = useState(true);
  // const [chartFilterVal, setChartFilterVal] = useState("");

  const { loading, netprofitData } = useSelector(
    (state) => state.employee_dashboard
  );
  // const { userDepartmentDrop } = useSelector(
  //   (state) => state.expenditure_projection
  // );
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [totalNetProfit, setTotalNetProfit] = useState("");
  const [netProfitGraphVal, setNetProfotGraphVal] = useState({});

  const department_id = localStorage.getItem("employee-department-id");
  const userId = localStorage.getItem("employee-dashboard-user-id");
  // console.log(department_id)

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    if (user?.organizations[0]?.id || department_id) {
      handleGetNetProfitGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id, department_id, actionCtx?.currencyValue?.id]);

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    // const linkTwo = splitLink[1];
    // // console.log("linkTwo", linkTwo);
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // console.log("linkTwo", linkTwo);
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: department_id || userDepartmentDrop[0]?.id,
      user_id: userId,
      page: linkTwo,
      currency_id: actionCtx?.currencyValue?.id,
    };
    dispatch(getEmpNetProfitProjectionPagination(obj));
  };

  console.log(netprofitData, "netData");

  const handleGetNetProfitGraph = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: department_id || userDepartmentDrop[0]?.id,
      user_id: userId,
      currency_id: actionCtx?.currencyValue?.id,
    };
    const data = await dispatch(getEmployeeNetProfitProjection(obj));
    if (data?.payload?.success) {
      const totalNetProfitval =
        data?.payload?.data?.total_net_profit_projection;
      const netProfitGraph = data?.payload?.data?.projection_net_profit_graph;
      let netProfitGraphValues = [];
      // console.log(netProfitGraph, "net profit graph");
      for (const netProfitObj of netProfitGraph) {
        netProfitGraphValues.push(
          parseInt(netProfitObj?.value?.net_projected_graph)
        );
        // console.log(netProfitObj, "net profit chart");
      }
      // for(const netProfitData p)
      setNetProfotGraphVal(netProfitGraphValues);
      setTotalNetProfit(totalNetProfitval);
    }
  };

  const tableHeadList = [
    "Product",
    `Projected Expenditure (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label
    )})`,
    `Projected Revenue (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label
    )})`,
    `Total GP (${checkCurrencyFormat(actionCtx?.currencyValue?.label)})`,
    "Total GP%",
    `Net Profit Projection (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label
    )})`,
  ];

  return (
    <>
      <EmployeeNetProfitProjection
        netProVal={formatNumber(String(totalNetProfit || 0))}
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={` ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          ></div>
        )}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            {/* <div className="title-select-box">
              <p className="title">Net Profit Projection Graph</p>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Monthly"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={optionList}
              />
            </div> */}
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box" style={{ marginBottom: "3rem" }}>
              {/* <EmployeeMonthlyExpenseChart /> */}

              <EmployeeNetProfitProjectionGraph
                loading={loading}
                dataValue={netProfitGraphVal}
              />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            dontShowPagination={netprofitData?.data?.length < 1}
            fromPage={netprofitData?.from}
            toPage={netprofitData?.to}
            nextPage={netprofitData?.next_page_url}
            prevPage={netprofitData?.prev_page_url}
            onNextPage={() => handlePagination(netprofitData?.next_page_url)}
            onPrevPage={() => handlePagination(netprofitData?.prev_page_url)}
            totalPage={netprofitData?.total}
          >
            {netprofitData?.map((chi, idx) => {
              const {
                product_name,
                total_expediture,
                total_revenue,
                total_gp,
                // total_gp_percent,
                net_profit_projection,
                // status,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={product_name}
                  loading={loading}
                  two={formatNumber(String(total_expediture || 0))}
                  three={formatNumber(String(total_revenue || 0))}
                  four={formatNumber(String(total_gp || 0))}
                  five={formatNumber(String(net_profit_projection || 0))}
                  six={formatNumber(String(net_profit_projection || 0))}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </EmployeeNetProfitProjection>
    </>
  );
};

export default EmployeeNetProfitProjectionIndex;
