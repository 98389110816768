import React from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../../../styles/dashboard/modals/BudgetSettingCommonModal.css";
import { Dna } from "react-loader-spinner";

const CommonInputModal = ({
  children,
  onCancel,
  title,
  btnText,
  longContent,
  loading,
  onBtnClick,
  btnDisable,
  btnActive,
}) => {
  return (
    <div className="budget-settings-common-modal-wrap">
      <div className="title-box">
        <p className="title">{title || "title"}</p>
        <div onClick={onCancel} className="cancel-box">
          <FaTimes className="icon" />
        </div>
      </div>
      {/* content box start */}
      <div className={`content-box ${longContent && "content-box-long"}`}>
        {children}
      </div>
      {/* content box end */}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onBtnClick(e)
        }}
        action=""
        className="form"
      >
        {/* submit form start */}
        {!loading ? (
          <input
            disabled={btnDisable}
            type="submit"
            value={btnText}
            className={`form-group btn-reuse form-submit ${
              btnActive && "form-submit-active"
            }`}
            style={{ marginTop: "3rem" }}
            // onClick={() => {
            //   console.log("yes");
            // }}
          />
        ) : (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "flex-start", marginLeft: "2rem" }}
          >
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
        {/* submit form  end */}
      </form>

      {/* btn start */}
      {/* <div className="btn-box">
        <ButtonBlue text={btnText || "text"} />
      </div> */}
      {/* btn end */}
    </div>
  );
};

export default CommonInputModal;
