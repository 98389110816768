import React from "react";
// import ActionContext from "../../../../../../../context/ActionContext";
import "../../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeSettingsCommonNavLink from "./commonNavLink";

const EmployeeCommonSettingsLayout = ({ settingsLink, children }) => {
  // const actionCtx = useContext(ActionContext);

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Settings`}> */}
        <div className="expense-projection-inde-wrap">
          <>
            {settingsLink && (
              <EmployeeSettingsCommonNavLink
                link={"/employee-dashboard-home"}
              />
            )}
          </>
          <>{children}</>
          {/* children box end */}
        </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};

export default EmployeeCommonSettingsLayout;
