/* eslint-disable default-case */
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useThemeDetector } from "../../../../../utils/getThemeColor";
import { useSelector } from "react-redux";
// import faker from 'faker';
// ChartJS.pointWidth(10);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   maintainAspectRatio: false,
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "bottom",
//     },
//     title: {
//       display: false,
//       text: "Chart.js Bar Chart",
//     },
//   },
//   scales: {
//     x: {
//       grid: {
//         display: false,
//       },
//       barPercentage: 1.0,
//       categoryPercentage: 1.0,
//     },
//     // y: {  grid: {
//     //     display: false,
//     //   }
//     // }
//   },
// };

const labels = [
  "Eng.",
  "HR.",
  "Finance",
  "Admin Mgt.",
  "Strategy",
  "Brands & Comms.",
  "IT.",
  "Product & Sales",
  "Project",
];

// import React from 'react'

const NetProfitProjectionChart = ({ dataOne, dataTwo, dataToShow }) => {
  const isDarkTheme = useThemeDetector();
  const { preference } = useSelector((state) => state.preference);
  const brandcolor = localStorage.getItem("brand_color");
  const data = {
    data: {
      labels,
      datasets: [
        {
          label: "IT Horizons",
          data: dataOne || [
            4300, 1300, 6600, 3400, 900, 1200, 4500, 1000, 1100,
          ],
          backgroundColor: brandcolor || "#004BFF",
        },
        {
          label: "Zojatech",
          data: dataTwo || [
            2300, 1900, 5600, 1400, 1500, 3200, 7500, 100, 2500,
          ],
          backgroundColor: "#CC0905",
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          // display: false,
          position: "bottom",
        },
        title: {
          display: false,
          text: "Chart.js Line Chart",
        },
      },
      scales: {
        y: {
          grid: {
            color: "#1d1d18",
          },
          ticks: {
            color: "#1d1d18",

            padding: 2,
          },
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            // padding: 20,
            padding: 0,
            gap: 10,
            color: "#1d1d18",
          },
        },
      },
    },
  };
  return (
    <>
      <Bar
        // className="chart"
        redraw={false}
        width={100}
        height={35}
        options={data.options}
        data={dataToShow || data.data}
        ticks={data.ticks}
      />
    </>
  );
};

export default NetProfitProjectionChart;
