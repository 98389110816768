import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import noteIcon from "../../../../../../assets/note-icon.svg";
import ActionContext from "../../../../../../context/ActionContext";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import {
  getDepartmentBudgetReps,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import "../../../../../../styles/dashboard/admin-settings/AdminSettings.css";
import RemovableGroup from "./RemovableGroup";
import Select from "react-select";
// import { getApprovalClassById } from "../../../../../../redux/approvals";

const FormSummary = ({
  setObjToSubmit,
  getObj,
  triggerCancel,
  defaultObj,
  list,
}) => {
  // const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const { departmentDrop, loadingPage, departmentBudgetRep } = useSelector(
    (state) => state.department
  );
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const [details, setDetails] = useState({
    Name: "",
    subsidiary: "",
    subsidiary_id: "",
    department_id: "",
  });

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      // getSingleApproval()
    }
    // eslint-disable-next-line
  }, []);

  const [approvalList, setApprovalList] = useState([]);

  // const actionCtx = useContext(ActionContext)
  useEffect(() => {
    if (actionCtx?.activeOrg?.type !== "group") {
      setDetails((prev) => {
        return {
          ...prev,
          subsidiary_id: subsidiariesDrop[0]?.value,
          subsidiary: subsidiariesDrop[0],
        };
      });
      // handleGetBranchBySub(subsidiariesDrop[0])
      const obj = {
        subsidiary_id: subsidiariesDrop[0]?.value,
        department_id: " ",
      };
      setObjToSubmit(obj);
      dispatch(getDepartmentsBySubsidiary(subsidiariesDrop[0]?.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsidiariesDrop]);

  useEffect(() => {
    setApprovalList([]);
  }, [triggerCancel]);

  useEffect(() => {
    if (defaultObj?.flows?.length > 0) {
      // const newList = [...defaultObj?.flows, ...approvalList];
      setApprovalList(defaultObj?.flows);
      // console.log(defaultObj?.flows);
      // console.log(newList);
    }
    const obj = {
      subsidiary_id: defaultObj?.subsidiary,
      department_id: defaultObj?.department,
      flows: approvalList,
      subsidiary: defaultObj?.subsidiary,
    };
    // setDetails(obj)
    // const newList = defaultObj?.flows?.length > 0 ? [...defaultObj?.flows, ...approvalList] : approvalList;
    // console.log(obj);
    // console.log(defaultObj?.flows);
    // setApprovalList(defaultObj?.flows);
    setObjToSubmit(obj);
    // eslint-disable-next-line
  }, [getObj]);

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   const data = { ...details, [name]: value };
  //   setDetails(data);
  // };

  const handleAddToApprovalList = () => {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const obj = {
      user_id: "",
      num_required: "",
      time_frame: "",
      alternate_user: "",
      id: id,
      notify_approver: "",
    };
    const newList = [...approvalList, obj];
    // console.log(newList);
    setApprovalList(newList);
  };

  const handleSelectUser = (param, child) => {
    const newList = approvalList.map((chi) =>
      chi.id === child.id ? { ...chi, user_id: String(param.value) } : chi
    );
    // console.log(approvalList);
    // console.log(newList);
    setApprovalList(newList);

    const obj = {
      subsidiary_id: details?.subsidiary_id,
      department_id: details?.department_id?.id,
      flows: newList,
    };
    setObjToSubmit(obj);
  };

  const handleNotifyUser = (param, child) => {
    const newList = approvalList.map((chi) =>
      chi.id === child.id ? { ...chi, notify_approver: param } : chi
    );
    // console.log(approvalList);
    // console.log(newList);
    setApprovalList(newList);

    const obj = {
      subsidiary_id: details?.subsidiary_id,
      department_id: details?.department_id?.id,
      flows: newList,
    };
    setObjToSubmit(obj);
  };

  const handleSelectUserTwo = (param, child) => {
    const newList = approvalList.map((chi) =>
      chi.id === child.id
        ? { ...chi, alternate_user: String(param.value) }
        : chi
    );
    // console.log(newList);
    setApprovalList(newList);
    const obj = {
      subsidiary_id: details?.subsidiary_id,
      department_id: details?.department_id?.id,
      flows: newList,
    };
    setObjToSubmit(obj);
  };

  const handleSelectUserType = (param, child) => {
    if (param?.label === "Single User") {
      const newList = approvalList.map((chi) =>
        chi.id === child.id
          ? {
              ...chi,
              num_required: param.value,
              time_frame: "",
              user_two_name: "",
            }
          : chi
      );
      setApprovalList(newList);
      const obj = {
        subsidiary_id: details?.subsidiary_id,
        department_id: details?.department_id?.id,
        flows: newList,
      };
      setObjToSubmit(obj);
    } else {
      const newList = approvalList.map((chi) =>
        chi.id === child.id ? { ...chi, num_required: param.value } : chi
      );
      // console.log(newList);
      setApprovalList(newList);
      const obj = {
        subsidiary_id: details?.subsidiary_id,
        department_id: details?.department_id?.id,
        flows: newList,
      };
      setObjToSubmit(obj);
    }
  };

  const handleSelectUserTime = (param, child) => {
    const newList = approvalList.map((chi) =>
      chi.id === child.id ? { ...chi, time_frame: param.label } : chi
    );
    //   console.log(newList);
    setApprovalList(newList);
    const obj = {
      subsidiary_id: details?.subsidiary_id,
      department_id: details?.department_id?.id,
      flows: newList,
    };
    setObjToSubmit(obj);
  };

  const handleRemoveChild = (child) => {
    const newList = approvalList.filter((chi) => chi.id !== child.id);
    // console.log(child);
    // console.log(newList);
    setApprovalList(newList);
    // console.log(child);
    const obj = {
      subsidiary_id: details?.subsidiary_id,
      department_id: details?.department_id?.id,
      flows: newList,
    };
    setObjToSubmit(obj);
  };

  const handleBudgetRep = (list) => {
    const data = list?.map((chi) => {
      return {
        label: chi?.first_name + " " + chi?.last_name,
        value: chi?.id,
      };
    });
    return data;
  };

  return (
    <div>
      {/* form box start */}
      <div className="form-box">
        {/* <p className="form-title">Invite via </p> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // history.push("/dashboard-home");
          }}
          action=""
          className="form"
        >
          {/* wrap start */}
          <div className="wrapper">
            <p className="box-title">1.Approval Details</p>
            <div className="form-wrap" style={{ width: "80%" }}>
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Subsidiary" className="label">
                  Subsidiary <span>*</span>
                </label>
                <Select
                  className={`select-wrap ${
                    details?.subsidiary?.length > 0 &&
                    // Object?.keys(details?.subsidiary)?.length > 0 &&
                    "select-wrap-active"
                  }`}
                  placeholder="Select Subsidiary"
                  value={
                    actionCtx?.activeOrg?.type !== "group"
                      ? subsidiariesDrop || details?.subsidiary_id
                      : details?.subsidiary || defaultObj?.subsidiary_id
                  }
                  onChange={(e) => {
                    dispatch(getDepartmentsBySubsidiary(e.value));
                    const obj = {
                      subsidiary_id: e.value,
                      department_id: " ",
                    };
                    setObjToSubmit(obj);
                    setDetails((prev) => {
                      return {
                        ...prev,
                        subsidiary: e,
                        subsidiary_id: e.value,
                        department_id: "",
                      };
                    });
                  }}
                  styles={reactSelectStyle}
                  options={subsidiariesDrop}
                />
              </div>
              {/* group box end */}

              {/* group box start */}
              <div className="form-group">
                <label htmlFor="department" className="label">
                  Department <span>*</span>
                </label>
                <Select
                  className={`select-wrap ${
                    details?.department_id?.length > 0 && "select-wrap-active"
                  }`}
                  styles={reactSelectStyle}
                  placeholder="choose department"
                  value={details.department_id || defaultObj?.department_id}
                  // isMulti
                  onChange={async (e) => {
                    const obj = {
                      department_id: e.value,
                    };
                    setObjToSubmit(obj);
                    setDetails((prev) => {
                      return { ...prev, department_id: e };
                    });
                    await dispatch(getDepartmentBudgetReps(e.value));
                  }}
                  options={departmentDrop}
                  isLoading={loadingPage}
                />
              </div>
              {/* group box end */}

              {/* group box start */}

              <div className="form-group">
                <label
                  htmlFor="department"
                  className="label"
                  style={{ fontWeight: "500", color: "#1D1D18" }}
                >
                  Budget Reps
                </label>
                <Select
                  className={`select-wrap select-wrap-multi disable-select `}
                  styles={reactSelectStyle}
                  placeholder="Show Budget Reps"
                  isLoading={loadingPage}
                  // value={[
                  //   { label: "Hassan", value: "hassan" },
                  //   { label: "Emmanuel", value: "emmah" },
                  // ]}
                  value={details?.department_id ? handleBudgetRep(departmentBudgetRep) : ""}
                  isMulti
                  isDisabled
                />
              </div>

              {/* group box end */}
            </div>
          </div>
          {/* wrap end */}
          {/* wrap start */}
          <div className="wrapper">
            <p className="box-title"> 2. Approval Setup</p>
            {/* step assign box start */}
            <div className="step-assign-box">
              <div className="step-assign-line">
                <p className="text">step</p>
                <p className="text">Assigned To</p>
                <div className="text">
                  Approval required{" "}
                  <span>
                    {" "}
                    <figure
                      className="img-box"
                      style={{
                        filter: "saturate(0%)",
                        transform: "translateX(.5rem) translateY(.3rem)",
                      }}
                    >
                      <img src={noteIcon} alt="" className="img" />
                    </figure>
                  </span>
                </div>
              </div>
              {/* assign line end */}
            </div>
            {/* step assign box end */}
            {/* removable box start */}
            {/* {    console.log(approvalList)} */}
            <div className="removable-wrap">
              {approvalList?.map((chid, idx) => {
                const {
                  user_id,
                  time_frame,
                  alternate_user,
                  num_required,
                  notify_approver,
                } = chid;
                return (
                  <RemovableGroup
                    noBorder={approvalList?.length - idx === 1 ? true : false}
                    reUseableZindex={approvalList?.length - idx}
                    userSelectVal={user_id}
                    timeeSelectVal={time_frame}
                    userTwoSelectVal={alternate_user}
                    userTypeSelectVal={num_required}
                    list={approvalList}
                    key={chid.id || idx}
                    num={idx}
                    notifyChecked={notify_approver === 1 ? true : false}
                    handleRemoveChild={() => handleRemoveChild(chid)}
                    onSelectUser={(e) => handleSelectUser(e, chid)}
                    onSelectUserTwo={(e) => handleSelectUserTwo(e, chid)}
                    onSelectUserType={(e) => handleSelectUserType(e, chid)}
                    onSelectUserTime={(e) => handleSelectUserTime(e, chid)}
                    onNotify={(e) => {
                      handleNotifyUser(e, chid);
                    }}
                  />
                );
              })}
              {/* <RemovableGroup /> */}
            </div>
            {/* removable box end */}

            {/* add more start */}
            <div className="add-approval-box" onClick={handleAddToApprovalList}>
              <FaPlus className="icon" />
              <p className="text">Add Approval Step</p>
            </div>
            {/* add more end */}
          </div>
          {/* wrap end */}
          {/* wrap stsrt */}
          {/* <div className="wrapper" style={{borderBottom: "unset"}}> */}
          {/* <p className="box-title"> 3. Approval Settings</p>
            <div className="content">
              <div className="check-label-box">
                <input type="checkbox" className="check" id="check-notify" />
                <label htmlFor="check-notify" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Notify budget rep and budget owner of comments and approval
                  progress{" "}
                </label>
              </div> */}
          {/* check start */}
          {/* <div className="check-label-box">
                <input type="checkbox" className="check" id="check-email" />
                <label htmlFor="check-email" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send email reminder notification{" "}
                </label>
              </div> */}
          {/* check end */}
          {/* check start */}
          {/* <div className="check-label-box">
                <input type="checkbox" className="check" id="check-comment" />
                <label htmlFor="check-comment" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Allows comments to approve{" "}
                </label>
              </div> */}
          {/* check end */}
          {/* check start */}
          {/* <div className="check-label-box">
                <input type="checkbox" className="check" id="check-reject" />
                <label htmlFor="check-reject" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Allows comments to reject{" "}
                </label>
              </div> */}
          {/* check end */}
          {/* </div>
          </div> */}
          {/* wrap end */}
        </form>
      </div>
      {/* form box end */}
    </div>
  );
};

export default FormSummary;
