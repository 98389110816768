import React, { useContext } from "react";
import ButtonBlue from "../buttons/ButtonBlue";
import "../../styles/SessionTimeoutModal.css";
import ActionContext from "../../context/ActionContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SessionTimeoutModal = () => {
     function clearCache() {
        if ("caches" in window) {
          // Clear all caches
          caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
              caches.delete(cacheName);
            });
          });
        }
      }
    // const history = useHistory();
 const location = useLocation()
  const urlSave = location.pathname.split("/")[1];
  const actionCtx = useContext(ActionContext);
  const handleLogout = () => {
    actionCtx?.setTokenCrashed(false);
    actionCtx.setActiveOrgManual({})
    actionCtx?.profileBoxOpenFunc(false);
    // actionCtx?. 
    clearCache()
    localStorage.clear();
    window.location.replace(`${window.location.protocol + '//' + window.location.host}/login?${urlSave || ""}`);
  };

  return (
    <div className="session-time-out-modal-wrap">
      <div className="wrap">
        <p className="title">Session Expired</p>
        <p className="text">
          For security reasons, your session has expired. Please log in <br />{" "}
          again to continue
        </p>
        <ButtonBlue onClick={handleLogout} text={`Back to Login`} />
      </div>
    </div>
  );
};

export default SessionTimeoutModal;
