import React, { useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH, FaTimes } from "react-icons/fa";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import imgOne from "../../../../../../assets/hod-two-img.svg";
import cardviewImg from "../../../../../../assets/view-card-img.png";
import userImg from "../../../../../../assets/hod-one-img.svg";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import ApprovalContent from "../../actual/modals/contentHead";
import EmployeeOverviewTableBodyRow from "../../home/modals/Reimbursement/overviewTableRow";

const EmployeeOverviewRejectModal = ({
  onCancel,
  onApprove,
  onReject,
  onComment,
  onViewBigImg,
  rejectText,
  approveBtn,
  chart_of_account,
  classes,
  units,
  unitsTwo,
  unit_price,
  total_value,
  date_text,
  last_updated,
  bigImg,
  nextPage,
  onNextPage,
}) => {
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });
  const [showActions, setShowActions] = useState(false);

  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  const contentHeader = ["Months", "Units", "Total Value"];
  const contentHeaderTwo = ["Level", "Name", "Status"];

  const contentList = [
    {
      month: "Jan 2022",
      units: "0.00",
      total_value: "0.00",
    },
    {
      month: "Feb 2022",
      units: "1.00",
      total_value: "20,000.00",
    },
    {
      month: "Mar 2022",
      units: "0.00",
      total_value: "0.00",
    },
    {
      month: "Apr 2022",
      units: "1.00",
      total_value: "20,000.00",
    },
    {
      month: "May 2022",
      units: "0.00",
      total_value: "0.00",
    },
    {
      month: "Jun 2022",
      units: "1.00",
      total_value: "20,000.00",
    },
    {
      month: "Jul 2022",
      units: "0.00",
      total_value: "0.00",
    },
    {
      month: "Aug 2022",
      units: "1.00",
      total_value: "20,000.00",
    },
    {
      month: "Sep 2022",
      units: "0.00",
      total_value: "0.00",
    },
    {
      month: "Oct 2022",
      units: "1.00",
      total_value: "20,000.00",
    },
    {
      month: "Nov 2022",
      units: "0.00",
      total_value: "0.00",
    },
    {
      month: "Dec 2022",
      units: "1.00",
      total_value: "20,000.00",
    },
  ];

  const contentListTwo = [
    {
      level: "01",
      user: {
        img: userImg,
        name: "Hassan Lamidi",
        email: "hlamidi@zojatech.com",
      },
      status: "Rejected",
    },
    {
      level: "02",
      user: {
        img: userImg,
        name: "Hassan Lamidi",
        email: "hlamidi@zojatech.com",
      },
      status: "Pending",
    },
    {
      level: "03",
      user: {
        img: userImg,
        name: "Hassan Lamidi",
        email: "hlamidi@zojatech.com",
      },
      status: "Pending",
    },
    {
      level: "04",
      user: {
        img: userImg,
        name: "Hassan Lamidi",
        email: "hlamidi@zojatech.com",
      },
      status: "Pending",
    },
    {
      level: "05",
      user: {
        img: userImg,
        name: "Hassan Lamidi",
        email: "hlamidi@zojatech.com",
      },
      status: "Pending",
    },
  ];

  return (
    <div className="overview-card-modal-wrap">
      <div className="top-box">
        <p className="title" style={{ padding: "1rem" }}>
          Overview
        </p>
        {approveBtn && <ButtonBlue onClick={onApprove} text={`Approve`} />}
        <div
          onClick={() => setShowActions(!showActions)}
          className="ellipse-box"
        >
          <FaEllipsisH className="icon" />
          {/* action box start */}
          <div className={`action-box ${showActions && "action-box-active"}`}>
            {nextPage && (
              <p style={{ color: "#5B6871" }} onClick={onNextPage}>
                <span>Modify</span>
              </p>
            )}
            {rejectText && (
              <p onClick={onReject}>
                <span>Reject</span>
              </p>
            )}
            <p onClick={onComment}>
              <span style={{ color: "#5B6871" }}>{`View Comments`}</span>
            </p>
            <p>
              <span>Export as PDF</span>
            </p>
          </div>
          {/* action box end */}
        </div>
        <div onClick={onCancel} className="cancel-box">
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      <div className="content-box">
        {/* title drop control box start */}
        <>
          <div
            onClick={() => handleShowBox("one")}
            className="title-drop-control-box"
          >
            <p className="title">Budget Owner Details</p>
            <div className="icon-box">
              {!showBox.one && <FaAngleDown className="icon" />}
              {showBox.one && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.one && (
            <div className="content-wrap">
              <div className="three-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Chart of Account</p>
                  <p className="value">{chart_of_account}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Class</p>
                  <p className="value">{classes}</p>
                </div>
                {/* box end */}
                {/* box start */}
                {units && (
                  <div className="box">
                    <p className="text">Units</p>
                    <p className="value">1.00</p>
                  </div>
                )}
                {/* box end */}
              </div>
              <div className="three-box">
                {unitsTwo && (
                  <div className="box">
                    <p className="text">Units</p>
                    <p className="value">1.00</p>
                  </div>
                )}
                {/* box start */}
                {unit_price && (
                  <div className="box">
                    <p className="text">Unit Price</p>
                    <p className="value">20,000.00</p>
                  </div>
                )}
                {/* box end */}
                {/* box start */}
                {total_value && (
                  <div className="box">
                    <p className="text">Total Value</p>
                    <p className="value">20,000.00</p>
                  </div>
                )}
                {/* box end */}
              </div>
              <div className="one-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Description</p>
                  <p className="value">employee food</p>
                </div>
                {/* box end */}
              </div>
              <div className="two-box">
                {/* box start */}
                <div className="box">
                  <p className="text">{date_text}</p>
                  <p className="value">25 May, 2022 - 7:08pm</p>
                </div>
                {/* box end */}
                {/* box start */}
                {last_updated && (
                  <div className="box">
                    <p className="text">Last Updated</p>
                    <p className="value">26 May, 2022 - 7:08pm</p>
                  </div>
                )}
                {/* box end */}
              </div>

              {/* created by wrap start */}
              <div className="one-box">
                <div className="box">
                  <p className="text">Created by</p>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="img-text-box"
                  >
                    <figure style={{ marginRight: "1rem" }} className="img-box">
                      <img src={imgOne} alt="" className="img" />
                    </figure>
                    <div className="text-box">
                      <p className="value">Lamidi Hassan</p>
                      <p style={{ marginBottom: "unset" }} className="text">
                        hlamidi@zojatech.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* created by wrap end */}
              {/* image wrap box start */}
              {bigImg && (
                <div
                  className="image-wrap-box"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <div onClick={onViewBigImg} className="box-img">
                    <figure className="img-box">
                      <img src={cardviewImg} alt="" className="img" />
                    </figure>
                  </div>

                  <div onClick={onViewBigImg} className="box-img">
                    <figure className="img-box">
                      <img src={cardviewImg} alt="" className="img" />
                    </figure>
                  </div>
                </div>
              )}
              {/* <div onClick={onViewBigImg} className="box-img">
                  <figure className="img-box">
                    <img src={cardviewImg} alt="" className="img" />
                  </figure>
                </div> */}

              {/* <div onClick={onViewBigImg} className="box-img">
                  <figure className="img-box">
                    <img src={cardviewImg} alt="" className="img" />
                  </figure>
                </div> */}
              {/* image wrap box end */}
            </div>
          )}
        </>
        {/* title drop control box end */}

        {/* title drop control box start */}
        <>
          <div
            onClick={() => handleShowBox("two")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Monthly Breakdown</p>
            <div className="icon-box">
              {!showBox.two && <FaAngleDown className="icon" />}
              {showBox.two && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.two && (
            <div>
              <ApprovalContent
                headerOne={contentHeader[0]}
                headerTwo={contentHeader[1]}
                headerThree={contentHeader[2]}
              />
              {contentList.map((chi, idx) => {
                const { month, units, total_value } = chi;
                return (
                  <>
                    <EmployeeOverviewTableBodyRow
                      key={idx}
                      one={month}
                      two={units}
                      three={total_value}
                    />
                  </>
                );
              })}
            </div>
          )}
          {/* {showBox.two && <div className="content-wrap"></div>} */}
        </>
        {/* title drop control box end */}
        {/* title drop control box start */}
        <>
          <div
            onClick={() => handleShowBox("three")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Approval Flow</p>
            <div className="icon-box">
              {!showBox.three && <FaAngleDown className="icon" />}
              {showBox.three && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.three && (
            <div className="content-wra">
              <ApprovalContent
                headerOne={contentHeaderTwo[0]}
                headerTwo={contentHeaderTwo[1]}
                headerThree={contentHeaderTwo[2]}
              />
              {contentListTwo.map((chi, idx) => {
                const { level, user, status } = chi;
                return (
                  <>
                    <EmployeeOverviewTableBodyRow
                      key={idx}
                      one={level}
                      userOne={user}
                      status={status}
                    />
                  </>
                );
              })}
            </div>
          )}
        </>
        {/* title drop control box end */}
      </div>
      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default EmployeeOverviewRejectModal;
