import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import "../../../../../employee-folder/styles/dashboard/home/chart/LineChart.scss";
// import Select from "react-select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useThemeDetector } from "../../../../../utils/getThemeColor";
import { useSelector } from "react-redux";
import { numberFormatChart } from "../../../../../helper/Helpers";
import { Oval } from "react-loader-spinner";
// import ActionContext from "../../../../../context/ActionContext";
// import Select from "react-select/dist/declarations/src/Select";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const NetProfitChart = ({
  children,
  pending,
  approve,
  approveLabel,
  pendingLabel,
  dataValue,
  loading,
}) => {
  const isDarkTheme = useThemeDetector();
  // const actionCtx = useContext(ActionContext)
  const { preference } = useSelector((state) => state.preference);
  const [data] = useState({
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          // label: "First Dataset",
          //   data: [8, 10, 24, 33, 75, 145, 87, 250, 450, 420, 425, 976 ],
          data: [10, 20, 40, 35, 78, 45, 56, 34, 97, 89, 100, 99],
          backgroundColor: `${approve ? "#07A287" : pending ? "#FCA549" : ""}`,
          borderRadius: "3rem",
          borderColor: `${approve ? "#07A287" : pending ? "#FCA549" : ""}`,
          tension: 0.2,
          pointBorderColor: "#FFF",
          showLine: true,
          label: `${
            approveLabel ? "Net Profit" : pendingLabel ? "Gross Profit" : ""
          }`,
          display: false,
        },
      ],
    },
    options: {
      pointRadius: 2,
      pointHoverRadius: 3,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        title: {
          display: false,
          text: "Chart.js Line Chart",
        },
      },
      scales: {
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            callback: (val) => `${numberFormatChart(val)}`,
            padding: 10,
            suggestedMin: 0,
            suggestedMax: -4,
            color: "#1d1d18",
          },
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
            // color: "red",
          },
          ticks: {
            color: "#1d1d18",
          },
        },
      },
    },
  });

  const dataVal = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Projected",
        data: [10, 20, 40, 35, 18, 45, 56, 34, 97, 99, 100, 99],
        borderColor: "#07A287",
        backgroundColor: "#07A287",
        tension: 0.5,
      },
      {
        label: "Actual",
        data: [10, 20, 40, 35, 78, 45, 56, 54, 97, 9, 10, 79],
        borderColor: "#FCA549",
        backgroundColor: "#FCA549",
        tension: 0.5,
      },
    ],
  };

  if (loading) {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "25rem",
            display: "grid",
            placeItems: "center",
          }}
          className=""
        >
          <Oval
            height={70}
            width={70}
            color={`#020202`}
            wrapperStyle={{}}
            wrapperClass="loader-spiner-react"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="rgba(0,0,0, .2)"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ width: "98%", height: "100%" }}>
        <Line data={dataValue || dataVal} options={data.options} />
        {children}
      </div>
    </>
  );
};
export default NetProfitChart;
