import React, { useContext, useEffect, useRef, useState } from "react";
import { FaCheck, FaMinus, FaPlus } from "react-icons/fa";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeSpreadForm from "./SpreadForm";
// import "aos/dist/aos.css";
// import Aos from "aos";
import Modal from "react-awesome-modal";
import EmployeeMonthlyForm from "./MonthlyForm";
import { useHistory } from "react-router-dom";
import EmployeeBulkModal from "./modals/upload";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
  reactSelectStyleTable,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
// import { useContext } from "react";
// import ActionContext from "../../../../../../context/ActionContext";
import Flatpickr from "react-flatpickr";
import "../../../../../styles/dashboard/projection/expense/expenditureProjection.css";
import "flatpickr/dist/themes/airbnb.css";
import {
  createExpenditureProjection,
  ExpenditureProjectionDownload,
  getDeptBySubsidiary,
  getSubsidiariesByOrg,
  getUserDepartment,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { Dna } from "react-loader-spinner";
import moment from "moment";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import { NumericFormat } from "react-number-format";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import {
  getDepartment,
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { toast } from "react-toastify";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { checkDepartmentApprovalFlow } from "../../../../../../redux/employee/approval-flow";
import RevvexActionBar from "../../../../../../component/fragments/ActionBar";
import ActionContext from "../../../../../../context/ActionContext";
// import { handleNumberFormatInput } from "../../../../../../helper/Helpers";
// import { useThemeDetector } from "../../../../../../utils/getThemeColor";
// import { getUser } from "../../../../../../redux/users/users";

const EmployeeNewExpenditureProjection = () => {
  const [spreadForm, setSpreadForm] = useState(false);
  // const [btnChange, setBtnChange] = useState(false);
  const [monthlyForm, setMonthlyForm] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const { loadingPage, departmentDrop } = useSelector(
    (state) => state.department
  );
  const { loadingFlow } = useSelector((state) => state.verify_approval_flow);

  const [changeLabel, setChangeLabel] = useState({
    inputLabel: "Quantity",
    totalLabel: "Total Quantity",
  });

  const [details, setDetails] = useState({
    projection_type: "",
    date: "",
    user_id: "",
    department_id: "",
    chart_of_account_id: "",
    organization_id: "",
    description: "",
    unit_price: "",
    units: "",
    subsidiary_id: "",
    fiscal_year_id: "",
    total_value: "",
    // monthly_breakdown: [{ month_name: { label: "" }, value: "" }],
    monthly_breakdown: [{ name: "", value: "" }],
    is_submitted: "",
    entry_type: "",
    total_quantity: "",
    is_shared_service: "",
    departments_aportions: [
      { department_id: "", subsidiary_id: "", apportions: "" },
    ],
    // total_amount: "",
  });
  const [startDate, setstartDate] = useState("");
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);
  const { user } = useSelector((state) => state.user);
  const [refreshTable] = useState(false);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  // const actionCtx = useContext(ActionContext);
  const [totalQuantity, setTotalQuatity] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [spreadTotalVal, setSpreadTotalVal] = useState("");
  const { loading, userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [draftLoader, setDraftLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);
  const [allSubisidiarySelected, setAllSelectedSubsidiary] = useState([]);
  // const [deptLoader, setDeptLoader] = useState(false);

  // to handle shared service check
  const [isSharedBudget, setIsSharedBudget] = useState(false);
  const [isDeptBudget, setIsDeptBudget] = useState([]);
  const [selectSharedDept, setSelectSharedDept] = useState({});
  const [apportionBudget, setApportionBudget] = useState("");
  const [subOption, setSubOption] = useState([]);
  const [subsidiaryBudgetId, setSubsidiaryBudgetId] = useState("");
  const [approvalFlowAlert, setApprovalFlowAlert] = useState("");

  // const [sharedBudgetDept, setSharedBudgetDept] = useState({});
  const [budgetPercentage, setBudgetPercentage] = useState("");
  const [departmentOption, setDepartmentOption] = useState([]);

  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const entryType = [
    {
      label: "Quantity",
      value: 1,
    },
    {
      label: "Amount",
      value: 2,
    },
  ];

  const enableSubmit = () => {
    const checkSelectedDept = allSubisidiarySelected?.map(
      (chi) => chi?.selected_departments || ""
    );

    let allowSubmit = false;

    allowSubmit =
      startDate &&
      details?.fiscal_year_id &&
      details?.chart_of_account_id &&
      details?.subsidiary_id &&
      // details?.departments_aportions &&
      details?.department_id &&
      details?.description &&
      approvalFlowAlert == "" &&
      details?.unit_price;

    if (details.projection_type === "spread") {
      allowSubmit = allowSubmit && details?.units;
    } else {
      allowSubmit =
        totalValue &&
        totalQuantity &&
        details?.monthly_breakdown &&
        details?.monthly_breakdown[0] &&
        details?.entry_type &&
        allowSubmit;
    }
    if (isSharedBudget && checkSelectedDept) {
      allowSubmit =
        allowSubmit &&
        allSubisidiarySelected[0] &&
        checkSelectedDept[0][0]?.shared_apportion;
    }

    return allowSubmit;
  };

  useEffect(() => {
    // dispatch(getCountries())
    const chartAccObj = {
      id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
      reason: true,
    };
    if (actionCtx?.activeOrg?.id) {
      dispatch(getChatAccounts(chartAccObj));
      dispatch(getFiscalYears(chartAccObj));
      dispatch(getSubsidiaries(chartAccObj));
      dispatch(getDepartments(actionCtx?.activeOrg?.id));
      dispatch(getUserDepartment(actionCtx?.activeOrg?.id));
    }

    // eslint-disable-next-line
  }, [refreshTable, actionCtx?.activeOrg?.id]);

  const history = useHistory();
  // setSpreadForm(!spreadForm);

  const handleAddToMonthList = () => {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const obj = {
      id: id,
      name: { label: "", value: "" },
      value: "",
    };
    const newList = {
      ...details,
      monthly_breakdown: [...details?.monthly_breakdown, obj],
    };
    setDetails(newList);
  };

  // const enableSubmit = () => {

  // }

  const handleSelectOptionToMonthList = (e, item) => {
    // // console.log(e);
    // // console.log(item);
    const newList = details?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id ? { ...chi, name: e } : chi
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };

  const handleInputOptionToMonthList = (e, item) => {
    const newList = details?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id
        ? {
            ...chi,
            value: e.target.value,
          }
        : chi
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };

  useEffect(() => {
    if (
      details?.projection_type === "monthly" &&
      details?.entry_type?.label === "Quantity"
    ) {
      const totalQua = details?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      setTotalValue(totalQuaAddList);

      const totalQuaVal =
        totalQuaAddList *
        formatNumberWithoutComma(String(details?.unit_price || 0));
      setTotalQuatity(totalQuaVal);
      // setTotalValue(totalQuaVal);
      // const totalQuaAddListVal = totalQuaAddList;
    }
    if (details?.entry_type?.label === "Amount" && details?.unit_price) {
      const totalQua = details?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      setTotalValue(totalQuaAddList);
      const totalQuaAddListVal = totalQuaAddList;

      setTotalQuatity(totalQuaAddListVal);
      const totalAmtVal =
        totalQuaAddListVal /
        formatNumberWithoutComma(String(details?.unit_price));
      const round = totalAmtVal || 0;
      setTotalValue(round);
    }
  }, [details]);

  // added changes to the calculation

  // spread total value
  useEffect(() => {
    if (details?.projection_type === "spread") {
      const totalSpreadVal =
        formatNumberWithoutComma(String(details?.unit_price)) *
        formatNumberWithoutComma(String(details?.units));
      // const totalSpreadValString = totalSpreadVal;
      setSpreadTotalVal(totalSpreadVal);
    }
  }, [details]);

  const handleRemoveOptionMonthList = (item) => {
    const newList = details?.monthly_breakdown?.filter(
      (chi) => chi?.id !== item?.id
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };

  const [isApportion, setIsApportion] = useState([]);

  const editApportionToSubmit = (list, dept) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          subsidiary_id: chi?.subsidiary?.id,
          department_id: dept,
          apportion: chi?.budget_percentage,
        };
      });
      // console.log(newList, "newList");
      setIsApportion(newList);
    }
  };
  // const formatApportionToSubmit = (obj, e) => {
  //   const newList = isApportion?.map((chi) =>
  //     obj?.id === chi?.id ? { ...chi, apportion: e } : chi
  //   );
  //   setIsApportion(newList);
  // };

  const [apportionSum, setApportionSum] = useState("");
  // useEffect(() => {
  //   handleApportionBudgetSum();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleApportionSum = () => {
  //   const totalApportion = isApportion
  //     ?.map((chi) => chi.apportion)
  //     .reduce((a, b) => Number(a) + Number(b), 0);
  //   setApportionSum(totalApportion);
  // };

  const [isApportionAlert, setIsApportionAlert] = useState(false);

  // const [apportionLengthCheck, setApportionLengthCheck] = useState([]);````

  const [apportionWarningMsg, setApportionWarningMsg] = useState("");
  const handleSharedSum = () => {
    const newApportionListForShared = allSubisidiarySelected?.map((chi) => {
      return {
        subsidiary_id: chi?.value,
        apportions: chi?.budget_percentage,
        label: chi?.label,
        subsidiary_departments: chi?.selected_departments?.map((child) => {
          return {
            department_id: child?.value,
            apportion: child?.shared_apportion,
          };
        }),
      };
    });

    const newInvalidBudgetLength = [];

    newApportionListForShared?.forEach((chi) => {
      const totalApportion = chi?.subsidiary_departments
        ?.map((chil) => chil?.apportion)
        ?.reduce((a, b) => Number(a) + Number(b), 0);

      if (totalApportion !== 100) {
        newInvalidBudgetLength.push({
          ...chi,
          subsidiary_id: chi.subsidiary_id,
          label: chi?.label,
          department_id: chi.subsidiary_departments.find(
            (chil) => chil.apportion !== 100
          ),
        });
      }
    });

    if (newInvalidBudgetLength.length > 0) {
      setIsApportionAlert(true);
      const errorMessage = newInvalidBudgetLength
        .map((dept) => {
          return `Subsidiary: ${dept.label}.`;
        })
        .join("\n");

        setApportionWarningMsg(
          `Budget Allocation Error: The total allocation across all categories must add up to 100% of \n${errorMessage}`
        );
      // setTimeout(() => {
      //   // toast.error(
      //   //   `Budget Allocation Error: The total allocation across all categories must add up to 100% of \n${errorMessage}`,
      //   //   {
      //   //     theme: "colored",
      //   //   }
      //   // );

      // }, 1500);
      // setTimeout(() => {
      //   setIsApportionAlert(false);
      // }, 7000);
    } else {
      setIsApportionAlert(false);
    }
  };

  const handleApprovalFlowCheckByDept = async () => {
    const checkObj = {
      projection_type: "Expenditure Projection",
      department_id: details?.department_id?.value,
    };
    const checkData = await dispatch(checkDepartmentApprovalFlow(checkObj));
    // console.log(checkData, "data");
    if (checkData?.payload?.status !== 200) {
      setApprovalFlowAlert(checkData?.payload?.message);
    } else {
      setApprovalFlowAlert("");
    }
  };

  useEffect(() => {
    if (details?.department_id?.value) {
      handleApprovalFlowCheckByDept();
    }
  }, [details?.department_id?.value]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newList = details.monthly_breakdown.map((chi) => {
      return {
        value: formatNumberWithoutComma(String(chi.value)),
        name: chi?.name?.label,
      };
    });

    const newApportionListForSHared = allSubisidiarySelected?.map((chi) => {
      return {
        subsidiary_id: chi?.value,
        apportions: Number(chi?.budget_percentage),
        subsidiary_departments: chi?.selected_departments?.map((child) => {
          return {
            department_id: child?.value,
            apportion: Number(child?.shared_apportion),
          };
        }),
      };
    });

    const obj = {
      ...details,
      chart_of_account_id: details?.chart_of_account_id?.value,
      monthly_breakdown: newList,
      date: startDate,
      organization_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
      user_id: user?.id,
      apportion_length: apportionSum,
      departments_aportions: newApportionListForSHared,
      is_submitted: details?.is_submitted === 1 ? 1 : 0,
      total_quantity: totalValue,
      department_id: details?.department_id?.value,
      subsidiary_id: details?.subsidiary_id?.value,
      fiscal_year_id: details?.fiscal_year_id?.value,
      total_value: formatNumberWithoutComma(String(totalQuantity)),
      entry_type: details.entry_type?.label,
      // is_shared_service: isApportion?.length > 0 ? true : false,
      is_shared_service: isSharedBudget ? true : false,
      projection_type: details?.projection_type,
      unit_price: formatNumberWithoutComma(String(details?.unit_price)),
      units:
        details?.projection_type === "monthly"
          ? totalValue
          : formatNumberWithoutComma(String(details?.units)),
      // departments_aportions_two: newApportionListForSHared,
    };
    // console.log("objective", obj);
    // return
    if (details?.projection_type === "spread") {
      delete obj["monthly_breakdown"];
      obj["total_value"] = formatNumberWithoutComma(String(spreadTotalVal));
    }

    // console.log(obj, "obj");
 
    if (isApportionAlert) {
      // setTimeout(() => {
      //   setApproveLoader(false) || setDraftLoader(false);
      // }, 2000);
      return;
    }
    if (obj?.is_submitted === 0) {
      setDraftLoader(true);
    }
    if (obj?.is_submitted === 1) {
      setApproveLoader(true);
    }

    const data = await dispatch(createExpenditureProjection(obj));
    if (data?.payload?.success && obj?.is_submitted === 0) {
      setDraftLoader(false);
      history.push("/employee-dashboard-projection-expenditure");
    } else if (data?.payload?.success && obj?.is_submitted === 1) {
      setApproveLoader(false);
      history.push("/employee-dashboard-projection-expenditure-submitted");
    } else {
      setDraftLoader(false);
      setApproveLoader(false);
    }
  };

  const handleSpreadForm = () => {
    setSpreadForm(true);
    setMonthlyForm(false);
    // setBtnChange(true);
  };

  const handleMonthlyForm = () => {
    setMonthlyForm(true);
    setSpreadForm(false);
    // setBtnChange(true);
  };

  // const { preference } = useSelector((state) => state.preference);
  // const isDarkTheme = useThemeDetector();
  // require(`flatpickr/dist/themes/${
  //   preference?.display === "Light"
  //               ? "airbnb"
  //               : preference?.display === "Dark"
  //               ? "dark"
  //               : isDarkTheme
  //               ? "dark"
  //               : "airbnb"
  // }.css`);

  const apportionData =
    details?.department_id?.branch?.subsidiary?.shared_service;

  const handleSubOptions = async () => {
    const subsidiaryOptions = isApportion?.map((chi) => {
      return {
        // ...chi,
        value: chi?.subsidiary?.id,
        label: chi?.subsidiary?.name,
        budget_percentage: chi?.budget_percentage,
      };
    });
    setSubOption(subsidiaryOptions);
  };

  // const handleSharedDeptOptions = async (param) => {
  //   await dispatch(getDepartmentsBySubsidiary({ reason: true, id: param }));
  //   // const newData = data?.payload?.data?.departments;
  //   // const newValue = newData?.map((chi) => {
  //   //   return {
  //   //     value: chi?.id,
  //   //     label: chi?.name,
  //   //   };
  //   // });
  //   // setSharedBudgetDept(newValue);
  // };

  const handleAddDeptBudget = (id) => {
    const uid = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);

    const obj = {
      id: uid,
      department_id: "",
      budget_apportion: "",
    };
    const newItem = [...isDeptBudget, obj];
    setIsDeptBudget(newItem);
  };

  const handleSelectBudgetApportion = (param, child) => {
    const newList = isDeptBudget.map((chi) =>
      chi.id === child.id ? { ...chi, budget_apportion: param } : chi
    );
    setIsDeptBudget(newList);
  };
  const handleRemoveDeptBudget = (child) => {
    const newList = isDeptBudget.filter((chi) => chi.id !== child.id);
    setIsDeptBudget(newList);
  };

  const handleBudgetPercentageChange = (e) => {
    const newValue = e.target.value;
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      const updatedSubsidiaryBudgetId = {
        ...subsidiaryBudgetId,
        budget_percentage: newValue,
      };
      setSubsidiaryBudgetId(updatedSubsidiaryBudgetId);
    }
  };

  // const formatApportionToSubmit = (obj, e) => {
  //   const newList = isApportion?.map((chi) =>
  //     obj?.id === chi?.id ? { ...chi, apportion: e } : chi
  //   );
  //   setIsApportion(newList);
  // };

  const handleMultiSelectChange = (
    selectedValues,
    { action, removedValue },
    list
  ) => {
    const addSharedPortionToList = (param) => {
      const newList = param?.map((chi, idx) => {
        return { ...chi, shared_apportion: "" };
      });
      return newList;
    };

    if (action === "select-option") {
      const newlyAddedOptionList = selectedValues.map((option) =>
        !allSubisidiarySelected?.includes(option)
          ? {
              ...option,
              departments: addSharedPortionToList(list),
              selected_departments: [],
            }
          : option
      );
      // console.log("Newly added option:", newlyAddedOptionList);
      setAllSelectedSubsidiary(newlyAddedOptionList);
    }
  };

  const handleMultiSelectChangeTwo = (
    selectedValues,
    { action, removedValue },
    list,
    id
  ) => {
    const getObjChild = allSubisidiarySelected?.find(
      (chi) => chi?.value === id
    );
    const addSelectedToObjChild = {
      ...getObjChild,
      selected_departments: selectedValues,
    };
    // console.log(addSelectedToObjChild);
    const addBackToAllList = allSubisidiarySelected?.map((chi) =>
      chi?.value === id ? addSelectedToObjChild : chi
    );
    setAllSelectedSubsidiary(addBackToAllList);

    // if (action === "select-option") {
    //   const newlyAddedOptionList = allSubisidiarySelected.map((option) =>
    //     !allSubisidiarySelected?.includes(option)
    //       ? {
    //           ...option,
    //           selected_departments: selectedValues,
    //         }
    //       : option
    //   );
    //   console.log("Newly added option:", newlyAddedOptionList);
    //   // setAllSelectedSubsidiary(newlyAddedOptionList);
    // }
  };

  const handleFormatDepartmentDrop = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return { ...chi, label: chi?.name, value: chi?.id };
      });
      return newList;
    }
  };

  const handleApportionDeptChange = (sudId, deptId, value) => {
    const getObjChild = allSubisidiarySelected?.find(
      (chi) => chi?.value === sudId
    );

    const addSelectedToObjChild = getObjChild?.selected_departments?.map(
      (chi) =>
        chi?.value === deptId ? { ...chi, shared_apportion: value } : chi
    );

    const addBackToAllList = allSubisidiarySelected?.map((chi) =>
      chi?.value === sudId
        ? { ...chi, selected_departments: addSelectedToObjChild }
        : chi
    );
    setAllSelectedSubsidiary(addBackToAllList);
  };

  // const hanleCheckSubmit = () => {
  //   const alertText = "The sharing percentage is above 100%";
  //   console.log(apportionSum);
  //   if (isSharedBudget && apportionSum > 100) {
  //     toast.error(alertText, {
  //       theme: "colored",
  //     });
  //     // setDraftLoader(false);
  //     // setApproveLoader(false);
  //     // return;
  //   }
  // };

  const handleDeleteChildFromAll = (sudId, deptId) => {
    const getObjChild = allSubisidiarySelected?.find(
      (chi) => chi?.value === sudId
    );
    const addSelectedToObjChild = getObjChild?.selected_departments?.filter(
      (chi) => chi?.value !== deptId
    );
    // console.log(addSelectedToObjChild);
    const addBackToAllList = allSubisidiarySelected?.map((chi) =>
      chi?.value === sudId
        ? { ...chi, selected_departments: addSelectedToObjChild }
        : chi
    );
    setAllSelectedSubsidiary(addBackToAllList);
  };

  const handleNewBudgetPercentage = (value, valId) => {
    const newValue = allSubisidiarySelected?.map((chi) =>
      chi?.value === valId ? { ...chi, budget_percentage: value } : chi
    );
    setAllSelectedSubsidiary(newValue);
  };

  const handleDepartmentOptions = (option) => {
    if (option?.length > 0) {
      const newOption = option?.map((chi) => {
        return {
          ...chi,
          label: chi?.name,
          value: chi?.id,
        };
      });
      return newOption;
    }
  };

  return (
    <div>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Expenditure Projection`}> */}
      {/* action bar start */}

      {(approvalFlowAlert || isApportionAlert) && (
        <RevvexActionBar
          type={"warning"}
          visible={approvalFlowAlert || apportionWarningMsg}
          msg={approvalFlowAlert || apportionWarningMsg}
          // onAction={() =>
          //   history.push(
          //     "/employee-dashboard-projection-expenditure-submitted"
          //   )
          // }
          onCancel={() => {
            setApprovalFlowAlert("");
            setIsApportionAlert(false);
          }}
          // actionText={"Submitted Projections"}
        />
      )}
      {/* action bar end */}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">New Expenditure Projection</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          <div className="btn-download-box ">
            <button className={`btn-bulk `} onClick={() => setShowModal(true)}>
              <FaPlus className="icon" /> Bulk Upload
            </button>
            {loading ? (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <p
                className="text"
                onClick={() => dispatch(ExpenditureProjectionDownload())}
              >
                See and download template
              </p>
            )}
          </div>
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container */}

        <div className="form-box" style={{ width: "80%" }}>
          <form onSubmit={handleSubmit} action="" className="form">
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Projection Information</p>
              <div className="form-wrap">
                {/* group box start */}

                <div className="form-group">
                  <label htmlFor="date" className="label">
                    Date <span>*</span>
                  </label>
                  <Flatpickr
                    id="date"
                    name="date"
                    className={`input ${startDate && "input-active"}`}
                    autoComplete="off"
                    value={startDate}
                    onChange={(date) => {
                      setstartDate(moment(date[0]).format("YYYY-MM-DD"));
                    }}
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Chart of account <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.chart_of_account_id && "select-wrap-active"
                    }`}
                    placeholder="Select chart of account"
                    value={details?.chart_of_account_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, chart_of_account_id: e };
                      });
                    }}
                    // isLoading={loading}
                    styles={reactSelectStyle}
                    options={chatAccountDrop}
                  />
                </div>
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    placeholder="Select Subsidiary"
                    value={details.subsidiary_id}
                    onChange={async (e) => {
                      setDetails((prev) => {
                        // setDeptLoader(true);
                        return { ...prev, subsidiary_id: e };
                      });
                      const obj = {
                        reason: true,
                        id: e?.value,
                      };
                      const data = await dispatch(
                        getDepartmentsBySubsidiary(obj)
                      );
                      if (data?.payload?.success) {
                        const newData = data?.payload?.data?.departments;
                        setDepartmentOption(newData);
                      }

                      // if (data?.payload?.success) {
                      //   // setDeptLoader(false);
                      // } else {
                      //   setDeptLoader(false);
                      // }
                    }}
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    // isLoading={isLoading}
                  />
                </div>
                {/* group box end */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Department <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.department_id && "select-wrap-active"
                    }`}
                    placeholder="Select Department"
                    value={details?.department_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, department_id: e };
                      });

                      editApportionToSubmit(
                        e?.branch?.subsidiary?.shared_service[0]?.apportions,
                        e?.id
                      );
                      setIsSharedBudget(apportionData?.length < 1 && false);
                      setAllSelectedSubsidiary(
                        apportionData?.length < 1 ? [] : allSubisidiarySelected
                      );
                    }}
                    // isLoading={loading}
                    styles={reactSelectStyle}
                    // options={
                    //   user?.user_type === "ADMIN" && details?.subsidiary_id
                    //     ? departmentDrop
                    //     : user?.user_type === "EMPLOYEE" &&
                    //       details?.subsidiary_id
                    //     ? userDepartmentDrop
                    //     : []
                    // }
                    options={handleDepartmentOptions(departmentOption)}
                    // isLoading={deptLoader}
                    isLoading={loadingPage}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Fiscal Year <span>*</span>
                  </label>
                  <Select
                    placeholder="Select fiscal year"
                    className={`select-wrap ${
                      details.fiscal_year_id && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    value={details?.fiscal_year_id}
                    // isLoading={loading}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, fiscal_year_id: e };
                      });
                    }}
                    options={fiscalYearDrop}
                  />
                </div>
                {/* group box end */}

                {/* group box start */}
                <br />
                <div className="form-group">
                  <label htmlFor="description" className="label">
                    Description <span>*</span>
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={handleChange}
                    style={{
                      width: "207%",
                      padding: "3rem",
                      // height:"25vh",
                      resize: "none",
                    }}
                    cols="20"
                    rows="8"
                    placeholder="Enter your description here"
                    className={`input ${
                      details?.description && "input-active"
                    }`}
                    value={details?.description}
                  />
                </div>
                {/* group box end */}
              </div>
              <div className="">
                {/* second wrap start */}
                {/* <div className="wrapper"></div> */}
                <div>
                  <div className="wrapper"></div>
                  <p className="box-title">2. Projection Format</p>
                </div>
                {/* form check bos start */}
                <div className="form-check-box" style={{ width: "60rem" }}>
                  {/* check group start */}

                  <div className="check-group" onChange={handleSpreadForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-spread-evenly"
                      value="spread"
                      checked={details?.projection_type === "spread"}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return {
                            ...prev,
                            projection_type: e.target.value,
                            unit_price: "",
                            units: "",
                          };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-spread-evenly"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Spread Evenly</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (share units and value accross all items)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                  {/* check group start */}
                  {/* <input type="radio"/> */}
                  <div className="check-group" onChange={handleMonthlyForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-monthly-entry-one"
                      value="monthly"
                      checked={details?.projection_type === "monthly"}
                      onChange={(e) => {
                        setDetails((prev) => {
                          setTotalQuatity("");
                          setTotalValue("");
                          return {
                            ...prev,
                            projection_type: e.target.value,
                            unit_price: "",
                            units: "",
                            entry_type: "",
                            monthly_breakdown: [
                              { name: { label: "" }, value: "" },
                            ],
                          };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-monthly-entry-one"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Monthly Entry</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (specify type and unit value for each item)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                </div>
                {/* spread-evenly form start */}
                {spreadForm && (
                  <EmployeeSpreadForm
                    onInputChange={handleChange}
                    unit_price={details?.unit_price}
                    units={details?.units}
                    total_value={spreadTotalVal || ""}
                    // total_value={formatNumber(String(spreadTotalVal || 0))}
                    // total_value={totalValue}
                  />
                )}
                {/* spread-evenly form end */}
                {monthlyForm && (
                  <EmployeeMonthlyForm
                    onInputChange={handleChange}
                    entry_type_value={details?.entry_type}
                    // unit_price={details?.unit_price}
                    unit_price={
                      details?.projection_type === "monthly" &&
                      details?.unit_price
                    }
                    units={details?.units}
                    list={details?.monthly_breakdown}
                    handleEntryTypeChange={(e) => {
                      // // console.log("check", e);
                      // // console.log(e.value);
                      e.value === 2
                        ? setChangeLabel((prev) => {
                            return {
                              ...prev,
                              inputLabel: "Amount",
                              totalLabel: "Total Quantity",
                            };
                          })
                        : setChangeLabel(
                            !changeLabel.inputLabel,
                            !changeLabel.totalLabel
                          );
                      setDetails({ ...details, entry_type: e });
                    }}
                    // entryType={details?.entry_type}
                    Quantity={changeLabel.inputLabel}
                    // total_quantity={details.units}
                    total_quantity={totalValue || ""}
                    total_value={totalQuantity || ""}
                    handleAddMonthList={handleAddToMonthList}
                    onSelectvalueChange={handleSelectOptionToMonthList}
                    onInputValueChange={handleInputOptionToMonthList}
                    handleRemoveMonthList={handleRemoveOptionMonthList}
                    Total_quantity_label={changeLabel?.totalLabel}
                    entryType={entryType}
                    total_value_label={
                      details?.entry_type?.label === "Amount" && "Total Amount"
                    }
                  />
                )}

                {/* form check bos end */}
              </div>

              <div className="emp-expenditure-shared-service-budget-wrap">
                {/* shared budget wrap start */}
                {/* {apportionData?.length > 0 && ( */}
                <div>
                  <div className="wrapper" />
                  <p className="shared-percent-title">
                    3. Set Sharing Percentage
                  </p>
                  {/* set sharing percentage note wrap start */}
                  <div>
                    <p>
                      Set cost sharing percentage or retain default shared
                      service settings.
                    </p>
                    <p
                      style={{
                        marginTop: ".5rem",
                        marginBottom: "2rem",
                        fontWeight: "400",
                      }}
                    >
                      Note: Percentage for all subsidiaries add up to 100%
                    </p>
                  </div>
                  {/* set sharing percentage note wrap end */}
                  <div className="hide-box">
                    <input
                      checked={isSharedBudget}
                      disabled={apportionData?.length > 0 ? false : true}
                      type="checkbox"
                      name=""
                      id="is_shared_toggle"
                      className="input"
                      onChange={() => {
                        setIsSharedBudget(!isSharedBudget);
                        setAllSelectedSubsidiary([]);
                        handleSubOptions();
                      }}
                    />
                    <label className="label" htmlFor="is_shared_toggle">
                      <span></span>
                      <span></span>
                    </label>
                  </div>
                </div>
                {/* )} */}
                {/* shared budget wrap end */}
                {/* shared budget form wrap start */}

                {isSharedBudget && (
                  <div className="shared-budget-form-wrap">
                    {/* new  design box start -------------------- */}
                    {/* select subsidiary start */}
                    <div className="shared-form-group">
                      <label className="">Select Subsidiary</label>
                      <Select
                        name="subsidiary_id"
                        id="subsidiary_name"
                        className={"select-wrap select-wrap-multi"}
                        placeholder="Select Subsidiary"
                        value={allSubisidiarySelected}
                        isMulti
                        // onSe

                        onChange={(e, objAction) => {
                          setAllSelectedSubsidiary(e);
                          const obj = {
                            reason: true,
                            id: objAction.option?.value,
                          };

                          const getDeptBySub = async (objVal) => {
                            // console.log(objVal);
                            // return
                            const data = await dispatch(
                              getDepartmentsBySubsidiary(objVal)
                            );
                            if (data?.payload?.success) {
                              const departMentss =
                                data?.payload?.data?.departments;
                              handleMultiSelectChange(
                                e,
                                objAction,
                                departMentss
                              );
                            }
                          };
                          objAction?.option && getDeptBySub(obj);
                        }}
                        options={subOption}
                        styles={reactSelectStyle}
                      />
                    </div>
                    {/* select subsidiary end */}
                    {/* selected main wrapper bix show -------- */}
                    <div className="selected-main-wrapper-box">
                      {allSubisidiarySelected?.map((chi, idx) => {
                        // console.log(allSubisidiarySelected, "all-selected");
                        const {
                          budget_percentage,
                          label,
                          departments,
                          selected_departments,
                        } = chi;
                        return (
                          <div className="selected-item-sub" key={idx}>
                            <p className="title">
                              <span>{label} Subsidiary</span> with budget
                              percentage of
                              <input
                                id="budget_percentage"
                                name="budget_percentage"
                                className="shared-form-input budget-input"
                                value={budget_percentage}
                                onChange={(e) => {
                                  handleNewBudgetPercentage(
                                    e?.target?.value <= 100
                                      ? e?.target?.value
                                      : budget_percentage,
                                    chi?.value
                                  );
                                }}
                              />
                            </p>
                            {/* selected box start --- */}
                            {/* form-group start */}
                            <div
                              className="shared-item-box"
                              style={{ margin: "1.5rem 0rem" }}
                            >
                              <label className="shared-budget-label">
                                Select Department<span>*</span>
                              </label>

                              <Select
                                isMulti
                                styles={reactSelectStyle}
                                isLoading={loadingPage}
                                className="select-wrap select-wrap-multi"
                                value={selected_departments}
                                options={handleFormatDepartmentDrop(
                                  departments
                                )}
                                onChange={(e, objAction) => {
                                  handleMultiSelectChangeTwo(
                                    e,
                                    objAction,
                                    departments,
                                    chi?.value
                                  );
                                }}
                                // isDisabled
                              />
                            </div>
                            {/* form-group end */}
                            {/* selected box ebd ----- */}
                            {/* depart and shared list start */}
                            {selected_departments?.length > 0 && (
                              <div className="depart-shared-list-box">
                                {selected_departments?.map((child, indx) => {
                                  const { shared_apportion } = child;
                                  return (
                                    <div
                                      key={indx}
                                      className="share-budget-form-box"
                                    >
                                      <p className="shared-budget-step">
                                        {indx + 1 || "1"}
                                      </p>
                                      {/* form-group start */}
                                      <div className="shared-budget-box ">
                                        <label className="shared-budget-label">
                                          Select Department<span>*</span>
                                        </label>
                                        <Select
                                          className="shared-select-wrap"
                                          value={{
                                            label: child?.name,
                                            value: child?.id,
                                          }}
                                          options={departmentDrop}
                                          // onChange={(e) => setSelectSharedDept(e, child)}
                                          isDisabled
                                        />
                                      </div>
                                      {/* form-group end */}
                                      {/* form group start */}
                                      <div
                                        // style={{
                                        //   display: "flex",
                                        //   flexDirection: "column",
                                        //   gap: ".2rem",
                                        // }}
                                        className="shared-budget-box"
                                      >
                                        <label className="shared-budget-label">
                                          Apportion Budget( % )<span>*</span>
                                        </label>

                                        <input
                                          className={`shared-form-input ${
                                            shared_apportion &&
                                            "shared-form-input-active"
                                          }`}
                                          // decimalScale={3}
                                          // decimalSeparator="."
                                          type="number"
                                          name="apportion_budget"
                                          // thousandsGroupStyle={"lakh"}
                                          // allowNegative
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            handleApportionDeptChange(
                                              chi?.value,
                                              child?.value,
                                              newValue <= 100
                                                ? newValue
                                                : shared_apportion
                                            );
                                          }}
                                          value={shared_apportion}
                                          id="apportion_budget"
                                          // disabled={disable}
                                          // placeholder="Specify Budget"
                                        />
                                      </div>
                                      {/* form group end */}
                                      <div
                                        className="cancel-icon-wrap"
                                        onClick={() => {
                                          // handleRemoveDeptBudget(child);
                                          handleDeleteChildFromAll(
                                            chi?.value,
                                            child?.value
                                          );
                                        }}
                                      >
                                        <FaMinus className="minus-icon" />
                                      </div>
                                      <br />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            {/* depart and shared list end */}
                          </div>
                        );
                      })}
                    </div>
                    {/* selected main wrapper bix show end -------- */}
                    {/* new  design box end -------------------- */}

                    {/* <div> */}

                    {/* sharing percentage form wrap end*/}
                    {/* <ButtonBlue text={"Submit"} /> */}
                    {/* </div> */}
                  </div>
                )}

                {/* shared budget form wrap end */}
              </div>
            </div>
            {/* second wrap end*/}
            <div className="wrapper"></div>
            {/* btn box start */}

            {/* button start */}
            <div style={{ display: "flex", gap: "3rem" }}>
              {!approveLoader ? (
                <input
                  onClick={() => {
                    handleSharedSum();
                    setDetails({
                      ...details,
                      is_submitted: 1,
                      newArray: allSubisidiarySelected,
                    });
                  }}
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Submit For Approval"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{ height: "5rem" }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="50"
                    width="100"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
              {draftLoader ? (
                <div>
                  <Dna
                    visible={true}
                    height="50"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <input
                  onClick={() => {
                    handleSharedSum();
                    setDetails({
                      ...details,
                      is_submitted: 0,
                      newArray: allSubisidiarySelected,
                    });
                  }}
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Save To Draft"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  // style={{ height: "5rem" }}
                  style={{
                    height: "5rem",
                    background: "#ffff",
                    color: !enableSubmit() ? "#cccc" : "var(--blue-color)",
                    border: !enableSubmit()
                      ? ".1rem solid #cccc"
                      : ".1rem solid var(--blue-color)",
                  }}
                />
              )}
              {/* button end */}
              {/* button start */}
            </div>
            {/* btn box end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
      {/* modal start */}
      <Modal effect="fadeInUp" visible={showModal}>
        <EmployeeBulkModal onCancel={() => setShowModal(false)} />
      </Modal>

      {/* modal end */}
    </div>
  );
};

export default EmployeeNewExpenditureProjection;
