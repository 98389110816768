import React, { useState, useEffect, useContext } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import ReUseableTable from "../../../../tables/ReUseableTable";
import Modal from "react-awesome-modal";
import SharedModal from "./SharedModal";
import ButtonBlue from "../../../../buttons/ButtonBlue";
// import { useDispatch } from "react-redux";
// import { getSubsidiaries } from "../../../../../redux/subsidiary/subsidiary";
import ActionContext from "../../../../../context/ActionContext";

const Subsidiary = () => {
  // const dispatch = useDispatch()
  // const [singleChild, setSingleChild] = useState({})
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const tableHeadList = ["Name", "Country", "Address", "Parent Company"];
  const tableBodyList = [
    {
      name: "IT Horizon",
      country: "Nigeria",
      address: "9B, Akin-Ogunmade Davies...",
      parent: "9B, Akin-Ogunmade Davies...",
    },
    {
      name: "IT Horizon",
      country: "Nigeria",
      address: "9B, Akin-Ogunmade Davies...",
      parent: "9B, Akin-Ogunmade Davies...",
    },
  ];

   const actionCtx = useContext(ActionContext)
   useEffect(() => {
    // dispatch(getSubsidiaries())
    if(actionCtx?.activeOrg?.type === "single"){
      history.push("/dashboard-administration-admin-settings");
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])


  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitleSpan={`Step 2 of 6`}
        pageTitle={`Add Subsidiary`}
      > */}
        <div className="admin-org-settings-wrap">
          <div className="subtitle-box">
            <p
              onClick={() =>
                history.push("/dashboard-administration-admin-settings")
              }
              className="text"
            >
              Organization
            </p>
            <FaAngleRight className="icon" />
            <p
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-subsidaries"
                )
              }
              className="active text"
            >
              Subsidiary
            </p>
            {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
          </div>

          {/* table box start */}
          <ReUseableTable
            table_title={`Subsidiary`}
            table_head_list={tableHeadList}
            table_title_span={`(1)`}
            table_body_list={tableBodyList}
            btn_text="subsidiary"
          />
          {/* table box end */}

          {/* note box start */}
          <div className="note-wrap">
            <div className="note-img-wrap">
              Important Notice:
              <figure className="img-box">
                <img src={noteIcon} alt="" className="img" />
              </figure>
            </div>
            <div className="note-box">
              <p>
                1. For organizations with shared budgets, you would need to have
                created at least two subsidiaries to set up a shared services
              </p>
            </div>
          </div>
          {/* note box end */}
          {/* btn box start */}
          <div className="btn-box-wrap">
            <button
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-admin-invite"
                )
              }
              className="btn-reuse"
            >
              Go to previous step
            </button>
            <button
              onClick={() => setShowModal(true)}
              className="btn-reuse btn-two"
            >
              Go to the next step
            </button>
          </div>
          {/* btn box end */}
        </div>
      {/* </DashboardLayout> */}

      {/* modal start  */}
      <Modal visible={showModal} effect="fadeInUp">
        <SharedModal onCancel={() => setShowModal(false)}>
          <div className="btn-box">
            <button className="btn-white">Go to the next step</button>
            <ButtonBlue
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-add-subsidary-shared-service"
                )
              }
              text={`Setup Shared Service`}
            />
          </div>
        </SharedModal>
      </Modal>
      {/* modal end */}
    </>
  );
};

export default Subsidiary;
