import React from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/admin-settings/CustomizationStyles.css";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";

const CommonCustomizationScreen = ({ children, title, btnShow, onClick, border }) => {
  return (
    <>
      {/* <DashboardLayout pageTitle={`Customization`} goBack> */}
        <div className="customization-wrap">
          <div className="left-box">
            <p className="dashboard">Dashboard</p>
            <p className="title">Email Template</p>
          </div>
          {/* end of left box */}
          <div className="right-box">
            <div className="title-btn-box" style={{borderBottom: border}}>
              <p className="title">{title || "Email Template"}</p>
              {btnShow && (
                <ButtonBlue text={`New template`} onClick={onClick} >
                  <FaPlus style={{ marginRight: ".6rem" }} />
                </ButtonBlue>
              )}
            </div>
            {children}
          </div>

          {/* end of right box */}
        </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default CommonCustomizationScreen;
