import AllUsers from "../../../../component/pages/dashboard/home/allUsers";
import SingleApprovalPage from "../../../../component/pages/dashboard/home/SingleApprovalPage";

export const Dashboard_home_all_users_route_group = [
  {
    path: "/dashboard-home-all-users",
    component: AllUsers,
    pageTitle: "Dashboard",
    goBack: true,
  },
  {
    path: "/dashboard-single-approve",
    component: SingleApprovalPage,
    pageTitle: "",
  }
];
