import React from "react";
import { useHistory } from "react-router-dom";
// import ReportModal from "./reportModal";
// import EmployeeReportModal from "../external/support/modal/reportModal";
import { useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
// import TawkTo from 'tawkto-react'

const SupportDropDown = () => {
  const history = useHistory();
  const tawkMessengerRef = useRef();



  return (
    <div>
      {/* employee support drop down start */}
      {/* 
      {reportModal && (
        <div className="blur-bg-wrap">
          <ReportModal />
        </div>
      )} */}

      <p
        onClick={() => history.push("/dashboard-administration-support")}
        style={{ marginTop: "3rem" }}
      >
        Help Center
      </p>
      {/* <p onClick={() => setReportModal(true)}>Report an Issue</p> */}
      <p>Report an Issue</p>
      {/* <p onClick={handleMinimize}>Chat with a support</p> */}
      <div className="emp-arrow-box"></div>

      {/* employee support drop down end */}

      <>
        <TawkMessengerReact
          propertyId="637b5d89daff0e1306d89234"
          widgetId="1gicuapa3"
          ref={tawkMessengerRef}
          // style={{backgroundColor: "red"}}
        />
      </>
    </div>
  );
};
export default SupportDropDown;


// <!--Start of Tawk.to Script-->
// <script type="text/javascript">
// var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
// (function(){
// var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// s1.async=true;
// s1.src='https://embed.tawk.to/637b5d89daff0e1306d89234/1gicuapa3';
// s1.charset='UTF-8';
// s1.setAttribute('crossorigin','*');
// s0.parentNode.insertBefore(s1,s0);
// })();
// </script>
// <!--End of Tawk.to Script-->