import EmployeeSettingsPreferenceIndex from "../../../../component/pages/dashboard/external/settings/preference";
import EmployeeSettingsIndex from "../../../../component/pages/dashboard/external/settings/profile";

export const Employee_dashboard_settings_administration_route_group = [
  {
    path: "/employee-dashboard-administration-admin-settings",
    component: EmployeeSettingsIndex,
    pageTitle: "Settings",
    goBack: true,
  },
  {
    path: "/employee-dashboard-administration-admin-settings-preference",
    component: EmployeeSettingsPreferenceIndex,
    pageTitle: "Settings",
    goBack: true,
  },
];
