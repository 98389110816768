import React from "react";
import successGif from "../../../../../assets/transfer-profile-success.gif";
import "../../../../../styles/dashboard/admin-settings/ProfileTransfer/ProfileTransfer.css";
import ButtonBlue from "../../../../buttons/ButtonBlue";

const SuccessTransferModal = ({ onCancel }) => {
  return (
    <div className="success-transfer-profile-modal-wrap">
      <figure className="img-box">
        <img src={successGif} alt="" className="img" />
      </figure>
      <p className="title">Profile Transfer Successful</p>
      <p className="text">
        Profile authorization has been granted to New User to perform allocated
        task in role
      </p>
      <ButtonBlue onClick={onCancel} text={`Close`} bgColor={`#FFEFEB`} />
    </div>
  );
};

export default SuccessTransferModal;
