import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct } from "../../../../../../redux/budget-setting/revenue-projection/product";
import CommonDeleteModal from "./CommonDeleteModal";

const DeleteBudgetRevProduct = ({ onClose, onFinish, detail }) => {
  const { loading } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    const id = detail?.id;
    const data = await dispatch(deleteProduct(id));
    if (data?.payload?.success) {
      onFinish();
    }
  };
  return (
    <>
      <CommonDeleteModal
        loading={loading}
        onDelete={handleDelete}
        title={`Delete Products (${detail?.name})`}
        text={`Are you sure you want to delete this information. Deleting this, would affect your 
organizatiuon budget settings. Do you still want to delete?`}
        onClose={onClose}
      />
    </>
  );
};

export default DeleteBudgetRevProduct;
