import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc, customErrorId } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getDepartments = createAsyncThunk(
  "admin/get-departments",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/departments/organization/${formData}`
      );
      // console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setDepartments(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const departmentsInfo = data.data.departments;
        await thunkAPI.dispatch(setDepartmentDrop(departmentsInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getDepartmentsBySubsidiary = createAsyncThunk(
  "admin/get-departments",
  async (formData, thunkAPI) => {
    try {
      let url = "";
      if (formData?.reason) {
        url = `employee/departments/subsidiary/${formData?.id}`;
      } else {
        url = `admin/departments/subsidiary/${formData}`;
      }
      const { data } = await axios.get(url);
      // console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setDepartments(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const departmentsInfo = data.data.departments;
        await thunkAPI.dispatch(setDepartmentDrop(departmentsInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getDepartmentBudgetReps = createAsyncThunk(
  "admin/get-department-budget-reps",
  async (formData, thunkAPI) => {
    try {
      let url = "";
      if (formData?.reason) {
        url = `employee/get-department-budget-reps/${formData?.id}`;
      } else {
        url = `admin/get-department-budget-reps?department_id=${formData}`;
      }
      const { data } = await axios.get(url);
      console.log("branch", data);

      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setDepartments(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const deptBudgetRep = data.data;
        await thunkAPI.dispatch(setDepartmentBudgetRep(deptBudgetRep));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

// departments/subsidiary/

export const getDepartmentsWithPagination = createAsyncThunk(
  "admin/get-departments-with-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/departments/organization/${
          formData?.org_id
        }?paginated=true&perPage=${formData?.per_page || 5}`
      );
      // console.log("branch", data.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setDepartments(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const departmentInfo = data.data.departments;
        await thunkAPI.dispatch(setDepartments(departmentInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getDepartmentsByFilter = createAsyncThunk(
  "admin/get-departments-filter",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `admin/departments/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setDepartments(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const departmentInfo = data.data.departments;
        await thunkAPI.dispatch(setDepartmentFilter(departmentInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getDepartmentsPagination = createAsyncThunk(
  "admin/get-departments-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        // await thunkAPI.dispatch(setDepartments(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const departmentInfo = data.data.departments;
        await thunkAPI.dispatch(setDepartments(departmentInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getDepartment = createAsyncThunk(
  "admin/get-department",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/departments/${formData}`);
      // console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createDepartment = createAsyncThunk(
  "admin/create-department",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/departments", formData);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "admin/delete-department",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/departments/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

// departments/subsidiary/

export const updateDepartment = createAsyncThunk(
  "admin/update-department",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/departments/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const department = createSlice({
  name: "department",
  initialState: {
    departmentDrop: [],
    departmentBudgetRep: [],
    departments: [],
    links: {},
    meta: {},
    department: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setDepartments: (state, action) => {
      state.isAuth = true;
      state.departments = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setDepartmentFilter: (state, action) => {
      state.isAuth = true;
      state.departments = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setDepartmentDrop: (state, action) => {
      state.isAuth = true;
      state.departmentDrop = addLabelValueFunc(action.payload);
    },
    setDepartmentBudgetRep: (state, action) => {
      state.isAuth = true;
      state.departmentBudgetRep = action.payload;
    },
    setDepartment: (state, action) => {
      state.isAuth = true;
      state.department = action.payload;
    },
  },
  extraReducers: {
    [createDepartment.pending]: (state) => {
      state.loading = true;
    },
    [createDepartment.fulfilled]: (state) => {
      state.loading = false;
    },
    [createDepartment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateDepartment.pending]: (state) => {
      state.loading = true;
    },
    [updateDepartment.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateDepartment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getDepartmentsBySubsidiary.pending]: (state) => {
      state.loadingPage = true;
    },
    [getDepartmentsBySubsidiary.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getDepartmentsBySubsidiary.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    // [getDepartments.pending]: (state) => {
    //   state.loading = true;
    // },
    // [getDepartments.fulfilled]: (state) => {
    //   state.loading = false;
    // },
    // [getDepartments.rejected]: (state) => {
    //   // localStorage.removeItem('token');
    //   state.loading = false;
    //   state.isAuth = false;
    //   state = null;
    // },
    [getDepartmentsPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getDepartmentsPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getDepartmentsPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getDepartmentsByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getDepartmentsByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getDepartmentsByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getDepartmentBudgetReps.pending]: (state) => {
      state.loadingPage = true;
    },
    [getDepartmentBudgetReps.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getDepartmentBudgetReps.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getDepartmentsWithPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getDepartmentsWithPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getDepartmentsWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteDepartment.pending]: (state) => {
      state.loading = true;
    },
    [deleteDepartment.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteDepartment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDepartment,
  setDepartments,
  setDepartmentDrop,
  setDepartmentFilter,
  setDepartmentBudgetRep,
} = department.actions;

export default department.reducer;
