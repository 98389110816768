import React from "react";
import "../../../../styles/dashboard/admin-settings/ApprovalSettingModal.css";
import giftImg from "../../../../assets/gift-img.svg";
import "../../../styles/auth/employee-success-message.scss"


const EmployeeSuccessModal = ({ children }) => {
  return (
    <div style={{width: "60rem"}} className="approval-setting-modal-wrap emp-success-msg ">
        {/* no comment 😐 */}
            <figure>
          <img src={giftImg} alt="" />
        </figure>
        <span className="employee-congratulations">Congratulations !</span>
        <br />
        <span>You have successfully activated your account.</span>
        <span>Click on the button below to login to your dashboard</span>
      {children}
    </div>
  );
};
export default EmployeeSuccessModal;
