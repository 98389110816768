import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
import CommonExpenseLayout from "./CommonLayout";
import Select from "react-select";
// import ExpenseChart from "./RevenueChart";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActionContext from "../../../../../context/ActionContext";
import RevenueChart from "./RevenueChart";
// import userImg from "../../../../../assets/hod-one-img.svg";
import RevenueChartOne from "./RevenueChartOne";
import { useHistory } from "react-router-dom";
import {
  checkCurrencyFormat,
  formatNumberWithComma,
  // FormatNumWithCurrency,
  getChartStructureGross,
  getChartStructures,
  perPageOptions,
  reactSelectStyleTable,
} from "../../../../../helper/Helpers";
import {
  getRevenueProjectionCharts,
  getRevenueProjectionChartsPagination,
} from "../../../../../redux/projections/RevenueProjection";
import { useDispatch, useSelector } from "react-redux";

const RevenueProjectionIndex = ({ children, chartBox }) => {
  const actionCtx = useContext(ActionContext);
  const { loading, revenue_projections, loadingPage } = useSelector(
    (state) => state.revenue_projection_chart
  );
  const history = useHistory();
  //   const [showChart, setShowChart] = useState(true);
  const [indexNo, setIndexNo] = useState("");
  const dispatch = useDispatch();
  const brandcolor = localStorage.getItem("brand_color");
  const [dataChartValueGross, setDataChartValueGross] = useState({
    labels: "",
    datasets: [
      {
        label: "Gross Profit",
        data: [],
        backgroundColor: brandcolor,
      },
    ],
  });
  const [dataChartValueGrossVer, setDataChartValueGrossVer] = useState({
    labels: "",
    datasets: [
      {
        label: "Gross Profit",
        data: [],
        backgroundColor: brandcolor,
      },
    ],
  });
  const [dataChartValue, setDataChartValue] = useState({
    labels: "",
    datasets: [
      {
        label: "Approved",
        data: [],
        backgroundColor: "#07A287",
      },
      {
        label: "Pending",
        data: [],
        backgroundColor: "#FCA549",
      },
      {
        label: "Rejected",
        data: [],
        backgroundColor: "#CC0905",
      },
    ],
  });
  //   const [showChartBox, setShowChartBox] = useState(true);

  const tableHeadList = [
    "Subsidiary",
    "Department",
    "User",
    `Total Revenue ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    `Total GP ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    "Status",
    "Action",
  ];

  const vertProSelect = [
    { label: "Product", value: "product" },
    { label: "Vertical", value: "vertical" },
  ];

  const [verProVal, setVerProVal] = useState({
    label: "Product",
    value: "product",
  });

  useEffect(() => {
    getChartValueHere();
    // eslint-disable-next-line
  }, [actionCtx?.activeOrg, actionCtx?.currencyValue, actionCtx?.perPage]);

  const getChartValueHere = async () => {
    // console.log("yes");
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    // console.log(obj);
    // return
    const data = await dispatch(getRevenueProjectionCharts(obj));
    // console.log(data);
    if (data?.payload?.success) {
      const list = getChartStructures(data?.payload?.data?.graph_data);
      const listGross = getChartStructureGross(
        data?.payload?.data?.gross_per_product
      );
      const listGrossVer = getChartStructureGross(
        data?.payload?.data?.gross_per_vertical
      );
      setDataChartValue(list);
      setDataChartValueGross(listGross);
      setDataChartValueGrossVer(listGrossVer);
    }
  };

  const handlePagination = (url) => {
    const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getRevenueProjectionChartsPagination(obj));
  };

  const handlePaginationNum = (num) => {
    // const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getRevenueProjectionChartsPagination(obj));
  };

  return (
    <>
      <CommonExpenseLayout
        setDataChartValue={setDataChartValue}
        setDataChartValueGross={setDataChartValueGross}
        setDataChartValueGrossVer={setDataChartValueGrossVer}
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Revenue Projection Graph</p>
              {/* <Select
                // isSearchable={true}
                // isMulti
                placeholder="Sort By"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              /> */}
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <RevenueChart
                loading={loading || loadingPage}
                dataToShow={dataChartValue}
              />
            </div>
            {/* chart end */}
          </div>
        )}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div
              // onClick={() => {
              //   console.log(revenue_projections);
              // }}
              className="title-select-box"
            >
              <p className="title">{`Gross Profit Per ${verProVal?.label}`}</p>
              <Select
                styles={reactSelectStyleTable}
                placeholder="Sort By"
                className="select"
                value={verProVal}
                onChange={(e) => {
                  setVerProVal(e);
                }}
                options={vertProSelect}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <RevenueChartOne
                loading={loading || loadingPage}
                dataToShow={
                  verProVal?.value === "product"
                    ? dataChartValueGross
                    : dataChartValueGrossVer
                }
              />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* perpage box start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              position: "relative",
              zIndex: "20",
              marginBottom: "-1rem",
            }}
            className="perpage-wrap"
          >
            <span style={{ fontWeight: "600" }}> All Revenue Projection</span>{" "}
            <Select
              styles={reactSelectStyleTable}
              onChange={(e) => {
                actionCtx?.setPerpage(e);
              }}
              value={actionCtx?.perPage}
              options={perPageOptions}
            />
          </div>
          {/* perpage box end */}
          {/* table box start */}
          <ActualReuseableTable
            loading={
              loading &&
              revenue_projections?.length < 1
                ? true
                : loadingPage &&
                  revenue_projections?.length < 1
                ? true
                : false
            }
            loadingNum={7}
            useNumPagination
            currentPage={
              revenue_projections?.departments_projections?.current_page
            }
            totalTableLength={
              revenue_projections?.departments_projections?.total
            }
            onNumPage={(e) => {
              handlePaginationNum(e);
            }}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            six
            five={tableHeadList[4]}
            // six={tableHeadList[5]}
            // action
            onNextPage={() =>
              handlePagination(
                revenue_projections?.departments_projections?.next_page_url
              )
            }
            toPage={revenue_projections?.departments_projections?.to}
            totalPage={revenue_projections?.departments_projections?.total}
            fromPage={revenue_projections?.departments_projections?.from}
            nextPage={
              revenue_projections?.departments_projections?.next_page_url
            }
            prevPage={
              revenue_projections?.departments_projections?.prev_page_url
            }
            onPrevPage={() => {
              handlePagination(
                revenue_projections?.departments_projections?.prev_page_url
              );
            }}
          >
            {revenue_projections?.departments_projections?.data?.map(
              (chi, idx) => {
                const {
                  subsidiary,
                  department,
                  user,
                  total_revenue,
                  total_gp,
                } = chi;
                return (
                  <TableBodyRow
                    key={idx}
                    one={subsidiary?.name}
                    two={department?.name}
                    user={{ img: user?.profile_picture, name: user?.full_name }}
                    five={formatNumberWithComma(total_revenue || "0")}
                    six={formatNumberWithComma(String(total_gp || 0)) || "0"}
                    loading={loading || loadingPage}
                    action
                    rowNo={idx}
                    indexNo={indexNo}
                    setIndexNo={() => setIndexNo(idx)}
                    updateText={` Update Branch`}
                    onNextPage={() => {
                      // console.log(chi);
                      localStorage.setItem(
                        "revenue-pro-chart-id",
                        department?.id
                      );
                      localStorage.setItem(
                        "revenue-pro-chart-user-id",
                        user?.id
                      );
                      history.push("/dashboard-projection-revenue-main");
                    }}
                    //   onUpdate={() =>
                    //     history.push(
                    //       "/dashboard-administration-admin-settings-branch-update"
                    //     )
                    //   }
                    // onNextPage={() =>
                    //   history.push("/dashboard-projection-revenue-main")
                    // }
                  />
                );
              }
            )}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </CommonExpenseLayout>
    </>
  );
};

export default RevenueProjectionIndex;
