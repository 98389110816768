import { configureStore } from "@reduxjs/toolkit";
import user from "./user";
import subsidiary from "./subsidiary/subsidiary";
import branch from "./branch/branch";
import department from "./department/deparment";
import users from "./users/users";
import role from "./role/role";
import preference from "./preference/preference";
import classes from "./budget-setting/expenditure-projection/class";
import categories from "./budget-setting/expenditure-projection/categories";
import chatOfAccount from "./budget-setting/expenditure-projection/chartOfAccount";
import vertical from "./budget-setting/revenue-projection/vertical";
import product from "./budget-setting/revenue-projection/product";
import shared_service from "./shared-service/shareService";
import revenue_projection from "./employee/revenue-projection/revenue-projection";
import employee_accept_invite from "./employee/activate-account/process-invite";
import fiscal_year from "./fiscal-year/fiscalYear";
import expense_limit from "./budget-setting/expense-limit/expenseLimit";
import expenditure_projection from "./employee/expenditure-projection/expenditure-projection";
import currency from "./currency/currency";
import group from "./group/group";
import budgetAssumptions from "./budget-setting/budget-assumption/budgetAssumptions";
import actual_revenue from "./employee/actual-revenue/actual-revenue";
import actual_expense_employee from "./employee/actual-expense/index";
import revenue_projection_report from "./employee/report/revenue-projection-report";
import expense_projection_report from "./employee/report/expense-projection-report";
import actual_supplementary_budget from "./employee/actual-supplementary-budget/actual-supplementary-budget";
import general_report from "./employee/report/general-report/index";
import approvals from "./approvals/index";
import budget_performance_report from "./employee/report/budget-performance";
import budget_variance_report from "./employee/report/budget-variance-report";
import revenue_variance_report from "./employee/report/revenue-variance-report";
import recurrent_and_capital_report from "./employee/report/recurrent-and-capital-budget";
import actual_revenue_report from "./employee/report/actual-revenue-report";
import actual_expense_report from "./employee/report/actual-expense-report";
import recurrent_and_capital_expense_report from "./employee/report/recurrent-and-capital-expense";
import projection_actual_gp_report from "./employee/report/projection-vs-actual-gp";
import projection_actual_revenue_gp_report from "./employee/report/projection-actual-rev-and-gp";
import expense_gp_projection_report from "./employee/report/expense-vs-gp-projection";
import cash_flow_report from "./employee/report/cash-flow";
import consolidated_budget_report from "./employee/report/consolidated-budget";
import revenue_performance_report from "./employee/report/revenue-performance-report";
import custom_report from "./employee/report/custom-report";
import notifications from "./notifications/notifications";
// admin report
import admin_consolidated_budget_report from "./reports/consolidated-budget";
import admin_revenue_projection_report from "./reports/revenue-projection";
import admin_expense_projection_report from "./reports/expense-projection";
import admin_budget_performance_report from "./reports/budget_performance";
import admin_revenue_performance_report from "./reports/revenue-performance";
import admin_budget_variance_report from "./reports/budget-variance";
import admin_revenue_variance_report from "./reports/revenue-variance";
import admin_recurrent_budget_report from "./reports/recurrent-and-capital-budget";
import admin_actual_income_report from "./reports/actual-income";
import admin_actual_expense_report from "./reports/actual-expense";
import admin_recurrent_expense_report from "./reports/recurrent-and-capital-expense";
import admin_projection_vs_actual_gp_report from "./reports/projection-vs-actual-gp";
import admin_projectio_actual_income_report from "./reports/projection-actual-income";
import admin_expense_projection_actual_report from "./reports/expense-projection-vs-actual";
import admin_cashflow_report from "./reports/cashflow-report";

import expenditure_projection_chart from "./projections/ExpenditureProjection";
import revenue_projection_chart from "./projections/RevenueProjection";
import actual_expense_chart from "./actuals/expense";
import actual_revenue_chart from "./actuals/revenue";
import suplementary_budget_chart from "./actuals/suplementaryBudget";
import employee_dashboard from "./employee/dashboard";
import employee_preference from "./employee/preference/preference";
import portfolio from "./budget-setting/revenue-projection/portfolio";
import subscription from "./subscription/subscription";
import integration from "./integration/integration";
import verify_approval_flow from "./employee/approval-flow";

const initialState = {};
const store = configureStore({
  reducer: {
    user,
    users,
    subsidiary,
    branch,
    department,
    role,
    preference,
    classes,
    categories,
    chatOfAccount,
    vertical,
    product,
    shared_service,
    revenue_projection,
    employee_accept_invite,
    fiscal_year,
    expense_limit,
    expenditure_projection,
    currency,
    group,
    budgetAssumptions,
    actual_expense_employee,
    actual_revenue,
    actual_supplementary_budget,
    revenue_performance_report,
    revenue_projection_report,
    expense_projection_report,
    general_report,
    approvals,
    budget_performance_report,
    budget_variance_report,
    revenue_variance_report,
    recurrent_and_capital_report,
    actual_revenue_report,
    actual_expense_report,
    recurrent_and_capital_expense_report,
    projection_actual_gp_report,
    projection_actual_revenue_gp_report,
    expense_gp_projection_report,
    cash_flow_report,
    consolidated_budget_report,
    custom_report,  
    admin_consolidated_budget_report,
    admin_revenue_projection_report,
    admin_expense_projection_report,
    admin_budget_performance_report,
    admin_revenue_performance_report,
    admin_budget_variance_report,
    admin_revenue_variance_report,
    admin_recurrent_budget_report,
    admin_actual_income_report,
    admin_actual_expense_report,
    admin_recurrent_expense_report,
    admin_projection_vs_actual_gp_report,
    admin_projectio_actual_income_report,
    admin_expense_projection_actual_report,
    admin_cashflow_report,
    expenditure_projection_chart,
    revenue_projection_chart,
    actual_expense_chart,
    actual_revenue_chart,
    suplementary_budget_chart,
    employee_dashboard,
    employee_preference,
    notifications,
    portfolio,
    subscription,
    integration,
    verify_approval_flow,
  },
  initialState,
  devTools: true,
});

export default store;
