import React, { useEffect, useState } from "react";
// import EmployeeDashboardLayout from "../../../layout/dashboard/EmpDashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import "../../../../../employee-folder/styles/dashboard/home/home.scss";
import "../../../../../employee-folder/styles/dashboard/home/chart/LineChart.scss";
import EmployeeHomeSortBox from "./sortbox";
import EmployeeNetProfitChart from "./chart/netProfitChart";
import "../../../../../employee-folder/styles/dashboard/home/chart/reinburstment.scss";
// import Select from "react-select";
import { FaAngleDown, FaAngleUp, FaTimes, loading } from "react-icons/fa";
import EmployeeHomeChatTwo from "./chart/secondChart";
import EmployeeHomeChartThree from "./chart/thirdChart";
import { useHistory } from "react-router-dom";
import EmployeeAdvancedSearchModal from "./modals/advanceSearch";
import Modal from "react-awesome-modal";
// import ButtonBlue from "../../../../../component/buttons/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeDashboard } from "../../../../../redux/employee/dashboard";
import {
  formatNumber,
  // FormatNumWithCurrency,
} from "../../../../../helper/Helpers";
import { getUserDepartment } from "../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { getFiscalYears } from "../../../../../redux/fiscal-year/fiscalYear";
import { useContext } from "react";
import ActionContext from "../../../../../context/ActionContext";
import ButtonBlue from "../../../../../component/buttons/ButtonBlue";
import BudgetAssmptionModal from "../../../../../component/pages/dashboard/modal/budgetAssumption";
import BigShowModalComponent from "../../../../../component/pages/dashboard/modal/BigShowModalComponent";
import MultiProgress from "react-multi-progress";
import {
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../redux/department/deparment";
// import moment from "moment";

const EmployeeDashboardHome = ({ children }) => {
  const [showModal, setShowModal] = useState({ search: false });
  const [showChart, setShowChart] = useState({
    chart: true,
    arrowUp: true,
    arrowDown: false,
    chartTwo: true,
    arrowUpTwo: true,
    arrowDownTwo: false,
  });
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [showCurrentWrap, setShowCurrentWrap] = useState(true);
  const [showBigModal, setShowBigModal] = useState({
    url: "",
    show: false,
  });

  const history = useHistory();

  const range = [
    { label: "Today", value: "today" },
    { label: "2 Weeks", value: "2 weeks" },
    { label: "1 Month", value: "4 weeks" },
    { label: "3 Months", value: "3 months" },
    { label: "6 Months", value: "6 months" },
    { label: "1 year", value: "1 year" },
  ];

  // const optionList = [
  //   {
  //     label: "Default",
  //     value: 1,
  //   },
  //   {
  //     label: "Current Fiscal Year",
  //     value: 2,
  //   },
  //   {
  //     label: "Previous Year",
  //     value: 3,
  //   },
  //   {
  //     label: "Today",
  //     value: 4,
  //   },
  //   {
  //     label: "Yesterday",
  //     value: 5,
  //   },
  //   {
  //     label: "Last 7 days",
  //     value: 6,
  //   },
  //   {
  //     label: "Monthly",
  //     value: 7,
  //   },
  // ];

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.user);
  const { loading, dashboardDatas } = useSelector(
    (state) => state.employee_dashboard
  );
  const { departmentDrop } = useSelector((state) => state.department);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);

  const actionCtx = useContext(ActionContext);

  // console.log(expenseItem, "expenseItem")

  const getPercentage = (valOne, valTwo) => {
    const percentage = (valOne / (valOne + valTwo)) * 100;
    // console.log(Math.round(percentage));
    return Math.round(percentage);
  };

  // to get expense item chart
  const [values, setValues] = useState({});
  const [dataLabel, setDataLabel] = useState({});
  const [reimburseValues, setReimburseValues] = useState({});
  const [reimburseAwait, setReimburseAwait] = useState({});
  // const [reimburseLabel, setReimburseLabel] = useState({});
  const [netProfitValues, setNetProfitValues] = useState({});
  // const [netProfitText, setNetProfitText] = useState({});
  const [actualProfitValues, setActualProfitvalues] = useState({});
  const [grossProfitActualValues, setGrossProfitActualValues] = useState([]);
  const [grossProfitProjectValues, setGrossProfitProjectValues] = useState([]);
  const [userDrop, setUserDrop] = useState({});
  const [singleUserDrop, setSingleUserDrop] = useState({});
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  // const { dashboard_details, loading } = useSelector((state) => state.users);
  // const [date, setDate] = useState({ label: "Enter Date" });

  const [details, setDetails] = useState({
    department_id: "",
    currency_conversion_id: "",
    fiscal_year_id: "",
    user: "",
    period: "",
    subsidiary_id: "",
  });
  const [departmentOption, setDepartmentOption] = useState([]);

  useEffect(() => {
    const obj = {
      id: user?.organizations[0]?.id,
      reason: true,
    };
    dispatch(getFiscalYears(obj));
    dispatch(getDepartments(user?.organizations[0]?.id));
    // dispatch(getUserDepartment(user?.organizations[0]?.id));
    handleGetDept();
    if (
      user?.organizations[0]?.id &&
      (userDepartmentDrop[0]?.id || details?.department_id?.id)

      // actionCtx?.currencyValue?.id
    ) {
      handleGetEmpDashboardValues();
    }

    //eslint-disable-next-line
  }, [
    //eslint-disable-next-line
    user?.organizations[0]?.id,
    //eslint-disable-next-line
    userDepartmentDrop[0]?.id,
    details,
    actionCtx?.currencyValue?.id,
  ]);
  // console.log("user", details?.department_id?.id);
  // console.log(details?.user_id, "users");

  //handle get department and user
  const handleGetDept = async () => {
    const data = await dispatch(getUserDepartment(user?.organizations[0]?.id));
    if (data?.payload?.success) {
      const userDropVal = details?.department_id?.users?.map((chi) => {
        return {
          label: chi?.full_name,
          value: chi?.id,
        };
      });
      const userSingleDropVal = userDepartmentDrop[0]?.users?.map((chi) => {
        return {
          label: chi?.full_name,
          value: chi?.id,
        };
      });
      // console.log(userSingleDropVal, "user single drop value");
      // setSingleDept(deptData);
      setSingleUserDrop(userSingleDropVal);
      setUserDrop(userDropVal);
    }
  };

  const handleGetEmpDashboardValues = async () => {
    // console.log(user?.organizations[0]?.fiscal_year[0]?.currency_conversion_id, "uesr");
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id || userDepartmentDrop[0]?.id,
      // dept_id: details?.department_id?.id || userDepartmentDrop[0]?.id,
      fiscal_year_id: details?.fiscal_year_id?.id,
      user_id: details?.user_id?.value,
      period: details?.period?.value,
      currency_id: actionCtx?.currencyValue?.id,
      // date: date?.value,
    };
    const data = await dispatch(getEmployeeDashboard(obj));
    // console.log(data, "data-net-profit");
    if (data?.payload?.success) {
      let expenseValue = [];
      let expenseLabel = [];
      let ProjectedNetProfitValue = [];
      let netProfitLabel = [];
      let actualNetProfitValue = [];
      let grossProfitActualVal = [];
      let grossProfitProjectedVal = [];
      let reimbuerseVal = [];
      let reimburseAwait = [];

      // console.log(data, "data");
      const expenseItems = data?.payload?.data?.expense_item;
      const netProfitProjectedItems =
        data?.payload?.data?.net_profit?.projected_profit_data;
      const grossProfitItems =
        data?.payload?.data?.gross_profit?.gross_profit_data;
      const reimburseData = data?.payload?.data?.actual_awaiting_reimbursment;
      // console.log(reimburseData, "reimburseData");
      // console.log(reimburseData, "reimbursedata");

      for (const dataObj of expenseItems) {
        expenseValue.push(parseInt(dataObj?.Amount));
        expenseLabel.push(String(dataObj?.Item));
      }

      for (const netProjectedObj of netProfitProjectedItems) {
        ProjectedNetProfitValue.push(
          parseInt(netProjectedObj?.value?.projected)
        );
      }
      // month label
      for (const netProfitLabelObj of netProfitProjectedItems) {
        netProfitLabel.push(String(netProfitLabelObj?.month));
      }
      for (const netActualObj of netProfitProjectedItems) {
        actualNetProfitValue.push(parseInt(netActualObj?.value?.actual));
      }
      for (const grossProfitObj of grossProfitItems) {
        grossProfitActualVal.push(parseInt(grossProfitObj?.value?.actual));
      }
      for (const grossProfitObj of grossProfitItems) {
        grossProfitProjectedVal.push(
          parseInt(grossProfitObj?.value?.projected)
        );
      }
      for (const reimbuerseObj of reimburseData) {
        reimbuerseVal.push(parseInt(reimbuerseObj?.value?.actual));
      }
      for (const reimbuerseObj of reimburseData) {
        reimburseAwait.push(parseInt(reimbuerseObj?.value?.awaiting));
      }
      // console.log(reimburseAwait, "sdbfk");
      // setReimburseValues(reimburseData);
      setReimburseValues(reimbuerseVal);
      setGrossProfitProjectValues(grossProfitProjectedVal);
      setGrossProfitActualValues(grossProfitActualVal);
      setActualProfitvalues(actualNetProfitValue);
      // setNetProfitText(netProfitLabel);
      setNetProfitValues(ProjectedNetProfitValue);
      setValues(expenseValue);
      setDataLabel(expenseLabel);
      setReimburseAwait(reimburseAwait);
      // setSingleChild(details?.department_id?.id);
    }
  };

  // console.log(currencyName, currencyValue, "currency-name");

  const monthLabel = [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //to get net profit data

  // to get expense Item
  const dataExpense = {
    data: {
      labels: Array.from(dataLabel),
      datasets: [
        {
          data: values,
          // data: data,
          // data: expenseVal,
          backgroundColor: "#0E73F6",
          barPercentage: 1,
          label: "Expense Item",
          display: false,
        },
      ],
    },
  };

  const periodOptions = [
    { label: "All", value: "" },
    { label: "1 month", value: "1month" },
    { label: "2 months", value: "2month" },
    { label: "3 months", value: "3month" },
    { label: "4 Months", value: "4month" },
    { label: "5 Months", value: "5month" },
    { label: "6 months", value: "6month" },
    { label: "7 months", value: "7month" },
    { label: "8 months", value: "8month" },
    { label: "9 Months", value: "9month" },
    { label: "10 Months", value: "10month" },
    { label: "11 Months", value: "11month" },
    { label: "1 year", value: "1year" },
  ];

  // console.log(handleCurrencySymbol(currencyLabel), "currency symbol");
  // const hh = [1, 2, 3, 4];

  // const gg = Array(netProfitValues)?.map((chi) => {
  //   console.log(
  //     // formatNumber(
  //     FormatNumWithCurrency(
  //       chi?.map((hh) => {

  //       }),
  //       actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
  //       actionCtx?.currencyValue
  //       // )
  //     ),
  //     "child"
  //   );
  // });

  // formatNumber(
  //   FormatNumWithCurrency(
  //     chi[2],
  //     actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
  //     actionCtx?.currencyValue
  //   ) || 0
  // ),

  // console.log(gg, "sndflsnd");
  // console.log(hh, "hhh");
  // const each = netProfitValues?.forEach((chi) => {
  //   console.log(chi);
  // });
  // console.log(each, "dsfksb");
  const handleDepartmentOptions = (option) => {
    if (option?.length > 0) {
      const newOption = option?.map((chi) => {
        return {
          ...chi,
          label: chi?.name,
          value: chi?.id,
        };
      });
      return newOption;
    }
  };

  return (
    <>
      {/* <EmployeeDashboardLayout goBack organization pageTitle={"Dashboard"}> */}
      <div className="expense-projection-inde-wrap">
        <EmployeeHomeSortBox
          // onSearch={() =>
          //   setShowModal((prev) => {
          //     return { ...prev, search: true };
          //   })
          // }
          isLoading={loading}
          //fiscal year
          fiscalOption={fiscalYearDrop}
          fiscal_year_value={details?.fiscal_year_id}
          onFiscalChange={(e) =>
            setDetails((prev) => {
              return { ...prev, fiscal_year_id: e };
            })
          }
          // subsidiary
          subValue={details?.subsidiary_id}
          onSubChange={async (e) => {
            setDetails((prev) => {
              return { ...prev, subsidiary_id: e };
            });
            const obj = {
              reason: true,
              id: e?.value,
            };
            const data = await dispatch(getDepartmentsBySubsidiary(obj));
            if (data?.payload?.success) {
              const newData = data?.payload?.data?.departments;
              setDepartmentOption(newData);
            }
          }}
          subOptions={subsidiariesDrop}
          // department
          deptValue={details?.department_id}
          onDeptChange={(e) =>
            setDetails((prev) => {
              return { ...prev, department_id: e };
            })
          }
          deptOption={handleDepartmentOptions(departmentOption)}
          // deptOption={
          //   user?.user_type === "ADMIN" ? departmentDrop : userDepartmentDrop
          // }
          //user
          userOption={userDrop || singleUserDrop}
          onUserChange={(e) => {
            setDetails((prev) => {
              return { ...prev, user_id: e };
            });
          }}
          dateRange={periodOptions}
          onDateChange={(e) => {
            // setDate(e);
            setDetails((prev) => {
              return { ...prev, period: e };
            });
          }}
          dateVal={details?.period}
          // date
          handleDateChange={(e) => {
            setDetails((prev) => {
              return { ...prev, period: e };
            });
            // console.log(e, "e");
          }}
          // dateRange={dateRange}
          range={range}
        />
        {showCurrentWrap &&
          actionCtx?.activeOrg?.active_fiscal_year?.budget_assumption && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "12fr 1fr",
                marginTop: "3rem",
                padding: "3rem",
                paddingBottom: "unset",
                backgroundColor: "var(--blue-fade-color)",
              }}
            >
              <div>
                <p>
                  Current fiscal year is{" "}
                  <span>{actionCtx?.activeOrg?.active_fiscal_year?.title}</span>
                </p>
                <ButtonBlue
                  style={{ margin: "2rem 0 2rem 0" }}
                  text={`View Budget Assumption`}
                  onClick={() => setShowBudgetModal(true)}
                  // onClick={()=>history.push('/dashboard')}
                />
              </div>
              <div
                style={{
                  backgroundColor: " var(--back-bg-color)",
                  placeItems: "center",
                  display: "grid",
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => setShowCurrentWrap(false)}
              >
                <FaTimes />
              </div>
            </div>
          )}
        {/* employee top container start */}
        <div className="emp-top-wrap">
          {/* first card start */}
          <div>
            <p>Total Projection</p>

            {/* card group start */}
            <div className="card-wrap">
              {/* card-content start */}
              <div className="emp-home-card">
                {/* <div> */}
                {/* progress bar start */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  className=""
                >
                  <p style={{ color: "#07A287", fontWeight: "600" }}>
                    {getPercentage(
                      dashboardDatas?.data?.total_projection?.revenue,
                      dashboardDatas?.data?.total_projection?.expenditure
                    ) || "0"}
                    %
                  </p>
                  <p style={{ color: "#FCA549", fontWeight: "600" }}>
                    {getPercentage(
                      dashboardDatas?.data?.total_projection?.expenditure,
                      dashboardDatas?.data?.total_projection?.revenue
                    ) || "0"}
                    %
                  </p>
                </div>
                <div>
                  <MultiProgress
                    transitionTime={1.2}
                    elements={[
                      {
                        value: getPercentage(
                          dashboardDatas?.data?.total_projection?.revenue || 0,
                          dashboardDatas?.data?.total_projection?.expenditure ||
                            0
                        ),
                        color: "#07A287",
                      },
                      {
                        value:
                          100 -
                          getPercentage(
                            dashboardDatas?.data?.total_projection?.revenue ||
                              0,
                            dashboardDatas?.data?.total_projection
                              ?.expenditure || 0
                          ),
                        color: "#FCA549",
                      },
                    ]}
                    roundLastElement={false}
                    backgroundColor="#ECEDED"
                    round={false}
                    height={13}
                  />
                </div>
                {/* progress bar end */}
                {/* top text wrap start */}
                <div className="top-text-wrap">
                  {/* revenue wrap start */}

                  <div
                    onClick={() => {
                      history.push("/employee-dashboard-projection-revenue");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>Revenue</p>
                    <p className="approve-digit">
                      {dashboardDatas?.data?.total_projection?.revenue < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboardDatas?.data?.total_projection?.revenue || "0"
                        )
                      )}
                      {/* {`${formatNumber(
                        String(
                          FormatNumWithCurrency(
                            dashboardDatas?.data?.total_projection?.revenue,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}`} */}
                    </p>
                  </div>
                  {/* revenue wrap end */}
                  {/* expenditure wrap start */}
                  <div
                    onClick={() => {
                      history.push(
                        "/employee-dashboard-projection-expenditure"
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>Expenditure</p>
                    <p className="pending-digit">
                      {dashboardDatas?.data?.total_projection?.expenditure < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboardDatas?.data?.total_projection?.expenditure ||
                            "0"
                        )
                      )}
                      {/* {`${formatNumber(
                        FormatNumWithCurrency(
                          dashboardDatas?.data?.total_projection?.expenditure,
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.base_currency?.name,
                          actionCtx?.currencyValue
                        ) || 0
                      )}`} */}
                    </p>
                  </div>
                  {/* expenditure wrap end */}
                </div>
                {/* top text wrap end */}
              </div>
              {/* card-content end */}
            </div>
            {/* card group end */}
          </div>
          {/* first card end */}

          {/* second card start */}
          <div>
            <p>Total Actual</p>
            {/* card group start */}
            <div className="card-wrap">
              {/* card-content start */}
              <div className="emp-home-card">
                {/* <div> */}
                {/* progress bar start */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  className=""
                >
                  <p style={{ color: "#07A287", fontWeight: "600" }}>
                    {getPercentage(
                      dashboardDatas?.data?.total_actual?.revenue,
                      dashboardDatas?.data?.total_actual?.expense
                    ) || "0"}
                    %
                  </p>
                  <p style={{ color: "#FCA549", fontWeight: "600" }}>
                    {getPercentage(
                      dashboardDatas?.data?.total_actual?.expense,
                      dashboardDatas?.data?.total_actual?.revenue
                    ) || "0"}
                    %
                  </p>
                </div>
                <div>
                  <MultiProgress
                    transitionTime={1.2}
                    elements={[
                      {
                        value: getPercentage(
                          dashboardDatas?.data?.total_actual?.revenue || 0,
                          dashboardDatas?.data?.total_actual?.expense || 0
                        ),
                        color: "#07A287",
                      },
                      {
                        value:
                          100 -
                          getPercentage(
                            dashboardDatas?.data?.total_actual?.revenue || 0,
                            dashboardDatas?.data?.total_actual?.expense || 0
                          ),
                        color: "#FCA549",
                      },
                    ]}
                    roundLastElement={false}
                    backgroundColor="#ECEDED"
                    round={false}
                    height={13}
                  />
                </div>
                {/* progress bar end */}
                {/* top text wrap start */}
                <div className="top-text-wrap">
                  {/* revenue wrap start */}
                  <div
                    onClick={() => {
                      history.push("/employee-dashboard-actual-revenue");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p
                    // onClick={() =>
                    //   console.log(
                    //     "daashboard data",
                    //     dashboardDatas?.data?.total_projection
                    //   )
                    // }
                    >
                      Revenue
                    </p>
                    <p className="approve-digit">
                      {/* {`N${formatNumber(
                        String(dashboardDatas?.data?.total_actual?.revenue || 0)
                      )}`} */}

                      {/* {`${formatNumber(
                        FormatNumWithCurrency(
                          dashboardDatas?.data?.total_actual?.revenue,
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.base_currency?.name,
                          actionCtx?.currencyValue
                        ) || 0
                      )}`} */}
                      {dashboardDatas?.data?.total_actual?.revenue < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboardDatas?.data?.total_actual?.revenue || "0"
                        )
                      )}
                    </p>
                  </div>
                  {/* revenue wrap end */}
                  {/* expenditure wrap start */}
                  <div
                    onClick={() => {
                      history.push("/employee-dashboard-actual-expense");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>Expense</p>
                    <p className="pending-digit">
                      {/* {`N${formatNumber(
                        String(dashboardDatas?.data?.total_actual?.expense || 0)
                      )}`} */}
                      {/* {`${formatNumber(
                        FormatNumWithCurrency(
                          dashboardDatas?.data?.total_actual?.expense,
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.base_currency?.name,
                          actionCtx?.currencyValue
                        ) || 0
                      )}`} */}
                      {dashboardDatas?.data?.total_actual?.expense < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboardDatas?.data?.total_actual?.expense || "0"
                        )
                      )}
                    </p>
                  </div>
                  {/* expenditure wrap end */}
                </div>
                {/* top text wrap end */}
              </div>
              {/* card-content end */}
            </div>
            {/* card group end */}
          </div>

          {/* second card end */}

          {/* employee net profit chart start */}
          {/* emp-chart-container start */}
          <div className="emp-linechart-container">
            <div className="emp-line-chart">
              {/* chart top wrap start */}
              <div className="chart-top-wrap">
                <div>
                  <p>Net Profit</p>
                  <p className="emp-chart-head">Projected Vs Actual</p>
                </div>
                {/* select start */}
                {/* <div>
                  <Select
                    options={optionList}
                    placeholder="Current Fiscal Year"
                  />
                </div> */}
                {/* select end */}
                {/* chart top wrap end */}
              </div>
              <EmployeeNetProfitChart
                // dataValue={netProfitGraphVal}
                loading={loading}
                dataValues={netProfitValues}
                actualValues={actualProfitValues}
                dataLabels={Array.from(monthLabel)}
                approve={true}
                approveLabel
              >
                <hr />
                <div className="chart-bottom-wrap">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem(
                        "employee-department-id",
                        details?.department_id?.id || userDepartmentDrop[0]?.id
                      );

                      localStorage.setItem(
                        "employee-dashboard-user-id",
                        details?.user_id?.value
                      );

                      // setSingleChild(details?.department_id?.id);
                      history.push(
                        "/employee-dashboard-home-net-profit-projection"
                      );
                    }}
                  >
                    <p className="chart-pending-text">Projected Net Profit</p>
                    <p className="chart-bottom-digit">
                      {/* {`NGN${formatNumber(
                      String(
                        dashboardDatas?.data?.net_profit?.projected_net_profit
                      ) || 0
                    )}`} */}
                      {/* {`${formatNumber(
                        FormatNumWithCurrency(
                          dashboardDatas?.data?.net_profit
                            ?.projected_net_profit,
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.base_currency?.name,
                          actionCtx?.currencyValue
                        ) || 0
                      )}`} */}
                      {dashboardDatas?.data?.net_profit?.projected_net_profit <
                      0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboardDatas?.data?.net_profit
                            ?.projected_net_profit || "0"
                        )
                      )}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      history.push(
                        "/employee-dashboard-home-actual-net-profit-projection"
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p className="chart-approve-text">Actual Net Profit</p>
                    <p className="chart-bottom-digit">
                      {/* {`NGN${formatNumber(
                      String(
                        dashboardDatas?.data?.net_profit?.actual_net_profit || 0
                      )
                    )}`} */}
                      {/* {`${formatNumber(
                        FormatNumWithCurrency(
                          dashboardDatas?.data?.net_profit?.actual_net_profit,
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.base_currency?.name,
                          actionCtx?.currencyValue
                        ) || 0
                      )}`} */}
                      {dashboardDatas?.data?.net_profit?.actual_net_profit < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboardDatas?.data?.net_profit?.actual_net_profit ||
                            "0"
                        )
                      )}
                    </p>
                  </div>
                </div>
              </EmployeeNetProfitChart>
            </div>
          </div>
          {/* emp-chart-container end */}
          {/* second chart-card start */}
          <div className="emp-linechart-container">
            <div className="emp-line-chart">
              {/* chart top wrap start */}
              <div className="chart-top-wrap">
                <div>
                  <p>Gross Profit</p>
                  <p className="emp-chart-head">Projected Vs Actual</p>
                </div>
                {/* select start */}
                {/* <div>
                  <Select
                    options={optionList}
                    placeholder="Current Fiscal Year"
                  />
                </div> */}
                {/* select end */}
                {/* chart top wrap end */}
              </div>
              <EmployeeNetProfitChart
                loading={loading}
                dataValues={grossProfitProjectValues}
                actualValues={grossProfitActualValues}
                dataLabels={Array.from(monthLabel)}
                pending={true}
                pendingLabel
              >
                <hr />
                <div className="chart-bottom-wrap">
                  <div
                    onClick={() =>
                      history.push(
                        "/employee-dashboard-home-gross-profit-projection"
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <p className="chart-pending-text">Projected Gross Profit</p>
                    <p className="chart-bottom-digit">
                      {/* {`NGN${formatNumber(
                        String(
                          dashboardDatas?.data?.gross_profit
                            ?.projected_gross_profit || 0
                        )
                      )}`} */}
                      {/* {`${formatNumber(
                        FormatNumWithCurrency(
                          dashboardDatas?.data?.gross_profit
                            ?.projected_gross_profit,
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.base_currency?.name,
                          actionCtx?.currencyValue
                        ) || 0
                      )}`} */}
                      {dashboardDatas?.data?.gross_profit
                        ?.projected_gross_profit < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboardDatas?.data?.gross_profit
                            ?.projected_gross_profit || "0"
                        )
                      )}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      history.push(
                        "/employee-dashboard-home-actual-gross-profit"
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p className="chart-approve-text">Actual Gross Profit</p>
                    <p className="chart-bottom-digit">
                      {/* {`NGN${formatNumber(
                        String( 
                          dashboardDatas?.data?.gross_profit
                            ?.actual_gross_profit || 0
                        )
                      )}`} */}
                      {/* {`${formatNumber(
                        FormatNumWithCurrency(
                          dashboardDatas?.data?.gross_profit
                            ?.actual_gross_profit,
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.base_currency?.name,
                          actionCtx?.currencyValue
                        ) || 0
                      )}`} */}
                      {dashboardDatas?.data?.gross_profit
                        ?.actual_gross_profitt < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboardDatas?.data?.gross_profit
                            ?.actual_gross_profit || "0"
                        )
                      )}
                    </p>
                  </div>
                </div>
              </EmployeeNetProfitChart>
            </div>
          </div>
          {/* emp-chart-container end */}
          {/* second chart-card end */}
          {/* employee net profit chart end */}
        </div>
        {/* employee top container end */}
        {/* employee reinbursement chart start */}
        {/* container start */}
        <div className="emp-reinburstment-container">
          <div className="chart-top">
            <div>
              <p>Reimbursement</p>
            </div>
            <div>
              {showChart.arrowDown && (
                <div
                  className="arrow-btn"
                  onClick={() =>
                    setShowChart((prev) => {
                      return {
                        ...prev,
                        chart: !showChart.chart,
                        arrowUp: true,
                        arrowDown: false,
                      };
                    })
                  }
                >
                  <FaAngleDown />
                </div>
              )}
              {showChart.arrowUp && (
                <div
                  className="arrow-btn"
                  onClick={() =>
                    setShowChart((prev) => {
                      return {
                        ...prev,
                        chart: !showChart.chart,
                        arrowUp: false,
                        arrowDown: true,
                      };
                    })
                  }
                >
                  <FaAngleUp />
                </div>
              )}
            </div>
          </div>
          {showChart.chart && (
            <EmployeeHomeChatTwo
              actualValue={reimburseAwait}
              dataValue={reimburseValues}
              loading={loading}
              // ActualRemiburseVal={formatNumber(
              //   String(dashboardDatas?.data?.reimburse) || 0
              // )}
              ActualRemiburseVal={`${formatNumber(
                dashboardDatas?.data?.reimburse || 0
              )}`}
              AwaitingReimburse={`${formatNumber(
                dashboardDatas?.data?.awaiting_reimburse || 0
              )}`}
            />
          )}
          {/* employee reinbursement chart end */}
          {/* monthly expense chart start */}

          <div
            style={{
              marginTop: "3rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p>Monthly Expense</p>
            </div>
            {/* arrow toggler start */}
            <div>
              {showChart.arrowDownTwo && (
                <div
                  style={{ cursor: "pointer" }}
                  className="arrow-btn"
                  onClick={() =>
                    setShowChart((prev) => {
                      return {
                        ...prev,
                        chartTwo: !showChart.chartTwo,
                        arrowUpTwo: true,
                        arrowDownTwo: false,
                      };
                    })
                  }
                >
                  <FaAngleDown />
                </div>
              )}
              {showChart.arrowUpTwo && (
                <div
                  style={{ cursor: "pointer" }}
                  className="arrow-btn"
                  onClick={() =>
                    setShowChart((prev) => {
                      return {
                        ...prev,
                        chartTwo: !showChart.chartTwo,
                        arrowUpTwo: false,
                        arrowDownTwo: true,
                      };
                    })
                  }
                >
                  <FaAngleUp />
                </div>
              )}
            </div>
            {/* arrow toggler end */}
          </div>
          {showChart.chartTwo && (
            <EmployeeHomeChartThree
              loading={loading}
              dataValue={dataExpense?.data}
            />
          )}
        </div>
        {/* monthly expense chart end */}
      </div>
      {/* container end */}
      {/* <EmployeeExpenseChart /> */}
      {/* </EmployeeDashboardLayout> */}
      {children}
      <>
        {/* modal start */}
        <Modal effect="fadeInUp" visible={showModal.search}>
          <EmployeeAdvancedSearchModal>
            <div
              style={{ display: "flex", justifyContent: "right", gap: "3rem" }}
            >
              <button
                onClick={() =>
                  setShowModal((prev) => {
                    return { ...prev, search: false };
                  })
                }
                className="emp-homesort-btn"
              >
                Cancel
              </button>
              <button
                className="emp-homesort-btn"
                style={{
                  backgroundColor: "var(--blue-color)",
                  color: "var(--white-color)",
                }}
              >
                Filter
              </button>
            </div>
          </EmployeeAdvancedSearchModal>
        </Modal>
        {/* modal end */}

        {/* modal start */}
        <Modal effect="fadeInLeft" visible={showBudgetModal}>
          <BudgetAssmptionModal
            budgeBtn={true}
            budgeText={false}
            onBigShow={(param) => {
              setShowBudgetModal(false);
              actionCtx?.setIsModalOut(true);
              setShowBigModal((prev) => {
                return { ...prev, show: true, url: param };
              });
            }}
            onClose={() => setShowBudgetModal(false)}
          />
        </Modal>

        {/* modal end */}
        {/* modal start */}
        <Modal visible={showBigModal?.show} effect="fadeInLeft">
          <BigShowModalComponent
            onClose={() => {
              actionCtx?.setIsModalOut(false);
              setShowBudgetModal(true);
              setShowBigModal((prev) => {
                return { ...prev, url: "", show: false };
              });
            }}
            detail={showBigModal?.url}
          />
        </Modal>
        {/* modal end */}
      </>
    </>
  );
};

export default EmployeeDashboardHome;
