import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../../../../../styles/dashboard/tables/ActualReuseableTable.css";
// import Select from "react-select";

const EmployeeCommonNavLink = () => {
  const history = useHistory();
  const location = useLocation();

  const links = [
    {
      name: "Draft Projection",
      link: "/employee-dashboard-projection-revenue",
    },
    {
      name: "Submitted Projection",
      link: "/employee-dashboard-projection-revenue-submitted",
    },
  ];

  return (
    <div className="common-link-wrap">
      {links.map((child, idx) => {
        const { name, link } = child;
        return (
          <p
            onClick={() => history.push(link)}
            key={idx}
            className={`link ${location.pathname === link && "link-active"}`}
          >
            {" "}
            <span>{name}</span>
          </p>
        );
      })}

      {/*expenditure projection header select */}
      {/* <div style={{marginLeft: "50rem"}}> */}

      {/* <div style={{ marginLeft: "40rem", width: "16vh" }}>
        <Select
          // isSearchable={true}
          // isMulti
          placeholder="Sort By"
          className="select"
          // value={chartFilterVal.value}
          // onChange={(e) => setChartFilterVal(e.value)}
          // options={categoryList}
        />
      </div>
      <div style={{ width: "16vh" }}>
        <Select
          // isSearchable={true}
          // isMulti
          placeholder="Filter By"
          className="select"
          // value={chartFilterVal.value}
          // onChange={(e) => setChartFilterVal(e.value)}
          // options={categoryList}
        />
      </div> */}
      {/* </div> */}
      {/* expenditure projection header select */}
    </div>
  );
};

export default EmployeeCommonNavLink;
