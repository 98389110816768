import React, { useState } from "react";
import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
// import nameIcon from "../../../assets/name-input-icon.svg";
import "../../../../styles/auth/AuthLayout.css";
import "../../../../styles/auth/AuthForm.css";
import workMailIcon from "../../../../assets/work-mail-icon.svg";
import { FaLongArrowAltLeft } from "react-icons/fa";
// import Aos from "aos";
// import "aos/dist/aos.css";

const EmployeeForgetPassword = () => {
  const history = useHistory();
  //   const [showPassword, setShwoPassord] = useState(false);
  const [details, setDetails] = useState({
    email: "",
    // password: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  return (
    <>
      <AuthLayout
        getHelp
        title="Forgot Password"
        small_text={`Enter the email address associated with your account, and `}
        small_text_two={`we'll send you a link to reset your password.`}
      >
        {/* get started */}
        {/* auth form start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            history.push("/employee-recover-password");
          }}
          action=""
          className="auth-form-wrap"
          style={{marginTop: "4rem"}}
        >
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="work_email" className="form-label">
              <figure className="img-box">
                <img src={workMailIcon} className="img" alt="" />
              </figure>
            </label>
            <input
              type="work_email"
              name="email"
              id="work_email"
              className="form-input white-black-color"
              placeholder="Email"
              onChange={handleChange}
              value={details.email}
            />
            <div className={`status ${details.email && "status-active"}`}></div>
            <p
              className={`placeholder white-black-bg white-black-color ${
                details.email && "placeholder-active"
              }`}
            >
              Email
            </p>
          </div>
          {/* form group end */}

          {/* already text start */}
          {/* <div className="already-box already-box-login">
            <p className="text text-login">
              <span>Forgot Password</span>
            </p>
          </div> */}
          {/* already text end */}
          {/* submit form start */}
          <input
            type="submit"
            value="Continue"
            className={`form-group btn-reuse form-submit form-submit-forget-password ${
              details.email && "form-submit-active"
            }`}
          />
          {/* submit form  end */}
        </form>

        <div className="go-back-box" onClick={() => history.push("/employee-login")}>
          <FaLongArrowAltLeft className="icon" />
          <p className="text">Go back to sign in</p>
        </div>
        {/* auth form end */}
      </AuthLayout>
    </>
  );
};

export default EmployeeForgetPassword;
