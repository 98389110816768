import React from "react";
import CommonDeleteModal from "../../administration/budget/modals/CommonDeleteModal";

const DeleteNetProfitProjection = ({ onClose }) => {
  return (
    <>
      <CommonDeleteModal
        title={`Delete Budget Assumption`}
        text={`Are you sure you want to delete this branch. Once confirmed, this would permanently delete this information from the database.`}
        onClose={onClose}
      />
    </>
  );
};

export default DeleteNetProfitProjection;
