import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc, customErrorId } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getExpenditureProjections = createAsyncThunk(
  "employee/get-expenditure-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/expenditure_projection/expenditure-index/${
          formData?.org_id
        }?perPage=${formData?.per_page || ""}
      `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setExpenditureProjections(data?.data?.expenditure)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getDeptBySubsidiary = createAsyncThunk(
  "employee/get-department-by-subsidiary",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `dropdowns/department/${formData}
      `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // await thunkAPI.dispatch(
        //   setExpenditureProjections(data?.data?.expenditure)
        // );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ExpenditureProjectionDownload = createAsyncThunk(
  "employee/expenditure-projection-download",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/expenditure_projection/worksheet
      `,
        formData
      );
      // const file = new Blob([data.data], { type: "application/xlsx" }); //Build a URL from the file
      // const fileURL = URL.createObjectURL(file); //Open the URL on new Window
      // window.open(fileURL);
      // console.log("data--->", data);
      window.open(data?.url, "_self").focus();
      // const downloadCsv = (url) => {
      //   // console.log(templateDownload?.url, "url");
      //   // dispatch(ExpenditureProjectionDownload());
      // };

      await thunkAPI.dispatch(setTemplateDownload(data));
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data, "data data")
        toast.success(data.message, {
          theme: "colored",
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getUserDepartment = createAsyncThunk(
  "employee/get-user-department",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/get-user-department?organization_id=${formData}
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const userDepartmentsInfo = data?.data?.department;
        await thunkAPI.dispatch(setUserDepartmentDrop(userDepartmentsInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

// export const getActivityLog = createAsyncThunk(
//   "employee/get-activities",
//   async (formData, thunkAPI) => {
//     try {
//       const { data } = await axios.get(
//         `/activities
//       `
//       );
//       // console.log(data);
//       if (!data?.success) {
//         return thunkAPI.rejectWithValue(data);
//       }
//       if (data?.success) {
//         // console.log(data, "activtiy data");
//         const activityInfo = data?.data?.department;
//         await thunkAPI.dispatch(setActivity(activityInfo));
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (err) {
//       toast.error(err?.response?.data?.message, {
//         theme: "colored",
// toastId: customErrorId,
//       });
//       if (err) {
//         return thunkAPI.rejectWithValue(err);
//       }
//     }
//   }
// );

export const getExpenditureProjectionById = createAsyncThunk(
  "employee/get-expenditure-projection-by-id",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/expenditure_projection/submitted-list/${
          formData?.org_id || ""
        }?filter[department_id]=${formData?.dept || ""}&filter[status]=${
          formData?.status || ""
        }&currency_conversion_id=${formData?.currency || ""}&perPage=${
          formData?.per_page || ""
        }&chart_of_account=${
          formData?.chart_of_account_id || ""
        }&fiscal_year_id=${formData?.fiscal_year_id || ""}
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list", data?.data?.expenditure)
        await thunkAPI.dispatch(
          setExpenditureProjectionById(data?.data?.expenditure)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const notifyNextApprover = createAsyncThunk(
  "notify-next-approver",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(`/notify-next-approver`, formData);
      // console.log("data-approval", data);
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-approval", data);
        // await thunkAPI.dispatch(
        //   setExpenditureProjectionById(data?.data?.expenditure)
        // );
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeProjectionDownload = createAsyncThunk(
  "employee/get-pro-download",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(
        `/employee/reports/download-projection?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&report_type=${
          formData?.type || ""
        }&report_format=${formData?.format || ""}&user_id=${
          formData?.user_id
        }&currency_conversion_id=${formData?.currency || ""}&status=${
          formData?.status || ""
        }&chart_of_account=${formData?.chart_of_account_id || ""}&vertical_id=${
          formData?.vertical_id || ""
        }&product_id=${formData?.product_id || ""}&fiscal_year_id=${
          formData?.fiscal_year_id || ""
        }`
      );
      console.log(data, "data");
      if (data?.status === 200) {
        // console.log(data);
        return data;
      }
      return data;
      // if (!data?.success) {
      //   return thunkAPI.rejectWithValue(data);
      // }
      // if (data?.success) {
      //   return thunkAPI.rejectWithValue(data);
      // }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAnyEmployeeReports = createAsyncThunk(
  "employee/get-pro-download",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(
        `/employee/reports/downloads/report?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&report_type=${
          formData?.type || ""
        }&report_format=${formData?.format || ""}&user_id=${
          formData?.user_id
        }&currency_conversion_id=${formData?.currency || ""}&status=${
          formData?.status || ""
        }`
      );
      // console.log(data);
      if (data?.status === 200) {
        // console.log(data);
        return data;
      }
      return data;
      // if (!data?.success) {
      //   return thunkAPI.rejectWithValue(data);
      // }
      // if (data?.success) {
      //   return thunkAPI.rejectWithValue(data);
      // }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const recallProjections = createAsyncThunk(
  "employee/recall-projections",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(`/recall-projection`, formData);
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list", data?.data?.expenditure)
        // await thunkAPI.dispatch(
        //   setExpenditureProjectionById(data?.data?.expenditure)
        // );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovedExpenditureProjectionById = createAsyncThunk(
  "employee/get-approved-expenditure-projection-by-id",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/expenditure_projection/submitted-list/${
          formData?.org_id || ""
        }?filter[is_submitted]=${
          formData?.filter_submitted || ""
        }&filter[status]=${formData?.status || ""}&filter[department_id]=${
          formData?.dept_id || ""
        }
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list", data?.data?.expenditure)
        await thunkAPI.dispatch(
          setExpenditureProjectionById(data?.data?.expenditure)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getApprovedExpenditureProjectionByIdPagination = createAsyncThunk(
  "employee/get-approved-expenditure-projection-by-id-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/expenditure_projection/submitted-list/${
          formData?.org_id || ""
        }?filter[is_submitted]=${
          formData?.filter_submitted || ""
        }&filter[status]=${formData?.status || ""}&page=${
          formData?.page || ""
        }&filter[department_id]=${formData?.dept_id || ""}
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list", data?.data?.expenditure)
        await thunkAPI.dispatch(
          setExpenditureProjectionById(data?.data?.expenditure)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjectionBySearch = createAsyncThunk(
  "employee/get-expenditure-projection-search",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/expenditure_projection/expenditure-index/${
          formData?.org_id || ""
        }?filter_by=${formData?.filter || ""}&search=${
          formData?.search || ""
        }&sort_by=${formData?.sort || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        await thunkAPI.dispatch(
          setExpenditureProjectionSearch(data?.data?.expenditure)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjectionSubmittedBySearch = createAsyncThunk(
  "employee/get-expenditure-projection-search",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/expenditure_projection/submitted-list/${
          formData?.org_id || ""
        }?filter=${formData?.filter || ""}&search=${
          formData?.search || ""
        }&sort=${formData?.sort || ""}&filter_by[department_id]=${
          formData?.dept || ""
        }&filter[status]=${formData?.status || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}&fiscal_year_id=${
          formData?.fiscal_year_id || ""
        }`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        await thunkAPI.dispatch(
          setExpenditureProjectionSubmittedSearch(data?.data?.expenditure)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovedExpenditureProjectionSubmittedBySearch =
  createAsyncThunk(
    "employee/get-expenditure-projection-search",
    async (formData, thunkAPI) => {
      try {
        // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
        const { data } = await axios.get(
          `/employee/expenditure_projection/submitted-list/${
            formData?.org_id || ""
          }?sort=${formData?.sort || ""}&search=${
            formData?.search || ""
          }&filter[is_submitted]=${formData?.is_submitted || ""}&filter=${
            formData?.filter_by || ""
          }&status=${formData?.status || ""}&filter_by[department_id]=${
            formData?.dept_id || ""
          }`
        );
        // console.log("branch", data.data.branches.data);
        if (!data?.success) {
          toast.error(data.message, {
            theme: "colored",
            toastId: customErrorId,
          });
          return thunkAPI.rejectWithValue(data);
        }
        if (data?.success) {
          // console.log(data);
          await thunkAPI.dispatch(
            setExpenditureProjectionSubmittedSearch(data?.data?.expenditure)
          );
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        // console.log(err);
        if (err.response.status === 500) {
          toast.error(err.response?.data?.message, {
            theme: "colored",
            toastId: customErrorId,
          });
        }
        if (err) {
          return thunkAPI.rejectWithValue(err);
        }
      }
    }
  );

export const getExpenditureProjectionPagination = createAsyncThunk(
  "employee/get-expenditure-projection-by-id-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/expenditure_projection/submitted-list/${
          formData?.org_id || ""
        }?filter[department_id]=${formData?.dept || ""}&page=${
          formData?.page || ""
        }&filter[status]=${formData?.status || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&currency_conversion_id=${formData?.currency_id || ""}&perPage=${
          formData?.per_page || ""
        }&chart_of_account=${
          formData?.chart_of_account_id || ""
        }&fiscal_year_id=${formData?.fiscal_year_id || ""}
      `
      );
      if (!data?.success) {
        // await thunkAPI.dispatch(setSharedServices(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("owfa", data?.data?.expenditure);
        const expenditureInfo = data?.data?.expenditure;
        await thunkAPI.dispatch(setExpenditureProjectionById(expenditureInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getExportToPdf = createAsyncThunk(
  "employee/export-to-pdf",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/downloads/get-pdf?id=${formData?.id || ""}&type=${
          formData?.type || ""
        }&currency_conversion_id=${formData?.currency_id || ""}`
      );
      if (!data?.url) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.url) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureDraftProjectionPagination = createAsyncThunk(
  "employee/get-expenditure-projection-draft-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } =
        await axios.get(`/employee/expenditure_projection/expenditure-index/${
          formData?.org_id
        }?perPage=${formData?.per_page || ""}&page=${
          formData?.page || ""
        }&filter_by=${formData?.filter || ""}&search=${
          formData?.search || ""
        }&sort_by=${formData?.sort || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }
    `);
      if (!data?.success) {
        // await thunkAPI.dispatch(setSharedServices(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const expenditureInfo = data?.data?.expenditure;
        await thunkAPI.dispatch(setExpenditureProjections(expenditureInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjection = createAsyncThunk(
  "employee/get-expenditure-projection",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(
        `/employee/expenditure_projection/show-expenditure/${formData}`
      );
      // console.log("get-expenditure-projection", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createExpenditureProjection = createAsyncThunk(
  "employee/create-expenditure-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/expenditure_projection/expenditure",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getSubsidiariesByOrg = createAsyncThunk(
  "employee/get-subsidiaries-by-organization",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `/employee/subsidiary-by-organization-id/${formData}`
      );
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        // toast.success(data.message, {
        //   theme: "colored",
        // });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const commentExpenditureProjection = createAsyncThunk(
  "employee/comment-expenditure-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `/employee/comments/expenditure/add-comment?expenditure_projection_id=${
          formData?.expenditure_id || ""
        }`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAllExpenditureProjectionComment = createAsyncThunk(
  "employee/get-all-comment-expenditure-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `/employee/comments/expenditure/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        await thunkAPI.dispatch(
          setExpenditureProjectionComments(
            data?.data?.expenditure_projection_comments
          )
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ApproveExpenditureProjection = createAsyncThunk(
  "employee/approve-expenditure-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `/approval/projections?organization_id=${
          formData?.organization_id || ""
        }&department_id=${formData?.department_id || ""}&projection_type=${
          formData?.projection_type || ""
        }&comment=${formData?.comment || ""}&status=${
          formData?.status || ""
        }&projection_id=${formData?.projection_id || ""}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ExpenditureBulkUpload = createAsyncThunk(
  "employee/expenditure-bulk-upload",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `employee/expenditure_projection/bulk-update`,
        formData
      );
      // const { data } = await axios.post(
      //   `employee/expenditure_projection/bulk-update?organization_id=${
      //     formData?.org_id || ""
      //   }&file=${formData?.file || ""}`,
      //   formData
      // );
      // console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // if (err.response.data.message) {
      //   toast.error(err.response.data.message, {
      //     theme: "colored",
      // toastId: customErrorId,
      //   });
      // }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const submitDraftForApproval = createAsyncThunk(
  "employee/submit-draft-for-approval",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/expenditure_projection/submitted-expenditure",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const submitSingleDraftForApproval = createAsyncThunk(
  "employee/submit-draft-for-approval",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/expenditure_projection/submitted-expenditure",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteExpenditueProjection = createAsyncThunk(
  "employee/delete-expenditure-projection",
  async (formData, thunkAPI) => {
    try {
      let url = "";
      if (formData?.multi) {
        url = `/multiple-projection-delete`;
      } else {
        url = `employee/expenditure_projection/expenditure-delete/${formData}`;
      }
      const token = localStorage.getItem("token");
      await setAuthToken(token);

      const { data } = formData?.multi
        ? await axios.post(url, formData)
        : await axios.delete(url, formData);
      // console.log('set-up-org', data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteExpenditueProjectionComment = createAsyncThunk(
  "employee/delete-expenditure-projection-comment",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `employee/comments/expenditure/${formData?.exp_id}/${formData?.comment_id}`
      );
      console.log("set-up-org", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateExpenditureProjections = createAsyncThunk(
  "employee/update-expenditure-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `/employee/expenditure_projection/expenditure-update/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const expenditure_projection = createSlice({
  name: "expenditure_projection",
  initialState: {
    expenditureProjctions: [],
    expenditureProjectionById: [],
    expenditureProjectionComments: [],
    links: {},
    templateDownload: {},
    meta: {},
    expenditureProjection: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
    userDepartment: [],
    userDepartmentDrop: [],
    empActivity: [],
  },
  reducers: {
    setExpenditureProjections: (state, action) => {
      state.isAuth = true;
      state.expenditureProjctions = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },

    setUserDepartmentDrop: (state, action) => {
      state.isAuth = true;
      state.userDepartmentDrop = addLabelValueFunc(action.payload);
    },
    setActivity: (state, action) => {
      state.isAuth = true;
      state.empActivity = addLabelValueFunc(action.payload);
    },
    setTemplateDownload: (state, action) => {
      state.isAuth = true;
      state.templateDownload = action.payload;
    },

    setExpenditureProjectionById: (state, action) => {
      state.isAuth = true;
      state.expenditureProjectionById = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },

    setExpenditureProjectionSearch: (state, action) => {
      state.isAuth = true;
      state.expenditureProjctions = action.payload;

      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setExpenditureProjectionComments: (state, action) => {
      state.isAuth = true;
      state.expenditureProjectionComments = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setExpenditureProjectionSubmittedSearch: (state, action) => {
      state.isAuth = true;
      state.expenditureProjectionById = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [createExpenditureProjection.pending]: (state) => {
      state.loading = true;
    },
    [createExpenditureProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [createExpenditureProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [commentExpenditureProjection.pending]: (state) => {
      state.loading = true;
    },
    [commentExpenditureProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [commentExpenditureProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [notifyNextApprover.pending]: (state) => {
      state.loading = true;
    },
    [notifyNextApprover.fulfilled]: (state) => {
      state.loading = false;
    },
    [notifyNextApprover.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeProjectionDownload.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeProjectionDownload.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeProjectionDownload.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getAllExpenditureProjectionComment.pending]: (state) => {
      state.loading = true;
    },
    [getAllExpenditureProjectionComment.fulfilled]: (state) => {
      state.loading = false;
    },
    [getAllExpenditureProjectionComment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [recallProjections.pending]: (state) => {
      // state.loading = true;
      state.recallLoading = true;
    },
    [recallProjections.fulfilled]: (state) => {
      // state.loading = false;
      state.recallLoading = false;
    },
    [recallProjections.rejected]: (state) => {
      // localStorage.removeItem('token');
      // state.loading = false;
      state.recallLoading = false;
      state.isAuth = false;
      state = null;
    },
    [ApproveExpenditureProjection.pending]: (state) => {
      state.loading = true;
    },
    [ApproveExpenditureProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [ApproveExpenditureProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteExpenditueProjectionComment.pending]: (state) => {
      state.loadingTwo = true;
    },
    [deleteExpenditueProjectionComment.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [deleteExpenditueProjectionComment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [ExpenditureProjectionDownload.pending]: (state) => {
      state.loading = true;
    },
    [ExpenditureProjectionDownload.fulfilled]: (state) => {
      state.loading = false;
    },
    [ExpenditureProjectionDownload.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovedExpenditureProjectionById.pending]: (state) => {
      state.loadingPage = true;
    },
    [getApprovedExpenditureProjectionById.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getApprovedExpenditureProjectionById.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovedExpenditureProjectionByIdPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getApprovedExpenditureProjectionByIdPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getApprovedExpenditureProjectionByIdPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovedExpenditureProjectionSubmittedBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getApprovedExpenditureProjectionSubmittedBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getApprovedExpenditureProjectionSubmittedBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [updateExpenditureProjections.pending]: (state) => {
      state.loading = true;
    },
    [updateExpenditureProjections.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateExpenditureProjections.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [ExpenditureBulkUpload.pending]: (state) => {
      state.loadingTwo = true;
    },
    [ExpenditureBulkUpload.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [ExpenditureBulkUpload.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    // [submitDraftForApproval.pending]: (state) => {
    //   state.loading = true;
    // },
    // [submitDraftForApproval.fulfilled]: (state) => {
    //   state.loading = false;
    // },
    // [submitDraftForApproval.rejected]: (state) => {
    //   // localStorage.removeItem('token');
    //   state.loading = false;

    //   state.isAuth = false;
    //   state = null;
    // },
    [submitDraftForApproval.pending]: (state) => {
      state.loadingTwo = true;
      state.loading = true;
    },
    [submitDraftForApproval.fulfilled]: (state) => {
      state.loading = false;
      state.loadingTwo = false;
    },
    [submitDraftForApproval.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [submitSingleDraftForApproval.pending]: (state) => {
      state.loadingTwo = true;
    },
    [submitSingleDraftForApproval.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [submitSingleDraftForApproval.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [deleteExpenditueProjection.pending]: (state) => {
      state.loadingTwo = true;
    },
    [deleteExpenditueProjection.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [deleteExpenditueProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjectionPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjectionPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [ApproveExpenditureProjection.pending]: (state) => {
      state.loading = true;
    },
    [ApproveExpenditureProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [ApproveExpenditureProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [getExpenditureDraftProjectionPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureDraftProjectionPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureDraftProjectionPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },

    [getExpenditureProjectionBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjectionBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjectionBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionSubmittedBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjectionSubmittedBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjectionSubmittedBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },

    [getExpenditureProjections.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjections.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjections.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionById.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjectionById.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjectionById.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getUserDepartment.pending]: (state) => {
      state.loading = true;
    },
    [getUserDepartment.fulfilled]: (state) => {
      state.loading = false;
    },
    [getUserDepartment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setExpenditureProjections,
  setExpenditureProjectionSearch,
  setExpenditureProjectionById,
  setTemplateDownload,
  setExpenditureProjectionSubmittedSearch,
  setUserDepartmentDrop,
  setActivity,
  setExpenditureProjectionComments,
} = expenditure_projection.actions;

export default expenditure_projection.reducer;
