import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/admin-settings/ApprovalDetailFlow.css";
// import DashboardLayout from "../../../../../layout/dashboard/DashboardLayout";
import CommonLinks from "../CommonLinks";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import Modal from "react-awesome-modal";
import ApprovalSuccessModal from "../ApprovalSuccessModal";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { getApprovalByClass } from "../../../../../../redux/approvals";
// import { FaTimes } from "react-icons/fa";

const RevenueProjectionApproval = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { loading, approvals } = useSelector((state) => state.approvals);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [activeId] = useState(
    localStorage.getItem("approval-select-class-id") || 1
  );

  const tableHeadList = ["Subsidiary", "Departments", "Approval Level"];
  const [indexNo, setIndexNo] = useState("");

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      class_id: activeId,
    };
    dispatch(getApprovalByClass(obj));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitle={`Setup Approval Flow`}
        pageTitleSpan={`Step 6 of 6`}
      > */}
      <div className="approval-flow-wrap">
        <p
          onClick={() => {
            console.log(approvals);
          }}
          className="title"
        >
          Approval
        </p>
        {/* comon link start */}
        <CommonLinks />
        {/* comon link end */}

        {/* table box start */}
        <ActualReuseableTable
          tableTitle={`All Approval `}
          tableTitleSpan={`${approvals?.length}`}
          addBtntext={`New Approval`}
          onCreateNew={() => history.push("/dashboard-general-approvals-setup")}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          fromPage={1}
          toPage={approvals?.length}
          totalPage={approvals.length}
          action
          loading={loading && approvals?.length < 1}
          loadingNum={5}
        >
          {approvals?.map((chi, idx) => {
            const { department, subsidiary, type, id } = chi;
            return (
              <TableBodyRow
                loading={loading}
                key={idx}
                two={department?.name}
                one={subsidiary?.name}
                three={type?.name}
                action
                updateText={`Edit Approval`}  
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                onUpdate={() => {
                  localStorage.setItem("approval-single-id", id)
                  history.push("/dashboard-general-approvals-setup-edit");
                }}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}

        {/* text btn box start */}
        {/* <div className="text-button-box">
          <p className="text">
            Click on the button when you’re done setting up
          </p>
          <button onClick={() => setShowModal(true)} className="btn">
            Complete Setup
          </button>
        </div> */}
        {/* text btn box end */}
      </div>
      {/* </DashboardLayout> */}
      {/* modal start  */}
      <Modal visible={showModal} effect="fadeInUp">
        <ApprovalSuccessModal onCancel={() => setShowModal(false)}>
          <ButtonBlue
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-approval-flow-revenue-projection"
              )
            }
            text={`Continue`}
          />
        </ApprovalSuccessModal>
      </Modal>
      {/* modal end */}
    </>
  );
};

export default RevenueProjectionApproval;
