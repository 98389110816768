import React from "react";
import "../../../../../styles/dashboard/admin-settings/ApprovalSettingModal.css";
// import "../../../../../styles/dashboard/admin-settings/AdminSettingApproval.css"
import checkImg from "../../../../../assets/shared-success-img.png";
// import ButtonBlue from "../../../../buttons/ButtonBlue";
import { FaTimes } from "react-icons/fa";

const SharedModal = ({ children, onCancel }) => {
  return (
    <div style={{width: "55rem"}} className="approval-setting-modal-wrap">
      <div onClick={onCancel} className="cancel-box">
        <FaTimes className="icon" />
      </div>
      <figure className="img-box">
        <img src={checkImg} alt="" className="img" />
      </figure>
      <p className="title">Checklist Completed !</p>
      <p className="text">
        You have successfully met the criteria requirement to setup
      </p>
      <p className="text">a shared service for your organization</p>
      {children}
    </div>
  );
};

export default SharedModal;
