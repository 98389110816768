import React from "react";
// import CommonSortBox from "../../../../../../component/fragments/CommonSortBox";
// import CommonNavLink from "../../../../../../component/pages/dashboard/actuals/expense/CommonNavLink";
// import ActionContext from "../../../../../../../context/ActionContext";
import "../../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeTotalRevenueApprovedNavLink from "./approveNavLink";
import EmployeeCommonNavLink from "./CommonNavLink";
import EmployeeDraftRevenueLink from "./draftNavLink";
import EmployeeTotalPendingNavLink from "./pendingNavLink";
import EmployeeTotalRejectNavLink from "./rejectNavLink";
import EmployeeRevenueNavLink from "./RevenueNavLink";
const EmployeeCommonRevenueLayout = ({
  children,
  chartBox,
  revenueNavLink,
  totalRevenueNavLink,
  approvedLink,
  pendingLink,
  rejectLink,
  draftLink
}) => {
  // const actionCtx = useContext(ActionContext);

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Actual Revenue`}> */}
        <div className="expense-projection-inde-wrap">
          <>
            {totalRevenueNavLink && (
              <EmployeeCommonNavLink link={"/dashboard-home"} />
            )}
            {revenueNavLink && (
              <EmployeeRevenueNavLink link={"/dashboard-home"} />
            )}
            {approvedLink && (
              <EmployeeTotalRevenueApprovedNavLink link={"/dashboard-home"} />
            )}
            {pendingLink && (
              <EmployeeTotalPendingNavLink link={"/dashboard-home"} />
            )}
            {rejectLink && (
              <EmployeeTotalRejectNavLink link={"/dashboard-home"} />
            )}
            {draftLink && (
              <EmployeeDraftRevenueLink link={"/dashboard-home"} />
            )}
          </>
          {/* common links end */}
          {/* chart and table box start */}
          <>{chartBox}</>
          {/* chart  box end */}
          {/* children box start */}
          <>{children}</>
          {/* children box end */}
        </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};

export default EmployeeCommonRevenueLayout;
