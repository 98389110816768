import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
import CommonExpenseLayout from "./CommonLayout";
import Select from "react-select";
import ExpenseChart from "./ExpenseChart";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActionContext from "../../../../../context/ActionContext";
// import userImg from "../../../../../assets/hod-one-img.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCurrencyFormat,
  formatNumberWithComma,
  // FormatNumWithCurrency,
  getChartStructures,
  perPageOptions,
  reactSelectStyleTable,
} from "../../../../../helper/Helpers";
import {
  getActualExpenseChart,
  getActualExpenseChartPagination,
} from "../../../../../redux/actuals/expense";

const ExpenseActualIndex = ({ children, chartBox }) => {
  const actionCtx = useContext(ActionContext);
  const { loading, actual_expense, loadingPage } = useSelector(
    (state) => state.actual_expense_chart
  );
  //   const [showChart, setShowChart] = useState(true);
  const [indexNo, setIndexNo] = useState("");
  const [dataChartValue, setDataChartValue] = useState({
    labels: "",
    datasets: [
      {
        label: "Approved",
        data: [],
        backgroundColor: "#07A287",
      },
      {
        label: "Pending",
        data: [],
        backgroundColor: "#FCA549",
      },
      {
        label: "Rejected",
        data: [],
        backgroundColor: "#CC0905",
      },
    ],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    getChartValueHere();
    // eslint-disable-next-line
  }, [actionCtx?.activeOrg, actionCtx?.currencyValue, actionCtx?.perPage]);

  const getChartValueHere = async () => {
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    // console.log(obj);
    // return
    const data = await dispatch(getActualExpenseChart(obj));
    if (data?.payload?.success) {
      // console.log(data);
      const list = getChartStructures(data?.payload?.data?.graph_data);
      setDataChartValue(list);
    }
  };
  const [chartFilterVal, setChartFilterVal] = useState("");
  const history = useHistory();

  const categoryList = [
    { label: "Zojapay", value: 1 },
    { label: "ITH", value: 2 },
    { label: "Zojatech", value: 3 },
  ];

  const tableHeadList = [
    "Subsidiary",
    "Branch",
    "Departments",
    `Amount Projected ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label
    )} )`,
    `Amount Rejected ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label
    )} )`,
  ];

  const handlePagination = (url) => {
    const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getActualExpenseChartPagination(obj));
  };

  const handlePaginationNum = (num) => {
    // const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getActualExpenseChartPagination(obj));
  };

  return (
    <>
      <CommonExpenseLayout
        onReset={getChartValueHere}
        setDataChartValue={setDataChartValue}
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Actual Expense Graph</p>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Sort By"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <ExpenseChart loading={loading || loadingPage} dataToShow={dataChartValue} />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* perpage box start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              position: "relative",
              zIndex: "20",
              marginBottom: "-1rem",
            }}
            className="perpage-wrap"
          >
            <span style={{ fontWeight: "600" }}> All Actual Expense</span>{" "}
            <Select
              styles={reactSelectStyleTable}
              onChange={(e) => {
                actionCtx?.setPerpage(e);
              }}
              value={actionCtx?.perPage}
              options={perPageOptions}
            />
          </div>
          {/* perpage box end */}
          {/* table box start */}
          <ActualReuseableTable
           loading={
            loading &&
            actual_expense?.length < 1
          }
          loadingNum={7}
            useNumPagination
            currentPage={actual_expense?.departments_projections?.current_page}
            totalTableLength={actual_expense?.departments_projections?.total}
            onNumPage={(e) => {
              handlePaginationNum(e);
            }}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six
            // five={tableHeadList[4]}
            // six={tableHeadList[5]}
            // action
            totalPage={actual_expense?.departments_projections?.total}
            fromPage={actual_expense?.departments_projections?.from}
            toPage={actual_expense?.departments_projections?.to}
            nextPage={actual_expense?.departments_projections?.next_page_url}
            onPrevPage={() =>
              handlePagination(
                actual_expense?.departments_projections?.prev_page_url
              )
            }
            onNextPage={() =>
              handlePagination(
                actual_expense?.departments_projections?.next_page_url
              )
            }
            prevPage={actual_expense?.departments_projections?.prev_page_url}
          >
            {actual_expense?.departments_projections?.data?.map((chi, idx) => {
              const {
                subsidiary,
                branch,
                department,
                amount_projected,
                status,
                amount_rejected,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={subsidiary?.name}
                  two={branch?.name}
                  loading={loading || loadingPage}
                  three={department?.name}
                  four={
                    formatNumberWithComma(String(amount_projected || 0)) || "0"
                  }
                  five={formatNumberWithComma(String(amount_rejected || 0))}
                  progressType={status}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  updateText={` Update Branch`}
                  onNextPage={() => {
                    localStorage.setItem(
                      "actual-expense-chart-id",
                      chi?.department?.id
                    );
                    history.push("/dashboard-actual-expense-main");
                    // console.log(chi?.department?.id);
                  }}
                  //   onUpdate={() =>
                  //     history.push(
                  //       "/dashboard-administration-admin-settings-branch-update"
                  //     )
                  //   }
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </CommonExpenseLayout>
    </>
  );
};

export default ExpenseActualIndex;
