import React, { useEffect } from "react";
import { useState } from "react";
import revvexLogo from "../../../../assets/revvex-white-logo.svg";
import lockIcon from "../../../../assets/password-icon.svg";
import { useHistory } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../../../styles/auth/employee-auth-password.scss";
import "../../../../styles/dashboard/SetupLayout.scss";
import ButtonBlue from "../../../../component/buttons/ButtonBlue";
import Modal from "react-awesome-modal";
import EmployeeSuccessModal from "./SuccessMessage";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import {
  activateAccount,
  getProcessInvite,
} from "../../../../redux/employee/activate-account/process-invite";
// import { getUser, getUsers } from "../../../../redux/users/users";
import { toast } from "react-toastify";
import {
  validatePasswordLowercase,
  validatePasswordNumber,
  validatePasswordSpecialCharacter,
  validatePasswordUpperCase,
} from "../../../../helper/ValidateInput";
// import { ActivateAccount } from "../../../../redux/users/users";

const EmployeeCreatePassword = () => {
  const [showPassword, setShowPassword] = useState({ one: false, two: false });
  const [showModal,] = useState(false);
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [refreshTable,] = useState(false);

  // const { user } = useSelector((state) => state.user);
  // console.log(details);
  const { loading } = useSelector((state) => state.employee_accept_invite);
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    const newError = { ...errors, [name]: "" };
    setErrors(newError);
    // setShowPassword()
    setDetails(data);
  };

  const history = useHistory();

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !validatePasswordLowercase(details?.password) &&
      !validatePasswordUpperCase(details?.password) &&
      !validatePasswordSpecialCharacter(details?.password) &&
      !validatePasswordNumber(details?.password)
    ) {
      toast.error(
        "Password should at least be 8 characters long,contain 1 capital letter, 1 lowercase letter,  1 special character,  1 numeric character.",
        {
          theme: "colored",
          position: "top-right",
        }
      );
      return;
    }
    if (!validatePasswordLowercase(details?.password)) {
      toast.error("Password should have at least 1 lowercase letter", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordUpperCase(details?.password)) {
      toast.error("Password should have at least 1 capital letter", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordSpecialCharacter(details?.password)) {
      toast.error("Password should have at least 1 special character", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordNumber(details?.password)) {
      toast.error("Password should have at least  1 numeric character.", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    // validations end -----------------
    if (details?.password_confirmation !== details?.password) {
      setErrors((prev) => {
        return {
          ...prev,
          new_password: "Password does not match",
          confirm_password: "Password does not match",
        };
      });
      return;
    }
    const windowUrl = window.location.href;
    const windowUrlSplit = windowUrl.split("hash=");
    const hashValue = windowUrlSplit[1];
    const hashValueSplit = hashValue.split("=?");
    const hashValueSplitOne = hashValueSplit[0];
    const obj = { ...details, hash: hashValueSplitOne };
    // localStorage.setItem("token", data?.payload?.token);
    // console.log(data?.payload?.token);
    // return;
    // return;
    const data = await dispatch(activateAccount(obj));
    if (data?.payload?.success) {
      const token = data?.payload?.data?.token;
      const user = data?.payload?.data?.user;
      const user_type = data?.payload?.data?.user?.user_type;
      // console.log("usertype: ", user_type);
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      setDetails({
        first_name: "",
        last_name: "",
        password: "",
        password_confirmation: "",
      });
      if (user_type === "ADMIN") {
        history.push("/dashboard-home");
      } else {
        history.push("/employee-dashboard-home");
      }
    }

    // dispatch(getUser());
    // setShowModal(true);
  };

  // const { employee } = useSelector((state) => state.employee_accept_invite);
  // const [inviteUser, setInviteUser] = useState("");

  useEffect(() => {
    // console.log("hash", hashValueSplitOne);
    // console.log("yes");
    handleGetUser();
    // eslint-disable-next-line
  }, [refreshTable]);

  // console.log(showPassword.one, "pasword")

  const handleGetUser = async (param) => {
    const windowUrl = window.location.href;
    const windowUrlSplit = windowUrl.split("hash=");
    const hashValue = windowUrlSplit[1];
    const hashValueSplit = hashValue.split("=?");
    const hashValueSplitOne = hashValueSplit[0];
    const data = await dispatch(getProcessInvite(hashValueSplitOne));
    // console.log(data);
    if (data?.payload?.success) {
      const obj = data?.payload?.data[0];
      setDetails({
        ...details,
        first_name: obj?.first_name,
        last_name: obj?.last_name,
      });
    }
  };

  return (
    <>
      <div className="setup-layout-wrap employee-auth-password">
        <div className="left-box">
          {/* logo box start */}
          <div className="logo-box">
            <figure className="img-box">
              <img src={revvexLogo} alt="" className="img" />
            </figure>
          </div>
          {/* logo box end */}
          {/* list box start */}
          <div className="list-box">
            <p className="terms">Terms of Service </p>
            <p className="privacy">Privacy Policy</p>
            <p className="copy">© 2022 Revvex. All rights reserved</p>
          </div>
          {/* list box end */}
        </div>
        {/* end of left box */}
        <div className="right-box">
          {/* content box start */}
          <div className="content-box">
            <p className="title">Let's activate your account</p>
            <p className="text">
              This will only take few minutes and you can always modify your
              profile settings at any time via your settings.
            </p>
            {/* group-box start */}{" "}
            <div className="wrapper">
              <form onSubmit={handleSubmit}>
                <div className="form-wrap">
                  {/* form-group for first name start */}
                  <div className="form-group">
                    <label htmlFor="First name" className="label">
                      First Name <span>*</span>
                    </label>
                    <input
                      // disabled
                      type="text"
                      placeholder="First name"
                      className={`input ${
                        details?.first_name && "input-active"
                      }`}
                      id="first_name"
                      name="first_name"
                      onChange={handleChange}
                      value={details?.first_name}
                    />
                  </div>
                  {/* form-group for first name end */}

                  {/* form-group for last name start */}
                  <div className="form-group">
                    <label htmlFor="First name" className="label">
                      Last Name <span>*</span>
                    </label>
                    <input
                      // disabled
                      type="text"
                      placeholder="Last name"
                      className={`input ${
                        details?.last_name && "input-active"
                      }`}
                      id="last_name"
                      name="last_name"
                      onChange={handleChange}
                      value={details?.last_name}
                    />
                  </div>
                  {/* form group for last name end */}

                  {/* form-group for new password start */}
                  <div className="form-group">
                    <label
                      htmlFor="new password"
                      className="password-label label"
                    >
                      New Password <span>*</span>
                    </label>
                    {/* employee input new password container start */}
                    <div className="employee-input-container">
                      <figure className="img-box">
                        <img
                          src={lockIcon}
                          className="employee-lock-img"
                          alt=""
                        />
                      </figure>

                      <input
                        type={showPassword.one ? "text" : "password"}
                        placeholder="**********"
                        className={`input employee-pasword-input employee-password ${
                          details.password && "input-active"
                        }`}
                        id="password"
                        name="password"
                        onChange={handleChange}
                        style={{ paddingLeft: "34px" }}
                        value={details.password}
                      />
                      <div
                        onClick={() =>
                          setShowPassword((prev) => {
                            return { ...prev, one: !showPassword.one };
                          })
                        }
                        // onClick={() => setShowPassword(!showPassword)}
                        className="show-hide"
                      >
                        {showPassword?.one ? (
                          <FaEyeSlash className="icon" />
                        ) : (
                          <FaEye className="icon" />
                        )}
                      </div>
                      {errors.new_password && (
                        <p className="error">{errors?.new_password}</p>
                      )}
                    </div>
                    {/* employee input  new password container end */}
                  </div>
                  {/* form-group for new password end */}

                  {/* form-group for confirm password start */}
                  <div className="form-group">
                    <label
                      htmlFor="confirm password"
                      className="password-label label"
                    >
                      Confirm Password <span>*</span>
                    </label>
                    {/* employee input confirm password container start */}
                    <div className="employee-input-container">
                      <figure className="img-box">
                        <img
                          src={lockIcon}
                          className="employee-lock-img"
                          alt=""
                        />
                      </figure>
                      <input
                        type={showPassword.two ? "text" : "password"}
                        placeholder="**********"
                        className={`input employee-password ${
                          details.password_confirmation && "input-active"
                        }`}
                        id="password_confirmation"
                        name="password_confirmation"
                        onChange={handleChange}
                        style={{ paddingLeft: "34px" }}
                        value={details.password_confirmation}
                      />
                      <div
                        // onClick={() => setShowPassword(!showPassword)}
                        onClick={() =>
                          setShowPassword((prev) => {
                            return { ...prev, two: !showPassword.two };
                          })
                        }
                        className="show-hide"
                      >
                        {showPassword.two ? (
                          <FaEyeSlash className="icon" />
                        ) : (
                          <FaEye className="icon" />
                        )}
                      </div>
                      {errors.confirm_password && (
                        <p className="error">{errors?.confirm_password}</p>
                      )}
                    </div>
                  </div>
                  {/* employee input confirm password end */}
                </div>
                {/* form-group for confirm password end */}
                {/* note box start */}
                <div className="note-box">
                  <p>
                    <span>note:</span>Ensure the created password is 8
                    characters long or more, contains at least one number and 1
                    case title e.t.c
                  </p>
                </div>
                {/* <div className="wrapper" style={{width: "100%" }}></div> */}
                {/* note box end */}
                {!loading ? (
                  <input
                    type="submit"
                    value="Continue"
                    className={`form-group btn-reuse form-submit form-submit-forget-password ${
                      details?.password &&
                      details?.password_confirmation &&
                      "form-submit-active"
                    }`}
                    disabled={
                      // !user?.first_name ||
                      // !user?.last_name ||
                      !details?.password || !details?.password_confirmation
                    }
                  />
                ) : (
                  <div style={{ alignSelf: "center" }}>
                    <Dna
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="dna-loading"
                      // wrapperStyle={{color: "red", backgroundColor : "red"}}
                      wrapperClass="dna-wrapper"
                    />
                  </div>
                )}
              </form>
            </div>
            {/* <ButtonBlue text={"Continue"} onClick={() => setShowModal(true)} /> */}
            {/* <ButtonBlue text={"Continue"} onClick={handleSubmit} /> */}
            {/* group-box ends */}
          </div>
          {/* content box end */}
        </div>
      </div>
      {/* modal start */}
      <Modal visible={showModal} effect={`fadeInUp`}>
        <EmployeeSuccessModal>
          <div
            style={{
              display: "flex",
              width: "50rem",
              justifyContent: "center",
            }}
          >
            <ButtonBlue
              onClick={() => history.push("/employee-dashboard-home")}
              text={`Continue to Dashboard`}
            />
          </div>
        </EmployeeSuccessModal>
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeCreatePassword;
