import axios from "axios";
import { toast } from "react-toastify";
import { customErrorId } from "../helper/Helpers";
// import { toast } from "react-toastify";
// import { customErrorId } from "../helper/Helpers";
// import env from "../env";

const API = process.env.REACT_APP_BASE_URL;

/** base url to make request to the BE end point */

const instance = axios.create({
  baseURL: API,
});

// console.log(env.base_url, process.env.NODE_ENV);

instance.interceptors.request.use(
  async (config) => {
    // console.log(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    // console.log(response);

    return response;
  },
  async (error) => {
    // console.log(error);
    if (error?.response?.status === 422 || error?.response?.status === 400) {
      toast.error(`${error?.response?.data?.message}`, {
        theme: "colored",
        toastId: customErrorId,
      });
    }
    if (error?.response?.status === 403 ) {
      toast.error(`${error?.response?.data?.message}`, {
        theme: "colored",
        toastId: customErrorId,
      });
    }
    // if (error.response.status === 500) {
    //   toast.error("Bad Network, check your network settings", {
    //     theme: "colored",
    //     toastId: customErrorId,
    //   });
    // }
    // toast.error(
    //   `${error?.response?.data?.message}`,
    //   {
    //     theme: "colored",
    //     toastId: customErrorId,
    //   }
    // );
    return error;
  }
  // return Promise.reject(error);
  // }
);

export default instance;
