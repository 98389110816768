import React, { useEffect, useState } from "react";
import { FaAngleRight, FaCheck, FaPlus } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import noteIcon from "../../../../../assets/note-icon.svg";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getGroup } from "../../../../../redux/group/group";
// import { department } from "../../../../../redux/department/deparment";
// import { fa } from "faker/lib/locales";

const ViewGroup = () => {
  const history = useHistory();
  const [ setSelectOne] = useState(null);
  const [groupSingle, setGroupSingle] = useState({});
  const dispatch = useDispatch();
  // const { loading } = useSelector((state) => state.group);
  //   const [selectTwo, setSelectTwo] = useState(null);
  const [details, setDetails] = useState({
    Subsidiary_Name: "",
    country: "",
    Subsidiary_Address: "",
    Subsidiary_of: "",
  });

  useEffect(() => {
    getGroupInfo();
      // eslint-disable-next-line
  }, []);

  const getGroupInfo = async () => {
    const id = localStorage.getItem("group_id");
    const data = await dispatch(getGroup(id));
    if (data?.payload?.success) {
      const group = data?.payload?.data?.group;
      const deptOpt = group?.departments?.map((chi) => {
        return { label: chi?.name, value: chi?.id };
      });
      const submitOpt = {
        label: group?.submit_to?.full_name,
        value: group?.submit_to?.id,
      };
      const obj = {
        ...group,
        departments: deptOpt,
        submit_to: submitOpt,
      };

      // console.log(obj);
      setGroupSingle(obj);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const selectListOne = [
    { label: "Naira", value: "1" },
    { label: "USD", value: "2" },
    { label: "Euro", value: "3" },
  ];

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 2 of 6`}
        pageTitle={`View Group`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() => {
              history.push("/dashboard-administration-admin-settings-group");
              setGroupSingle({})
            }}
            className="text"
          >
            Groups
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">View Groups</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">View Groups</p>
            <p className="text">
              Keep under control the extension of the user’s account management.
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          <div className="btn-download-box">
            <button
              className={`btn-bulk ${
                details.Subsidiary_Address ||
                details.Subsidiary_Name ||
                details.Subsidiary_of ||
                details.country
                  ? "btn-bulk-inactive"
                  : ""
              }`}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div>
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // history.push("/dashboard-home");
            }}
            action=""
            className="form"
          >
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1. Group Information</p>
              <div className="form-wrap" style={{ gridTemplateRows: "unset" }}>
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary Name" className="label">
                    Group name <span>*</span>
                  </label>
                  <input
                    disabled
                    type="text"
                    // placeholder="IT Horizon Holdings"
                    className={`input ${
                      details.Subsidiary_Name && "input-active"
                    }`}
                    id="Subsidiary Name"
                    name="Subsidiary_Name"
                    onChange={handleChange}
                    value={groupSingle?.name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Country" className="label">
                    Department 
                  </label>
                  <Select
                    isDisabled
                    className="select "
                    isMulti
                    value={groupSingle?.departments}
                    onChange={(e) => {
                      setSelectOne(e);
                    }}
                    options={selectListOne}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div
                  className="form-group"
                  style={{ gridColumn: "1 / span 2" }}
                >
                  <label htmlFor="Subsidiary Address" className="label">
                    Subsidiary Address
                  </label>
                  <textarea
                    disabled
                    className={`input ${
                      details.Subsidiary_Address && "input-active"
                    }`}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    value={groupSingle?.description}
                  ></textarea>
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}

            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">2. Group Permission</p>
              <div className="form-wrap" style={{ gridTemplateRows: "unset" }}>
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary Name" className="label">
                    Head of group 
                  </label>
                  <input
                    disabled
                    type="text"
                    // placeholder="IT Horizon Holdings"
                    className={`input ${
                      details.Subsidiary_Name && "input-active"
                    }`}
                    id="Subsidiary Name"
                    name="Subsidiary_Name"
                    onChange={handleChange}
                    value={groupSingle?.head_of_group?.full_name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Country" className="label">
                    Submit to 
                  </label>
                  <Select
                    isDisabled
                    className="select "
                    // placeholder="Sync with system settings"
                    value={groupSingle?.submit_to}
                    onChange={(e) => {
                      setSelectOne(e);
                    }}
                    options={selectListOne}
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            {/* check start */}
            <div className="check-label-box">
              <input
                disabled
                type="checkbox"
                className="check"
                id="check-org-delete"
                checked={
                  groupSingle?.should_notify_members === 1 ? true : false
                }
              />
              <label htmlFor="check-org-delete" className="label">
                <span>
                  <FaCheck className="icon" />
                </span>
                Notify all members or users in each department{" "}
              </label>
            </div>
            {/* check end */}
            {/* check start */}
            <div className="check-label-box">
              <input
                disabled
                type="checkbox"
                className="check"
                id="check-org-de"
                checked={
                  groupSingle?.should_be_viewable_by_all_members === 1
                    ? true
                    : false
                }
              />
              <label htmlFor="check-org-de" className="label">
                <span>
                  <FaCheck className="icon" />
                </span>
                Viewable by all members of the departments{" "}
              </label>
            </div>
            {/* check end */}
            {/* btn box start */}

            {/* btn box end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default ViewGroup;
