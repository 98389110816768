import React from "react";
import { FaPlus } from "react-icons/fa";
import "../../styles/dashboard/tables/ReUseableTable.css";

const ReUseableTable = ({
  table_head_list,
  table_title_span,
  table_body_list,
  table_title,
  btn_text,
}) => {
  return (
    <div className="reusable-table-wrap">
      <div className="title-btn-box">
        <p className="title">
          {`${table_title}`} <span>{table_title_span}</span>
        </p>
        {btn_text && (
          <button className="btn">
            <FaPlus className="icon" /> {`new ${btn_text}`}
          </button>
        )}
      </div>
      {/* table head box start */}
      <div
        className="table-head"
        style={{
          gridTemplateColumns: `min-content repeat(${table_head_list?.length}, 1fr)`,
        }}
      >
        <span></span>
        {table_head_list &&
          table_head_list?.map((chi, idx) => {
            return (
              <p key={idx} className="head-text">
                {chi}
              </p>
            );
          })}
      </div>
      {/* table head box end */}
      {/* table body box start */}
      <div className="table-body">
        {table_body_list &&
          table_body_list?.map((chi, idx) => {
            return (
              <div
                key={idx}
                style={{
                  gridTemplateColumns: `min-content repeat(${
                    Object.keys(chi).length
                  }, 1fr)`,
                }}
                className="body-wrap"
              >
                <span></span>
                {Object.entries(chi).map( ([key, value], idx) => {
                  if (key.toLowerCase() === "status") {
                    return (
                      <p
                        key={idx}
                        style={{
                          color:
                            value.toLowerCase() === "active"
                              ? "#07A287"
                              : "#FA3F3F",
                        }}
                        className={`body-text `}
                      >
                        <span></span>
                        {value}
                      </p>
                    );
                  }
                  return (
                    <p key={idx} className="body-text">
                      {value}
                    </p>
                  );
                })}
              </div>
            );
          })}
      </div>
      {/* table body box end */}
    </div>
  );
};

export default ReUseableTable;
