import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeCommonRevenueLayout from "./layout/CommonLayout";
import Modal from "react-awesome-modal";
import EmployeeRevenueComentModal from "../modals/comment";
// import EmployeeApprovedOverviewModal from "../modals/submitted revenue/overview";
import EmployeeActualRevenueApproveOverviewModal from "./layout/modals/approveOverviewModal";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  getActualSubmittedRevenueBySearch,
  getAllRevenueActualComment,
  getSubmittedApprovedActualRevenue,
  getSubmittedActualRevenuePagination,
} from "../../../../../../redux/employee/actual-revenue/actual-revenue";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import EmployeeActualRevenueDraftExpense from "./revenueDraftIndex";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
import ActionContext from "../../../../../../context/ActionContext";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import moment from "moment";

const EmployeeRevenueApproveTable = () => {
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    comment: false,
    approveOverview: false,
  });
  const { user } = useSelector((state) => state.user);
  const { loadingPage, submittedActualRevenues } = useSelector(
    (state) => state.actual_revenue
  );
  const [dept, setDept] = useState({ label: "Select Dept..." });
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [refreshTable] = useState(false);
  const [search, setSearch] = useState("");

  const [singleChild, setSingleChild] = useState({});
  // const [indexNo, setIndexNo] = useState("");
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  // const history = useHistory();

  const SortByOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
  ];
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const [sortByVal, setSortByVal] = useState({ label: "Sort by" });
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Product", value: "product_id" },
    { label: "Vertical", value: "vertical_id" },
  ];
  // const dispatch = useDispatch();
  const debouncedSearchTerm = useDebounce(search, 1000);

  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        q: search,
        filter_by: filterByVal?.value,
        sort_by: sortByVal?.value,
        org_id: user?.organizations[0]?.id,
        dept_id: dept?.id,
        status: "approved",
      };
      dispatch(getActualSubmittedRevenueBySearch(obj));
    } else {
      handleGetApprovedTable();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const handleSortChange = (param) => {
    const obj = {
      // q: search,
      sort_by: param?.value,
      dept_id: dept?.id,
      status: "approved",
      org_id: user?.organizations[0]?.id,
    };
    // console.log("obj", obj)
    dispatch(getActualSubmittedRevenueBySearch(obj));
  };
  const handleGetApprovedTable = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      status: "approved",
      dept_id: dept?.id,
    };
    await dispatch(getSubmittedApprovedActualRevenue(obj));
  };
  useEffect(() => {
    // handleGetApprovedTable();
    getUserDepartment(user?.organizations[0]?.id);
    handleGetActualRevenueData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, user?.organizations[0]?.id, dept]);

  // const handlePagination = async (param) => {
  //   const splitLink = param.split("/api");
  //   const linkTwo = splitLink[1];
  //   // console.log("linkTwo", linkTwo);
  //   dispatch(getSubmittedActualRevenuePagination(linkTwo));
  // };

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      status: "approved",
      dept_id: dept?.id,
    };
    dispatch(getSubmittedActualRevenuePagination(obj));
  };

  const handleGetAllComment = (param) => {
    dispatch(getAllRevenueActualComment(param));
  };

  const [singleItem, setSingleItem] = useState({});

  const handleGetActualRevenueData = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      status: "approved",
      dept_id: dept?.id,
    };
    const data = await dispatch(getSubmittedApprovedActualRevenue(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };
  const cardValue = [
    singleItem?.summary?.total_projection || 0,
    singleItem?.summary?.approved || 0,
    singleItem?.summary?.awaiting_approval || 0,
    singleItem?.summary?.rejected || 0,
  ];

  const cardHeader = ["Total Revenue", "Approved", "Pending", "Rejected"];

  const history = useHistory();

  const tableHeadList = [
    "Product",
    "Units",
    "Unit Price",
    "Revenue",
    "GP",
    "GP %",
    "Date",
    "Status",
  ];

  const link = [
    "/employee-dashboard-actual-revenue-form",
    "/employee-dashboard-actual-revenue-submitted",
    "/employee-dashboard-actual-revenue-total-revenue-submitted-approved",
    "/employee-dashboard-actual-revenue-total-revenue-submitted-pending",
    "/employee-dashboard-actual-revenue-total-revenue-submitted-rejected",
  ];

  return (
    <>
      <div>
        <EmployeeCommonRevenueLayout approvedLink={true}>
          <EmployeeReusableTable
            dept
            departmentDrop={userDepartmentDrop}
            deptVal={dept}
            setDepartmentVal={(e) => {
              setDept(e);
            }}
            sort
            filter
            optionSort={SortByOptions}
            optionSortVal={sortByVal}
            setOptionSortVal={(e) => {
              setSortByVal(e);
              handleSortChange(e);
            }}
            optionFilter={filterByOptions}
            optionFilterVal={filterByVal}
            setOptionFilterVal={(e) => {
              setFilterByVal(e);
              // handleFil
            }}
            onSearchTable={(e) => {
              setSearch(e);
              // console.log("e", e)
            }}
            cardTitleOne={cardHeader[0]}
            loading={loadingPage}
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            cardBodyOne={formatNumber(
              String(
                FormatNumWithCurrency(
                  cardValue[0],
                  actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
                  actionCtx?.currencyValue
                ) || 0
              )
            )}
            cardBodyTwo={cardValue[1]}
            cardBodyThree={cardValue[2]}
            cardBodyFour={cardValue[3]}
            firstCard={true}
            secondCard={true}
            thirdCard={true}
            fourthCard={true}
            showSearch={true}
            linkCardTwo={link[2]}
            addButton={submittedActualRevenues?.data?.length > 0}
            BtnTitle={`New Revenue`}
            onPage={() => history.push(link[0])}
            onFirstCard={() => history.push(link[1])}
            onSecondCard={() => history.push(link[2])}
            onThirdCard={() => history.push(link[3])}
            onFourthCard={() => history.push(link[4])}

            // onCard={onApprove}
          />
          <ActualReuseableTable
            dontShowBnSearch
            dontShowPagination={submittedActualRevenues?.data?.length < 1}
            fromPage={submittedActualRevenues?.from}
            toPage={submittedActualRevenues?.to}
            nextPage={submittedActualRevenues?.next_page_url}
            prevPage={submittedActualRevenues?.prev_page_url}
            totalPage={submittedActualRevenues?.total}
            onNextPage={() =>
              handlePagination(submittedActualRevenues?.next_page_url)
            }
            onPrevPage={() =>
              handlePagination(submittedActualRevenues?.prev_page_url)
            }
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            eight={tableHeadList[7]}
            action
          >
            {submittedActualRevenues?.data?.length > 0 ? (
              <>
                {submittedActualRevenues?.data?.map((chi, idx, id) => {
                  const {
                    product,
                    unit,
                    unit_price,
                    gross_revenue,
                    gp,
                    gross_profit_percent,
                    status,
                    date,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      item={chi}
                      key={idx}
                      one={product?.name}
                      two={formatNumber(String(unit)) || 0}
                      three={formatNumber(
                        String(
                          FormatNumWithCurrency(
                            unit_price,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}
                      four={formatNumber(
                        String(
                          FormatNumWithCurrency(
                            gross_revenue,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}
                      five={formatNumber(
                        String(
                          FormatNumWithCurrency(
                            gp,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}
                      six={formatNumber(String(gross_profit_percent)) || 0}
                      seven={moment(date).format("YYYY-MM-DD")}
                      progressType={
                        status === "approved"
                          ? "accept"
                          : status === "rejected"
                          ? "reject"
                          : "pending"
                      }
                      loading={loadingPage}
                      action
                      rowNo={idx}
                      indexNo={indexNo}
                      setIndexNo={() => setIndexNo(idx)}
                      // updateText={`modify`}
                      // approveText={"Approve"}
                      viewText={"View Comments"}
                      onView={() => {
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, comment: true };
                        });
                      }}
                      onAcceptStatus={() => {
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approveOverview: true };
                        });
                      }}
                      // deleteText={`Reject`}
                      exportText={`Export as PDF`}
                    />
                  );
                })}
              </>
            ) : (
              <EmployeeActualRevenueDraftExpense>
                <ButtonBlue
                  onClick={() =>
                    history.push("/employee-dashboard-actual-revenue-form")
                  }
                  text={`Create Revenue`}
                >
                  <FaPlus style={{ marginRight: "15px" }} />
                </ButtonBlue>
              </EmployeeActualRevenueDraftExpense>
            )}
          </ActualReuseableTable>
        </EmployeeCommonRevenueLayout>
      </div>
      {/* modal start */}
      <Modal visible={showModal.comment}>
        <EmployeeRevenueComentModal
          details={singleChild}
          data={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, comment: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeActualRevenueApproveOverviewModal
          details={singleChild}
          // rejectText={
          //   user?.permissions?.includes("approve/reject actual revenue")
          //     ? true
          //     : false
          // }
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            });
            handleGetAllComment(singleChild?.id);
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      {/* <Modal effect="fadeInDown" visible={showModal.overview}>
        <EmployeeActualRevenueApproveOverviewModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            })
          }
        />
      </Modal> */}
      {/* modal end */}
    </>
  );
};

export default EmployeeRevenueApproveTable;
