import EmployeeExpenseProjectionIndex from "../../../../component/pages/dashboard/projection/expense";
import EmployeeDraftSuccessTable from "../../../../component/pages/dashboard/projection/expense/DraftSuccessTable";
// import EmployeeDraftProjection from "../../../../component/pages/dashboard/projection/expense/EmpDraftProjection";
import EmployeeFinalSubmitProjection from "../../../../component/pages/dashboard/projection/expense/EmpFinalSubmission";
// import EmployeeExpenseCreate from "../../../../component/pages/dashboard/projection/expense/draftprojection";
import EmployeeNewExpenditureProjection from "../../../../component/pages/dashboard/projection/expense/NewExpProjection";
import EmployeeSubmitProjection from "../../../../component/pages/dashboard/projection/expense/SubmitProjection";
import EmployeeExpenseApproveTable from "../../../../component/pages/dashboard/projection/expense/table";
import EmployeeExpensePendingTable from "../../../../component/pages/dashboard/projection/expense/table/pendingTable";
import EmployeeExpenseRejectedTable from "../../../../component/pages/dashboard/projection/expense/table/rejectedTable";
import EmployeeExpenditureApproved from "../../../../component/pages/dashboard/projection/expense/approve";
import EmployeeExpenditurePending from "../../../../component/pages/dashboard/projection/expense/pending";
import EmployeeProjectionReject from "../../../../component/pages/dashboard/projection/expense/reject";
import ExpenditureProjectionUpdate from "../../../../component/pages/dashboard/projection/expense/updateExpenditureProjection";

export const Employee_expense_projection_route_group = [
  {
    path: "/employee-dashboard-projection-expenditure",
    component: EmployeeExpenseProjectionIndex,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  // {
  //   path: "/employee-dashboard-projection-expenditure-create",
  //   component: EmployeeExpenseCreate,
  //   goBack: true,
  //   pageTitle: "Expenditure Projection",
  // },
  {
    path: "/employee-dashboard-projection-expenditure-submitted-success-approve",
    component: EmployeeExpenseApproveTable,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-submitted-success-pending",
    component: EmployeeExpensePendingTable,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-submitted-success-rejected",
    component: EmployeeExpenseRejectedTable,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-new",
    component: EmployeeNewExpenditureProjection,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-submitted",
    component: EmployeeSubmitProjection,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-submitted-success",
    component: EmployeeFinalSubmitProjection,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-draft-projection-submitted",
    component: EmployeeDraftSuccessTable,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-submitted-approved",
    component: EmployeeExpenditureApproved,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-submitted-pending",
    component: EmployeeExpenditurePending,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-submitted-rejected",
    component: EmployeeProjectionReject,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  {
    path: "/employee-dashboard-projection-expenditure-update",
    component: ExpenditureProjectionUpdate,
    goBack: true,
    pageTitle: "Expenditure Projection",
  },
  
];
