import React, { useContext } from "react";
import { useState } from "react";
import { FaAngleDown, FaAngleRight, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import "../../../../../../../employee-folder/styles/dashboard/reports/consolidatedBudget/index.scss";
import { FiArrowUp } from "react-icons/fi";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminProjectionVsActualGpReport,
  getAdminProjectionVsActualGpReportPagination,
} from "../../../../../../../redux/reports/projection-vs-actual-gp";
import {
  addAllToList,
  checkCurrencyFormat,
  formatNumber,
  perPageOptions,
  reactSelectStyle,
  reactSelectStyleTable,
} from "../../../../../../../helper/Helpers";
import TableBodyRow from "../../../../../../tables/TableBodyRow";
import { useEffect } from "react";
import ActionContext from "../../../../../../../context/ActionContext";
import { getDepartments } from "../../../../../../../redux/department/deparment";
import { getSubsidiaries } from "../../../../../../../redux/subsidiary/subsidiary";
import { getAdminProjectionDownload } from "../../../../../../../redux/reports/consolidated-budget";
import ButtonBlue from "../../../../../../buttons/ButtonBlue";
import { Dna } from "react-loader-spinner";
import useDebounce from "../../../../../../../helper/UseDebounce";

const ProjectionActualGP = () => {
  const history = useHistory();

  const [details, setDetails] = useState({
    period: "",
    department_id: "",
    subsidiary_id: "",
  });

  const optionList = [
    {
      label: "Subsidiary",
      value: 1,
    },
    {
      label: "Department",
      value: 2,
    },
    {
      label: "Expense",
      value: 3,
    },
  ];

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const { adminProjectionVsActualGpReports, loadingPage, loading } =
    useSelector((state) => state.admin_projection_vs_actual_gp_report);
  const { departmentDrop } = useSelector((state) => state.department);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);

  const statusDrop = [
    {
      label: "All Projections",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);

  const actionCtx = useContext(ActionContext);
  useEffect(() => {
    if (actionCtx?.activeOrg?.id) {
      const obj = {
        report_type: "projection_vs_actual_GP",
        org_id: actionCtx?.activeOrg?.id || actionCtx?.activeOrg?.id,
        cur_id: actionCtx?.currencyValue?.id || "",
        per_page: actionCtx?.perPage?.value,
        sub_id: details?.subsidiary_id?.value,
        search: search,
      };
      dispatch(getAdminProjectionVsActualGpReport(obj));
      dispatch(getDepartments(actionCtx?.activeOrg?.id));
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionCtx?.activeOrg,
    actionCtx?.currencyValue,
    actionCtx?.perPage?.value,
    details,
    debouncedSearchTerm,
  ]);

  const tableHeadList = [
    "Department",
    "Subsidiary",
    `Total Actual Gross Profit  ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    `Total Supplementary Budget  ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    "Status",
  ];

  const handlePagination = async (param) => {
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];

    const obj = {
      report_type: "projection_vs_actual_GP",
      org_id: actionCtx?.activeOrg?.id || actionCtx?.activeOrg?.id,
      // dept_id: userDepartmentDrop[0]?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      page: linkTwo,
    };
    dispatch(getAdminProjectionVsActualGpReportPagination(obj));
  };

  const [loader, setLoader] = useState({
    one: false,
    two: false,
    three: false,
  });

  const handlePaginationNum = async (num) => {
    const obj = {
      report_type: "projection_vs_actual_GP",
      org_id: actionCtx?.activeOrg?.id || actionCtx?.activeOrg?.id,
      // dept_id: userDepartmentDrop[0]?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      page: num,
    };
    dispatch(getAdminProjectionVsActualGpReportPagination(obj));
  };

  const handleFileDownload = async (param) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      dept_id: details?.department_id?.id,
      type: "projection_vs_actual_GP",
      format: param,
      user_id: user?.id,
      currency: actionCtx?.currencyValue?.id || "",
      status: details?.status?.value,
    };
    setLoader((prev) => {
      return {
        ...prev,
        one: param === "excel" && true,
        two: param === "pdf" && true,
      };
    });
    const data = await dispatch(getAdminProjectionDownload(obj));
    if (data?.payload?.status === 200) {
      window.open(data?.payload?.data?.url, "_blank").focus();
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    } else {
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    }
  };

  const [statVal, setStatVal] = useState("");
  const handleSortByStatus = async (e) => {
    const obj = {
      report_type: "projection_vs_actual_GP",
      org_id: actionCtx?.activeOrg?.id || actionCtx?.activeOrg?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      status: e.value,
    };
    // console.log(obj);
    // return;
    dispatch(getAdminProjectionVsActualGpReportPagination(obj));
  };

  return (
    <>
      {/* <EmployeeDashboardLayout
        goBack
        pageTitle={`View Projections Vs. Actual GP Report`}
        organization
      > */}
      <div className="admin-org-settings-wrap">
        {/* left box start */}
        <div
          className="title-btn-bo"
          style={{ borderBottom: "1px solid var(--border-color)" }}
        >
          <div className="emp-consolidate-wra ">
            <div className="consolidate-left-box">
              <div className="subtitle-box ">
                <p className="text">Report</p>
                <FaAngleRight className="icon" />
                <p
                  className="text"
                  onClick={() => history.push("/dashboard-general-report")}
                >
                  General Reports
                </p>
                <FaAngleRight className="icon" />
                <p className="active text">Projection Vs. Actual GP</p>
              </div>
            </div>
            {/* left box end */}

            {/* select wrap start */}

            <div
              className="consolidate-right-box"
              style={{ marginTop: "-5rem" }}
            >
              <div className="filter-label">
                <label className="label">Filter By</label>
              </div>
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  styles={reactSelectStyle}
                  placeholder="Status"
                  className={`select-wrap ${details?.status && ""}`}
                  value={details?.status}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, status: e };
                    });
                  }}
                  options={statusDrop}
                />
              </div>
              {/* <div className="form-group">
                <Flatpickr
                  id="date"
                  name="date"
                  // multiple
                  options={range}
                  className={`input ${startDate && "input-active"}`}
                  autoComplete="off"
                  value={startDate}
                  onClose={(date) => {
                    setStartDate(
                      moment(date[0]).format("YYYY-MM-DD") +
                        " to " +
                        moment(date[1]).format("YYYY-MM-DD")
                    );
                    // console.log(, "date-date");
                  }}
                  // onClose={handleDateChange}
                  placeholder="YYYY/MM/DD"
                />
              </div> */}
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  placeholder={"Department"}
                  className={`select-wrap ${details?.department_id && ""}`}
                  value={details?.department_id}
                  styles={reactSelectStyle}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, department_id: e };
                    });
                    // setSingleDept((prev) => {
                    //   return { ...prev, department: e };
                    // });
                  }}
                  options={addAllToList(departmentDrop, "Departments")}
                />
              </div>
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  styles={reactSelectStyle}
                  placeholder="Subsidiary"
                  className={`select-wrap ${details?.subsidiary_id && ""}`}
                  isLoading={loading}
                  value={details?.subsidiary_id}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, subsidiary_id: e };
                    });
                  }}
                  options={subsidiariesDrop}
                />
              </div>
            </div>
          </div>
        </div>
        {/* select wrap end */}

        {/* sub-title start */}
        <div className="title-btn-bo">
          {/* text title box start */}
          <div className=" sub-right-box">
            <div className="emp-sub-report">
              <p className="title">
                {`${
                  details?.department_id?.label || "All"
                } Projection vs Actual Gp`}
              </p>
              <Select
                className="per-page-select"
                styles={reactSelectStyleTable}
                value={actionCtx?.perPage}
                onChange={(e) => {
                  actionCtx?.setPerpage(e);
                }}
                options={perPageOptions}
              />
            </div>
            <div className="emp-btn-wrap">
              {/* <div
                // style={{ height: "5rem", marginTop: "1.4rem" }}
                className="emp-reuseable-table-searc emp-adjust-repor"
              > */}
              {/* <div className="emp-search-box">
                <input
                  type="text"
                  placeholder="Search user"
                  id="search"
                  className="input"
                  onChange={(e) => setSearch(e?.target?.value)}
                />
                <label htmlFor="search" className="label">
                  <FaSearch className="icon" />
                </label>
              </div> */}
              {/* </div> */}
              {loader?.one ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleFileDownload("excel");
                  }}
                  text={`Export`}
                />
              )}
              {loader?.two ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleFileDownload("pdf");
                  }}
                  text={`Print`}
                />
              )}
            </div>
            {/* sub-title end */}
          </div>
        </div>
        {/* table start */}
        <ActualReuseableTable
          useNumPagination
          currentPage={adminProjectionVsActualGpReports?.current_page}
          totalTableLength={adminProjectionVsActualGpReports?.total}
          onNumPage={(e) => {
            handlePaginationNum(e);
          }}
          dontShowBnSearch={true}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          six={tableHeadList[5]}
          dontShowPagination={
            adminProjectionVsActualGpReports?.data?.length < 1
          }
          fromPage={adminProjectionVsActualGpReports?.from}
          toPage={adminProjectionVsActualGpReports?.to}
          totalPage={adminProjectionVsActualGpReports?.total}
          nextPage={adminProjectionVsActualGpReports?.next_page_url}
          prevPage={adminProjectionVsActualGpReports?.prev_page_url}
          onNextPage={() =>
            handlePagination(adminProjectionVsActualGpReports?.next_page_url)
          }
          onPrevPage={() =>
            handlePagination(adminProjectionVsActualGpReports?.prev_page_url)
          }
        >
          {adminProjectionVsActualGpReports?.data?.map((chi, idx) => {
            const {
              department,
              subsidiary,
              total_amount_gp,
              total_amount_projected,
              status,
              // supplementary_budget,
            } = chi;
            return (
              <TableBodyRow
                key={idx}
                one={department?.name}
                two={subsidiary?.name}
                three={formatNumber(String(total_amount_gp || 0))}
                four={formatNumber(String(total_amount_projected || 0))}
                rowNo={idx}
                // five={formatNumber(String(supplementary_budget))}
                loading={loadingPage}
                progressType={
                  status === "approved"
                    ? "accept"
                    : status === "rejected"
                    ? "reject"
                    : "pending"
                }
              />
            );
          })}
        </ActualReuseableTable>
        {/* table end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default ProjectionActualGP;
