import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import { getSubsidiaries } from "../../../../../redux/subsidiary/subsidiary";
import {
  getBranches,
  getBranchesBySubsidiary,
} from "../../../../../redux/branch/branch";
import { getUsers } from "../../../../../redux/users/users";
import {
  getDepartment,
  updateDepartment,
} from "../../../../../redux/department/deparment";
import { reactSelectStyle } from "../../../../../helper/Helpers";
import Select from "react-select";
import { Dna } from "react-loader-spinner";
import { toast } from "react-toastify";

const EditDepartment = () => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const [refreshTable] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { usersDrop } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { branchSubDrop } = useSelector((state) => state.branch);
  const { loading } = useSelector((state) => state.department);
  const [singleDept, setSingleDept] = useState({});
  // const { loading } = useSelector((state) => state.subsidiary);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const [details, setDetails] = useState({
    name: "",
    subsidiary_id: "",
    branch_id: "",
    department_code: "",
    head_of_department_id: "",
    submit_to_id: "",
    organization_id: "",
    description: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    const dataSingle = { ...singleDept, [name]: value };
    setSingleDept(dataSingle);
  };

  // const actionCtx = useContext(ActionContext)
  useEffect(() => {
    if (actionCtx?.activeOrg?.type !== "group") {
      setDetails((prev) => {
        return { ...prev, subsidiary_id: subsidiariesDrop[0] };
      });
      setSingleDept((prev) => {
        return { ...prev, subsidiary_id: subsidiariesDrop[0] };
      });
      handleGetBranchBySub(subsidiariesDrop[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsidiariesDrop]);

  const handleGetBranchBySub = async (param) => {
    //  console.log(param?.id);
    dispatch(getBranchesBySubsidiary(param?.id));
    setDetails((prev) => {
      return { ...prev, branch_id: {} };
    });
    setSingleDept((prev) => {
      return { ...prev, branch_id: {} };
    });
  };

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      const id = localStorage.getItem("department_id");
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      dispatch(getBranches(actionCtx?.activeOrg?.id));
      dispatch(getUsers(actionCtx?.activeOrg?.id));
      setDetails((prev) => {
        return { ...prev, subsidiary_id: "", branch_id: "" };
      });
      hanldGetDepartment(id);
    }
    // eslint-disable-next-line
  }, [actionCtx.setActiveOrgManual, refreshTable]);

  const hanldGetDepartment = async (id) => {
    const data = await dispatch(getDepartment(id));
    if (data?.payload?.success) {
      const deptDetail = data?.payload?.data?.department;
      const newDetails = {
        ...deptDetail,
        branch_id: {
          label: deptDetail?.branch?.name,
          value: deptDetail?.branch?.id,
        },
        subsidiary_id: {
          ...deptDetail?.branch?.subsidiary,
          label: deptDetail?.branch?.subsidiary?.name,
          value: deptDetail?.branch?.subsidiary?.id,
        },
        head_of_department_id: {
          value: deptDetail?.head_of_department?.id,
          label: deptDetail?.head_of_department?.full_name,
        },
        submit_to_id: {
          value: deptDetail?.submit_to?.id,
          label: deptDetail?.submit_to?.full_name,
        },
      };
      // console.log(newDetails);
      // console.log( deptDetail?.branch?.subsidiary);
      handleGetBranchBySub(deptDetail?.branch?.subsidiary);
      setSingleDept(newDetails);
    }
  };
  const [updateLoader, setUpdateLoader] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      id: singleDept?.id,
      name: details?.name || singleDept?.name,
      subsidiary_id:
        details?.subsidiary_id?.value || singleDept?.subsidiary_id?.value,
      branch_id: details?.branch_id?.value || singleDept?.branch_id?.value,
      department_code: details?.department_code || singleDept?.department_code,
      head_of_department_id:
        details?.head_of_department_id?.value ||
        singleDept?.head_of_department_id.value,
      submit_to_id:
        details?.submit_to_id?.value || singleDept?.submit_to_id.value,
      description: details?.description || singleDept?.description,
    };
    setUpdateLoader(true);
    const data = await dispatch(updateDepartment(obj));
    if (Object.keys(singleDept?.branch_id).length === 0) {
      toast.error("Please select a branch", {
        theme: "colored",
      });
    } else if (data?.payload?.success) {
      // dispatch(updateDepartment(obj));
      setUpdateLoader(false);
      history.push("dashboard-administration-admin-settings-department");
    } else {
      setUpdateLoader(false);
    }
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 4 of 6`}
        pageTitle={`Edit Department`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-department"
              )
            }
            className="text"
          >
            Departments
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Edit Department</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box"></div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* wrap start */}
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Department Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Name" className="label">
                    Department Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    // placeholder="Department Name"
                    className={`input ${details.name && "input-active"}`}
                    id="Name"
                    name="name"
                    onChange={handleChange}
                    value={singleDept?.name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      actionCtx?.activeOrg?.type !== "group" && "disable-select"
                    } ${details.subsidiary_id && "select-wrap-active"}`}
                    // placeholder="Sync with system settings"
                    isDisabled={actionCtx?.activeOrg?.type !== "group"}
                    value={
                      actionCtx?.activeOrg?.type !== "group"
                        ? subsidiariesDrop || singleDept?.subsidiary_id
                        : singleDept?.subsidiary_id
                    }
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                      setSingleDept((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                      handleGetBranchBySub(e);
                    }}
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    // isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Branch" className="label">
                    Branch <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.branch_id && "select-wrap-active"
                    }`}
                    // placeholder="Sync with system settings"
                    value={singleDept?.branch_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, branch_id: e };
                      });
                      setSingleDept((prev) => {
                        return { ...prev, branch_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={branchSubDrop}
                    // isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Department Code" className="label">
                    Department Code <span>*</span>
                  </label>
                  <input
                    name="department_code"
                    onChange={handleChange}
                    value={singleDept?.department_code}
                    type="text"
                    // placeholder="001"
                    className={`input ${
                      details.department_code && "input-active"
                    }`}
                    id="Department Code"
                  />
                </div>
                {/* group box end */}
              </div>

              <div
                style={{
                  gridTemplateColumns: "1fr",
                  margin: "3rem 0rem 0rem 0rem",
                  gridTemplateRows: "1fr",
                }}
                className="form-wrap"
              >
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Department Code" className="label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    value={singleDept?.description}
                    onChange={handleChange}
                    //   name=""
                    style={{
                      width: "100%",
                      resize: "none",
                      textTransform: "unset",
                    }}
                    id=""
                    cols="30"
                    rows="10"
                    // placeholder="Dedicated support to the product development and product inquiries"
                    className={`input ${details.description && "input-active"}`}
                  ></textarea>
                </div>
                {/* group box end */}
              </div>
              {/* another warpa start */}

              <div className="wrapper">
                <p className="box-title">2. Department Personnel</p>
                <div className="form-wrap" style={{ gridTemplateRows: "1fr" }}>
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="head of dept" className="label">
                      Set head of department <span>*</span>
                    </label>
                    <Select
                      className={`select-wrap ${
                        details.head_of_department_id && "select-wrap-active"
                      }`}
                      // placeholder="Sync with system settings"
                      value={singleDept?.head_of_department_id}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, head_of_department_id: e };
                        });
                        setSingleDept((prev) => {
                          return { ...prev, head_of_department_id: e };
                        });
                      }}
                      styles={reactSelectStyle}
                      options={usersDrop}
                      // isLoading={loading}
                    />
                  </div>
                  {/* group box end */}
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="submit to" className="label">
                      Submit to
                      <figure
                        className="img-box"
                        style={{
                          filter: "saturate(0%)",
                          transform: "translateX(.5rem) translateY(.3rem)",
                        }}
                      >
                        <img src={noteIcon} alt="" className="img" />
                      </figure>
                    </label>
                    <Select
                      className={`select-wrap ${
                        details.head_of_department_id && "select-wrap-active"
                      }`}
                      // placeholder="Sync with system settings"
                      value={singleDept?.submit_to_id}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, submit_to_id: e };
                        });
                        setSingleDept((prev) => {
                          return { ...prev, submit_to_id: e };
                        });
                      }}
                      styles={reactSelectStyle}
                      options={usersDrop}
                      // isLoading={loading}
                    />
                  </div>
                  {/* group box end */}
                </div>
              </div>
              {/* another wrap end */}
            </div>
            {/* wrap end */}
            {/* <div className="note-img-wrap">
                Important Notice:
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
              </div> */}
            {/* note box start */}
            {/* <div className="note-box">
                <p>
                  1. For organizations with shared budgets, you would need to
                  have created at least two subsidiaries to set up a shared
                  services
                </p>
              </div> */}
            {/* note box end */}

            {/* btn box start */}
            {!updateLoader ? (
              <input
                disabled={
                  !details?.name &&
                  !details?.branch_id &&
                  !details?.subsidiary_id &&
                  // !details?.organization_id &&
                  !details?.department_code &&
                  !details?.description &&
                  !details?.head_of_department_id &&
                  !details?.submit_to_id
                }
                type="submit"
                value="Save & Continue"
                className={`form-group form-submit ${
                  details.name ||
                  details.branch_id ||
                  details.subsidiary_id ||
                  // details.organization_id ||
                  details.department_code ||
                  details.description ||
                  details.head_of_department_id ||
                  details.submit_to_id
                    ? "form-submit-active"
                    : !singleDept?.branch_id
                    ? ""
                    : ""
                }`}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* btn box end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default EditDepartment;
