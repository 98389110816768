import React, { useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
// import imgOne from "../../../../../../assets/hod-two-img.svg";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import { approveExpenditureProjection } from "../../../../../../redux/projections/ExpenditureProjection";

const ApprovalModal = ({ onCancel, detail, onFinish }) => {
  const [comments, setComments] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.expenditure_projection_chart
  );
  const handleApprove = async () => {
    const obj = {
      organization_id: detail?.organization?.id,
      department_id: detail?.department?.id,
      // subsidiary_id: 17,
      // projection_type: detail?.projection_type, 
      projection_type: "expenditure_projection",
      comment: comments,
      status: "approved",
      projection_id: detail?.id,
    };
    // console.log(obj);
    const data = await dispatch(approveExpenditureProjection(obj));

    if (data?.payload?.success) {
      setComments("");
      onFinish();
    }

    // console.log(detail, "details");
  };
  return (
    <div style={{ width: "55rem" }} className="overview-card-modal-wrap">
      <div className="top-box">
        <p className="title">
          {`Approve (${detail?.chart_of_account?.name})`}{" "}
        </p>
      </div>
      <div className="content-box">
        <div className="img-input-wrap">
          {detail?.createdBy?.profile_picture && (
            <figure
              style={{
                marginRight: "1rem",
                width: "2.5rem",
                height: "2.5rem",
              }}
              className="img-box"
            // style={{width: "2.5rem", height: "2.5rem"}}
            >
              <img
                src={detail?.createdBy?.profile_picture}
                alt=""
                className="img"
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            </figure>
          )}
          <textarea
            placeholder="type here your comment"
            style={{ resize: "unset" }}
            value={comments}
            onChange={(e) => setComments(e.target?.value)}
            name=""
            id=""
            cols="30"
            rows="5"
            className="input-text"
          ></textarea>
        </div>
      </div>
      <div className="bottom-box">
        <ButtonBlue onClick={onCancel} text={`Cancel`} />
        {!loading ? (
          <ButtonBlue onClick={handleApprove} text={`Yes, Approve `} />
        ) : (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="70"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovalModal;
