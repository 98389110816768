import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { createClass } from "../../../../../../redux/budget-setting/expenditure-projection/class";
import CommonInputModal from "./CommonInputModal";

const AddBudgetExpClassModal = ({ onCancel, onFinish }) => {
  const { loading } = useSelector((state) => state.classes);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    organization_id: "",
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...details,
      organization_id: actionCtx?.activeOrg?.id,
    };
    const data = await dispatch(createClass(obj));
    if (data?.payload?.success) {
      setDetails({
        organization_id: "",
        name: "",
        description: "",
      });
      onFinish()
    }
  };
  return (
    <>
      <CommonInputModal
        onCancel={onCancel}
        title={`New Class`}
        btnText={`Save`}
        loading={loading}
        btnActive={details?.description && details?.name}
        onBtnClick={handleSubmit}
        btnDisable={!details?.description || !details?.name}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={details.name}
            type="text"
            placeholder="Name expense class"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description <span>*</span>
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
            value={details.description}
            type="text"
            placeholder="describe your expenditure class"
          ></textarea>
        </div>
        {/* group box end */}
      </CommonInputModal>
    </>
  );
};

export default AddBudgetExpClassModal;
