import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../../helper/Helpers";
// import { addLabelValueFunc, formatDate } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getAdminBudgetVarianceReport = createAsyncThunk(
  "admin/get-budget-variance-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/report/details?report_type=${
          formData?.report_type || ""
        }&organization_id=${formData?.org_id || ""}&currency_conversion_id=${
          formData?.cur_id || ""
        }&status=${formData?.status || ""}&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setAdminBudgetVarianceReports(data?.data?.budget_variance)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAdminBudgetVarianceReportPagination = createAsyncThunk(
  "admin/get-budget-variance-report-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/report/details?report_type=${
          formData?.report_type || ""
        }&organization_id=${formData?.org_id || ""}&page=${
          formData?.page
        }&currency_conversion_id=${formData?.cur_id || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      // /api/employee/reports/consolidated-report?page=2
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setAdminBudgetVarianceReports(data?.data?.budget_variance)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Bad Network, check your network settings", {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const admin_budget_variance_report = createSlice({
  name: "admin_budget_variance_report",
  initialState: {
    adminBudgetVarianceReports: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
  },

  reducers: {
    setAdminBudgetVarianceReports: (state, action) => {
      state.isAuth = true;
      state.adminBudgetVarianceReports = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getAdminBudgetVarianceReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminBudgetVarianceReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminBudgetVarianceReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getAdminBudgetVarianceReportPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminBudgetVarianceReportPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminBudgetVarianceReportPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAdminBudgetVarianceReports } =
  admin_budget_variance_report.actions;

export default admin_budget_variance_report.reducer;
