import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeCommonExpenseLayout from "../expense/CommonLayout";
import Modal from "react-awesome-modal";
// import EmployeeProjectionComentModal from "../revenue/modals/comment";
// import EmployeeRejectCommentModal from "../../actual/modals/rejectComment";
// import EmployeeOverviewActualReinbursementModal from "../../home/modals/Reimbursement/EmployeeOverviewModal";
import {
  getAllExpenditureProjectionComment,
  getApprovedExpenditureProjectionById,
  getApprovedExpenditureProjectionByIdPagination,
  getApprovedExpenditureProjectionSubmittedBySearch,
  getUserDepartment,
  // getApprovedExpenditureProjectionById,\
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { useDispatch, useSelector } from "react-redux";
import EmployeeDraftProjection from "./EmpDraftProjection";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import useDebounce from "../../../../../../helper/UseDebounce";
import EmpExpApprovedProjectionOverview from "./modals/approveOverview";
import EmpExpProRejectModal from "./modals/rejectModal";
import EmpExpProjectionComentModal from "./modals/comments";
import ActionContext from "../../../../../../context/ActionContext";
import moment from "moment";

const EmployeeExpenditureApproved = () => {
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    comment: false,
    approveOverview: false,
    reject: false,
  });

  const history = useHistory();

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Units",
    "Unit Price",
    "Date",
    "status",
    // "Action",
  ];

  const cardHeader = [
    "Expenditure Projected",
    "Approved",
    "Pending",
    "Rejected",
  ];
  const [singleItem, setSingleItem] = useState({});
  const cardValue = [
    singleItem?.total_projection || 0,
    singleItem?.approved || 0,
    singleItem?.awaiting_approval || 0,
    singleItem?.rejected || 0,
  ];

  // const handleGetDeptId = () =

  const [refreshTable] = useState(false);

  const { loadingPage, expenditureProjectionById } = useSelector(
    (state) => state.expenditure_projection
  );

  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);

  const [dept, setDept] = useState({ label: "Select Dept..." });
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    handleGetExpenditureData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, user?.organizations[0]?.id, dept]);

  const dispatch = useDispatch();

  const handleGetExpenditureData = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: dept?.id,
      filter_submitted: true,
      status: "approved",
    };
    const data = await dispatch(getApprovedExpenditureProjectionById(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };

  const [singleChild, setSingleChild] = useState({});

  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart Of Account", value: "chart_of_account" },
    { label: "Class", value: "class" },
    { label: "Description", value: "description" },
    { label: "Unit", value: "unit" },
    { label: "Unit Price", value: "unit_price" },
  ];

  const SortByOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
  ];

  const link = [
    "/employee-dashboard-projection-expenditure-new",
    "/employee-dashboard-projection-expenditure-submitted",
    "/employee-dashboard-projection-expenditure-submitted-approved",
    "/employee-dashboard-projection-expenditure-submitted-pending",
    "employee-dashboard-projection-expenditure-submitted-rejected",
  ];

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      status: "approved",
      dept_id: dept?.id,
    };
    // console.log("linkTwo", obj);
    dispatch(getApprovedExpenditureProjectionByIdPagination(obj));
  };

  const handleSortChange = (param) => {
    const obj = {
      // q: search,
      sort: param?.value,
      is_submitted: 1,
      dept_id: dept?.id,
      status: "approved",
      org_id: user?.organizations[0]?.id,
    };
    // console.log("obj", obj)
    dispatch(getApprovedExpenditureProjectionSubmittedBySearch(obj));
  };

  const handleGetCommentData = (param) => {
    // setSingleChild(param);
    dispatch(getAllExpenditureProjectionComment(param));
  };

  const [search, setSearch] = useState("");
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const [sortByVal, setSortByVal] = useState({ label: "Sort by" });

  const debouncedSearchTerm = useDebounce(search, 1000);

  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        org_id: user?.organizations[0]?.id,
        search: search,
        dept_id: dept?.id,
        is_submitted: 1,
        status: "approved",
        filter_by: filterByVal?.value,
      };
      dispatch(getApprovedExpenditureProjectionSubmittedBySearch(obj));
    } else {
      handleGetExpenditureData();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const handleSingleCommentData = (param) => {
    setSingleChild(param);
    if (param) {
      dispatch(getAllExpenditureProjectionComment(param?.id));
    }
  };

  // const getSubmitDeptId = localStorage.getItem(
  //   "user-expenditure-submit-dept-id"
  // );
  // const getPendingDeptId = localStorage.getItem(
  //   "user-pending-expenditure-dept-id"
  // );
  // localStorage.setItem("user-approved-expenditure-dept-id", dept?.id);

  return (
    <>
      <EmployeeCommonExpenseLayout approveLink={true}>
        <EmployeeReusableTable
          dept
          departmentDrop={userDepartmentDrop}
          deptVal={dept}
          setDepartmentVal={(e) => {
            setDept(e);
          }}
          sort={true}
          filter={true}
          optionSort={SortByOptions}
          optionSortVal={sortByVal}
          setOptionSortVal={(e) => {
            setSortByVal(e);
            handleSortChange(e);
          }}
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          addButton={expenditureProjectionById?.data?.length > 0}
          BtnTitle={`New Expenditure`}
          onPage={() => history.push(link[0])}
          firstCard
          secondCard
          thirdCard
          loading={loadingPage}
          fourthCard
          showSearch
          cardTitleOne={cardHeader[0]}
          cardTitleTwo={cardHeader[1]}
          cardTitleThree={cardHeader[2]}
          cardTitleFour={cardHeader[3]}
          cardBodyOne={formatNumber(
            String(
              FormatNumWithCurrency(
                cardValue[0],
                actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
                actionCtx?.currencyValue
              ) || 0
            )
          )}
          cardBodyTwo={cardValue[1]}
          cardBodyThree={cardValue[2]}
          cardBodyFour={cardValue[3]}
          linkCardTwo={link[2]}
          // onPage={() => history.push(link[0])}
          onFirstCard={() => history.push(link[1])}
          onSecondCard={() => history.push(link[2])}
          onThirdCard={() => history.push(link[3])}
          onFourthCard={() => history.push(link[4])}

          // onCard={onApprove}
        />
        <ActualReuseableTable
          dontShowPagination={expenditureProjectionById?.data?.length < 1}
          dontShowBnSearch
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          six={tableHeadList[5]}
          seven={tableHeadList[6]}
          nextPage={expenditureProjectionById?.next_page_url}
          prevPage={expenditureProjectionById?.prev_page_url}
          fromPage={expenditureProjectionById?.from}
          toPage={expenditureProjectionById?.to}
          totalPage={expenditureProjectionById?.total}
          onNextPage={() =>
            handlePagination(expenditureProjectionById?.next_page_url)
          }
          onPrevPage={() =>
            handlePagination(expenditureProjectionById?.prev_page_url)
          }
          action
        >
          {expenditureProjectionById?.data?.length > 0 ? (
            <>
              {expenditureProjectionById?.data.map((chi, idx) => {
                const {
                  chart_of_account,
                  description,
                  units,
                  unit_price,
                  date,
                  status,
                } = chi;
                return (
                  <EmpTableBodyRow
                    item={chi}
                    key={idx}
                    one={chart_of_account?.name}
                    two={chart_of_account?.class}
                    three={description}
                    four={formatNumber(String(units) || 0)}
                    five={formatNumber(
                      String(
                        FormatNumWithCurrency(
                          unit_price,
                          actionCtx?.activeOrg?.active_fiscal_year
                            ?.base_currency?.name,
                          actionCtx?.currencyValue
                        ) || 0
                      )
                    )}
                    six={moment(date).format("YYYY-MM-DD")}
                    progressType={
                      status === "approved"
                        ? "accept"
                        : status === "rejected"
                        ? "reject"
                        : "pending"
                    }
                    loading={loadingPage}
                    action
                    rowNo={idx}
                    indexNo={indexNo}
                    setIndexNo={() => setIndexNo(idx)}
                    // updateTextTwo={`Update`}
                    // approveText={"Approve"}
                    viewText={"View Comments"}
                    // onUpdateTwo={() => handleExpenditureProjectionUpdate(chi)}
                    onView={() => {
                      // eslint-disable-next-line
                      {
                        actionCtx?.setIsModalOut(true);
                        handleSingleCommentData(chi);
                        setShowModal((prev) => {
                          return { ...prev, comment: true };
                        });
                        // setTimeout(() => {
                        // }, 1000);
                      }
                    }}
                    onAcceptStatus={() => {
                      actionCtx?.setIsModalOut(false);
                      setSingleChild(chi);
                      setShowModal((prev) => {
                        return { ...prev, approveOverview: true };
                      });
                    }}
                    // deleteTextTwo={
                    //   user?.permissions?.includes(
                    //     "approve/reject expense projection"
                    //   ) && `Reject`
                    // }
                    onDeleteTwo={() => {
                      actionCtx?.setIsModalOut(true);
                      setSingleChild(chi);
                      setShowModal((prev) => {
                        return { ...prev, reject: true };
                      });
                    }}
                    exportText={`Export as PDF`}
                    // onAcceptStatus={() => {
                    //   setShowModal((prev) => {
                    //     return { ...prev, approveOverview: true };
                    //   });
                    // }}
                  />
                );
              })}
            </>
          ) : (
            <EmployeeDraftProjection>
              <ButtonBlue
                onClick={() =>
                  history.push("/employee-dashboard-projection-expenditure-new")
                }
                text={`Create Expenditure`}
              >
                <FaPlus style={{ marginRight: "15px" }} />
              </ButtonBlue>
            </EmployeeDraftProjection>
          )}
        </ActualReuseableTable>
      </EmployeeCommonExpenseLayout>

      {/* modal end */}
      <Modal visible={showModal.comment}>
        <EmpExpProjectionComentModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, comment: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmpExpProRejectModal
          details={singleChild}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false };
            })
          }
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
          }}
          onRefreshCard={handleGetExpenditureData}
          onDispatch={() => {
            const obj = {
              org_id: user?.organizations[0]?.id,
              filter_submitted: true,
              status: "approved",
            };
            dispatch(getApprovedExpenditureProjectionById(obj));
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmpExpApprovedProjectionOverview
          // update
          details={singleChild}
          data={singleChild}
          // onUpdate={() => {
          //   localStorage.setItem(
          //     "single-expenditure-projection-id",
          //     singleChild?.id
          //   );
          //   history.push("/employee-dashboard-projection-expenditure-update");
          // }}
          // rejectText={user?.permissions?.includes(
          //   "approve/reject expense projection"
          // )}
          onReject={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, reject: true, approveOverview: false };
            });
          }}
          onComment={() => {
            // console.log(singleChild?.id);
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          // onApprove={() =>
          //   setShowModal((prev) => {
          //     return { ...prev, approve: true, approveOverview: false };
          //   })
          // }
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal end */}
    </>
  );
};

export default EmployeeExpenditureApproved;
