import React, { useContext, useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import imgOne from "../../../../../../assets/hod-two-img.svg";
import ActionContext from "../../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import { approveAllProjection } from "../../../../../../redux/projections/ExpenditureProjection";
import { Dna } from "react-loader-spinner";

const FinalApprovalModal = ({ onCancel, allId, onFinish }) => {
  const [comments, setComments] = useState("");
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.expenditure_projection_chart
  );

  const handleApproveAll = async () => {
    const obj = {
      // organization_id: detail?.organization?.id,
      // department_id: detail?.department?.id,
      // subsidiary_id: 17,
      // projection_type: detail?.projection_type,
      org_id: actionCtx?.activeOrg?.id,
      projection_type: "supplementary",
      comment: comments,
      status: "approved",
      ids: allId,
    };
    const data = await dispatch(approveAllProjection(obj));
    if (data?.payload?.success) {
      onFinish();
    }
  };
  return (
    <div style={{width: "55rem"}} className="overview-card-modal-wrap">
      <div className="top-box">
        <p className="title">Do you wish to approve all?</p>
      </div>
      <div className="content-box">
        <div className="img-input-wrap">
          <figure className="img-box">
            <img src={imgOne} alt="" className="img" />
          </figure>
          <textarea
            placeholder="type here your comment"
            style={{ resize: "unset" }}
            name=""
            id=""
            cols="30"
            rows="5"
            value={comments}
            onChange={(e) => setComments(e.target?.value)}
            className="input-text"
          ></textarea>
        </div>
      </div>
      <div className="bottom-box">
        <ButtonBlue onClick={onCancel} text={`Cancel`} />
        {!loading ? (
          <ButtonBlue
            bgColor={allId?.length < 1 && "#cccccc"}
            onClick={() => allId.length > 0 && handleApproveAll()}
            text={allId?.length > 0 ? `Yes, Approve All ` : "No Budget to approve"}
          />
        ) : (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="70"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalApprovalModal;
