import homeImg from "../../../assets/home-icon.svg";
// import allUserImg from "../../../assets/all-users-icon.svg";
import expenditureImg from "../../../assets/expenditure-icon.svg";
import revenueImg from "../../../assets/revenue-icon.svg";
import supplementImg from "../../../assets/supplementary.svg";
// import integrationImg from "../../../assets/integration-icon.svg";
import supportImg from "../../../assets/support-icon.svg";
import adminSetImg from "../../../assets/admin-setting-icon.svg";
// import approvalImg from "../../../assets/approval-icon.svg";
import reportImg from "../../../assets/report-icon.svg";

export const employeeSidebarList = [
  {
    title: "Main Menu",
    drop: "one",
    route: "/employee-dashboard-home",
    links: [
      { name: "Home", link: "/employee-dashboard-home", img: homeImg },
      // { name: "All Users", link: "/employee-dashboard-home-all-users", img: allUserImg},
    ],
  },
  {
    title: "Projection",
    drop: "two",
    route: "/employee-dashboard-projection",
    links: [
      {
        name: "Expenditure",
        link: "/employee-dashboard-projection-expenditure",
        img: expenditureImg,
      },
      {
        name: "Revenue",
        link: "/employee-dashboard-projection-revenue",
        img: revenueImg,
      },
    ],
  },
  {
    title: "Actual",
    drop: "three",
    route: "/employee-dashboard-actual",
    links: [
      {
        name: "Expense",
        link: "/employee-dashboard-actual-expense",
        img: expenditureImg,
      },
      {
        name: "Revenue",
        link: "/employee-dashboard-actual-revenue",
        img: revenueImg,
      },
      {
        name: "Supplementary Budget",
        link: "/employee-dashboard-actual-supplementary-budget",
        img: supplementImg,
      },
    ],
  },
  {
    title: "General",
    drop: "four",
    route: "/employee-dashboard-general",
    links: [
      // { name: "Approvals", link: "/employee-dashboard-general-approvals", img: approvalImg },
      {
        name: "Report",
        link: "/employee-dashboard-general-report",
        img: reportImg,
      },
      // { name: "Integration", link: "/employee-dashboard-general-integration", img: integrationImg},
    ],
  },
  {
    title: "External",
    route: "/employee-dashboard-administration",
    drop: "five",
    links: [
      {
        name: "Support",
        link: "/employee-dashboard-administration-support",
        img: supportImg,
      },
      {
        name: "Settings",
        link: "/employee-dashboard-administration-admin-settings",
        img: adminSetImg,
      },
    ],
  },
];

