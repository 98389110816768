/* eslint-disable no-lone-blocks */
import React, { useContext } from "react";
import "../../../../styles/dashboard/SidebarMenu.css";
import revvexLogo from "../../../../assets/revvex-white-logo.svg";
import { employeeSidebarList } from "../../../context/data/EmpSidebarData";
import {
  FaAngleDown,
  FaAngleRight,
  FaAngleUp,
  FaLayerGroup,
  FaRandom,
} from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import ActionContext from "../../../../context/ActionContext";
import revvexSmallLogo from "../../../../assets/revex-small-logo.svg";
import EmployeeSupportDropDown from "../../pages/dashboard/dropdown/supportdropdown";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
// import { useState } from "react";

const EmployeeSidebarMenu = () => {
  const actionCtx = useContext(ActionContext);
  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const { plan_details } = useSelector((state) => state.users);

  const getDaysLeft = (param) => {
    const currentDate = moment();
    const targetDate = moment(param);

    const daysLeft = targetDate.diff(currentDate, "days");

    return daysLeft;
  };

  const handleLinkPush = (link) => {
    if (plan_details?.active_plan_details?.status === "active") {
      history.push(link);
    } else {
      toast.info("");
    }
  };
  // const [showSupportDropdown, setShowSupportDropdown] = useState(false);

  return (
    <div className="sidebar_menu_layout">
      {/* <div
        className={`emp-support-dropdown-wrap ${
          showSupportDropdown && "support-dropdown-show"
        }`}
      >
        <EmployeeSupportDropDown />
      </div> */}

      {/* logo box start */}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://revvex.io"
        className="logo-box"
      >
        {!actionCtx.isSidebarReduce && (
          <figure className="img-box">
            <img src={revvexLogo} alt="" className="img" />
          </figure>
        )}
        {actionCtx.isSidebarReduce && (
          <figure className="img-box img-box-small">
            <img src={revvexSmallLogo} alt="" className="img" />
          </figure>
        )}
      </a>
      {/* logo box end */}
      {/* role box stqrt */}
      <div className="user-role-box">
        <p className="text">
          {/* {user?.role === "creator administrator" ? "ADMIN DASHBOARD" : "EMPLOYEE DASHBOARD"} */}
          EMPLOYEE DASHBOARD
        </p>
      </div>
      {/* role box end */}
      {/* list box start */}
      <div className="list-box">
        {employeeSidebarList.map((child, idx) => {
          const { title, links, route, drop } = child;
          if (drop === "one") {
            return (
              <div
                key={idx}
                className={`item ${
                  location.pathname.includes(route) && "item-active"
                } ${actionCtx.isSidebarReduce && "item-reduce"}`}
              >
                <div
                  className="parent-box"
                  onClick={() => actionCtx.sidebarDropdownFunc(drop)}
                >
                  <p>{title}</p>
                  {actionCtx.sidebarDropdown.one && (
                    <FaAngleUp className="drop-icon" />
                  )}
                  {!actionCtx.sidebarDropdown.one && (
                    <FaAngleDown className="drop-icon" />
                  )}
                </div>
                {/* {actionCtx.sidebarDropdown.one && ( */}
                <div
                  className={`children-box ${
                    actionCtx.sidebarDropdown.one && "children-box-show"
                  }`}
                >
                  {links.map((chid, index) => {
                    const { name, link, img } = chid;
                    return (
                      <div
                        key={index}
                        className={`child ${
                          location.pathname.includes(link) && "child-active"
                        } ${actionCtx.isSidebarReduce && "child-reduce"}`}
                        onClick={() => history.push(link)}
                      >
                        <figure className="img-box">
                          <img src={img} alt="" className="img" />
                        </figure>
                        {!actionCtx.isSidebarReduce && (
                          <p className="child-text">{name}</p>
                        )}
                        {actionCtx.isSidebarReduce && (
                          <p className="child-text-reduce">{name}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* )} */}
              </div>
            );
          }
          if (drop === "two") {
            return (
              <div
                key={idx}
                className={`item ${
                  location.pathname.includes(route) && "item-active"
                } ${actionCtx.isSidebarReduce && "item-reduce"}`}
              >
                <div
                  className="parent-box"
                  onClick={() => actionCtx.sidebarDropdownFunc(drop)}
                >
                  <p>{title}</p>
                  {actionCtx.sidebarDropdown.two && (
                    <FaAngleUp className="drop-icon" />
                  )}
                  {!actionCtx.sidebarDropdown.two && (
                    <FaAngleDown className="drop-icon" />
                  )}
                </div>
                {/* {actionCtx.sidebarDropdown.two && ( */}
                <div
                  className={`children-box ${
                    actionCtx.sidebarDropdown.two && "children-box-show"
                  }`}
                >
                  {links.map((chid, index) => {
                    const { name, link, img } = chid;
                    return (
                      <div
                        key={index}
                        className={`child ${
                          location.pathname.includes(link) && "child-active"
                        } ${actionCtx.isSidebarReduce && "child-reduce"}`}
                        onClick={() => history.push(link)}
                      >
                        <figure className="img-box">
                          <img src={img} alt="" className="img" />
                        </figure>
                        {!actionCtx.isSidebarReduce && (
                          <p className="child-text">{name}</p>
                        )}
                        {actionCtx.isSidebarReduce && (
                          <p className="child-text-reduce">{name}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* )} */}
              </div>
            );
          }
          if (drop === "three") {
            return (
              <div
                key={idx}
                className={`item ${
                  location.pathname.includes(route) && "item-active"
                } ${actionCtx.isSidebarReduce && "item-reduce"}`}
              >
                <div
                  className="parent-box"
                  onClick={() => actionCtx.sidebarDropdownFunc(drop)}
                >
                  <p>{title}</p>
                  {actionCtx.sidebarDropdown.three && (
                    <FaAngleUp className="drop-icon" />
                  )}
                  {!actionCtx.sidebarDropdown.three && (
                    <FaAngleDown className="drop-icon" />
                  )}
                </div>
                {/* {actionCtx.sidebarDropdown.three && ( */}
                <div
                  className={`children-box ${
                    actionCtx.sidebarDropdown.three && "children-box-show"
                  }`}
                >
                  {links.map((chid, index) => {
                    const { name, link, img } = chid;
                    return (
                      <div
                        key={index}
                        className={`child ${
                          location.pathname.includes(link) && "child-active"
                        } ${actionCtx.isSidebarReduce && "child-reduce"}`}
                        onClick={() => history.push(link)}
                      >
                        <figure className="img-box">
                          <img src={img} alt="" className="img" />
                        </figure>
                        {!actionCtx.isSidebarReduce && (
                          <p className="child-text">{name}</p>
                        )}
                        {actionCtx.isSidebarReduce && (
                          <p className="child-text-reduce">{name}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* )} */}
              </div>
            );
          }
          if (drop === "four") {
            return (
              <div
                key={idx}
                className={`item ${
                  location.pathname.includes(route) && "item-active"
                } ${actionCtx.isSidebarReduce && "item-reduce"}`}
              >
                <div
                  className="parent-box"
                  onClick={() => actionCtx.sidebarDropdownFunc(drop)}
                >
                  <p>{title}</p>
                  {actionCtx.sidebarDropdown.four && (
                    <FaAngleUp className="drop-icon" />
                  )}
                  {!actionCtx.sidebarDropdown.four && (
                    <FaAngleDown className="drop-icon" />
                  )}
                </div>
                {/* {actionCtx.sidebarDropdown.four && ( */}
                <div
                  className={`children-box ${
                    actionCtx.sidebarDropdown.four && "children-box-show"
                  }`}
                >
                  {links.map((chid, index) => {
                    const { name, link, img } = chid;
                    return (
                      <div
                        key={index}
                        className={`child ${
                          location.pathname.includes(link) && "child-active"
                        } ${actionCtx.isSidebarReduce && "child-reduce"}`}
                        onClick={() => history.push(link)}
                      >
                        <figure className="img-box">
                          <img src={img} alt="" className="img" />
                        </figure>
                        {!actionCtx.isSidebarReduce && (
                          <p className="child-text">{name}</p>
                        )}
                        {actionCtx.isSidebarReduce && (
                          <p className="child-text-reduce">{name}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* )} */}
              </div>
            );
          }
          if (drop === "five") {
            return (
              <div
                key={idx}
                className={`item ${
                  location.pathname.includes(route) && "item-active"
                } ${actionCtx.isSidebarReduce && "item-reduce"}`}
              >
                <div
                  className="parent-box"
                  onClick={() => actionCtx.sidebarDropdownFunc(drop)}
                >
                  <p>{title}</p>
                  {actionCtx.sidebarDropdown.five && (
                    <FaAngleUp className="drop-icon" />
                  )}
                  {!actionCtx.sidebarDropdown.five && (
                    <FaAngleDown className="drop-icon" />
                  )}
                </div>
                {/* {actionCtx.sidebarDropdown.five && ( */}
                <div
                  className={`children-box ${
                    actionCtx.sidebarDropdown.five && "children-box-show"
                  }`}
                >
                  {links.map((chid, index) => {
                    const { name, link, img } = chid;
                    return (
                      <div
                        key={index}
                        className={`child ${
                          location.pathname.includes(link) && "child-active"
                        } ${actionCtx.isSidebarReduce && "child-reduce"}`}
                        onClick={() => {
                          name === "Support" &&
                            actionCtx.setShowSupportDropDown("any");
                          {
                            name === "Settings" && history.push(link);
                          }
                        }}
                      >
                        <figure className="img-box">
                          <img src={img} alt="" className="img" />
                        </figure>
                        {!actionCtx.isSidebarReduce && (
                          <p className="child-text">{name}</p>
                        )}
                        {actionCtx.isSidebarReduce && (
                          <p className="child-text-reduce">{name}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* )} */}
              </div>
            );
          }
          return <React.Fragment key={idx}></React.Fragment>;
        })}
      </div>

      {/* list box end */}
      {/* upgrade box start */}
      {/* {getDaysLeft(plan_details?.active_plan_details?.end_date) < 5 && ( */}
      {/* <>
        {!actionCtx.isSidebarReduce &&
          user?.role === "creator administrator" && (
            <div className="upgrade-box">
              {getDaysLeft(plan_details?.active_plan_details?.end_date) > 1 && (
                <p className="text">{`There are ${getDaysLeft(
                  plan_details?.active_plan_details?.end_date
                )} day${
                  getDaysLeft(plan_details?.active_plan_details?.end_date) > 1
                    ? "s"
                    : ""
                } left in your `}</p>
              )}
              {getDaysLeft(plan_details?.active_plan_details?.end_date) ===
                1 && (
                <p className="text">Your subscription will expire tomorrow,</p>
              )}
              {getDaysLeft(plan_details?.active_plan_details?.end_date) < 1 && (
                <p className="text">Your subscription has expired.</p>
              )}
              <p className="text">
                {plan_details?.active_plan_details?.subscription_plan_details
                  ?.title || ""}
              </p>
              {user?.role === "creator administrator" && (
                <p
                  onClick={() => {
                    history.push(
                      "/dashboard-administration-admin-settings-billing"
                    );
                  }}
                  className="upgrade"
                >
                  Renew / Upgrade Plan
                </p>
              )}
            </div>
          )}
        {user?.role === "creator administrator" &&
          actionCtx.isSidebarReduce && (
            <div
              onClick={() => {
                history.push(
                  "/dashboard-administration-admin-settings-billing"
                );
              }}
              className="upgrade-wrap-reduce"
            >
              <FaLayerGroup className="upgrade-box-reduce" />
              <p className="text">Renew / upgrade plan</p>
            </div>
          )}
      </> */}

      <>
        {!actionCtx.isSidebarReduce &&
          user?.role === "creator administrator" && (
            <div className="upgrade-box">
              {getDaysLeft(plan_details?.active_plan_details?.end_date) >= 2 &&
                !plan_details?.active_plan_details?.is_trial && (
                  <p className="text">
                    Your plan {plan_details?.active_plan_details?.days_left}
                  </p>
                )}
              {getDaysLeft(plan_details?.active_plan_details?.end_date) < 2 &&
                !plan_details?.active_plan_details?.is_trial && (
                  <p className="text">Your subscription has expired.</p>
                )}
              <br />
              <p className="text">
                Plan :{" "}
                {plan_details?.active_plan_details?.subscription_plan_details
                  ?.title || ""}
              </p>
              {plan_details?.active_plan_details?.is_trial && (
                <p className="text">{"Free Trial Mode"}</p>
              )}
              {user?.role === "creator administrator" && (
                <p
                  onClick={() => {
                    history.push(
                      "/dashboard-administration-admin-settings-billing"
                    );
                  }}
                  className="upgrade"
                >
                  Renew / Upgrade Plan
                </p>
              )}
            </div>
          )}
        {actionCtx.isSidebarReduce &&
          user?.role === "creator administrator" && (
            <div
              onClick={() => {
                history.push(
                  "/dashboard-administration-admin-settings-billing"
                );
              }}
              className="upgrade-wrap-reduce"
            >
              <FaLayerGroup className="upgrade-box-reduce" />
              <p className="text">Renew / upgrade plan</p>
            </div>
          )}
      </>
      {/* )} */}

      {/* upgrade box end */}

      {/* switch box start */}
      {user?.role === "creator administrator" && !actionCtx.isSidebarReduce && (
        <button
          className="switch-btn"
          onClick={() => history.push("/dashboard-home")}
        >
          <p className="text">Switch to admin </p>
          <FaAngleRight className="icon" />
        </button>
      )}
      {user?.role === "creator administrator" && actionCtx.isSidebarReduce && (
        <div
          className="switch-btn-wrap-reduce"
          onClick={() => history.push("/dashboard-home")}
        >
          <FaRandom className="switch-btn-reduce" />
          <p className="text">switch to admin</p>
        </div>
      )}
      {/* switch box end */}
      {/* support drop down start */}
      <div
        className={`emp-support-dropdown-wrap ${
          actionCtx.showSupportDropDown && "support-dropdown-show"
        } ${
          actionCtx.isSidebarReduce && ` emp-support-dropdown-wrap-collapse`
        }`}
      >
        <EmployeeSupportDropDown />
      </div>
      {/* support drop down end */}
    </div>
  );
};

export default EmployeeSidebarMenu;
