import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc, customErrorId } from "../../../helper/Helpers";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getCategories = createAsyncThunk(
  "admin/get-categories",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/categories/organization/${formData}`
      );
        // console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setCategories(data.data.categories));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setCategoriesDrop(data.data.categories));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCategoriesByClass = createAsyncThunk(
    "admin/get-categories-by-class",
    async (formData, thunkAPI) => {
      try {
        const { data } = await axios.get(
          `/admin/categories/class/${formData}`
        );
          // console.log("branch", data);
        if (!data?.success) {
          // toast.error(data.message, {
          //   theme: "colored",
        // toastId: customErrorId,
          // });
          // await thunkAPI.dispatch(setCategories(data.data.categories));
          return thunkAPI.rejectWithValue(data);
        }
        if (data?.success) {
          await thunkAPI.dispatch(setCategoriesDrop(data.data.categories));
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        if (err) {
          return thunkAPI.rejectWithValue(err);
        }
      }
    }
  );

export const getCategoriesWithPagination = createAsyncThunk(
  "admin/get-categories-with-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/categories/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}`
      );
      //   console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setCategories(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setCategories(data.data.categories));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCategoriesByFilter = createAsyncThunk(
  "admin/get-categories-filter",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/admin/categories/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      // console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setCategories(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const classesInfo = data.data.categories;
        await thunkAPI.dispatch(setCategoriesFilter(classesInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCategoriesPagination = createAsyncThunk(
  "admin/get-categories-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        // await thunkAPI.dispatch(setCategories(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const classesInfo = data.data.categories;
        await thunkAPI.dispatch(setCategories(classesInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCategory = createAsyncThunk(
  "admin/get-category",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/classes/${formData}`);
      console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createCategory = createAsyncThunk(
  "admin/create-category",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/categories", formData);
      //   console.log('class', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        if(err?.response?.status === 422){
          toast.error(err?.response?.data?.errors.name[0], {
            theme: "colored",
        toastId: customErrorId,
          });
        }
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "admin/delete-category",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/categories/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateCategory = createAsyncThunk(
  "admin/update-category",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/categories/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const categories = createSlice({
  name: "categories",
  initialState: {
    categoryDrop: [],
    categories: [],
    links: {},
    meta: {},
    category: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setCategories: (state, action) => {
      state.isAuth = true;
      state.categories = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setCategoriesFilter: (state, action) => {
      state.isAuth = true;
      state.categories = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setCategoriesDrop: (state, action) => {
      state.isAuth = true;
      state.categoryDrop = addLabelValueFunc(action.payload);
    },
    setCategory: (state, action) => {
      state.isAuth = true;
      state.category = action.payload;
    },
  },
  extraReducers: {
    [createCategory.pending]: (state) => {
      state.loading = true;
    },
    [createCategory.fulfilled]: (state) => {
      state.loading = false;
    },
    [createCategory.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateCategory.pending]: (state) => {
      state.loading = true;
    },
    [updateCategory.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateCategory.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getCategoriesPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getCategoriesPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getCategoriesPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getCategoriesByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getCategoriesByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getCategoriesByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getCategoriesWithPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getCategoriesWithPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getCategoriesWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteCategory.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteCategory.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getCategoriesByClass.pending]: (state) => {
      state.loadingPage = true;
    },
    [getCategoriesByClass.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getCategoriesByClass.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCategory,
  setCategories,
  setCategoriesDrop,
  setCategoriesFilter,
} = categories.actions;

export default categories.reducer;
