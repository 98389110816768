import React from "react";
import { useContext } from "react";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../context/ActionContext";
import { TransferProfile } from "../../../../../redux/users/users";
import "../../../../../styles/dashboard/admin-settings/ProfileTransfer/ProfileTransfer.css";
import ButtonBlue from "../../../../buttons/ButtonBlue";

const ConfirmTransferModal = ({ onCancel, onFinish, detail }) => {
  const { loading } = useSelector((state) => state?.users);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const handleSubmit = async () => {
    let val;
    if (detail?.is_new_user) {
      const obj = {
        organization_id: actionCtx?.activeOrg?.id,
        transfer_from_email: detail?.transfer_from_email?.label,
        transfer_to_email: detail?.transfer_to_email,
        is_new_user: true,
      };
      val = obj;
    }
    if (!detail?.is_new_user) {
      const obj = {
        organization_id: actionCtx?.activeOrg?.id,
        transfer_from_email: detail?.transfer_from_email?.label,
        transfer_to_email: detail?.email?.label,
        is_new_user: false,
      };
      val = obj;
    }
    const data = await dispatch(TransferProfile(val));
    if (data?.payload?.success) {
      onFinish();
    }
  };
  return (
    <div className="profile-transfer-confirm-modal-wrap">
      <p className="title">Transfer Profile</p>
      <p className="text">
        By confirming transfer you are authorizing <span> {detail?.email?.label || detail?.transfer_to_email}</span> the
        permission to take control of <span>{detail?.transfer_from_email?.label}</span> account and have
        full access to all their activities and account history{" "}
      </p>
      {/* two btn start */}
      <div className="two-btn-box">
        <ButtonBlue onClick={onCancel} text={`Cancel`} bgColor={`#ffffff`} />
        {loading ? (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center" }}
          >
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue onClick={handleSubmit} text={`Yes Transfer`} />
        )}
      </div>
      {/* two btn end */}
    </div>
  );
};

export default ConfirmTransferModal;
