import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
// import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../../../helper/Helpers";
// import { addLabelValueFunc } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getEmployeeDashboard = createAsyncThunk(
  "employee/get-employee-dashboard",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/dasbord-menue?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&fiscal_year_id=${
          formData?.fiscal_year_id || ""
        }&user_id=${formData?.user_id || ""}&period=${
          formData?.period || ""
        }&currency_conversion_id=${formData?.currency_id || ""}
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setDashboardData(data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeNetProfitProjection = createAsyncThunk(
  "employee/get-employee-net-profit-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/net_profit_projection?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&user_id=${
          formData?.user_id || ""
        }&currency_conversion_id=${formData?.currency_id || ""}
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setNetProfitProjectionData(data?.data?.net_profit_project)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeActualGrossProfitProjection = createAsyncThunk(
  "employee/get-employee-actual-gross-profit-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/gross_profit_actual?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&currency_conversion_id=${
          formData?.currency_conversion_id || ""
        }
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setActualGrossProfitProjectionData(data?.data?.revenue_net_gp_table)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getEmployeeActualGrossProfitProjectionPagination =
  createAsyncThunk(
    "employee/get-employee-actual-gross-profit-projection",
    async (formData, thunkAPI) => {
      try {
        const { data } = await axios.get(
          `/employee/dashboard/gross_profit_actual?organization_id=${
            formData?.org_id || ""
          }&department_id=${formData?.dept_id || ""}&currency_conversion_id=${
            formData?.currency_conversion_id || ""
          }&page=${formData?.page || ""}
        `
        );
        // console.log(data, "-----------test------------");
        if (!data?.success) {
          return thunkAPI.rejectWithValue(data?.data);
        }
        if (data?.success) {
          await thunkAPI.dispatch(
            setActualGrossProfitProjectionData(data?.data?.revenue_net_gp_table)
          );
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        if (err) {
          return thunkAPI.rejectWithValue(err);
        }
      }
    }
  );

export const getEmployeeGpProjectionGraph = createAsyncThunk(
  "employee/get-employee-gp-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/gross_profit_graph?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setGpProjectionGraph(data?.data?.gp_projection_graph_table)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeActualReimburseGraph = createAsyncThunk(
  "employee/get-employee-actual-reimburse-graph",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/reimburse_graph?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&currency_conversion_id=${
          formData?.currency_conversion_id || ""
        }
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setActualReimburseGraph(data?.data?.reimburse_table)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeActualReimburseGraphPagination = createAsyncThunk(
  "employee/get-employee-actual-reimburse-graph-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/reimburse_graph?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&currency_conversion_id=${
          formData?.currency_conversion_id || ""
        }&page=${formData?.page || ""}
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setActualReimburseGraph(data?.data?.reimburse_table)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeAwaitingReimburseGraph = createAsyncThunk(
  "employee/get-employee-awaiting-reimburse-graph",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/awaiting_reimburse_graph?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&currency_conversion_id=${
          formData?.currency_conversion_id || ""
        }
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setActualReimburseGraph(data?.data?.reimburse_table)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getEmployeeAwaitingReimburseGraphPagination = createAsyncThunk(
  "employee/get-employee-awaiting-reimburse-graph",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/awaiting_reimburse_graph?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&currency_conversion_id=${
          formData?.currency_conversion_id || ""
        }&page=${formData?.page || ""}
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setActualReimburseGraph(data?.data?.reimburse_table)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeGpProjectionGraphPagination = createAsyncThunk(
  "employee/get-employee-gp-projection-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/gross_profit_graph?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&page=${formData?.page || ""}
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setGpProjectionGraph(data?.data?.gp_projection_graph_table)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeActualProfitProjection = createAsyncThunk(
  "employee/get-employee-net-actual-profit-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/actual_net_graph?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}
        `
      );
      // console.log(data, "-----------test------------");
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setActualProfitProjectionData(data?.data?.net_profit_actual_graph)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeActualProfitProjectionPagination = createAsyncThunk(
  "employee/get-employee-net-actual-profit-projection-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/actual_net_graph?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&page=${formData?.page || ""}
        `
      );
      if (!data?.success) {
        // await thunkAPI.dispatch(setSharedServices(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setActualProfitProjectionData(data?.data?.net_profit_actual_graph)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmpNetProfitProjectionPagination = createAsyncThunk(
  "employee/get-employee-net-profit-projection-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/dashboard/net_profit_projection?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&user_id=${
          formData?.user_id || ""
        }&page=${formData?.page || ""}`
      );
      if (!data?.success) {
        // await thunkAPI.dispatch(setSharedServices(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setNetProfitProjectionData(data?.data?.net_profit_project)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const employee_dashboard = createSlice({
  name: "employee_dashboard",
  initialState: {
    dashboardDatas: [],
    netprofitData: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    loadingPage: false,
  },
  reducers: {
    setDashboardData: (state, action) => {
      state.isAuth = true;
      state.dashboardDatas = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setNetProfitProjectionData: (state, action) => {
      state.isAuth = true;
      state.netprofitData = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setActualProfitProjectionData: (state, action) => {
      state.isAuth = true;
      state.actualprofitData = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setActualReimburseGraph: (state, action) => {
      state.isAuth = true;
      state.actualReimburseData = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setGpProjectionGraph: (state, action) => {
      state.isAuth = true;
      state.gpProjectionGraph = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setActualGrossProfitProjectionData: (state, action) => {
      state.isAuth = true;
      state.actualGpProjectionGraph = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getEmployeeDashboard.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeDashboard.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeDashboard.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeNetProfitProjection.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeNetProfitProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeNetProfitProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeActualGrossProfitProjection.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeActualGrossProfitProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeActualGrossProfitProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeActualProfitProjection.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeActualProfitProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeActualProfitProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeActualReimburseGraph.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeActualReimburseGraph.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeActualReimburseGraph.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeActualGrossProfitProjectionPagination.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeActualGrossProfitProjectionPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeActualGrossProfitProjectionPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeAwaitingReimburseGraph.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeAwaitingReimburseGraph.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeAwaitingReimburseGraph.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeAwaitingReimburseGraphPagination.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeAwaitingReimburseGraphPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeAwaitingReimburseGraphPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeGpProjectionGraph.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeGpProjectionGraph.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeGpProjectionGraph.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeGpProjectionGraphPagination.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeGpProjectionGraphPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeGpProjectionGraphPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeActualProfitProjectionPagination.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeActualProfitProjectionPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeActualProfitProjectionPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmpNetProfitProjectionPagination.pending]: (state) => {
      state.loading = true;
    },
    [getEmpNetProfitProjectionPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmpNetProfitProjectionPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});
export const {
  setDashboardData,
  setNetProfitProjectionData,
  setActualProfitProjectionData,
  setGpProjectionGraph,
  setActualReimburseGraph,
  setActualGrossProfitProjectionData,
} = employee_dashboard.actions;

export default employee_dashboard.reducer;
