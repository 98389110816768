import React from "react";
import "../../../../../styles/dashboard/projection/expense/index.css";
import illustrationIcon from "../../../../../../assets/Illustration-icon.svg";

const EmployeeActualSupplementaryBudgetDraft = ({
  children,
  currentMessage,
}) => {
  return (
    <div className="employee-draft-container employee-draft-container-two">
      {/* ilustration icon  */}
      <figure>
        <img src={illustrationIcon} alt="" />
      </figure>
      {/* ilustration icon  */}

      <div>
        {currentMessage || (
          <p>
            You currently do not have any saved or created custom budget.
            <br />
            Click on the button to create supplementary budget.
          </p>
        )}
        <div className="emp-draft-btn">{children}</div>
      </div>
      {/* {children} */}
    </div>
  );
};
export default EmployeeActualSupplementaryBudgetDraft;
