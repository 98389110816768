import React, { useContext, useState } from "react";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import CommonExpenseLayout from "./CommonLayout";
import Select from "react-select";
import ExpenseChart from "./ExpenseChart";
import ActionContext from "../../../../../context/ActionContext";
import { useHistory } from "react-router-dom";

const ExpenxeAwaitApproval = () => {
//   const [showChart, setShowChart] = useState(true);
const actionCtx = useContext(ActionContext)
  const [indexNo, setIndexNo] = useState("");
  const [dataChartValue ] = useState({
    labels: "",
    datasets: [
        {
          label: "Approved",
          data: [],
          backgroundColor: "#07A287",
        },
        {
          label: "Pending",
          data: [],
          backgroundColor: "#FCA549",
        },
        {
          label: "Rejected",
          data: [],
          backgroundColor: "#CC0905",
        },
      ]
  })
  const [chartFilterVal, setChartFilterVal] = useState("");
  const history = useHistory()

  const categoryList = [
    { label: "Zojapay", value: 1 },
    { label: "ITH", value: 2 },
    { label: "Zojatech", value: 3 },
  ];

  const tableHeadList = [
    "Subsidiary",
    "Branch",
    "Departments",
    "Amount Projected",
    "Status",
    "Action",
  ];
  const tableBodyList = [
    {
      subsidiary: "IT Horizons",
      branch: "ITH Branch",
      departments: "Product & Sales",
      amount_projected: "220,000.00",
      status: "pending",
      action: "",
    },
    {
      subsidiary: "IT Horizons",
      branch: "ITH Branch",
      departments: "Product & Sales",
      amount_projected: "220,000.00",
      status: "pending",
      action: "",
    },
    {
      subsidiary: "IT Horizons",
      branch: "ITH Branch",
      departments: "Product & Sales",
      amount_projected: "220,000.00",
      status: "pending",
      action: "",
    },
  ];

  return (
    <>
      <CommonExpenseLayout>
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div className={`chart-wrap ${actionCtx.isShowChart?.expense_projection && "chat-wrap-show"}`}>
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Expense Projection Graph</p>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Sort By"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <ExpenseChart dataToShow={dataChartValue} />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            // action
            onNextPage
          >
            {tableBodyList.map((chi, idx) => {
              const {
                subsidiary,
                branch,
                departments,
                amount_projected,
                status,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={subsidiary}
                  two={branch}
                  three={departments}
                  four={amount_projected}
                  progressType={status}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  updateText={` Update Branch`}
                  onNextPage={() =>
                    history.push("/dashboard-projection-expense-main")
                  }
                  //   onUpdate={() =>
                  //     history.push(
                  //       "/dashboard-administration-admin-settings-branch-update"
                  //     )
                  //   }
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </CommonExpenseLayout>
    </>
  );
};

export default ExpenxeAwaitApproval;
