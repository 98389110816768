import React, { useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
// import ActionContext from "../../../../../../context/ActionContext";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { updateBudgetAssumption } from "../../../../../../redux/budget-setting/budget-assumption/budgetAssumptions";
import { getUser } from "../../../../../../redux/users/users";

const UpdateAssumptionModal = ({ onCancel, detail, onFinish, onEdit, onDelete }) => {
  const dispatch = useDispatch();
  // const actionCtx = useContext(ActionContext);
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const { loading } = useSelector((state) => state.budgetAssumptions);
  const [details, setDetails] = useState({
    fiscal_year_id: "",
    assumption: "",
    attachment: "",
    organization_id: "",
  });


  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      assumption: detail?.assumption,
      attachment: detail?.attachment,
      fiscal_year_id: detail?.fiscal_year_id?.value,
      organization_id: detail?.organization_id,
    };
    // console.log(obj);
    // return
    const formData = new FormData()
    formData.append("organization_id", obj?.organization_id)
    formData.append("fiscal_year_id", obj?.fiscal_year_id)
    formData.append("attachment", obj?.attachment)
    formData.append("assumption", obj?.assumption)
    const formDataObj = {
      formData: formData,
      id: detail?.id,
    }
    // console.log(formDataObj);
    // return
    const data = await dispatch(updateBudgetAssumption(formDataObj));
    if (data?.payload?.success) {
      dispatch(getUser())
      onFinish();
      setDetails({
        fiscal_year_id: "",
        assumption: "",
        attachment: "",
        organization_id: "",
      });
    }
  };

  return (
    <>
      <CommonInputModal
        loading={loading}
        btnActive={details?.assumption || details?.attachment || details?.fiscal_year_id}
        onBtnClick={handleSubmit}
        btnDisable={!details?.assumption && !details?.attachment && !details?.fiscal_year_id}
        onCancel={onCancel}
        title={`Update Assumption`}
        btnText={`Update`}
      >
        {/* group box start */}
        <div onClick={() => {console.log(detail);}} className="form-group">
          <label htmlFor="Branch" className="label">
            Fiscal Year <span>*</span>
          </label>
          <Select
            placeholder="Select fiscal year"
            className="select "
            styles={reactSelectStyle}
            value={detail?.fiscal_year_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, fiscal_year_id: e };
              });
              onEdit(e, "fiscal");
            }}
            options={fiscalYearDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Assumption <span>*</span>
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="assumption"
            id=""
            cols="30"
            rows="10"
            onChange={(e) => {
              handleChange(e);
              onEdit(e);
            }}
            value={detail?.assumption}
            type="text"
            placeholder="Enter your budget assumption"
          ></textarea>
        </div>
        {/* group box end */}
        {/* input file start */}
        <div className="file-input-box">
          <input
            onChange={(e) => {
              setDetails((prev) => {
                return {
                  ...prev,
                  attachment: e?.target?.files[0],
                };
              });
              onEdit(e?.target?.files[0], "attach")
              // console.log(e?.target?.files[0]);
            }}
            type="file"
            name=""
            id="file-assumption-two"
          />
            {/* {details.attachment ? ( */}
           {details?.attachment?.name || detail?.attachment?.file_name ? <p style={{ display: "flex", alignItems: "center" }}>
              {details?.attachment?.name || detail?.attachment?.file_name}{" "}
              <span
              onClick={() => {
                setDetails((prev) => {
                  return {...prev, attachment: ""}
                })
                onDelete()
              }}
                style={{
                  color: "#CC0905",
                  display: "inline-block",
                  marginLeft: ".7rem",
                  transform: "translateY(.2rem)",
                  cursor: "pointer"   
                }}
              >
                <FaTrash />{" "} 
              </span>
            </p> : ""}
          {/* ) : ""}  */}
          <label htmlFor="file-assumption-two">
            <FaPlus className="icon" /> Attach document
          </label>
        </div>
        {/* input file end */}
      </CommonInputModal>
    </>
  );
};

export default UpdateAssumptionModal;
