import React, { useState } from "react";
import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
// import nameIcon from "../../../assets/name-input-icon.svg";
import "../../../styles/auth/AuthLayout.css";
import "../../../styles/auth/AuthForm.css";
// import workMailIcon from "../../../assets/work-mail-icon.svg";
import lockIcon from "../../../assets/password-icon.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { ResetPassword } from "../../../redux/user";
import { Dna } from "react-loader-spinner";
import { toast } from "react-toastify";
import {
  validatePasswordLowercase,
  validatePasswordNumber,
  validatePasswordSpecialCharacter,
  validatePasswordUpperCase,
} from "../../../helper/ValidateInput";
// import Aos from "aos";
// import "aos/dist/aos.css";

const CreateNewPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const [showPassword, setShwoPassord] = useState(false);
  const [details, setDetails] = useState({
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState({
    new_password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    const newError = { ...errors, [name]: "" };
    setErrors(newError);
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //  validations start ----------------

    if (
      !validatePasswordLowercase(details?.password) &&
      !validatePasswordUpperCase(details?.password) &&
      !validatePasswordSpecialCharacter(details?.password) &&
      !validatePasswordNumber(details?.password)
    ) {
      toast.error(
        "Password should at least be 8 characters long,contain 1 capital letter, 1 lowercase letter,  1 special character,  1 numeric character.",
        {
          theme: "colored",
          position: "top-right",
        }
      );
      return;
    }
    if (!validatePasswordLowercase(details?.password)) {
      toast.error("Password should have at least 1 lowercase letter", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordUpperCase(details?.password)) {
      toast.error("Password should have at least 1 capital letter", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordSpecialCharacter(details?.password)) {
      toast.error("Password should have at least 1 special character", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordNumber(details?.password)) {
      toast.error("Password should have at least  1 numeric character.", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    // validations end -----------------

    if (details?.password_confirmation !== details?.password) {
      setErrors((prev) => {
        return {
          ...prev,
          new_password: "Password does not match",
          confirm_password: "Password does not match",
        };
      });
      return;
    }
    const obj = {
      ...details,
      email: localStorage.getItem("email-to-receive-otp"),
    };
    // console.log(objNew);
    // return
    const data = await dispatch(ResetPassword(obj));
    if (data?.payload?.response) {
      const errors = data.payload?.response?.data?.errors?.password;
      if (errors) {
        setErrors((prev) => {
          return { ...prev, new_password: errors[0] };
        });
      }
    }
    if (data?.payload?.success) {
      console.log(data);
      history.push("/login");
    }
  };
  const [passwordMatch, setPassswordMatch] = useState(false);
  const checkPasswordMatch = (str) => {
    if (details?.password === str) {
      setPassswordMatch(true);
    } else {
      setPassswordMatch(false);
    }
  };

  return (
    <>
      <AuthLayout
        getHelp
        title="Create New Password"
        small_text={`Ensure your new password is eight (8) characters long, contain`}
        small_text_two={` at least one number.`}
      >
        {/* get started */}
        {/* auth form start */}
        <form
          onSubmit={handleSubmit}
          // history.push("/login");
          action=""
          className="auth-form-wrap"
          style={{ marginTop: "4rem" }}
        >
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="new_password" className="form-label">
              <figure className="img-box">
                <img src={lockIcon} className="img" alt="" />
              </figure>
            </label>
            <input
              type={showPassword ? "text" : "Password"}
              name="password"
              id="new_password"
              className="form-input white-black-color"
              placeholder="new password"
              onChange={handleChange}
              value={details.password}
            />
            <div
              className={`status ${details.password && "status-active"}`}
            ></div>
            <p
              className={`placeholder  white-black-bg white-black-color ${
                details.password && "placeholder-active"
              }`}
            >
              new Password
            </p>
            <div
              onClick={() => setShwoPassord(!showPassword)}
              className="show-hide"
            >
              {showPassword ? (
                <FaEyeSlash className="icon" />
              ) : (
                <FaEye className="icon" />
              )}
            </div>

            {errors.new_password && (
              <p className="error">{errors?.new_password}</p>
            )}
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="confirm_password" className="form-label">
              <figure className="img-box">
                <img src={lockIcon} className="img" alt="" />
              </figure>
            </label>
            <input
              type={showPassword ? "text" : "Password"}
              name="password_confirmation"
              id="confirm_password"
              className="form-input white-black-color"
              placeholder="confirm password"
              disabled={!details?.password}
              onChange={(e) => {
                handleChange(e);
                checkPasswordMatch(e.target.value);
              }}
              value={details.password_confirmation}
            />
            <div
              className={`status ${
                details.password_confirmation && "status-active"
              }`}
            ></div>
            <p
              className={`placeholder  white-black-bg white-black-color ${
                details.password_confirmation && "placeholder-active"
              }`}
            >
              confirm Password
            </p>
            <div
              onClick={() => setShwoPassord(!showPassword)}
              className="show-hide"
            >
              {showPassword ? (
                <FaEyeSlash className="icon" />
              ) : (
                <FaEye className="icon" />
              )}
            </div>

            {errors.confirm_password && (
              <p className="error">{errors?.confirm_password}</p>
            )}
           
          </div>
          {/* form group end */}
          {passwordMatch && (
              <p style={{ color: "#119c2b", marginTop: "-2rem" }} className="error">
                Password Match
              </p>
            )}
          {/*  */}
          {/*  */}
          {/* submit form start */}
          {!loading ? (
            <input
              disabled={!details?.password_confirmation || !details?.password}
              type="submit"
              value="Continue"
              className={`form-group btn-reuse form-submit ${
                details.password_confirmation &&
                details.password &&
                "form-submit-active"
              }`}
            />
          ) : (
            <div style={{ alignSelf: "center" }}>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
          {/* submit form  end */}
        </form>
        {/* auth form end */}
      </AuthLayout>
    </>
  );
};

export default CreateNewPassword;
