import React from "react";
import { FaTimes } from "react-icons/fa";
import bigimg from "../../../../../../assets/view-card-img.png";
// import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";

const cancelStyles =  {
    backgroundColor: " #FFEFEB",
    width:"3.5rem",
    height: "3.5rem",
    borderRadius: "50%",
    display: "grid",
    placeItems: "center",
    cursor: "pointer",
    position: "absolute",
    top: "2rem",
    right: "2rem",
}

const BigImgModal = ({onCancel}) => {
  return (
    <div
      style={{
        width: "80vw",
        height: "90vh",
        backgroundColor: "var(--bg-white-color)",
        borderRadius: "1rem",
        position: "relative",
        display: "flex",
        alignItems: "center"
      }}
    >
         <div style={cancelStyles} onClick={onCancel} className="cancel-box">
          <FaTimes style={{color: "#CC0905"}} className="icon" /> 
        </div>
      
      <figure style={{ width: "70vw", height: "80vh" }}>
        <img
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          src={bigimg}
          alt=""
        />
      </figure>
    </div>
  );
};

export default BigImgModal;
