import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "...axios";
import axios from "../../../utils/axios";
import { toast } from "react-toastify";
import setAuthToken from "../../../utils/auth";
import { customErrorId } from "../../../helper/Helpers";

export const createEmployeeActualExpense = createAsyncThunk(
  "admin/actual-employee-expense-create",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        "/employee/actual/actual_expense",
        formData,
        config
      );
      //   console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ActualExpenseDownload = createAsyncThunk(
  "employee/actual-expense-download",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual/worksheet
      `,
        formData
      );
      // const file = new Blob([data.data], { type: "application/xlsx" }); //Build a URL from the file
      // const fileURL = URL.createObjectURL(file); //Open the URL on new Window
      // window.open(fileURL);
      // console.log("data--->", data);
      window.open(data?.url, "_self").focus();
      // const downloadCsv = (url) => {
      //   // console.log(templateDownload?.url, "url");
      //   // dispatch(ExpenditureProjectionDownload());
      // };

      await thunkAPI.dispatch(setActualTemplateDownload(data));
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data, "data data")
        toast.success(data.message, {
          theme: "colored",
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const ActualExpenseBulkUpload = createAsyncThunk(
  "employee/actual-expense-bulk-upload",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `employee/actual/bulk-update`,
        formData
      );
      // const { data } = await axios.post(
      //   `employee/expenditure_projection/bulk-update?organization_id=${
      //     formData?.org_id || ""
      //   }&file=${formData?.file || ""}`,
      //   formData
      // );
      // console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      // if (err.response.data.message) {
      //   toast.error(err.response.data.message, {
      //     theme: "colored",
        // toastId: customErrorId,
      //   });
      // }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const submitDraftEmployeeActualExpense = createAsyncThunk(
  "admin/submit-draft-actual-expense",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/employee/actual/submit-actual",
        formData
      );
      //   console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const partialReimbursement = createAsyncThunk(
  "employee/partial-reimbursement",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/employee/actual/re-imburse",
        formData
      );
      //   console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const rejectAllActuals = createAsyncThunk(
  "admin/reject-all-actuals",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/employee/actual/reject-all-actual",
        formData
      );
      //   console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateEmployeeActualExpense = createAsyncThunk(
  "admin/update-actual-expense",
  async (formData, thunkAPI) => {
    const { id, obj } = formData;
    try {
      const { data } = await axios.post(
        `employee/actual/actual-expense/${id}`,
        obj
      );
      //   console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovedActualExpenseById = createAsyncThunk(
  "employee/get-approved-actual-expense-by-id",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual/all-submitted/${
          formData?.id || ""
        }?filter[is_submitted]=${
          formData?.filter_submitted || ""
        }&filter[status]=${formData?.status || ""}&filter[department_id]=${
          formData?.dept_id || ""
        }
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list", data?.data?.subsidiary)
        await thunkAPI.dispatch(setActualExpensePending(data.data.expense));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const commentEmpActualExpense = createAsyncThunk(
  "employee/comment-actual-expense",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `/employee/comments/actual-expenditure/add-comment?actual_expense_id=${
          formData?.actual_expense_id || ""
        }&status=${formData?.status || ""}&user_id=${
          formData?.user_id || ""
        }&comment=${formData?.comment || ""}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAllActualExpenseComment = createAsyncThunk(
  "employee/get-all-comment-actual-expense",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `/employee/comments/actual-expenditure/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        // console.log("data-comment", data?.data?.actual_expense_comments)
        await thunkAPI.dispatch(
          setActualExpenseComments(data?.data?.actual_expense_comments)
        );
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getActualExpenseSubmittedBySearch = createAsyncThunk(
  "employee/get-actual-expense-search",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/employee/actual/all-submitted/${formData?.id || ""}?sort_by=${
          formData?.sort || ""
        }&search=${formData?.search || ""}&filter=${
          formData?.filter_by || ""
        }&filter[status]=${formData?.status || ""}&filter[department_id]=${
          formData?.dept_id || ""
        }&currency_conversion_id=${formData?.currency_id || ""}&perPage=${
          formData?.per_page || ""
        }`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        await thunkAPI.dispatch(setActualExpensePending(data?.data?.expense));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeActualExpensesDraft = createAsyncThunk(
  "employee/get-actual-employee-expense-draft",
  async (formData, thunkAPI) => {
    try {
      // const { id, search, filter } = formData;
      const { data } = await axios.get(
        `employee/actual/index-draft/${formData?.id || ""}?search=${
          formData?.search || ""
        }&sort_by=${formData?.sort_by || ""}&filter_by=${
          formData?.filter || ""
        }&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        //   toast.error(data.message, {
        //     theme: "colored",
        // toastId: customErrorId,
        //   });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setActualExpenseDraft(data));
        // await thunkAPI.dispatch(
        //   setActualExpenseAvailableBal(data?.data?.aviable_balance_table)
        // );
        // await thunkAPI.dispatch(
        //   setActualExpenseBookBal(data?.data?.book_balance_table)
        // );
        // return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSingleEmployeeActualExpensesDraft = createAsyncThunk(
  "admin/get-single-actual-employee-expense-draft",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual/actual_expense/${formData.id}`
      );
      if (!data?.success) {
        //   toast.error(data.message, {
        //     theme: "colored",
        // toastId: customErrorId,
        //   });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        //   console.log(data);
        //   await thunkAPI.dispatch(setActualExpenseDraft(data.data.expense));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeActualExpensesPending = createAsyncThunk(
  "admin/get-actual-employee-expense-Pending",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual/all-submitted/${
          formData?.id || ""
        }?filter[department_id]=${formData?.dept_id || ""}&filter[status]=${
          formData?.status || ""
        }&currency_conversion_id=${formData?.currency_id || ""}&perPage=${
          formData?.per_page || ""
        }&chart_of_account=${formData?.chart_of_account_id || ""}`
      );
      if (!data?.success) {
        //   toast.error(data.message, {
        //     theme: "colored",
        // toastId: customErrorId,
        //   });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        await thunkAPI.dispatch(setActualExpensePending(data.data.expense));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getEmpActualExpenseReimburse = createAsyncThunk(
  "admin/get-employee-actual-expense-reimbursement",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual/re-imburse/${formData}`
      );
      if (!data?.success) {
        //   toast.error(data.message, {
        //     theme: "colored",
        // toastId: customErrorId,
        //   });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        // await thunkAPI.dispatch(setActualExpensePending(data.data.expense));
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeActualExpensesPendingPagination = createAsyncThunk(
  "admin/get-actual-employee-expense-Pending",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/actual/all-submitted/${
          formData?.id || ""
        }?filter[department_id]=${formData?.dept_id || ""}&filter[status]=${
          formData?.status || ""
        }&page=${formData?.page || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}&chart_of_account=${
          formData?.chart_of_account_id || ""
        }`
      );
      if (!data?.success) {
        //   toast.error(data.message, {
        //     theme: "colored",
        // toastId: customErrorId,
        //   });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        //   console.log(data.data);
        await thunkAPI.dispatch(setActualExpensePending(data.data.expense));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeeActualExpensesDraftPagination = createAsyncThunk(
  "admin/get-actual-employee-expense-draft-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `employee/actual/index-draft/${formData?.id || ""}?search=${
          formData?.search || ""
        }&sort_by=${formData?.sort_by || ""}&filter_by=${
          formData?.filter || ""
        }&perPage=${formData?.per_page || ""}&page=${formData?.page || ""}`
      );
      if (!data?.success) {
        //   toast.error(data.message, {
        //     theme: "colored",
        // toastId: customErrorId,
        //   });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setActualExpenseDraft(data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteActualExpenseEmployee = createAsyncThunk(
  "admin/delete-actual-employee-expense",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.delete(
        `employee/actual/actual_expense/${formData}`
      );
      if (!data?.success) {
        //   toast.error(data.message, {
        //     theme: "colored",
        // toastId: customErrorId,
        //   });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data);
        toast.success(data.data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const actual_expense_employee = createSlice({
  name: "actual_expense_employee",
  initialState: {
    isAuth: false,
    loading: false,
    loadingPage: false,
    loadingTwo: false,
    actualExpenseDraft: [],
    actualBookBal: [],
    actualAvailableBal: [],
    actualExpensePending: [],
    actualExpenseComments: [],
    links: {},
    meta: {},
    // loadingPage: false,
  },
  reducers: {
    setActualExpenseDraft: (state, action) => {
      state.isAuth = true;
      state.actualExpenseDraft = action.payload;
      state.links = action?.payload?.data?.actual_draft;
      state.meta = action?.payload?.data?.actual_draft;
    },
    setActualExpenseBookBal: (state, action) => {
      state.isAuth = true;
      state.actualBookBal = action.payload.data;
      state.links = action.payload;
      state.meta = action.payload;
    },
    setActualExpenseAvailableBal: (state, action) => {
      state.isAuth = true;
      state.actualAvailableBal = action.payload.data;
      state.links = action.payload;
      state.meta = action.payload;
    },
    setActualExpensePending: (state, action) => {
      state.isAuth = true;
      state.actualExpensePending = action.payload.data;
      state.links = action.payload;
      state.meta = action.payload;
    },
    setActualTemplateDownload: (state, action) => {
      state.isAuth = true;
      state.templateDownload = action.payload;
    },
    setActualExpenseComments: (state, action) => {
      state.isAuth = true;
      state.actualExpenseComments = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [createEmployeeActualExpense.pending]: (state) => {
      state.loading = true;
    },
    [createEmployeeActualExpense.fulfilled]: (state) => {
      state.loading = false;
    },
    [createEmployeeActualExpense.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeActualExpensesDraft.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeActualExpensesDraft.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeActualExpensesDraft.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getAllActualExpenseComment.pending]: (state) => {
      state.loading = true;
    },
    [getAllActualExpenseComment.fulfilled]: (state) => {
      state.loading = false;
    },
    [getAllActualExpenseComment.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getActualExpenseSubmittedBySearch.pending]: (state) => {
      state.loading = true;
    },
    [getActualExpenseSubmittedBySearch.fulfilled]: (state) => {
      state.loading = false;
    },
    [getActualExpenseSubmittedBySearch.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeActualExpensesDraftPagination.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeActualExpensesDraftPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeActualExpensesDraftPagination.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmpActualExpenseReimburse.pending]: (state) => {
      state.loading = true;
    },
    [getEmpActualExpenseReimburse.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmpActualExpenseReimburse.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovedActualExpenseById.pending]: (state) => {
      state.loadingPage = true;
    },
    [getApprovedActualExpenseById.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getApprovedActualExpenseById.rejected]: (state) => {
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [commentEmpActualExpense.pending]: (state) => {
      state.loading = true;
    },
    [commentEmpActualExpense.fulfilled]: (state) => {
      state.loading = false;
    },
    [commentEmpActualExpense.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeActualExpensesPending.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeActualExpensesPending.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeActualExpensesPending.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getEmployeeActualExpensesPendingPagination.pending]: (state) => {
      state.loading = true;
    },
    [getEmployeeActualExpensesPendingPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getEmployeeActualExpensesPendingPagination.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteActualExpenseEmployee.pending]: (state) => {
      state.loading = true;
    },
    [deleteActualExpenseEmployee.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteActualExpenseEmployee.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [rejectAllActuals.pending]: (state) => {
      state.loading = true;
    },
    [rejectAllActuals.fulfilled]: (state) => {
      state.loading = false;
    },
    [rejectAllActuals.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [submitDraftEmployeeActualExpense.pending]: (state) => {
      state.loadingTwo = true;
    },
    [submitDraftEmployeeActualExpense.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [submitDraftEmployeeActualExpense.rejected]: (state) => {
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [ActualExpenseBulkUpload.pending]: (state) => {
      state.loadingTwo = true;
    },
    [ActualExpenseBulkUpload.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [ActualExpenseBulkUpload.rejected]: (state) => {
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [ActualExpenseDownload.pending]: (state) => {
      state.loadingTwo = true;
    },
    [ActualExpenseDownload.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [ActualExpenseDownload.rejected]: (state) => {
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [updateEmployeeActualExpense.pending]: (state) => {
      state.loading = true;
    },
    [updateEmployeeActualExpense.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateEmployeeActualExpense.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

export const {
  setActualExpenseDraft,
  setActualExpenseBookBal,
  setActualExpenseAvailableBal,
  setActualExpensePending,
  setActualTemplateDownload,
  setActualExpenseComments,
} = actual_expense_employee.actions;

export default actual_expense_employee.reducer;
