import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
import CommonExpenseLayout from "./CommonLayout";
import Select from "react-select";
// import ExpenseChart from "./RevenueChart";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActionContext from "../../../../../context/ActionContext";
import RevenueChart from "./RevenueChart";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSupplementaryBudgetChart,
  getSupplementaryBudgetChartPagination,
} from "../../../../../redux/actuals/suplementaryBudget";
import {
  checkCurrencyFormat,
  formatNumberWithComma,
  getChartStructures,
  perPageOptions,
  reactSelectStyleTable,
} from "../../../../../helper/Helpers";

const SupplementaryBudgetActualIndex = ({ children, chartBox }) => {
  const actionCtx = useContext(ActionContext);
  //   const [showChart, setShowChart] = useState(true);
  const history = useHistory();
  const [indexNo, setIndexNo] = useState("");
  const { loading, supplementary_budget, loadingPage } = useSelector(
    (state) => state.suplementary_budget_chart
  );
  const [dataChartValue, setDataChartValue] = useState({
    labels: "",
    datasets: [
      {
        label: "Approved",
        data: [],
        backgroundColor: "#07A287",
      },
      {
        label: "Pending",
        data: [],
        backgroundColor: "#FCA549",
      },
      {
        label: "Rejected",
        data: [],
        backgroundColor: "#CC0905",
      },
    ],
  });
  const dispatch = useDispatch();
  useEffect(() => {
    getChartValueHere();
    // eslint-disable-next-line
  }, [actionCtx?.activeOrg, actionCtx?.currencyValue, actionCtx?.perPage]);

  const getChartValueHere = async () => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    // log
    const data = await dispatch(getSupplementaryBudgetChart(obj));
    if (data?.payload?.success) {
      // console.log(data);
      const list = getChartStructures(data?.payload?.data?.graph_data);
      setDataChartValue(list);
    }
  };
  const [chartFilterVal, setChartFilterVal] = useState("");

  const categoryList = [
    { label: "Zojapay", value: 1 },
    { label: "ITH", value: 2 },
    { label: "Zojatech", value: 3 },
  ];

  const tableHeadList = [
    "Subsidiary",
    "Departments",
    "User",
    "Total Revenue",
    "Total GP",
    "Status",
    "Action",
  ];

  const handlePagination = (url) => {
    const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getSupplementaryBudgetChartPagination(obj));
  };

  const handlePaginationNum = (num) => {
    // const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getSupplementaryBudgetChartPagination(obj));
  };

  return (
    <>
      <CommonExpenseLayout
        setDataChartValue={setDataChartValue}
        onReset={getChartValueHere}
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Supplementary Graph</p>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Sort By"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <RevenueChart
                loading={loading || loadingPage}
                datatoSHow={dataChartValue}
              />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* perpage box start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              position: "relative",
              zIndex: "20",
              marginBottom: "-1rem",
            }}
            className="perpage-wrap"
          >
            <span style={{ fontWeight: "600" }}> All Supplementary</span>{" "}
            <Select
              styles={reactSelectStyleTable}
              onChange={(e) => {
                actionCtx?.setPerpage(e);
              }}
              value={actionCtx?.perPage}
              options={perPageOptions}
            />
          </div>
          {/* perpage box end */}
          {/* table box start */}
          <ActualReuseableTable
            loading={loading && supplementary_budget?.length < 1}
            loadingNum={6}
            useNumPagination
            currentPage={
              supplementary_budget?.departments_projections?.current_page
            }
            totalTableLength={
              supplementary_budget?.departments_projections?.total
            }
            onNumPage={(e) => {
              handlePaginationNum(e);
            }}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={`Price  ( ${checkCurrencyFormat(
              actionCtx?.currencyValue?.label?.split(" - ")[0]
            )} )`}
            five
            toPage={supplementary_budget?.departments_projections?.to}
            fromPage={supplementary_budget?.departments_projections?.from}
            totalPage={supplementary_budget?.departments_projections?.total}
            onPrevPage={() =>
              handlePagination(
                supplementary_budget?.departments_projections?.prev_page_url
              )
            }
            onNextPage={() =>
              handlePagination(
                supplementary_budget?.departments_projections?.next_page_url
              )
            }
            nextPage={
              supplementary_budget?.departments_projections?.next_page_url
            }
            prevPage={
              supplementary_budget?.departments_projections?.prev_page_url
            }
          >
            {supplementary_budget?.departments_projections?.data?.map(
              (chi, idx) => {
                const { subsidiary , department, user, new_price } = chi || "";
                return (
                  <TableBodyRow
                    key={idx}
                    one={subsidiary?.name || " "}
                    two={department?.name || " "}
                    user={
                      { img: user?.profile_picture, name: user?.full_name } || (
                        <>&nbsp;</>
                      )
                    }
                    five={formatNumberWithComma(String(new_price || 0)) || "0"}
                    loading={loading || loadingPage}
                    action
                    rowNo={idx}
                    indexNo={indexNo}
                    setIndexNo={() => setIndexNo(idx)}
                    updateText={` Update Branch`}
                    onNextPage={() => {
                      localStorage.setItem(
                        "supplementary-chart-id",
                        chi?.department?.id
                      );
                      localStorage.setItem(
                        "supplementary-chart-user-id",
                        chi?.user?.id
                      );
                      history.push(
                        "/dashboard-actual-supplementary-budget-main"
                      );
                    }}
                  />
                );
              }
            )}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </CommonExpenseLayout>
    </>
  );
};

export default SupplementaryBudgetActualIndex;
