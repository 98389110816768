import React, { useState } from "react";
import EmployeeRemovableGroup from "./EmpRemovableGroup";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { NumericFormat } from "react-number-format";

const EmployeeMonthlyForm = ({
  unit_price,
  units,
  onInputChange,
  list,
  total_value,
  total_quantity,
  handleAddMonthList,
  onSelectvalueChange,
  handleRemoveMonthList,
  onInputValueChange,
  onTotalQuantityChange,
  onInputTotalChange,
  entryType,
  Quantity,
  handleEntryTypeChange,
  Total_quantity_label,
  entry_type_value,
  loading,
  total_value_label,
  name,
}) => {
  const [details ] = useState({ unit_price: "", entry_type: "" });


  return (
    <>
      <div
        className="form-wrap"
        style={{ marginTop: "3rem", marginBottom: "-10rem" }}
      >
        {/* form-group start */}
        <div className="form-group">
          {/* unit price group start */}
          <label htmlFor="unit price" className="label">
            Unit Price <span>* </span>
          </label>
          <NumericFormat
            // // type="number"
            // name="unit_price"
            // id="unit_price"
            // placeholder="Unit Value"
            // className={`input ${unit_price && "input-active"}`}
            // value={details.unit_price || unit_price}
            // onChange={onInputChange}
            className={`input ${unit_price && "input-active"}`}
            decimalScale={3}
            decimalSeparator="."
            type="text"
            name="unit_price"
          // thousandsGroupStyle={"lakh"}
            allowNegative
            onChange={onInputChange}
            thousandSeparator={","}
            value={details.unit_price || unit_price}
            id="unit_price"
            placeholder="Specify unit price"
          />
        </div>
        {/* unit price group end */}
        <div className="form-group">
          <label htmlFor="quantity" className="label">
            Entry Type <span>*</span>
          </label>
          <Select
            type="number"
            name="entry_type"
            placeholder={"Select Entry Type"}
            value={entry_type_value}
            className={`select-wrap ${
              entry_type_value && "select-wrap-active"
            }`}
            options={entryType || entryType}
            styles={reactSelectStyle}
            onChange={handleEntryTypeChange}
            isLoading={loading}
          />
        </div>

        {/* form-group end */}
      </div>
      {/* <div className="form-box form-group"> */}
      <div className="wrapper emp-itemize">
        <p className="box-title">Itemize monthly</p>
        <div className="step-assign-box">
          <div
            className="step-assign-line"
            style={{
              display: "flex",
              gap: "46.9%",
              marginBottom: "-2rem",
              marginTop: "2rem",
            }}
          >
            <p className="text">Months</p>
            <p className="text">Total value</p>
          </div>
        </div>
      </div>
      <div>
        <EmployeeRemovableGroup
          list={list}
          total_value_label={total_value_label}
          total_value={total_value}
          total_quantity={total_quantity}
          // total_quantity={}
          handleRemoveMonthList={handleRemoveMonthList}
          handleAddMonthList={handleAddMonthList}
          onSelectvalueChange={onSelectvalueChange}
          onInputValueChange={onInputValueChange}
          onInputChange={onInputChange}
          // entryType={entryType}
          Quantity={Quantity}
          // name={name}
          loading={loading}
          Total_quantity_label={Total_quantity_label}
          onInputTotalChange={onInputTotalChange}
        />
      </div>
      {/* </div> */}
    </>
  );
};
export default EmployeeMonthlyForm;
