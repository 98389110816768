import React from "react";
// import { useState } from "react";
// import { FaSearch } from "react-icons/fa";
// import Select from "react-select";
import "../../../../../../employee-folder/styles/dashboard/reports/generalReport.scss";
import ProjectionImg from "../../../../../../../src/assets/Projection-img.svg";
import ActualsImg from "../../../../../../../src/assets/Actuals-img.svg";
import DocumentImg from "../../../../../../../src/assets/Document-img.svg";
import { useHistory } from "react-router-dom";

const EmployeeGeneralReportsPage = () => {
  // const [details, setDetails] = useState({
  //   subsidiary: "",
  //   department: "",
  // });



  const ProjectionList = [
    {
      one: "Projections",
      two: "Consolidated Budget",
      three: "Revenue Projection",
      four: "Expense Projection",
      five: "Budget Performance",
      six: "Revenue Performance",
      seven: "Budget Variance",
      eight: "Revenue Variance",
      nine: "Recurrent & Capital Budget",
    },
  ];

  const ActualList = [
    {
      one: "Actuals",
      two: "Actual Revenue",
      three: "Actual Expense",
      four: "Recurrent & Capital Expense",
    },
  ];

  const BusinessOverviewList = [
    {
      one: "Business Overview",
      two: "Projections Vs. ActualGP",
      three: "Projections Vs. Actual Revenue & Actual GP",
      four: "Expense Projections Vs. GP Projections",
      five: "Cashflow",
    },
  ];

  const history = useHistory();

  const links = [
    "/employee-dashboard-general-report-consolidated-budget",
    "/employee-dashboard-general-report-revenue-projection",
    "/employee-dashboard-general-report-expense-projection",
    "/employee-dashboard-general-report-budget-performance",
    "/employee-dashboard-general-report-revenue-performance",
    "/employee-dashboard-general-report-budget-variance",
    "/employee-dashboard-general-report-revenue-variance",
    "/employee-dashboard-general-report-recurrent-and-capital-budget",
    "/employee-dashboard-general-report-actual-revenue",
    "/employee-dashboard-general-report-actual-expense",
    "/employee-dashboard-general-report-recurrent-and-capital-expense",
    "/employee-dashboard-general-report-projection-vs-actual-gp",
    "/employee-dashboard-general-report-projection-vs-actual-revenue-and-actual-gp",
    "/employee-dashboard-general-report-expense-projections-vs-gp-projections",
    "/employee-dashboard-general-report-cash-flow",
  ];

  return (
    <div>
      {/* <div className="title-btn-box">
        <div
          className="emp-reuseable-table-search emp-adjust-report"
          style={{ gap: "60rem" }}
        >
          <div className="emp-search-box">
            <input
              type="text"
              placeholder="Search for reports"
              id="search"
              className="input"
            />
            <label htmlFor="search" className="label">
              <FaSearch className="icon" />
            </label>
          </div>

         
          <div className="emp-filter-section">
            <div className="filter-label">
              <label>Filter By</label>
            </div>
            <div>
              <Select
                // isSearchable={true}
                isMulti
                placeholder="Subsidiary"
                styles={{ justifyContent: "right", display: "flex" }}
                className="select"
                value={details.subsidiary}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, subsidiary: e };
                  });
                }}
              />
            </div>

            <div>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Department"
                className="select"
                // value={details.department}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, department: e };
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* table wrap start */}
      <div className="emp-report-table-wrap">
        {/* first row start */}
        <div className="emp-report-table-list">
          {ProjectionList.map((chi, idx) => {
            const { one, two, three, four, five, six, seven, eight, nine } =
              chi;
            return (
              <div key={idx}>
                <div>
                  <figure className="reports-title-wrap">
                    <img src={ProjectionImg} alt="" />
                    <p className="reports-title">{one}</p>
                  </figure>
                </div>
                <div className="report-items">
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[0])}
                  >
                    {two}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[1])}
                  >
                    {three}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[2])}
                  >
                    {four}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[3])}
                  >
                    {five}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[4])}
                  >
                    {six}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[5])}
                  >
                    {seven}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[6])}
                  >
                    {eight}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[7])}
                  >
                    {nine}
                  </p>
                </div>
              </div>
            );
          })}
          {/* first row end */}
          {/* second row start */}
          {ActualList.map((chi, idx) => {
            const { one, two, three, four } = chi;
            return (
              <div key={idx}>
                <div>
                  <figure className="reports-title-wrap">
                    <img src={ActualsImg} alt="" />
                    <p className="reports-title">{one}</p>
                  </figure>
                </div>
                <div className="report-items">
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[8])}
                  >
                    {two}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[9])}
                  >
                    {three}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[10])}
                  >
                    {four}
                  </p>
                </div>
              </div>
            );
          })}
          {/* second row send */}
          {/* third row start */}
          {BusinessOverviewList.map((chi, idx) => {
            const { one, two, three, four, five } = chi;
            return (
              <div key={idx}>
                <div>
                  <figure className="reports-title-wrap">
                    <img src={DocumentImg} alt="" />
                    <p className="reports-title">{one}</p>
                  </figure>
                </div>
                <div className="report-items">
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[11])}
                  >
                    {two}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[12])}
                  >
                    {three}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[13])}
                  >
                    {four}
                  </p>
                  <p
                    className="reports-text"
                    onClick={() => history.push(links[14])}
                  >
                    {five}
                  </p>
                </div>
              </div>
            );
          })}
          {/* third row send */}
        </div>
      </div>
      {/* table wrap end */}
    </div>
  );
};
export default EmployeeGeneralReportsPage;
