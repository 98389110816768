import React from "react";
import { FaCheck } from "react-icons/fa";
import "../../../../../styles/dashboard/admin-settings/CommoPermissionBox.css";

const CommonCheckBox = ({
  per,
  des,
  idx,
  noBorder,
  value,
  checked,
  onChange,
  disable
}) => {
  return (
    <div style={{ marginTop: idx === 0 ? "2rem" : "" }} className="">
      <input
        checked={checked}
        onChange={onChange}
        className="input-check-common-permission"
        type="checkbox"
        name="check"
        id={`check-${idx}`}
        disabled={disable}
      />
      <label
        htmlFor={`check-${idx}`}
        className="label three-box-permission-common"
      >
        <p style={{textTransform: "capitalize"}}>{per}</p>
        <p>{des}</p>
        <div className="input-check-box">
          <FaCheck className="icon" />
        </div>
      </label>
      <div className="underline underline-common-permission"></div>
    </div>
  );
};

export default CommonCheckBox;
