import Integration from "../../../../component/pages/dashboard/general/integration";
import CRMTools from "../../../../component/pages/dashboard/general/integration/CRMtools";

export const Dashboard_general_integration_route_group = [
  {
    path: "/dashboard-general-integration",
    component: Integration,
    pageTitle: "Integration",
    goBack: true,
  },
  {
    path: "/dashboard-general-integration-crm-tools",
    component: CRMTools,
    pageTitle: "Integration",
    goBack: true,
  },
  {
    path: "/dashboard-general-integration-connected-platform",
    component: Integration,
    pageTitle: "Integration",
    goBack: true,
  },
  
];
