import React, { useEffect, useState } from "react";
import Select from "react-select";
import { RiCheckboxBlankFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import BookAndBalanceChart from "./chartTwo";
import {
  formatDataForDashboardGraphBookVsAvailable,
  reactSelectStyleTable,
} from "../../../../../helper/Helpers";
// import { useState } from "react";
import { useThemeDetector } from "../../../../../utils/getThemeColor";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
// import { reactSelectStyleCommonSort } from "../helper/Helpers";
// reactSelectStyleCommonSort

const AvailableBalanceChart = ({ book_bal, avail_bal, loading }) => {
  const { dashboard_details } = useSelector((state) => state.users);
  const [dataToShow, setDataToShow] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Available",
        data: [],
        borderColor: "#FCA549",
        tension: 0.2,
        backgroundColor: "#FCA549",
      },
      {
        label: "Book",
        tension: 0.2,
        data: [],
        borderColor: "#07A287",
        backgroundColor: "#07A287",
      },
    ],
  });
  const optionList = [
    {
      label: "Default",
      value: 1,
    },
    {
      label: "Current Fiscal Year",
      value: 2,
    },
    {
      label: "Previous Year",
      value: 3,
    },
    {
      label: "Today",
      value: 4,
    },
    {
      label: "Yesterday",
      value: 5,
    },
    {
      label: "Last 7 days",
      value: 6,
    },
    {
      label: "Monthly",
      value: 7,
    },
  ];

  const history = useHistory();
  const isDarkTheme = useThemeDetector();
  const { preference } = useSelector((state) => state.preference);

  useEffect(() => {
    const grahData = formatDataForDashboardGraphBookVsAvailable(
      dashboard_details?.book_vs_available_balance
    );
    setDataToShow(grahData);
  }, [dashboard_details]);

  // if (loading) {
  //   return (
  //     <>
  //       <div
  //         style={{
  //           width: "100%",
  //           height: "25rem",
  //           display: "grid",
  //           placeItems: "center",
  //         }}
  //         className=""
  //       >
  //         <Oval
  //           height={70}
  //           width={70}
  //           color={`#020202`}
  //           wrapperStyle={{}}
  //           wrapperClass="loader-spiner-react"
  //           visible={true}
  //           ariaLabel="oval-loading"
  //           secondaryColor="rgba(0,0,0, .2)"
  //           strokeWidth={2}
  //           strokeWidthSecondary={2}
  //         />
  //       </div>
  //     </>
  //   );
  // }

  return (
    <div
      style={{
        border: "1px solid #dde2e4",
      }}
      className="emp-reinburstment-wrap"
    >
      {/* reibursement box start  */}
      {loading ? (
        <>
          <div
            style={{
              width: "100%",
              height: "25rem",
              display: "grid",
              placeItems: "center",
            }}
            className=""
          >
            <Oval
              height={70}
              width={70}
              color={`#020202`}
              wrapperStyle={{}}
              wrapperClass="loader-spiner-react"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="rgba(0,0,0, .2)"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        </>
      ) : (
        <div className="emp-reinbursement-box">
          <div>
            <p className="reinbursement-head">Book and Available Balance</p>
            <BookAndBalanceChart loading={loading} dataToshow={dataToShow} />
          </div>
          <div className="right-content">
            <div className="select-wrap" style={{ width: "18rem" }}>
              <Select
                options={optionList}
                placeholder="Current Fiscal Year"
                className="select"
                styles={reactSelectStyleTable}
              />
            </div>
            <div
              onClick={() => history.push("/dashboard-home-book-balance")}
              style={{ cursor: "pointer" }}
            >
              <p className="pending-text">Book Balance </p>
              <p className="chart-digit">{book_bal || "0"}</p>
            </div>
            <div
              onClick={() => history.push("/dashboard-home-available-balance")}
              style={{ cursor: "pointer" }}
            >
              <p className="active-text">Available Balance </p>
              <p className="chart-digit">{avail_bal || "0"}</p>
            </div>
            <div className="bottom-text">
              <div>
                <p>
                  <RiCheckboxBlankFill className="icon" color="#fca549" />
                  Book Balance
                </p>
              </div>
              <div>
                <p>
                  <RiCheckboxBlankFill className="icon" color="#07a287" />
                  Available Balance
                </p>
              </div>
            </div>
            {/* <hr /> */}
          </div>
        </div>
      )}
      {/* reibursement box end  */}
    </div>
  );
};

export default AvailableBalanceChart;
