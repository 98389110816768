import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeCommonRevenueLayout from "./layout/CommonLayout";
import EmployeeProjectionApprovalModal from "./modals/approve";
import Modal from "react-awesome-modal";
import EmployeeProjectionComentModal from "./modals/comment";
import EmployeeRevenueOverviewRejectModal from "./modals/rejectedOverviewModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRevenueProjectionComment,
  getApprovedRevenueProjectionById,
  getApprovedRevenueProjectionSubmittedBySearch,
  // getRevenueProjection,
  // getRevenueProjectionById,
  getRevenueProjectionSubmittedPagination,
  getRevenueSubmitProjectionBySearch,
} from "../../../../../../redux/employee/revenue-projection/revenue-projection";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import EmployeeDraftProjection from "../expense/EmpDraftProjection";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
import ActionContext from "../../../../../../context/ActionContext";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
// import Modal from "react-awesome-modal";
// import EmployeeRevenueApprovalModal from "../modals/approve";
// import EmployeeRevenueComentModal from "../modals/comment";
// import EmployeeRejectOverviewModal from "../modals/submitted revenue/reject-overview";

const EmployeeRevenueProjectionRejectTable = ({ children }) => {
  const [indexNo, setIndexNo] = useState("");

  const history = useHistory();

  const [showModal, setShowModal] = useState({
    approve: false,
    comment: false,
    rejectOverview: false,
  });

  const { user } = useSelector((state) => state.user);
  const [refreshTable] = useState(false);
  const { loadingPage, revenueProjectionById } = useSelector(
    (state) => state.revenue_projection
  );
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [dept, setDept] = useState({ label: "Select Dept..." });
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [sortByVal, setSortByVal] = useState({ label: "Sort by" });
  const [singleChild, setSingleChild] = useState({});

  const SortByOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
  ];
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Product", value: "product_id" },
    { label: "Vertical", value: "vertical_id" },
  ];

  const cardHeader = ["Total Revenue", "Approved", "Pending", "Rejected"];
  // const cardValue = ["100,000.00", "20", "10", "5"];

  const tableHeadList = [
    "Product",
    "Units",
    "Unit Price",
    "Revenue",
    "GP",
    "GP %",
    "Status",
  ];
  const link = [
    "/employee-dashboard-projection-revenue-new",
    "/employee-dashboard-projection-revenue-submitted",
    "/employee-dashboard-projection-revenue-submitted-approved",
    "/employee-dashboard-projection-revenue-submitted-pending",
    "/employee-dashboard-projection-revenue-submitted-rejected",
  ];
  const debouncedSearchTerm = useDebounce(search, 1000);
  // search and filter
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        org_id: user?.organizations[0]?.id,
        search: search,
        is_submitted: 1,
        status: "rejected",
        dept_id: dept?.id,
        filter_by: filterByVal?.value,
      };
      dispatch(getApprovedRevenueProjectionSubmittedBySearch(obj));
    } else {
      handleGetRevenueData();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);
  const handleSortChange = (param) => {
    const obj = {
      dept_id: dept?.id,
      sort_by: param?.value,
      org_id: user?.organizations[0]?.id,
      status: "rejected",
    };
    // console.log("obj", obj)
    dispatch(getRevenueSubmitProjectionBySearch(obj));
  };

  useEffect(() => {
    getUserDepartment(user?.organizations[0]?.id);
    handleGetRevenueData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, user?.organizations[0]?.id, dept]);

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      status: "rejected",
      dept_id: dept?.id,
    };
    dispatch(getRevenueProjectionSubmittedPagination(obj));
  };

  const [singleItem, setSingleItem] = useState({});

  const handleGetRevenueData = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      status: "rejected",
      dept_id: dept?.id,
    };
    const data = await dispatch(getApprovedRevenueProjectionById(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };

  const cardValue = [
    singleItem?.summary?.total_projection || 0,
    singleItem?.summary?.approved || 0,
    singleItem?.summary?.awaiting_approval || 0,
    singleItem?.summary?.rejected || 0,
  ];

  const handleGetCommentData = (param) => {
    // setSingleChild(param);
    dispatch(getAllRevenueProjectionComment(param));
  };

  const handleSingleCommentData = (param) => {
    setSingleChild(param);
    if (param) {
      dispatch(getAllRevenueProjectionComment(param?.id));
    }
  };

  return (
    <>
      <div>
        <EmployeeCommonRevenueLayout rejectLink={true}>
          <EmployeeReusableTable
            dept
            departmentDrop={userDepartmentDrop}
            deptVal={dept}
            setDepartmentVal={(e) => {
              setDept(e);
            }}
            sort
            filter
            optionSort={SortByOptions}
            optionSortVal={sortByVal}
            setOptionSortVal={(e) => {
              setSortByVal(e);
              handleSortChange(e);
            }}
            optionFilter={filterByOptions}
            optionFilterVal={filterByVal}
            setOptionFilterVal={(e) => {
              setFilterByVal(e);
              // handleFil
            }}
            onSearchTable={(e) => {
              setSearch(e);
            }}
            showSearch={true}
            firstCard={true}
            loading={loadingPage}
            addButton={revenueProjectionById?.data?.length > 0}
            BtnTitle={`New Revenue`}
            onPage={() => history.push(link[0])}
            secondCard
            thirdCard={true}
            fourthCard
            cardTitleOne={cardHeader[0]}
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            cardBodyOne={formatNumber(
              String(
                FormatNumWithCurrency(
                  cardValue[0],
                  actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
                  actionCtx?.currencyValue
                ) || 0
              )
            )}
            cardBodyTwo={cardValue[1]}
            cardBodyThree={cardValue[2]}
            cardBodyFour={cardValue[3]}
            cardBodyFive={cardValue[4]}
            linkCardFour={link[4]}
            onFirstCard={() => history.push(link[1])}
            onSecondCard={() => history.push(link[2])}
            onThirdCard={() => history.push(link[3])}
            onFourthCard={() => history.push(link[4])}
            // onCard={onApprove}
          />
          {children}
          <div className="table-box">
            <ActualReuseableTable
              dontShowPagination={revenueProjectionById?.data?.length < 1}
              dontShowBnSearch
              one={tableHeadList[0]}
              two={tableHeadList[1]}
              three={tableHeadList[2]}
              four={tableHeadList[3]}
              five={tableHeadList[4]}
              six={tableHeadList[5]}
              seven={tableHeadList[6]}
              prevPage={revenueProjectionById?.prev_page_url}
              nextPage={revenueProjectionById?.next_page_url}
              fromPage={revenueProjectionById?.from}
              toPage={revenueProjectionById?.to}
              totalPage={revenueProjectionById?.total}
              onNextPage={() =>
                handlePagination(revenueProjectionById?.next_page_url)
              }
              onPrevPage={() =>
                handlePagination(revenueProjectionById?.prev_page_url)
              }
              action
              // onNextPage
            >
              {revenueProjectionById?.data?.length > 0 ? (
                <>
                  {revenueProjectionById?.data?.map((chi, idx, id) => {
                    const {
                      product,
                      unit,
                      unit_price,
                      total_revenue_project,
                      total_gross_profit,
                      gpp,
                      status,
                    } = chi;
                    return (
                      <EmpTableBodyRow
                        item={chi}
                        key={idx}
                        one={product?.name}
                        two={formatNumber(
                          String(
                            FormatNumWithCurrency(
                              unit,
                              actionCtx?.activeOrg?.active_fiscal_year
                                ?.base_currency?.name,
                              actionCtx?.currencyValue
                            ) || 0
                          )
                        )}
                        three={formatNumber(
                          String(
                            FormatNumWithCurrency(
                              unit_price,
                              actionCtx?.activeOrg?.active_fiscal_year
                                ?.base_currency?.name,
                              actionCtx?.currencyValue
                            ) || 0
                          )
                        )}
                        four={formatNumber(
                          String(
                            FormatNumWithCurrency(
                              total_revenue_project,
                              actionCtx?.activeOrg?.active_fiscal_year
                                ?.base_currency?.name,
                              actionCtx?.currencyValue
                            ) || 0
                          )
                        )}
                        five={formatNumber(
                          String(
                            FormatNumWithCurrency(
                              total_gross_profit,
                              actionCtx?.activeOrg?.active_fiscal_year
                                ?.base_currency?.name,
                              actionCtx?.currencyValue
                            ) || 0
                          )
                        )}
                        six={formatNumber(
                          String(
                            FormatNumWithCurrency(
                              gpp,
                              actionCtx?.activeOrg?.active_fiscal_year
                                ?.base_currency?.name,
                              actionCtx?.currencyValue
                            ) || 0
                          )
                        )}
                        // rejectedText={"reject"}
                        loading={loadingPage}
                        progressType={
                          status === "approved"
                            ? "accept"
                            : status === "rejected"
                            ? "reject"
                            : "pending"
                        }
                        action
                        rowNo={idx}
                        indexNo={indexNo}
                        setIndexNo={() => setIndexNo(idx)}
                        // updateText={`Update`}
                        // approveText={
                        //   user?.permissions?.includes(
                        //     "approve/reject revenue projection"
                        //   )
                        //     ? "Approve"
                        //     : null
                        // }
                        // onUpdate={() => handleRevenueProjectionUpdate(chi)}
                        // onRejected={() => {
                        //   // eslint-disable-next-line
                        //   {
                        //     setSingleChild(chi);
                        //     setShowModal((prev) => {
                        //       return { ...prev, rejectOverview: true };
                        //     });
                        //   }
                        // }}
                        viewText={"Comments"}
                        // deleteText={`Reject`}
                        exportText={`Export as PDF`}
                        // onApprove={() => {
                        //   actionCtx?.setIsModalOut(true);
                        //   setSingleChild(chi);
                        //   setShowModal((prev) => {
                        //     return { ...prev, approve: true };
                        //   });
                        // }}
                        onView={() => {
                          // eslint-disable-next-line
                          {
                            actionCtx?.setIsModalOut(true);
                            handleSingleCommentData(chi);
                            setShowModal((prev) => {
                              return { ...prev, comment: true };
                            });
                            // setTimeout(() => {
                            // }, 1000);
                          }
                        }}
                        onRejectStatus={() => {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return {
                              ...prev,
                              rejectOverview: true,
                            };
                          });
                        }}
                      />
                    );
                  })}
                </>
              ) : (
                <EmployeeDraftProjection projectionText={`revenue`}>
                  <ButtonBlue
                    onClick={() =>
                      history.push("/employee-dashboard-projection-revenue-new")
                    }
                    text={`Create Revenue`}
                  >
                    <FaPlus style={{ marginRight: "15px" }} />
                  </ButtonBlue>
                </EmployeeDraftProjection>
              )}
            </ActualReuseableTable>
          </div>
        </EmployeeCommonRevenueLayout>
      </div>
      {/* modal end */}
      <Modal visible={showModal.approve}>
        <EmployeeProjectionApprovalModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
            // handleGetRevenueData();
          }}
          onRefreshCard={handleGetRevenueData}
          onDispatch={() => {
            const objtwo = {
              org_id: user?.organizations[0]?.id,
              filter_submitted: true,
              status: "rejected",
            };
            dispatch(getApprovedRevenueProjectionById(objtwo));
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal end */}
      <Modal visible={showModal.comment}>
        <EmployeeProjectionComentModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, comment: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal Effect={"fadeInOut"} visible={showModal.rejectOverview}>
        <EmployeeRevenueOverviewRejectModal
          // update
          details={singleChild}
          data={singleChild}
          // onUpdate={() => {
          //   localStorage.setItem(
          //     "single-revenue-projection-id",
          //     singleChild?.id
          //   );
          //   history.push("/employee-dashboard-projection-revenue-update");
          // }}
          // approveBtn={
          //   user?.permissions?.includes("approve/reject revenue projection")
          //     ? true
          //     : null
          // }
          // rejectText
          // onReject={() =>
          //   setShowModal((prev) => {
          //     return { ...prev, reject: true, rejectOverview: false };
          //   })
          // }
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          // onApprove={() => {
          //   actionCtx?.setIsModalOut(true);

          //   setShowModal((prev) => {
          //     return { ...prev, approve: true, rejectOverview: false };
          //   });
          // }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeRevenueProjectionRejectTable;
