import React, { useContext } from "react";
import { useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
// import EmpTableBodyRow from "../../../../../../../employee-folder/component/layout/dashboard/EmpTableBodyRow";
import Select from "react-select";
import "../../../../../../../employee-folder/styles/dashboard/reports/consolidatedBudget/index.scss";
import { FiArrowUp } from "react-icons/fi";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
import {
  addAllToList,
  checkCurrencyFormat,
  formatNumber,
  perPageOptions,
  reactSelectStyle,
  reactSelectStyleTable,
} from "../../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminActualExpenseReport,
  getAdminActualExpenseReportPagination,
} from "../../../../../../../redux/reports/actual-expense";
import { useEffect } from "react";
import TableBodyRow from "../../../../../../tables/TableBodyRow";
import ActionContext from "../../../../../../../context/ActionContext";
import { getDepartments } from "../../../../../../../redux/department/deparment";
import { getAdminProjectionDownload } from "../../../../../../../redux/reports/consolidated-budget";
import { Dna } from "react-loader-spinner";
import ButtonBlue from "../../../../../../buttons/ButtonBlue";
import EmpTableBodyRow from "../../../../../../../employee-folder/component/layout/dashboard/EmpTableBodyRow";
import { getSubsidiaries } from "../../../../../../../redux/subsidiary/subsidiary";

const ActualExpense = () => {
  const history = useHistory();

  const [details, setDetails] = useState({
    period: "",
    department_id: "",
    subsidiary_id: "",
    status: "",
  });

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const { adminActualExpenseReports, loadingPage, loading } = useSelector(
    (state) => state.admin_actual_expense_report
  );
  const { departmentDrop } = useSelector((state) => state.department);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);

  const actionCtx = useContext(ActionContext);
  useEffect(() => {
    if (actionCtx?.activeOrg?.id) {
      const obj = {
        report_type: "actual_expense",
        org_id: actionCtx?.activeOrg?.id || actionCtx?.activeOrg?.id,
        cur_id: actionCtx?.currencyValue?.id || "",
        per_page: actionCtx?.perPage?.value,
        status: details?.status?.value,
      };
      dispatch(getAdminActualExpenseReport(obj));
      dispatch(getDepartments(actionCtx?.activeOrg?.id));
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionCtx?.activeOrg,
    actionCtx?.currencyValue,
    actionCtx?.perPage?.value,
    details,
  ]);

  const tableHeadList = [
    "Department",
    "Subsidiary",
    `Total Actual Expense   ( ${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )} )`,
    "Status",
  ];

  const statusDrop = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const handlePagination = async (param) => {
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // console.log("linkTwo", linkTwo);
    // link: linkTwo,
    const obj = {
      report_type: "actual_expense",
      org_id: actionCtx?.activeOrg?.id || actionCtx?.activeOrg?.id,
      // dept_id: userDepartmentDrop[0]?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      page: linkTwo,
    };
    dispatch(getAdminActualExpenseReportPagination(obj));
  };
  const handlePaginationNum = async (num) => {
    const obj = {
      report_type: "actual_expense",
      org_id: actionCtx?.activeOrg?.id || actionCtx?.activeOrg?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      page: num,
    };
    dispatch(getAdminActualExpenseReportPagination(obj));
  };

  const [statVal, setStatVal] = useState("");
  const handleSortByStatus = async (e) => {
    const obj = {
      report_type: "actual_expense",
      org_id: actionCtx?.activeOrg?.id || actionCtx?.activeOrg?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      status: e.value,
    };
    // console.log(obj);
    // return;
    dispatch(getAdminActualExpenseReport(obj));
  };

  const [loader, setLoader] = useState({
    one: false,
    two: false,
    three: false,
  });

  const handleFileDownload = async (param) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      dept_id: details?.department_id?.id,
      type: "actual_expense",
      format: param,
      user_id: user?.id,
      currency: actionCtx?.currencyValue?.id || "",
      status: details?.status?.value,
    };
    setLoader((prev) => {
      return {
        ...prev,
        one: param === "excel" && true,
        two: param === "pdf" && true,
      };
    });
    const data = await dispatch(getAdminProjectionDownload(obj));
    if (data?.payload?.status === 200) {
      window.open(data?.payload?.data?.url, "_blank").focus();
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    } else {
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    }
  };
  return (
    <>
      {/* <EmployeeDashboardLayout
        goBack
        pageTitle={`View Actual Expense Report`}
        organization
      > */}
      <div className="admin-org-settings-wrap">
        {/* left box start */}
        <div
          className="title-btn-bo"
          style={{ borderBottom: "1px solid var(--border-color)" }}
        >
          <div className="emp-consolidate-wra ">
            {/* <div className="consolidate-left-box"> */}
            <div className="subtitle-box ">
              <p className="text">Report</p>
              <FaAngleRight className="icon" />
              <p
                className="text"
                onClick={() => history.push("/dashboard-general-report")}
              >
                General Reports
              </p>
              <FaAngleRight className="icon" />
              <p className="active text">Actual Expense</p>
            </div>
            {/* </div> */}
            {/* left box end */}

            {/* select wrap start */}

            <div
              className="consolidate-right-box"
              style={{ marginTop: "-5rem" }}
            >
              <div className="filter-label">
                <label className="label">Filter By</label>
              </div>
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  styles={reactSelectStyle}
                  placeholder="Status"
                  className={`select-wrap ${details?.status && ""}`}
                  value={details?.status}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, status: e };
                    });
                  }}
                  options={statusDrop}
                />
              </div>
              {/* <div className="form-group">
                <Flatpickr
                  id="date"
                  name="date"
                  // multiple
                  options={range}
                  className={`input ${startDate && "input-active"}`}
                  autoComplete="off"
                  value={startDate}
                  onClose={(date) => {
                    // setStartDate(
                      moment(date[0]).format("YYYY-MM-DD") +
                        " to " +
                        moment(date[1]).format("YYYY-MM-DD")
                    );
                    // console.log(, "date-date");
                  }}
                  // onClose={handleDateChange}
                  placeholder="YYYY/MM/DD"
                />
              </div> */}
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti

                  placeholder={"Department"}
                  className={`select-wrap ${details?.department_id && ""}`}
                  value={details?.department_id}
                  styles={reactSelectStyle}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, department_id: e };
                    });
                    // setSingleDept((prev) => {
                    //   return { ...prev, department: e };
                    // });
                  }}
                  options={addAllToList(departmentDrop, "Departments")}
                />
              </div>
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  styles={reactSelectStyle}
                  placeholder="Subsidiary"
                  className={`select-wrap ${details?.subsidiary_id && ""}`}
                  isLoading={loading}
                  value={details?.subsidiary_id}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, subsidiary_id: e };
                    });
                  }}
                  options={subsidiariesDrop}
                />
              </div>
            </div>
          </div>
        </div>
        {/* select wrap end */}

        {/* sub-title start */}
        <div className="title-btn-bo">
          {/* text title box start */}
          <div className=" sub-right-box">
            <div className="emp-sub-report">
              <p className="title">
                {`${details?.department_id?.label || "All"} Actual Expense`}
              </p>
              <Select
                className="per-page-select"
                styles={reactSelectStyleTable}
                value={actionCtx?.perPage}
                onChange={(e) => {
                  actionCtx?.setPerpage(e);
                }}
                options={perPageOptions}
              />
            </div>
            <div className="emp-btn-wrap">
              {/* <button className="emp-export-btn">
                <FiArrowUp className="export-icon" />
                Export
              </button> */}
              {loader?.one ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleFileDownload("excel");
                  }}
                  text={`Export`}
                />
              )}
              {loader?.two ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleFileDownload("pdf");
                  }}
                  text={`Print`}
                />
              )}

              {/* {loader?.three ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleActualExpenseReport("");
                  }}
                  text={`All Department`}
                />
              )} */}
              {/* <button className="emp-print-btn">
                Print
                <FaAngleDown className="print-icon" />
              </button> */}
            </div>
            {/* sub-title end */}
          </div>
        </div>
        {/* table start */}
        <ActualReuseableTable
          useNumPagination
          currentPage={adminActualExpenseReports?.current_page}
          totalTableLength={adminActualExpenseReports?.total}
          onNumPage={(e) => {
            handlePaginationNum(e);
          }}
          dontShowBnSearch={true}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          dontShowPagination={adminActualExpenseReports?.data?.length < 1}
          fromPage={adminActualExpenseReports?.from}
          toPage={adminActualExpenseReports?.to}
          totalPage={adminActualExpenseReports?.total}
          nextPage={adminActualExpenseReports?.next_page_url}
          prevPage={adminActualExpenseReports?.prev_page_url}
          onNextPage={() =>
            handlePagination(adminActualExpenseReports?.next_page_url)
          }
          onPrevPage={() =>
            handlePagination(adminActualExpenseReports?.prev_page_url)
          }
        >
          {adminActualExpenseReports?.data?.map((chi, idx) => {
            const { department, subsidiary, total_actual_expense, status } =
              chi;
            return (
              <EmpTableBodyRow
                key={idx}
                one={department?.name}
                two={subsidiary.name}
                three={formatNumber(String(total_actual_expense || 0))}
                rowNo={idx}
                // four={actual_amount}
                // progressType={status}
                progressType={
                  status === "approved"
                    ? "accept"
                    : status === "rejected"
                    ? "reject"
                    : "pending"
                }
                loading={loadingPage}
                onNextPage={() => {
                  localStorage.setItem("actual-expense-report", department?.id);
                  history.push("/dashboard-actual-expense-main");
                }}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default ActualExpense;
