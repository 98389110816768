import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeCommonRevenueLayout from "./layout/CommonLayout";
import Modal from "react-awesome-modal";
import EmployeeProjectionComentModal from "./modals/comment";
// import EmployeeOverviewRevenueApprovedModal from "./modals/approveOverviewModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRevenueProjectionComment,
  getApprovedRevenueProjectionById,
  getApprovedRevenueProjectionSubmittedBySearch,
  getRevenueProjectionSubmittedPagination,
} from "../../../../../../redux/employee/revenue-projection/revenue-projection";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import EmployeeDraftProjection from "../expense/EmpDraftProjection";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
// import EmpRevenueOverview from "./modals/approveOverviewModal";
import EmployeeRevApproveOverview from "./modals/approveOverviewModal";
import EmpExpRevRejectModal from "./modals/rejectModal";
import ActionContext from "../../../../../../context/ActionContext";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";

// import EmployeeRevenueComentModal from "../modals/comment";
// import EmployeeApprovedOverviewModal from "../modals/submitted revenue/overview";

const EmployeeProjectionRevenueApproveTable = () => {
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    comment: false,
    approveOverview: false,
  });

  const { user } = useSelector((state) => state.user);
  const [refreshTable] = useState(false);
  const { loadingPage, revenueProjectionById } = useSelector(
    (state) => state.revenue_projection
  );
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [sortByVal, setSortByVal] = useState({ label: "Sort by" });
  const [singleChild, setSingleChild] = useState({});
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [dept, setDept] = useState({ label: "Select Dept..." });
  const SortByOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
  ];
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Product", value: "product_id" },
    { label: "Vertical", value: "vertical_id" },
    // { label: "Date Created", value: "created_at" },
  ];
  const debouncedSearchTerm = useDebounce(search, 1000);

  // search and filter
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        org_id: user?.organizations[0]?.id,
        search: search,
        is_submitted: 1,
        status: "approved",
        dept_id: dept?.id,
        filter_by: filterByVal?.value,
        // sort_by: sortByVal?.value,
      };
      dispatch(getApprovedRevenueProjectionSubmittedBySearch(obj));
    } else {
      handleGetRevenueData();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const handleSortChange = (param) => {
    const obj = {
      dept_id: dept?.id,
      sort_by: param?.value,
      org_id: user?.organizations[0]?.id,
      status: "approved",
    };
    // console.log("obj", obj)
    dispatch(getApprovedRevenueProjectionSubmittedBySearch(obj));
  };

  useEffect(() => {
    getUserDepartment(user?.organizations[0]?.id);
    handleGetRevenueData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, user?.organizations[0]?.id, dept]);

  const handleGetRevenueData = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      status: "approved",
      dept_id: dept?.id,
    };
    const data = await dispatch(getApprovedRevenueProjectionById(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      status: "approved",
      dept_id: dept?.id,
    };
    dispatch(getRevenueProjectionSubmittedPagination(obj));
  };

  // const handlePagination = async (param) => {
  //   const splitLink = param.split("/api");
  //   const linkTwo = splitLink[1];
  //   console.log("linkTwo", linkTwo);
  //   dispatch(getRevenueProjectionSubmittedPagination(param));
  // };

  const [singleItem, setSingleItem] = useState({});

  const cardValue = [
    singleItem?.summary?.total_projection || 0,
    singleItem?.summary?.approved || 0,
    singleItem?.summary?.awaiting_approval || 0,
    singleItem?.summary?.rejected || 0,
  ];

  // const cardValue = ["100,000.00", "20", "10", "5"];

  const history = useHistory();

  const tableHeadList = [
    "Product",
    "Units",
    "Unit Price",
    "Revenue",
    "GP",
    "GP %",
    "Status",
  ];

  const cardHeader = ["Total Revenue", "Approved", "Pending", "Rejected"];

  const link = [
    "/employee-dashboard-projection-revenue-new",
    "/employee-dashboard-projection-revenue-submitted",
    "/employee-dashboard-projection-revenue-submitted-approved",
    "/employee-dashboard-projection-revenue-submitted-pending",
    "employee-dashboard-projection-revenue-submitted-rejected",
  ];

  const handleGetCommentData = (param) => {
    // setSingleChild(param);
    dispatch(getAllRevenueProjectionComment(param));
  };

  const handleSingleCommentData = (param) => {
    setSingleChild(param);
    if (param) {
      dispatch(getAllRevenueProjectionComment(param?.id));
    }
  };

  return (
    <>
      <div>
        <EmployeeCommonRevenueLayout approvedLink={true}>
          <EmployeeReusableTable
            dept
            departmentDrop={userDepartmentDrop}
            deptVal={dept}
            setDepartmentVal={(e) => {
              setDept(e);
            }}
            sort
            filter
            optionSort={SortByOptions}
            optionSortVal={sortByVal}
            setOptionSortVal={(e) => {
              setSortByVal(e);
              handleSortChange(e);
            }}
            optionFilter={filterByOptions}
            optionFilterVal={filterByVal}
            setOptionFilterVal={(e) => {
              setFilterByVal(e);
              // handleFil
            }}
            onSearchTable={(e) => {
              setSearch(e);
              // console.log("e", e)
            }}
            showSearch={true}
            firstCard={true}
            addButton={revenueProjectionById?.data?.length > 0}
            BtnTitle={`New Revenue`}
            loading={loadingPage}
            // showSearch={true}
            cardTitleOne={cardHeader[0]}
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            cardBodyOne={formatNumber(
              String(
                FormatNumWithCurrency(
                  cardValue[0],
                  actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
                  actionCtx?.currencyValue
                ) || 0
              )
            )}
            cardBodyTwo={cardValue[1]}
            cardBodyThree={cardValue[2]}
            cardBodyFour={cardValue[3]}
            // firstCard={true}
            secondCard={true}
            thirdCard={true}
            fourthCard={true}
            linkCardTwo={link[2]}
            onPage={() => history.push(link[0])}
            onFirstCard={() => history.push(link[1])}
            onSecondCard={() => history.push(link[2])}
            onThirdCard={() => history.push(link[3])}
            onFourthCard={() => history.push(link[4])}

            // onCard={onApprove}
          />
          <ActualReuseableTable
            dontShowPagination={revenueProjectionById?.data?.length < 1}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            prevPage={revenueProjectionById?.prev_page_url}
            nextPage={revenueProjectionById?.next_page_url}
            fromPage={revenueProjectionById?.from}
            toPage={revenueProjectionById?.to}
            totalPage={revenueProjectionById?.total}
            onNextPage={() =>
              handlePagination(revenueProjectionById?.next_page_url)
            }
            onPrevPage={() =>
              handlePagination(revenueProjectionById?.prev_page_url)
            }
            action
          >
            {revenueProjectionById?.data?.length > 0 ? (
              <>
                {revenueProjectionById?.data?.map((chi, idx, id) => {
                  const {
                    product,
                    unit,
                    unit_price,
                    total_revenue_project,
                    total_gross_profit,
                    gpp,
                    status,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      item={chi}
                      key={idx}
                      one={product?.name}
                      two={formatNumber(
                        String(
                          FormatNumWithCurrency(
                            unit,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}
                      three={formatNumber(
                        String(
                          FormatNumWithCurrency(
                            unit_price,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}
                      four={formatNumber(
                        String(
                          FormatNumWithCurrency(
                            total_revenue_project,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}
                      five={formatNumber(
                        String(
                          FormatNumWithCurrency(
                            total_gross_profit,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}
                      six={formatNumber(
                        String(
                          FormatNumWithCurrency(
                            gpp,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}
                      filter_submitted
                      loading={loadingPage}
                      progressType={
                        status === "approved"
                          ? "accept"
                          : status === "rejected"
                          ? "reject"
                          : "pending"
                      }
                      action
                      rowNo={idx}
                      indexNo={indexNo}
                      setIndexNo={() => setIndexNo(idx)}
                      // updateText={`modify`}
                      // approveText={"Approve"}
                      // rejectedText={
                      //   user?.permissions?.includes(
                      //     "approve/reject revenue projection"
                      //   )
                      //     ? "reject"
                      //     : null
                      // }
                      viewText={"View Comments"}
                      onView={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          handleSingleCommentData(chi);
                          setShowModal((prev) => {
                            return { ...prev, comment: true };
                          });
                          // setTimeout(() => {
                          // }, 1000);
                        }
                      }}
                      onAcceptStatus={() => {
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approveOverview: true };
                        });
                      }}
                      onOverview={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, approveOverview: true };
                          });
                        }
                      }}
                      onRejected={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, reject: true };
                          });
                        }
                      }}
                      // deleteText={`Reject`}
                      exportText={`Export as PDF`}
                    />
                  );
                })}
              </>
            ) : (
              <EmployeeDraftProjection projectionText={`revenue`}>
                <ButtonBlue
                  onClick={() =>
                    history.push("/employee-dashboard-projection-revenue-new")
                  }
                  text={`Create Revenue`}
                >
                  <FaPlus style={{ marginRight: "15px" }} />
                </ButtonBlue>
              </EmployeeDraftProjection>
            )}
          </ActualReuseableTable>
        </EmployeeCommonRevenueLayout>
      </div>
      {/* modal end */}
      <Modal visible={showModal.comment}>
        <EmployeeProjectionComentModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, comment: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmpExpRevRejectModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
            // handleGetRevenueData();
          }}
          onRefreshCard={handleGetRevenueData}
          onDispatch={() => {
            const objtwo = {
              org_id: user?.organizations[0]?.id,
              filter_submitted: true,
              status: "approved",
            };
            dispatch(getApprovedRevenueProjectionById(objtwo));
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeRevApproveOverview
          // update
          details={singleChild}
          data={singleChild}
          // onUpdate={() => {
          //   localStorage.setItem(
          //     "single-revenue-projection-id",
          //     singleChild?.id
          //   );
          //   history.push("/employee-dashboard-projection-revenue-update");
          // }}
          // rejectText={
          //   user?.permissions?.includes("approve/reject revenue projection")
          //     ? true
          //     : null
          // }
          // onReject={() => {
          //   actionCtx?.setIsModalOut(true);
          //   setShowModal((prev) => {
          //     return { ...prev, reject: true, approveOverview: false };
          //   });
          // }}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          onApprove={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, approve: true, approveOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeProjectionRevenueApproveTable;
