import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight  } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
// import ReUseableTable from "../../../../tables/ReUseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import { getFiscalYearByFilter, getFiscalYearPagination, getFiscalYearWithPagination } from "../../../../../redux/fiscal-year/fiscalYear";
import { formatDate } from "../../../../../helper/Helpers";
import useDebounce from "../../../../../helper/UseDebounce";

const FiscalYear = () => {
  // const [ setSingleChild] = useState({});
  const [refreshTable ] = useState(false);
  // const [ setShowDeleteModal] = useState(false);
  const { loadingPage, fiscal_years, meta, links } = useSelector(
    (state) => state.fiscal_year
  );
  const actionCtx = useContext(ActionContext);
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // const [indexNo, setIndexNo] = useState("");
  const tableHeadList = ["Title", "Start Date", "End Date", "Base Currency"];
  

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      // dispatch(getBranches(actionCtx?.activeOrg?.id));
      dispatch(getFiscalYearWithPagination(obj));
    }
      // eslint-disable-next-line
  }, [refreshTable, actionCtx?.activeOrg]);


  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "Title", value: "title" },
    { label: "Start date", value: "start date" },
    { label: "End date", value: "end date" },
    { label: "Base currency", value: "base currency" },
  ];

  

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getFiscalYearPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    user?.organizations?.length > 0 && dispatch(getFiscalYearWithPagination(obj));
  };



  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: filterByVal?.value || "",
        org_id: actionCtx?.activeOrg?.id,
      };
      dispatch(getFiscalYearByFilter(obj));
    }

    return () => {
      isMount = false;
    };

      // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 3 of 6`}
        pageTitle={`Admin Settings`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            // onClick={() =>
            //   history.push(
            //     "/dashboard-administration-admin-settings-subsidaries"
            //   )
            // }
            className="active text"
          >
            Fiscal Year
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>

        {/* table box start */}
        <ActualReuseableTable
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          perPageValue={actionCtx?.perPage}
          setPerPageValue={(e) => {
            // setPerpageVal(e);
            handleSortPerPage(e.value);
          }}
          fromPage={meta?.from || 1}
          toPage={meta?.to || fiscal_years?.length}
          totalPage={meta?.total || fiscal_years?.length}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          tableTitle={`Fiscal Year`}
          tableTitleSpan={`(2)`}
          addBtntext={`New Fiscal Year`}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          onCreateNew={() =>
            history.push(
              "/dashboard-administration-admin-settings-fiscal-year-add"
            )
          }
          loading={loadingPage && fiscal_years?.length < 1}
          loadingNum={6}
        >
          {fiscal_years?.map((chi, idx) => {
            const { title, start_date, end_date, base_currency } = chi;
            return (
              <TableBodyRow
                key={idx}
                one={title}
                two={formatDate(start_date)}
                three={formatDate(end_date)}
                four={base_currency?.name}
               loading={loadingPage}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}

        {/* note box start */}
        {/* <div className="note-wrap">
         <div className="note-img-wrap">
            Important Notice:
            <figure className="img-box">
              <img src={noteIcon} alt="" className="img" />
            </figure>
          </div>
          <div className="note-box">
            <p>
              1. For organizations with shared budgets, you would need to have
              created at least two subsidiaries to set up a shared services
            </p>
          </div>
         </div> */}
        {/* note box end */}
        {/* btn box start */}
        {/* <div className="btn-box-wrap">
            <button
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-subsidaries"
                )
              }
              className="btn-reuse"
            >
              Go to previous step
            </button>
            <button className="btn-reuse btn-two">Go to the next step</button>
          </div> */}
        {/* btn box end */}
        {/* skip text btn box start*/}
        {/* <div className="skip-box">
            <p className="text">
              If you do not have a registered branch under your organization,
              click to next the process
            </p>
            <button className="btn">Skip this</button>
          </div> */}
        {/* skip text btn box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default FiscalYear;
