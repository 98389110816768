import React from "react";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
// import { submitSingleRevenueDraftForApproval } from "../../../../../../../redux/employee/revenue-projection/revenue-projection";
import "../../../../../../styles/dashboard/modals/DeleteSharedmodal.css";
import { submitDraftEmployeeActualExpense } from "../../../../../../redux/employee/actual-expense";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EmpActualSubmitForApproval = ({ onClose, onFinish, details }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loadingTwo } = useSelector((state) => state.actual_expense_employee);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dat = { actual_expense_ids: [details?.id] };

    const data = await dispatch(submitDraftEmployeeActualExpense(dat));
    if (data?.payload?.success) {
      onFinish();
      history.push("/employee-dashboard-actual-expense-submitted");
    }
  };

  return (
    <div className="delete-shared-modal-wrap">
      <p className="title" style={{ textTransform: "capitalize" }}>
        {`Submit Projection (${details?.chart_of_account?.name || ""})`}
      </p>
      <p className="text">
        {` Are you sure you want to submit this projection?`}
      </p>

      <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        {loadingTwo ? (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="100"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue
            // bgColor={`var(--blue-color)`}
            text={`Yes Submit`}
            onClick={handleSubmit}
            //
          />
        )}
      </div>
    </div>
  );
};

export default EmpActualSubmitForApproval;
