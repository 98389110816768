import React, { useContext, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import Select from "react-select";
import ActionContext from "../../../../../../context/ActionContext";
// import { useHistory } from "react-router-dom";
import EmployeeNetProfitProjection from ".";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
// import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
// import EmployeeMonthlyExpenseChart from "../chart/monthlyChart";
import EmployeeNetProfitProjectionGraph from "../chart/profitProjection";
// import Modal from "react-awesome-modal";
// import EmployeeOverviewPendingReinbursementModal from "../modals/Reimbursement/pendingModal";
// import EmployeeRevenueApprovalModal from "../../actual/modals/approve";
// import EmployeeRevenueComentModal from "../../actual/modals/comment";
// // import EmployeeDeleteRevenueModal from "../../actual/modals/deleteRevenue";
// import EmployeeBigImgModal from "../../actual/modals/bigImg";
// import EmployeeRejectCommentModal from "../../actual/modals/rejectComment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import {
  getEmployeeAwaitingReimburseGraph,
  getEmployeeAwaitingReimburseGraphPagination,
} from "../../../../../../redux/employee/dashboard";
import TableBodyRow from "../../../../../../component/tables/TableBodyRow";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";

const EmployeePendingReimbursementIndex = ({ children, chartBox }) => {
  const actionCtx = useContext(ActionContext);
  // const history = useHistory();
  // //   const [showChart, setShowChart] = useState(true);
  // const [indexNo, setIndexNo] = useState("");
  // //   const [showChartBox, setShowChartBox] = useState(true);
  const [chartFilterVal, setChartFilterVal] = useState("");

  // const [showModal, setShowModal] = useState({
  //   overview: false,
  //   approve: false,
  //   comment: false,
  //   reject: false,
  //   big_img: false,
  //   approveTwo: false,
  //   commentTwo: false,
  //   rejectTwo: false,
  // });

  const department_id = localStorage.getItem("employee-department-id");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const { loading, actualReimburseData } = useSelector(
    (state) => state.employee_dashboard
  );
  const [totalReimburse, setTotalReimburse] = useState("");
  const [reimburesGraph, setReimburseGraph] = useState({});

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    if (user?.organizations[0]?.id || department_id) {
      handleGetAwaitingReimburseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id, department_id]);

  const handleGetAwaitingReimburseData = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: department_id || userDepartmentDrop[0]?.id,
      currency_conversion_id: "",
    };

    const data = await dispatch(getEmployeeAwaitingReimburseGraph(obj));
    // console.log(data, "data ");
    if (data?.payload?.success) {
      const totalReimburseVal = data?.payload?.data?.total_actual_reimburse;
      const reimburseVal = data?.payload?.data?.reimburse_actual_graph;
      let reimburseGraphVal = [];
      // console.log(netProfitGraph, "net profit graph");
      for (const reimburseObj of reimburseVal) {
        reimburseGraphVal.push(parseInt(reimburseObj?.value));
        // console.log(netProfitObj, "net profit chart");
      }
      // for(const netProfitData p)
      setReimburseGraph(reimburseGraphVal);
      setTotalReimburse(totalReimburseVal);
    }
  };

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    // const linkTwo = splitLink[1];
    // // console.log("linkTwo", linkTwo);
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // console.log("linkTwo", linkTwo);
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      currency_conversion_id: "",
      dept_id: department_id || userDepartmentDrop[0]?.id,
      user_id: user?.id,
      page: linkTwo,
    };
    dispatch(getEmployeeAwaitingReimburseGraphPagination(obj));
  };

  const optionList = [
    {
      label: "Default",
      value: 1,
    },
    {
      label: "Current Fiscal Year",
      value: 2,
    },
    {
      label: "Previous Year",
      value: 3,
    },
    {
      label: "Today",
      value: 4,
    },
    {
      label: "Yesterday",
      value: 5,
    },
    {
      label: "Last 7 days",
      value: 6,
    },
    {
      label: "Monthly",
      value: 7,
    },
  ];

  const tableHeadList = ["Product", "Units", "Unit Price", "Revenue"];

  return (
    <>
      <EmployeeNetProfitProjection
        reimburseVal={formatNumber(
          String(
            FormatNumWithCurrency(
              totalReimburse,
              actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
              actionCtx?.currencyValue
            ) || 0
          )
        )}
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={` ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          ></div>
        )}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Awaiting Reimbursement Graph</p>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Monthly"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={optionList}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              {/* <EmployeeMonthlyExpenseChart /> */}
              <EmployeeNetProfitProjectionGraph
                loading={loading}
                dataValue={reimburesGraph}
              />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            dontShowPagination={actualReimburseData?.data?.length < 1}
            fromPage={actualReimburseData?.from}
            toPage={actualReimburseData?.to}
            nextPage={actualReimburseData?.next_page_url}
            prevPage={actualReimburseData?.prev_page_url}
            onNextPage={() => {
              handlePagination(actualReimburseData?.next_page_url);
            }}
            onPrevPage={() =>
              handlePagination(actualReimburseData?.prev_page_url)
            }
            totalPage={actualReimburseData?.total}
            // action
            // onNextPage
          >
            {actualReimburseData?.data?.map((chi, idx) => {
              const {
                chart_of_account,
                units,
                unit_price,
                total_expense,
                // status,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={chart_of_account?.name}
                  loading={loading}
                  two={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        units,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  three={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        unit_price,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  four={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        total_expense,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  // action
                  // //   progressType={status}
                  // rowNo={idx}
                  // indexNo={indexNo}
                  // setIndexNo={() => setIndexNo(idx)}
                  // // updateText={` Update Branch`}
                  // //   onNextPage={() => {}}
                  // onOverview={() =>
                  //   setShowModal((prev) => {
                  //     return { ...prev, overview: true };
                  //   })
                  // }
                  // updateText={`Modify`}
                  // approveText={"Approve"}
                  // viewText={`view comments`}
                  // deleteText={`reject`}
                  // exportText={`export as PDF`}
                  // onUpdate={() =>
                  //   history.push("/employee-dashboard-projection-revenue-new")
                  // }
                  // onApprove={() =>
                  //   setShowModal((prev) => {
                  //     return { ...prev, approveTwo: true };
                  //   })
                  // }
                  // onView={() =>
                  //   setShowModal((prev) => {
                  //     return { ...prev, commentTwo: true };
                  //   })
                  // }
                  // onDelete={() =>
                  //   setShowModal((prev) => {
                  //     return { ...prev, rejectTwo: true };
                  //   })
                  // }
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </EmployeeNetProfitProjection>
      {/* modal start */}
      {/* <Modal effect="fadeInDown" visible={showModal.overview}>
        <EmployeeOverviewPendingReinbursementModal
          onNextPage={() =>
            history.push("/employee-dashboard-projection-revenue-new")
          }
          rejectText={true}
          nextPage
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, overview: false };
            })
          }
          onApprove={() =>
            history.push(
              "/employee-dashboard-projection-revenue-submitted-total"
            )
          }
          onReject={() =>
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            })
          }
          onViewBigImg={() => {
            setShowModal((prev) => {
              return { ...prev, overview: false, big_img: true };
            });
          }}
        />
      </Modal> */}
      {/* modal end */}
      {/* modal start */}
      {/* <Modal effect="fadeInLeft" visible={showModal.approve}>
        <EmployeeRevenueApprovalModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approve: false, overview: true };
            })
          }
        />
      </Modal> */}
      {/* <Modal effect="fadeInLeft" visible={showModal.approveTwo}>
        <EmployeeRevenueApprovalModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approveTwo: false };
            })
          }
        />
      </Modal> */}
      {/* modal end */}
      {/* modal start */}
      {/* <Modal effect="fadeInUp" visible={showModal.comment}>
        <EmployeeRevenueComentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, comment: false, overview: true };
            })
          }
        />
      </Modal> */}
      {/* modal end */}
      {/* modal start */}
      {/* <Modal effect="fadeInUp" visible={showModal.commentTwo}>
        <EmployeeRevenueComentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, commentTwo: false };
            })
          }
        />
      </Modal> */}
      {/* modal end */}
      {/* modal start */}
      {/* <Modal effect="fadeInRight" visible={showModal.reject}>
        <EmployeeRejectCommentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false, overview: true };
            })
          }
        />
      </Modal> */}
      {/* modal end */}
      {/* modal start */}
      {/* <Modal effect="fadeInRight" visible={showModal.rejectTwo}>
        <EmployeeRejectCommentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, rejectTwo: false };
            })
          }
        />
      </Modal> */}
      {/* modal end */}
      {/* modal start */}
      {/* <Modal effect="fadeInRight" visible={showModal.big_img}>
        <EmployeeBigImgModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, big_img: false, overview: true };
            })
          }
        />
      </Modal> */}
      {/* modal end */}
    </>
  );
};

export default EmployeePendingReimbursementIndex;
