import EmployeeActualSupplementaryIndex from "../../../component/pages/dashboard/actual/supplementary budget";
import EmployeeSupplementaryBudgetSubmitTable from "../../../component/pages/dashboard/actual/supplementary budget/budgetSubmitTable";
import EmployeeSupplementaryDraftTable from "../../../component/pages/dashboard/actual/supplementary budget/draftTable";
import EmployeeActualSupplementaryForm from "../../../component/pages/dashboard/actual/supplementary budget/form";
import EmployeeSupplementaryApprovedTable from "../../../component/pages/dashboard/actual/supplementary budget/links/approve";
import EmployeeSupplementaryPendingTable from "../../../component/pages/dashboard/actual/supplementary budget/links/pending";
import EmployeeSupplementaryRejectTable from "../../../component/pages/dashboard/actual/supplementary budget/links/reject";
import EmployeeActualSupplementarySubmit from "../../../component/pages/dashboard/actual/supplementary budget/submitted";
import EmployeeActualSupplementaryFormUpdate from "../../../component/pages/dashboard/actual/supplementary budget/update";

export const Employee_actual_supplementary_budget_route_group = [
  {
    path: "/employee-dashboard-actual-supplementary-budget",
    component: EmployeeActualSupplementaryIndex,
    goBack: true,
    pageTitle: "Supplementary Budget",
  },
  {
    path: "/employee-dashboard-actual-supplementary-budget-submitted",
    component: EmployeeActualSupplementarySubmit,
    goBack: true,
    pageTitle: "Supplementary Budget",
  },
  {
    path: "/employee-dashboard-actual-supplementary-budget-form",
    component: EmployeeActualSupplementaryForm,
    goBack: true,
    pageTitle: "Supplementary Budget",
  },
  {
    path: "/employee-dashboard-actual-supplementary-budget-form-update",
    component: EmployeeActualSupplementaryFormUpdate,
    goBack: true,
    pageTitle: "Supplementary Budget",
  },
  {
    path: "/employee-dashboard-actual-supplementary-budget-draft",
    component: EmployeeSupplementaryDraftTable,
    goBack: true,
    pageTitle: "Supplementary Budget",
  },
  {
    path: "/employee-dashboard-actual-supplementary-budget-submit",
    component: EmployeeSupplementaryBudgetSubmitTable,
    goBack: true,
    pageTitle: "Supplementary Budget",
  },
  {
    path: "/employee-dashboard-actual-supplementary-budget-approved",
    component: EmployeeSupplementaryApprovedTable,
    goBack: true,
    pageTitle: "Supplementary Budget",
  },
  {
    path: "/employee-dashboard-actual-supplementary-budget-pending",
    component: EmployeeSupplementaryPendingTable,
    goBack: true,
    pageTitle: "Supplementary Budget",
  },
  {
    path: "/employee-dashboard-actual-supplementary-budget-rejected",
    component: EmployeeSupplementaryRejectTable,
    goBack: true,
    pageTitle: "Supplementary Budget",
  },
];
