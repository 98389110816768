import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../../utils/axios";
import setAuthToken from "../../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc, customErrorId } from "../../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const createCustomReport = createAsyncThunk(
  "employee/create-custom-report",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/reports/custom-report/create-custom-report",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getMainModule = createAsyncThunk(
  "employee/get-main-module",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/custom-report/main-modules
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data?.data?.main_modules, "---check--");
        const mainModuleInfo = data?.data?.main_modules;
        await thunkAPI.dispatch(setMainModuleDrop(mainModuleInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRelatedModule = createAsyncThunk(
  "employee/get-related-module",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/custom-report/related-modules?main_module=${formData}
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const mainModuleInfo = data?.data?.related_modules;
        await thunkAPI.dispatch(setRelatedModuleDrop(mainModuleInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getAvailableColumns = createAsyncThunk(
  "employee/get-related-module",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/custom-report/available-columns?main_module=${formData?.main_module}&related_module=${formData?.related_module}`
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const mainModuleInfo = data?.data?.available_columns;
        await thunkAPI.dispatch(setAvailableColumnDrop(mainModuleInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const custom_report = createSlice({
  name: "custom_report",
  initialState: {
    // expenditureProjctions: [],
    isAuth: false,
    loading: false,
    mainModuleDrop: [],
    relatedModuleDrop: [],
    availableColumnDrop: [],
  },

  reducers: {
    setMainModuleDrop: (state, action) => {
      state.isAuth = true;
      state.mainModuleDrop = action.payload;
    },
    setRelatedModuleDrop: (state, action) => {
      state.isAuth = true;
      state.mainModuleDrop = addLabelValueFunc(action.payload);
    },
    setAvailableColumnDrop: (state, action) => {
      state.isAuth = true;
      state.availableColumnDrop = action.payload;
    },
  },

  extraReducers: {
    [createCustomReport.pending]: (state) => {
      state.loading = true;
    },
    [createCustomReport.fulfilled]: (state) => {
      state.loading = false;
    },
    [createCustomReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setExpenditureProjections,
  setMainModuleDrop,
  setRelatedModuleDrop,
  setAvailableColumnDrop,
} = custom_report.actions;

export default custom_report.reducer;
