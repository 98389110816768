import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
// import ReUseableTable from "../../../../tables/ReUseableTable";
import Modal from "react-awesome-modal";
import SharedModal from "./SharedModal";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubsidiaries,
  getSubsidiariesByFilter,
  getSubsidiariesPagination,
  getSubsidiariesWithPagination,
} from "../../../../../redux/subsidiary/subsidiary";
import ActionContext from "../../../../../context/ActionContext";
import DeleteSubsidiaryModal from "./DeleteSubsidiaryModal";
import useDebounce from "../../../../../helper/UseDebounce";

const SubsidiaryAfterCheckList = () => {
  const [singleChild, setSingleChild] = useState({});
  const [perPageval] = useState({
    label: "5",
    value: "5",
  });
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "Name", value: "name" },
    { label: "Country", value: "country" },
    { label: "Address", value: "address" },
    // { label: "Parent Company", value: "Parent Company" },
  ];
  const [refreshTable, setRefreshTable] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const actionCtx = useContext(ActionContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { subsidiaries, meta, links, loadingPage } = useSelector(
    (state) => state.subsidiary
  );
  const { user } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const tableHeadList = ["Name", "Country", "Address", "Parent Company"];

  const handleUpdateSub = (param) => {
    localStorage.setItem("single-subsidiary-id", param.id);
    history.push("/dashboard-administration-admin-settings-subsidaries-update");
  };

  // const actionCtx = useContext(ActionContext)
  useEffect(() => {
    // dispatch(getSubsidiaries())
    if (actionCtx?.activeOrg?.type === "single") {
      history.push("/dashboard-administration-admin-settings");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    user?.organizations?.length > 0 &&
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    user?.organizations?.length > 0 &&
      dispatch(getSubsidiariesWithPagination(obj));
    // eslint-disable-next-line
  }, [refreshTable]);

  const handleFinish = () => {
    setSingleChild({});
    setRefreshTable(!refreshTable);
    setShowDeleteModal(false);
  };

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    // console.log(linkTwo);
    dispatch(getSubsidiariesPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    // console.log(obj);
    // return;
    user?.organizations?.length > 0 &&
      dispatch(getSubsidiariesWithPagination(obj));
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: filterByVal?.value || "",
        org_id: actionCtx?.activeOrg?.id,
      };
      dispatch(getSubsidiariesByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if(actionCtx?.activeOrg?.type !== "group"){
      history?.goBack()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 2 of 6`}
        pageTitle={`Add Subsidiary`}
      > */}
      <div
        onClick={() => {
          console.log(actionCtx?.activeOrg?.type);
        }}
        className="admin-org-settings-wrap"
      >
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-subsidaries-main"
              )
            }
            className="active text"
          >
            Subsidiary
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>

        {/* table box start */}
        <ActualReuseableTable
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          perPageValue={perPageval}
          setPerPageValue={(e) => handleSortPerPage(e?.value)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          tableTitle={subsidiaries?.length > 1 ? "subsidiaries" : `Subsidiary`}
          tableTitleSpan={`(${subsidiaries?.length})`}
          addBtntext={`New Subsidiary`}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          // four={tableHeadList[3]}
          action
          onCreateNew={() =>
            history.push(
              "/dashboard-administration-admin-settings-add-subsidary"
            )
          }
          fromPage={meta?.from || 1}
          toPage={meta?.to || subsidiaries?.length}
          totalPage={meta?.total || subsidiaries?.length}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          loading={loadingPage && subsidiaries?.length < 1}
          loadingNum={6}
        >
          {subsidiaries?.map((chi, idx) => {
            const { name, country, address, organization, id } = chi;
            return (
              <TableBodyRow
                key={id}
                one={name}
                two={country}
                three={address}
                four={organization && organization?.name}
                action
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                updateText={` Update Subsidiary`}
                deleteText={`Delete Subsidiary`}
                onDelete={() => {
                  setSingleChild(chi);
                  setShowDeleteModal(true);
                }}
                onUpdate={() => handleUpdateSub(chi)}
                loading={loadingPage}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
        {actionCtx?.activeOrg?.check_list?.percentage !== 100 && (
          <div className="btn-box-wrap">
            <button
              onClick={() => {
                history.push(
                  "/dashboard-administration-admin-settings-add-branch"
                );
              }}
              className="btn-reuse btn-two"
            >
              Go to the next step
            </button>
          </div>
        )}
      </div>
      {/* </DashboardLayout> */}

      {/* modal start  */}
      <Modal visible={showModal} effect="fadeInUp">
        <SharedModal onCancel={() => setShowModal(false)}>
          <div className="btn-box">
            <button className="btn-white">Go to the next step</button>
            <ButtonBlue
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-add-subsidary-shared-service"
                )
              }
              text={`Setup Shared Service`}
            />
          </div>
        </SharedModal>
      </Modal>
      {/* modal end */}
      {/* modal start  */}
      <Modal visible={showDeleteModal} effect="fadeInUp">
        <DeleteSubsidiaryModal
          details={singleChild}
          onFinish={handleFinish}
          onClose={() => setShowDeleteModal(false)}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default SubsidiaryAfterCheckList;
