import React from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/admin-settings/AdminSettings.css";
import { FaAngleRight } from "react-icons/fa";

const UserInvite = () => {
  return (
    <>
      <DashboardLayout
        goBack
        pageTitle={`Set/Invite Admin`}
        pageTitleSpan={`Step 1 of 5`}
      >
        <div className="admin-org-settings-wrap">
          <div className="subtitle-box">
            <p className="text">Organization</p>
            <FaAngleRight className="icon" />
            <p className="text">Users</p>
          </div>
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Admin Invite</p>
            <p className="text">
              Let another member perform managerial tasks, such as setting up
              departments, adding users - by giving them administrative roles.
              When a member with an admin role signs into his/her account via
              invite, they’ll automatically have access to additional management
              controls.
            </p>
          </div>
          {/* text tititl box end */}
          {/* form box start */}
          <div className="form-box">
            
          </div>
          {/* form box end */}
        </div>
      </DashboardLayout>
    </>
  );
};

export default UserInvite;
