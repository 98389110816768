import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc, customErrorId } from "../../../helper/Helpers";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getExpenseLimits = createAsyncThunk(
  "admin/get-expensense-limits",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/expense-limits/organization/${formData}&currency_conversion_id=${formData?.cur_id}`
      );
      //   console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setExpenseLimits(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // const departmentsInfo = data.data.classes;
        await thunkAPI.dispatch(setexpenseLimitDrop(data.data.expense_limits));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenseLimitWithPagination = createAsyncThunk(
  "admin/get-expense-limit-with-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/expense-limits/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}&currency_conversion_id=${formData?.cur_id}`
      );
        // console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setExpenseLimits(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setExpenseLimits(data.data.expense_limits));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenseLimitByFilter = createAsyncThunk(
  "admin/get-expense-limit-filter",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/admin/expense-limits/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}&currency_conversion_id=${formData?.cur_id}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // toastId: customErrorId,
        // });
        // await thunkAPI.dispatch(setExpenseLimits(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const verticalInfo = data.data.expense_limits;
        await thunkAPI.dispatch(setExpenseLimitFilter(verticalInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenseLimitPagination = createAsyncThunk(
  "admin/get-expense-limit-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}&currency_conversion_id=${formData?.cur_id}`);
      if (!data?.success) {
        // await thunkAPI.dispatch(setExpenseLimits(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const verticalInfo = data.data.expense_limits;
        await thunkAPI.dispatch(setExpenseLimits(verticalInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenseLimit = createAsyncThunk(
  "admin/get-expense-limit",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/expense-limits/${formData}`);
      console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createExpenseLimit = createAsyncThunk(
  "admin/create-expense-limit",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/expense-limits", formData);
      //   console.log('class', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteExpenseLimit = createAsyncThunk(
  "admin/delete-expense-limit",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/expense-limits/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateExpenseLimit = createAsyncThunk(
  "admin/update-expense-limit",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/expense-limits/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        toastId: customErrorId,
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const expense_limit = createSlice({
  name: "expense-limit",
  initialState: {
    expenseLimitDrop: [],
    expense_limits: [],
    links: {},
    meta: {},
    expense_limit: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setExpenseLimits: (state, action) => {
      state.isAuth = true;
      state.expense_limits = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setExpenseLimitFilter: (state, action) => {
      state.isAuth = true;
      state.expense_limits = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setexpenseLimitDrop: (state, action) => {
      state.isAuth = true;
      state.expenseLimitDrop = addLabelValueFunc(action.payload);
    },
    setExpenseLimit: (state, action) => {
      state.isAuth = true;
      state.expense_limit = action.payload;
    },
  },
  extraReducers: {
    [createExpenseLimit.pending]: (state) => {
      state.loading = true;
    },
    [createExpenseLimit.fulfilled]: (state) => {
      state.loading = false;
    },
    [createExpenseLimit.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateExpenseLimit.pending]: (state) => {
      state.loading = true;
    },
    [updateExpenseLimit.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateExpenseLimit.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenseLimitPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenseLimitPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenseLimitPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenseLimitByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenseLimitByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenseLimitByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenseLimitWithPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenseLimitWithPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenseLimitWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteExpenseLimit.pending]: (state) => {
      state.loading = true;
    },
    [deleteExpenseLimit.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteExpenseLimit.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setExpenseLimit,
  setExpenseLimits,
  setexpenseLimitDrop,
  setExpenseLimitFilter,
} = expense_limit.actions;

export default expense_limit.reducer;
