import EmployeeActualExpenseIndex from "../../../component/pages/dashboard/actual/expense";
import EmployeeActualDraftExpense from "../../../component/pages/dashboard/actual/expense/draftExpenses";
import EmployeeActualNewExpense from "../../../component/pages/dashboard/actual/expense/form";
import EmployeeExpenseSubmitApproveTable from "../../../component/pages/dashboard/actual/expense/links/approve";
import EmployeeExpenseSubmitPendingTable from "../../../component/pages/dashboard/actual/expense/links/pending";
import EmployeeExpenseSubmitRejectTable from "../../../component/pages/dashboard/actual/expense/links/reject";
import EmployeeActualSubmit from "../../../component/pages/dashboard/actual/expense/SubmitActual";
// import EmployeeActualSubmitExpense from "../../../component/pages/dashboard/actual/expense/submitExpenses";
import EmployeeActualNewExpenseUpdate from "../../../component/pages/dashboard/actual/expense/UpdateActualForm";

export const Employee_actual_expense_route_group = [
  {
    path: "/employee-dashboard-actual-expense",
    component: EmployeeActualExpenseIndex,
    goBack: true,
    pageTitle: "Actual Expense",
  },
  {
    path: "/employee-dashboard-actual-expense-update",
    component: EmployeeActualNewExpenseUpdate,
    goBack: true,
    pageTitle: "Actual Expense",
  },
  {
    path: "/employee-dashboard-actual-expense-submitted",
    component: EmployeeActualSubmit,
    goBack: true,
    pageTitle: "Actual Expense",
  },
  {
    path: "/employee-dashboard-actual-expense-new",
    component: EmployeeActualNewExpense,
    goBack: true,
    pageTitle: "Actual Expense",
  },
  {
    path: "/employee-dashboard-actual-expense-draft-submit",
    component: EmployeeActualDraftExpense,
    goBack: true,
    pageTitle: "Actual Expense",
  },
  // {
  //   path: "/employee-dashboard-actual-expense-submitted",
  //   component: EmployeeActualSubmitExpense,
  //   goBack: true,
  //   pageTitle: "Actual Expense",
  // },
  {
    path: "/employee-dashboard-actual-expense-submitted-approved",
    component: EmployeeExpenseSubmitApproveTable,
    goBack: true,
    pageTitle: "Actual Expense",
  },
  {
    path: "/employee-dashboard-actual-expense-submitted-pending",
    component: EmployeeExpenseSubmitPendingTable,
    goBack: true,
    pageTitle: "Actual Expense",
  },
  {
    path: "/employee-dashboard-actual-expense-submitted-rejected",
    component: EmployeeExpenseSubmitRejectTable,
    goBack: true,
    pageTitle: "Actual Expense",
  },
];
