import React from "react";
import { Line } from "react-chartjs-2";
import "../../../../../../employee-folder/styles/dashboard/home/chart/LineChart.scss";
// import Select from "react-select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { numberFormatChart } from "../../../../../../helper/Helpers";
import { Oval } from "react-loader-spinner";
// import Select from "react-select/dist/declarations/src/Select";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EmployeeReinburstmentChart = ({
  children,
  dataValue,
  actualValue,
  pending,
  approve,
  loading,
}) => {
  const data = {
    options: {
      //   maintainAspectRatio: false,
      // responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        title: {
          display: false,
          text: "Chart.js Line Chart",
        },
      },
      scales: {
        y: {
          grid: {
            // display: false,
            drawBorder: false,
          },
          ticks: {
            // min: 10,
            // max: 5,

            padding: 30,
            callback: (val) => `${numberFormatChart(val)}`,

            // stepSize: 10,
          },
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
          // ticks: {
          //   padding: 20,
          //   // gap:10,
          //   // margin: 20,
          // },
        },
      },
    },
  };

  const dataVal = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Actual",
        // data: [10, 20, 40, 35, 78, 45, 56, 54, 97, 9, 10, 79],
        data: actualValue,
        borderColor: "#FCA549",
        backgroundColor: "#FCA549",
        tension: 0.2,
        pointBorderColor: "#FFFF",
      },
      {
        label: "Awaiting",
        // data: [10, 20, 40, 35, 18, 45, 56, 34, 97, 99, 100, 99],
        data: dataValue,
        borderColor: "#07A287",
        tension: 0.2,
        pointBorderColor: "#FFFF",
        backgroundColor: "#07A287",
      },
    ],
  };

  if (loading) {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "25rem",
            display: "grid",
            placeItems: "center",
          }}
          className=""
        >
          <Oval
            height={70}
            width={70}
            color={`#020202`}
            wrapperStyle={{}}
            wrapperClass="loader-spiner-react"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="rgba(0,0,0, .2)"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      </>
    );
  }

  return (
    <>
      {/* <Line
        width={100}
        height={30}
        data={data.data}
        options={data.options}
        ticks={data.ticks}
      >
        {children}
      </Line> */}
      <div style={{ width: "98%" }}>
        <Line
          width={100}
          height={35}
          data={dataVal}
          options={data.options}
          ticks={data.ticks}
        />

        {children}
      </div>
    </>
  );
};
export default EmployeeReinburstmentChart;
