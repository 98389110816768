import React from "react";
import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
import signGoogleIcon from "../../../assets/sign-google-icon.svg";
import signMailIcon from "../../../assets/sign-mail-icon.svg";
import "../../../styles/auth/AuthLayout.css";
// import Aos from "aos";
// import "aos/dist/aos.css";

const GetStarted = () => {
  const history = useHistory();

  return (
    <>
      <AuthLayout getHelp title="Register your account">
        {/* get started */}
        {/* btn start */}
        <div
          className="btn-box btn-box-first"
          onClick={() => history.push("/sign-up")}
        >
          <figure className="img-box">
            <img src={signMailIcon} alt="" className="img" />
          </figure>
          <p className="text">Sign up with email</p>
        </div>
        {/* btn end */}
        {/* or box start */}
        <div className="or-box">
          <span></span>
          <span>or</span>
          <span></span>
        </div>
        {/* or box end */}
        {/* btn start */}
        <div className="btn-box" onClick={() => history.push("/sign-up")}>
          <figure className="img-box">
            <img src={signGoogleIcon} alt="" className="img" />
          </figure>
          <p className="text">Sign up with Google</p>
        </div>
        {/* btn end */}

        {/* terms and privacy text start */}
        <div className="term-privacy-box">
          <p className="text">
            By clicking the button above, you agree to our{" "}
            <span
              onClick={() => window.open("http://revvex.io/terms-of-service")}
            >
              Terms of Service
            </span>{" "}
          </p>
          <p className="text">
            {" "}
            and{" "}
            <span
              onClick={() => window.open("http://revvex.io/privacy-policy ")}
            >
              Privacy Policy.
            </span>
          </p>
        </div>
        {/* terms and privacy text end */}

        {/* already text start */}
        <div className="already-box">
          <p className="text">
            Already have an account?{" "}
            <span onClick={() => history.push("/login")}>Login</span>
          </p>
        </div>
        {/* already text end */}
      </AuthLayout>
    </>
  );
};

export default GetStarted;
