import React, { useContext, useEffect, useState } from "react";
import BudgetMain from "..";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import CommonExpenditureLink from "./commonLink";
import Modal from "react-awesome-modal";
import AddBudgetExpAccountModal from "../modals/AddBudgetExpAccountModal";
import UpdateBudgetExpAccountModal from "../modals/UpdateExpAccontModal";
import DeleteBudgetExpAccountModal from "../modals/DeleteExpAccountModal";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { getClasses } from "../../../../../../redux/budget-setting/expenditure-projection/class";
// import { getCategories } from "../../../../../../redux/budget-setting/expenditure-projection/categories";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  getChatAccountByFilter,
  getChatAccountPagination,
  getChatOfAccountWithPagination,
} from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";

const tableHeadList = [
  "Name",
  "Description",
  "Code",
  "Class",
  "Category",
  "Action",
];

const BudgetExpAccount = () => {
  const [indexNo, setIndexNo] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const { chatAccounts, meta, links, loadingPage } = useSelector(
    (state) => state.chatOfAccount
  );
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      dispatch(getClasses(actionCtx?.activeOrg?.id));
      // dispatch(getCategories(actionCtx?.activeOrg?.id));
      dispatch(getChatOfAccountWithPagination(obj));
    }
    // eslint-disable-next-line
  }, [refreshTable]);

  const handleFinish = () => {
    actionCtx?.setIsModalOut(false);
    setShowModal((prev) => {
      return { add: false, edit: false, delete: false };
    });
    setRefreshTable(!refreshTable);
  };

  const handleDelete = (param) => {
    setSingleChild(param);
    setShowModal((prev) => {
      return { ...prev, delete: true };
    });
  };

  const handleUpdate = (param) => {
    // console.log(param);
    // return;
    actionCtx?.setIsModalOut(true);
    const obj = {
      ...param,
      projection_class_id: {
        label: param?.projection_class?.name,
        value: param?.projection_class?.id,
      },
      projection_category_id: {
        label: param?.projection_category?.name,
        value: param?.projection_category?.id,
      },
    };
    setSingleChild(obj);
    setShowModal((prev) => {
      return { ...prev, edit: true };
    });
  };

  const handleEditSingleChild = (e, param) => {
    // e.preventDefault();
    if (param === "class") {
      setSingleChild((prev) => {
        return { ...prev, projection_class_id: e };
      });
    }
    if (param === "category") {
      setSingleChild((prev) => {
        return { ...prev, projection_category_id: e };
      });
    }
    if (param === "check") {
      // console.log(e);
      setSingleChild((prev) => {
        return { ...prev, should_spread_budget_item: e };
      });
    }
    if (e && !param) {
      const { name, value } = e?.target;
      const data = { ...singleChild, [name]: value };
      setSingleChild(data);
    }
  };

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getChatAccountPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    // console.log(obj);
    user?.organizations?.length > 0 &&
      dispatch(getChatOfAccountWithPagination(obj));
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: "",
        org_id: actionCtx?.activeOrg?.id,
      };
      // console.log(obj);
      dispatch(getChatAccountByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink
          title={`Expenditure Projection`}
          textOne={`Classes`}
          textTwo={`Categories`}
          textThree={`Chart of account`}
          linkOne={`/dashboard-administration-admin-settings-budget-exp-classes`}
          linkTwo={`/dashboard-administration-admin-settings-budget-exp-categories`}
          linkThree={`/dashboard-administration-admin-settings-budget-exp-account`}
        />
        <div className="table-box-budget">
          {/* table box start */}
          <ActualReuseableTable
            fromPage={meta?.from || 1}
            toPage={meta?.to || chatAccounts?.length}
            totalPage={meta?.total || chatAccounts?.length}
            nextPage={links?.next}
            prevPage={links?.prev}
            onNextPage={() => handlePagination(links?.next)}
            onPrevPage={() => handlePagination(links?.prev)}
            onSearchTable={(e) => {
              setSearch(e);
            }}
            perPageValue={actionCtx?.perPage?.value}
            setPerPageValue={(e) => {
              // setPerpageVal(e);
              handleSortPerPage(e.value);
            }}
            tableTitle={
              chatAccounts?.length > 1
                ? `Chart of accounts`
                : "Chart of account"
            }
            dontShowSelect
            // tableTitle={`Chart of account`}
            tableTitleSpan={`(2)`}
            addBtntext={`New chart of account`}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            action
            onCreateNew={() => {
              actionCtx?.setIsModalOut(true);
              setShowModal((prev) => {
                return { ...prev, add: true };
              });
            }}
            loading={loadingPage && chatAccounts?.length < 1}
            loadingNum={7}
          >
            {chatAccounts?.map((chi, idx) => {
              const {
                projection_class,
                description,
                projection_category,
                code,
                name,
              } = chi;
              return (
                <TableBodyRow
                  loading={loadingPage}
                  key={idx}
                  one={name}
                  two={description}
                  three={code || "---"}
                  four={projection_class?.name || <>&nbsp;</>}
                  five={projection_category?.name || <>&nbsp;</>}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  updateText={`Update`}
                  deleteText={`Delete`}
                  onUpdate={() => {
                    handleUpdate(chi);
                  }}
                  onDelete={() => handleDelete(chi)}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
      </BudgetMain>

      <Modal effect="fadeInUp" visible={showModal.add}>
        <AddBudgetExpAccountModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, add: false };
            });
          }}
          onFinish={handleFinish}
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.edit}>
        <UpdateBudgetExpAccountModal
          onFinish={handleFinish}
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, edit: false };
            });
            setSingleChild({});
          }}
          onEdit={handleEditSingleChild}
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.delete}>
        <DeleteBudgetExpAccountModal
          onFinish={handleFinish}
          detail={singleChild}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
    </>
  );
};

export default BudgetExpAccount;
