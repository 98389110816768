import EmployeeActivateMessage from "../../component/pages/auth/ActivateMessage"
import EmployeeCreateNewPassword from "../../component/pages/auth/createNewPassword";
import EmployeeCreatePassword from "../../component/pages/auth/CreatePassword";
import EmployeeForgetPassword from "../../component/pages/auth/forgotPassword";
import EmployeeLogin from "../../component/pages/auth/login";
import EmployeeRecoverPassword from "../../component/pages/auth/recoverPassword";



export const Employee_onboarding_route_group = [
  { path: "/employee-active-message", component: EmployeeActivateMessage },
  {path: '/employee-activate-account', component: EmployeeCreatePassword},
  {path: "/employee-login", component: EmployeeLogin},
  {path:"/employee-forget-password", component: EmployeeForgetPassword},
  {path:"/employee-recover-password", component: EmployeeRecoverPassword},
  {path:"/employee-create-new-password", component: EmployeeCreateNewPassword}
];
