import React from "react";
import "../../../../styles/dashboard/projection/expense/index.css";
import illustrationIcon from "../../../../../assets/Illustration-icon.svg";

const EmployeeActualDraftExpense = ({
  children,
  currentMessage,
  expenseText,
  revenueText,
}) => {
  return (
    <div className="employee-draft-container employee-draft-container-two">
      {/* ilustration icon  */}
      <figure style={{ marginTop: "-4rem" }}>
        <img src={illustrationIcon} alt="" />
      </figure>
      {/* ilustration icon  */}

      <div>
        {currentMessage || (
          <p>
            You currently do not have any saved or created actual
            <br /> {expenseText || `expense`}. Click on the button below to
            create {expenseText || `expense`}
          </p>
        )}
        <div className="emp-draft-btn">{children}</div>
      </div>
      {/* {children} */}
    </div>
  );
};
export default EmployeeActualDraftExpense;
