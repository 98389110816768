import React, { useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH, FaTimes } from "react-icons/fa";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import "../../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
// import ButtonBlue from "../../../../../buttons/ButtonBlue";
// import imgOne from "../../../../../../../assets/hod-two-img.svg";
// import cardviewImg from "../../../../../../../assets/view-card-img.png";
import ApprovalContent from "../../../actual/modals/contentHead";
// import ContentBody from "../../../actual/modals/contentBody";
import EmployeeOverviewTableBodyRow from "../../../home/modals/Reimbursement/overviewTableRow";
// import userImg from "../../../../../../../assets/hod-one-img.svg";
import DotLoader from "../../../../../../loader/dots";
import { formatNumber } from "../../../../../../../helper/Helpers";
import { useSelector } from "react-redux";
import userImg from "../../../../../../../assets/Unknown_person.jpeg";

const EmployeeRevenueOverviewModalPending = ({
  onCancel,
  onApprove,
  onReject,
  onComment,
  onViewBigImg,
  rejectText,
  onUpdate,
  approveBtn,
  update,
  data,
  details,
}) => {
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });
  const [showActions, setShowActions] = useState(false);
  const { user } = useSelector((state) => state.user);

  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  const contentHeader = ["Months", "Units", "Total Value"];
  const contentHeaderTwo = ["Level", "Name", "Status"];

  // console.log(details)

  return (
    <div className="overview-card-modal-wrap">
      <div className="top-box">
        <p className="title">Overview</p>
        <div style={{ visibility: "hidden" }}>
          <ButtonBlue onClick={onApprove} text={`Approve`} />
        </div>
        <div>
          {approveBtn && <ButtonBlue onClick={onApprove} text={`Approve`} />}
        </div>
        <div
          onClick={() => setShowActions(!showActions)}
          className="ellipse-box"
        >
          <FaEllipsisH className="icon" />
          {/* action box start */}
          <div className={`action-box ${showActions && "action-box-active"}`}>
            {update && (
              <p onClick={onUpdate}>
                <span style={{ color: "#5B6871" }}>Update</span>
              </p>
            )}
            <p onClick={onComment}>
              <span style={{ color: "#5B6871" }}>View Comments</span>
            </p>
            {rejectText && (
              <p onClick={onReject} style={{ color: "#CC0905" }}>
                <span>Reject</span>
              </p>
            )}
            <p style={{ marginTop: "5.6px" }}>
              <span>Export as PDF</span>
            </p>
          </div>
          {/* action box end */}
        </div>
        <div onClick={onCancel} className="cancel-box">
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      <div className="content-box">
        {/* title drop control box start */}
        <>
          <div
            onClick={() => handleShowBox("one")}
            className="title-drop-control-box"
          >
            <p className="title">Budget Owner Details </p>
            <div className="icon-box">
              {!showBox.one && <FaAngleDown className="icon" />}
              {showBox.one && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.one && (
            <div className="content-wrap">
              <div className="three-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Product</p>
                  <p className="value">
                    {" "}
                    {details?.product?.name === undefined ? (
                      <DotLoader />
                    ) : (
                      `${details?.product?.name}`
                    )}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Vertical</p>
                  <p className="value">
                    {details?.vertical?.name === undefined ? (
                      <DotLoader />
                    ) : (
                      `${details?.vertical?.name}`
                    )}
                  </p>
                </div>
                {/* box start */}
                <div className="box">
                  <p className="text">Units</p>
                  <p className="value">
                    {details?.unit === undefined ? (
                      <DotLoader />
                    ) : (
                      `${formatNumber(String(details?.unit))}`
                    )}
                  </p>
                </div>
                {/* box end */}
                {/* box end */}
                {/* box start */}
                {/* <div className="box">
                  <p className="text">Units</p>
                  <p className="value">1.00</p>
                </div> */}
                {/* box end */}
              </div>
              <div className="three-box">
                <div className="box">
                  <p className="text">Unit Price</p>
                  <p className="value">
                    {" "}
                    {details?.unit_price === undefined ? (
                      <DotLoader />
                    ) : (
                      `${formatNumber(String(details?.unit_price))}`
                    )}
                  </p>
                </div>
                {/* box start */}
                <div className="box">
                  <p className="text">GP</p>
                  <p className="value">
                    {" "}
                    {details?.gpp === undefined ? (
                      <DotLoader />
                    ) : (
                      `${formatNumber(String(details?.gpp))}`
                    )}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Total Income Projected</p>
                  <p className="value">
                    {" "}
                    {details?.gpp === undefined ? (
                      <DotLoader />
                    ) : (
                      `${formatNumber(String(details?.total_revenue_project))}`
                    )}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                {/* <div className="box">
                  <p className="text">Total Value</p>
                  <p className="value">20,000.00</p>
                </div> */}
                {/* box end */}
              </div>
              <div className="three-box">
                <div className="box">
                  <p className="text">Subsidiary</p>
                  <p className="value">
                    {" "}
                    {details?.subsidiary?.name === undefined ? (
                      <DotLoader />
                    ) : (
                      `${details?.subsidiary?.name}`
                    )}
                  </p>
                </div>
                {/* box start */}
                <div className="box">
                  <p className="text">Cleintele Account</p>
                  <p className="value">
                    {" "}
                    {details?.clientele_account === undefined ? (
                      <DotLoader />
                    ) : (
                      `${details?.clientele_account}`
                    )}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Revenue</p>
                  <p className="value">
                    {" "}
                    {details?.total_revenue_project === undefined ? (
                      <DotLoader />
                    ) : (
                      `${formatNumber(String(details?.total_revenue_project))}`
                    )}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                {/* <div className="box">
                  <p className="text">Total Value</p>
                  <p className="value">20,000.00</p>
                </div> */}
                {/* box end */}
              </div>
              <div className="two-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Description</p>
                  <p className="value">
                    {" "}
                    {details?.description === undefined ? (
                      <DotLoader />
                    ) : (
                      `${details?.description}`
                    )}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Department</p>
                  <p className="value">
                    {" "}
                    {details?.department?.name === undefined ? (
                      <DotLoader />
                    ) : (
                      `${details?.department?.name}`
                    )}
                  </p>
                </div>
                {/* box end */}
              </div>
              <div className="two-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Date Created</p>
                  <p className="value">
                    {" "}
                    {details?.created_at === undefined ? (
                      <DotLoader />
                    ) : (
                      `${details?.created_at}`
                    )}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Last Updated</p>
                  <p className="value">
                    {" "}
                    {details?.updated_at === undefined ? (
                      <DotLoader />
                    ) : (
                      `${details?.updated_at}`
                    )}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                {/* <div className="box">
                  <p className="text">Last Updated</p>
                  <p className="value">26 May, 2022 - 7:08pm</p>
                </div> */}
                {/* box end */}
              </div>

              {/* created by wrap start */}
              <div className="one-box">
                <div className="box">
                  <p className="text">Created by</p>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="img-text-box"
                  >
                    <figure style={{ marginRight: "1rem" }} className="img-box">
                      <img
                        style={{
                          borderRadius: "20%",
                          width: "4rem",
                          height: "4rem",
                        }}
                        src={user?.profile_picture || userImg}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <div className="text-box">
                      <p className="value">
                        {" "}
                        {details?.createdBy?.full_name === undefined ? (
                          <DotLoader />
                        ) : (
                          `${details?.createdBy?.full_name}`
                        )}
                      </p>
                      <p style={{ marginBottom: "unset" }} className="text">
                        {details?.createdBy?.email === undefined ? (
                          <DotLoader />
                        ) : (
                          `${details?.createdBy?.email}`
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* created by wrap end */}
              {/* image wrap box start */}
              {/* <div className="image-wrap-box"> */}
              {/* img view start */}
              {/* <div onClick={onViewBigImg} className="box-img">
                  <figure className="img-box">
                    <img src={cardviewImg} alt="" className="img" />
                  </figure>
                </div> */}
              {/* img view end */}
              {/* img view start */}
              {/* <div onClick={onViewBigImg} className="box-img">
                  <figure className="img-box">
                    <img src={cardviewImg} alt="" className="img" />
                  </figure>
                </div> */}
              {/* img view end */}
              {/* img view start */}
              {/* <div onClick={onViewBigImg} className="box-img">
                  <figure className="img-box">
                    <img src={cardviewImg} alt="" className="img" />
                  </figure>
                </div> */}
              {/* img view end */}
              {/* img view start */}
              {/* <div onClick={onViewBigImg} className="box-img">
                  <figure className="img-box">
                    <img src={cardviewImg} alt="" className="img" />
                  </figure>
                </div> */}
              {/* img view end */}
              {/* </div> */}
              {/* image wrap box end */}
            </div>
          )}
        </>
        {/* title drop control box end */}

        {/* title drop control box start */}
        <>
          <div
            onClick={() => handleShowBox("two")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Monthly Breakdown</p>
            <div className="icon-box">
              {!showBox.two && <FaAngleDown className="icon" />}
              {showBox.two && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.two && (
            <div>
              <ApprovalContent
                headerOne={contentHeader[0]}
                headerTwo={contentHeader[1]}
                headerThree={contentHeader[2]}
              />
              {details?.monthly_breakdown[0]?.map((breakdown, idx) => {
                const {
                  month_name,
                  value: total_value,
                  // value: units,
                } = breakdown;
                return (
                  <>
                    <EmployeeOverviewTableBodyRow
                      key={idx}
                      one={month_name}
                      two={
                        details?.entry_type === "quantity"
                          ? formatNumber(String(total_value))
                          : formatNumber(String(details?.units / 12)) ||
                            details?.entry_type === "amount"
                          ? formatNumber(
                              String(total_value / details?.unit_price)
                            )
                          : formatNumber(String(details?.units / 12))
                      }
                      three={
                        details?.entry_type === "quantity"
                          ? formatNumber(
                              String(total_value * details?.unit_price)
                            )
                          : formatNumber(String(total_value))
                      }
                    />
                  </>
                );
              })}
            </div>
          )}
          {/* {showBox.two && <div className="content-wrap"></div>} */}
        </>
        {/* title drop control box end */}
        {/* title drop control box start */}
        <>
          <div
            onClick={() => handleShowBox("three")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Approval Flow</p>
            <div className="icon-box">
              {!showBox.three && <FaAngleDown className="icon" />}
              {showBox.three && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.three && (
            <div className="content-wra">
              <ApprovalContent
                headerOne={contentHeaderTwo[0]}
                headerTwo={contentHeaderTwo[1]}
                headerThree={contentHeaderTwo[2]}
              />
              {details?.approval_flow?.map((chi, idx) => {
                const { step, status, user } = chi;
                return (
                  <>
                    <EmployeeOverviewTableBodyRow
                      key={idx}
                      one={step?.toString().padStart(2, "0")}
                      userOne
                      userName={`${user?.first_name || ""} ${user?.last_name || ""}`}
                      userEmail={user?.email || ""}
                      userImg={user?.profile_picture || userImg}
                      status={
                        status === "approved"
                          ? "Approved"
                          : status === "pending"
                          ? "Pending"
                          : status === "rejected"
                          ? "Rejected"
                          : "pending"
                      }
                    />
                  </>
                );
              })}
            </div>
          )}
        </>
        {/* title drop control box end */}
      </div>
      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default EmployeeRevenueOverviewModalPending;
