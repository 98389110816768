/* eslint-disable default-case */
import React from "react";
import { Line } from "react-chartjs-2";
import "../../../../../employee-folder/styles/dashboard/home/chart/LineChart.scss";
// import Select from "react-select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useThemeDetector } from "../../../../../utils/getThemeColor";
import { useSelector } from "react-redux";
import { numberFormatChart } from "../../../../../helper/Helpers";
import { Oval } from "react-loader-spinner";
// import Select from "react-select/dist/declarations/src/Select";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BookAndBalanceChart = ({ children, pending, approve, dataToshow,  }) => {
  // const [data, setData] = useState();
  // const isDarkTheme = useThemeDetector();
  // const { preference } = useSelector((state) => state.preference);
  const data = {
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          data: [0],
          backgroundColor: "#004BFF",
          borderRadius: "3rem",
          borderColor: "#004BFF",
          tension: 0.2,
          pointBorderColor: "#FFF",
          showLine: true,
          label: " Reimbursement",
          display: false,
        },
      ],
    },
    options: {
      //   maintainAspectRatio: false,
      // responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        title: {
          display: false,
          text: "Chart.js Line Chart",
        },
      },
      scales: {
        y: {
          grid: {
            // display: false,
            drawBorder: false,
          },
          ticks: {
            callback: (val) => `${numberFormatChart(val)}`,
            color: "#1d1d18",
            padding: 20,
            // stepSize: 18,
            // suggestedMin: 0,
            // suggestedMax: -4,
          },
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            padding: 20,
            color: "#1d1d18",
          },
        },
      },
    },
  };



  return (
    <>
      {/* <Line
        width={100}
        height={30}
        data={data.data}
        options={data.options}
        ticks={data.ticks}
      >
        {children}
      </Line> */}
      <div style={{ width: "98%" }}>
        <Line
          width={100}
          height={35}
          data={dataToshow || data.data}
          options={data.options}
          ticks={data.ticks}
        />

        {children}
      </div>
    </>
  );
};
export default BookAndBalanceChart;
