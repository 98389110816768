import React, { useState } from "react";
import BudgetMain from "..";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import CommonExpenditureLink from "../expenditure-rev/commonLink";
import Modal from "react-awesome-modal"
import AddRevenueLimitModal from "../modals/AddRevenueLimitModal";
import UpdateRevenueLimitModal from "../modals/UpdateRevenueLimitModal";
import DeleteRevenueLimitModal from "../modals/DeleteRevenueLimitModal";

const tableHeadList = ["Fiscal Year", "Subsidiary", "Department", "Limit", "Action"];
const tableBodyList = [
  {
    fiscal_year: "2022",
    subsidiary: "IT Horizon",
    department: "Department",
    limit: "250,000.00",
  },
  {
    fiscal_year: "2022",
    subsidiary: "IT Horizon",
    department: "Department",
    limit: "250,000.00",
  },
];

const RevenueLimit = () => {
  const [indexNo, setIndexNo] = useState("");
  // const history = useHistory();
  const [showModal, setShowModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink title={`Revenue Limit`} />
        <div className="table-box-budget">
             {/* table box start */}
         <ActualReuseableTable
            // showBulkUpload
            dontShowSelect
            tableTitle={`Limit`}
            tableTitleSpan={`(2)`}
            addBtntext={`New Limit`}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            action
            onCreateNew={() =>
              setShowModal((prev) => {
                return { ...prev, add: true };
              })
            }
            // loading={loadingPage && expense_limits?.length < 1}
            // loadingNum={6}
          >
            {tableBodyList.map((chi, idx) => {
              const { fiscal_year, subsidiary, department, limit } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={fiscal_year}
                  two={subsidiary}
                  three={department}
                  four={limit}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  deleteText={`delete`}
                  updateText={` modify`}
                  onUpdate={() =>
                    setShowModal((prev) => {
                      return { ...prev, edit: true };
                    })
                  }
                  onDelete={() =>
                    setShowModal((prev) => {
                      return { ...prev, delete: true };
                    })
                  }
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
      </BudgetMain>

      <Modal effect="fadeInUp" visible={showModal.add}>
        <AddRevenueLimitModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, add: false };
            })
          }
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.edit}>
        <UpdateRevenueLimitModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, edit: false };
            })
          }
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.delete}>
        <DeleteRevenueLimitModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, delete: false };
            })
          }
        />
      </Modal>
    </>
  );
};

export default RevenueLimit;
