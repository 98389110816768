import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
// import Modal from "react-awesome-modal";
import EmployeeCommonExpenseLayout from "./CommonLayout";
import Modal from "react-awesome-modal";
// import EmployeeProjectionApprovalModal from "../revenue/modals/approve";
// import EmployeeProjectionComentModal from "../revenue/modals/comment";
// import EmployeeOverviewRejectModal from "../modals/rejectModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllExpenditureProjectionComment,
  getApprovedExpenditureProjectionById,
  getApprovedExpenditureProjectionByIdPagination,
  getApprovedExpenditureProjectionSubmittedBySearch,
  getUserDepartment,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import useDebounce from "../../../../../../helper/UseDebounce";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import EmployeeDraftProjection from "./EmpDraftProjection";
import { FaPlus } from "react-icons/fa";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import EmpExpRejectProjectionOverview from "./modals/rejectOverview";
import EmpExpProApproveOverview from "./modals/approveModal";
import EmpExpProjectionComentModal from "./modals/comments";
import EmpExpProRejectModal from "./modals/rejectModal";
import ActionContext from "../../../../../../context/ActionContext";
import moment from "moment";

const EmployeeProjectionReject = ({ children }) => {
  const [indexNo, setIndexNo] = useState("");

  const history = useHistory();

  const [showModal, setShowModal] = useState({
    approve: false,
    comment: false,
    rejectOverview: false,
  });

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Units",
    "Unit Price",
    "Date",
    "status",
    // "Action",
  ];

  const cardHeader = [
    "Expenditure Projected",
    "Approved",
    "Pending",
    "Rejected",
  ];
  const [singleItem, setSingleItem] = useState({});
  const cardValue = [
    singleItem?.total_projection || 0,
    singleItem?.approved || 0,
    singleItem?.awaiting_approval || 0,
    singleItem?.rejected || 0,
  ];

  const [refreshTable] = useState(false);
  const actionCtx = useContext(ActionContext);

  const { loadingPage, expenditureProjectionById } = useSelector(
    (state) => state.expenditure_projection
  );

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [dept, setDept] = useState({ label: "Select Dept..." });
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    handleGetExpenditureData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, user?.organizations[0]?.id, dept]);

  const handleGetExpenditureData = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      dept_id: dept?.id,
      status: "rejected",
    };
    const data = await dispatch(getApprovedExpenditureProjectionById(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };

  const [singleChild, setSingleChild] = useState({});

  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart Of Account", value: "chart_of_account" },
    { label: "Class", value: "class" },
    { label: "Description", value: "description" },
    { label: "Unit", value: "unit" },
    { label: "Unit Price", value: "unit_price" },
  ];

  const SortByOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
  ];
  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      status: "rejected",
      dept_id: dept?.id,
    };
    // console.log("linkTwo", obj);
    dispatch(getApprovedExpenditureProjectionByIdPagination(obj));
  };
  const handleSortChange = (param) => {
    const obj = {
      // q: search,
      sort: param?.value,
      is_submitted: 1,
      dept_id: dept?.id,
      status: "rejected",
      org_id: user?.organizations[0]?.id,
    };
    dispatch(getApprovedExpenditureProjectionSubmittedBySearch(obj));
  };

  const handleExpenditureProjectionUpdate = (param, update = false) => {
    localStorage.setItem("single-expenditure-projection-id", param.id);
    history.push("/employee-dashboard-projection-expenditure-update");
    if (update) {
      history.push("/employee-dashboard-projection-expenditure-update");
    }
  };

  const [search, setSearch] = useState("");
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const [sortByVal, setSortByVal] = useState({ label: "Sort by" });

  const debouncedSearchTerm = useDebounce(search, 1000);

  // search and filter
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        org_id: user?.organizations[0]?.id,
        search: search,
        dept_id: dept?.id,
        is_submitted: 1,
        status: "rejected",
        filter_by: filterByVal?.value,
      };
      dispatch(getApprovedExpenditureProjectionSubmittedBySearch(obj));
    } else {
      handleGetExpenditureData();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const link = [
    "/employee-dashboard-projection-expenditure-new",
    "/employee-dashboard-projection-expenditure-submitted",
    "/employee-dashboard-projection-expenditure-submitted-approved",
    "/employee-dashboard-projection-expenditure-submitted-pending",
    "/employee-dashboard-projection-expenditure-submitted-rejected",
  ];

  const handleGetCommentData = (param) => {
    // setSingleChild(param);
    dispatch(getAllExpenditureProjectionComment(param));
  };

  const handleSingleCommentData = (param) => {
    setSingleChild(param);
    if (param) {
      dispatch(getAllExpenditureProjectionComment(param?.id));
    }
  };

  return (
    <>
      <div>
        <EmployeeCommonExpenseLayout rejectLink={true}>
          <EmployeeReusableTable
            dept
            departmentDrop={userDepartmentDrop}
            deptVal={dept}
            setDepartmentVal={(e) => {
              setDept(e);
            }}
            sort={true}
            filter={true}
            optionSort={SortByOptions}
            optionSortVal={sortByVal}
            setOptionSortVal={(e) => {
              setSortByVal(e);
              handleSortChange(e);
            }}
            optionFilter={filterByOptions}
            optionFilterVal={filterByVal}
            setOptionFilterVal={(e) => setFilterByVal(e)}
            onSearchTable={(e) => {
              setSearch(e);
            }}
            addButton={expenditureProjectionById?.data?.length > 0}
            BtnTitle={`New Expenditure`}
            showSearch={true}
            loading={loadingPage}
            cardTitleOne={cardHeader[0]}
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            cardBodyOne={formatNumber(
              String(
                FormatNumWithCurrency(
                  cardValue[0],
                  actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
                  actionCtx?.currencyValue
                ) || 0
              )
            )}
            cardBodyTwo={cardValue[1]}
            cardBodyThree={cardValue[2]}
            cardBodyFour={cardValue[3]}
            firstCard={true}
            secondCard={true}
            thirdCard={true}
            fourthCard={true}
            linkCardFour={link[4]}
            onPage={() => history.push(link[0])}
            onFirstCard={() => history.push(link[1])}
            onSecondCard={() => history.push(link[2])}
            onThirdCard={() => history.push(link[3])}
            onFourthCard={() => history.push(link[4])}
            // onCard={onApprove}
          />
          {children}
          <div className="table-box">
            <ActualReuseableTable
              dontShowPagination={expenditureProjectionById?.data?.length < 1}
              dontShowBnSearch
              one={tableHeadList[0]}
              two={tableHeadList[1]}
              three={tableHeadList[2]}
              four={tableHeadList[3]}
              five={tableHeadList[4]}
              six={tableHeadList[5]}
              seven={tableHeadList[6]}
              action
              nextPage={expenditureProjectionById?.next_page_url}
              prevPage={expenditureProjectionById?.prev_page_url}
              fromPage={expenditureProjectionById?.from}
              toPage={expenditureProjectionById?.to}
              totalPage={expenditureProjectionById?.total}
              onNextPage={() =>
                handlePagination(expenditureProjectionById?.next_page_url)
              }
              onPrevPage={() =>
                handlePagination(expenditureProjectionById?.prev_page_url)
              }
            >
              {expenditureProjectionById?.data?.length > 0 ? (
                <>
                  {expenditureProjectionById?.data.map((chi, idx) => {
                    const {
                      chart_of_account,
                      description,
                      units,
                      unit_price,
                      date,
                      status,
                    } = chi;
                    return (
                      <EmpTableBodyRow
                        item={chi}
                        key={idx}
                        one={chart_of_account?.name}
                        two={chart_of_account?.class}
                        three={description}
                        four={formatNumber(String(units) || 0)}
                        five={formatNumber(
                          String(
                            FormatNumWithCurrency(
                              unit_price,
                              actionCtx?.activeOrg?.active_fiscal_year
                                ?.base_currency?.name,
                              actionCtx?.currencyValue
                            ) || 0
                          )
                        )}
                        six={moment(date).format("YYYY-MM-DD")}
                        progressType={
                          status === "approved"
                            ? "accept"
                            : status === "rejected"
                            ? "reject"
                            : "pending"
                        }
                        loading={loadingPage}
                        action
                        rowNo={idx}
                        indexNo={indexNo}
                        setIndexNo={() => setIndexNo(idx)}
                        // updateText={`Update`}
                        approveText={
                          user?.permissions?.includes(
                            "approve/reject expense projection"
                          ) && "Approve"
                        }
                        onUpdate={() => handleExpenditureProjectionUpdate(chi)}
                        viewText={"Comments"}
                        // deleteText={`Reject`}
                        exportText={`Export as PDF`}
                        onApprove={() => {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, approve: true };
                          });
                        }}
                        onView={() => {
                          // eslint-disable-next-line
                          {
                            actionCtx?.setIsModalOut(true);
                            handleSingleCommentData(chi);
                            setShowModal((prev) => {
                              return { ...prev, comment: true };
                            });
                            // setTimeout(() => {
                            // }, 1000);
                          }
                        }}
                        onRejectStatus={() => {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, rejectOverview: true };
                          });
                        }}
                      />
                    );
                  })}
                </>
              ) : (
                <EmployeeDraftProjection>
                  <ButtonBlue
                    onClick={() =>
                      history.push(
                        "/employee-dashboard-projection-expenditure-new"
                      )
                    }
                    text={`Create Expenditure`}
                  >
                    <FaPlus style={{ marginRight: "15px" }} />
                  </ButtonBlue>
                </EmployeeDraftProjection>
              )}
            </ActualReuseableTable>
          </div>
        </EmployeeCommonExpenseLayout>
      </div>

      {/* modal end */}
      <Modal visible={showModal.approve}>
        <EmpExpProApproveOverview
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
          }}
          onClose={() =>
            setShowModal((prev) => {
              actionCtx?.setIsModalOut(false);
              return { ...prev, approve: false };
            })
          }
          onRefreshCard={handleGetExpenditureData}
          onDispatch={() => {
            const obj = {
              org_id: user?.organizations[0]?.id,
              filter_submitted: true,
              status: "rejected",
            };
            dispatch(getApprovedExpenditureProjectionById(obj));
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmpExpProRejectModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal end */}
      <Modal visible={showModal.comment}>
        <EmpExpProjectionComentModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, comment: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal Effect={"fadeInOut"} visible={showModal.rejectOverview}>
        <EmpExpRejectProjectionOverview
          // update
          details={singleChild}
          data={singleChild}
          onUpdate={() => {
            localStorage.setItem(
              "single-expenditure-projection-id",
              singleChild?.id
            );
            history.push("/employee-dashboard-projection-expenditure-update");
          }}
          rejectText
          onReject={() => {
            actionCtx?.setIsModalOut(true);

            setShowModal((prev) => {
              return { ...prev, reject: true, rejectOverview: false };
            });
          }}
          onComment={() => {
            actionCtx?.setIsModalOut(true);

            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          onApprove={() => {
            actionCtx?.setIsModalOut(true);

            setShowModal((prev) => {
              return { ...prev, approve: true, rejectOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal start */}
    </>
  );
};

export default EmployeeProjectionReject;
