
import React, { useState, useRef, useEffect } from "react";
import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
import ReactPinField from "react-pin-field";
// import nameIcon from "../../../assets/name-input-icon.svg";
import "../../../styles/auth/AuthLayout.css";
import "../../../styles/auth/AuthForm.css";
import { useSelector , useDispatch} from "react-redux";
import { Dna } from "react-loader-spinner";
import { resendForgetPassword, ResetPasswordVerifyOtp } from "../../../redux/user";

const RecoverPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const { userEmail, loading } = useSelector((state) => state.user);
  const [ emailLocal ] = useState(localStorage.getItem("email-to-receive-otp"))
  const [showActive, setShowActive] = useState(false);
  const [details, setDetails] = useState({
    otp: ""
  })
  const ref = useRef();

  useEffect(() => {
    // ref.current[0].focus();
  });

  const handleResendOtp = async () => {
    // const email = localStorage.getItem("email-to-receive-otp") 
    
    dispatch(resendForgetPassword());
    // console.log(email);
  }

const handleSubmit = async (e) => {
  e.preventDefault();
  // console.log(details);
  // history.push("/create-new-password");
  // return;
  // console.log(details);
  const data = await dispatch(ResetPasswordVerifyOtp(details));
  if(data?.payload?.success){
    // console.log(data?.payload);
    history.push("/create-new-password");
  }
  // console.log(data?.payload);
  // history.push("/create-new-password");
}

  return (
    <>
      <AuthLayout
        getHelp
        title="Recover Password"
        small_text={`A four digit recovery code has been sent to your email`}
      >
        {/* get started */}
        <p style={{ fontWeight: "1.3rem", color: "#004BFF" }}>
        {userEmail || emailLocal}
        </p>
        {/* auth form start */}
        <form
          onSubmit={handleSubmit}
          action=""
          className="auth-form-wrap"
          style={{marginTop: "4rem"}}
        >
          <div className="form-group" style={{ border: "unset" }}>
            <ReactPinField
              length={4}
              className="pin-field"
              onChange={(num) => {
                setShowActive(false)
                setDetails((prev) => {
                  return {...prev, otp: num}
                })
                }}
                onComplete={() => setShowActive(true)}
                // disabled={completed}
                validate="0123456789"
                autoFocus
                ref={ref}
            />
          </div>
          {/* submit form start */}
        {!loading ?  <input
          disabled={!showActive}
            type="submit"
            value="Confirm code"
            className={`form-group btn-reuse form-submit ${
              showActive &&
              "form-submit-active"
            }`}
            style={{ alignSelf: "flex-start" , padding: "1.2rem 4rem"}}
          /> : (
            <div style={{ alignSelf: " flex-start" }}>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
             
            </div>
          )}
          {/* submit form  end */}
        </form>
        {/* auth form end */}
        {/* already text start */}
        <div className="already-box">
          <p className="text">
            Didn’t get the mail? <span onClick={handleResendOtp} >Resend</span>
          </p>
        </div>
        {/* already text end */}
      </AuthLayout>
    </>
  );
};

export default RecoverPassword;