import React from "react";
// import { useEffect } from "react";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import { submitSingleDraftForApproval } from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import "../../../../../../../styles/dashboard/modals/DeleteSharedmodal.css";
import { useHistory } from "react-router-dom";

const EmpSubmitForApproval = ({ onClose, onFinish, details }) => {
  const dispatch = useDispatch();
  const { loadingTwo } = useSelector((state) => state.expenditure_projection);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dat = { expenditure_projection_ids: [details?.id] };

    const data = await dispatch(submitSingleDraftForApproval(dat));
    if (data?.payload?.success) {
      onFinish();
      history.push("/employee-dashboard-projection-expenditure-submitted");
    }
  };

  return (
    <div className="delete-shared-modal-wrap">
      <p className="title" style={{ textTransform: "capitalize" }}>
        {`Submit Projection (${details?.chart_of_account?.name})`}
      </p>
      <p className="text">
        {` Are you sure you want to submit this projection?`}
      </p>

      <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        {loadingTwo ? (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="100"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue
            // bgColor={`var(--blue-color)`}
            text={`Yes Submit`}
            onClick={handleSubmit}
            //
          />
        )}
      </div>
    </div>
  );
};

export default EmpSubmitForApproval;
