import homeImg from "../assets/home-icon.svg";
import allUserImg from "../assets/all-users-icon.svg"
import expenditureImg from "../assets/expenditure-icon.svg"
import revenueImg from "../assets/revenue-icon.svg"
import supplementImg from "../assets/supplementary.svg"
import integrationImg from "../assets/integration-icon.svg"
import supportImg from "../assets/support-icon.svg"
import adminSetImg from "../assets/admin-setting-icon.svg"
import approvalImg from "../assets/approval-icon.svg"
import reportImg from "../assets/report-icon.svg"

export const sidebarList = [
  {
    title: "Main Menu",
    drop: "one",
    route: "/dashboard-home",
    links: [
      { name: "Home", link: "/dashboard-home", img: homeImg },
      { name: "All Users", link: "/dashboard-home-all-users", img: allUserImg},
    ],
  },
  {
    title: "Projection",
    drop: "two",
    route: "/dashboard-projection",
    links: [
      { name: "Expenditure", link: "/dashboard-projection-expense", img: expenditureImg },
      { name: "Revenue", link: "/dashboard-projection-revenue", img: revenueImg},
    ],
  },
  {
    title: "Actual",
    drop: "three",
    route: "/dashboard-actual",
    links: [
      { name: "Expense", link: "/dashboard-actual-expense", img: expenditureImg },
      { name: "Revenue", link: "/dashboard-actual-revenue", img: revenueImg},
      { name: "Supplementary Budget", link: "/dashboard-actual-supplementary-budget", img: supplementImg},
    ],
  },
  {
    title: "General",
    drop: "four",
    route: '/dashboard-general',
    links: [
      { name: "Approvals", link: "/dashboard-general-approvals", img: approvalImg },
      { name: "Report", link: "/dashboard-general-report", img: reportImg},
      { name: "Integration", link: "/dashboard-general-integration", img: integrationImg},
    ],
  },
  {
    title: "Administration",
    route: "/dashboard-administration",
    drop: "five",
    links: [
      { name: "Support", link: "/dashboard-administration-support", img: supportImg },
      { name: "Admin Settings", link: "/dashboard-administration-admin-settings", img: adminSetImg},
    ],
  },
];
