import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getSubsidiaries } from "../../../../../redux/subsidiary/subsidiary";
import ActionContext from "../../../../../context/ActionContext";
import { getBranches, getBranchesBySubsidiary } from "../../../../../redux/branch/branch";
import { getUsers } from "../../../../../redux/users/users";
import { Dna } from "react-loader-spinner";
import { createDepartment } from "../../../../../redux/department/deparment";

const AddDepartment = () => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const [refreshTable ] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { usersDrop } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { branchSubDrop, loadingPage } = useSelector((state) => state.branch);
  const { loading } = useSelector((state) => state.department);
  // const { loading } = useSelector((state) => state.subsidiary);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const [details, setDetails] = useState({
    name: "",
    subsidiary_id: "",
    branch_id: "",
    department_code: "",
    head_of_department_id: "",
    submit_to_id: "",
    organization_id: "",
    description: "",
  });

  // const actionCtx = useContext(ActionContext)
  useEffect(() => {
    if(actionCtx?.activeOrg?.type !== "group"){
      setDetails((prev) => {
        return {...prev, subsidiary_id: subsidiariesDrop[0]}
      })
      handleGetBranchBySub(subsidiariesDrop[0])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsidiariesDrop])

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...details,
      submit_to_id: details?.submit_to_id?.value,
      organization_id: actionCtx?.activeOrg?.id,
      head_of_department_id: details?.head_of_department_id?.value,
      subsidiary_id: details?.subsidiary_id?.value,
      branch_id: details?.branch_id?.value,
    };
    // console.log(actionCtx.activeOrg);
    // console.log(obj?.subsidiary_id);
    // return;
    const data = await dispatch(createDepartment(obj));
    if (data?.payload?.success) {
      setDetails({
        name: "",
        subsidiary_id: "",
        branch_id: "",
        department_code: "",
        head_of_department_id: "",
        submit_to_id: "",
        organization_id: "",
        description: "",
      });
      history.push("/dashboard-administration-admin-settings-department") 
    }
  };

  const handleGetBranchBySub = async(param) => {
  //  console.log(param?.id);
   dispatch(getBranchesBySubsidiary(param?.id));
   setDetails((prev) => {
    return {...prev, branch_id: {}}
   })
  }

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      dispatch(getBranches(actionCtx?.activeOrg?.id));
      dispatch(getUsers(actionCtx?.activeOrg?.id));
      setDetails((prev) => {
        return { ...prev, subsidiary_id: "", branch_id: "" };
      });
    }
      // eslint-disable-next-line
  }, [actionCtx.setActiveOrgManual, refreshTable]);

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 4 of 6`}
        pageTitle={`Add Department`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-department"
              )
            }
            className="text"
          >
            Departments
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Add Departments</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Add Departments</p>
            <p className="text">
              Add department information and control the organisation structure
              of your account management
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          {/* <div className="btn-download-box">
            <button
              className={`btn-bulk ${
                details.Address ||
                details.Branch_Code ||
                details.Name ||
                details.city ||
                details.Branch ||
                details.subsidiary
                  ? "btn-bulk-inactive"
                  : ""
              }`}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div> */}
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* wrap start */}
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Department Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Name" className="label">
                    Department Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Department Name"
                    className={`input ${details.name && "input-active"}`}
                    id="Name"
                    name="name"
                    onChange={handleChange}
                    value={details.name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${actionCtx?.activeOrg?.type !== "group" &&  "disable-select"} ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    placeholder="Choose a subsidiary"
                    value={actionCtx?.activeOrg?.type !== "group"
                    ? subsidiariesDrop || details?.subsidiary_id
                    : details.subsidiary_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                      handleGetBranchBySub(e)
                    }}
                    isDisabled={actionCtx?.activeOrg?.type !== "group"}
                    
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    // isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Branch" className="label">
                    Branch <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.branch_id && "select-wrap-active"
                    }`}
                    placeholder="Choose a branch"
                    value={details.branch_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, branch_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={branchSubDrop}
                    isLoading={loadingPage}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                {/* <div className="form-group">
                  <label htmlFor="Department Code" className="label">
                    Department Code <span>*</span>
                  </label>
                  <input
                    name="department_code"
                    onChange={handleChange}
                    value={details.department_code}
                    type="text"
                    placeholder="Department code"
                    className={`input ${
                      details.department_code && "input-active"
                    }`}
                    id="Department Code"
                  />
                </div> */}
                {/* group box end */}
              </div>

              <div
                style={{
                  gridTemplateColumns: "1fr",
                  margin: "3rem 0rem 0rem 0rem",
                  gridTemplateRows: "1fr",
                }}
                className="form-wrap"
              >
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Department Code" className="label">
                    Description <span>*</span>
                  </label>
                  <textarea
                    name="description"
                    value={details?.description}
                    onChange={handleChange}
                    //   name=""
                    style={{ width: "100%", resize: "none" }}
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Description"
                    className={`input ${details.description && "input-active"}`}
                  ></textarea>
                </div>
                {/* group box end */}
              </div>
              {/* another warpa start */}

              <div className="wrapper">
                <p className="box-title">2. Department Personnel</p>
                <div className="form-wrap" style={{ gridTemplateRows: "1fr" }}>
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="head of dept" className="label">
                      Set head of department 
                    </label>
                    <Select
                      className={`select-wrap ${
                        details.head_of_department_id && "select-wrap-active"
                      }`}
                      placeholder="Set head of department"
                      value={details.head_of_department_id}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, head_of_department_id: e };
                        });
                      }}
                      styles={reactSelectStyle}
                      options={usersDrop}
                      // isLoading={loading}
                    />
                  </div>
                  {/* group box end */}
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="submit to" className="label">
                      Submit to
                      <figure
                        className="img-box"
                        style={{
                          filter: "saturate(0%)",
                          transform: "translateX(.5rem) translateY(.3rem)",
                        }}
                      >
                        <img src={noteIcon} alt="" className="img" />
                      </figure>
                    </label>
                    <Select
                      className={`select-wrap ${
                        details.head_of_department_id && "select-wrap-active"
                      }`}
                      placeholder="Submit to"
                      value={details.submit_to_id}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, submit_to_id: e };
                        });
                      }}
                      styles={reactSelectStyle}
                      options={usersDrop}
                      // isLoading={loading}
                    />
                  </div>
                  {/* group box end */}
                </div>
              </div>
              {/* another wrap end */}
            </div>
            {/* wrap end */}
            {/* <div className="note-img-wrap">
                Important Notice:
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
              </div> */}
            {/* note box start */}
            {/* <div className="note-box">
                <p>
                  1. For organizations with shared budgets, you would need to
                  have created at least two subsidiaries to set up a shared
                  services
                </p>
              </div> */}
            {/* note box end */}

            {/* btn box start */}
            {!loading ? (
              <input
                disabled={
                  !details?.name ||
                  !details?.branch_id ||
                  !details?.subsidiary_id ||
                  // !details?.organization_id ||
                  // !details?.department_code ||
                  !details?.description 
                  // !details?.head_of_department_id ||
                  // !details?.submit_to_id
                }
                type="submit"
                value="Save & Continue"
                className={`form-group form-submit ${
                  details.name &&
                  details.branch_id &&
                  details.subsidiary_id &&
                  // details.organization_id &&
                  // details.department_code && 
                  details.description &&
                  // details.head_of_department_id &&
                  // details.submit_to_id &&
                  "form-submit-active"
                }`}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* btn box end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default AddDepartment;
