import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
// import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
// import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
// import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
// import EmployeeCommonRevenueLayout from "./layout/CommonLayout";
import Modal from "react-awesome-modal";
// import EmployeeRevenueComentModal from "../modals/comment";
// import EmployeeApprovedOverviewModal from "../modals/submitted revenue/overview";
import EmployeeCommonExpenseLayout from "../CommonLayout";
import EmployeeReusableTable from "../../../../../table/EmployeeReusableTable";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../../layout/dashboard/EmpTableBodyRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getActualExpenseSubmittedBySearch,
  getAllActualExpenseComment,
  getApprovedActualExpenseById,
  getEmployeeActualExpensesPendingPagination,
} from "../../../../../../../redux/employee/actual-expense";
import { useEffect } from "react";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../../helper/Helpers";
import EmployeeActualDraftExpense from "../../actualDraftExpense";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
import EmpRejectActualExpOverviewModal from "../../modals/submitted revenue/reject-overview";
import EmpActualExpenseApproveOverview from "../../modals/actual-expense/approveModal";
import EmpActualExpenseComentModal from "../../modals/actual-expense/commentsModal";
import useDebounce from "../../../../../../../helper/UseDebounce";
import ActionContext from "../../../../../../../context/ActionContext";
import { getUserDepartment } from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import moment from "moment";

const EmployeeExpenseSubmitRejectTable = () => {
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    approve: false,
    comment: false,
    rejectOverview: false,
  });
  const [dept, setDept] = useState({ label: "Select Dept..." });
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const cardHeader = ["Total Expenses", "Approved", "Pending", "Rejected"];
  // const cardValue = ["20,000.00", "0", "0", "0"];

  const history = useHistory();

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Units",
    "Unit Price",
    "Date",
    "Status",
  ];

  const link = [
    "/employee-dashboard-actual-expense-new",
    "/employee-dashboard-actual-expense-submitted",
    "/employee-dashboard-actual-expense-submitted-approved",
    "/employee-dashboard-actual-expense-submitted-pending",
    "/employee-dashboard-actual-expense-submitted-rejected",
  ];

  const { actualExpensePending, meta, links, loadingPage } = useSelector(
    (state) => state.actual_expense_employee
  );
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [singleItem, setSingleItem] = useState({});
  const [singleChild, setSingleChild] = useState({});
  const actionCtx = useContext(ActionContext);

  const handleGetActualExpenseRejectedTable = async () => {
    const obj = {
      id: user?.organizations[0]?.id,
      filter_submitted: true,
      status: "rejected",
      dept_id: dept?.id,
    };
    await dispatch(getApprovedActualExpenseById(obj));
  };

  const handleGetActualExpenseData = async () => {
    const obj = {
      id: user?.organizations[0]?.id,
      filter_submitted: true,
      status: "rejected",
      dept_id: dept?.id,
    };
    const data = await dispatch(getApprovedActualExpenseById(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };

  useEffect(() => {
    // handleGetActualExpenseRejectedTable();
    getUserDepartment(user?.organizations[0]?.id);
    handleGetActualExpenseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id, dept]);

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      status: "rejected",
      dept_id: dept?.id,
    };
    dispatch(getEmployeeActualExpensesPendingPagination(obj));
  };

  const handleGetAllComment = (param) => {
    dispatch(getAllActualExpenseComment(param));
  };

  // search sort and filter
  const SortByOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
  ];
  const [sortByVal, setSortByVal] = useState({ label: "Sort by" });

  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart Of Account", value: "chart_of_account_id" },
    { label: "class", value: "class" },
    { label: "description", value: "description" },
    { label: "units", value: "units" },
    { label: "unit_price", value: "unit_price" },
  ];

  const [search, setSearch] = useState("");
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });

  const handleSortChange = (param) => {
    const obj = {
      // q: search,
      sort: param?.value,
      is_submitted: 1,
      status: "rejected",
      dept_id: dept?.id,
      id: user?.organizations[0]?.id,
    };
    // console.log("obj", obj)
    dispatch(getActualExpenseSubmittedBySearch(obj));
  };

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        id: user?.organizations[0]?.id,
        search: search,
        is_submitted: 1,
        status: "rejected",
        dept_id: dept?.id,
        filter_by: filterByVal?.value,
      };
      dispatch(getActualExpenseSubmittedBySearch(obj));
    } else {
      handleGetActualExpenseRejectedTable();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const cardValue = [
    singleItem?.total_actual_expense || 0,
    singleItem?.Approved || 0,
    singleItem?.awaiting_approval || 0,
    singleItem?.rejected || 0,
  ];

  return (
    <>
      <div>
        <EmployeeCommonExpenseLayout rejectLink={true}>
          <EmployeeReusableTable
            dept
            departmentDrop={userDepartmentDrop}
            deptVal={dept}
            setDepartmentVal={(e) => {
              setDept(e);
            }}
            sort={true}
            filter={true}
            optionSort={SortByOptions}
            optionSortVal={sortByVal}
            setOptionSortVal={(e) => {
              setSortByVal(e);
              handleSortChange(e);
            }}
            optionFilter={filterByOptions}
            optionFilterVal={filterByVal}
            setOptionFilterVal={(e) => setFilterByVal(e)}
            onSearchTable={(e) => {
              setSearch(e);
            }}
            addButton={actualExpensePending.length > 0}
            BtnTitle={`New Expense`}
            onPage={() => history.push(link[0])}
            firstCard
            secondCard
            thirdCard
            loading={loadingPage}
            fourthCard
            showSearch
            cardTitleOne={cardHeader[0]}
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            cardBodyOne={formatNumber(
              String(
                FormatNumWithCurrency(
                  cardValue[0],
                  actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
                  actionCtx?.currencyValue
                ) || 0
              )
            )}
            cardBodyTwo={cardValue[1]}
            cardBodyThree={cardValue[2]}
            cardBodyFour={cardValue[3]}
            linkCardFour={link[4]}
            // onPage={() => history.push(link[0])}
            onFirstCard={() => history.push(link[1])}
            onSecondCard={() => history.push(link[2])}
            onThirdCard={() => history.push(link[3])}
            onFourthCard={() => history.push(link[4])}

            // onCard={onApprove}
          />
          <ActualReuseableTable
            dontShowBnSearch
            dontShowPagination={actualExpensePending?.length < 1}
            // addBtntext={`Create Expense`}
            // onCreateNew={() => {
            //   history.push("/employee-dashboard-actual-expense-new");
            // }}
            // optionFilterVal={filterByVal}
            // setOptionFilterVal={(e) => setFilterByVal(e)}
            // optionFilter={tableSelectOption}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            action
            fromPage={meta?.from || 1}
            toPage={meta?.to || actualExpensePending?.length}
            totalPage={meta?.total || actualExpensePending?.length}
            nextPage={links?.next}
            prevPage={links?.prev}
            onNextPage={() => handlePagination(links?.next)}
            onPrevPage={() => handlePagination(links?.prev)}
            // onSearchTable={(e) => setSearch(e)}
          >
            {actualExpensePending?.length > 0 ? (
              <>
                {actualExpensePending?.map((chi, idx) => {
                  const {
                    chart_of_account,
                    description,
                    units,
                    unit_price,
                    date,
                    status,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      rowNo={idx}
                      indexNo={indexNo}
                      setIndexNo={() => setIndexNo(idx)}
                      action
                      loading={loadingPage}
                      key={idx}
                      one={chart_of_account?.name}
                      two={chart_of_account?.class}
                      three={description}
                      // loading={loadingPage}
                      four={formatNumber(String(units))}
                      five={formatNumber(
                        String(
                          FormatNumWithCurrency(
                            unit_price,
                            actionCtx?.activeOrg?.active_fiscal_year
                              ?.base_currency?.name,
                            actionCtx?.currencyValue
                          ) || 0
                        )
                      )}
                      six={moment(date).format("YYYY-MM-DD")}
                      progressType={
                        status === "approved"
                          ? "accept"
                          : status === "rejected"
                          ? "reject"
                          : "pending"
                      }
                      // updateText={`modify`}
                      // approveText={"Approve"}
                      viewText={"View Comments"}
                      // updateTextThree={`Modify`}
                      // onUpdateThree={() => history.push(links[0])}
                      approveText={
                        user?.permissions?.includes(
                          "approve/reject actual expense"
                        )
                          ? "Approve"
                          : null
                      }
                      onApprove={() => {
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approve: true };
                        });
                      }}
                      onView={() => {
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, comment: true };
                        });
                      }}
                      onRejectStatus={() => {
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, rejectOverview: true };
                        });
                      }}
                      exportText={`Export as PDF`}
                    />
                  );
                })}
              </>
            ) : (
              <EmployeeActualDraftExpense>
                <ButtonBlue
                  onClick={() =>
                    history.push("/employee-dashboard-actual-expense-new")
                  }
                  text={`Create Expense`}
                >
                  <FaPlus style={{ marginRight: "15px" }} />
                </ButtonBlue>
              </EmployeeActualDraftExpense>
            )}
          </ActualReuseableTable>
        </EmployeeCommonExpenseLayout>
      </div>
      {/* modal end */}
      <Modal visible={showModal.approve}>
        <EmpActualExpenseApproveOverview
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
          }}
          onRefreshCard={handleGetActualExpenseData}
          // onDispatch={() =>
          //   dispatch(getExpenditureProjectionById(user?.organizations[0]?.id))
          // }
          onDispatch={handleGetActualExpenseRejectedTable}
        />
      </Modal>
      {/* modal end */}
      {/* modal end */}
      <Modal visible={showModal.comment}>
        <EmpActualExpenseComentModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, comment: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.rejectOverview}>
        <EmpRejectActualExpOverviewModal
          // update
          details={singleChild}
          data={singleChild}
          approveBtn={
            user?.permissions?.includes("approve/reject actual expense")
              ? true
              : false
          }
          // onUpdate={() => {
          //   localStorage.setItem(
          //     "single-expenditure-projection-id",
          //     singleChild?.id
          //   );
          //   history.push("/employee-dashboard-projection-expenditure-update");
          // }}
          // onReimburse={() =>
          //   setShowModal((prev) => {
          //     return { ...prev, reimburse: true, rejectOverview: false };
          //   })
          // }
          // rejectText={
          //   user?.permissions?.includes("approve/reject actual expense")
          //     ? true
          //     : false
          // }
          // reimburseText
          // onReject={() =>
          //   setShowModal((prev) => {
          //     return { ...prev, rejectAll: true, rejectOverview: false };
          //   })
          // }
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            });
            handleGetAllComment(singleChild?.id);
          }}
          onApprove={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, approve: true, rejectOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeExpenseSubmitRejectTable;
