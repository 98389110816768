import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "../../../utils/axios";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../../helper/Helpers";

export const getSubscriptions = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get("/subscription-plan", formData);
      // console.log(data);
      if (!data?.data?.success) {
        toast.error(data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.data?.success) {
        toast.success(data?.message, {
          theme: "colored",
        });
        const subInfo = data?.data?.data;
        // console.log(subInfo);
        await thunkAPI.dispatch(setSubscriptions(subInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubscriptionCallback = createAsyncThunk(
  "subscription-callback",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(
        `/subscription/callback?reference=${formData?.reference}`
      );

      if (!data?.data?.success) {
        toast.error(data?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.success) {
        toast.success(data?.data?.message, {
          theme: "colored",
        });
        const subInfo = data?.data?.data;
        // console.log(subInfo);
        await thunkAPI.dispatch(setSubscriptions(subInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCurrenciesTwo = createAsyncThunk(
  "admin/get-currencies",
  async (formData, thunkAPI) => {
    try {
      // const token = localStorage.getItem("token");
      // await setAuthToken(token);
      const { data } = await axios.get("/convertCurrency");
      // console.log("-------country------", data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data.success) {
        // // console.log(data);
        await thunkAPI.dispatch(setCurrencies(data.data));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubscription = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(`/subscription-plan/${formData}`);
      if (!data?.success) {
        toast.error(data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubscriptionFeatures = createAsyncThunk(
  "subscription-features",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(`/subscription-feature/`, formData);
      // console.log(data, "data-feat");
      if (!data?.data?.success) {
        toast.error(data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.data?.success) {
        // console.log(data?.data?.data, "data-feat");
        toast.success(data?.message, {
          theme: "colored",
        });
        const subInfo = data?.data?.data;
        // console.log(subInfo);
        await thunkAPI.dispatch(setSubFeatures(subInfo));

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

// export const getCurrentPlan = createAsyncThunk(
//   "current-plan",
//   async (formData, thunkAPI) => {
//     try {
//       const data = await axios.get(
//         `/get-current-plan-by-user?organization_id=${formData}`
//       );
//       // console.log(data, "data-current");

//       if (!data?.status === 200) {
//         toast.error(data?.message, {
//           theme: "colored",
// toastId: customErrorId,
//         });
//         return thunkAPI.rejectWithValue(data);
//       }
//       if (data?.status === 200) {
//         await thunkAPI.dispatch(
//           setCurrentPlan(data?.data?.data?.active_plan_details)
//         );
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (err) {
//       if (err.message === "Network Error") {
//         toast.error(err.message, {
//           theme: "colored",
// toastId: customErrorId,
//         });
//       }
//       // // console.log(err);
//       if (err) {
//         return thunkAPI.rejectWithValue(err);
//       }
//     }
//   }
// );

export const downloadInvoiceSubscription = createAsyncThunk(
  "download-invoice-subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(
        `admin/download-subscribed-plan/${formData?.id}`
      );
      // console.log(data);
      if (!data?.success) {
        toast.error(data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.success === false) {
        toast.error(err.response.data.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const upgradeSubscription = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(`/subscription/payment`, formData);
      if (!data?.status) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const autoRenewSubscriptionSubscription = createAsyncThunk(
  "auto-subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.patch(
        `/admin/auto-renewal/${formData?.id}`,
        formData
      );
      if (data?.data?.success === false) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data, "data-upgrade");
      if (data?.data?.success === true) {
        toast.success(data.data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCountries = createAsyncThunk(
  "admin/get-countries",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(`subscription-feature`, formData);
      console.log(data, "data-upgrade");
      if (!data?.status) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
          toastId: customErrorId,
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

// export const getCountries = createAsyncThunk(
//   "admin/get-countries",
//   async (formData, thunkAPI) => {
//     try {
//       // const token = localStorage.getItem("token");
//       // await setAuthToken(token);
//       const { data } = await axios.get("/dropdowns/countries");
//       // console.log("-------country------", data);
//       if (!data.success) {
//         toast.error(data.message, {
//           theme: "colored",
// toastId: customErrorId,
//         });
//         // console.log(data);
//         return thunkAPI.rejectWithValue(data);
//       }
//       if (data.success) {
//         // // console.log(data);
//         await thunkAPI.dispatch(setCountries(data.data));
//       }
//     } catch (err) {
//       if (err.message === "Network Error") {
//         toast.error(err.message, {
//           theme: "colored",
// toastId: customErrorId,
//         });
//       }
//       if (err) {
//         return thunkAPI.rejectWithValue(err);
//       }
//     }
//   }
// );

// export const bookADemoWithUs = createAsyncThunk(
//   "subscription-plan",
//   async (formData, thunkAPI) => {
//     try {
//       const data = await axios.post(`/booking`, formData);
//       if (!data?.status) {
//         toast.error(data?.payload?.response?.data?.message, {
//           theme: "colored",
// toastId: customErrorId,
//         });
//         // console.log(data?.payload?.response?.data?.message, "message");
//         return thunkAPI.rejectWithValue(data);
//       }
//       // console.log(data, "data-upgrade");
//       if (data?.status === 200) {
//         toast.success(data?.message, {
//           theme: "colored",
//         });
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (err) {
//       if (err.message === "Network Error") {
//         toast.error(err.message, {
//           theme: "colored",
// toastId: customErrorId,
//         });
//       }
//       // // console.log(err);
//       if (err) {
//         return thunkAPI.rejectWithValue(err);
//       }
//     }
//   }
// );

const addLabelValueFunc = (list) => {
  // // console.log(list);
  const newList = list.map((chi) => {
    return {
      ...chi,
      label:
        chi?.name === "NGN"
          ? "Naira"
          : chi?.name === "EUR"
          ? "Euro"
          : chi?.name === "USD"
          ? "Dollar"
          : chi?.name,
      value: chi?.id || chi?.name,
    };
  });
  // // console.log(newList);
  return newList;
};

export const subscription = createSlice({
  name: "subscription",
  initialState: {
    subscriptions: [],
    isAuth: false,
    loading: false,
    currencies: [],
    loadingDownload: false,
    loadingAuto: false,
    features: [],
  },
  reducers: {
    setSubscriptions: (state, action) => {
      //   state.isAuth = true;
      state.subscriptions = action.payload;
    },

    setCurrencies: (state, action) => {
      state.currencies = addLabelValueFunc(action.payload);
    },
    setCurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    setSubFeatures: (state, action) => {
      state.features = action.payload;
    },
  },
  extraReducers: {
    [getSubscriptions.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscriptions.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [autoRenewSubscriptionSubscription.pending]: (state) => {
      state.loadingAuto = true;
    },
    [autoRenewSubscriptionSubscription.fulfilled]: (state) => {
      state.loadingAuto = false;
    },
    [autoRenewSubscriptionSubscription.rejected]: (state) => {
      state.loadingAuto = false;
      state = null;
    },
    [downloadInvoiceSubscription.pending]: (state) => {
      state.loadingDownload = true;
    },
    [downloadInvoiceSubscription.fulfilled]: (state) => {
      state.loadingDownload = false;
    },
    [downloadInvoiceSubscription.rejected]: (state) => {
      state.loadingDownload = false;
      state = null;
    },
    [getSubscription.pending]: (state) => {
      state.loading = true;
    },
    setCurrencies: (state, action) => {
      state.currencies = addLabelValueFunc(action.payload);
    },
    [getSubscription.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscription.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [getSubscriptionFeatures.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptionFeatures.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscriptionFeatures.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [getSubscriptionCallback.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptionCallback.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscriptionCallback.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [upgradeSubscription.pending]: (state) => {
      state.loading = true;
    },
    [upgradeSubscription.fulfilled]: (state) => {
      state.loading = false;
    },
    [upgradeSubscription.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    // [getCurrentPlan.pending]: (state) => {
    //   state.loading = true;
    // },
    // [getCurrentPlan.fulfilled]: (state) => {
    //   state.loading = false;
    // },
    // [getCurrentPlan.rejected]: (state) => {
    //   state.loading = false;
    //   state = null;
    // },
    // [bookADemoWithUs.pending]: (state) => {
    //   state.loading = true;
    // },
    // [bookADemoWithUs.fulfilled]: (state) => {
    //   state.loading = false;
    // },
    // [bookADemoWithUs.rejected]: (state) => {
    //   state.loading = false;
    //   state = null;
    // },
  },
});

export const {
  setSubscriptions,
  setCountries,
  // setCurrentPlan,
  setCurrencies,
  setSubFeatures,
} = subscription.actions;

export default subscription.reducer;
