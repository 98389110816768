import { Dna } from "react-loader-spinner";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";

const SharedBudgetModalMessage = ({ onCancel, onDiscard, discardLoader }) => {
  return (
    <div className="employee-shared-budget-message-wrap">
      <p>Are you sure you want to discard sharing this budget percentage ?</p>
      <div className="emp-shared-budget-msg-btn-wrap">
        <ButtonBlue text={"Cancel"} onClick={onCancel} />
        {discardLoader ? (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="40"
              width="40"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue
            text={"Yes, Discard"}
            btnClassName="emp-shared-discard-btn"
            onClick={onDiscard}
          />
        )}
      </div>
    </div>
  );
};
export default SharedBudgetModalMessage;
