import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BudgetMain from "..";
import NoTableContent from "../../../../../fragments/NoTableContent";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import CommonExpenditureLink from "../expenditure-rev/commonLink";
import Modal from "react-awesome-modal"
import DeleteBulkUploadTemplateModal from "../modals/DeleteBulkUploadTemplate";

const tableHeadList = ["Name"];
const tableBodyList = [
  {
    name: "Expenditure",
  },
];

const BulkUploadTemplate = () => {
  const [indexNo, setIndexNo] = useState("");
  const history = useHistory();
  const [showModal, setShowModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink title={`Template For Budgets`} />
        <div className="table-box-budget">
          {/* table box start */}
          <ActualReuseableTable
            // showBulkUpload
            dontShowSelect
            dontShowSearch
            dontShowPagination={tableBodyList.length < 1}
            addBtntext={`New template`}
            one={tableHeadList[0]}
            action
            onCreateNew={() =>
              history.push(
                "/dashboard-administration-admin-settings-budget-bulk-upload-template-add"
              )
            }
          >
            {tableBodyList.map((chi, idx) => {
              const { name } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={name}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  deleteText={`delete`}
                  updateText={`replace`}
                  onUpdate={() =>
                    history.push(
                      "/dashboard-administration-admin-settings-budget-bulk-upload-template-update"
                    )
                  }
                  onDelete={() =>
                    setShowModal((prev) => {
                      return { ...prev, delete: true };
                    })
                  }
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
          {/* create new box for no content start */}
          {tableBodyList.length < 1 && (
            <NoTableContent
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-budget-bulk-upload-template-add"
                )
              }
            />
          )}
          {/* create new box for no content end */}
        </div>
      </BudgetMain>
      <Modal effect="fadeInUp" visible={showModal.delete}>
        <DeleteBulkUploadTemplateModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, delete: false };
            })
          }
        />
      </Modal>
    </>
  );
};

export default BulkUploadTemplate;
