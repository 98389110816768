import React, { useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
// import CommonExpenseLayout from "./CommonLayout";
import EmployeeCommonExpenseLayout from "../expense/CommonLayout";

import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import Modal from "react-awesome-modal";
// import illustrationIcon from "../../../../assets/Illustration-icon.svg";
// import illustrationIcon from "../../../../../../assets/Illustration-icon.svg";
// import EmployeeSubmitProjectionLayoutIndex from "./layout";
// import DeleteSharedModal from "../../../../../../component/pages/dashboard/administration/subsidiary/DeleteSharedModal";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeProjectionApprovalModal from "../revenue/modals/approve";
import EmployeeProjectionComentModal from "../revenue/modals/comment";
// import EmployeeDeleteProjectionModal from "../revenue/modals/reject";
// import EmployeeOverviewPendingReinbursementModal from "../../home/modals/Reimbursement/pendingModal";
import EmployeeApprovedOverviewModal from "../../actual/modals/submitted revenue/overview";
import EmployeeOverviewActualReinbursementModal from "../../home/modals/Reimbursement/EmployeeOverviewModal";
import EmployeeRejectCommentModal from "../../actual/modals/rejectComment";
import EmployeeOverviewRejectModal from "../modals/rejectModal";

const EmployeeExpenseSuccessTable = ({ children }) => {
  const [showModal, setShowModal] = useState({
    overview: false,
    rejectOverview: false,
    approveOverview: false,
    comment: false,
    reject: false,
    approve: false,
  });
  const [indexNo, setIndexNo] = useState("");
  const history = useHistory();
  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Units",
    "Unit Price",
    "Status",
    "Action",
  ];

  const employeeTableBodyList = [
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "pending",
      action: "",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "accept",
      action: "",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "reject",
      action: "",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "accept",
      action: "",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "pending",
      action: "",
    },
    {
      chart_of_account: "Office Equipment",
      classes: "Asset",
      description: "Safekeep documents...",
      units: "1.00",
      unit_price: "20,000",
      status: "pending",
      action: "",
    },
  ];

  const cardHeader = [
    "Expenditure Projected",
    "Approved",
    "Pending",
    "Rejected",
  ];
  const cardValue = ["220,000.00", "20", "10", "5"];

  const links = [
    // "/employee-dashboard-projection-expenditure-new",
    // "/employee-dashboard-projection-expenditure-submitted",
    // "/employee-dashboard-projection-expenditure-submitted-approved",
    // "/employee-dashboard-projection-expenditure-submitted-pending",
    // "employee-dashboard-projection-expenditure-submitted-rejected",
  ];

  return (
    <>
      <EmployeeCommonExpenseLayout allprojection={true}>
        {/* graph wrap end */}
        <EmployeeReusableTable
          sort
          filter
          showSearch={true}
          firstCard={true}
          secondCard
          thirdCard={true}
          fourthCard
          addButton={true}
          BtnTitle={`New Projection`}
          cardTitleOne={cardHeader[0]}
          cardTitleTwo={cardHeader[1]}
          cardTitleThree={cardHeader[2]}
          cardTitleFour={cardHeader[3]}
          cardBodyOne={cardValue[0]}
          cardBodyTwo={cardValue[1]}
          cardBodyThree={cardValue[2]}
          cardBodyFour={cardValue[3]}
          linkCardOne={links[1]}
          onFirstCard={() => history.push(links[1])}
          onSecondCard={() => history.push(links[2])}
          onThirdCard={() => history.push(links[3])}
          onFourthCard={() => history.push(links[4])}
          onPage={() => history.push(links[0])}
        />
        {/* {children} */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowPagination={false}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            // action
            onNextPage
          >
            {employeeTableBodyList.map((chi, idx) => {
              const {
                chart_of_account,
                classes,
                description,
                units,
                unit_price,
                status,
              } = chi;
              return (
                <EmpTableBodyRow
                  key={idx}
                  one={chart_of_account}
                  two={classes}
                  three={description}
                  four={units}
                  five={unit_price}
                  progressType={status}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  updateText={`modify`}
                  approveText={"Approve"}
                  viewText={"View Comments"}
                  deleteText={`Reject`}
                  exportText={`Export as PDF`}
                  onUpdate={() => history.push(links[0])}
                  onApprove={() =>
                    setShowModal((prev) => {
                      return { ...prev, approve: true };
                    })
                  }
                  onView={() =>
                    setShowModal((prev) => {
                      return { ...prev, comment: true };
                    })
                  }
                  onDelete={() =>
                    setShowModal((prev) => {
                      return { ...prev, reject: true };
                    })
                  }
                  onOverview={() =>
                    setShowModal((prev) => {
                      return { ...prev, overview: true };
                    })
                  }
                  onRejectStatus={() =>
                    setShowModal((prev) => {
                      return {
                        ...prev,
                        rejectOverview: true,
                      };
                    })
                  }
                  onAcceptStatus={() =>
                    setShowModal((prev) => {
                      return { ...prev, approveOverview: true };
                    })
                  }
                />
              );
            })}
          </ActualReuseableTable>
        </div>
      </EmployeeCommonExpenseLayout>
      {/* modal start */}
      <Modal Effect={"fadeInOut"} visible={showModal.rejectOverview}>
        <EmployeeOverviewRejectModal
          chart_of_account={`Office Equipment`}
          classes={`Assets`}
          units
          unit_price
          total_value
          date_text={`Date Created`}
          last_updated
          approveBtn
          nextPage
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            })
          }
          onNextPage={() =>
            history.push("/employee-dashboard-projection-expenditure")
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeOverviewActualReinbursementModal
          chart_of_account={`Office Equipment`}
          classes={`Assets`}
          units
          unit_price
          total_value
          date_text={`Date Created`}
          last_updated
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            })
          }
          viewComments={`View Comments`}
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            })
          }
          // deleteText
          exportPdf
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={showModal.approve}>
        <EmployeeProjectionApprovalModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approve: false };
            })
          }
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={showModal.comment}>
        <EmployeeProjectionComentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, comment: false };
            })
          }
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmployeeRejectCommentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect={"fadeInDown"} visible={showModal.overview}>
        <EmployeeApprovedOverviewModal
          nextPage
          rejectText
          onNextPage={() =>
            history.push("/employee-dashboard-projection-expenditure-new")
          }
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, overview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            })
          }
          onReject={() =>
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeExpenseSuccessTable;
