import React, { useRef, useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH, FaTimes } from "react-icons/fa";
import "../../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import cancel_btn from "../../../../../../../assets/CancelBtn.svg";
import ApprovalContent from "../contentHead";
// import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
// import ContentBody from "../contentBody";
import {
  formatNumber,
  formatToWholeNum,
} from "../../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import EmployeeOverviewTableBodyRow from "../../../home/modals/Reimbursement/overviewTableRow";
import Modal from "react-awesome-modal";
import moment from "moment";
import userImg from "../../../../../../../assets/Unknown_person.jpeg";
import { useContext } from "react";
import ActionContext from "../../../../../../../context/ActionContext";
import { Dna } from "react-loader-spinner";
import { getExportToPdf } from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import Skeleton from "react-loading-skeleton";
import { getActualExpenseActivityLog } from "../../../../../../../redux/actuals/expense";
import { useEffect } from "react";
import { getAllActualExpenseComment } from "../../../../../../../redux/employee/actual-expense";

const EmpRejectActualExpOverviewModal = ({
  onCancel,
  onReject,
  onComment,
  details,
  rejectText,
  handleShowModal,
  onShowImg,
  setShowLink,
}) => {
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });
  const [showActions, setShowActions] = useState(false);
  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const [exportLoader, setExportLoader] = useState(false);
  const handleExport = async () => {
    const obj = {
      id: details?.id,
      type: "actual_expense",
      currency_id: actionCtx?.currencyValue?.id,
    };
    // console.log(obj?.id, "id");
    setExportLoader(true);
    const data = await dispatch(getExportToPdf(obj));
    if (data?.payload?.url) {
      setExportLoader(false);
      // actionCtx?.setIsModalOut(false);
      window.open(data?.payload?.url, "_blank").focus();
      // setShowActions(false);
      // handleShowModal((prev) => {
      //   return {
      //     ...prev,
      //     rejectOverview: false,
      //   };
      // });
    } else {
      setExportLoader(false);
    }
  };

  const [logList, setLogList] = useState([]);
  const [loadingLog, setLoadingLog] = useState(false);
  const [comments, setComments] = useState([]);
  const handleGetAllCommentData = async (id) => {
    const data = await dispatch(getAllActualExpenseComment(id));
    if (data?.payload?.success) {
      const commentVal = data?.payload?.data?.actual_expense_comments?.map(
        (chi) => chi?.activities[0]
      );
      setComments(commentVal);
    }
  };

  useEffect(() => {
    if (details?.id && comments) {
      handleGetActivityLogDetails();
    }
  }, [comments, details?.id]);

  const [activityLogData, setActivityLogData] = useState([]);
  const handleGetActivityLogDetails = () => {
    const data = [...comments, ...details?.activities];
    setActivityLogData(data);
  };
  const handleGetActivityLog = async (id) => {
    setLoadingLog(true);
    const obj = {
      id: id,
    };
    const data = await dispatch(getActualExpenseActivityLog(obj));
    if (data?.payload?.success) {
      setLoadingLog(false);
      setLogList(data?.payload?.data);
    }
  };

  // const contentHeader = ["Level", "Name", "Status"];

  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  // const contentHeader = ["Months", "Units", "Total Value"];
  const contentHeaderTwo = ["Level", "Name", "Status"];

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const screenShotRef = useRef();
  const [detailToShow, setDetailToShow] = useState("overview");
  // console.log("details", details);

  return (
    <div className="overview-card-modal-wrap">
      <div className="top-box">
        <div className="title-box">
          <p
            onClick={() => {
              setDetailToShow("overview");
            }}
            className={`title ${detailToShow === "overview" && "title-active"}`}
          >
            Overview
          </p>
          <p
            onClick={() => {
              setDetailToShow("activity");
              handleGetAllCommentData(details?.id);
              handleGetActivityLog(details?.id);
            }}
            className={`title ${detailToShow === "activity" && "title-active"}`}
          >
            Activity Log
          </p>
        </div>
        {/* <p className="title">Overview</p> */}
        <div style={{ marginTop: "5rem" }}></div>
        <div className="ellipse-box">
          <FaEllipsisH
            className="icon"
            onClick={() => setShowActions(!showActions)}
          />
          <div
            className={`action-box ${showActions && "action-box-active"}`}
            style={{ zIndex: "1000" }}
          >
            <p onClick={onReject} style={{ display: "none" }}>
              {rejectText && <span style={{ color: "#CC0905" }}>Reject</span>}
            </p>
            <p onClick={onComment}>
              <span>Comments</span>
            </p>
            {exportLoader ? (
              <div style={{ alignSelf: "center", display: "flex" }}>
                <Dna
                  visible={true}
                  height="25"
                  width="30"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <p style={{ marginTop: "0.5rem" }} onClick={handleExport}>
                <span>Export as PDF</span>
              </p>
            )}
          </div>
          {/* action box end */}
        </div>
        <div
          onClick={() => {
            onCancel(setShowActions(false));
            setDetailToShow("overview");
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      <div ref={screenShotRef} className="content-box">
        {/* overview start --------------- */}
        {detailToShow === "overview" && (
          <>
            <>
              {/* title drop control box start */}
              <div
                onClick={() => handleShowBox("one")}
                className="title-drop-control-box"
              >
                <p className="title">Budget Owner Details</p>
                <div className="icon-box">
                  {!showBox.one && <FaAngleDown className="icon" />}
                  {showBox.one && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.one && (
                <div className="content-wrap">
                  <div className="two-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Chart of Account</p>
                      <p className="value">{`${details?.chart_of_account?.name}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Class</p>
                      <p className="value">{`${details?.chart_of_account?.class}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    {/* <div className="box">
                  <p className="text">Units</p>
                  <p className="value">{`${details?.units}`}</p>
                </div> */}
                    {/* box end */}
                  </div>
                  <div className="three-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Department</p>
                      <p
                        className="value"
                        style={{ width: "80%" }}
                      >{`${details?.department?.name}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Units</p>
                      <p className="value">
                        {`${formatToWholeNum(details?.units)}`}
                      </p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Unit_price</p>
                      <p className="value">
                        {" "}
                        {`${formatNumber(String(details?.unit_price))}`}
                      </p>
                    </div>
                  </div>
                  {/* box end */}
                  {/* <div className="three-box"> */}
                  {/* box start */}
                  {/* <div className="box">
                  <p className="text">Subsidiary</p>
                  <p className="value">Zojatech</p>
                </div> */}
                  {/* box end */}
                  {/* box start */}
                  {/* <div className="box">
                  <p className="text">Company Name</p>
                  <p className="value">Unknown</p>
                </div> */}
                  {/* box end */}
                  {/* box start */}
                  {/* <div className="box">
                  <p className="text">Revenue</p>
                  <p className="value">200,000.00</p>
                </div> */}
                  {/* box end */}
                  {/* </div> */}
                  <div className="one-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Description</p>
                      <p
                        className="value"
                        style={{ width: "80%" }}
                      >{`${details?.description}`}</p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="two-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Expense Date</p>
                      <p className="value">{`${moment(
                        details?.expense_date
                      ).format("YYYY-MM-DD HH:mm a")}`}</p>
                      {/* {moment(date).format("YYYY-MM-DD")} */}
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Last Updated</p>
                      <p className="value">{`${details?.updated_at}`}</p>
                    </div>
                    {/* box end */}
                  </div>
                  {/* created by wrap start */}
                  <div className="one-box">
                    <div className="box">
                      <p className="text">Created by</p>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="img-text-box"
                      >
                        <figure
                          style={{ marginRight: "1rem" }}
                          className="img-box"
                        >
                          <img
                            style={{
                              borderRadius: "20%",
                              width: "4rem",
                              height: "4rem",
                            }}
                            src={user?.profile_picture || userImg}
                            alt=""
                            className="img"
                          />
                        </figure>
                        <div className="text-box">
                          <p className="value">
                            {`${details?.createdBy?.full_name}`}
                          </p>
                          <p style={{ marginBottom: "unset" }} className="text">
                            {`${details?.createdBy?.email}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="three-box">
                    {details?.receipts !== "" ? (
                      details?.receipts?.map((chi, idx) => {
                        const { original_url, mime_type, file_name } = chi;
                        // console.log(mime_type, "picture");
                        return (
                          <div>
                            {!mime_type.includes("image/") ? (
                              <div>
                                <p
                                  style={{
                                    width: "10rem",
                                    height: "10rem",
                                    backgroundColor: "#ffff",
                                    padding: "0rem 1rem",
                                    fontWeight: "800",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    !mime_type.includes("image/") &&
                                      downloadCsv(original_url);
                                  }}
                                >
                                  File Name: {file_name}
                                </p>
                              </div>
                            ) : (
                              <figure
                                onClick={() => {
                                  onShowImg();
                                  setShowLink(original_url);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  style={{
                                    width: "10rem",
                                    height: "10rem",
                                  }}
                                  src={original_url}
                                  alt=""
                                />
                              </figure>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          // width: "10rem",
                          // height: "10rem",
                          // backgroundColor: "#ffff",
                          // boxShadow: "0.1rem 0.1rem 8rem rgba(0, 0, 0, .2)",
                          // cursor: "pointer",
                          padding: "0rem 1rem",
                          fontWeight: "800",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p>No receipt found!</p>
                      </div>
                    )}
                  </div>

                  {/* created by wrap end */}
                </div>
              )}
            </>
            {/* title drop control box end */}
            {/* title drop control box start */}
            {/* <>
          <div
            onClick={() => handleShowBox("two")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Monthly Breakdown</p>
            <div className="icon-box">
              {!showBox.two && <FaAngleDown className="icon" />}
              {showBox.two && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.two && <div className="content-wrap"></div>}
        </> */}
            {/* title drop control box end */}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("three")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem" }}
              >
                <p className="title">Approval Flow</p>
                <div className="icon-box">
                  {!showBox.three && <FaAngleDown className="icon" />}
                  {showBox.three && <FaAngleUp className="icon" />}
                </div>
              </div>
              {/* {showBox.three && } */}
              {showBox.three && (
                <div className="content-wra">
                  <ApprovalContent
                    headerOne={contentHeaderTwo[0]}
                    headerTwo={contentHeaderTwo[1]}
                    headerThree={contentHeaderTwo[2]}
                  />
                  {details?.approval_flow?.map((chi, idx) => {
                    const { step, user, status, approval_flow_id } = chi;
                    return (
                      <>
                        {approval_flow_id !== null ? (
                          <EmployeeOverviewTableBodyRow
                            key={idx}
                            one={step?.toString().padStart(2, "0")}
                            userOne
                            userName={user?.first_name + " " + user?.last_name}
                            userEmail={user?.email}
                            userImg={user?.profile_picture || userImg}
                            status={status}
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "3rem",
                              fontWeight: "600",
                              fontSize: "2rem",
                            }}
                          >
                            <p>NO APPROVAL FLOW FOUND</p>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
            </>
            {/* title drop control box end */}
          </>
        )}
        {/* overview end ------------------ */}
        {/* overview end --------------- */}
        {/* activity log start -------- */}
        {detailToShow === "activity" && (
          <>
            <div
              onClick={() => {
                // console.log(logList);
              }}
              className="activity-log-wrap-box"
            >
              {/* box start */}
              {details?.activities?.map((chi, idx) => {
                const { causer, title, type, created_at, icon } = chi;
                // console.log(detail?.activities);
                if (logList?.length < 1) {
                  return (
                    <div key={idx} className="log-box">
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                      <div className="img-wrap">
                        <>
                          <Skeleton width={35} height={35} circle />
                        </>
                      </div>
                      <div className="dash"></div>
                      {/* detail wrap start */}
                      <div className="log-detail-wrap">
                        <>
                          <Skeleton width={100} height={15} />
                        </>
                        <>
                          <Skeleton width={170} height={12} />
                        </>
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      </div>
                      {/* detail wrap end */}
                    </div>
                  );
                }
                return (
                  <div key={idx} className="log-box">
                    {loadingLog ? (
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                    ) : (
                      <div className="num">
                        <span>
                          {details?.activities.length - idx < 10
                            ? `0${details?.activities.length - idx}`
                            : details?.activities.length - idx}
                        </span>
                      </div>
                    )}
                    <div className="img-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={35} height={35} circle />
                        </>
                      ) : (
                        <figure className="img-box">
                          <img src={icon} alt="" className="img" />
                        </figure>
                      )}
                    </div>
                    <div className="dash"></div>
                    {/* detail wrap start */}
                    <div className="log-detail-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={15} />
                        </>
                      ) : (
                        <p className="title">{title || "---"}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={170} height={12} />
                        </>
                      ) : (
                        <p className="initiator">{`${type || ""} by: ${
                          causer?.full_name
                        }`}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="date">
                          {moment(created_at).format("MM-DD-YYYY HH:mm:ss A")}
                        </p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="time">{created_at?.split(" ")[1]}</p>
                      )}
                    </div>
                    {/* detail wrap end */}
                  </div>
                );
              })}
              {/* box end */}
            </div>
          </>
        )}
        {/* activity log end ----------- */}
        {/* modal start */}

        {/* modal end */}
        {/* title drop control box end */}
      </div>
      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default EmpRejectActualExpOverviewModal;
