import EmployeeActualRevenueIndex from "../../../component/pages/dashboard/actual/revenue";
// import EmployeeActualRevenueDraftSubmit from "../../../component/pages/dashboard/actual/revenue/draftSubmit";
import EmployeeActualRevenueForm from "../../../component/pages/dashboard/actual/revenue/revenueForm";
import EmployeeActualRevenueSubmit from "../../../component/pages/dashboard/actual/revenue/submitted";
import EmployeeRevenueApproveTable from "../../../component/pages/dashboard/actual/revenue/approve";
import EmployeeRevenuePendingTable from "../../../component/pages/dashboard/actual/revenue/pending";
import EmployeeRevenueRejectTable from "../../../component/pages/dashboard/actual/revenue/rejected";
import EmployeeRevenueTableIndex from "../../../component/pages/dashboard/actual/revenue/layout/table";
import UpdateEmployeeActualRevenueForm from "../../../component/pages/dashboard/actual/revenue/update";

export const Employee_actual_revenue_route_group = [
  {
    path: "/employee-dashboard-actual-revenue",
    component: EmployeeActualRevenueIndex,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/employee-dashboard-actual-revenue-update",
    component: UpdateEmployeeActualRevenueForm,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/employee-dashboard-actual-revenue-submitted",
    component: EmployeeActualRevenueSubmit,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/employee-dashboard-actual-revenue-form",
    component: EmployeeActualRevenueForm,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  // {
  //   path: "/employee-dashboard-actual-revenue-form-submitted",
  //   component: EmployeeActualRevenueDraftSubmit,
  //   pageTitle: "Actual Revenue",
  //   goBack: true,
  // },
  {
    path: "/employee-dashboard-actual-revenue-total-revenue-submitted",
    component: EmployeeRevenueTableIndex,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/employee-dashboard-actual-revenue-total-revenue-submitted-approved",
    component: EmployeeRevenueApproveTable,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/employee-dashboard-actual-revenue-total-revenue-submitted-pending",
    component: EmployeeRevenuePendingTable,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/employee-dashboard-actual-revenue-total-revenue-submitted-rejected",
    component: EmployeeRevenueRejectTable,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
];
