import React, { useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
// import imgOne from "../../../../../../assets/hod-two-img.svg";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import userImg from "../../../../../../assets/Unknown_person.jpeg";
import { Dna } from "react-loader-spinner";
import { rejectAllActuals } from "../../../../../../redux/employee/actual-expense";

const EmployeeActualExepenseRejectAllModal = ({
  onCancel,
  details,
  onClose,
  onRefreshCard,
  onDispatch,
}) => {
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.actual_expense_employee);

  const [lists, setLists] = useState({ comment: "" });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...lists, [name]: value };
    setLists(data);
  };

  const handleSubmit = async () => {
    const obj = {
      actual_expense_ids: [details?.id],
    };
    const data = await dispatch(rejectAllActuals(obj));
    if (data?.payload?.success) {
      setLists({
        comment: "",
      });
      onDispatch();
      onClose();
      onRefreshCard();
    }
    // console.log(data);
  };

  return (
    <div style={{ width: "55rem" }} className="overview-card-modal-wrap">
      <div className="top-box">
        <p className="title">{`Reject all Approvals (${details?.chart_of_account?.name})`}</p>
      </div>
      <div className="content-box">
        <div style={{ padding: "1rem" }}>
          <p>
            Are you sure you want to reject this expense approvals. Once this
            action is completed and cannot be undone again.
          </p>
        </div>
        <div className="img-input-wrap">
          <figure className="img-box">
            <img
              style={{
                borderRadius: "20%",
                width: "4rem",
                height: "4rem",
              }}
              src={user?.profile_picture || userImg}
              alt=""
              className="img"
            />
          </figure>
          <textarea
            placeholder="type here your comment"
            style={{ resize: "unset" }}
            name="comment"
            value={lists?.comment}
            onChange={handleChange}
            id="comment"
            cols="30"
            rows="5"
            className="input-text"
          ></textarea>
        </div>
      </div>
      {/* <div className="bottom-box">
        <ButtonBlue onClick={onCancel} text={`Cancel`} />
        <ButtonBlue
          onClick={handleSubmit}
          bgColor={`#CC0905`}
          text={`Yes, Reject `}
        />
      </div> */}
      <div className="bottom-box">
        <ButtonBlue
          onClick={() => {
            setLists((prev) => {
              return { ...prev, comment: "" };
            });
            onCancel();
          }}
          text={`Cancel`}
        />
        {loading ? (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue
            onClick={() => {
              // lists?.comment &&
              handleSubmit(onClose, onRefreshCard, onDispatch);
            }}
            bgColor={`#CC0905`}
            text={`Yes, Reject `}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeActualExepenseRejectAllModal;
