import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../../table/EmployeeReusableTable";
import Modal from "react-awesome-modal";
import EmployeeRevenueApprovalModal from "../../modals/approve";
import EmployeeRevenueComentModal from "../../modals/comment";
import EmployeeRejectModal from "../../modals/reject";
import EmployeeCommonRevenueLayout from "./CommonLayout";
import EmployeeActualRevenuePendingOverviewModal from "./modals/pendingOverview";
import EmployeeActualRevenueApproveOverviewModal from "./modals/approveOverviewModal";
import EmployeeActualRevenueRejectOverviewModal from "./modals/rejectOverview";

const EmployeeRevenueTableIndex = ({ children }) => {
  const history = useHistory();

  const [indexNo, setIndexNo] = useState("");

  const [showModal, setShowModal] = useState({
    approve: false,
    comment: false,
    reject: false,
    overview: false,
    approveOverview: false,
    rejectOverview: false,
  });

  const cardHeader = ["Total Revenue", "Approved", "Pending", "Rejected"];
  const cardValue = ["100,000.00", "20", "10", "5"];

  const tableHeadList = [
    "Product",
    "Units",
    "Unit Price",
    "Revenue",
    "GP",
    "GP %",
    "Status",
  ];

  const employeeTableBodyList = [
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "pending",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "accept",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "reject",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "pending",
    },
    {
      product: "DS Pro",
      units: "1.00",
      unit_price: "20,000.00",
      revenue: "200,000.00",
      gross_profit: "20,000.00",
      gross_percentage: "200",
      status: "accept",
    },
  ];

  const links = [
    "/employee-dashboard-actual-revenue-form",
    "/employee-dashboard-actual-revenue-total-revenue-submitted",
    "/employee-dashboard-actual-revenue-total-revenue-submitted-approved",
    "/employee-dashboard-actual-revenue-total-revenue-submitted-pending",
    "/employee-dashboard-actual-revenue-total-revenue-submitted-rejected",
  ];

  // const location = useLocation();

  return (
    <>
      <div>
        <EmployeeCommonRevenueLayout revenueNavLink={true}>
          <EmployeeReusableTable
            dontShowPagination={false}
            cardTitleOne={cardHeader[0]}
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            cardBodyOne={cardValue[0]}
            cardBodyTwo={cardValue[1]}
            cardBodyThree={cardValue[2]}
            cardBodyFour={cardValue[3]}
            showSearch={true}
            sort
            filter
            BtnTitle={`New Revenue`}
            linkCardOne={links[1]}
            addButton={true}
            firstCard={true}
            secondCard={true}
            thirdCard={true}
            fourthCard={true}
            onPage={() => history.push(links[0])}
            onFirstCard={() => history.push(links[1])}
            onSecondCard={() => history.push(links[2])}
            onThirdCard={() => history.push(links[3])}
            onFourthCard={() => history.push(links[4])}
          />

          {children}
          <div className="table-box">
            {/* table box start */}
            <ActualReuseableTable
              dontShowPagination={false}
              dontShowBnSearch
              one={tableHeadList[0]}
              two={tableHeadList[1]}
              three={tableHeadList[2]}
              four={tableHeadList[3]}
              five={tableHeadList[4]}
              six={tableHeadList[5]}
              seven={tableHeadList[6]}
              action
              onNextPage
            >
              {employeeTableBodyList.map((chi, idx) => {
                const {
                  product,
                  units,
                  unit_price,
                  revenue,
                  gross_profit,
                  gross_percentage,
                  status,
                } = chi;
                return (
                  <EmpTableBodyRow
                    key={idx}
                    one={product}
                    two={units}
                    three={unit_price}
                    four={revenue}
                    five={gross_profit}
                    six={gross_percentage}
                    progressType={status}
                    onProgress={() => history.push("/")}
                    action
                    rowNo={idx}
                    indexNo={indexNo}
                    setIndexNo={() => setIndexNo(idx)}
                    updateText={`modify`}
                    approveText={"Approve"}
                    viewText={"View Comments"}
                    // updateTextThree={'comments'}
                    deleteText={`Reject`}
                    exportText={`Export as PDF`}
                    onUpdate={() => history.push(links[0])}
                    onApprove={() =>
                      setShowModal((prev) => {
                        return { ...prev, approve: true };
                      })
                    }
                    onView={() =>
                      setShowModal((prev) => {
                        return { ...prev, comment: true };
                      })
                    }
                    onDelete={() =>
                      setShowModal((prev) => {
                        return { ...prev, reject: true };
                      })
                    }
                    onOverview={() =>
                      setShowModal((prev) => {
                        return { ...prev, overview: true };
                      })
                    }
                    onAcceptStatus={() =>
                      setShowModal((prev) => {
                        return { ...prev, approveOverview: true };
                      })
                    }
                    onRejectStatus={() =>
                      setShowModal((prev) => {
                        return { ...prev, rejectOverview: true };
                      })
                    }
                  />
                );
              })}
            </ActualReuseableTable>
          </div>
        </EmployeeCommonRevenueLayout>
      </div>

      {/* modal start */}
      <Modal visible={showModal.approve}>
        <EmployeeRevenueApprovalModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approve: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.comment}>
        <EmployeeRevenueComentModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, comment: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmployeeRejectModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.overview}>
        <EmployeeActualRevenuePendingOverviewModal
          nextPage
          rejectText
          onNextPage={() => history.push(links[0])}
          onReject={() =>
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            })
          }
          onApprove={() =>
            setShowModal((prev) => {
              return { ...prev, approve: true, overview: false };
            })
          }
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, overview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeActualRevenueApproveOverviewModal
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            })
          }
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.rejectOverview}>
        <EmployeeActualRevenueRejectOverviewModal
          nextPage
          onNextPage={() => history.push(links[0])}
          onComment={() =>
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            })
          }
          onApprove={() =>
            setShowModal((prev) => {
              return { ...prev, approve: true, rejectOverview: false };
            })
          }
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            })
          }
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeRevenueTableIndex;
