import React, { useContext, useState } from "react";
import CommonExpenseLayout from "./CommonLayout";
import Select from "react-select";
import ActionContext from "../../../../../context/ActionContext";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import ExpenseChart from "./ExpenseChart";
import { useHistory } from "react-router-dom";
import userImg from "../../../../../assets/hod-one-img.svg";

const ExpenseRejected = () => {//   const [showChart, setShowChart] = useState(true);
    const actionCtx = useContext(ActionContext)
      const [indexNo, setIndexNo] = useState("");
    //   const [showChartBox, setShowChartBox] = useState(true);
      const [chartFilterVal, setChartFilterVal] = useState("");
      const history = useHistory()
    
      const categoryList = [
        { label: "Zojapay", value: 1 },
        { label: "ITH", value: 2 },
        { label: "Zojatech", value: 3 },
      ];
    
    
      const tableHeadList = [
        "Departments",
        "User",
        "Item",
        "Description",
        "Unit",
        "Status",
        // "Action",
      ];
      const tableBodyList = [
        {
          subsidiary: "IT Horizons",
          user: { img: userImg, name: "Timi Ayeni" },
          branch: "ITH Branch",
          departments: "Product & Sales",
          amount_projected: "220,000.00",
          status: "reject",
          action: "",
        },
        {
          subsidiary: "IT Horizons",
          user: { img: userImg, name: "Timi Ayeni" },
          branch: "ITH Branch",
          departments: "Product & Sales",
          amount_projected: "220,000.00",
          status: "reject",
          action: "",
        },
        {
          subsidiary: "IT Horizons",
          user: { img: userImg, name: "Timi Ayeni" },
          branch: "ITH Branch",
          departments: "Product & Sales",
          amount_projected: "220,000.00",
          status: "reject",
          action: "",
        },
      ];

  const dataOne = [70000, 15500, 562300, 149800, 10500, 430200, 750000, 103210]
  const dataTwo = [85000, 4500, 897300, 109800, 103200, 78200, 190000, 190210]
    
  return (
    <>
      <CommonExpenseLayout
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div className={`chart-wrap ${actionCtx.isShowChart?.expense_projection && "chat-wrap-show"}`}>
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Actual Expense Graph</p>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Sort By"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <ExpenseChart dataOne={dataTwo} dataTwo={dataOne} />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            // action
            onNextPage
          >
            {tableBodyList.map((chi, idx) => {
              const {
                subsidiary,
                branch,
                departments,
                amount_projected,
                status,
                user,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={subsidiary}
                  userOne={user}
                  two={branch}
                  three={departments}
                  four={amount_projected}
                  progressType={status}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  updateText={` Update Branch`}
                  onNextPage={() =>
                    history.push("/dashboard-actual-expense-main")
                  }
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </CommonExpenseLayout>
    </>
  );
};

export default ExpenseRejected;
