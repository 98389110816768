import React, { useContext, useState } from "react";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import CommonExpenseLayout from "./CommonLayout";
// import ExpenseChart from "./ExpenseChart";
import Select from "react-select";
import ActionContext from "../../../../../context/ActionContext";
import userImg from "../../../../../assets/hod-one-img.svg";
import RevenueChart from "./RevenueChart";
import RevenueChartOne from "./RevenueChartOne";
import { useHistory } from "react-router-dom";

// const Chart = () => {
//   return <p>chart .....</p>;
// };

const RevenueApproved = () => {
  const actionCtx = useContext(ActionContext);
  const history = useHistory()
  // const [showChart, setShowChart] = useState(true);
  const [indexNo, setIndexNo] = useState("");
  // const [showChartBox, setShowChartBox] = useState(true);
  const [chartFilterVal, setChartFilterVal] = useState("");

  const categoryList = [
    { label: "Zojapay", value: 1 },
    { label: "ITH", value: 2 },
    { label: "Zojatech", value: 3 },
  ];

  const tableHeadList = [
    "Subsidiary",
    "Departments",
    "User",
    "Total Revenue",
    "Total GP",
    "Status",
    "Action",
  ];
  const tableBodyList = [
    {
      subsidiary: "IT Horizons",
      branch: "ITH Branch",
      user: { img: userImg, name: "Timi Ayeni" },
      total_revenue: "20,000.00",
      total_gp: "220,000.00",
      status: "accept",
      action: "",
    },
    {
      subsidiary: "IT Horizons",
      branch: "ITH Branch",
      user: { img: userImg, name: "Timi Ayeni" },
      total_revenue: "20,000.00",
      total_gp: "220,000.00",
      status: "accept",
      action: "",
    },
    {
      subsidiary: "IT Horizons",
      branch: "ITH Branch",
      user: { img: userImg, name: "Timi Ayeni" },
      total_revenue: "20,000.00",
      total_gp: "220,000.00",
      status: "accept",
      action: "",
    },
  ];

  const dataOne = [
    70000, 15500, 562300, 149800, 10500, 430200, 750000, 103210, 1700023,
  ];
  const dataTwo = [
    85000, 4500, 897300, 109800, 103200, 78200, 190000, 190210, 250290,
  ];


  const dataOneSingle = [70, 30, 90, 45, 15, 70, 85, 10, 29, 97, 72, 33, 56, 9, 83]

  return (
    <>
      <CommonExpenseLayout>
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Actual Income Graph</p>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Sort By"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              />
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <RevenueChart dataOne={dataOne} dataTwo={dataTwo} />
            </div>
            {/* chart end */}
          </div>
        )}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Gross Profit Per Product</p>
              {/* <Select
                // isSearchable={true}
                // isMulti
                placeholder="Sort By"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              /> */}
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <RevenueChartOne dataOne={dataOneSingle} />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            // action
            onNextPage
          >
            {tableBodyList.map((chi, idx) => {
              const {
                subsidiary,
                branch,
                user,
                total_revenue,
                total_gp,
                status,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={subsidiary}
                  two={branch}
                  user={user}
                  five={total_revenue}
                  six={total_gp}
                  progressType={status}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  updateText={` Update Branch`}
                  onNextPage={() =>
                    history.push("/dashboard-actual-revenue-main")
                  }
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </CommonExpenseLayout>
    </>
  );
};

export default RevenueApproved;
