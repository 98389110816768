import React, { useState, useEffect, useContext } from "react";
import { FaCheck } from "react-icons/fa";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
// import EmployeeRevenueProjectionMonthlyForm from "./MonthlyForm";
// import EmployeeRevenueProjectionSpreadForm from "./SpreadForm";
// import Modal from "react-awesome-modal";
// import EmployeeBulkModal from "../../actual/modals/upload";
import { useHistory } from "react-router-dom";
// import EmployeeSupplementarySpreadForm from "./SpreadForm";
import EmployeeSupplementaryMonthForm from "./monthForm";
import EmployeeSupplementarySpreadForm from "./spreadForm";
// import EmployeeBulkModal from "../modals/upload";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  handleNumberFormatInput,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import moment from "moment";
import {
  createActualSupplementaryBudget,
  getApprovedPrice,
} from "../../../../../../redux/employee/actual-supplementary-budget/actual-supplementary-budget";
import { Dna } from "react-loader-spinner";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { NumericFormat } from "react-number-format";
import {
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import { checkDepartmentApprovalFlow } from "../../../../../../redux/employee/approval-flow";
import ActionContext from "../../../../../../context/ActionContext";

const EmployeeActualSupplementaryForm = () => {
  const [details, setDetails] = useState({
    date: "",
    chart_of_account_id: "",
    user_id: "",
    organization_id: "",
    monthly_breakdown: [{ name: { label: "" }, value: "" }],
    approved_price: "",
    new_price: "",
    unit_price: "",
    description: "",
    units: "",
    subsidiary_id: "",
    // status: "",
    is_submitted: "",
    entry_type: "",
    total_supplementary: "",
    total_quantity: "",
    department_id: "",
    fiscal_year_id: "",
    supplementary_type: "",
  });

  const [spreadForm, setSpreadForm] = useState(false);
  const [draftLoader, setDraftLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);
  // const [btnChange, setBtnChange] = useState(false);
  const [monthlyForm, setMonthlyForm] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [changeLabel, setChangeLabel] = useState({
    inputLabel: "Quantity",
    totalLabel: "Total Quantity",
  });
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  // const { loading } = useSelector((state) => state.actual_supplementary_budget);
  const [startDate, setstartDate] = useState("");
  const { loading, chatAccountDrop } = useSelector(
    (state) => state.chatOfAccount
  );
  const [spreadTotalVal, setSpreadTotalVal] = useState("");
  // const [refreshTable, setRefreshTable] = useState(false);
  const [totalQuantity, setTotalQuatity] = useState("");
  const { departmentDrop, loadingPage } = useSelector(
    (state) => state.department
  );
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const [totalValue, setTotalValue] = useState("");
  const [approvedAmount, setApprovedAmount] = useState("");
  const [newAmount, setNewAmount] = useState("");
  //   const [showModal, setShowModal] = useState(false);
  const [refreshTable] = useState(false);

  // const history = useHistory();
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);

  useEffect(() => {
    if (details?.supplementary_type === "spread") {
      const totalSpreadVal =
        formatNumberWithoutComma(String(details?.unit_price || 0)) *
        formatNumberWithoutComma(String(details?.units || 0));
      setSpreadTotalVal(totalSpreadVal);
      if (approvedAmount && spreadTotalVal) {
        const newAmountVal = approvedAmount + spreadTotalVal;
        setNewAmount(newAmountVal || 0);
      }
    }
    if (
      (details?.supplementary_type === "monthly" &&
        details?.entry_type?.label === "Quantity") ||
      (approvedAmount && totalValue)
    ) {
      const totalQua = details?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      const totalQuaAddListVal = totalQuaAddList || 0;
      setTotalQuatity(totalQuaAddListVal);
      const totalVal =
        totalQuaAddList *
        formatNumberWithoutComma(String(details.unit_price || 0));
      const totalValRound = totalVal || 0;
      setTotalValue(totalValRound);

      const newAmountVal = approvedAmount + totalValue;
      setNewAmount(newAmountVal || 0);

      // console.log(totalVal, "totalVal");
    }
    if (
      details?.supplementary_type === "monthly" &&
      details?.entry_type?.label === "Amount"
    ) {
      const totalQua = details?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      const totalQuaAddListVal = totalQuaAddList || 0;
      setTotalValue(totalQuaAddListVal);

      if (
        details?.supplementary_type === "monthly" &&
        details?.entry_type?.label === "Amount"
      ) {
        const totalApprovedVal =
          totalValue /
          formatNumberWithoutComma(String(details?.unit_price || 0));
        const totalApprovedValue = totalApprovedVal || 0;
        setTotalQuatity(totalApprovedValue);
      }
      if (approvedAmount && totalValue) {
        const newAmountVal = approvedAmount + totalValue;
        setNewAmount(newAmountVal || 0);
      }
    }
    //eslint-disable-next-line
  }, [details, totalValue, spreadTotalVal, approvedAmount, newAmount]);

  const handleGetApprovedData = async () => {
    const obj = {
      dept_id: details?.department_id?.id,
      chat_id: details?.chart_of_account_id?.id,
      fiscal_year: details?.fiscal_year_id?.id,
    };
    // console.log(obj?.chat_id);
    const data = await dispatch(getApprovedPrice(obj));
    if (data?.payload?.success) {
      const approvedCalc = data?.payload?.data?.expenditure_sum;
      const approvedCalcRound = approvedCalc || 0;
      setApprovedAmount(approvedCalcRound);
      // console.log(approvedCalc, "approved-price");
    }
  };

  // console.log("userdepartment", details?.department_id?.id);
  // console.log("chart of account id", details?.chart_of_account_id?.id);

  useEffect(() => {
    const empObj = {
      id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
      reason: true,
    };
    dispatch(getChatAccounts(empObj));
    dispatch(getSubsidiaries(empObj));
    dispatch(getDepartments(actionCtx?.activeOrg?.id));
    dispatch(getFiscalYears(empObj));
    dispatch(getUserDepartment(empObj?.id));
    // if (
    //   details?.department_id?.id &&
    //   details?.chart_of_account_id?.id &&
    //   details?.fiscal_year_id
    // ) {
    //   handleGetApprovedData();
    // }

    // eslint-disable-next-line
  }, [refreshTable]);

  useEffect(() => {
    if (
      details?.department_id?.id &&
      details?.chart_of_account_id?.id &&
      details?.fiscal_year_id
    ) {
      handleGetApprovedData();
    }
  }, [details]);

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name } = e.target;
  //   const data = { ...details, [name]: handleNumberFormatInput(e) };
  //   setDetails(data);
  // };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleInputOptionToMonthList = (e, item) => {
    const newList = details?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id
        ? {
            ...chi,
            value: handleNumberFormatInput(e),
          }
        : chi
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };

  const handleAddToMonthList = () => {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const obj = {
      id: id,
      name: { label: "", value: "" },
      value: "",
    };
    const newList = {
      ...details,
      monthly_breakdown: [...details.monthly_breakdown, obj],
    };
    setDetails(newList);
    // console.log(newList);
    // console.log(test);
  };

  const enableSubmit = () => {
    let allowSubmit = false;

    allowSubmit =
      startDate &&
      details?.chart_of_account_id &&
      (details?.approved_price || details?.approved_price === "") &&
      // details?.new_price &&
      newAmount &&
      details?.department_id &&
      details?.subsidiary_id &&
      details?.fiscal_year_id &&
      details?.description &&
      details?.unit_price;

    if (details.supplementary_type === "spread") {
      allowSubmit = allowSubmit && details?.units;
    } else {
      allowSubmit =
        totalValue &&
        totalQuantity &&
        details?.monthly_breakdown &&
        details?.monthly_breakdown[0] &&
        details?.entry_type &&
        allowSubmit;
    }

    return allowSubmit;
  };

  const handleSelectOptionToMonthList = (e, item) => {
    // console.log(e);
    // console.log(item);
    const newList = details?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id ? { ...chi, name: e } : chi
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };

  const handleRemoveOptionMonthList = (item) => {
    const newList = details?.monthly_breakdown?.filter(
      (chi) => chi?.id !== item?.id
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(details);

    const checkObj = {
      projection_type: "Supplementary Budget",
      department_id: details?.department_id?.value,
    };
    details?.is_submitted === false && setDraftLoader(true);
    details?.is_submitted === true && setApproveLoader(true);
    const checkData = await dispatch(checkDepartmentApprovalFlow(checkObj));
    if (checkData?.payload?.status !== 201) {
      details?.is_submitted === false && setDraftLoader(false);
      details?.is_submitted === true && setApproveLoader(false);
      return;
    }
    const newList = details.monthly_breakdown.map((chi) => {
      return {
        value: formatNumberWithoutComma(String(chi.value)),
        name: chi.name.label,
      };
    });

    const obj = {
      ...details,
      date: startDate,
      chart_of_account_id: details?.chart_of_account_id?.value,
      monthly_breakdown: newList,
      organization_id: actionCtx?.activeOrg?.id,
      department_id: details?.department_id?.value,
      user_id: user?.id,
      approved_price: approvedAmount,
      subsidiary_id: details?.subsidiary_id?.value,
      // approved_price: formatNumberWithoutComma(String(details?.approved_price)),
      // new_price: formatNumberWithoutComma(String(details?.new_price)),
      new_price: newAmount,
      fiscal_year_id: details?.fiscal_year_id?.value,
      // status: details?.status === "pending" ? "pending" : "draft",
      is_submitted: details?.is_submitted === true ? true : false,
      total_supplementary:
        details?.supplementary_type === "spread" ? spreadTotalVal : totalValue,
      // total_supplementary: spreadTotalVal,
      total_quantity: totalQuantity,
      entry_type: details.entry_type?.label,
      projection_type: details?.projection_type,
      unit_price: formatNumberWithoutComma(String(details.unit_price)),
      // units: Number(totalQuantity),
      units: !!formatNumberWithoutComma(String(details.units))
        ? formatNumberWithoutComma(String(details.units))
        : formatNumberWithoutComma(String(totalQuantity)),
    };
    // console.log("departent_id", obj);
    // return
    if (details.supplementary_type === "spread") {
      delete obj["monthly_breakdown"];
      obj["total_value"] = spreadTotalVal;
    }
    // console.log(obj);
    // return;
    if (obj?.is_submitted === false) {
      setDraftLoader(true);
    }
    if (obj?.is_submitted === true) {
      setApproveLoader(true);
    }
    const data = await dispatch(createActualSupplementaryBudget(obj));

    if (data?.payload?.success && obj?.is_submitted === false) {
      // setDetails({
      //   date: "",
      //   chart_of_account_id: "",
      //   user_id: "",
      //   organization_id: "",
      //   monthly_breakdown: [{ name: { label: "" }, value: "" }],
      //   approved_price: "",
      //   new_price: "",
      //   unit_price: "",
      //   units: "",
      //   description: "",
      //   status: "",
      //   entry_type: "",
      //   total_supplementary: "",
      //   total_quantity: "",
      //   department_id: "",
      //   fiscal_year_id: "",
      //   supplementary_type: "",
      // });
      // setstartDate("");
      // setSpreadTotalVal("");
      // setTotalQuatity("");
      // setTotalValue("");
      // setSpreadForm(false);
      // setMonthlyForm(false);
      setDraftLoader(false);
      history.push("/employee-dashboard-actual-supplementary-budget");
    } else if (data?.payload?.success && obj?.is_submitted === true) {
      setApproveLoader(false);
      history.push("/employee-dashboard-actual-supplementary-budget-submitted");
    } else {
      setDraftLoader(false);
      setApproveLoader(false);
    }
    // console.log(obj);
  };

  const entryType = [
    {
      label: "Quantity",
      value: 1,
    },
    {
      label: "Amount",
      value: 2,
    },
  ];

  const handleSpreadForm = () => {
    setSpreadForm(true);
    setMonthlyForm(false);
    // setBtnChange(true);
  };

  const handleMonthlyForm = () => {
    setMonthlyForm(true);
    setSpreadForm(false);
    // setBtnChange(true);
  };
  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Supplementary Budget`}> */}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">New Supplementary</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          {/* <div
              className="btn-download-box"
              onClick={() => setShowModal(true)}
            >
              <button className={`btn-bulk `}>
                {" "}
                <FaPlus className="icon" /> Bulk Upload
              </button>
              <p className="text">See and download template</p>
            </div> */}
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container */}

        <div className="form-box" style={{ width: "80%" }}>
          <form onSubmit={handleSubmit} action="" className="form">
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Supplementary Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="date" className="label">
                    Date <span>*</span>
                  </label>
                  <Flatpickr
                    id="date"
                    name="date"
                    className={`input ${startDate && "input-active"}`}
                    autoComplete="off"
                    value={startDate}
                    onChange={(date) => {
                      setstartDate(moment(date[0]).format("YYYY-MM-DD"));
                    }}
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Chart of account <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details?.chart_of_account_id && "select-wrap-active"
                    }`}
                    placeholder="Select chart of account"
                    value={details?.chart_of_account_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, chart_of_account_id: e };
                      });
                      // console.log(e?.id, "test");
                    }}
                    styles={reactSelectStyle}
                    options={chatAccountDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    placeholder="Select Subsidiary"
                    value={details?.subsidiary_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                      const obj = {
                        reason: true,
                        id: e?.value,
                      };
                      dispatch(getDepartmentsBySubsidiary(obj));
                    }}
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="department" className="label">
                    Department <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.department_id && "select-wrap-active"
                    }`}
                    placeholder="Select Department"
                    value={details?.department_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, department_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={details?.subsidiary_id ? departmentDrop : []}
                    isLoading={loadingPage}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Fiscal Year <span>*</span>
                  </label>
                  <Select
                    placeholder="Select fiscal year"
                    className={`select-wrap ${
                      details.fiscal_year_id && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    value={details?.fiscal_year_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, fiscal_year_id: e };
                      });
                    }}
                    options={fiscalYearDrop}
                    isLoading={loading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="approved_price" className="label">
                    Approved Amount <span>*</span>
                  </label>
                  <NumericFormat
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    disabled
                    name="approved_price"
                    // type="number"
                    placeholder="Approved Price"
                    onChange={handleChange}
                    value={approvedAmount || ""}
                    style={{ background: "#ECEDED" }}
                    id="approved_price"
                    className={`input`}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="new_price" className="label">
                    New Amount <span>*</span>
                  </label>
                  <NumericFormat
                    // type="number"
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    disabled
                    name="new_price"
                    onChange={handleChange}
                    style={{ background: "#ECEDED" }}
                    // value={details?.new_price}
                    value={newAmount || ""}
                    id="new_price"
                    placeholder="Enter new price"
                    className={`input ${details?.new_price && "input-active"}`}
                  />
                </div>
                {/* group box end */}

                {/* group box start */}
                <br />
                <div className="form-group">
                  <label htmlFor="description" className="label">
                    Description
                    <span>*</span>
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={handleChange}
                    style={{ width: "206%", resize: "none", padding: "30px" }}
                    cols="30"
                    rows="7"
                    placeholder="Enter your description here..."
                    className={`input ${
                      details?.description && "input-active"
                    }`}
                    value={details?.description}
                  />
                </div>
                {/* group box end */}
              </div>
              <div className="">
                {/* second wrap start */}
                {/* <div className="wrapper"></div> */}
                <div>
                  <div className="wrapper"></div>
                  <p className="box-title">2. Supplementary Format</p>
                </div>
                {/* form check bos start */}
                <div className="form-check-box" style={{ width: "60rem" }}>
                  {/* check group start */}
                  <div className="check-group" onChange={handleSpreadForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-spread-evenly"
                      value="spread"
                      checked={details?.supplementary_type === "spread"}
                      onChange={(e) => {
                        setDetails((prev) => {
                          setTotalQuatity("");
                          setTotalValue("");
                          // setApprovedAmount("");
                          // setNewAmount("");
                          return {
                            ...prev,
                            supplementary_type: e.target.value,
                            unit_price: "",
                            units: "",
                            entry_type: "",
                            monthly_breakdown: [
                              { name: { label: "" }, value: "" },
                            ],
                          };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-spread-evenly"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Spread Evenly</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (share value accross all months)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                  {/* check group start */}
                  <div className="check-group" onChange={handleMonthlyForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-monthly"
                      value="monthly"
                      checked={details?.supplementary_type === "monthly"}
                      onChange={(e) => {
                        setDetails((prev) => {
                          setSpreadTotalVal("");
                          // setNewAmount("");
                          return {
                            ...prev,
                            supplementary_type: e.target.value,
                            unit_price: "",
                            units: "",
                          };
                        });
                      }}
                    />
                    <label htmlFor="check-monthly" className="check-label">
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Monthly Entry</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (specify value for each months)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                </div>

                {spreadForm && (
                  <EmployeeSupplementarySpreadForm
                    onInputChange={handleChange}
                    unit_price={details?.unit_price}
                    units={details?.units}
                    total_value={spreadTotalVal || ""}
                  />
                )}
                {monthlyForm && (
                  <EmployeeSupplementaryMonthForm
                    unit_price={details?.unit_price}
                    onInputChange={handleChange}
                    handleEntryTypeChange={(e) => {
                      // console.log("check", e);
                      console.log(e.value);
                      e.value === 2
                        ? setChangeLabel((prev) => {
                            return {
                              ...prev,
                              inputLabel: "Amount",
                              totalLabel: "Total Quantity",
                            };
                          })
                        : setChangeLabel(
                            !changeLabel.inputLabel,
                            !changeLabel.totalLabel
                          );
                      setDetails({ ...details, entry_type: e });
                    }}
                    list={details?.monthly_breakdown}
                    // value={}
                    Quantity={changeLabel?.inputLabel}
                    Total_quantity_label={changeLabel?.totalLabel}
                    onSelectvalueChange={handleSelectOptionToMonthList}
                    onInputValueChange={handleInputOptionToMonthList}
                    handleAddMonthList={handleAddToMonthList}
                    handleRemoveMonthList={handleRemoveOptionMonthList}
                    entryType={entryType}
                    quantity={details?.units}
                    total_quantity={totalQuantity || ""}
                    total_supplementary={totalValue || ""}
                    entry_type_value={details?.entry_type}
                    // name={details?.monthly_breakdown?.month_name}
                  />
                )}
                {/* form check bos end */}
              </div>{" "}
            </div>
            {/* second wrap end*/}
            <div className="wrapper"></div>
            {/* btn box start */}
            {/* <div className="btn-box-wrap">
              {!btnChange && (
                <button
                  className="btn-reuse btn-two"
                  style={{ display: "none" }}
                  onClick={() =>
                    history.push(
                      "/employee-dashboard-actual-supplementary-budget-submit"
                    )
                  }
                >
                  Submit for approval
                </button>
              )}
              <>
                {" "}
                <button
                  className="btn-reuse btn-two"
                  style={{ backgroundColor: "#ffff", marginRight: "3rem" }}
                  onClick={() =>
                    history.push(
                      "/employee-dashboard-actual-supplementary-budget-submit"
                    )
                  }
                >
                  Submit for approval
                </button>
                {btnChange && (
                  <button
                    className="btn-reuse btn-two"
                    style={{
                      backgroundColor: "var(--blue-color)",
                      color: "#fff",
                    }}
                    onClick={() =>
                      history.push(
                        "/employee-dashboard-actual-supplementary-budget-draft"
                      )
                    }
                  >
                    Save Budget
                  </button>
                )}
                {!btnChange && (
                  <button
                    className="btn-reuse btn-two"
                    onClick={() =>
                      history.push(
                        " "
                      )
                    }
                  >
                    Save Budget
                  </button>
                )}
              </>
            </div> */}

            {/* btn box end */}
            {/* btn start */}

            <div style={{ display: "flex", gap: "3rem" }}>
              {!approveLoader ? (
                <input
                  // onClick={() => setDetails({ ...details, status: "pending" })}
                  onClick={() => setDetails({ ...details, is_submitted: true })}
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Submit For Approval"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{ height: "5rem" }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="40"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
              {!draftLoader ? (
                <input
                  onClick={() =>
                    setDetails({ ...details, is_submitted: false })
                  }
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Save To Draft"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{
                    height: "5rem",
                    background: "#ffff",
                    color: !enableSubmit() ? "#cccc" : "var(--blue-color)",
                    border: !enableSubmit()
                      ? ".1rem solid #cccc"
                      : ".1rem solid var(--blue-color)",
                  }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="40"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
              {/* button end */}
              {/* button start */}
              {/* {!loading ? ( */}
            </div>
            {/* btn end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
      {/* modal start */}
      {/* <Modal effect="fadeInUp" visible={showModal}>
        <EmployeeBulkModal onCancel={() => setShowModal(false)} />
      </Modal> */}
      {/* modal end */}
    </>
  );
};

export default EmployeeActualSupplementaryForm;
