import React, {  useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import {
  ExpenditureBulkUpload,
} from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import "../../../../../../styles/dashboard/modals/bulkUpload.scss";

const EmployeeBulkModal = ({ children, onCancel}) => {
  const dispatch = useDispatch();
  const [fileUpload, setFileUpload] = useState("");
  const { loadingTwo } = useSelector((state) => state.expenditure_projection);

  const { user } = useSelector((state) => state.user);

  // console.log(fileUpload, "name file");

  const handleUpload = async () => {
    // e.preventDefault();
    if (fileUpload) {
      const obj = {
        file: fileUpload,
        org_id: user?.organizations[0]?.id,
      };
      const formData = new FormData();
      formData.append("file", obj?.file);
      formData.append("organization_id", obj?.org_id);
      const data = await dispatch(ExpenditureBulkUpload(formData));
      if (data?.payload?.success) {
        // console.log(data, "data");
        // setFileUpload("");
        onCancel();
      }
    }
  };

  // console.log(fileUpload, "file-upload");

  return (
    <>
      <div className="emp-bulk-upload-wrap ">
        <div className=" cancel-wrap">
          <div>
            <p className="title">Upload Executed Document</p>
            <p className="text">
              Select a csv file of up to 1gb. Selected documents must be title
              with the file name
            </p>
          </div>
          <div
            onClick={() => {
              setFileUpload("");
              onCancel();
            }}
            className="cancel-box"
          >
            <FaTimes className="icon" />
          </div>
        </div>
        <div className="span-wrap">
          <div className="span-wrap-two">
            <p>CSV file</p>
            {/* <p className="span-text">{showFile}</p> */}
            <p className="span-text">
              {fileUpload ? fileUpload?.name : "No file is Selected"}
            </p>
          </div>
          <input
            type="file"
            id="file_upload"
            accept="csv"
            // value={fileUpload?.name}
            onChange={(e) => {
              setFileUpload(e?.target?.files[0]);
            }}
            // ref={hiddenFileInput}
            // onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <label
            htmlFor="file_upload"
            className="span-text-two"
            style={{ cursor: "pointer" }}

            // onClick={(e) =>   handleFileChange(e)}
          >
            Browse file
          </label>
        </div>
        <div className="btn-wrap">
          <ButtonBlue
            onClick={() => {
              setFileUpload("");
              onCancel();
            }}
            text={`cancel`}
          />
          {loadingTwo ? (
            <div
              className="dna-wrapper-box"
              style={{ alignSelf: "center", marginTop: "0rem" }}
            >
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <ButtonBlue
              // bgColor={showFile ? "var(--blue-color)" : "#ECEDED"}
              onClick={() => handleUpload(onCancel)}
              bgColor={fileUpload ? "var(--blue-color)" : "#ECEDED"}
              text={`Start Upload`}
            />
          )}
        </div>
        {/* // #ECEDED` */}
      </div>
      {children}
    </>
  );
};
export default EmployeeBulkModal;
