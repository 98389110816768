import React, { useEffect, useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
// import imgOne from "../../../../../../assets/hod-two-img.svg";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import {
  ApproveExpenditureProjection,
  getUserDepartment,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { Dna } from "react-loader-spinner";
import userImg from "../../../../../../assets/Unknown_person.jpeg";
import { FaTimes } from "react-icons/fa";

const EmployeeRejectModal = ({
  onCancel,
  details,
  onClose,
  onRefreshCard,
  onDispatch,
}) => {
  const [lists, setLists] = useState({ comment: "" });
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.expenditure_projection);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...lists, [name]: value };
    setLists(data);
  };

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    // eslint-disable-next-line
  }, [user?.organizations[0]?.id]);

  const handleSubmit = async () => {
    // e.preventDefault();
    const obj = {
      organization_id: user?.organizations[0]?.id,
      department_id: details?.department?.id,
      // subsidiary_id: ,
      projection_type: "actual_revenue",
      comment: lists?.comment,
      status: "rejected",
      projection_id: details?.id,
    };
    const data = await dispatch(ApproveExpenditureProjection(obj));
    if (data?.payload?.success) {
      setLists("");
      onDispatch();
      onClose();
      onRefreshCard();
    }
  };
  return (
    <div className="overview-card-modal-wrap emp-approval-modal">
      <div className="top-box">
        <p className="title">{`Reject (${details?.product?.name})`}</p>
        <div
          onClick={() => {
            setLists((prev) => {
              return { ...prev, comment: "" };
            });
            onCancel();
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      <div className="content-box">
        <div className="img-input-wrap">
          <figure className="img-box">
            <img
              style={{
                borderRadius: "20%",
                width: "4rem",
                height: "4rem",
              }}
              src={user?.profile_picture || userImg}
              alt=""
              className="img"
            />
          </figure>
          <textarea
            placeholder="type here your comment"
            style={{ resize: "unset" }}
            name="comment"
            value={lists?.comment}
            onChange={handleChange}
            id="comment"
            cols="30"
            rows="5"
            className="input-text"
          ></textarea>
        </div>
      </div>
      <div className="bottom-box">
        <ButtonBlue onClick={onCancel} text={`Cancel`} />
        {loading ? (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "center", marginTop: "0rem" }}
          >
            <Dna
              visible={true}
              height="40"
              width="100"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <ButtonBlue
            text={`Yes, Reject`}
            bgColor={!lists?.comment && "#CCCC"}
            onClick={() => {
              lists?.comment &&
                handleSubmit(onClose, onRefreshCard, onDispatch);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeRejectModal;
