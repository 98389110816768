// import ReuseButton from "../../../fragments/ReuseButton";
import Select from "react-select";
// import { formatNumWithCommaNairaSymbol } from "../../../utils/Helper";
import "../../../../../styles/dashboard/subscription/modals/upgradePlan.css";
import { useContext, useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
// import { upgradeSubscription } from "../../../redux/subsciption/subscription";
import { Dna } from "react-loader-spinner";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import { validateEmail } from "../../../components/helper/uSeOutsideClick";
// import PaystackPop from "@paystack/inline-js";
import {
  formatNumWithCommaNairaSymbol,
  trimLongString,
  validateEmail,
} from "../../../../../../helper/Helpers";
import { upgradeSubscription } from "../../../../../../redux/subscription/subscription";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import ActionContext from "../../../../../../context/ActionContext";

const UpgradePlan = ({
  onCancel,
  detail,
  planOption,
  planValue,
  onPlanChange,
  setSuccessModal,
  setShowModal,
  setUrl,
  currencyObj,
  selectActive,
  setSelectActive,
  // isLoading,
}) => {
  //   const planDetails = ["1-5 Users", "No Subsidiaries", "No Branches"];
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.subscription);
  const { user } = useSelector((state) => state.user);

  const selectStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#ffff",
      border: "0.1rem solid var(--blue-color)",
      //   border: state.isFocused ? "0.1rem solid var(--blue-color)" : "#ffff",
      borderRadius: ".5rem",
      boxShadow: state.isFocused ? "var(--blue-color)" : 0,
      "&:hover": null,
    }),
    container: (provided) => ({
      ...provided,
      fontWeight: "400",
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#000",
      "&:hover": null,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected && "var(--blue-color)",
    }),
  };

  const actionCtx = useContext(ActionContext);

  const handleSubmit = async () => {
    const obj = {
      subscription_type: selectActive ? "yearly" : "monthly",
      subscription_plan_id: planValue?.value,
      user_id: user?.id,
      email: user?.email,
    };
    const data = await dispatch(upgradeSubscription(obj));

    if (data?.payload?.response?.status === 400) {
      toast.error("Current subscription still running!", {
        theme: "colored",
        position: "top-right",
      });
    }
    if (data?.payload?.status === 200) {
      // console.log(data, "data");
      //   console.log(data?.payload?.data?.authorization_url, "chek");
      setUrl(data?.payload?.data?.data);
      setSuccessModal(true);
      setShowModal(false);
      actionCtx.setIsModalOut(true);
    }
  };

  const checkBox = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="10" fill="var(--blue-color)" />
      <path
        d="M16.8 5.59961L8 14.3996L4 10.3996"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  return (
    <div className="upgrade-plan-wrap">
      <div className="upgrade-plan-container">
        <p className="upgrade-title">Subscription Plan</p>
        <p className="cancel-icon" onClick={onCancel}>
          {cancelIcon}
        </p>

        {/* current plan wrap start */}
        <div className="current-plan-wrap">
          <label>Plan:</label>
          <Select
            styles={selectStyle}
            className="upgrade-select upgrade-active-select"
            placeholder="Current Plan"
            options={planOption}
            // onChange={(e) => {
            //   setPlanSelect((prev) => {
            //     return { ...prev, subscription_plan_id: e?.value };
            //   });
            // }}
            onChange={onPlanChange}
            value={planValue}
            isLoading={loading}
          />
        </div>
        {/* current plan wrap end */}
        {/* currency select start */}
        <div className="plan-content-wrap">
          {loading ? (
            <div style={{ marginBottom: "1rem" }}>
              <Skeleton width={100} height={`30%`} />
            </div>
          ) : (
            <p className="upgrade-amount">
              {`${
                !selectActive
                  ? formatNumWithCommaNairaSymbol(
                      Number(
                        planValue?.monthly_price || detail?.monthly_price
                      ) / Number(currencyObj?.rate),
                      currencyObj?.name
                    )
                  : formatNumWithCommaNairaSymbol(
                      Number(planValue?.yearly_price || detail?.yearly_price) /
                        Number(currencyObj?.rate),
                      currencyObj?.name
                    )
              } / ${!selectActive ? "Monthly" : "Yearly"}` || <>&nbsp;</>}
            </p>
          )}
          {/* date wrap start */}
          <div className="select-date-wrap">
            <p
              onClick={() => {
                setSelectActive(false);
              }}
              className={`select-date ${!selectActive && "active-select"}`}
            >
              Monthly
            </p>
            <p
              onClick={() => {
                setSelectActive(true);
              }}
              className={`select-date ${selectActive && "active-select"}`}
            >
              Yearly
            </p>
          </div>
          {/* date wrap end */}
        </div>
        {/* currency select end */}
        <div className="current-plan-container">
          {/* plan details wrap start */}
          <div className="plan-details-wrap">
            {loading ? (
              <Skeleton width={300} height={`10%`} />
            ) : (
              <p className="plan-title">
                {planValue?.description || detail?.description}
              </p>
            )}
            <div className="plan-details-container">
              {(planValue?.limited_to || detail?.limited_to)?.map(
                (chi, idx) => {
                  return (
                    <div key={idx} className="plan-details-box">
                      {loading ? (
                        <Skeleton width={20} circle={true} height={`30%`} />
                      ) : (
                        <figure className="check-box">{checkBox}</figure>
                      )}
                      {loading ? (
                        <Skeleton width={200} height={`30%`} />
                      ) : (
                        <p>{chi}</p>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
          {/* plan details wrap end */}
        </div>
        {/* contact info start */}
        <div className="contact-info-wrap">
          {/* contact info box start */}
          {/* <div>
            <label htmlFor="email" className="contact-label">
              Work Email
            </label>
            <input
              className={`contact-input ${
                details?.email && "contact-input-active"
              }`}
              type="email"
              name="email"
              id="email"
              value={details?.email}
              onChange={handleChange}
            />
          </div> */}
          {/* contact info box end */}

          {/* contact info box start */}
          {/* <div>
          <label className="contact-label">Phone Number</label>
          <PatternFormat
            type="text"
            className="contact-input"
            id="contact line"
            name="contact_line"
            format="### ### ### ###"
            value={details?.phone_number}
          />
        </div> 
          {/* contact info box end */}

          {/* btn wrap start */}
          <div className="upgrade-btn">
            {loading ? (
              <Dna
                visible={true}
                height="50"
                width="100"
                ariaLabel="dna-loading"
                wrapperClass="dna-wrapper"
              />
            ) : (
              <ButtonBlue text={"Upgrade Plan"} onClick={handleSubmit} />
            )}
          </div>
          {/* btn wrap end */}
        </div>
        {/* contact info end */}
      </div>
    </div>
  );
};
export default UpgradePlan;

export const cancelIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.8332 5.34199L14.6582 4.16699L9.99984 8.82533L5.3415 4.16699L4.1665 5.34199L8.82484 10.0003L4.1665 14.6587L5.3415 15.8337L9.99984 11.1753L14.6582 15.8337L15.8332 14.6587L11.1748 10.0003L15.8332 5.34199Z"
      fill="#CC0905"
    />
  </svg>
);
