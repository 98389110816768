import ZohoImg from "../../../../../assets/zohoImg.svg";
import revvexLogo from "../../../../../assets/revvex-logo.svg";
// import loading from "../../../../../assets/loading.svg";
import "../../../../../styles/dashboard/integration/zohoRedirect.css";
import stockInvestImg from "../../../../../assets/stock-investing-img.svg";
import economyGraphImg from "../../../../../assets/economy-graph-img.svg";
import { updateToZohoIntegration } from "../../../../../redux/integration/integration";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
// import { ThreeDots } from "react-loader-spinner";
const ZohoRedirect = () => {
  const history = useHistory();
  const [refreshTable] = useState(false);
  const dispatch = useDispatch();
  const handleUpdateToZoho = async () => {
    const windowUrl = window.location.href;
    const windowUrlSplit = windowUrl.split("code=");
    const hashValue = windowUrlSplit[1];
    const hashValueSplit = hashValue.split("&l");
    const hashValueSplitOne = hashValueSplit[0];
    const obj = {
      code: hashValueSplitOne,
      name: "zoho expense",
    };
    // console.log(obj);
    const data = await dispatch(updateToZohoIntegration(obj));
    // console.log(data, "data");
    if (data?.payload?.success) {
      history.push("/dashboard-home");
    }
  };

  useEffect(() => {
    const delay = 3000;
    const duration = setTimeout(() => {
      handleUpdateToZoho();
    }, delay);
    return () => clearTimeout(duration);
  }, []);
  return (
    <div className="zoho-redirect-wrap">
      <div className="zoho-redirect-box">
        <div>
          <p className="sync-text">Sync in Progress</p>
          <p className="wait-text">Please wait...</p>
        </div>

        {/* logo wrap start */}
        <div className="logo-img">
          <img src={revvexLogo} alt="" />
          <div className="loading-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            {/* <div></div>
            <div></div>
            <div></div>
            <div></div> */}
          </div>
          <img src={ZohoImg} alt="" />
        </div>
        {/* <p onClick={handleUpdateToZoho}>Route to homepage</p> */}
        {/* logo wrap end */}
      </div>
      {/* stock image wrap start */}
      <div className="stock-img">
        <img src={stockInvestImg} alt="" />
        <img src={economyGraphImg} alt="" />
      </div>
      {/* stock image wrap end */}
    </div>
  );
};
export default ZohoRedirect;
