import Report from "../../../../component/pages/dashboard/general/report";
import CustomReport from "../../../../component/pages/dashboard/general/report/customReport";
import CreateReport from "../../../../component/pages/dashboard/general/report/customReport/create";
import ActualExpense from "../../../../component/pages/dashboard/general/report/routes/actualExpense";
import ActualIncome from "../../../../component/pages/dashboard/general/report/routes/actualRevenue";
import BudgetPerformance from "../../../../component/pages/dashboard/general/report/routes/budgetPerformance";
import BudgetVariance from "../../../../component/pages/dashboard/general/report/routes/budgetVariance";
import CashFlow from "../../../../component/pages/dashboard/general/report/routes/cashflow";
import ConsolidatedBudget from "../../../../component/pages/dashboard/general/report/routes/consolidatedBudget";
import EngDeptConsolidatedBudget from "../../../../component/pages/dashboard/general/report/routes/consolidatedBudget/sub-index";
import ExpenseProjectionReport from "../../../../component/pages/dashboard/general/report/routes/expenseProjection";
import ExpenseGPProjection from "../../../../component/pages/dashboard/general/report/routes/expProVsGpPro";
import ProjectionActualGP from "../../../../component/pages/dashboard/general/report/routes/projVsActGP";
import ProjectionActualIncome from "../../../../component/pages/dashboard/general/report/routes/projVsActRev";
import RecurrentAndCapitalBudget from "../../../../component/pages/dashboard/general/report/routes/recurrentBudget";
import RecurrentAndCapitalExpense from "../../../../component/pages/dashboard/general/report/routes/recurrentExpense";
import RevenuePerformance from "../../../../component/pages/dashboard/general/report/routes/revenuePerformance";
import GeneralRevenueProjection from "../../../../component/pages/dashboard/general/report/routes/revenueProjection";
import RevenueVariance from "../../../../component/pages/dashboard/general/report/routes/revenueVariance";

export const Dashboard_general_report_route_group = [
  {
    path: "/dashboard-general-report",
    component: Report,
    pageTitle: "Reports",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-consolidated-budget",
    component: ConsolidatedBudget,
    pageTitle: "View Consolidated Budget Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-consolidated-budget-engineering-department",
    component: EngDeptConsolidatedBudget,
    pageTitle: "View Consolidated Budget Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-revenue-projection",
    component: GeneralRevenueProjection,
    pageTitle: "View Revenue Projection Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-budget-performance",
    component: BudgetPerformance,
    pageTitle: "View Budget Performance Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-revenue-performance",
    component: RevenuePerformance,
    pageTitle: "View Revenue Performance Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-budget-variance",
    component: BudgetVariance,
    pageTitle: "View Budget Variance Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-revenue-variance",
    component: RevenueVariance,
    pageTitle: "View Revenue Variance Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-recurrent-and-capital-budget",
    component: RecurrentAndCapitalBudget,
    pageTitle: "View Recurrent and Capital Budget Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-actual-income",
    component: ActualIncome,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-actual-expense",
    component: ActualExpense,
    pageTitle: "Actual Expense",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-recurrent-and-capital-expense",
    component: RecurrentAndCapitalExpense,
    pageTitle: "View Recurrent and Capital Expense Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-projection-vs-actual-gp",
    component: ProjectionActualGP,
    pageTitle: "View Projections Vs. Actual GP Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-projection-vs-actual-income-and-actual-gp",
    component: ProjectionActualIncome,
    pageTitle: "View Projections Vs. Actual Income & Actual GP Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-expense-projections-vs-gp-projections",
    component: ExpenseGPProjection,
    pageTitle: "View Expense Projection Vs. GP Projection Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-cashflow",
    component: CashFlow,
    pageTitle: "View Cashflow Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-expense-projection",
    component: ExpenseProjectionReport,
    pageTitle: "View Expense Projection Report",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-custom",
    component: CustomReport,
    pageTitle: "Reports",
    goBack: true,
  },
  {
    path: "/dashboard-general-report-create",
    component: CreateReport,
    pageTitle: "Reports",
    goBack: true,
  },
];
