import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight} from "react-icons/fa";
import Modal from "react-awesome-modal";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import UpdateCurrencyModal from "./UpdateCurrencyModal";
import AddCurrencyModal from "./AddCurrencyModal";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import { getCurrienciesConversion } from "../../../../../redux/currency/currency";
import { formatNumberWithComma } from "../../../../../helper/Helpers";
import DeleteCurrencyModal from "./DeleteCurrencyModal";

const Currency = () => {
  // const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const { loading, currencyConversion } = useSelector(
    (state) => state.currency
  );
  const [refreshTable, setRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState({
    update: false,
    add: false,
    delete: false,
  });
  const dispatch = useDispatch();
  const [indexNo, setIndexNo] = useState("");
  const tableHeadList = ["Symbol", "Currency", "Rate"];

  const handleFinish = () => {
    actionCtx?.setIsModalOut(false);
    setRefreshTable(!refreshTable);
    setShowModal({
      update: false,
      add: false,
      delete: false,
    });
  };

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
    };
    dispatch(getCurrienciesConversion(obj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  const editSingleChild = (param, val) => {
    if (param === "currency") {
      setSingleChild((prev) => {
        return { ...prev, currency_id: val };
      });
    }
    if (param === "rate") {
      setSingleChild((prev) => {
        return { ...prev, rate: val };
      });
    }
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 2 of 6`}
        pageTitle={`Admin Settings`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              // history.push("/dashboard-administration-admin-settings")
              console.log(currencyConversion)
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Currency</p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>

        {/* table box start */}
        <ActualReuseableTable
          tableTitle={currencyConversion?.length > 1 ? "Currencies" : `Currency`}
          tableTitleSpan={`${currencyConversion?.length}`}
          addBtntext={`New Currency`}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          action
          onCreateNew={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, add: true };
            });
          }}
          toPage={currencyConversion?.length}
          totalPage={currencyConversion?.length}
          loading={loading && currencyConversion?.length < 1}
          loadingNum={6}
        >
          {currencyConversion?.map((chi, idx) => {
            const { currency, rate } = chi;
            return (
              <TableBodyRow
                loading={loading}
                key={idx}
                one={currency?.symbol}
                two={currency?.name}
                three={formatNumberWithComma(String(rate))}
                action
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                updateText={` Update Currency`}
                deleteText="Delete Currency"
                onDelete={() => {
                  setSingleChild(chi);
                  setShowModal((prev) => {
                    return { ...prev, delete: true };
                  });
                }}
                onUpdate={() => {
                  actionCtx?.setIsModalOut(true);
                  const obj = {
                    ...chi,
                    currency_id: {
                      label: chi?.currency?.name,
                      value: chi?.currency?.id,
                    },
                  };
                  // console.log(obj);
                  setSingleChild(obj);
                  setShowModal((prev) => {
                    return { ...prev, update: true };
                  });
                }}
                
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
      </div>
      {/* </DashboardLayout> */}

      {/* modal start  */}
      <Modal visible={showModal.update} effect="fadeInUp">
        <UpdateCurrencyModal
          detail={singleChild}
          onFinish={handleFinish}
          setDetail={editSingleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, update: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start  */}
      <Modal visible={showModal.add} effect="fadeInUp">
        <AddCurrencyModal
          onFinish={handleFinish}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, add: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start  */}
      <Modal visible={showModal.delete} effect="fadeInUp">
        <DeleteCurrencyModal
          onFinish={handleFinish}
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default Currency;
