import ExpenseApproved from "../../../component/pages/dashboard/actuals/expense/ExpenseApproved";
import ExpenseRejected from "../../../component/pages/dashboard/actuals/expense/ExpenseRejected";
import ExpenxeAwaitApproval from "../../../component/pages/dashboard/actuals/expense/ExpenxeAwaitApproval";
import ExpenseActualIndex from "../../../component/pages/dashboard/actuals/expense";
// import ExpenseActualMain from "../../../component/pages/dashboard/actuals/expense/ExpenseActualMain";
import ExpenseActuallMain from "../../../component/pages/dashboard/actuals/expense/ExpenseActuallMain";

export const Expense_Actual_route_group = [
  {
    path: "/dashboard-actual-expense",
    component: ExpenseActualIndex,
    pageTitle: "Actual Expense",
    goBack: true,
  },
  {
    path: "/dashboard-actual-expense-approved",
    component: ExpenseApproved,
    pageTitle: "Actual Expense",
    goBack: true,
  },
  {
    path: "/dashboard-actual-expense-awaiting-approval",
    component: ExpenxeAwaitApproval,
    pageTitle: "Actual Expense",
    goBack: true,
  },
  {
    path: "/dashboard-actual-expense-rejected",
    component: ExpenseRejected,
    pageTitle: "Actual Expense",
    goBack: true,
  },
  {
    path: "/dashboard-actual-expense-main",
    component: ExpenseActuallMain,
    pageTitle: "Actual Expense",
    goBack: true,
  },
];
