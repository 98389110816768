import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersByFilter,
  getUsersPagination,
  getUsersWithPagination,
  // geUsersPagination,
} from "../../../../../redux/users/users";
import useDebounce from "../../../../../helper/UseDebounce";
import Modal from "react-awesome-modal";
import DeleteEmployeeModal from "./DeleteEmployeeModal";

const Employee = () => {
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { loading, users, meta, links, allUssers } = useSelector(
    (state) => state.users
  );
  const actionCtx = useContext(ActionContext);
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [indexNo, setIndexNo] = useState("");
  const tableHeadList = [
    "Full Name",
    "Email",
    "Department",
    "Subsidiary",
    "Status",
    "Role",
    "Action",
  ];

  const [perPageval, ] = useState({
    label: "5",
    value: "5",
  });
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "Name", value: "name" },
    { label: "Email", value: "email" },
    { label: "Department", value: "department" },
    { label: "Subsidiary", value: "subsidiary" },
    { label: "Status", value: "status" },
    { label: "Role", value: "role" },
  ];

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    const splitLinktwo = linkTwo.split("?");
    const newLink = `${splitLinktwo[0]}?paginated&${splitLinktwo[1]}`;
    dispatch(getUsersPagination(newLink));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    user?.organizations?.length > 0 && dispatch(getUsersWithPagination(obj));
  };

  const handleFinish = () => {
    setSingleChild({});
    setRefreshTable(!refreshTable);
    setShowDeleteModal(false);
  };

  const handleView = (id) => {
    localStorage.setItem("user-id", id);
    history.push("/dashboard-administration-admin-settings-view-employee");
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: filterByVal?.value || "",
        org_id: actionCtx?.activeOrg?.id,
      };
      dispatch(getUsersByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      // dispatch(getBranches(actionCtx?.activeOrg?.id));
      dispatch(getUsersWithPagination(obj));
    }
    // eslint-disable-next-line
  }, [refreshTable]);

  const handleEditUser = (id) => {
    localStorage.setItem("user-id", id);
    history.push("/dashboard-administration-admin-settings-edit-employee");
  };

  const getDepartMentNames = (list) => {
    const listval = list.map((chi, idx) =>
      idx === 0 ? chi?.name : `, ${chi?.name}`
    );
    const stringVal = listval.toString();
    // console.log(stringVal);
    return stringVal;
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitleSpan={`Step 5 of 6`}
        pageTitle={`Add Employee`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              {console.log(user);}
            }
            className="active text"
          >
            Users
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>

        {/* table box start */}
        <ActualReuseableTable
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          onAddBtnTwo={() => history.push("/dashboard-administration-admin-settings-admin-invite")}
          showBtnTwo={true}
          btnTwoText={`Invite user via email`}   
          perPageValue={perPageval}
          setPerPageValue={(e) => {
            // setPerpageVal(e);
            handleSortPerPage(e.value);
          }}
          fromPage={meta?.from || 1}
          toPage={meta?.to || users?.length}
          totalPage={meta?.total || users?.length}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          tableTitle={`Users`}
          tableTitleSpan={`(2)`}
          addBtntext={`New User`}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}   
          five={tableHeadList[4]}
          six={tableHeadList[5]}
          action
          onCreateNew={() =>
            history.push(
              "/dashboard-administration-admin-settings-add-employee"
            )
          }
          loading={loading && allUssers?.length < 1}
          loadingNum={8}
        >
          {allUssers?.map((chi, idx) => {
            // console.log(chi);
            const {
              full_name,
              email,
              department,
              subsidiary,
              is_active,
              role,
            } = chi;
            return (
              <TableBodyRow
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                key={idx}
                one={full_name}
                two={email}
                removeCapitalize
                three={
                  department?.length > 0 ? (
                    getDepartMentNames(department)
                  ) : (
                    <>&nbsp;</>
                  )
                }
                four={subsidiary?.name || <>&nbsp;</>}
                status={is_active ? "Active" : "inactive"}
                eight={role || <>&nbsp;</>}  
                action
                loading={loading}
                viewText={`view user`}
                updateText={role !== "creator administrator" ? `edit user` : user?.role === "creator administrator" ? "edit user" : ""}
                deleteText={role !== "creator administrator" && `delete user`}
                onDelete={() => {
                  setShowDeleteModal(true);
                  setSingleChild(chi);
                }}
                onUpdate={() => handleEditUser(chi?.id)}
                onView={() => handleView(chi?.id)}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
        {/* btn box start */}
        {actionCtx?.activeOrg?.check_list?.percentage !== 100 && (
          <div className="btn-box-wrap">
            <button
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-department"
                )
              }
              className="btn-reuse"   
            >
              Go to previous step
            </button>
          </div>
        )}
        {/* btn box end */}
      </div>
      {/* </DashboardLayout> */}
      {/* modal start  */}
      <Modal visible={showDeleteModal} effect="fadeInUp">
        <DeleteEmployeeModal
          details={singleChild}
          onFinish={handleFinish}
          onClose={() => setShowDeleteModal(false)}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default Employee;
