import React, { useContext, useEffect, useState } from "react";
// import "../../styles/pricing/PricingContent.css";
import "../../../../styles/dashboard/subscription/pricingContent.css";
import Select from "react-select";
import Aos from "aos";
import "aos/dist/aos.css";
// import ReuseButton from "../../fragments/ReuseButton";
// import { formatNumWithCommaNairaSymbol } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
// import { getSubscriptions } from "../../redux/subsciption/subscription";
// import { useLocation, useHistory } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import ButtonBlue from "../../../../../component/buttons/ButtonBlue";
import { formatNumWithCommaNairaSymbol } from "../../../../../helper/Helpers";
import {
  getCurrenciesTwo,
  // getAllSubscriptionFeatures,
  // getCurrentPlan,
  getSubscription,
  getSubscriptionCallback,
  getSubscriptions,
} from "../../../../../redux/subscription/subscription";
import Modal from "react-awesome-modal";
import SuccessModal from "./modals/successModal";
import PaymentGateway from "./paymentGateway";
import UpgradePlan from "./modals/upgrade";
import ActionContext from "../../../../../context/ActionContext";
import { BiCheck } from "react-icons/bi";
import PaystackPop from "@paystack/inline-js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PricingContents = ({ children }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [paystackModal, setPayStackModal] = useState(false);
  const [options, setOptions] = useState({});
  const [singleList, setSingleList] = useState([]);
  const actionCtx = useContext(ActionContext);
  const [selectPlan, setSelectPlan] = useState({});
  const { user } = useSelector((state) => state.user);
  const [url, setUrl] = useState({});
  const { plan_details } = useSelector((state) => state.users);
  const [currencyObj, setCurrencyObj] = useState({ rate: 1 });

  const [subData, setSubData] = useState([]);
  const { subscriptions, loading, currencies } = useSelector(
    (state) => state.subscription
  );

  const handleGetSingleSub = async (id) => {
    const data = await dispatch(getSubscription(id));
    if (data?.payload?.data?.success) {
      const singleItem = data?.payload?.data?.data;
      setSingleList(singleItem);
      setSelectPlan({
        ...singleItem,
        label: singleItem?.title,
        value: singleItem?.id,
      });
    }
  };

  // const [singleList, setSingleList] = useState({});
  const handleGetSubscription = async () => {
    // await dispatch(getCurrentPlan(actionCtx?.activeOrg?.id));

    const data = await dispatch(getSubscriptions());
    setLoader(true);
    if (data?.payload?.data?.success) {
      setLoader(false);
      const subList = data?.payload?.data?.data;
      const subItem = subList?.map((chi, idx) => {
        return {
          ...chi,
          ...planList[idx],
        };
      });
      setSubData(subItem);
    } else {
      setLoader(false);
    }
  };

  const handleSubDataOption = () => {
    const data = subData?.filter((chi) => {
      return (
        chi.title !==
        plan_details?.active_plan_details?.subscription_plan_details?.title
      );
    });

    const newData = data?.map((chi) => {
      return {
        ...chi,
        label: chi.title,
        value: chi.id,
      };
    });

    setOptions(newData);
  };
  useEffect(() => {
    setCurrencyObj(currencies[0]);
    // console.log(currencies);
  }, [currencies]);

  useEffect(() => {
    handleGetSubscription();

    dispatch(getCurrenciesTwo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const planList = [
    {
      color: "var(--blue-color)",
      plan_name: "SME",
      value: "200000",
      details: ["1 - 5 Users", "No Subsidiaries", "No Branches"],
      // anime_delay: "0",
    },
    {
      color: "rgba(250, 21, 159, 1)",
      plan_name: "Medium",
      value: "2000000",
      details: ["5 - 20 Users", "3 Subsidiaries", " 6 Branches"],
      // anime_delay: "500",
    },
    {
      color: "rgb(15, 128, 40)",
      plan_name: "Large",
      value: "4000000",
      details: [
        "21 - 50 Users",
        "6 Subsidiaries",
        "12 Branches",
        "6 Organizations",
      ],
      // anime_delay: "1000",
    },
    {
      color: "rgba(240, 18, 218, 1)",
      plan_name: "Enterprise",
      value: "",
      details: [
        "Above 50 Users",
        "Unlimited Subsidiaries",
        "Unlimited Branches",
      ],
      // anime_delay: "1500",
    },
  ];

  const formatList = (list) => {
    if (list.length > 0) {
      const newList = list?.map((chi, idx) => {
        return {
          ...chi,
          ...planList[idx],
        };
      });
      return newList;
    }
  };

  const history = useHistory();

  const reactSelectStyle = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused
        ? "0.1rem solid var(--blue-color)"
        : "0.1rem solid #DDE2E4",
      // backgroundColor: state.isSelected ? "#DDE2E4" : "white",
      boxShadow: state.isFocused ? "0.1rem solid var(--blue-color)" : 0,
      "&:hover": {
        // border: state.isFocused ? 0 : 0
      },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "var(--blue-color)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--blue-color)" : "white",
    }),
  };

  const [checkVal, setCheckVal] = useState(false);
  // const [activeSelect, setActiveSelect] = useState(false);

  // const [subReference, setSubReference] = useState("");

  const handlePaystackPayment = () => {
    const publicKey = "pk_test_8628c9c209755a8bd62a303b711e4a9c7168cf12";

    const paystack = new PaystackPop();

    paystack.newTransaction({
      key: publicKey,
      amount: url?.amount,
      email: url?.email,
      ref: url?.reference,

      onClose: () => {
        setShowModal(true);
      },

      onSuccess: async () => {
        const obj = {
          reference: url?.reference,
        };
        const data = await dispatch(getSubscriptionCallback(obj));
        if (data?.payload?.data?.success) {
          history.push("/dashboard-administration-admin-settings-billing");
          // You can optionally display a success message to the user here
        }
      },
    });
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 0,
    });
  }, []);

  // console.log(url, "ur");

  return (
    <div className="pricing-content-section-wrap">
      {children}
      <div className="size-wrap">
        {/* selct start */}
        {/* <div className="react-select-box">
          <Select
            styles={reactSelectStyle}
            className="select"
            placeholder="Currency"
            options={selectListOne}
          />
        </div> */}
        {/* selct end */}
        {/* select filter by year or month start */}
        <div className="filter-yearly-monthly-box">
          <p
            onClick={() => {
              setCheckVal(false);
            }}
            className="text"
          >
            Monthly
          </p>
          <input
            checked={checkVal}
            type="checkbox"
            name=""
            id="price-toggle"
            className="check-input"
            onChange={() => {
              setCheckVal(!checkVal);
            }}
          />
          <label htmlFor="price-toggle" className="check-label">
            <span className="first-child"></span>
            <span className="second-child"></span>
          </label>
          <p
            onClick={() => {
              setCheckVal(true);
            }}
            className="text"
          >
            Yearly
          </p>

          <div className="react-select-box">
            <Select
              styles={reactSelectStyle}
              className="select"
              placeholder="Currency"
              options={currencies}
              value={currencyObj}
              onChange={(e) => {
                setCurrencyObj(e);
              }}
            />
          </div>
        </div>
        {/* select filter by year or month end */}
        {/* card plan select wrap start */}
        {loading && subscriptions?.length < 1 ? (
          <div className="oval-loader">
            <Oval
              height={80}
              width={80}
              color="var(--blue-color)"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="var(--blue-color)"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <div className="card-plan-select-wrap">
            {formatList(subscriptions)?.map((chi, idx) => {
              const {
                color,
                // details,
                // plan_name,
                // value,
                // anime_delay,
                title,
                monthly_price,
                yearly_price,
                limited_to,
                id,
              } = chi;
              return (
                <div
                  // data-aos="flip-left"
                  // data-aos-delay={anime_delay}
                  // data-aos-easing="ease-out-cubic"
                  // data-aos-offset={`250`}
                  key={idx}
                  className="card-plan"
                >
                  <div
                    style={{
                      backgroundColor: color,
                    }}
                    className="top-tab"
                  ></div>
                  <p className="plan-title">{title}</p>
                  <p className="value">
                    {`${
                      !checkVal
                        ? formatNumWithCommaNairaSymbol(
                            Number(monthly_price) / Number(currencyObj?.rate),
                            currencyObj?.name
                          )
                        : formatNumWithCommaNairaSymbol(
                            yearly_price / Number(currencyObj?.rate),
                            currencyObj?.name
                          )
                    } / ${!checkVal ? "Monthly" : "Yearly"}` || <>&nbsp;</>}
                  </p>
                  <div className="list-box">
                    {limited_to?.map((chi, idx) => {
                      return (
                        <div key={idx} className="list-item">
                          <div className="list-item-box">
                            <BiCheck className="check-icon" />
                            <p>{chi}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <ButtonBlue
                    btnClassName={`btn-try`}
                    bgColor={
                      plan_details?.active_plan_details
                        ?.subscription_plan_details?.title === title
                        ? "#ccc"
                        : color
                    }
                    text={
                      plan_details?.active_plan_details?.status === "active" &&
                      plan_details?.active_plan_details
                        ?.subscription_plan_details?.title === title
                        ? "Active Subscription"
                        : plan_details?.active_plan_details?.status ===
                            "inactive" &&
                          plan_details?.active_plan_details
                            ?.subscription_plan_details?.title !== title
                        ? "Subscribe Now"
                        : "Change Plan"
                    }
                    style={{
                      cursor:
                        plan_details?.active_plan_details
                          ?.subscription_plan_details?.title !== title
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      actionCtx.setIsModalOut(true);
                      plan_details?.active_plan_details
                        ?.subscription_plan_details?.title === title
                        ? setShowModal(false)
                        : setShowModal(true);
                      plan_details?.active_plan_details
                        ?.subscription_plan_details?.title !== title &&
                        handleGetSingleSub(id);
                      plan_details?.active_plan_details
                        ?.subscription_plan_details?.title !== title &&
                        handleSubDataOption();
                    }}
                  />
                  {/* 
                    <p style={{ color: color }} className="sub-text">
                      {currentPlan?.subscription_plan_details?.title === title
                        ? " Upgrade/Change Plan"
                        : "Try Now"}
                    </p> */}
                </div>
              );
            })}
          </div>
        )}
        {/* card plan select wrap end */}
      </div>
      {/* modal start */}
      <div className="upgrade-plan-modal-bg">
        <Modal visible={showModal} effect="fadeInLeft">
          <UpgradePlan
            planOption={options}
            selectActive={checkVal}
            setSelectActive={setCheckVal}
            detail={singleList}
            onCancel={() => {
              setShowModal(false);
              actionCtx.setIsModalOut(false);
            }}
            planValue={selectPlan}
            onPlanChange={(e) => {
              setSelectPlan(e);
            }}
            setSuccessModal={setSuccessModal}
            setShowModal={setShowModal}
            setUrl={setUrl}
            currencyObj={currencyObj}
          />
        </Modal>
      </div>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={successModal} effect="fadeInLeft">
        <SuccessModal
          onCancel={() => {
            setSuccessModal(false);
            actionCtx.setIsModalOut(true);
            setShowModal(true);
          }}
          onClick={() => {
            handlePaystackPayment();
            actionCtx.setIsModalOut(true);
            if (url) {
              setSuccessModal(false);
              actionCtx.setIsModalOut(true);
              // setShowModal(true);
            }
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={paystackModal} effect="fadeInLeft">
        <PaymentGateway
          url={url}
          onCancel={() => {
            setPayStackModal(false);
            setShowModal(true);
          }}
        />
      </Modal>
      {/* modal end */}
    </div>
  );
};

export default PricingContents;
