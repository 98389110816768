import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import "../../../../../../../employee-folder/styles/dashboard/reports/createReport.scss";
import { useHistory } from "react-router-dom";
// import deleteIcon from "../../../../../../../../src/assets/delete-icon.svg";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailableColumns,
  getMainModule,
  getRelatedModule,
} from "../../../../../../../redux/employee/report/custom-report";

const EmployeeCreateReportFormIndex = () => {
  const [details, setDetails] = useState({
    organization_id: "",
    department_id: "",
    name: "",
    description: "",
    main_module: "",
    related_module: "",
    selected_columns: [],
    criteria: "",
  });
  // const [selectedList, setSelectedList] = useState([]);
  // const [showDelete, setShowDelete] = useState(false);
  // const [showColumns, setShowColums] = useState(false);
  // const { mainModuleDrop } = useSelector((state) => state.custom_report);
  // const { relatedModuleDrop } = useSelector((state) => state.custom_report);
  const { availableColumnDrop } = useSelector((state) => state.custom_report);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  // const handle

  // const handleProjectionSubmit = (e) => {
  //   e.preventDefault();
  //   const data = { details };
  //   if (details) {
  //     setSelectedList((prev) => [...prev, data]);
  //     setShowColums(true);
  //     setShowDelete(true);
  //     // setDetails("");
  //   }
  // };

  // const handleRemoveList = (idx) => {
  //   const data = [...details];
  //   // data.splice(idx, 1);
  //   setSelectedList(data.splice(idx, -1));
  //   setShowDelete(false);
  //   setShowColums(false);
  // };
  const dispatch = useDispatch();

  // console.log(availableColumnDrop, "main one")
  const mainModuleDropdown = [
    {
      value: "REVENUE",
      label: "Revenue Project",
    },
    {
      value: "EXPENSEPROJECTION",
      label: "Expenditure Projection",
    },
    {
      value: "ACTUALEXPENSE",
      label: "Actual Expense",
    },
    {
      value: "ACTUALREVENUE",
      label: "Revenue Actual",
    },
    {
      value: "SUPPLEMENTARY",
      label: "Supplementary Budget",
    },
  ];

  useEffect(() => {
    // const getMainModule = mainModuleDropdown.map((chi) => {
    //   return {
    //     // ...chi,
    //     value: chi.value,
    //     label: chi.label,
    //   };
    // });
    // console.log("get-main", getMainModule)
    dispatch(getMainModule());
    // console.log(mainModuleDrop?.ACTUALEXPENSE);
    dispatch(getRelatedModule("REVENUE"));
    const obj = {
      main_module: "ACTUALEXPENSE",
      related_module: "ACTUALREVENUE",
    };
    dispatch(getAvailableColumns(obj));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const history = useHistory();




  // const drop = [
  //   {
  //     value: mainModuleDrop?.ACTUALEXPENSE,
  //     label: "Actual Expense",
  //   },
  //   {
  //     value: mainModuleDrop?.ACTUALREVENUE,
  //     label: "Revenue Actual",
  //   },
  //   {
  //     value: mainModuleDrop?.EXPENSEPROJECTION,
  //     label: "Expenditure Projection",
  //   },
  //   {
  //     value: mainModuleDrop?.REVENUE,
  //     label: "Revenue Projection",
  //   },
  //   {
  //     value: mainModuleDrop?.SUPPLEMENTARTY,
  //     label: "Supplementary Budget",
  //   },
  // ];

  // console.log(mainModuleDrop, "<--main--->")

  return (
    <>
      {/* <EmployeeDashboardLayout pageTitle={`Reports`} goBack organization> */}
      <div className="admin-org-settings-wrap">
        {/* title container start */}
        <div className="title-btn-box subtitle-box">
          <p className="text">Reports</p>
          <FaAngleRight className="icon" />
          <p
            className="text"
            onClick={() =>
              history.push("/employee-dashboard-general-report-custom")
            }
          >
            custom Reports
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Create Report</p>
        </div>
        {/* title container end */}

        {/* form title start */}
        <div className="sub-right-box">
          <p className="title">Create Report</p>
        </div>
        {/* form title end */}

        {/* form start */}
        <div className="form-box" style={{ width: "80%" }}>
          <form onSubmit={handleSubmit} action="" className="form">
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1. Report Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="name" className="label">
                    Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Report Name"
                    className={`input ${details?.name && "input-active"}`}
                    value={details?.name}
                    id="name"
                    name="name"
                    onChange={handleChange}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label
                    htmlFor="description"
                    className="label"
                    // style={{ marginTop: "4px" }}
                  >
                    Description <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Description"
                    className={`input ${
                      details?.description && "input-active"
                    }`}
                    value={details?.description}
                    id="description"
                    name="description"
                    onChange={handleChange}
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            <div className="title-btn-box" />
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">2. Module Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="main_module" className="label">
                    Main Module <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.main_module && "select-wrap-active"
                    }`}
                    placeholder="Select main module"
                    value={details?.main_module}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, main_module: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={mainModuleDropdown}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label
                    htmlFor="related_module"
                    className="label"
                    // style={{ marginTop: "4px" }}
                  >
                    Related Module <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.related_module && "select-wrap-active"
                    }`}
                    placeholder="Select related module"
                    value={details?.related_module}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, related_module: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    // options={chatAccountDrop}
                  />
                </div>
                {/* group box end */}
              </div>
              {/* group box start */}
              <div className="title-btn-box" />
              <p
                className="box-title wrapper "
                style={{ borderBottom: "unset", paddingBottom: "unset" }}
              >
                3. Report Representation
              </p>
              <br />

              <div className="emp-columns-wrap">
                {/* first column start */}
                <div>
                  <p className="box-title">Available Columns</p>
                  {/* <p className="sub-title">Projections</p> */}
                  <div className="emp-projection-wra">
                    {/* <p className="list-title">Projections</p> */}
                    <div>
                      {/* <select
                        // multiple
                        className="input"
                        value={details}
                        onChange={handleProjectionChange}
                        // options={projectionList}
                        // placeholder={header[0]}
                      >
                        <option value="" disabled selected hidden>
                          Projections
                        </option>
                        <option>Projected Revenue</option>
                        <option>User</option>
                        <option>Department</option>
                        <option>Subsidiary</option>
                        <option>Expense</option>
                        <option>Budget</option>
                      </select> */}
                      <Select
                        isMulti
                        className={`select-wrap ${
                          details?.available_columns && "select-wrap-active"
                        }`}
                        placeholder="Select related module"
                        value={details?.available_columns}
                        onChange={(e) => {
                          setDetails((prev) => {
                            return { ...prev, selected_columns: e };
                          });
                          console.log(e);
                        }}
                        styles={reactSelectStyle}
                        options={availableColumnDrop}
                      />
                      {/* <Select /> */}
                    </div>
                  </div>
                  {/* <div className="add-btn">
                      <ButtonBlue text={`Add`} />
                    </div> */}
                </div>
                {/* first column end */}
                {/* second column start (btn) */}
                {/* second column end (btn) */}
                {/* third column start */}
                {/* <div className="emp-selected-column-wrap">
                  {showColumns && <p className="box-title">Selected Columns</p>}

                 
                </div> */}
                {/* third column end */}
                {/* fourth column start(delete) */}

                {/* {showDelete && (
                  <figure  className="emp-delete-btn">
                    <img src={deleteIcon} alt="" />
                  </figure>
                )} */}

                {/* fourth column end (delete) */}
              </div>
              {/* group box end */}
            </div>
            <div className="title-btn-box" />
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">4. Filters</p>
              <div className="form-wrap">
                {/* group-box start */}
                <div className="form-group">
                  <label htmlFor="criteria" className="label">
                    Criteria
                  </label>
                  <input
                    type="text"
                    placeholder="Projection created time"
                    className={`input ${details?.criteria && `input-active`}`}
                    id="criteria"
                    name="criteria"
                    onChange={handleChange}
                    value={details?.criteria}
                  />
                </div>
                {/* group-box end */}
                {/* group-box start */}
                <div className="form-group">
                  <label
                    htmlFor="criteria"
                    className="label"
                    style={{ visibility: "hidden" }}
                  >
                    Criteria
                  </label>
                  {/* <input
                    // disabled 
                    type="text"
                    placeholder="Period"
                    className={`input ${details?.period && `input-active`}`}
                    id="period"
                    name="period"
                    onChange={handleChange}
                    value={details?.period}
                  /> */}
                  <Select
                    className={`select-wrap ${
                      details?.period && "select-wrap-active"
                    }`}
                    placeholder="Period"
                    value={details?.period}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, period: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    // options={optionList}
                  />
                </div>
                {/* group-box end */}
              </div>
              <div className="title-btn-box" />
              <ButtonBlue text={`Save & Run Report`} />
            </div>
          </form>
        </div>

        {/* form end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default EmployeeCreateReportFormIndex;
