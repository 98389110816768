import React from "react";
// import EmployeeReinburstmentChart from "../chart/reinburstment";
// import Select from "react-select";
import "../../../../../../employee-folder/styles/dashboard/home/chart/monthChart.scss";
import EmployeeMonthlyExpenseChart from "./monthlyChart";

const EmployeeHomeChartThree = ({ dataValue, loading }) => {
  return (
    <div className="emp-reinburstment-wrap">
      {/* reibursement box start  */}
      <div>
        <div>
          <div className="emp-top-chart">
            <p className="emp-month-chart-head">Expense Item</p>
            {/* <Select placeholder="Monthly" options={optionList} /> */}
          </div>
          <EmployeeMonthlyExpenseChart
            loading={loading}
            dataValue={dataValue}
          />
        </div>
      </div>
      {/* reibursement box end  */}
    </div>
  );
};

export default EmployeeHomeChartThree;
