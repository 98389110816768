import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../../helper/Helpers";
// import { addLabelValueFunc, formatDate } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getAdminActualExpenseReport = createAsyncThunk(
  "admin/get-actual-expense-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/report/details?report_type=${
          formData?.report_type || ""
        }&organization_id=${formData?.org_id || ""}&currency_conversion_id=${
          formData?.cur_id || ""
        }&status=${formData?.status || ""}&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setAdminActualExpenseReports(data?.data?.actual_expense)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAdminActualExpenseReportPagination = createAsyncThunk(
  "admin/get-actual-expense-report-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/report/details?report_type=${
          formData?.report_type || ""
        }&organization_id=${formData?.org_id || ""}&page=${
          formData?.page
        }&currency_conversion_id=${formData?.cur_id || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      // /api/employee/reports/consolidated-report?page=2
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setAdminActualExpenseReports(data?.data?.actual_expense)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Bad Network, check your network settings", {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const admin_actual_expense_report = createSlice({
  name: "admin_actual_expense_report",
  initialState: {
    adminActualExpenseReports: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
  },

  reducers: {
    setAdminActualExpenseReports: (state, action) => {
      state.isAuth = true;
      state.adminActualExpenseReports = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getAdminActualExpenseReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminActualExpenseReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminActualExpenseReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getAdminActualExpenseReportPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminActualExpenseReportPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminActualExpenseReportPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAdminActualExpenseReports } =
  admin_actual_expense_report.actions;

export default admin_actual_expense_report.reducer;
