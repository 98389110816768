import React, { useContext, useEffect, useState } from "react";
// import userimg from "../../../../../../assets/hod-one-img.svg";
import Select from "react-select";
import { FaMinus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getSubsidiaries } from "../../../../../redux/subsidiary/subsidiary";
import ActionContext from "../../../../../context/ActionContext";
import { reactSelectStyle } from "../../../../../helper/Helpers";
import { NumericFormat } from "react-number-format";

const SharedRemovableGroup = ({
  num,
  handleRemoveChild,
  onSelectUser,
  onSelectPor,
  disable,
  dontShowRemoveIcon,
  selectValue,
  InputValue,
}) => {
  const [userSelect, setuserSelect] = useState(null);
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const dispatch = useDispatch();
  const [porBudget, setPorBudget] = useState("");

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
    }
    // eslint-disable-next-line
  }, []);

  //   const handleRemoveChild = (param) => {
  //     console.log(param);
  //   }
  return (
    <div
      className="remove-group"
      style={{
        width: "80%",
        gridTemplateColumns: " min-content 1fr 1fr 5rem",
      }}
    >
      <p className="step">{num + 1 || "1"}</p>
      {/* select box */}
      <div className="box">
        <label style={{ marginBottom: ".5rem" }} htmlFor="">
          Subsidiary <span>*</span>
        </label>
        <Select
          isDisabled={disable}
          className="select "
          styles={reactSelectStyle}
          value={selectValue || userSelect}
          onChange={(e) => {
            setuserSelect(e);
            onSelectUser(e);
          }}
          options={subsidiariesDrop}
        />
      </div>
      {/* select end */}
      {/* select type box start */}
      <div className="box form-group">
        <label className="label" htmlFor="">
          Apportion Budget <span>*</span>
        </label>
        <NumericFormat
          className={`input ${porBudget && "input-active"}`}
          decimalScale={3}
          decimalSeparator="."
          type="text"
          name="currency_rate"
          thousandsGroupStyle={"lakh"}
          allowNegative
          onChange={(e) => {
            onSelectPor(e.target.value);
            // console.log(e.target.value);
            setPorBudget(e.target.value);
          }}
          thousandSeparator={","}
          value={InputValue || porBudget}
          id="set_currency"
          disabled={disable}
          placeholder="50.00"
        />
      </div>
      {/* select type box end */}

      {/* cancel bos start */}
      {!disable && (
        <div className="cancel-box" onClick={handleRemoveChild}>
          <FaMinus className="icon" />
        </div>
      )}
      {/* cancel bos end */}
    </div>
  );
};

export default SharedRemovableGroup;
