import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
// import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/admin-settings/AdminSettings.css";
import {
  accountLinks,
  organizationLinks,
} from "../../../../data/AdminSettingLinks";
import ActionContext from "../../../../context/ActionContext";
import { useSelector } from "react-redux";

const AdminSettings = () => {
  const history = useHistory();
  const { plan_details } = useSelector((state) => state.users);
  const actionCtx = useContext(ActionContext);
  return (
    <>
      {/* <DashboardLayout pageTitle={`Admin Settings`}> */}
      <div className="admin-setting-wrap">
        <div className="left-box">
          <div className="content-box">
            <p className="title">Account</p>
            {accountLinks.map((chi, idx) => {
              const { name, link } = chi;
              return (
                <p
                  onClick={() => history.push(link)}
                  key={idx}
                  className="link"
                >
                  {name}
                </p>
              );
            })}
          </div>
        </div>
        <div className="right-box">
          <div className="content-box">
            <p className="title">Organization</p>
            {organizationLinks.map((chi, idx) => {
              const { name, link } = chi;
              if (
                name === "Shared Services" &&
                !plan_details?.privilages?.shared_service
              ) {
                return <React.Fragment key={idx}></React.Fragment>;
              }
              if (
                actionCtx?.activeOrg?.type === "single" &&
                name === "Subsidiaries" 
              ) {
                return <React.Fragment key={idx}></React.Fragment>;
              }
              if (
                actionCtx?.activeOrg?.type === "single" &&
                name === "Shared Services"
              ) {
                return <React.Fragment key={idx}></React.Fragment>;
              }
              if (plan_details?.privilages?.branches !== "1"  && name === "Branches") {
                return <React.Fragment key={idx}></React.Fragment>
              }
              if (!plan_details?.privilages?.subsidiaries && name === "Subsidiaries") {
                return <React.Fragment key={idx}></React.Fragment>
              }
              return (
                <p
                  onClick={() => history.push(link)}
                  key={idx}
                  className="link"
                >
                  {name}
                </p>
              );
            })}
          </div>
        </div>
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default AdminSettings;
