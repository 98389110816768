import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
import EmpTableBodyRow from "../../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeReusableTable from "../../../../../table/EmployeeReusableTable";
import EmployeeSupplementaryLayout from "../links/CommonLayout";
import Modal from "react-awesome-modal";
// import EmployeeRevenueComentModal from "../../modals/comment";
import EmployeeActualSupplementaryBudgetApproveOverview from "../modals/approveOverviewModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSupplementaryBudgetComment,
  getApprovedSupplementaryBudget,
  getSubmittedSupplementaryBudgetBySearch,
  getSubmittedSupplementaryBudgetPagination,
} from "../../../../../../../redux/employee/actual-supplementary-budget/actual-supplementary-budget";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../../helper/Helpers";
import EmployeeActualSupplementaryBudgetDraft from "../draftProjectionIndex";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
// import EmployeeActualSupplementaryBudgetApproved from "../modals/approveOverviewModal";
import EmployeeSupplementaryComentModal from "../modals/comment";
import EmployeeSupplementaryRejectModal from "../modals/reject";
import ActionContext from "../../../../../../../context/ActionContext";
import useDebounce from "../../../../../../../helper/UseDebounce";
import { getUserDepartment } from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import moment from "moment";

const EmployeeSupplementaryApprovedTable = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { loadingPage, submittedactualSupplementaryBudgets, links, meta } =
    useSelector((state) => state.actual_supplementary_budget);
  const [dept, setDept] = useState({ label: "Select Dept..." });
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [singleChild, setSingleChild] = useState({});
  const { user } = useSelector((state) => state.user);
  const [refreshTable] = useState(false);

  useEffect(() => {
    // if (user?.organizations[0]?.id) {
    //   handleGetApprovedData();
    // }
    getUserDepartment(user?.organizations[0]?.id);
    handleGetSupplemetaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, user?.organizations[0]?.id, dept]);

  // const handlePagination = async (param) => {
  //   const splitLink = param.split("/api");
  //   const linkTwo = splitLink[1];
  //   // console.log("linkTwo", linkTwo);
  //   dispatch(getSubmittedSupplementaryBudgetPagination(linkTwo));
  // };
  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      status: "approved",
      dept_id: dept?.id,
    };
    dispatch(getSubmittedSupplementaryBudgetPagination(obj));
  };

  const handleGetApprovedData = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      status: "approved",
      dept_id: dept?.id,
    };
    await dispatch(getApprovedSupplementaryBudget(obj));
  };
  const [indexNo, setIndexNo] = useState("");

  const [showModal, setShowModal] = useState({
    approve: false,
    comment: false,
    reject: false,
    approveOverview: false,
  });

  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const [sortByVal, setSortByVal] = useState({ label: "Sort by" });

  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart of Account", value: "chart_of_account_id" },
    { label: "Class", value: "class" },
  ];
  const SortByOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
  ];
  const [search, setSearch] = useState("");

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        q: search,
        status: "approved",
        filter_by: filterByVal?.value,
        sort_by: sortByVal?.value,
        org_id: user?.organizations[0]?.id,
      };
      dispatch(getSubmittedSupplementaryBudgetBySearch(obj));
    } else {
      handleGetSupplemetaryData();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const handleSortChange = (param) => {
    const obj = {
      sort_by: param?.value,
      dept_id: dept?.id,
      status: "approved",
      org_id: user?.organizations[0]?.id,
    };
    // console.log("obj", obj)
    dispatch(getSubmittedSupplementaryBudgetBySearch(obj));
  };

  const cardHeader = ["Total Supplementary", "Approved", "Pending", "Rejected"];
  // const cardValue = ["00.00", "0", "0", "0"];

  const [singleItem, setSingleItem] = useState({});

  const handleGetSupplemetaryData = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      status: "approved",
      dept_id: dept?.id,
    };
    const data = await dispatch(getApprovedSupplementaryBudget(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };

  const cardValue = [
    singleItem?.total_supplementary || 0,
    singleItem?.approved || 0,
    singleItem?.awaiting_approval || 0,
    singleItem?.rejected || 0,
  ];

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Proposed Price",
    "Approved Price",
    "Total Value",
    "Date",
    "status",
  ];

  const handleGetCommentData = (param) => {
    // setSingleChild(param);
    dispatch(getAllSupplementaryBudgetComment(param));
  };

  // const cardHeader = ["Total Supplementary", "Approved", "Pending", "Rejected"];
  // const cardValue = ["00.00", "0", "0", "0"];

  const link = [
    "/employee-dashboard-actual-supplementary-budget-form",
    "/employee-dashboard-actual-supplementary-budget-submitted",
    "/employee-dashboard-actual-supplementary-budget-approved",
    "/employee-dashboard-actual-supplementary-budget-pending",
    "/employee-dashboard-actual-supplementary-budget-rejected",
  ];

  return (
    <>
      <div>
        <EmployeeSupplementaryLayout approvedLink={true}>
          <EmployeeReusableTable
            dept
            departmentDrop={userDepartmentDrop}
            deptVal={dept}
            setDepartmentVal={(e) => {
              setDept(e);
            }}
            dontShowPagination={false}
            cardTitleOne={cardHeader[0]}
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            optionSort={SortByOptions}
            optionSortVal={sortByVal}
            setOptionSortVal={(e) => {
              setSortByVal(e);
              handleSortChange(e);
            }}
            optionFilter={filterByOptions}
            optionFilterVal={filterByVal}
            setOptionFilterVal={(e) => {
              setFilterByVal(e);
              // handleFil
            }}
            onSearchTable={(e) => {
              setSearch(e);
              // console.log("e", e)
            }}
            cardBodyOne={formatNumber(
              String(
                FormatNumWithCurrency(
                  cardValue[0],
                  actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
                  actionCtx?.currencyValue
                ) || 0
              )
            )}
            cardBodyTwo={cardValue[1]}
            cardBodyThree={cardValue[2]}
            cardBodyFour={cardValue[3]}
            showSearch={true}
            loading={loadingPage}
            sort
            filter
            BtnTitle={`New Budget`}
            linkCardTwo={link[2]}
            addButton={true}
            firstCard={true}
            secondCard={true}
            thirdCard={true}
            fourthCard={true}
            onPage={() => history.push(link[0])}
            onFirstCard={() => history.push(link[1])}
            onSecondCard={() => history.push(link[2])}
            onThirdCard={() => history.push(link[3])}
            onFourthCard={() => history.push(link[4])}
          />

          {children}
          <div className="table-box">
            {/* table box start */}
            <ActualReuseableTable
              dontShowBnSearch
              dontShowPagination={
                submittedactualSupplementaryBudgets.data?.length < 1
              }
              fromPage={meta?.from}
              toPage={meta?.to}
              nextPage={links?.next}
              prevPage={links?.prev}
              onNextPage={() => handlePagination(links?.next)}
              onPrevPage={() => handlePagination(links?.prev)}
              totalPage={meta?.total}
              one={tableHeadList[0]}
              two={tableHeadList[1]}
              three={tableHeadList[2]}
              four={tableHeadList[3]}
              five={tableHeadList[4]}
              six={tableHeadList[5]}
              seven={tableHeadList[6]}
              action
            >
              {submittedactualSupplementaryBudgets?.data?.length > 0 ? (
                <>
                  {submittedactualSupplementaryBudgets?.data?.map(
                    (chi, idx, id) => {
                      const {
                        chartofaccount,
                        // classes,
                        new_price,
                        approved_price,
                        total_supplementary,
                        date,
                        status,
                      } = chi;
                      return (
                        <EmpTableBodyRow
                          item={chi}
                          key={idx}
                          one={chartofaccount?.name}
                          two={chartofaccount?.class}
                          three={formatNumber(
                            String(
                              FormatNumWithCurrency(
                                new_price,
                                actionCtx?.activeOrg?.active_fiscal_year
                                  ?.base_currency?.name,
                                actionCtx?.currencyValue
                              ) || 0
                            )
                          )}
                          four={formatNumber(
                            String(
                              FormatNumWithCurrency(
                                approved_price,
                                actionCtx?.activeOrg?.active_fiscal_year
                                  ?.base_currency?.name,
                                actionCtx?.currencyValue
                              ) || 0
                            )
                          )}
                          five={formatNumber(
                            String(
                              FormatNumWithCurrency(
                                total_supplementary,
                                actionCtx?.activeOrg?.active_fiscal_year
                                  ?.base_currency?.name,
                                actionCtx?.currencyValue
                              ) || 0
                            )
                          )}
                          six={moment(date).format("YYYY-MM-DD")}
                          action
                          progressType={
                            status === "approved"
                              ? "accept"
                              : status === "rejected"
                              ? "reject"
                              : "pending"
                          }
                          loading={loadingPage}
                          // onProgress={() => history.push("/")}
                          // action
                          rowNo={idx}
                          indexNo={indexNo}
                          setIndexNo={() => setIndexNo(idx)}
                          // updateText={`Update`}
                          // approveText={"Approve"}
                          viewText={"View Comments"}
                          // deleteText={`Reject`}
                          rejectedText={
                            user?.permissions?.includes(
                              "approve/reject supplementary budget"
                            )
                              ? `Reject`
                              : null
                          }
                          onRejected={() => {
                            actionCtx?.setIsModalOut(true);
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, reject: true };
                            });
                          }}
                          exportText={`Export as PDF`}
                          onUpdate={() => history.push(links[0])}
                          onView={() => {
                            actionCtx?.setIsModalOut(true);
                            setSingleChild(chi);
                            handleGetCommentData(chi?.id);
                            setShowModal((prev) => {
                              return { ...prev, comment: true };
                            });
                          }}
                          onAcceptStatus={() => {
                            actionCtx?.setIsModalOut(true);
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, approveOverview: true };
                            });
                          }}
                          // onOverview={() => {
                          //   setSingleChild(chi);
                          //   setShowModal((prev) => {
                          //     return { ...prev, approveOverview: true };
                          //   });
                          // }}
                        />
                      );
                    }
                  )}
                </>
              ) : (
                <EmployeeActualSupplementaryBudgetDraft>
                  <ButtonBlue
                    onClick={() =>
                      history.push(
                        "/employee-dashboard-actual-supplementary-budget-form"
                      )
                    }
                    text={`Add custom budget`}
                  >
                    <FaPlus style={{ marginRight: "15px" }} />
                  </ButtonBlue>
                </EmployeeActualSupplementaryBudgetDraft>
              )}
            </ActualReuseableTable>
          </div>
        </EmployeeSupplementaryLayout>
      </div>
      {/* modal start */}
      <Modal visible={showModal.comment}>
        <EmployeeSupplementaryComentModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, comment: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmployeeSupplementaryRejectModal
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
          }}
          onRefreshCard={handleGetSupplemetaryData}
          onDispatch={handleGetApprovedData}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeActualSupplementaryBudgetApproveOverview
          details={singleChild}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
          rejectText={
            user?.permissions?.includes("approve/reject supplementary budget")
              ? true
              : false
          }
          onReject={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, reject: true, approveOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeSupplementaryApprovedTable;
