// import RevenueActualIndex from "../../../component/pages/dashboard/actual/revenue";
import RevenueApproved from "../../../component/pages/dashboard/actuals/revenue/RevenueApproved";
import RevenueAwaitApproval from "../../../component/pages/dashboard/actuals/revenue/RevenueAwaitApproval";
import RevenueRejected from "../../../component/pages/dashboard/actuals/revenue/RevenueRejected";
import RevenueActualIndex from "../../../component/pages/dashboard/actuals/revenue";
import RevenueActualMain from "../../../component/pages/dashboard/actuals/revenue/RevenueProjectionMain";

export const Revenue_Actual_route_group = [
  {
    path: "/dashboard-actual-revenue",
    component: RevenueActualIndex,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/dashboard-actual-revenue-approved",
    component: RevenueApproved,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/dashboard-actual-revenue-awaiting-approval",
    component: RevenueAwaitApproval,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/dashboard-actual-revenue-rejected",
    component: RevenueRejected,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
  {
    path: "/dashboard-actual-revenue-main",
    component: RevenueActualMain,
    pageTitle: "Actual Revenue",
    goBack: true,
  },
];
