import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../../helper/Helpers";
// import { addLabelValueFunc, formatDate } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getAdminProjectionActualIncomeReport = createAsyncThunk(
  "admin/get-projection-actual-income-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/report/details?report_type=${
          formData?.report_type || ""
        }&organization_id=${formData?.org_id || ""}&currency_conversion_id=${
          formData?.cur_id || ""
        }&perPage=${formData?.per_page || ""}&subsidiary_id=${
          formData?.sub_id || ""
        }`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setAdminProjectionActualIncomeReports(
            data?.data?.projection_vs_actual_revenue_and_actual_GP
          )
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAdminProjectionActualIncomeReportPagination = createAsyncThunk(
  "admin/get-projection-actual-income-report-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/report/details?report_type=${
          formData?.report_type || ""
        }&organization_id=${formData?.org_id || ""}&page=${
          formData?.page
        }&currency_conversion_id=${formData?.cur_id || ""}&status=${
          formData?.status || ""
        }`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      // /api/employee/reports/consolidated-report?page=2
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setAdminProjectionActualIncomeReports(
            data?.data?.projection_vs_actual_revenue_and_actual_GP
          )
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Bad Network, check your network settings", {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const admin_projectio_actual_income_report = createSlice({
  name: "admin_projectio_actual_income_report",
  initialState: {
    adminProjectionActualIncomeReports: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
  },

  reducers: {
    setAdminProjectionActualIncomeReports: (state, action) => {
      state.isAuth = true;
      state.adminProjectionActualIncomeReports = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getAdminProjectionActualIncomeReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminProjectionActualIncomeReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminProjectionActualIncomeReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getAdminProjectionActualIncomeReportPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getAdminProjectionActualIncomeReportPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getAdminProjectionActualIncomeReportPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAdminProjectionActualIncomeReports } =
  admin_projectio_actual_income_report.actions;

export default admin_projectio_actual_income_report.reducer;
