import React from "react";
// import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import Select from "react-select";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import "../../../../../../employee-folder/styles/dashboard/reports/customReport.scss";
// import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
// import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmpTableBodyRow from "../../../../../../employee-folder/component/layout/dashboard/EmpTableBodyRow";

const CustomReportTable = () => {
  // const [details, setDetails] = useState({ created: "" });

  const history = useHistory();



  const tableHeadList = [
    "S/N",
    "Report Name",
    "Description",
    "Created by",
    "Last Run",
  ];

  const employeeTableBodyList = [
    {
      serial_no: "01",
      report_name: "MD Report",
      description: "To compare the two",
      created_by: "Festy Dave",
      last_run: "Now",
    },
  ];

  return (
    <div className="admin-approval-settings-wrap">
      <div className="emp-report-custom-wrap">
        {/* left box start */}
        <div className="left-box">
          <p className="custom-title">Custom Reports</p>
          {/* <Select
            placeholder="Created by me"
            className="select"
            value={details.created.value}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, created: e.value };
              });
            }}
            options={optionList}
          /> */}
          <select name="" id="" className="select">
            <option value="" className="option">
              Created by me
            </option>
          </select>
        </div>
        {/* left box end */}

        {/*right box start */}
        <div className="emp-reuseable-table-search right-box">
          <div className="emp-search-box">
            <input
              type="text"
              placeholder="Search"
              id="search"
              className="input"
            />
            <label htmlFor="search" className="label">
              <FaSearch className="icon" />
            </label>
          </div>
          <div>
            <button
              onClick={() =>
                history.push("/dashboard-general-report-create")
              }
              className="emp-report-btn"
            >
              Create Report
            </button>
          </div>
        </div>
        {/*right box end */}
      </div>
      {/* table start */}
      <div style={{ marginTop: "-4rem" }}>
        <ActualReuseableTable
          dontShowBnSearch
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
        >
          {employeeTableBodyList.map((chi, idx) => {
            const {
              serial_no,
              report_name,
              description,
              created_by,
              last_run,
            } = chi;
            return (
              <EmpTableBodyRow
                key={idx}
                one={serial_no}
                twoSpan={report_name}
                three={description}
                four={created_by}
                five={last_run}
              />
            );
          })}
        </ActualReuseableTable>
      </div>

      {/* table end */}
    </div>
  );
};
export default CustomReportTable;
