import React, { useContext, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import Select from "react-select";
import ActionContext from "../../../../../../../context/ActionContext";
// import RevenueChart from "../RevenueChart";
// import userImg from "../../../../../../assets/hod-one-img.svg";
// import RevenueChartOne from "../RevenueChartOne";
// import { useHistory } from "react-router-dom";
import EmployeeNetProfitProjection from ".";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
// import EmpTableBodyRow from "../../../../../layout/dashboard/EmpTableBodyRow";
// import EmployeeMonthlyExpenseChart from "../chart/monthlyChart";
import EmployeeNetProfitProjectionGraph from "../../chart/profitProjection";
// import Modal from "react-awesome-modal";
// import EmployeeOverviewActualReinbursementModal from "../../modals/Reimbursement/EmployeeOverviewModal";
// import EmployeeRevenueApprovalModal from "../../../actual/modals/approve";
// import EmployeeRevenueComentModal from "../../../actual/modals/comment";
// import EmployeeDeleteRevenueModal from "../../../actual/modals/deleteRevenue";
// import EmployeeBigImgModal from "../../../actual/modals/bigImg";
import { useEffect } from "react";
import { getUserDepartment } from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeActualReimburseGraph,
  getEmployeeActualReimburseGraphPagination,
} from "../../../../../../../redux/employee/dashboard";
import {
  formatNumber,
  FormatNumWithCurrency,
} from "../../../../../../../helper/Helpers";
import TableBodyRow from "../../../../../../../component/tables/TableBodyRow";

const EmployeeActualReimbursementIndex = ({ children, chartBox }) => {
  const actionCtx = useContext(ActionContext);
  // const history = useHistory();
  //   const [showChart, setShowChart] = useState(true);
  // const [indexNo, setIndexNo] = useState("");
  //   const [showChartBox, setShowChartBox] = useState(true);
  // const [chartFilterVal, setChartFilterVal] = useState("");
  const { user } = useSelector((state) => state.user);
  const { loading, actualReimburseData } = useSelector(
    (state) => state.employee_dashboard
  );
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [totalReimburse, setTotalReimburse] = useState("");
  const [reimburesGraph, setReimburseGraph] = useState({});

  const department_id = localStorage.getItem("employee-department-id");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    if (user?.organizations[0]?.id || department_id) {
      handleGetActualReimburseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id, department_id]);

  const handleGetActualReimburseData = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: department_id || userDepartmentDrop[0]?.id,
      // user_id: user?.id,
      currency_conversion_id: "",
    };

    const data = await dispatch(getEmployeeActualReimburseGraph(obj));
    // console.log(data, "data ");
    if (data?.payload?.success) {
      const totalReimburseVal = data?.payload?.data?.total_actual_reimburse;
      const reimburseVal = data?.payload?.data?.reimburse_actual_graph;
      let reimburseGraphVal = [];
      // console.log(netProfitGraph, "net profit graph");
      for (const reimburseObj of reimburseVal) {
        reimburseGraphVal.push(parseInt(reimburseObj?.value));
        // console.log(netProfitObj, "net profit chart");
      }
      // for(const netProfitData p)
      setReimburseGraph(reimburseGraphVal);
      setTotalReimburse(totalReimburseVal);
    }
  };

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    // const linkTwo = splitLink[1];
    // // console.log("linkTwo", linkTwo);
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // console.log("linkTwo", linkTwo);
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      currency_conversion_id: "",
      dept_id: department_id || userDepartmentDrop[0]?.id,
      user_id: user?.id,
      page: linkTwo,
    };
    dispatch(getEmployeeActualReimburseGraphPagination(obj));
  };

  // console.log(actualReimburseData, "reimbur");

  // const [showModal, setShowModal] = useState({
  //   overview: false,
  //   approve: false,
  //   comment: false,
  //   reject: false,
  //   big_img: false,
  // });

  // const optionList = [
  //   {
  //     label: "Default",
  //     value: 1,
  //   },
  //   {
  //     label: "Current Fiscal Year",
  //     value: 2,
  //   },
  //   {
  //     label: "Previous Year",
  //     value: 3,
  //   },
  //   {
  //     label: "Today",
  //     value: 4,
  //   },
  //   {
  //     label: "Yesterday",
  //     value: 5,
  //   },
  //   {
  //     label: "Last 7 days",
  //     value: 6,
  //   },
  //   {
  //     label: "Monthly",
  //     value: 7,
  //   },
  // ];

  const tableHeadList = [
    "Chart of Account",
    "Units",
    "Unit Price",
    "Total Expense",
    // "Revenue",
    // "GP",
    // "GP%",
  ];

  // const dataLabel = [48, 76, 94, 39, 27, 79, 25, 125, 100, 80, 95, 99, 60];

  return (
    <>
      <EmployeeNetProfitProjection
        reimburseVal={formatNumber(
          String(
            FormatNumWithCurrency(
              totalReimburse,
              actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name,
              actionCtx?.currencyValue
            ) || 0
          )
        )}
      >
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={` ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          ></div>
        )}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            {/* <div className="title-select-box">
              <p className="title">Atual Reimbursement Graph</p>
              <Select
                // isSearchable={true}
                // isMulti
                placeholder="Monthly"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={optionList}
              />
            </div> */}
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              {/* <EmployeeMonthlyExpenseChart /> */}
              <EmployeeNetProfitProjectionGraph
                loading={loading}
                dataValue={reimburesGraph}
              />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            dontShowPagination={actualReimburseData?.data?.length < 1}
            fromPage={actualReimburseData?.from}
            toPage={actualReimburseData?.to}
            nextPage={actualReimburseData?.next_page_url}
            prevPage={actualReimburseData?.prev_page_url}
            onNextPage={() => {
              handlePagination(actualReimburseData?.next_page_url);
            }}
            onPrevPage={() =>
              handlePagination(actualReimburseData?.prev_page_url)
            }
            totalPage={actualReimburseData?.total}
            // action
            // onNextPage
          >
            {actualReimburseData?.data?.map((chi, idx) => {
              const {
                chart_of_account,
                units,
                unit_price,
                total_expense,
                // status,
              } = chi;
              return (
                <TableBodyRow
                  key={idx}
                  one={chart_of_account?.name}
                  loading={loading}
                  two={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        units,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  three={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        unit_price,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  four={formatNumber(
                    String(
                      FormatNumWithCurrency(
                        total_expense,
                        actionCtx?.activeOrg?.active_fiscal_year?.base_currency
                          ?.name,
                        actionCtx?.currencyValue
                      ) || 0
                    )
                  )}
                  //   progressType={status}
                  // action
                  // rowNo={idx}
                  // indexNo={indexNo}
                  // setIndexNo={() => setIndexNo(idx)}
                  // updateText={` Update Branch`}
                  // onOverview={() =>
                  //   setShowModal((prev) => {
                  //     return { ...prev, overview: true };
                  //   })
                  // }
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </EmployeeNetProfitProjection>
      <>
        {/* modal start */}
        {/* <Modal
          effect="fadeInDown"
          visible={showModal.overview}
          style={{ position: "relative", right: "20rem" }}
        > */}
        {/* <EmployeeOverviewActualReinbursementModal
            chart_of_account={"Food and Supply"}
            classes={`Liability`}
            unitsTwo
            unit_price
            date_text={`Expense Date`}
            bigImg
            exportPdf
            onCancel={() =>
              setShowModal((prev) => {
                return { ...prev, overview: false };
              })
            }
            onApprove={() =>
              setShowModal((prev) => {
                return { ...prev, approve: true, overview: false };
              })
            }
            // onReject={() =>
            //   setShowModal((prev) => {
            //     return { ...prev, reject: true, overview: false };
            //   })
            // }
            onComment={() =>
              setShowModal((prev) => {
                return { ...prev, comment: true, overview: false };
              })
            }
            onViewBigImg={() => {
              setShowModal((prev) => {
                return { ...prev, overview: false, big_img: true };
              });
            }}
          />
        </Modal> */}
        {/* modal end */}
        {/* modal start */}
        {/* <Modal effect="fadeInLeft" visible={showModal.approve}>
          <EmployeeRevenueApprovalModal
            onCancel={() =>
              setShowModal((prev) => {
                return { ...prev, approve: false, overview: true };
              })
            }
          />
        </Modal> */}
        {/* modal end */}
        {/* modal start */}
        {/* <Modal effect="fadeInUp" visible={showModal.comment}>
          <EmployeeRevenueComentModal
            onCancel={() =>
              setShowModal((prev) => {
                return { ...prev, comment: false, overview: true };
              })
            }
          />
        </Modal> */}
        {/* modal end */}
        {/* modal start */}
        {/* <Modal effect="fadeInRight" visible={showModal.reject}>
          <EmployeeDeleteRevenueModal
            onClose={() =>
              setShowModal((prev) => {
                return { ...prev, reject: false, overview: true };
              })
            }
          />
        </Modal> */}
        {/* modal end */}
        {/* modal start */}
        {/* <Modal effect="fadeInRight" visible={showModal.big_img}>
          <EmployeeBigImgModal
            onCancel={() =>
              setShowModal((prev) => {
                return { ...prev, big_img: false, overview: true };
              })
            }
          />
        </Modal> */}
        {/* modal end */}
      </>
    </>
  );
};

export default EmployeeActualReimbursementIndex;
