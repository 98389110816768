import React from "react";
import CreateImg from "../../assets/create-img.png";
import "../../styles/dashboard/NoTableContent.css"

const NoTableContent = ({text, onClick}) => {
  return (
    <div className="no-table-content-wrap">
      <div className="content-box" onClick={onClick}>
        <figure className="img-box">
          <img src={CreateImg} alt="" className="img" />
        </figure>
        <p className="text">{text || "create a budget template"}</p>
      </div>
    </div>
  );
};

export default NoTableContent;
