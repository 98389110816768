import React from "react";
// import { FaAngleRight } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/admin-settings/BudgetSetting.css";

const BudgetMain = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <>
      {/* <DashboardLayout goBack pageTitle={`Budget`}> */}
        <div className="budget-main-wrap">
          <div className="side-wrap">
            <p className="title">Budget</p>
            <p
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-budget-exp-classes"
                )
              }
              className={`link ${
                location.pathname.includes(
                  "/dashboard-administration-admin-settings-budget-exp-"
                ) && "link-active"
              }`}
            >
              Expenditure Projection
            </p>
            <p
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-budget-rev-pro-vertical"
                )
              }
              className={`link ${
                location.pathname.includes(
                  "/dashboard-administration-admin-settings-budget-rev-pro"
                ) && "link-active"
              }`}
            >
              Revenue Projection
            </p>
            <div className="underline"></div>
            <p className="title">Budget Settings</p>
            <p
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-budget-expense-limit"
                )
              }
              className={`link ${
                location.pathname.includes(
                  "/dashboard-administration-admin-settings-budget-expense-limit"
                ) && "link-active"
              }`}
            >
              Expense Limit
            </p>
            {/* <p
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-budget-revenue-limit"
                )
              }
              className={`link ${
                location.pathname.includes(
                  "/dashboard-administration-admin-settings-budget-revenue-limit"
                ) && "link-active"
              }`}
            >
              Revenue Limit
            </p> */}
            <p
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-budget-budget-assumption"
                )
              }
              className={`link ${
                location.pathname.includes(
                  "/dashboard-administration-admin-settings-budget-budget-assumption"
                ) && "link-active"
              }`}
            >
              Budget Assumption
            </p>
            {/* <p
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-budget-bulk-upload-template"
                )
              }
              className={`link ${
                location.pathname.includes(
                  "/dashboard-administration-admin-settings-budget-bulk-upload-template"
                ) && "link-active"
              }`}
            >
              Bulk Upload Template
            </p> */}
          </div>
          {/* side wrap end */}
          <div className="main-wrap">{children}</div>
        </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default BudgetMain;


