import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/admin-settings/ProfileSetting.css";
import { useHistory } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationsPagination,
  getOrganizationsWithPagination,
} from "../../../../../redux/user";
import ActionContext from "../../../../../context/ActionContext";

const OrganizationMain = () => {
  const [indexNo, setIndexNo] = useState("");
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const { organizationMain, meta, links, loadingTable , user} = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const [perPageval, setPerpageVal] = useState({
    label: "5",
    value: "5",
  });

  const tableHeadList = [
    "plan",
    "company",
    "country",
    "role",
    "fiscal year",
    "Action",
  ];

  useEffect(() => {
    dispatch(getOrganizationsWithPagination(actionCtx.perPage.value));
    // eslint-disable-next-line
  }, []);

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    // console.log(linkTwo);
    dispatch(getOrganizationsPagination(linkTwo));
  };

  return (
    <>
      {/* <DashboardLayout pageTitle={`Admin Settings`} goBack> */}
      <div className="profile-setting-wrap">
        <div className="subtitle-box">
          <p
            onClick={() => {
              // history.push("/dashboard-administration-admin-settings");
              // console.log(user?.permissions);
            }}
            className="text"
          >
            Admin Settings
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Organisation</p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>
        {/* subtitle end */}
        {/* table box start */}
        <ActualReuseableTable
          perPageValue={perPageval}
          setPerPageValue={(e) => {
            setPerpageVal(e);
            dispatch(getOrganizationsWithPagination(e.value));
          }}
          onCreateNew={() =>
            history.push(
              "/dashboard-administration-admin-settings-account-organization-select"
            )
          }
          tableTitle={`Organisation`}
          tableTitleSpan={`(2)`}
          addBtntext={`New Organisation`}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five="Type"
          six={tableHeadList[4]}
          // action
          dontShowBtn={!user?.permissions.includes("create organization") ? true : false}  
          fromPage={meta?.from}
          toPage={meta?.to}
          totalPage={meta?.total}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          loading={loadingTable && organizationMain?.length < 1}
          loadingNum={7}
        >
          {organizationMain?.map((chi, idx) => {
            const { active_fiscal_year, name, country, Role, type } = chi;
            return (
              <TableBodyRow
                Freenum
                key={idx}
                one="Freemium"
                two={name}
                three={country}
                four={Role || <>&nbsp;</>}
                five={type}
                six={active_fiscal_year?.title || <>&nbsp;</>}
                // action
                // seven
                loading={loadingTable}
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default OrganizationMain;
