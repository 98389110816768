import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
// import "aos/dist/aos.css";
// import Aos from "aos";

const EmployeeSpreadForm = ({
  onInputChange,
  unit_price,
  units,
  total_value,
}) => {
  const [details] = useState({
    unit_price: "",
    units: "",
    total_value: "",
  });

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   const data = { ...details, [name]: value };
  //   setDetails(data);
  // };

  return (
    <div
      className="form-wrap"
      style={{
        gridTemplateColumns: "1.5fr 1fr 1fr",
        marginTop: "3rem",
        marginBottom: "-10rem",
      }}
    >
      <div className="form-group">
        {/* unit-price input start */}
        <label htmlFor="unit price" className="label">
          Unit Price <span>*</span>
        </label>
        <NumericFormat
          // type="text"
          // placeholder="specify unit value"
          // name="unit_price"
          // className={`input ${unit_price && "input-active"}`}
          // id="unit_price"
          // value={details.unit_price || unit_price}
          // onChange={onInputChange}
          // className="input"
          className={`input ${unit_price && "input-active"}`}
          decimalScale={3}
          decimalSeparator="."
          type="text"
          name="unit_price"
          // thousandsGroupStyle={"lakh"}
          allowNegative
          onChange={onInputChange}
          thousandSeparator={","}
          value={details.unit_price || unit_price}
          id="unit_price"
          placeholder="Specify unit value"
        />
      </div>
      {/* unit-price input start */}
      {/* unit input start */}
      <div className="form-group">
        <label htmlFor="units" className="label">
          Units <span>*</span>
        </label>
        <NumericFormat
          // type="text"
          // name="units"
          // id="units"
          // // style={{ width: "30vh" }}
          // placeholder="Specify quantity"
          // value={units}
          // className={`input ${units && "input-active"}`}
          // onChange={onInputChange}
          // style={{width:"30vh"}}
          className={`input ${units && "input-active"}`}
          decimalScale={3}
          decimalSeparator="."
          type="text"
          name="units"
          // thousandsGroupStyle={"lakh"}
          allowNegative
          onChange={onInputChange}
          thousandSeparator={","}
          value={units}
          id="units"
          placeholder="Specify quantity"
        />
      </div>
      {/* unit input end */}
      {/* total value input start */}
      <div className="form-group">
        <label htmlFor="total value" className="label">
          Total value
        </label>
        <NumericFormat
          // disabled
          // type="text"
          // placeholder="Shows total value"
          // id="total_value"
          // name="total_value"
          // style={{
          //   background: "#ECEDED",
          // }}
          // className={`input`}
          // onChange={onInputChange}
          // value={total_value}
          className={`input`}
          disabled
          decimalScale={3}
          decimalSeparator="."
          type="text"
          name="total_value"
          style={{
            background: "#ECEDED",
          }}
          // thousandsGroupStyle={"lakh"}
          allowNegative
          // onChange={onInputChange}
          thousandSeparator={","}
          value={total_value}
          id="total_value"
          placeholder="Shows total Value"
        />
      </div>
      {/* total value input end */}
    </div>
  );
};
export default EmployeeSpreadForm;
