import { useState, createContext } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import { addLabelValueFunc } from "../helper/Helpers";
// import {}

const ActionContext = createContext({
  authSlideShow: true,
  authSlideShowFunc: (param) => {},
  sidebarDropdown: {},
  sidebarDropdownFunc: (param) => {},
  isSidebarReduce: false,
  sidebarReduceFunc: () => {},
  isProfileBoxOpen: false,
  profileBoxOpenFunc: (param) => {},
  isShowChart: {},
  showChartFunc: (param) => {},
  activeOrg: {},
  activeOrgFunc: (param) => {},
  orgOnce: true,
  setActiveOrgManual: (param) => {},
  perPage: {},
  setPerpage: (param) => {},
  removeOption: false,
  setRemoveOptions: () => {},
  showSupportDropDown: false,
  setShowSupportDropDown: (param) => {},
  currencyValue: {},
  setCurrencyValue: (param) => {},
  isModalOut: false,
  setIsModalOut: (param) => {},
  showCheckList: true,
  setShowCheckList: (param) => {},
  showNotification: false,
  setShowNotification: (param) => {},
  sortOptions: {},
  setSortOptions: (param) => {},
  curencyConversion: {},
  setCurrencyConversion: () => {},
  tokenCrashed: false,
  setTokenCrashed: (param) => {},
  serverDown: false,
  setServerDown: (param) => {},
  planExpire: false,
  setPlanExpire: (param) => {},
  showInfoSubExpire: false,
  setShowInfoSubExpire: (param) => {},
});

export function ActionContextProvider(props) {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const [OrgOnce, SetOrgOnce] = useState(true);
  const [isauthSlideShowVal, setIsAuthSlideShowVal] = useState(true);
  const [sidebarDropdownVal, setsidebarDropdownVal] = useState({
    one: location.pathname.includes("/dashboard-home") ? true : false,
    two: location.pathname.includes("/dashboard-projection") ? true : false,
    three: location.pathname.includes("/dashboard-actual") ? true : false,
    four: location.pathname.includes("/dashboard-general") ? true : false,
    five: location.pathname.includes("/dashboard-administration")
      ? true
      : false,
  });
  const [sidebarReduceVal, setSidebarReduceVal] = useState(false);
  const [profileboxShowVal, setprofileboxShowVal] = useState(false);
  const [removeOptionVal, setremoveOptionVal] = useState(false);
  const [modalOutVal, setModalOutVal] = useState(false);
  const [showNotificationVal, setShowNotifficatioVal] = useState(false);
  const [showChartVal, setShowChartVal] = useState({
    expense_projection: true,
    expense_projection_box: true,
  });
  const [aciveOrgVal, setActiveOrgVal] = useState(
    user?.organizations?.length > 0 && user?.organizations[0]
  );
  const [perPageVal, setPerPageVal] = useState({
    label: "20",
    value: 20,
  });
  // const currency
  const [supportDropDown, setSupportDropDown] = useState(false);
  const [tokenCrashedVal, setTokenCrashedVal] = useState(false);
  const [currencyValue, setCurrencyVal] = useState({});
  const [showCheckListVal, setShowCheckListVal] = useState(true);
  const [serverDownVal, setServerDownVal] = useState(false)
  // const [serverDownTwoVal, setServerDownTwoVal] = useState(false)
  const [sortOption, setSortOption] = useState({
    fis_id: "",
    bra_id: "",
    sub_id: "",
    dept_id: "",
    period: "",
  });
  // useEffect(()   => {
  //   setTimeout(() => {
  //     setIsAuthSlideShowVal(false);
  //   }, 6000);
  // }, []);

  function authSlideShowFunction(param) {
    setIsAuthSlideShowVal(false);
  }

  function sidebarDropdownFunction(param) {
    if (param === "one") {
      setsidebarDropdownVal((prev) => {
        return {
          ...prev,
          one: !sidebarDropdownVal.one,
          two: false,
          three: false,
          four: false,
          five: false,
        };
      });
    }
    if (param === "two") {
      setsidebarDropdownVal((prev) => {
        return {
          ...prev,
          two: !sidebarDropdownVal.two,
          one: false,
          three: false,
          four: false,
          five: false,
        };
      });
    }
    if (param === "three") {
      setsidebarDropdownVal((prev) => {
        return {
          ...prev,
          three: !sidebarDropdownVal.three,
          two: false,
          one: false,
          four: false,
          five: false,
        };
      });
    }
    if (param === "four") {
      setsidebarDropdownVal((prev) => {
        return {
          ...prev,
          four: !sidebarDropdownVal.four,
          two: false,
          three: false,
          one: false,
          five: false,
        };
      });
    }
    if (param === "five") {
      setsidebarDropdownVal((prev) => {
        return {
          ...prev,
          five: !sidebarDropdownVal.five,
          two: false,
          three: false,
          one: false,
          four: false,
        };
      });
    }
  }

  function sidebarReduceFunction() {
    setSidebarReduceVal(!sidebarReduceVal);
  }

  function profileBoxOpenFunction(param) {
    if (param === "any") {
      setprofileboxShowVal(!profileboxShowVal);
    }
    if (param === "close") {
      setprofileboxShowVal(false);
    }
  }

  function showChartFunction(param) {
    if (param === "expense-projection") {
      setShowChartVal((prev) => {
        return {
          ...prev,
          expense_projection: !showChartVal.expense_projection,
        };
      });
      setTimeout(() => {
        setShowChartVal((prev) => {
          return {
            ...prev,
            expense_projection_box: !showChartVal.expense_projection_box,
          };
        });
      }, 300);
    }
  }

  function activeOrgFunction(param) {
    if (OrgOnce) {
      setActiveOrgVal(param);
      user?.organizations?.length > 0
        ? document.documentElement.style.setProperty(
            "--blue-color",
            param?.brand_color
          )
        : document.documentElement.style.setProperty("--blue-color", "#004BFF");
      // console.log(param);
      setCurrencyVal(
        addLabelValFunc({
          ...param.active_fiscal_year,
          id: "",
        })
      );
      // console.log(addLabelValFunc(param.active_fiscal_year));
      localStorage.setItem("brand_color", param.brand_color);
    }
    SetOrgOnce(false);
  }

  const addLabelValFunc = (param) => {
    // console.log(param);
    const obj = {
      ...param,
      label: `${param?.base_currency?.name} - 1`,
      value: param?.base_currency?.id,
    };
    // console.log(obj);
    return obj;
  };

  function setActiveOrgManualFunc(param) {
    // console.log(param);
    setActiveOrgVal(param);
    localStorage.setItem("brand_color", param.brand_color);
    document.documentElement.style.setProperty(
      "--blue-color",
      param?.brand_color
    );
    setCurrencyVal(
      addLabelValFunc({
        ...param.active_fiscal_year,
        id:
          param.active_fiscal_year?.id === 1
            ? ""
            : param.active_fiscal_year?.id,
      })
    );
  }

  function setPerPageFunction(param) {
    setPerPageVal(param);
    // console.log(param);
  }

  function setRemoveOptionFunc() {
    setremoveOptionVal(!removeOptionVal);
  }

  function setSupportDropDownFunc(param) {
    if (param === "any") {
      setSupportDropDown(!supportDropDown);
    }
    if (param === "close") {
      setSupportDropDown(false);
    }
  }

  function setCurrencyValFunc(param) {
    setCurrencyVal(param);
    // console.log(param);
  }

  function changeModalOption(param) {
    setModalOutVal(param);
  }

  function setCheckListFuction(param) {
    setShowCheckListVal(param);
  }

  function setShowNotificationFunc(param) {
    setShowNotifficatioVal(param);
  }

  function setSortOptionVal(param) {
    setSortOption(param);
  }

  function setTokenCrashedFunction (param){
  setTokenCrashedVal(param)
  }

  function setIsSerDownFunc (param){
    setServerDownVal(param)
  }

  const [planExpireVal, setPlanExpireVal] = useState(false);
  function planExpireFunc (param) {
    setPlanExpireVal(param)
  }

  const [showInfoSubExpireVal, setShowInfoSubExpireVal] = useState(true);
  function planExpireSubFunc (param) {
    setShowInfoSubExpireVal(param)
  }

  const context = {
    authSlideShow: isauthSlideShowVal,
    authSlideShowFunc: authSlideShowFunction,
    sidebarDropdown: sidebarDropdownVal,
    sidebarDropdownFunc: sidebarDropdownFunction,
    isSidebarReduce: sidebarReduceVal,
    sidebarReduceFunc: sidebarReduceFunction,
    isProfileBoxOpen: profileboxShowVal,
    profileBoxOpenFunc: profileBoxOpenFunction,
    isShowChart: showChartVal,
    showChartFunc: showChartFunction,
    activeOrg: aciveOrgVal,
    activeOrgFunc: activeOrgFunction,
    orgOnce: OrgOnce,
    setActiveOrgManual: setActiveOrgManualFunc,
    perPage: perPageVal,
    setPerpage: setPerPageFunction,
    removeOption: removeOptionVal,
    setRemoveOptions: setRemoveOptionFunc,
    showSupportDropDown: supportDropDown,
    setShowSupportDropDown: setSupportDropDownFunc,
    currencyValue: currencyValue,
    setCurrencyValue: setCurrencyValFunc,
    isModalOut: modalOutVal,
    setIsModalOut: changeModalOption,
    showCheckList: showCheckListVal,
    setShowCheckList: setCheckListFuction,
    showNotification: showNotificationVal,
    setShowNotification: setShowNotificationFunc,
    sortOptions: sortOption,
    setSortOptions: setSortOptionVal,
    curencyConversion: {},
    setCurrencyConversion: () => {},
    tokenCrashed: tokenCrashedVal,
    setTokenCrashed: setTokenCrashedFunction,
    serverDown: serverDownVal,
    setServerDown: setIsSerDownFunc,
    planExpire: planExpireVal,
    setPlanExpire: planExpireFunc,
    showInfoSubExpire: showInfoSubExpireVal,
    setShowInfoSubExpire: planExpireSubFunc,
  };

  return (
    <ActionContext.Provider value={context}>
      {props.children}
    </ActionContext.Provider>
  );
}

export default ActionContext;
