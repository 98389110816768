import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Modal from "react-awesome-modal";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getBranches,
  getBranchesByFilter,
  getBranchesPagination,
  getBranchesWithPagination,
} from "../../../../../redux/branch/branch";
import ActionContext from "../../../../../context/ActionContext";
import DeleteBranchModal from "./DeleteBranchModal";
import useDebounce from "../../../../../helper/UseDebounce";

const Branch = () => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { loadingPage, meta, links, branches } = useSelector(
    (state) => state.branch
  );
  const { user } = useSelector((state) => state.user);
  // const { loading } = useSelector((state) => state.subsidiary);
  const [indexNo, setIndexNo] = useState("");

  const [perPageval] = useState({
    label: "5",
    value: "5",
  });

  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "Name", value: "name" },
    { label: "Subsidiary", value: "subsidiary" },
    { label: "State", value: "state" },
    { label: "Address", value: "address" },
    { label: "City", value: "city" },
    // { label: "Parent Company", value: "Parent Company" },
  ];
  const tableHeadList = [
    "Branch  Name",
    "Subsidiary",
    "State",
    "Address",
    "City",
    "Action",
  ];

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getBranchesPagination(linkTwo));
  };

  const handleFinish = () => {
    setSingleChild({});
    setRefreshTable(!refreshTable);
    setShowDeleteModal(false);
  };

  const hanldUpdate = (param) => {
    const id = param?.id;
    localStorage.setItem("branch_id", id);
    history.push("/dashboard-administration-admin-settings-branch-update");
  };

  useEffect(() => {
    user?.organizations?.length > 0 &&
      dispatch(getBranches(actionCtx?.activeOrg?.id));
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    user?.organizations?.length > 0 && dispatch(getBranchesWithPagination(obj));
    // eslint-disable-next-line
  }, [refreshTable]);

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    user?.organizations?.length > 0 && dispatch(getBranchesWithPagination(obj));
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: filterByVal?.value || "",
        org_id: actionCtx?.activeOrg?.id,
      };
      dispatch(getBranchesByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitleSpan={`Step 3 of 6`}
        pageTitle={`Add Branch`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-subsidaries"
              )
            }
            className="active text"
          >
            Branches
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>

        {/* table box start */}
        {/* <ReUseableTable
            table_title={`branch`}
            table_head_list={tableHeadList}
            table_title_span={`(1)`}
            table_body_list={tableBodyList}
            btn_text="branch"
          /> */}
        {/* table box end */}

        {/* table box start */}
        <ActualReuseableTable
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          perPageValue={perPageval}
          setPerPageValue={(e) => {
            // setPerpageVal(e);
            handleSortPerPage(e.value);
          }}
          tableTitle={branches?.length > 1 ? `Branches` : "branch"}
          tableTitleSpan={`(${branches?.length})`}
          addBtntext={`New Branch`}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          action
          onCreateNew={() =>
            history.push("/dashboard-administration-admin-settings-add-branch")
          }
          fromPage={meta?.from || 1}
          toPage={meta?.to || branches?.length}
          totalPage={meta?.total || branches?.length}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          loading={loadingPage && branches?.length < 1}
          loadingNum={5}
        >
          {branches?.map((chi, idx) => {
            const { subsidiary, state, address, city, name } = chi;
            return (
              <TableBodyRow
                key={idx}
                one={name}
                two={subsidiary ? subsidiary?.name : ""}
                three={state}
                four={address}
                five={city}
                action
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                updateText={` Update Branch`}
                onUpdate={() => hanldUpdate(chi)}
                deleteText={`Delete Branch`}
                onDelete={() => {
                  setSingleChild(chi);
                  setShowDeleteModal(true);
                }}
                loading={loadingPage}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}

        {/* note box start */}
        {/* <div className="note-wrap">
         <div className="note-img-wrap">
            Important Notice:
            <figure className="img-box">
              <img src={noteIcon} alt="" className="img" />
            </figure>
          </div>
          <div className="note-box">
            <p>
              1. For organizations with shared budgets, you would need to have
              created at least two subsidiaries to set up a shared services
            </p>
          </div>
         </div> */}
        {/* note box end */}
        {/* btn box start */}
        {actionCtx?.activeOrg?.check_list?.percentage !== 100 && (
          <div className="btn-box-wrap">
            <button
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-subsidaries-main"
                )
              }
              className="btn-reuse"
            >
              Go to previous step
            </button>
            <button
              onClick={() => {
                history.push(
                  "/dashboard-administration-admin-settings-add-department"
                );
              }}
              className="btn-reuse btn-two"
            >
              Go to the next step
            </button>
          </div>
        )}
        {/* btn box end */}
        {/* skip text btn box start*/}
        {/* <div className="skip-box">
          <p className="text">
            If you do not have a registered branch under your organization,
            click to next the process
          </p>
          <button className="btn">Skip this</button>
        </div> */}
        {/* skip text btn box end */}
      </div>
      {/* </DashboardLayout> */}
      {/* modal start  */}
      <Modal visible={showDeleteModal} effect="fadeInUp">
        <DeleteBranchModal
          details={singleChild}
          onFinish={handleFinish}
          onClose={() => setShowDeleteModal(false)}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default Branch;
