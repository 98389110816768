import React from "react";
// import { useEffect } from "react";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import { deleteActualSupplementaryBudget } from "../../../../../../../redux/employee/actual-supplementary-budget/actual-supplementary-budget";
import "../../../../../../../styles/dashboard/modals/DeleteSharedmodal.css";

const EmpActualSupplementaryDelete = ({ onClose, onFinish, details }) => {
  const dispatch = useDispatch();
  const { loadingTwo } = useSelector(
    (state) => state.actual_supplementary_budget
  );

  const handleDelete = async () => {
    const id = details?.id;

    const data = await dispatch(deleteActualSupplementaryBudget(id));
    if (data?.payload?.success) {
      onFinish();
    }
  };

  return (
    <div className="delete-shared-modal-wrap">
      <p className="title" style={{ textTransform: "capitalize" }}>
        {`Delete Supplementary Budget (${details?.chartofaccount?.name})`}
      </p>
      <p className="text">
        {` Are you sure you want to delete this budget? you'll permanently lose
        this information and may not be able to recover it. Do you still want to
        delete data?`}
      </p>
      {/* <p className="text text-two">
        organizatiuon budget settings. Do you still want to delete?
    </p> */}

      {/* <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        <ButtonBlue bgColor={`#CC0905`} text={`Yes Delete`} />
      </div> */}
      <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        {!loadingTwo ? (
          <ButtonBlue
            bgColor={`#CC0905`}
            text={`Yes Delete`}
            onClick={handleDelete}
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmpActualSupplementaryDelete;
