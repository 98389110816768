import React from "react";
import "../../../../../styles/dashboard/integration/index.scss";

import { useHistory, useLocation } from "react-router-dom";
import ConnectPlatform from "./connectPlatform";

const Integration = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <div className="integration-wrap">
        <p className="title">Integration</p>
        <p>
          Integrate with other extension to fast track and improve your
          experience
        </p>
        <div className="wrapper" />
        <div className="tools-wrap">
          <p
            className="tools"
            onClick={() =>
              history.push("/dashboard-general-integration-crm-tools")
            }
          >
            Accounting Tools
          </p>
          <p className="tools">Payment Tools</p>
          <p className="tools">Editing Tools</p>
        </div>
        {location.pathname ===
          "/dashboard-general-integration-connected-platform" && (
          <div>
            <br />
            <br />
            <br />
            <ConnectPlatform />
          </div>
        )}
      </div>
    </>
  );
};
export default Integration;
