import React from "react";
import ZohoImg from "../../../../../assets/zohoImg.svg";
import HelpImg from "../../../../../assets/helpImg.svg";
import { useContext } from "react";
import ActionContext from "../../../../../context/ActionContext";
import { useState } from "react";

const ConnectPlatform = () => {
  const actionCtx = useContext(ActionContext);

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onLeave = () => {
    setHover(false);
  };
  return (
    <>
      {/* connected platform start */}
      <div className="connected-platform">
        {/* toggle on and off start */}
        <div className="zoho-left-box">
          <p className="connected-title">Connected Platform</p>
          {hover && (
            <div className="hover-wrap">
              <p className="hover-box">Read Support Documentation</p>
              <div className="hover-clip"></div>
            </div>
          )}
        </div>
        <div
          className="expense-projection-inde-wrap"
          style={{
            paddingBottom: "unset",
            paddingTop: "0px",
            paddingLeft: "0px",
          }}
        >
          <div className="title-hide-box zoho-wrap">
            {/* hide box start */}

            <figure className="img">
              <img src={ZohoImg} alt="" />
            </figure>

            <div className="hide-box">
              <figure
                className="help-img"
                onMouseEnter={onHover}
                onMouseLeave={onLeave}
              >
                <img src={HelpImg} alt="" />
              </figure>
              <input
                checked={actionCtx.isShowChart?.expense_projection}
                className="input"
                onChange={(e) => actionCtx.showChartFunc("expense-projection")}
                type="checkbox"
                name=""
                id="chart-check"
                style={{ marginTop: "-3rem" }}
              />
              <p className="label-text">
                {actionCtx.isShowChart?.expense_projection ? "On" : "Off  "}
              </p>
              <label htmlFor="chart-check" className="label">
                <span></span>
                <span></span>
              </label>
            </div>
          </div>
          {/* toggle on and off end */}
        </div>
      </div>
      {/* connected platform end */}
    </>
  );
};

export default ConnectPlatform;
