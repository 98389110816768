import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import "../../../../../styles/dashboard/projection/expense/removebtn.scss";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { NumericFormat } from "react-number-format";

const EmployeeRemovableGroup = ({
  list,
  onSelectvalueChange,
  onInputValueChange,
  handleRemoveMonthList,
  handleAddMonthList,
  total_value,
  total_quantity,
  onInputChange,
  entryType,
  Quantity,
  Total_quantity_label,
  onInputTotalChange,
  name,
  loading,
  total_value_label,
}) => {
  const [details ] = useState({
    total_quantity: "",
    total_value: "",
    entry_type: "",
  });
  const monthOption = [
    {
      label: "January",
      name: "January",
      value: "January",
    },
    {
      label: "February",
      name: "February",
      value: "February",
    },
    {
      label: "March",
      name: "March",
      value: "March",
    },
    {
      label: "April",
      name: "April",
      value: "April",
    },
    {
      label: "May",
      name: "May",
      value: "May",
    },
    {
      label: "June",
      name: "June",
      value: "June",
    },
    {
      label: "July",
      name: "July",
      value: "July",
    },
    {
      label: "August",
      name: "August",
      value: "August",
    },
    {
      label: "September",
      name: "September",
      value: "September",
    },
    {
      label: "October",
      name: "October",
      value: "October",
    },
    {
      label: "November",
      name: "November",
      value: "November",
    },
    {
      label: "December",
      name: "December",
      value: "December",
    },
  ];

  return (
    <div>
      {list?.map((item, idx) => {
        const { month_name: name, value } = item;

        // console.log(item, "item");

        return (
          <div
            key={idx}
            className="form-wrap"
            // style={{ marginBottom: "-10rem", marginTop: "-2rem", gap: "5rem" }}
            style={{
              marginBottom: "-10rem",
              marginTop: "-2rem",
              gap: "5rem",
              width: "110%",
              // display:"grid",
              gridTemplateColumns: "1fr 1fr .1fr",
              // display: "grid",
            }}
          >
            <div className="form-group">
              <label htmlFor="" className="label">
                Select Month <span>*</span>
              </label>
              <Select
                className={`select-wrap ${
                  (item?.name?.label && "select-wrap-active") ||
                  (item?.month_name?.label && "select-wrap-active")
                }`}
                placeholder="Select Month"
                // defaultValue="Select Month"
                value={name}
                isLoading={loading}
                onChange={(e) => {
                  // setMonthSelect((prev) => {
                  //   return { ...prev, monthSelect[0]: e };
                  // });
                  onSelectvalueChange(e, item);
                  // console.log(e);
                  // console.log(item);
                }}
                styles={reactSelectStyle}
                options={monthOption}
                // options={productDrop}
              />
            </div>

            <div
              className="form-group"
              // style={{ display: "grid", gridTemplateColumns:"1fr 1fr 1fr 1fr  " }}
            >
              <label htmlFor="" className="label">
                {Quantity || "Quantity"} <span>*</span>
              </label>
              <NumericFormat
                // disabled
                // // type="number"
                // id=""
                // name="value"
                // placeholder="value populated"
                // value={value}
                // onChange={(e) => {
                //   onInputValueChange(e, item);
                // }}
                // className={`input ${value && "input-active"}`}
                className={`input ${value && "input-active"}`}
                decimalScale={3}
                decimalSeparator="."
                type="text"
                name="value"
                // thousandsGroupStyle={"lakh"}
                allowNegative
                // onChange={onInputChange}
                onChange={(e) => {
                  onInputValueChange(e, item);
                }}
                thousandSeparator={","}
                value={value}
                id="value"
                placeholder="Value Populated"
              />
            </div>

            {/* <div className="emp-cancel-wrap emp-minus-wrap"> */}
            <div
              className="employee-remove-btn"
              style={{ display: "flex", alignItems: "center" }}
            >
              <FaMinus
                className="icon"
                onClick={() => handleRemoveMonthList(item)}
              />
            </div>

            {/* </div> */}
          </div>
        );
      })}
      {/* form-wrap end */}
      <div
        className="emp-add-months-wrap"
        onClick={handleAddMonthList}

        // style={{ gridTemplateRows:"1fr" }}
      >
        <FaPlus className="icon" />
        <p className="text">Add more months</p>
      </div>
      {/* form-wrap start */}
      <div
        className="form-wrap"
        style={{ marginTop: "3rem", marginBottom: "-13rem" }}
      >
        <div className="form-group">
          <label htmlFor="total value" className="label">
            {total_value_label || "Total Value"}
          </label>
          <NumericFormat
            // // type="number"
            // placeholder="value populated"
            // id="total_value"
            // name="total_value"
            // style={{
            //   background: "#ECEDED",

            //   // border: "hidden",
            // }}
            // className={`input `}
            // value={details?.total_value || total_value}
            // onChange={onInputChange}
            disabled
            className={`input`}
            decimalScale={3}
            decimalSeparator="."
            type="text"
            name="total_value"
            // thousandsGroupStyle={"lakh"}
            style={{
              background: "#ECEDED",
              // border: "hidden",
            }}
            allowNegative
            onChange={onInputChange}
            thousandSeparator={","}
            value={details?.total_value || total_value}
            id="total_value"
            placeholder="value Populated"
          />
        </div>
        <div className="form-group">
          <label htmlFor="total quantity" className="label">
            {Total_quantity_label || "Total Quantity"}
          </label>
          <NumericFormat
            // // type="number"
            // placeholder="quantity populated"
            // id="total_quantity"
            // name="units"
            // style={{
            //   background: "#ECEDED",
            //   // border: "hidden",
            // }}
            // className={`input`}
            // value={total_quantity}
            // onChange={onInputTotalChange}
            disabled
            className={`input`}
            decimalScale={3}
            decimalSeparator="."
            type="text"
            name="units"
            style={{
              background: "#ECEDED",
              // border: "hidden",
            }}
            // thousandsGroupStyle={"lakh"}
            allowNegative
            onChange={onInputChange}
            thousandSeparator={","}
            value={total_quantity}
            id="units"
            placeholder="quantity populated"
          />
        </div>
      </div>
      {/* form-wrap end */}
    </div>
  );
};

export default EmployeeRemovableGroup;
