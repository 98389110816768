import React, { useContext, useEffect } from "react";
import {  FaTimes } from "react-icons/fa";
import "../../../../../styles/dashboard/admin-settings/PreferenceSettings.css";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import {  getCurriencies, updateCurrencyConversion } from "../../../../../redux/currency/currency";
import ActionContext from "../../../../../context/ActionContext";
import { NumericFormat } from "react-number-format";
import { Dna } from "react-loader-spinner";

const UpdateCurrencyModal = ({ children, onCancel , onFinish, detail, setDetail }) => {
  const dispatch = useDispatch();
  const { currencyDrop } = useSelector((state) => state.currency);



  useEffect(() => {
    dispatch(getCurriencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionCtx = useContext(ActionContext);
  const {loading} = useSelector((state) => state.currency)

  const handleSubmit = async () => {
    // console.log(detail);
    const obj = {
      id: detail?.id,
      rate: formatNumberWithoutComma(detail?.rate),
      currency_id: detail?.currency_id?.value,
      base_currency_id:
        actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.id,
      organization_id: actionCtx?.activeOrg?.id,
    };
    const data = await dispatch(updateCurrencyConversion(obj))
    if(data?.payload?.success){
      onFinish()
    }
  };



  return (
    <div className="update-password-wrap">
      {children}
      <div className="title-box">
        <p className="title">Update Currency</p>
        <div onClick={onCancel} className="cancel-box">
          <FaTimes className="icon" />
        </div>
      </div>
      {/* content box start */}
      <div className="content-box">
        <p className="text">
          Please note any changes made to your base currency would automatically
          affect your financial setting. Set other currency for a specific
          financial period
        </p>
        {/* form group start */}
        <div className="form-group-currency-modal">
          <label className="label" style={{ marginBottom: ".5rem" }} htmlFor="">
            Currency <span>*</span>
          </label>
          {/* select box */}
          <div className="box">
            <Select
              className="select "
              name="currency_id"
              placeholder="Sync with system settings"
              value={detail?.currency_id}
              styles={reactSelectStyle}
              onChange={(e) => {
                setDetail("currency", e)
              }}
              options={currencyDrop}
            />
          </div>
          {/* select end */}
        </div>
        {/* form group end */}
        {/* group box start */}
        <div className="form-group-currency-modal">
          <label htmlFor="Name" className="label">
            Set Rate <span>*</span>
          </label>
           <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            type="text"
            name="currency_rate"
            thousandsGroupStyle={"lakh"}
            allowNegative
            onChange={(e) => {
              setDetail((prev) => {
                return {
                  ...prev,
                  rate: e.target.value,
                };
              });
            }}
            thousandSeparator={","}
            value={detail?.rate}
            id="set_currency"
            placeholder="610.00"
            className={`input ${detail?.rate && "input-active"}`}
          />
        </div>
        {/* group box end */}
      </div>
      {/* content box end */}
      {/* btn start */}
      <div className="btn-box">
       {!loading ? <ButtonBlue
          onClick={() =>
           handleSubmit()
          }
          text={`Save`}
        /> :  (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "flex-end" }}
          >
            <Dna
              visible={true}
              height="70"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
      {/* btn end */}
    </div>
  );
};

export default UpdateCurrencyModal;
