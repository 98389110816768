import React, { useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH, FaTimes } from "react-icons/fa";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
// import "../../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
// import imgOne from "../../../../../../../assets/hod-two-img.svg";
// import cardviewImg from "../../../../../../../../assets/view-card-img.png";
import ApprovalContent from "../../../actual/modals/contentHead";
// import userImg from "../../../../../../../assets/hod-one-img.svg";
import userImg from "../../../../../../../assets/Unknown_person.jpeg";
import EmployeeOverviewTableBodyRow from "../../../home/modals/Reimbursement/overviewTableRow";
import { Dna } from "react-loader-spinner";
import { useSelector } from "react-redux";
import {
  formatNumber,
  formatToWholeNum,
} from "../../../../../../../helper/Helpers";

const EmpSupplementaryOverview = ({
  onCancel,
  onUpdate,
  onDelete,
  handleSubmit,
  data,
  details,
}) => {
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });
  const [showActions, setShowActions] = useState(false);
  const { loadingTwo } = useSelector(
    (state) => state.actual_supplementary_budget
  );
  const { user } = useSelector((state) => state.user);

  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  const contentHeader = ["Months", "Units", "Total Value"];
  // const contentHeaderTwo = ["Level", "Name", "Status"];

  // console.log(details, "details");

  // console.log(user, "user");

  return (
    <div className="overview-card-modal-wrap">
      <div className="top-box">
        <div className="title-box">
          <p
            // onClick={() => {
            //   setDetailToShow("overview");
            // }}
            className="title"
          >
            Overview
          </p>
          {/* <p
            onClick={() => {
              setDetailToShow("activity");
            }}
            className="title"
          >
            Activity Log
          </p> */}
        </div>
        {!loadingTwo ? (
          <ButtonBlue
            onClick={() => {
              handleSubmit(setShowActions(false));
            }}
            text={`Submit`}
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
        <div
          onClick={() => setShowActions(!showActions)}
          className="ellipse-box"
        >
          <FaEllipsisH className="icon" />
          <div
            style={{
              height: "9rem",
              paddingTop: "1rem",
              gap: "1.5rem",
            }}
            className={`action-box ${showActions && "action-box-active"}`}
          >
            <p style={{ display: "none" }}></p>
            <p onClick={onUpdate}>
              <span style={{ marginBottom: "1rem" }}>Update</span>
            </p>
            <p onClick={onDelete}>
              <span style={{ color: "#CC0905" }}>Delete</span>
            </p>
          </div>
          {/* action box end */}
        </div>
        <div
          onClick={() => {
            onCancel(setShowActions(false));
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      <div className="content-box">
        {/* title drop control box start */}
        <>
          <div
            onClick={() => handleShowBox("one")}
            className="title-drop-control-box"
          >
            <p className="title">Budget Owner Details</p>
            <div className="icon-box">
              {!showBox.one && <FaAngleDown className="icon" />}
              {showBox.one && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.one && (
            <div className="content-wrap">
              <div className="three-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Chart of Account</p>
                  <p
                    className="value"
                    style={{ textTransform: "capitalize" }}
                  >{`${details?.chartofaccount?.name}`}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Class</p>
                  <p
                    className="value"
                    style={{ textTransform: "capitalize" }}
                  >{`${details?.chartofaccount?.class}`}</p>
                </div>
                {/* box start */}
                <div className="box">
                  <p className="text">Approved Price</p>
                  <p className="value">{`${formatNumber(
                    String(details?.approved_price)
                  )}`}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  {/* <p className="text"></p> */}
                  {/* <p className="value">Healthcare</p> */}
                </div>
                {/* box end */}
                {/* box end */}
              </div>
              <div className="three-box">
                <div className="box">
                  <p className="text">New Price</p>
                  <p className="value">{`${formatNumber(
                    String(details?.new_price)
                  )}`}</p>
                </div>
                <div className="box">
                  <p className="text">Department</p>
                  <p className="value">{`${details?.department?.name}`}</p>
                </div>
                {/* box start */}
                <div className="box">
                  <p className="text">Description</p>
                  <p
                    className="value"
                    style={{ textTransform: "capitalize" }}
                  >{`${details?.description}`}</p>
                </div>
              </div>
              <div className="three-box">
                <div className="box">
                  <p className="text">Units</p>
                  <p className="value">{formatToWholeNum(details?.units)}</p>
                </div>
                {/* box start */}
                <div className="box">
                  <p className="text">Unit Price</p>
                  <p className="value">{`${formatNumber(
                    String(details?.unit_price)
                  )}`}</p>
                </div>
                {/* box-end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Total Value</p>
                  <p className="value">{`${formatNumber(
                    String(details?.total_supplementary)
                  )}`}</p>
                </div>
                {/* box-end */}
              </div>
              <div className="two-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Date Created</p>
                  <p className="value">{`${data?.created_at}`}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Last Updated</p>
                  <p className="value">{`${data?.updated_at}`}</p>
                </div>
                {/* box end */}
                {/* box start */}
                {/* <div className="box">
                  <p className="text">Last Updated</p>
                  <p className="value">26 May, 2022 - 7:08pm</p>
                </div> */}
                {/* box end */}
              </div>

              {/* created by wrap start */}
              <div className="one-box">
                <div className="box">
                  <p className="text">Created by</p>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="img-text-box"
                  >
                    <figure style={{ marginRight: "1rem" }} className="img-box">
                      <img
                        style={{
                          borderRadius: "20%",
                          width: "4rem",
                          height: "4rem",
                        }}
                        src={user?.profile_picture || userImg}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <div className="text-box">
                      <p className="value">{`${data?.createdby?.fullName}`}</p>
                      <p style={{ marginBottom: "unset" }} className="text">
                        {`${data?.createdby?.email}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* created by wrap end */}
            </div>
          )}
        </>
        {/* title drop control box end */}

        {/* title drop control box start */}
        <>
          <div
            onClick={() => handleShowBox("two")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Monthly Breakdown</p>
            <div className="icon-box">
              {!showBox.two && <FaAngleDown className="icon" />}
              {showBox.two && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.two && (
            <div>
              <ApprovalContent
                headerOne={contentHeader[0]}
                headerTwo={contentHeader[1]}
                headerThree={contentHeader[2]}
              />
              {details?.monthly_breakdown?.map((chi, idx) => {
                const {
                  month_name,
                  value: total_value,
                  // value: units,
                } = chi;
                return (
                  <>
                    <EmployeeOverviewTableBodyRow
                      key={idx}
                      one={month_name}
                      two={
                        details?.entry_type === "Quantity"
                          ? formatToWholeNum(total_value)
                          : formatToWholeNum(details?.units / 12) ||
                            details?.entry_type === "Amount"
                          ? formatToWholeNum(total_value / details?.unit_price)
                          : formatToWholeNum(details?.units / 12)
                      }
                      three={
                        details?.entry_type === "Quantity"
                          ? formatNumber(
                              String(total_value * details?.unit_price)
                            )
                          : formatNumber(String(total_value))
                      }
                    />
                  </>
                );
              })}
            </div>
          )}
          {/* {showBox.two && <div className="content-wrap"></div>} */}
        </>
        {/* title drop control box end */}
        {/* title drop control box start */}
        {/* <>
          <div
            onClick={() => handleShowBox("three")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Approval Flow</p>
            <div className="icon-box">
              {!showBox.three && <FaAngleDown className="icon" />}
              {showBox.three && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.three && (
            <div className="content-wra">
              <ApprovalContent
                headerOne={contentHeaderTwo[0]}
                headerTwo={contentHeaderTwo[1]}
                headerThree={contentHeaderTwo[2]}
              />
              {details?.approval_flow?.map((chi, idx) => {
                const { step, user, status } = chi;
                return (
                  <>
                    <EmployeeOverviewTableBodyRow
                      key={idx}
                      one={step?.toString().padStart(2, "0")}
                      userOne
                     userName={user?.first_name + " " + user?.last_name}
                      userEmail={user?.email}
                      userImg={user?.profile_picture || userImg}
                      status={
                        status === "approved"
                          ? "Approved"
                          : status === "pending"
                          ? "Pending"
                          : status === "rejected"
                          ? "Rejected"
                          : "pending"
                      }
                    />
                  </>
                );
              })}
            </div>
          )}
        </> */}
        {/* title drop control box end */}
      </div>
      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default EmpSupplementaryOverview;
