import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
// import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { customErrorId } from "../../../helper/Helpers";
// import { addLabelValueFunc, formatDate } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getConsolidatedBudgetReport = createAsyncThunk(
  "employee/get-consolidated-budget-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/consolidated-report?organization_id=${
          formData?.org_id || ""
        }&date=${formData?.date || ""}&department_id=${
          formData?.dept_id || ""
        }&subsidiary_id=${formData?.sub_id || ""}&status=${
          formData?.status || ""
        }&currency_conversion_id=${formData?.currency_id || ""}&perPage=${
          formData?.per_page || ""
        }`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setConsolidatedReports(data?.data?.consolidated_budget)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
        toastId: customErrorId,
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getConsolidatedBudgetReportPagination = createAsyncThunk(
  "employee/get-consolidated-budget-report-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/consolidated-report?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&subsidiary_id=${
          formData?.sub_id || ""
        }&status=${formData?.status || ""}&page=${
          formData?.page || ""
        }&currency_conversion_id=${formData?.currency_id}&perPage=${
          formData?.per_page || ""
        }`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      // /api/employee/reports/consolidated-report?page=2
      if (data?.success) {
        // console.log("data-check", data?.data?.consolidated_budget);
        await thunkAPI.dispatch(
          setConsolidatedReports(data?.data?.consolidated_budget)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Bad Network, check your network settings", {
          theme: "colored",
          toastId: customErrorId,
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const consolidated_budget_report = createSlice({
  name: "consolidated_budget_report",
  initialState: {
    consolidatedBudgetReports: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
  },

  reducers: {
    setConsolidatedReports: (state, action) => {
      state.isAuth = true;
      state.consolidatedBudgetReports = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getConsolidatedBudgetReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getConsolidatedBudgetReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getConsolidatedBudgetReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getConsolidatedBudgetReportPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getConsolidatedBudgetReportPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getConsolidatedBudgetReportPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConsolidatedReports } = consolidated_budget_report.actions;

export default consolidated_budget_report.reducer;
