import React, { useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
// import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import noteIcon from "../../../../../../assets/note-icon.svg";
import "../../../../../../styles/dashboard/admin-settings/AdminSettings.css";
import RemovableGroup from "./RemovableGroup";

const FormSummary = () => {
  // const history = useHistory();

  const [details, setDetails] = useState({
    Name: "",
    subsidiary: "",
    state: "",
    Address: "",
    city: "",
    Branch_Code: "",
  });

  const [approvalList, setApprovalList] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleAddToApprovalList = () => {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const obj = {
      id: id,
      user_name: "",
      user_type: "",
      user_time: "",
      user_two_name: "",
    };
    const newList = [...approvalList, obj];
    setApprovalList(newList);
    console.log(newList);
    // console.log(test);
  };

  const handleSelectUser = (param, child) => {
    const newList = approvalList.map((chi) =>
      chi.id === child.id ? { ...chi, user_name: param.label } : chi
    );
    // console.log(newList);
    setApprovalList(newList);
  };

  const handleSelectUserTwo = (param, child) => {
    const newList = approvalList.map((chi) =>
      chi.id === child.id ? { ...chi, user_two_name: param.label } : chi
    );
    // console.log(newList);
    setApprovalList(newList);
  };

  const handleSelectUserType = (param, child) => {
    if(param?.label === "Single User"){
        const newList = approvalList.map((chi) =>
        chi.id === child.id ? { ...chi, user_type: param.label, user_time: "", user_two_name: "" } : chi
      );
        console.log(newList);
      setApprovalList(newList);
    }
    else{
       const newList = approvalList.map((chi) =>
      chi.id === child.id ? { ...chi, user_type: param.label } : chi
    );
      console.log(newList);
    setApprovalList(newList);  
    }
   
  };

  const handleSelectUserTime = (param, child) => {
    const newList = approvalList.map((chi) =>
      chi.id === child.id ? { ...chi, user_time: param.label } : chi
    );
    //   console.log(newList);
    setApprovalList(newList);
  };

  const handleRemoveChild = (child) => {
    const newList = approvalList.filter((chi) => chi.id !== child.id);
    // console.log(child);
    // console.log(newList);
    setApprovalList(newList);
    // console.log(child);
  };

  return (
    <div>
      {/* form box start */}
      <div className="form-box" >
        {/* <p className="form-title">Invite via </p> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // history.push("/dashboard-home");
          }}
          action=""
          className="form"
        >
          {/* wrap start */}
          <div className="wrapper" >
            <p className="box-title">1.Approval Details</p>
            <div className="form-wrap" style={{ width: "80%" }}>
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Subsidiary" className="label">
                  Subsidiary <span>*</span>
                </label>
                <select
                  name="subsidiary"
                  onChange={handleChange}
                  value={details.subsidiary}
                  id=""
                  className={`input ${details.subsidiary && "input-active"}`}
                >
                  <option value="Naira">Zojatech Ltd</option>
                </select>
              </div>
              {/* group box end */}

              {/* group box start */}
              <div className="form-group">
                <label htmlFor="department" className="label">
                  Department <span>*</span>
                </label>
                <select
                  name="department"
                  onChange={handleChange}
                  value={details.subsidiary}
                  id="department"
                  className={`input ${details.subsidiary && "input-active"}`}
                >
                  <option value="Naira">Product & Sales</option>
                </select>
              </div>
              {/* group box end */}
            </div>
          </div>
          {/* wrap end */}
          {/* wrap start */}
          <div className="wrapper">
            <p className="box-title"> 2. Approval Setup</p>
            {/* step assign box start */}
            <div className="step-assign-box">
              <div className="step-assign-line">
                <p className="text">step</p>
                <p className="text">Assigned To</p>
                <div className="text">
                  Approval required{" "}
                  <span>
                    {" "}
                    <figure
                      className="img-box"
                      style={{
                        filter: "saturate(0%)",
                        transform: "translateX(.5rem) translateY(.3rem)",
                      }}
                    >
                      <img src={noteIcon} alt="" className="img" />
                    </figure>
                  </span>
                </div>
              </div>
              {/* assign line end */}
            </div>
            {/* step assign box end */}
            {/* removable box start */}
            <div className="removable-wrap">
              {approvalList.map((chid, idx) => {
                return (
                  <RemovableGroup
                    list={approvalList}
                    key={chid.id || idx}
                    num={idx}
                    handleRemoveChild={() => handleRemoveChild(chid)}
                    onSelectUser={(e) => handleSelectUser(e, chid)}
                    onSelectUserTwo={(e) => handleSelectUserTwo(e, chid)}
                    onSelectUserType={(e) => handleSelectUserType(e, chid)}
                    onSelectUserTime={(e) => handleSelectUserTime(e, chid)}
                  />
                );
              })}
              {/* <RemovableGroup /> */}
            </div>
            {/* removable box end */}

            {/* add more start */}
            <div className="add-approval-box" onClick={handleAddToApprovalList}>
              <FaPlus className="icon" />
              <p className="text">Add Approval Step</p>
            </div>
            {/* add more end */}
          </div>
          {/* wrap end */}
          {/* wrap stsrt */}
          <div className="wrapper" style={{borderBottom: "unset"}}>
            <p className="box-title"> 3. Approval Settings</p>
            <div className="content">
              <div className="check-label-box">
                <input type="checkbox" className="check" id="check-notify" />
                <label htmlFor="check-notify" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Notify budget rep and budget owner of comments and approval
                  progress{" "}
                </label>
              </div>
              {/* check start */}
              <div className="check-label-box">
                <input type="checkbox" className="check" id="check-email" />
                <label htmlFor="check-email" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Send email reminder notification{" "}
                </label>
              </div>
              {/* check end */}
              {/* check start */}
              <div className="check-label-box">
                <input type="checkbox" className="check" id="check-comment" />
                <label htmlFor="check-comment" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Allows comments to approve{" "}
                </label>
              </div>
              {/* check end */}
              {/* check start */}
              <div className="check-label-box">
                <input type="checkbox" className="check" id="check-reject" />
                <label htmlFor="check-reject" className="label">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  Allows comments to reject{" "}
                </label>
              </div>
              {/* check end */}
            </div>
          </div>
          {/* wrap end */}
        </form>
      </div>
      {/* form box end */}
    </div>
  );
};

export default FormSummary;
