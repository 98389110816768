import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FaAngleRight, FaCheck, FaPlus } from "react-icons/fa";
// import DashboardLayout from "../../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/admin-settings/AdminSettingApproval.css";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import noteIcon from "../../../../../../assets/note-icon.svg";
import FormSummary from "./FormSummary";
// import { useDispatch } from "react-redux";
// import { getApprovalClass, getApprovalType } from "../../../../../../redux/approvals";

const list = [
  {
    title: "Simple Approval",
    text: "This approval is automatically done by the Admin manager personnel",
  },
  {
    title: "Direct Approval",
    text: "This approval is automatically done by the “submitted to” or “head of department” preference.",
  },
  {
    title: "Custom Approval",
    text: "Set your approval based on your choice or style of preference",
  },
];

const ExpenditureProjectionSettings = () => {
  const [expenditureType, setExpenditureType] = useState("");
  const history = useHistory();
  // const dispatch = useDispatch()
  const [showForm, setSHowForm] = useState(false);

  // useEffect(() => {
  //  dispatch(getApprovalClass());
  //  dispatch(getApprovalType());
  // }, [])

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitle={`Setup Approval Flow`}
        pageTitleSpan={`Step 6 of 6`}
      > */}
      <div className="admin-approval-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push(
                // "/dashboard-administration-admin-settings-approval-flow"
                "/dashboard-general-approvals"
              )
            }
            className="text"
          >
            Approval
          </p>
          <FaAngleRight className="icon" />
          <p
            // onClick={() => history.push("/dashboard-general-approvals")}
            className="active text"
          >
            Expenditure Projection
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>
        {/* subtitle box end */}
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Expenditure Projection Approval</p>
            <p className="text">
              Set up approval type and assign approvers to company budget and
              expense. Approvals are automatically assigned to users preference
              in the process of setting up account. Updates made to a set
              approval will be reflected on users to which it is applied.
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          <div className="btn-download-box">
            <button
              onClick={() => {
                setSHowForm(false);
                setExpenditureType("");
              }}
              className="btn"
            >
              Reset
            </button>
            <button
              onClick={() =>
                history.push(
                  // "/dashboard-administration-admin-settings-approval-flow-expenditure-projection-main"
                  "/dashboard-general-approvals"
                )
              }
              className={`btn btn-save `}
            >
              save
            </button>
          </div>
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* list box start  */}
        <div
          className="list-wrap"
          style={{
            paddingBottom: "unset",
            marginBottom: "unset",
          }}
        >
          {list.map((chi, idx) => {
            const { title, text } = chi;
            return (
              <div
                key={idx}
                onClick={() => setExpenditureType(title)}
                className={`item ${expenditureType === title && "item-active"}`}
              >
                <p className="title">{title}</p>
                <p className="text">{text}</p>
                {/* check box start */}
                <div className="check-box">
                  <FaCheck
                    className={`icon ${
                      expenditureType === title && "icon-active"
                    }`}
                  />
                </div>
                {/* check box end */}
              </div>
            );
          })}
        </div>
        {/* note wrap start */}
        {expenditureType && !showForm && (
          <div
            className="admin-org-settings-wrap"
            style={{ paddingBottom: "unset", padding: "0", marginTop: "-4rem" }}
          >
            <div className="note-wrap">
              <div className="note-img-wrap">
                Important Notice:
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
              </div>
              {/* note box start */}
              <div className="note-box">
                <p>
                  1. The submitted item for approval will be rejected by the
                  initial choice of preference and re-submitted to be approved
                  by the custom approvers.
                </p>
              </div>
            </div>
          </div>
        )}
        {/* note wrap end */}
        {/* list box end */}
        {/* button box start */}
        {expenditureType && !showForm && (
          <div className="btn-wrap">
            <ButtonBlue
              onClick={() => setSHowForm(true)}
              text={`Set Approval Flow`}
            >
              <FaPlus style={{ marginRight: ".8rem" }} />
            </ButtonBlue>
          </div>
        )}
        {/* button box end */}

        {/* form wrap start  */}
        {showForm && expenditureType && (
          <div className="form-wrap">
            <FormSummary />
          </div>
        )}
        {/* form wrap end  */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default ExpenditureProjectionSettings;
