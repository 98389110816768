import React, { useContext, useEffect } from "react";
import "../../../styles/dashboard/SetupLayout.css";
import revvexLogo from "../../../assets/revvex-white-logo.svg";
import ActionContext from "../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import { getUserActivePlan, getUserActivePlanWithoutOrg } from "../../../redux/users/users";
import { getUserDetails } from "../../../redux/user";

const SetupLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);

  useEffect(() => {
    dispatch(getUserDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getUserActivePlanWithoutOrg());

    // eslint-disable-next-line
  }, [user]);

  return (
    <div
      onClick={() => {
        console.log(user);
      }}
      className="setup-layout-wrap"
    >
      <div className="left-box">
        {/* logo box start */}
        <div className="logo-box">
          <figure className="img-box">
            <img src={revvexLogo} alt="" className="img" />
          </figure>
        </div>
        {/* logo box end */}
        {/* list box start */}
        <div className="list-box">
          <p
            className="terms"
            onClick={() => window.open("https://revvex.io/terms-of-service")}
          >
            Terms of Service{" "}
          </p>
          <p
            className="privacy"
            onClick={() => window.open("https://revvex.io/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p className="copy">© 2022 Revvex. All rights reserved</p>
        </div>
        {/* list box end */}
      </div>
      {/* end of left box */}
      <div className="right-box">
        {/* content box start */}
        <div className="content-box">
          <p className="title">Welcome! let’s setup your organization</p>
          <p className="text">
            You can always modify your organization settings at any time via the
            admin setting .
          </p>

          {/* children wrap start */}
          <div className="wrap">{children}</div>
          {/* children wrap end */}
        </div>
        {/* content box end */}
      </div>
    </div>
  );
};

export default SetupLayout;
