import React, { useState } from "react";
import { Dna } from "react-loader-spinner";
// import Select from "react-select";
import "../../../../../../styles/dashboard/integration/modal/zohoModal.scss";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ZohoModal = ({
  onCancel,
  onConnect,
  handleChange,
  private_key,
  client_label,
  loader,
  // handlePasswordChange,
}) => {
  // const handlePasswordChange = (e) => {
  //   const value = e.target.value;
  //   setPassword(value);
  //   setDisplayPassword("*".repeat(value.length));
  // };

  const [showPassCode, setShowPassCode] = useState(false);
  // const [showPassCode, setShowPassCode] = useState(false);

  return (
    <div className="zoho-modal-wrap">
      <p className="zoho-title">Connect to Zoho Expense</p>
      <p className="zoho-text">
        The field below is required in order to Integrate Zoho Expense with your
        application
      </p>
      <div className="input-wrap">
        <div>
          <label htmlFor="private_key" className="label">
            Client ID <span className="span">*</span>
          </label>
          <input
            type="text"
            className={`input ${private_key && "input-active"}`}
            placeholder="Enter Your Key ID"
            name="private_key"
            id="private_key"
            value={private_key}
            onChange={handleChange}
          />
        </div>
        <div className="password-wrap">
          <label className="label">
            Client Secret <span className="span">*</span>
          </label>
          {!showPassCode && (
            <FaEye className="icon" onClick={() => setShowPassCode(true)} />
          )}
          {showPassCode && (
            <FaEyeSlash
              className="icon"
              onClick={() => setShowPassCode(false)}
            />
          )}
          <input
            type={showPassCode ? "text" : "password"}
            className={`input ${client_label && "input-active"}`}
            placeholder="**********"
            name="client_label"
            id="client_label"
            value={client_label}
            onChange={handleChange}
          />
        </div>
        <div className="btn-wrap">
          {/* <button className="cancel-btn" onClick={onCancel}>
            Cancel
          </button> */}
          <ButtonBlue
            onClick={() => {
              onCancel(setShowPassCode(false));
            }}
            text="Cancel"
            btnClassName="cancel-btn"
          />
          {loader ? (
            <div
              style={{
                alignSelf: "center",
                display: "flex",
                marginRight: "3rem",
              }}
            >
              <Dna
                visible={true}
                height="40"
                width="40"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            // <button className="connect-btn" onClick={onConnect}>
            //   Connect
            // </button>
            <ButtonBlue
              text={"Connect"}
              onClick={private_key && client_label && onConnect}
              bgColor={
                private_key && client_label ? "var(--blue-color)" : "#ccc"
              }
              style={{
                cursor: private_key && client_label ? "pointer" : "default",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ZohoModal;
