import React, { useContext, useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
// import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { getDepartmentsBySubsidiary } from "../../../../../../redux/department/deparment";
import {
  formatNumberWithoutComma,
  formatNumberWithoutDecimal,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { createExpenseLimit } from "../../../../../../redux/budget-setting/expense-limit/expenseLimit";
import { useEffect } from "react";

const AddExpenseLimitModal = ({ onCancel, onFinish }) => {
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { departmentDrop, loadingPage } = useSelector(
    (state) => state.department
  );
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  // const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.expense_limit);
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const [details, setDetails] = useState({
    organization_id: "",
    fiscal_year_id: "",
    subsidiary_id: "",
    department_id: "",
    limit: "",
  });

  // const categoryList = [
  //   { label: "Zojapay", value: 1 },
  //   { label: "ITH", value: 2 },
  //   { label: "Zojatech", value: 3 },
  // ];

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   const data = { ...details, [name]: value };
  //   setDetails(data);
  // };

  useEffect(() => {
    setDetails((prev) => {
      return { ...prev, subsidiary_id: "", department_id: "" };
    });
  }, [actionCtx?.activeOrg]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...details,
      organization_id: actionCtx?.activeOrg?.id,
      subsidiary_id: details?.subsidiary_id?.value,
      department_id: details?.department_id?.value,
      fiscal_year_id: details?.fiscal_year_id?.value,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(createExpenseLimit(obj));
    if (data?.payload?.success) {
      setDetails({
        organization_id: "",
        fiscal_year_id: "",
        subsidiary_id: "",
        department_id: "",
        limit: "",
      });
      onFinish();
    }
  };

  return (
    <>
      <CommonInputModal
        longContent
        onCancel={onCancel}
        title={`New Expense  Limit`}
        btnText={`Save`}
        loading={loading}
        btnActive={
          details?.fiscal_year_id &&
          details?.department_id &&
          details?.limit &&
          details?.subsidiary_id
        }
        onBtnClick={handleSubmit}
        btnDisable={
          !details?.fiscal_year_id ||
          !details?.department_id ||
          !details?.limit ||
          !details?.subsidiary_id
        }
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Fiscal Year <span>*</span>
          </label>
          <Select
            placeholder="Select fiscal year"
            className={`select-wrap ${
              details.fiscal_year_id && "select-wrap-active"
            }`}
            styles={reactSelectStyle}
            value={details.fiscal_year_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, fiscal_year_id: e };
              });
            }}
            options={fiscalYearDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Subsidiary <span>*</span>
          </label>
          <Select
            // placeholder="select subsidiary"
            className={`select-wrap ${
              details.subsidiary_id && "select-wrap-active"
            }`}
            placeholder="Select Subsidiary"
            value={details.subsidiary_id}
            onChange={(e) => {
              dispatch(getDepartmentsBySubsidiary(e.value));
              setDetails((prev) => {
                return { ...prev, subsidiary_id: e, department_id: "" };
              });
            }}
            styles={reactSelectStyle}
            options={subsidiariesDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Departments <span>*</span>
          </label>
          <Select
            // placeholder="select department"
            className={`select-wrap ${
              details.department_id && "select-wrap-active"
            }`}
            styles={reactSelectStyle}
            placeholder="choose department"
            value={details.department_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, department_id: e };
              });
            }}
            options={departmentDrop}
            isLoading={loadingPage}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Limit{" "}
            {actionCtx?.activeOrg?.active_fiscal_year?.base_currency?.name}{" "}
            <span>*</span>
          </label>
          <input
            name="limit"
            onChange={(e) =>
              !e.target.value.match(/[A-Z]+/g) &&
              !e.target.value.match(/[!@#$.%^&*_=+-]/g) &&
              !e.target.value.match(/[a-z]+/g) &&
              setDetails((prev) => {
                return {
                  ...prev,
                  limit: formatNumberWithoutComma(String(e.target.value)),
                };
              })
            }
            value={formatNumberWithoutDecimal(String(details.limit))}
            type="text"
            placeholder="enter expenditure limit"
            className={`input ${details.limit && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
      </CommonInputModal>
    </>
  );
};

export default AddExpenseLimitModal;
