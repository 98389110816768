import moment from "moment";
import React, { useContext, useState } from "react";
// import { useEffect } from "react";
// import { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ActionContext from "../../../context/ActionContext";
import {
  getNotifications,
  markAsRead,
  markAsReadAll,
} from "../../../redux/notifications/notifications";
// import ActionContext from "../../../context/ActionContext";
// import { getUserDetails } from "../../../redux/user";
// import { markAsRead } from "../../../redux/users/users";
import "./NotificationModal.css";

const NotificationModal = ({ onCancel }) => {
  const { notifications } = useSelector((state) => state.notifications);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingOne, setLoadingOne] = useState("");
  const [loadingTwo, setLoadingTwo] = useState(false);
  const marKasReadNotify = async (id) => {
    setLoadingOne(id);
    const data = await dispatch(markAsRead(id));

    if (data?.payload?.success) {
      dispatch(getNotifications());
      setLoadingOne("");
    } else {
      setLoadingOne("");
    }
  };

  const marKasReadNotifyAll = async () => {
    setLoadingTwo(true);
    const data = await dispatch(markAsReadAll());
    // console.log(data);
    if (
      data?.payload?.message === "Notifications marked as read"
    ) {
      dispatch(getNotifications());
      setLoadingTwo(false);
      actionCtx?.setShowNotification(false);
    }

    if (data?.payload?.success) {
      dispatch(getNotifications());
      setLoadingTwo(false);
      actionCtx?.setShowNotification(false);
    } else {
      setLoadingTwo(false);
    }
  };

  const newList = (list) => {
    const val = list?.filter((chi) => !chi?.read_at);
    // console.log(notifications);
    return val;
  };

  const extractLink = (param) => {
    if (param) {
      const msgClean = param?.split("<a href='")[0];
      const linkTag = param?.split("<a href='")[1];
      const linkStringVal = linkTag?.split("'>here</a>")[0];
      const refValOne = linkStringVal?.split("?ref=")[1];
      const refValTwo = refValOne?.split("==' target='_blank")[0];
      const obj = {
        refExist: refValTwo ? true : false,
        ref: refValTwo,
        msg: refValTwo ? msgClean : param,
        msgTwo: refValTwo && msgClean?.replace("here", ""),
      };
      return obj;
    }
    // return param;
  };

  return (
    <div
      onClick={() => {
        // console.log(notifications);
      }}
      className="notification-modal-wrap"
    >
      <div className="trans-box"></div>
      <div className="content-wrap">
        <div className="title-wrap">
          <p className="page-title">All Notifications</p>
          {newList(notifications)?.length > 0 && (
            <div className="mark-all-as-read">
              {loadingTwo ? (
                <>
                  {" "}
                  <div style={{ alignSelf: "flex-end" }}>
                    <Dna
                      visible={true}
                      height="30"
                      width="40"
                      ariaLabel="dna-loading"
                      // wrapperStyle={{color: "red", backgroundColor : "red"}}
                      wrapperClass="dna-wrapper"
                    />
                  </div>
                  s
                </>
              ) : (
                <>
                  <span onClick={marKasReadNotifyAll}>Mark all as read</span>
                </>
              )}
            </div>
          )}
        </div>
        <span
          onClick={() => {
            actionCtx?.setShowNotification(false);
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </span>
        {/* notify wrap start */}
        <div className="notify-wrap">
          {/* message box start */}
          {/* message box end */}

          {newList(notifications)?.length < 1 && (
            <div className="no-notification">
              <p>No new notifications! check back later</p>
            </div>
          )}
          {/* {newList(notifications)} */}
          {newList(notifications)?.length > 0 &&
            newList(notifications)
              ?.slice(0, 10)
              ?.map((chi, idx) => {
                // console.log(chi);
                return (
                  <div
                    key={idx}
                    className={`message-box ${
                      chi?.read_at && "message-box-inactive"
                    }`}
                  >
                    <p className="message-title">
                      {chi?.data?.title || chi?.data?.message?.title}
                    </p>{" "}
                    <p className="message-text">
                      {extractLink(chi?.data?.message?.body)?.refExist ? (
                        <>
                          {" "}
                          {extractLink(chi?.data?.message?.body)?.msgTwo}
                          <span
                            style={{
                              color: `var(--blue-color)`,
                              cursor: "pointer",
                              fontWeight: "600",
                            }}
                            onClick={() => {
                              actionCtx?.setShowNotification(false);
                              history.push(
                                `/dashboard-single-view-for-approval?ref="${extractLink(chi?.data?.message?.body)?.ref}"`
                              );
                            }}
                          >
                            {" "}
                            HERE
                          </span>
                        </>
                      ) : (
                        extractLink(chi?.data?.message?.body)?.msg ||
                        extractLink(chi?.data?.message)?.msg ||
                        "---"
                      )}
                    </p>
                    <div className="messagebox-footer">
                      <p className="date">
                        {moment(chi?.created_at).fromNow()}
                      </p>
                      {!chi?.read_at && (
                        <>
                          {loadingOne === chi?.id ? (
                            <>
                              <div style={{ alignSelf: "flex-end" }}>
                                <Dna
                                  visible={true}
                                  height="30"
                                  width="40"
                                  ariaLabel="dna-loading"
                                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                                  wrapperClass="dna-wrapper"
                                />
                              </div>
                            </>
                          ) : (
                            <span
                              onClick={() => {
                                marKasReadNotify(chi?.id);
                              }}
                              className={`read ${
                                !chi?.read_at && "read-active"
                              }`}
                              // style={{backgroundColor: chi?.read_at && "red"}}
                            >
                              Mark as read
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
        {/* notify wrap end */}
      </div>
    </div>
  );
};

export default NotificationModal;
