import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/airbnb.css";
import Select from "react-select";
import { formatNumberWithoutComma, reactSelectStyle } from "../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../context/ActionContext";
import { Dna } from "react-loader-spinner";
import { createFiscalYear } from "../../../../../redux/fiscal-year/fiscalYear";
import { getCurriencies } from "../../../../../redux/currency/currency";
import { NumericFormat } from "react-number-format";
import { useThemeDetector } from "../../../../../utils/getThemeColor";
// require(`flatpickr/dist/themes/${
//     color.split("-")[1] === "dark" ? "dark" : "airbnb"
//   }.css`);


const AddFiscalYear = () => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  // const [refreshTable, setRefreshTable] = useState(false);
  // const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.fiscal_year);
  const { currencyDrop } = useSelector((state) => state.currency);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    organization_id: "",
    title: "",
    start_date: "",
    end_date: "",
    base_currency_id: "",
    other_currency_id: "",
    rate: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...details,
      rate: formatNumberWithoutComma(details?.rate),
      base_currency_id: details?.base_currency_id?.value,
      other_currency_id: details?.other_currency_id?.value,
      organization_id: actionCtx?.activeOrg?.id,
    };


    const data = await dispatch(createFiscalYear(obj));
    if (data?.payload?.success) {
      setDetails({
        organization_id: "",
        title: "",
        start_date: "",
        end_date: "",
        base_currency_id: "",
        other_currency_id: "",
        rate: "",
      });
      history.push("/dashboard-administration-admin-settings-fiscal-year")
    }
  };

  useEffect(() => {

    
    dispatch(getCurriencies());
    // dispatch(getCurrienciesConversion(obj))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { preference } = useSelector((state) => state.preference);
  const isDarkTheme = useThemeDetector();
  require(`flatpickr/dist/themes/${
    preference?.display === "Light"
                ? "airbnb"
                : preference?.display === "Dark"
                ? "dark"
                : isDarkTheme
                ? "dark"
                : "airbnb"
  }.css`);

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 3 of 6`}
        pageTitle={`Admin Settings`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-fiscal-year"
              )
            }
            className="text"
          >
            Fiscal Year
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Add Fiscal Year</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Fiscal Year</p>
            <p className="text">
              Set your organization fiscal year to keep track of your financial
              data.
            </p>
          </div>
          {/* text tititl box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1.Financial Information</p>
              <div className="form-wrap form-wrap-six">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Name" className="label">
                    Title <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Eg. FY 2021"
                    className={`input ${details.title && "input-active"}`}
                    id="Name"
                    name="title"
                    onChange={handleChange}
                    value={details.title}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Start Date <span>*</span>
                  </label>
                  <Flatpickr
                    id="start_date"
                    name="start_date"
                    // onKeyUp={onKeyUp}
                    // onSelect={() =>
                    //   showErrorSeven &&
                    //   dispatch(showErrorActions("show-error-seven", "", false))
                    // }
                    className="form-input-calendar"
                    autoComplete="off"
                    value={details?.start_date}
                    onChange={(date) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          start_date: moment(date[0]).format("YYYY/MM/DD"),
                        };
                      });
                    }}
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="state" className="label">
                    End Date <span>*</span>
                  </label>
                  <Flatpickr
                    id="end_date"
                    name="end_date"
                    // onKeyUp={onKeyUp}
                    // onSelect={() =>
                    //   showErrorSeven &&
                    //   dispatch(showErrorActions("show-error-seven", "", false))
                    // }
                    className="form-input-calendar"
                    autoComplete="off"
                    value={details?.end_date}
                    onChange={(date) =>
                      setDetails((prev) => {
                        return {
                          ...prev,
                          end_date: moment(date[0]).format("YYYY/MM/DD"),
                        };
                      })
                    }
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Address" className="label">
                    Base Currency <span>*</span>
                  </label>
                  <Select
                    styles={reactSelectStyle}
                    className="select"
                    value={details.base_currency_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, base_currency_id: e };
                      });
                    }}
                    options={currencyDrop}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="state" className="label">
                    Other Currency <span>*</span>
                  </label>
                  <Select
                    className="select "
                    styles={reactSelectStyle}
                    value={details.other_currency_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, other_currency_id: e };
                      });
                    }}
                    options={currencyDrop}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Address" className="label">
                    Set Currency Rate
                  </label>
                  <NumericFormat
                   className={`input ${details.rate && "input-active"}`}
                  decimalScale={3}
                  decimalSeparator="."
                  type="text"
                  name="rate"
                  thousandsGroupStyle={"lakh"}
                  allowNegative
                  onChange={handleChange}
                  thousandSeparator={","}
                  value={details.rate}
                  placeholder="Eg. 355"
                />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            {/* <div className="note-img-wrap">
                Important Notice:
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
              </div> */}
            {/* note box start */}
            {/* <div className="note-box">
                <p>
                  1. For organizations with shared budgets, you would need to
                  have created at least two subsidiaries to set up a shared
                  services
                </p>
              </div> */}
            {/* note box end */}

            {/* btn box start */}
            <div className="btn-box-wrap">
              {!loading ? (
                <input
                  disabled={
                    !details?.title ||
                    !details?.base_currency_id ||
                    !details?.end_date ||
                    !details?.other_currency_id ||
                    !details?.start_date ||
                    !details?.rate
                  }
                  type="submit"
                  value="Save Fiscal Year"
                  className={`form-group form-submit ${
                    details?.title &&
                    details?.base_currency_id &&
                    details?.end_date &&
                    details?.other_currency_id &&
                    details?.start_date &&
                    details?.rate &&
                    "form-submit-active"
                  }`}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
            </div>
            {/* btn box end */}
          </form>
        </div>
        {/* form box end */}
        {/* skip text btn box start*/}
        {/* <div className="skip-box">
            <p className="text">
              If you do not have a registered branch under your organization,
              click to next the process
            </p>
            <button className="btn">Skip this</button>
          </div> */}
        {/* skip text btn box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default AddFiscalYear;
