import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import Modal from "react-awesome-modal";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../context/ActionContext";
import {
  getDepartmentsByFilter,
  getDepartmentsPagination,
  getDepartmentsWithPagination,
} from "../../../../../redux/department/deparment";
import useDebounce from "../../../../../helper/UseDebounce";
import DeleteDepartmentModal from "./DeleteDepartMentModal";

const Department = () => {
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const actionCtx = useContext(ActionContext);
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const { departments, meta, links, loadingPage } = useSelector(
    (state) => state.department
  );
  const dispatch = useDispatch();
  const [perPageval ] = useState({
    label: "5",
    value: "5",
  });
  const [indexNo, setIndexNo] = useState("");
  const tableHeadList = [
    "Dept Name",
    "Subsidiary",
    "Branch",
    "Status",
    "Users",
    "Action",
  ];
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "Name", value: "name" },
    { label: "Subsidiary", value: "subsidiary" },
    { label: "Branch", value: "branch" },
    { label: "Status", value: "status" },
    { label: "Hod", value: "hod" },
    // { label: "Parent Company", value: "Parent Company" },
  ];

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      // dispatch(getBranches(actionCtx?.activeOrg?.id));
      dispatch(getDepartmentsWithPagination(obj));
    }
    // eslint-disable-next-line
  }, [refreshTable]);

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getDepartmentsPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    user?.organizations?.length > 0 &&
      dispatch(getDepartmentsWithPagination(obj));
  };

  const handleFinish = () => {
    setSingleChild({});
    setRefreshTable(!refreshTable);
    setShowDeleteModal(false);
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: filterByVal?.value || "",
        org_id: actionCtx?.activeOrg?.id,
      };
      dispatch(getDepartmentsByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm, refreshTable]);

  const handleUpdate = (id) => {
    localStorage.setItem("department_id", id);
    history.push("/dashboard-administration-admin-settings-edit-department");
  };

  const handleView = (id) => {
    localStorage.setItem("department_id", id);
    history.push("/dashboard-administration-admin-settings-view-department");
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        pageTitleSpan={`Step 4 of 6`}
        pageTitle={`Add Department`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-subsidaries"
              )
            }
            className="active text"
          >
            Department
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>

        {/* table box start */}
        {/* <ReUseableTable
            table_title={`Department`}
            table_head_list={tableHeadList}
            table_title_span={`(1)`}
            table_body_list={tableBodyList}
            btn_text="Department"
          /> */}
        {/* table box end */}

        {/* table box start */}
        <ActualReuseableTable
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          perPageValue={perPageval}
          setPerPageValue={(e) => {
            // setPerpageVal(e);
            handleSortPerPage(e.value);
          }}
          fromPage={meta?.from || 1}
          toPage={meta?.to || departments?.length}
          totalPage={meta?.total || departments?.length}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          tableTitle={`Department `}
          addBtntext={`New Dept.`}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          action
          onCreateNew={() =>
            history.push(
              "/dashboard-administration-admin-settings-add-department"
            )
          }
          loading={loadingPage && departments?.length < 1}
          loadingNum={6}
        >
          {departments?.map((chi, idx) => {
            const {
              name,
              branch,
              is_active,
              id,
              users_with_hod,
            } = chi;
            const obj = users_with_hod.map((chi) => {
              return {
                first_name: chi?.first_name,
                last_name: chi?.last_name,
                img: chi?.profile_picture,
              };
            });
            return (
              <TableBodyRow
                loading={loadingPage}
                key={idx}
                one={name}
                two={branch?.subsidiary?.name}
                three={branch?.name}
                status={is_active ? "Active" : "inactive"}
                //   ten={hod || <>&nbsp;</>}
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                imageData={obj || <>&nbsp;</>}
                // imgDataName={obj}
                action
                updateText={`edit department`}
                viewText={`view department`}
                onView={() => handleView(id)}
                onUpdate={() => handleUpdate(id)}
                deleteText={`delete department`}
                onDelete={() => {
                  setSingleChild(chi);
                  setShowDeleteModal(true);
                }}
                bigActionPop
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
        {/* btn box start */}
        {actionCtx?.activeOrg?.check_list?.percentage !== 100 && (
          <div className="btn-box-wrap">
            <button
              onClick={() =>
                history.push(
                  "/dashboard-administration-admin-settings-branch"
                )
              }
              className="btn-reuse"
            >
              Go to previous step
            </button>
            <button
              onClick={() => {
                history.push(
                  "/dashboard-administration-admin-settings-add-employee"
                );
              }}
              className="btn-reuse btn-two"
            >
              Go to the next step
            </button>
          </div>
        )}
        {/* btn box end */}
      </div>
      {/* </DashboardLayout> */}
      {/* modal start  */}
      <Modal visible={showDeleteModal} effect="fadeInUp">
        <DeleteDepartmentModal
          details={singleChild}
          onFinish={handleFinish}
          onClose={() => setShowDeleteModal(false)}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default Department;
