import React from "react";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import "../../../../../../styles/dashboard/modals/DeleteSharedmodal.css";
import { Dna } from "react-loader-spinner";
// import ButtonBlue from "../../../../buttons/ButtonBlue";

const CommonDeleteModal = ({ onClose, title, text, onDelete, loading }) => {
  return (
    <div className="delete-shared-modal-wrap">
      <p className="title">{title}</p>
      <p className="text">{text}</p>
      {/* <p className="text text-two">
        organizatiuon budget settings. Do you still want to delete?
      </p> */}

      <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        {!loading ? (
          <ButtonBlue
            onClick={onDelete}
            bgColor={`#CC0905`}
            text={`Yes Delete`}
          />
        ) : (
          <div
            className="dna-wrapper-box"
            style={{ alignSelf: "flex-start", marginLeft: "2rem" }}
          >
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonDeleteModal;
