import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight, FaCheck, FaPlus } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import noteIcon from "../../../../../assets/note-icon.svg";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGroup } from "../../../../../redux/group/group";
import { getDepartments } from "../../../../../redux/department/deparment";
import { getUsers } from "../../../../../redux/users/users";
import ActionContext from "../../../../../context/ActionContext";
import { Dna } from "react-loader-spinner";
import { reactSelectStyle } from "../../../../../helper/Helpers";

const EditGroup = () => {
  const history = useHistory();
  // const [selectOne, setSelectOne] = useState(null);
  const [groupSingle, setGroupSingle] = useState({});
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  const { usersDrop } = useSelector((state) => state.users);
  const { departmentDrop, loadingPage } = useSelector(
    (state) => state.department
  );
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.group);
  const [details, setDetails] = useState({
    organization_id: "",
    name: "",
    description: "",
    department_ids: [],
    head_of_group_id: "",
    submit_to_id: "",
    should_notify_members: false,
    should_be_viewable_by_all_members: false,
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    const dataTwo = { ...groupSingle, [name]: value };
    setGroupSingle(dataTwo);
  };

  useEffect(() => {
    getGroupInfo();
    if (user?.organizations?.length > 0) {
      dispatch(getDepartments(actionCtx.activeOrg?.id));
      dispatch(getUsers(actionCtx?.activeOrg?.id));
    }
      // eslint-disable-next-line
  }, []);

  const getGroupInfo = async () => {
    const id = localStorage.getItem("group_id");
    const data = await dispatch(getGroup(id));
    if (data?.payload?.success) {
      const group = data?.payload?.data?.group;
      // console.log(group);
      const deptOpt = group?.departments?.map((chi) => {
        return { label: chi?.name, value: chi?.id };
      });
      const submitOpt = {
        label: group?.submit_to?.full_name,
        value: group?.submit_to?.id,
      };
      const headOfGroupOpt = {
        label: group?.head_of_group?.full_name,
        value: group?.head_of_group?.id,
      };
      const obj = {
        ...group,
        departments: deptOpt,
        submit_to: submitOpt,
        head_of_group: headOfGroupOpt,
      };
      setGroupSingle(obj);
    }
  };



  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 2 of 6`}
        pageTitle={`Edit Group`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings-group")
            }
            className="text"
          >
            Groups
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Edit Groups</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Edit Groups</p>
            <p className="text">
              Keep under control the extension of the user’s account management.
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          <div className="btn-download-box">
            <button
              className={`btn-bulk ${
                details.Subsidiary_Address ||
                details.Subsidiary_Name ||
                details.Subsidiary_of ||
                details.country
                  ? "btn-bulk-inactive"
                  : ""
              }`}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div>
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // handleSubmit();
            }}
            action=""
            className="form"
          >
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1. Group Information</p>
              <div className="form-wrap" style={{ gridTemplateRows: "unset" }}>
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary Name" className="label">
                    Group name <span>*</span>
                  </label>
                  <input
                    type="text"
                    // placeholder="IT Horizon Holdings"
                    className={`input ${details.name && "input-active"}`}
                    id="Subsidiary Name"
                    name="name"
                    onChange={handleChange}
                    value={groupSingle?.name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Country" className="label">
                    Edit department <span>*</span>
                  </label>
                  <Select
                    isMulti
                    styles={reactSelectStyle}
                    isLoading={loadingPage}
                    className="select "
                    // placeholder="Sync with system settings"
                    value={groupSingle?.departments}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, departments_ids: e };
                      });
                      setGroupSingle((prev) => {
                        return { ...prev, departments: e };
                      });
                    }}
                    options={departmentDrop}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div
                  className="form-group"
                  style={{ gridColumn: "1 / span 2" }}
                >
                  <label htmlFor="Subsidiary Address" className="label">
                    Subsidiary Address <span>*</span>
                  </label>
                  <textarea
                    className={`input ${details.description && "input-active"}`}
                    name="description"
                    id=""
                    value={groupSingle?.description}
                    cols="30"
                    rows="10"
                    style={{ resize: "none" }}
                    onChange={handleChange}
                  ></textarea>
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}

            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">2. Group Permission</p>
              <div className="form-wrap" style={{ gridTemplateRows: "unset" }}>
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary Name" className="label">
                    Set head of group <span>*</span>
                  </label>
                  <Select
                    // isMulti
                    styles={reactSelectStyle}
                    // isLoading={loadingPage}
                    className="select "
                    placeholder=""
                    value={groupSingle?.head_of_group}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, head_of_group_id: e };
                      });
                      setGroupSingle((prev) => {
                        return { ...prev, head_of_group: e };
                      });
                    }}
                    options={usersDrop}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Country" className="label">
                    Submit to <span>*</span>
                  </label>
                  <Select
                    // isMulti
                    styles={reactSelectStyle}
                    // isLoading={loadingPage}
                    className="select "
                    placeholder="Select submit to"
                    value={groupSingle?.submit_to}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, submit_to_id: e };
                      });
                      setGroupSingle((prev) => {
                        return { ...prev, submit_to: e };
                      });
                    }}
                    options={usersDrop}
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            {/* check start */}
            <div className="check-label-box">
              <input
                checked={
                  groupSingle?.should_notify_members === 1
                    ? true
                    : groupSingle?.should_notify_members === 0
                    ? false
                    : groupSingle?.should_notify_members
                }
                // checked={details?.should_notify_members}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, should_notify_members: e.target.checked };
                  });
                  setGroupSingle((prev) => {
                    return { ...prev, should_notify_members: e.target.checked };
                  });
                }}
                type="checkbox"
                className="check"
                id="check-org-notify"
              />
              <label htmlFor="check-org-notify" className="label">
                <span>
                  <FaCheck className="icon" />
                </span>
                Notify all members or users in each department{" "}
              </label>
            </div>
            {/* check end */}
            {/* check start */}
            <div className="check-label-box">
              <input
                type="checkbox"
                className="check"
                checked={
                  groupSingle?.should_be_viewable_by_all_members === 1
                    ? true
                    : groupSingle?.should_be_viewable_by_all_members === 0
                    ? false
                    : groupSingle?.should_be_viewable_by_all_members
                }
                value={details?.should_be_viewable_by_all_members}
                id="check-org-view-mem"
                onChange={(e) => {
                  setDetails((prev) => {
                    return {
                      ...prev,
                      should_be_viewable_by_all_members: e.target.checked,
                    };
                  });
                  setGroupSingle((prev) => {
                    return {
                      ...prev,
                      should_be_viewable_by_all_members: e.target.checked,
                    };
                  });
                }}
              />
              <label htmlFor="check-org-view-mem" className="label">
                <span>
                  <FaCheck className="icon" />
                </span>
                Viewable by all members of the departments{" "}
              </label>
            </div>
            {/* check end */}
            {/* btn box start */}
            {/* <div className="btn-box-wrap"> */}
            {/* <button
                //   onClick={() =>
                //     history.push(
                //       "/dashboard-administration-admin-settings-admin-invite"
                //     )
                //   }
                className="btn-reuse"
              >
                Cancel
              </button> */}
            {/* <button className="btn-reuse btn-two">Save Group</button> */}
            {!loading ? (
              <input
                disabled={
                  !details?.department_ids &&
                  !details?.description &&
                  !details?.head_of_group_id &&
                  !details?.name &&
                  !details?.submit_to_id &&
                  !details?.should_be_viewable_by_all_members &&
                  !details?.should_notify_members
                }
                type="submit"
                value="Update Group"
                className={`form-group btn-reuse form-submit ${
                  details?.description ||
                  details?.head_of_group_id ||
                  details?.name ||
                  details?.department_ids ||
                  details?.submit_to_id ||
                  details?.should_be_viewable_by_all_members ||
                  details?.should_notify_member
                    ? "form-submit-active"
                    : ""
                }`}
                style={{ marginTop: "3rem" }}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "3rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* </div> */}
            {/* btn box end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default EditGroup;
