import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SetupLayout from "../../layout/dashboard/SetupLayout";
import GroupImg from "../../../assets/group-house-icon.svg";
import singleImg from "../../../assets/single-house-icon.svg";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";

const list = [
  {
    name: "Group Organization",
    text: "Has multiple subsidiaries and branches.",
    img: GroupImg,
    value: "group",
  },
  {
    name: "Single Organization",
    text: "Has multiple branches and no subsidiaries",
    img: singleImg,
    value: "single",
  },
];

const SetupOrganizationSelect = () => {
  const history = useHistory();
  const [orgType, setOrgType] = useState("");
  const { plan_details } = useSelector((state) => state.users);
  // const [orgTypeVal, setOrgTypeVal] = useState(localStorage.getItem("organization_type_id") || "");

  const handleSetOrgTypeVal = (val) => {
    // console.log(val);
    // setOrgTypeVal(val)
    localStorage.setItem("organization_type_id", val);
  };

  useEffect(() => {
    const orgId = localStorage.getItem("organization_type_id") || "";
    // console.log(orgId);
    if (orgId && orgId === "group") {
      setOrgType("Group Organization");
    }
    if (orgId && orgId === "single") {
      setOrgType("Single Organization");
    }
  }, []);

  return (
    <>
      <SetupLayout>
        <div className="select-org-wrap">
          <p className="title">Select organization type</p>

          {/* select box start */}
          {list.map((child, idx) => {
            const { name, text, img, value } = child;
            return (
              <div
                key={idx}
                className={`child ${orgType === name && "child-active"}`}
                onClick={() => {
                  if (
                    name === "Group Organization" &&
                    !plan_details?.privilages?.organization_type
                  ) {
                    return;
                  }
                  handleSetOrgTypeVal(value);
                  setOrgType(name);
                }}
                style={{
                  cursor:
                    name === "Group Organization" &&
                    plan_details?.privilages?.organization_type !== "group organization" &&
                    "not-allowed",
                }}
              >
                <figure className="img-box">
                  <img src={img} alt="" className="img" />
                </figure>
                <div className="text-box">
                  <p className="name">{name}</p>
                  <p className="text">{text}</p>
                </div>
                {/* check box start */}
                <div className="check-box">
                  <FaCheck
                    className={`icon ${orgType === name && "icon-active"}`}
                  />
                </div>
                {/* check box end */}
              </div>
            );
          })}
          {/* select box end */}
          {/* button start */}
          <button
            onClick={() => {
              // console.log(plan_details);
              orgType && history.push("/setup-org-setting");
            }}
            className={`btn-set-up ${orgType && "btn-set-up-active"}`}
          >
            Start Setup
          </button>
          {/* button end */}
        </div>
      </SetupLayout>
    </>
  );
};

export default SetupOrganizationSelect;
