import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getApprovalClass } from "../../../../../redux/approvals";
import "../../../../../styles/dashboard/admin-settings/CommonLinksApprovalFlow.css";

const CommonLinks = () => {
  const history = useHistory();
  // const location = useLocation();
  const {approvalClass} = useSelector((state) => state.approvals)
 const [activeId, setActiveId] = useState(localStorage.getItem("approval-select-class-id") || 1)
// const active_id = localStorage.getItem("approval-select-class-id") || 1

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getApprovalClass());
    // dispatch(getApprovalType());   
      // eslint-disable-next-line
  }, []);

  const handleClickItem = (param) => {
   const id = param?.id
   setActiveId(id)
   localStorage.setItem("approval-select-class-id", id);
   if(id === 1){
    history.push("/dashboard-general-approvals-revenue-projection")
   }
   if(id === 2){
    history.push("/dashboard-general-approvals-actual-expense")
   }
   if(id === 3){
    history.push("/dashboard-general-approvals-expenditure-projection")
   }
   if(id === 4){
    history.push("/dashboard-general-approvals-actual-revenue")
   }
   if(id === 5){
    history.push("/dashboard-general-approvals-supplementary-budget")
   }
  }

  return (
    <div className="common-link-approval-flow-wrap">
      {approvalClass.map((chi, idx) => {
        const { name, id } = chi;
        return (
          <div
            onClick={() => {handleClickItem(chi);}}
            key={idx}
            className={`item ${Number(activeId) === id && "item-active"}`}
          >
            {
              <span
                className={`span ${Number(activeId) === id && "span-active"} ${
                  Number(activeId) === id && "span-active"
                }`}
              ></span>
            }
            <p>{name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default CommonLinks;
