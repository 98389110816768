import React from "react";
import CommonDeleteModal from "./CommonDeleteModal";

const DeleteBulkUploadTemplateModal = ({ onClose }) => {
  return (
    <>
      <CommonDeleteModal
        title={`Delete Template`}
        text={`Are you sure you want to delete this information. Deleting this, would affect your 
organizatiuon budget settings. Do you still want to delete?`}
        onClose={onClose}
      />
    </>
  );
};

export default DeleteBulkUploadTemplateModal;
