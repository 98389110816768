import React, { useContext, useEffect, useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { createChatOfAccount } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import { getCategoriesByClass } from "../../../../../../redux/budget-setting/expenditure-projection/categories";

const AddBudgetExpAccountModal = ({ onCancel, onFinish }) => {
  const { classDrop } = useSelector((state) => state.classes);
  const { categoryDrop, loadingPage } = useSelector(
    (state) => state.categories
  );
  const { loading } = useSelector((state) => state.chatOfAccount);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    organization_id: "",
    projection_class_id: "",
    projection_category_id: "",
    name: "",
    code: "",
    description: "",
    should_spread_budget_item: false,
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...details,
      organization_id: actionCtx?.activeOrg?.id,
      projection_class_id: details?.projection_class_id?.value,
      projection_category_id: details?.projection_category_id?.value,
      should_spread_budget_item: details?.should_spread_budget_item || false,
    };
    const data = await dispatch(createChatOfAccount(obj));
    if (data?.payload?.success) {
      onFinish();
      setDetails({
        organization_id: "",
        projection_class_id: "",
        projection_category_id: "",
        name: "",
        code: "",
        description: "",
        should_spread_budget_item: "",
      });
    }
  };

  useEffect(() => {
    setDetails((prev) => {
      return {...prev, projection_category_id: {}, projection_class_id: {}}
    })

  }, [actionCtx?.activeOrg])

  return (
    <>
      <CommonInputModal
        loading={loading}
        btnActive={
          details?.description &&
          details?.name &&
          details?.projection_class_id &&
          // details?.code &&
          details?.projection_category_id
        }
        onBtnClick={handleSubmit}
        btnDisable={
          !details?.description ||
          !details?.name ||
          !details?.projection_class_id ||
          !details?.projection_category_id 
          // !details?.code
        }
        longContent
        onCancel={onCancel}
        title={`New Chart Of Account`}
        btnText={`Save`}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={details.name}
            type="text"
            placeholder="Name expense category"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Code
          </label>
          <input
            name="code"
            onChange={handleChange}
            value={details.code}
            type="text"
            placeholder="enter account code"
            className={`input ${details.code && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Class <span>*</span>
          </label>
          <Select
            placeholder="Select expense class"
            className="select "
            value={details.projection_class_id}
            onChange={(e) => {
              dispatch(getCategoriesByClass(e.value));
              setDetails((prev) => {
                return { ...prev, projection_class_id: e };
              });
            }}
            styles={reactSelectStyle}
            options={classDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Category <span>*</span>
          </label>
          <Select
            isLoading={loadingPage}
            placeholder="Select expense category"
            className="select "
            value={details.projection_category_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, projection_category_id: e };
              });
            }}
            styles={reactSelectStyle}
            options={categoryDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
            value={details.description}
            type="text"
            placeholder="describe your expenditure class"
          ></textarea>
        </div>
        {/* group box end */}
        {/* check start */}
        <div className="check-label-box">
          <input
            onChange={(e) =>
              setDetails((prev) => {
                return { ...prev, should_spread_budget_item: e.target.checked };
              })
            }
            value={details?.should_spread_budget_item}
            type="checkbox"
            className="check"
            id="check-email"
          />
          <label htmlFor="check-email" className="label">
            <span>
              <FaCheck className="icon" />
            </span>
            Spread budget item <div className="blue">(chart of account)</div>{" "}
            evenly across months{" "}
          </label>
        </div>
        {/* check end */}
      </CommonInputModal>
    </>
  );
};

export default AddBudgetExpAccountModal;
