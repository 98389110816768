import React, { useState, useEffect } from "react";
import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
import "../../../styles/auth/AuthLayout.css";
import checkMailImg from "../../../assets/check-mail-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { resendverifyUserEmail } from "../../../redux/user";
// import { toast } from "react-toastify";
import { Dna } from "react-loader-spinner";

const CheckMail = () => {
  const dispatch = useDispatch();
  const { userEmail, user, loading } = useSelector((state) => state.user);
  const history = useHistory();
  const resendOtp = async () => {
    const data = await dispatch(resendverifyUserEmail());
    if (data.payload.status) {
      // toast.success(data.payload.message, {
      //   theme: "colored",
      // });
    }
  };

  // grab the email from the url
  // const [emailUser, setEmailUser] = useState("");
  // const [token, setToken] = useState("");
  const [details, setDetails] = useState({
    email: "",
    token: "",
  });
  const handleSplitUrl = () => {
    const url = window.location.href;
    const splitUrlForEmail = url?.split("?hash=");
    const splitValueForEmail = splitUrlForEmail[1];
    const splitValueForEmailTwo = splitValueForEmail.split("&key=");
    const newSplitValueForEmail = splitValueForEmailTwo[0];
    const splitUrlForToken = url?.split("&key=");
    const splitValueForToken = splitUrlForToken[1];
    setDetails((prev) => {
      return {
        ...prev,
        email: newSplitValueForEmail,
        token: splitValueForToken,
      };
    });
    // localStorage.setItem("token", details.token);
    // localStorage.setItem("email-to-receive-otp", details.email);
  };

  useEffect(() => {
    if (window.location.href?.includes("?hash=")) {
      handleSplitUrl();
    } else {
      return;
    }
  }, []);

  return (
    <>
      <AuthLayout>
        {/* check mail wrap start */}
        <div className="check-mail-wrap">
          <figure className="img-box">
            <img src={checkMailImg} alt="" className="img" />
          </figure>
          <p className="title">Check your mailbox !</p>
          <p className="small-text">
            We’ve sent an email to {details.email} with a
            {/* We’ve sent an email to {userEmail || user?.email} with a */}
          </p>
          <p className="small-text">
            an OTP to confirm your account. Check your inbox
          </p>
          <p className="small-text">to activate your account.</p>

          {/* button start */}
          {!loading ? (
            <button
              className="btn"
              onClick={() => {
                localStorage.setItem("token", details.token);
                localStorage.setItem("email-to-receive-otp", details.email);
                // history.push("/verify-email");
                window.location.replace("/verify-email");
              }}
            >
              Confirm Email
            </button>
          ) : (
            <div style={{ alignSelf: "flex-start" }}>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
          {/* button end */}
          {/* already text start */}
          <div className="already-box">
            <p className="text">
              Didn’t get the mail? <span onClick={resendOtp}>Resend</span>
            </p>
          </div>
          {/* already text end */}
        </div>
        {/* check mail wrap end */}
      </AuthLayout>
    </>
  );
};

export default CheckMail;
