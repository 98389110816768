import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import ActionContext from "../../context/ActionContext";
import { reactSelectStyleCommonSort } from "../../helper/Helpers";
import { getBranches, getBranchesBySubsidiary } from "../../redux/branch/branch";
// import { getBranchesBySubsidiary } from "../../redux/approvals";
import { getDepartmentsBySubsidiary } from "../../redux/department/deparment";
import { getFiscalYears } from "../../redux/fiscal-year/fiscalYear";
import { getSubsidiaries } from "../../redux/subsidiary/subsidiary";
import "../../styles/dashboard/CommonSortBox.css";

const CommonSortBox = ({ onSub, onDept, onBra, onDate, onSearch, onFis, onReset, subVal, deptVal, searchval, dateval, fisVal, braval }) => {
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { branchSubDrop, loadingPage, branchDrop } = useSelector((state) => state.branch);
  const { departmentDrop } = useSelector((state) => state.department);
  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const location = useLocation()

  useEffect(() => {
    const id = actionCtx?.activeOrg?.id;
    if (user?.organizations?.length > 0) {
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      dispatch(getFiscalYears(id));
      dispatch(getBranches(actionCtx?.activeOrg?.id));
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.activeOrg]);


  // 1month,2month,3month,4month,5month,6month,7month,8month,9month,10month,11month,1year

  const periodOptions = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
    {label: "All", value: ""},
  ];

  return (
    <div className="common-sort-box-wrap" style={{position: "relative", zIndex: 100}}>
      {/* select fiscal year wrap start */}
      <div className="select-wrap">
        <label className="label" htmlFor="">
          Fiscal Year
        </label>
        <Select
          styles={reactSelectStyleCommonSort}
          // isSearchable={true}
          // isMulti
          placeholder="Select Year"
          className="select"
          value={fisVal}
          onChange={(e) => {
            onFis && onFis(e);
            // const obj = { ...actionCtx?.sortOptions, fis_id: e };
            // actionCtx?.setSortOptions(obj);
          }}
          options={fiscalYearDrop}
        />
      </div>
      {/* select fiscal year wrap end */}
      {/* select Date wrap start */}
      <div className="select-wrap">
        <label className="label" htmlFor="">
          Month
        </label>
        <Select
          styles={reactSelectStyleCommonSort}
          // isSearchable={true}
          // isMulti
          placeholder="Select month"
          className="select"
          value={dateval}
          onChange={(e) => {
            onDate && onDate(e);
            // const obj = { ...actionCtx?.sortOptions, period: e };
            // actionCtx?.setSortOptions(obj);
          }}
          options={periodOptions}
        />
      </div>
      {/* select Date wrap end */}
      {/* select Subsidiary wrap start */}
      <div className="select-wrap">
        <label className="label" htmlFor="">
          Subsidiary
        </label>
        <Select
          // isSearchable={true}
          // isMulti
          styles={reactSelectStyleCommonSort}
          placeholder="Select Subsidiary"
          className="select"
          value={subVal}
          onChange={(e) => {
            onSub && onSub(e);
            dispatch(getDepartmentsBySubsidiary(e.value));
            dispatch(getBranchesBySubsidiary(e.value));
            // const obj = { ...actionCtx?.sortOptions, sub_id: e };
            // actionCtx?.setSortOptions(obj);
          }}
          options={subsidiariesDrop}
        />
      </div>
      {/* select Subsidiary wrap end */}
      {/* select Select Branch wrap start */}
      <div className="select-wrap">
        <label className="label" htmlFor="">
          Branch
        </label>
        <Select
          isLoading={loadingPage}
          styles={reactSelectStyleCommonSort}
          placeholder="Select Branch"
          className="select"
          value={braval}
          onChange={(e) => {
            onBra && onBra(e);
            // const obj = { ...actionCtx?.sortOptions, bra_id: e };
            // actionCtx?.setSortOptions(obj);
          }}
          options={location.pathname?.includes("/dashboard-actual-revenue") ? branchDrop : branchSubDrop}
        />
      </div>
      {/* select Select Branch wrap end */}
      {/* select Department wrap start */}
      <div className="select-wrap">
        <label className="label" htmlFor="">
          Department
        </label>
        <Select
          // isSearchable={true}
          // isMulti
          styles={reactSelectStyleCommonSort}
          placeholder="Select Department"
          className="select"
          value={deptVal}
          onChange={(e) => {
            onDept && onDept(e);
            // const obj = { ...actionCtx?.sortOptions, dept_id: e };
            // actionCtx?.setSortOptions(obj);
          }}
          options={departmentDrop}
        />
      </div>
      {/* select Department wrap end */}
      {/* Select Option wrap start */}
      {/* <div className="select-wrap">
        <label className="label" htmlFor="">
          Option
        </label>
        <Select
          // isSearchable={true}
          // isMulti
          styles={reactSelectStyleCommonSort}
          placeholder="Select Option"
          className="select"
          value={details.class}
          onChange={(e) => {
            setDetails((prev) => {
              return { ...prev, class: e };
            });
          }}
          options={categoryList}
        />
      </div> */}
      {/* Select Option wrap end */}
      {/* Select Option wrap start */}
      {/* <div className="select-wrap select-wrap-input">
        <input
          className="search-input"
          placeholder="Advanced Search"
          type="text"
          onChange={(e) => {
            onSearch && onSearch(e.target.value);
          }}
        />
      </div> */}
      {/* Select Option wrap end */}
      {/* reset values start */}
      <p
        onClick={() => {
          // const obj = { ...actionCtx?.sortOptions, sub_id: "", dept_id: "", bra_id: "", fis_id: "",  };
          // actionCtx?.setSortOptions(obj);
          onReset();
        }}
        className="reset-text"
      >
        Reset Values
      </p>
      {/* reset values end */}
    </div>
  );
};

export default CommonSortBox;
