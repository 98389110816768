import React, { useContext} from "react";
// import DashboardLayout from "../../../../../../component/layout/dashboard/DashboardLayout";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
import ActionContext from "../../../../../../context/ActionContext";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
// import CommonNavLink from "../../../../../../component/pages/dashboard/projection/revenue/CommonNavLink";

const EmployeeActualGrossProfit = ({
  children,
  chartBox,
  showChart,
  setShowChart,
  actualVal
}) => {
  const actionCtx = useContext(ActionContext);
  return (
    <>
      {/* <EmployeeDashboardLayout goBack organization pageTitle={`Dashboard`}> */}
        <div className="expense-projection-inde-wrap">
          {/* <CommonSortBox /> */}

          {/* title hide box start */}
          <div className="title-hide-box">
            <p className="title">
              Actual GP <span>{actualVal}</span>
            </p>
            {/* hide box start */}
            <div className="hide-box">
              <input
                checked={actionCtx.isShowChart?.expense_projection}
                className="input"
                onChange={(e) => actionCtx.showChartFunc("expense-projection")}
                type="checkbox"
                name=""
                id="chart-check"
              />
              <p className="label-text">
                {actionCtx.isShowChart?.expense_projection
                  ? "Hide Chart"
                  : "Show Chart"}
              </p>
              <label htmlFor="chart-check" className="label">
                <span></span>
                <span></span>
              </label>
            </div>
            {/* hide box end */}
          </div>
          {/* title hide box end */}
          {/* common links start */}
          <>
            {/* <CommonNavLink   /> */}
          </>
          {/* common links end */}
          {/* chart and table box start */}
          <>{chartBox}</>
          {/* chart  box end */}
          {/* children box start */}
          <>{children}</>
          {/* children box end */}
        </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};

export default EmployeeActualGrossProfit;
