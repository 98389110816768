import React from "react";
import { FaTimes } from "react-icons/fa";
import "./BudgetAssumptionModal.css";

const BigShowModalComponent = ({ onClose, detail }) => {
  return (
    <div className="show-big-budget-assumption-modal-homne">
      <div onClick={onClose} className="cancel-box">
        <FaTimes className="icon" />
      </div>
      <div style={{ backgroundImage: `url(${detail})` }} className="wrap"></div>
    </div>
  );
};

export default BigShowModalComponent;
