import DashboardHome from "../../../component/pages/dashboard/home";
import ActualGrossProfit from "../../../component/pages/dashboard/home/actualGrossProfit";
import ActualNetProfit from "../../../component/pages/dashboard/home/actualNetProfit";
// import AllUsers from "../. ./../component/pages/dashboard/home/allUsers";
import AvailableBalance from "../../../component/pages/dashboard/home/availableBalance";
import BookBalance from "../../../component/pages/dashboard/home/bookBalance";
import NetProfitProjection from "../../../component/pages/dashboard/home/netProfitProjection";
import TotalGrossProfit from "../../../component/pages/dashboard/home/totalGrossProfit";

export const Dashboard_home_route_group = [
  {
    path: "/dashboard-home",
    component: DashboardHome,
    pageTitle: "Dashboard",
    goBack: false,
  },
  {
    path: "/dashboard-home-net-profit-projection",
    component: NetProfitProjection,
    pageTitle: "Dashboard",
    goBack: true,
  },
  {
    path: "/dashboard-home-actual-net-profit",
    component: ActualNetProfit,
    pageTitle: "Dashboard",
    goBack: true,
  },
  {
    path: "/dashboard-home-projected-gross-profit",
    component: TotalGrossProfit,
    pageTitle: "Dashboard",
    goBack: true,
  },
  {
    path: "/dashboard-home-actual-gross-profit",
    component: ActualGrossProfit,
    pageTitle: "Dashboard",
    goBack: true,
  },
  {
    path: "/dashboard-home-book-balance",
    component: BookBalance,
    pageTitle: "Dashboard",
    goBack: true,
  },
  {
    path: "/dashboard-home-available-balance",
    component: AvailableBalance,
    pageTitle: "Dashboard",
    goBack: true,
  },

];
