import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import EmployeeDashboardLayout from "../component/layout/dashboard/EmpDashboardLayout";

const EmployeePrivateRoute = ({
  component: Component,
  pageTitle,
  pageTitleSpan,
  goBack,
  organization,
  ...rest
}) => {
  // const location = useLocation();
  const [ pagetitle ] = useState("");  
  // const [psmallTitle, setSmallTitle] = useState("");

  const login = JSON.parse(localStorage.getItem("login")) || false;
  const token = localStorage.getItem("token");

  // useEffect(() => {
  //   setPageTitle(pageTitle);
  //   setSmallTitle(pageTitleSpan);
  // }, [location.pathname, pagetitle, pageTitleSpan]);
  return (
    <Route
      {...rest}
      render={(props) =>
        login && token ? (
          <EmployeeDashboardLayout
            goBack={goBack}
            pageTitleSpan={pageTitleSpan}
            pageTitle={pageTitle}
            organization={organization}
          >
            <Component
              {...props}
              pageTitleSpan={pageTitleSpan}
              pageTitle={pagetitle}
            />
          </EmployeeDashboardLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
export default EmployeePrivateRoute;
