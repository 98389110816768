import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";

export const useThemeDetector = () => {
    // const {  preference } = useSelector((state) => state.preference);
    const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: light)").matches;
    const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());  
    const mqListener = (e => {
        setIsDarkTheme(e.matches);
    });
    
    useEffect(() => {
      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      darkThemeMq.addListener(mqListener);
      return () => darkThemeMq.removeListener(mqListener);
    }, []);
    return isDarkTheme;
}   