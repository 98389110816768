import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import useDebounce from "../../../../../helper/UseDebounce";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../context/ActionContext";
import { getRolesWithPagination } from "../../../../../redux/role/role";

const RoleMain = () => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const [refreshTable ] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { roles, meta, links , loadingPage} = useSelector((state) => state.role);
  const dispatch = useDispatch();
  const [indexNo, setIndexNo] = useState("");
  const tableHeadList = ["Roles", "Description", "Users"];

  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "Name", value: "name" },
    { label: "Descriptioon", value: "description" },
  ];

  const handlePagination = async (param) => {
    // console.log(param);
    // const splitLink = param.split("/api");
    // const linkTwo = splitLink[1];
    // dispatch(geUsersPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
 
    // user?.organizations?.length > 0 && dispatch(getUsersWithPagination(obj));
  };





  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    // console.log(user);
    if (user?.organizations?.length > 0) {
      dispatch(getRolesWithPagination(obj));
      // console.log(obj);
    }
    // eslint-disable-next-line
  }, [refreshTable]);

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
    
      // dispatch(getUsersByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 3 of 6`}
        pageTitle={`Admin Settings`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() => {
              console.log(roles);
              // history.push("/dashboard-administration-admin-settings");
            }}
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() => {
              console.log(user?.permissions);
            }}
            className="active text"
          >
            Role
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="text">Add Subsidiaries</p> */}
        </div>
        <p
          style={{ width: "60%", marginBottom: "3rem" }}
          className="small-text"
        >
          Roles are made up of permissions. Each user is assigned to a role to
          define what actions they can perform in. Create custom roles to
          configure specific permissions as needed by your organization.{" "}
        </p>

        {/* table box start */}
        <ActualReuseableTable
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          perPageValue={actionCtx.perPage.value}
          setPerPageValue={(e) => {
            // setPerpageVal(e);
            handleSortPerPage(e.value);
          }}
          fromPage={meta?.from || 1}
          toPage={meta?.to || roles?.length}
          totalPage={meta?.total || roles?.length}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          tableTitle={roles?.length > 1 ? `Role` : "Roles"}
          // tableTitleSpan={`(2)`}
          action
          addBtntext={`New role`}
          dontShowBtn={!user?.permissions?.includes("create roles")}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          four
          // three={tableHeadList[2]}
          onCreateNew={() =>
            history.push(
              "/dashboard-administration-admin-settings-role-creator-adminostrator"
            )
          }
          loading={loadingPage && roles.length < 1}
          loadingNum={7}
        >
          {roles?.map((chi, idx) => {
            const { name, description, is_editable } = chi;
            return (
              <TableBodyRow
                key={idx}
                one={name}
                two={description}
                three={<>&nbsp;</>}
                action
                loading={loadingPage}
                updateText={is_editable && `Update role`}
                // textOneText={`Assign Text`}
                onView={() => {
                  localStorage.setItem("role-id", chi?.id);
                  history.push(
                    "/dashboard-administration-admin-settings-role-creator-adminostrator-view"
                  );
                  // console.log("yes", chi?.id);
                }}
                viewText={
                  user?.permissions?.includes("view roles") && `View Role`
                }
                onUpdate={() => {
                  // console.log(chi);

                  localStorage.setItem("role-id", chi?.id);
                  history.push(
                    "/dashboard-administration-admin-settings-role-creator-adminostrator-update"
                  );
                }}
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default RoleMain;
