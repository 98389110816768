import React, { useContext, useEffect, useState } from "react";
import BudgetMain from "..";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import CommonExpenditureLink from "../expenditure-rev/commonLink";
import Modal from "react-awesome-modal";
import AddBudgetRevVerticalModal from "../modals/AddBudgetRevVerticalModal";
import UpdateBudgetRevVerticalModal from "../modals/UpdateBudgetRevVerticalModal";
import DeleteBudgetRevVerticalModal from "../modals/DeleteBudgetRevVertical.";
import ActionContext from "../../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  getVerticalByFilter,
  getVerticalPagination,
  getVerticals,
  getVerticalWithPagination,
} from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import AddBudgetRevPortfolioModal from "../modals/AddBudgetRevPortfolioModal";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import {
  getPortfolioPagination,
  getPortfolios,
  getPortfoliosWithPagination,
} from "../../../../../../redux/budget-setting/revenue-projection/portfolio";
import { trimLongString } from "../../../../../../helper/Helpers";
import DeleteBudgetRevPortfolioModal from "../modals/DeleteBudgetRevPortfolio";
import UpdateBudgetRevPortfolioModal from "../modals/UpdateBudgetRevPortfolioModal";

const tableHeadList = [
  "Name",
  "Subsidiary",
  "Vertical",
  "Description",
  "Action",
];
// const tableBodyList = [
//   {
//     name: "Commercial Banks",
//     des: "Banks with a capital base of at least 25Billion",
//   },
//   {
//     name: "Commercial Banks",
//     des: "Banks with a capital base of at least 25Billion",
//   },
// ];

const RevenueProjectionPortfolio = () => {
  const [indexNo, setIndexNo] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const { portfolios, loadingPage } = useSelector((state) => state.portfolio);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      dispatch(getVerticals(actionCtx?.activeOrg?.id));
      dispatch(getPortfoliosWithPagination(obj));
      dispatch(getPortfolios(obj));
    }
    // eslint-disable-next-line
  }, [actionCtx?.activeOrg, refreshTable]);

  useEffect(() => {}, [refreshTable]);

  const handleUpdate = async (param) => {
    actionCtx?.setIsModalOut(true);
    setShowModal((prev) => {
      return { ...prev, edit: true };
    });
    setSingleChild(param);
  };

  const handleFinish = () => {
    actionCtx?.setIsModalOut(false);
    setShowModal((prev) => {
      return { ...prev, add: false, edit: false, delete: false };
    });
    setRefreshTable(!refreshTable);
  };

  const handleDelete = (param) => {
    setSingleChild(param);
    setShowModal((prev) => {
      return { ...prev, delete: true };
    });
  };

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    // console.log(linkTwo);
    dispatch(getPortfolioPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    // console.log(obj);
    user?.organizations?.length > 0 && dispatch(getVerticalWithPagination(obj));
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: "",
        org_id: actionCtx?.activeOrg?.id,
      };
      // console.log(obj);
      dispatch(getVerticalByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const handleEdit = (e) => {
    const { name, value } = e.target;
    const data = { ...singleChild, [name]: value };
    setSingleChild(data);
  };

  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink
          title={`Revenue Projection`}
          textOne={`Vertical`}
          textTwo={`Portfolio`}
          textThree={`Products`}
          linkOne={`/dashboard-administration-admin-settings-budget-rev-pro-vertical`}
          linkTwo={`/dashboard-administration-admin-settings-budget-rev-pro-portfolio`}
          linkThree={`/dashboard-administration-admin-settings-budget-rev-pro-product`}
        />
        <div
          onClick={() => {
            // console.log(portfolios);
          }}
          className="table-box-budget"
        >
          {/* table box start */}
          <ActualReuseableTable
            fromPage={portfolios[0]?.from || 1}
            toPage={portfolios[0]?.to || portfolios[0]?.length}
            totalPage={portfolios[0]?.total || portfolios[0]?.length}
            nextPage={portfolios[0]?.next_page_url}
            prevPage={portfolios[0]?.prev_page_url}
            onNextPage={() => handlePagination(portfolios[0]?.next_page_url)}
            onPrevPage={() => handlePagination(portfolios[0]?.prev_page_url)}
            onSearchTable={(e) => {
              setSearch(e);
            }}
            perPageValue={actionCtx?.perPage?.value}
            setPerPageValue={(e) => {
              // setPerpageVal(e);
              handleSortPerPage(e.value);
            }}
            tableTitle={portfolios[0]?.length > 1 ? `Portfolios` : "Portfolio"}
            tableTitleSpan={`(${portfolios[0]?.length})`}
            showBulkUpload
            dontShowSelect
            addBtntext={`New Portfolio`}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            action
            onCreateNew={() => {
              actionCtx?.setIsModalOut(true);
              setShowModal((prev) => {
                return { ...prev, add: true };
              });
            }}
          >
            {portfolios[0]?.data?.map((chi, idx) => {
              const { name, description, subsidiary, vertical } = chi;
              return (
                <TableBodyRow
                  loading={loadingPage}
                  key={idx}
                  one={name}
                  two={subsidiary?.name}
                  three={vertical?.name || "--"}
                  four={trimLongString(description, 12)}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  deleteText={`Delete`}
                  updateText={`Update`}
                  onUpdate={() => {
                    handleUpdate(chi);
                    // console.log(chi);
                  }}
                  onDelete={() => handleDelete(chi)}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
      </BudgetMain>

      <Modal effect="fadeInUp" visible={showModal.add}>
        <AddBudgetRevPortfolioModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, add: false };
            });
          }}
          onFinish={handleFinish}
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.edit}>
        <UpdateBudgetRevPortfolioModal
          onFinish={handleFinish}
          detail={singleChild}
          onEdit={handleEdit}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, edit: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.delete}>
        <DeleteBudgetRevPortfolioModal
          detail={singleChild}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
            setSingleChild({});
          }}
          onFinish={handleFinish}
        />
      </Modal>
    </>
  );
};

export default RevenueProjectionPortfolio;
