import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../../../../../styles/dashboard/admin-settings/BudgetSetting.css";

const CommonExpenditureLink = ({
  title,
  textOne,
  textTwo,
  textThree,
  linkOne,
  linkTwo,
  linkThree,
}) => {
  const location = useLocation();
  const history = useHistory()
  return (
    <div className="common-link-expenditure-rev">
      <p className="main-title">{title}</p>
      <div className="box">
        {textOne && (
          <p
            className={`text ${location.pathname === linkOne && "text-active"}`}
            onClick={() => history.push(linkOne)}
          >
            {textOne}
          </p>
        )}
        {textTwo && (
          <p
            className={`text ${location.pathname === linkTwo && "text-active"}`}
            onClick={() => history.push(linkTwo)}
          >
            {textTwo}
          </p>
        )}
        {textThree && (
          <p
            className={`text ${
              location.pathname === linkThree && "text-active"
            }`}
            onClick={() => history.push(linkThree)}
          >
            {textThree}
          </p>
        )}
      </div>
    </div>
  );
};

export default CommonExpenditureLink;
