import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
// import EmployeeSpreadForm from "./SpreadForm";
// import EmployeeMonthlyForm from "./MonthlyForm";
import { useHistory } from "react-router-dom";
import Modal from "react-awesome-modal";
import { HiInformationCircle } from "react-icons/hi";
import Select from "react-select";
import {
  formatNumber,
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { getProducts } from "../../../../../../redux/budget-setting/revenue-projection/product";
import { getVerticals } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import {
  ActualRevenueProjectionDownload,
  createActualRevenue,
} from "../../../../../../redux/employee/actual-revenue/actual-revenue";
import { Dna } from "react-loader-spinner";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import EmployeeActualRevBulkModal from "./modal/upload";
import { getPortfolios } from "../../../../../../redux/budget-setting/revenue-projection/portfolio";
import {
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { getProductByPortfolio } from "../../../../../../redux/employee/revenue-projection/revenue-projection";
import { checkDepartmentApprovalFlow } from "../../../../../../redux/employee/approval-flow";
import RevvexActionBar from "../../../../../../component/fragments/ActionBar";
import ActionContext from "../../../../../../context/ActionContext";
// import { getPortfolios } from "../../../../../../redux/budget-setting/revenue-projection/portfolio";

const EmployeeActualRevenueForm = () => {
  const [details, setDetails] = useState({
    product_id: "",
    organization_id: "",
    portfolio_id: "",
    subsidiary_id: "",
    department_id: "",
    vertical_id: "",
    fiscal_year_id: "",
    clientele_account: "",
    description: "",
    unit_price: "",
    unit: "",
    date: "",
    gross_profit_percent: "",
    gp: "",
    gross_revenue: "",
    // status: "",
    is_submitted: "",
    is_allowed: 0,
  });
  const [startDate, setstartDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);
  const { productByPort, portLoading } = useSelector(
    (state) => state.revenue_projection
  );
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const { portfolioDrop, isLoading } = useSelector((state) => state.portfolio);
  // const { portfolioDrop, isLoading } = useSelector((state) => state.portfolio);
  const { loading } = useSelector((state) => state.actual_revenue);
  const { productDrop } = useSelector((state) => state.product);
  const { verticalDrop } = useSelector((state) => state.vertical);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { user } = useSelector((state) => state.user);
  const [refreshTable] = useState(false);
  // const [gpPercent, setGpPercent] = useState("");
  const [gpAmount, setGpAmount] = useState("");
  const [totalRev, setTotalRev] = useState("");
  const [draftLoader, setDraftLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);
  const [approvalFlowAlert, setApprovalFlowAlert] = useState("");
  const { departmentDrop, loadingPage } = useSelector(
    (state) => state.department
  );
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);

  useEffect(() => {
    const empObj = {
      id: actionCtx?.activeOrg?.id,
      reason: true,
    };

    dispatch(getFiscalYears(empObj));
    dispatch(getSubsidiaries(empObj));
    dispatch(getUserDepartment(empObj?.id));
    dispatch(getDepartments(actionCtx?.activeOrg?.id));
    // dispatch(getProducts(empObj));
    dispatch(getVerticals(empObj));
    dispatch(getPortfolios({ org_id: actionCtx?.activeOrg?.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    const totalGrossRev =
      formatNumberWithoutComma(String(details?.unit)) *
      formatNumberWithoutComma(String(details?.unit_price));
    const totalGrossRevVal = totalGrossRev || 0;
    setTotalRev(totalGrossRevVal);

    const totalGpAmount =
      totalGrossRev *
      (formatNumberWithoutComma(String(details?.gross_profit_percent || 0)) /
        100);
    const totalGpAmountVal = totalGpAmount || 0;
    setGpAmount(totalGpAmountVal);
  }, [details]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const [gpPercent, setGpPercent] = useState("");

  const history = useHistory();
  // setSpreadForm(!spreadForm);
  const handleApprovalFlowCheckByDept = async () => {
    const checkObj = {
      projection_type: "Actual Revenue",
      department_id: details?.department_id?.value,
    };
    const checkData = await dispatch(checkDepartmentApprovalFlow(checkObj));
    // console.log(checkData, "data");
    if (checkData?.payload?.status !== 201) {
      setApprovalFlowAlert(checkData?.payload?.message);
    } else {
      setApprovalFlowAlert("");
    }
  };

  useEffect(() => {
    if (details?.department_id?.value) {
      handleApprovalFlowCheckByDept();
    }
  }, [details?.department_id?.value]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const checkObj = {
    //   projection_type: "Actual Revenue",
    //   department_id: details?.department_id?.value,
    // };
    // details?.is_submitted === false && setDraftLoader(true);
    // details?.is_submitted === true && setApproveLoader(true);
    // const checkData = await dispatch(checkDepartmentApprovalFlow(checkObj));
    // if (checkData?.payload?.status !== 201) {
    //   details?.is_submitted === false && setDraftLoader(false);
    //   details?.is_submitted === true && setApproveLoader(false);
    //   return;
    // }
    const obj = {
      ...details,
      product_id: details?.product_id?.value,
      portfolio_id: details?.portfolio_id?.value,
      subsidiary_id: details.subsidiary_id?.value,
      vertical_id: details.vertical_id?.value,
      department_id: details?.department_id?.value,
      fiscal_year_id: details?.fiscal_year_id?.value,
      organization_id: actionCtx?.activeOrg?.id,
      date: startDate,
      // gross_profit_percent: formatNumberWithoutComma(
      //   String(gpPercent.toFixed(2))
      // ),
      gross_profit_percent: formatNumberWithoutComma(
        String(details?.gross_profit_percent)
      ),
      unit: Math.trunc(formatNumberWithoutComma(String(details?.unit))),
      unit_price: Math.trunc(
        formatNumberWithoutComma(String(details?.unit_price))
      ),
      gp: formatNumberWithoutComma(String(gpAmount)),
      gross_revenue: formatNumberWithoutComma(String(totalRev.toFixed(2))),
      // status: details?.status === "completed" ? "completed" : "draft",
      is_submitted: details?.is_submitted === true ? true : false,
    };
    // console.log("obj", obj);
    if (obj?.is_submitted === false) {
      setDraftLoader(true);
    }
    if (obj?.is_submitted === true) {
      setApproveLoader(true);
    }
    const data = await dispatch(createActualRevenue(obj));
    if (data?.payload?.success && obj?.is_submitted === false) {
      setDraftLoader(false);
      setDetails({
        product_id: "",
        organization_id: "",
        subsidiary_id: "",
        department_id: "",
        vertical_id: "",
        clientele_account: "",
        fiscal_year_id: "",
        description: "",
        unit_price: "",
        unit: "",
        gross_profit_percent: "",
        gp: "",
        gross_revenue: "",
        status: "",
      });
      history.push("/employee-dashboard-actual-revenue");
    } else if (data?.payload?.success && obj?.is_submitted === true) {
      setApproveLoader(false);
      history.push("/employee-dashboard-actual-revenue-submitted");
    } else {
      setDraftLoader(false);
      setApproveLoader(false);
    }
  };

  const enableSubmit = () => {
    let allowSubmit = false;
    allowSubmit =
      details?.product_id &&
      details?.vertical_id &&
      startDate &&
      details?.portfolio_id &&
      details?.subsidiary_id &&
      // details?.clientele_account &&
      details?.department_id &&
      details?.fiscal_year_id &&
      details?.description &&
      details?.unit_price &&
      details?.unit &&
      approvalFlowAlert == "" &&
      // details?.gp &&
      details?.gross_profit_percent;
    // gpPercent &&
    // totalRev;

    return allowSubmit;
  };

  const formatPercentage = (param) => {
    const splitVal = param.split("%");
    return Number(splitVal[0]);
  };

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Actual Revenue`}> */}
      {approvalFlowAlert && (
        <RevvexActionBar
          type={"warning"}
          visible={approvalFlowAlert}
          msg={approvalFlowAlert}
          onCancel={() => {
            setApprovalFlowAlert("");
          }}
          // actionText={"Submitted Projections"}
        />
      )}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">New Revenue</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          <div className="btn-download-box">
            <button className={`btn-bulk `} onClick={() => setShowModal(true)}>
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            {loading ? (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <p
                className="text"
                onClick={() => dispatch(ActualRevenueProjectionDownload())}
              >
                See and download template
              </p>
            )}
          </div>

          {/* bulk upload btn start */}
        </div>
        {/* title and btn container */}

        <div className="form-box" style={{ width: "80%" }}>
          <form onSubmit={handleSubmit} action="" className="form">
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Revenue Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="date" className="label">
                    Date <span>*</span>
                  </label>
                  <Flatpickr
                    id="date"
                    name="date"
                    className={`input ${startDate && "input-active"}`}
                    autoComplete="off"
                    value={startDate}
                    onChange={(date) => {
                      setstartDate(moment(date[0]).format("YYYY-MM-DD"));
                    }}
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="portfolio" className="label">
                    Portfolio <span>*</span>
                  </label>

                  <Select
                    className={`select-wrap ${
                      details?.portfolio_id && "select-wrap-active"
                    }`}
                    placeholder="Select Portfolio"
                    // isDisabled={true}
                    value={details?.portfolio_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          portfolio_id: e,
                        };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={portfolioDrop}
                    isLoading={isLoading}
                  />
                </div> */}
                <div className="form-group">
                  <label htmlFor="portfolio" className="label">
                    Portfolio <span>*</span>
                  </label>

                  <Select
                    className={`select-wrap ${
                      details?.portfolio_id && "select-wrap-active"
                    }`}
                    placeholder="Select Portfolio"
                    // isDisabled={true}
                    value={details?.portfolio_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          portfolio_id: e,
                        };
                      });
                      dispatch(getProductByPortfolio(e?.id));
                    }}
                    styles={reactSelectStyle}
                    options={portfolioDrop}
                    isLoading={isLoading}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="product" className="label">
                    Product <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details?.product_id && "select-wrap-active"
                    }`}
                    placeholder="Select Product"
                    value={details?.product_id}
                    isLoading={portLoading}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          product_id: e,
                          gross_profit_percent: formatNumber(
                            String(formatPercentage(e.gp_percentage))
                          ),
                          is_allowed: e.is_allowed,
                        };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={productByPort}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="vertical" className="label">
                    vertical <span>*</span>{" "}
                    <HiInformationCircle
                      style={{ marginLeft: "3px", fontSize: "16px" }}
                    />
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.vertical_id && "select-wrap-active"
                    }`}
                    placeholder="Select Vertical"
                    value={details.vertical_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, vertical_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={verticalDrop}
                    isLoading={isLoading}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    placeholder="Select Subsidiary"
                    value={details.subsidiary_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                      const obj = {
                        reason: true,
                        id: e?.value,
                      };
                      dispatch(getDepartmentsBySubsidiary(obj));
                    }}
                    styles={reactSelectStyle}
                    isLoading={isLoading}
                    options={subsidiariesDrop}
                  />
                </div>
                {/* group box end */}

                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="department" className="label">
                    Department <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.department_id && "select-wrap-active"
                    }`}
                    placeholder="Select Department"
                    value={details?.department_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, department_id: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    isLoading={loadingPage}
                    options={details?.subsidiary_id ? departmentDrop : []}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label
                    htmlFor="subsidiary"
                    className="label"
                    style={{ marginTop: "3.6px" }}
                  >
                    Company Name
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="e.g toyota"
                    id="clientele_account"
                    value={details.clientele_account}
                    name="clientele_account"
                    onChange={handleChange}
                    className={`input ${
                      details.clientele_account && "input-active"
                    }`}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Fiscal Year <span>*</span>
                  </label>
                  <Select
                    placeholder="Select fiscal year"
                    className={`select-wrap ${
                      details.fiscal_year_id && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    value={details?.fiscal_year_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, fiscal_year_id: e };
                      });
                    }}
                    isLoading={isLoading}
                    options={fiscalYearDrop}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                {/* <br /> */}
                {/* <br /> */}
                <div className="form-group">
                  <label htmlFor="description" className="label">
                    Description <span>*</span>
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={handleChange}
                    style={{ width: "206%", resize: "none", padding: "30px" }}
                    cols="30"
                    rows="7"
                    placeholder="Enter your description here"
                    className={`input ${
                      details?.description && "input-active"
                    }`}
                    value={details?.description}
                  />
                </div>
                {/* group box end */}
              </div>

              {/* second layer start */}
              <div
                className="form-wrap"
                style={{ marginTop: "3rem", marginBottom: "" }}
              >
                <div className="form-group">
                  {/* unit-price input start */}
                  <label htmlFor="unit price" className="label">
                    Unit Price <span>*</span>
                  </label>
                  <NumericFormat
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    // disabled
                    // type="number"
                    placeholder="input value"
                    name="unit_price"
                    className={`input ${details?.unit_price && "input-active"}`}
                    id="unit_price"
                    value={details?.unit_price}
                    onChange={handleChange}
                  />
                </div>
                {/* unit-price input start */}
                {/* unit input start */}
                <div className="form-group">
                  <label htmlFor="units" className="label">
                    Units <span>*</span>
                  </label>
                  <NumericFormat
                    // type="number"
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    name="unit"
                    id="units"
                    onChange={handleChange}
                    placeholder="input value"
                    value={details?.unit}
                    className={`input ${details?.unit && "input-active"}`}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="gross_revenue" className="label">
                    Gross Revenue
                  </label>
                  <NumericFormat
                    // type="number"
                    decimalScale={3}
                    decimalSeparator="."
                    allowNegative
                    thousandSeparator={","}
                    type="text"
                    disabled
                    style={{
                      backgroundColor: "#F1F2F3",
                    }}
                    // type="number"
                    name="gross_revenue"
                    id="gross_revenue"
                    placeholder="Shows Value"
                    onChange={handleChange}
                    value={totalRev || ""}
                    className={`input`}
                  />
                </div>

                <div
                  style={{
                    gridTemplateColumns: "1fr 1fr",
                    display: "grid",
                    gap: "2rem",
                  }}
                >
                  <div className="form-group">
                    <label
                      htmlFor="units"
                      className="label"
                      style={{ marginBottom: "unset" }}
                    >
                      GP %<span>*</span>
                    </label>
                    <NumericFormat
                      disabled={details?.is_allowed === 0}
                      decimalScale={2}
                      decimalSeparator="."
                      allowNegative
                      type="text"
                      name="gross_profit_percent"
                      id="gross_profit_percent"
                      value={details?.gross_profit_percent}
                      placeholder="Shows Value"
                      onChange={(e) => {
                        if (!isNaN(e.target.value) && e.target.value <= 100) {
                          setDetails((prev) => {
                            return {
                              ...prev,
                              gross_profit_percent: e?.target?.value,
                            };
                          });
                        } else {
                          setDetails((prev) => {
                            return {
                              ...prev,
                              gross_profit_percent: "",
                            };
                          });
                        }
                        // console.log(details?.gross_profit_percent, "gross");
                      }}
                      style={{
                        backgroundColor: details?.is_allowed === 0 && "#F1F2F3",
                      }}
                      className={`input ${
                        details?.gross_profit_percent &&
                        details?.is_allowed !== 0 &&
                        "input-active"
                      }`}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="gp_amount" className="label">
                      GP Amount
                    </label>
                    <NumericFormat
                      // type="number"
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      disabled
                      name="gp"
                      id="gp"
                      placeholder="Input Value"
                      onChange={handleChange}
                      value={gpAmount || ""}
                      className={`input`}
                      style={{
                        backgroundColor: "#F1F2F3",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* second wrap end*/}
            <div className="wrapper"></div>
            {/* btn box start */}

            <div style={{ display: "flex", gap: "3rem" }}>
              {!approveLoader ? (
                <input
                  onClick={() => setDetails({ ...details, is_submitted: true })}
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Submit For Approval"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{ height: "5rem" }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="50"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}

              {!draftLoader ? (
                <input
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Save To Draft"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{
                    height: "5rem",
                    background: "#ffff",
                    color: !enableSubmit() ? "#cccc" : "var(--blue-color)",
                    border: !enableSubmit()
                      ? ".1rem solid #cccc"
                      : ".1rem solid var(--blue-color)",
                  }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="50"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
            </div>
            {/* button end */}
            {/* button start */}
            {/* button end */}

            {/* btn box end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
      {/* modal start */}
      <Modal effect="fadeInUp" visible={showModal}>
        <EmployeeActualRevBulkModal onCancel={() => setShowModal(false)} />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeActualRevenueForm;
