import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  getUsers,
  updateUser,
} from "../../../../../redux/users/users";
import { formatNumberWithoutComma, reactSelectStyle } from "../../../../../helper/Helpers";
import Select from "react-select";
import ActionContext from "../../../../../context/ActionContext";
import { getSubsidiaries } from "../../../../../redux/subsidiary/subsidiary";
import {  PatternFormat } from "react-number-format";
import {
  getDepartmentsBySubsidiary,
} from "../../../../../redux/department/deparment";
import { getRoles } from "../../../../../redux/role/role";
import { Dna } from "react-loader-spinner";
// import { getRoles } from "@testing-library/react";

const EditEmployee = () => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const { roleDrop } = useSelector((state) => state.role);
  const { usersDrop, loading } = useSelector((state) => state.users);
  const [refreshPage, setRefreshPage] = useState(false);
  // const { organizations } = useSelector((state) => state.user);
  // const { branchSubDrop, loadingPage } = useSelector((state) => state.branch);
  const { departmentDrop, loadingPage } = useSelector(
    (state) => state.department
  );
  // const { loading } = useSelector((state) => state.subsidiary);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const [details, setDetails] = useState({
    subsidiary_id: "",
    department: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    role: "",
    submit_to: [],
  });

  const { user } = useSelector((state) => state.user);
  const [singleUser, setSingleUser] = useState({
    subsidiary_id: "",
    department: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    role: "",
    submit_to: [],
    departments: [],
  });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    const dataTwo = { ...singleUser, [name]: value };
    setSingleUser(dataTwo);
  };

  useEffect(() => {
    const id = localStorage.getItem("user-id");
    // console.log("yes");
    if (user?.organizations?.length > 0) {
      dispatch(getRoles(actionCtx.activeOrg?.id));
      dispatch(getUsers(actionCtx?.activeOrg?.id));
      dispatch(getSubsidiaries(actionCtx?.activeOrg?.id));
      // dispatch(getDepartments(actionCtx?.activeOrg?.id));
      // dispatch(getOrganizations());
      hanldGetUser(id);
    }
      // eslint-disable-next-line
  }, [refreshPage]);

  useEffect(() => {
    if(actionCtx?.activeOrg?.type !== "group" ){
      setDetails((prev) => {
        return {...prev, subsidiary_id: subsidiariesDrop[0]}
      })
      setSingleUser((prev) => {
        return {...prev, subsidiary: subsidiariesDrop[0]}
      })
      // console.log(subsidiariesDrop);
      dispatch(getDepartmentsBySubsidiary(subsidiariesDrop[0]?.id))
      // handleGetBranchBySub(subsidiariesDrop)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsidiariesDrop])

  const hanldGetUser = async (id) => {
    const data = await dispatch(getUser(id));
    if (data?.payload?.success) {
      const userDetail = data?.payload?.data?.user;
      console.log(userDetail);
      const submitToList = userDetail?.submit_to?.map((chi) => {
        return { label: chi?.full_name, value: chi?.id };
      });
      const deptList = userDetail?.department?.map((chi) => {
        return { label: chi?.name, value: chi?.id };
      });
      // console.log(deptList);
      dispatch(getDepartmentsBySubsidiary(userDetail?.subsidiary?.id));
      const newDetails = {
        ...userDetail,
        departments: deptList,
        subsidiary: {
          label: userDetail?.subsidiary?.name,
          value: userDetail?.subsidiary?.id,
        },
        submit_to: submitToList,
        role: { label: userDetail?.role, value: userDetail?.role },
      };
      // console.log(newDetails);
      setSingleUser(newDetails);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitList = details?.submit_to?.map((chi) => chi.value);
    const submitListDefault = singleUser?.submit_to.map((chi) => chi.value);
    // const deptList = details?.department?.map((chi) => chi.value);
    const deptListDefault = singleUser?.departments?.map((chi) => chi.value);
    const obj = {
      id: singleUser?.id,
      first_name: details?.first_name || singleUser?.first_name,
      last_name: details?.last_name || singleUser?.last_name,
      phone_number: formatNumberWithoutComma(details?.phone_number) || singleUser?.phone_number,
      email: details?.email || singleUser?.email,
      submit_to: submitList.length > 0 ? submitList : submitListDefault,
      subsidiary_id:
        details?.subsidiary_id?.value || singleUser?.subsidiary?.value,
      department_ids: deptListDefault,
      role: details?.role.label || singleUser?.role.label,
    };
    // console.log(singleUser);
    // console.log(obj);
    const data = await dispatch(updateUser(obj));
    if (data?.payload?.success) {
      setRefreshPage(!refreshPage);
      history.push("/dashboard-administration-admin-settings-employee")
    }
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 5 of 6`}
        pageTitle={`Edit Employee`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings-employee")
            }
            className="text"
          >
            Users
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Edit User</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Invite Users</p>
            <p className="text">
              Add department information and control the organisation structure
              of your account management
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          {/* <div className="btn-download-box">
            <button
              className={`btn-bulk ${
                details.Subsidiary_Address ||
                details.Subsidiary_Name ||
                details.Phone_Number ||
                details.country
                  ? "btn-bulk-inactive"
                  : ""
              }`}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div> */}
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1.User Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="First Name" className="label">
                    First Name
                  </label>
                  <input
                    // disabled
                    type="text"
                    placeholder="First Name"
                    className={`input ${details.First_Name && "input-active"}`}
                    id="First Name"
                    name="first_name"
                    onChange={handleChange}
                    value={singleUser?.first_name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <div className="form-group">
                    <label htmlFor="Last Name" className="label">
                      Last Name
                    </label>
                    <input
                      // disabled
                      type="text"
                      placeholder="Last Name"
                      className={`input ${details.Last_Name && "input-active"}`}
                      id="Last Name"
                      name="last_name"
                      onChange={handleChange}
                      value={singleUser?.last_name}
                    />
                  </div>
                  {/* group box end */}
                  {/* <select
                      name="Country"
                      onChange={handleChange}
                      value={details.country}
                      id=""
                      className={`input ${details.country && "input-active"}`}
                    >
                      <option value="Naira">Nigeria</option>
                    </select> */}
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Email" className="label">
                    Email
                  </label>
                  <input
                    // disabled
                    name="email"
                    onChange={handleChange}
                    value={singleUser?.email}
                    type="text"
                    placeholder="Email"
                    className={`input ${details.Email && "input-active"}`}
                    id="Email"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Phone Number" className="label">
                    Phone Number
                    {/* <figure
                        className="img-box"
                        style={{
                          filter: "saturate(0%)",
                          transform: "translateX(.5rem) translateY(.3rem)",
                        }}
                      >
                        <img src={noteIcon} alt="" className="img" />
                      </figure> */}
                  </label>
                  {/* <input
                    // disabled
                    name="phone_number"
                    onChange={(e) => !e.target.value.match(/[A-Z]+/g) &&
                    !e.target.value.match(/[!@#$.%^&*_=+-]/g) &&
                    !e.target.value.match(/[a-z]+/g) && handleChange(e)}
                    value={singleUser?.phone_number}
                    type="text"
                    placeholder="0909599***"
                    className={`input ${
                      details.Phone_Number && "input-active"
                    }`}
                    id="Phone Number"
                  /> */}
                     <PatternFormat
                    type="text"
                    className={`input ${
                      details.phone_number && "input-active"
                    }`}
                    id="contact line"
                    onChange={handleChange}
                    value={singleUser?.phone_number}
                    name="phone_number"
                    placeholder="0909599***"
                    format="### ### ### ###"
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */} 
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">2. User Permission</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Select organization" className="label">
                    Select Subsidiary
                  </label>
                  <Select
                    // isDisabled
                    className={`select-wrap  ${actionCtx?.activeOrg?.type !==  "group" && "disable-select"} ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    placeholder="Select Subsidiary"
                    isDisabled={actionCtx?.activeOrg?.type !==  "group"}
                    onChange={(e) => {
                      dispatch(getDepartmentsBySubsidiary(e.value));
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                      setSingleUser((prev) => {
                        return { ...prev, subsidiary: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    value={actionCtx?.activeOrg?.type !== "group" ? subsidiariesDrop || singleUser?.subsidiary : singleUser?.subsidiary}
                    options={subsidiariesDrop}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  {/* <label htmlFor="Last Name" className="label">
                      Last Name 
                    </label> */}
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="Choose a department" className="label">
                      Choose a department
                    </label>
                    <Select
                      // isDisabled
                      isLoading={loadingPage}
                      options={departmentDrop}
                      className={`select-wrap select-wrap-multi ${
                        details.department && "select-wrap-active"
                      }`}
                      isMulti
                      styles={reactSelectStyle}
                      placeholder="choose department"
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, department: e };
                        });
                        setSingleUser((prev) => {
                          return { ...prev, departments: e };
                        });
                      }}
                      value={singleUser?.departments}
                    />
                  </div>
                  {/* group box end */}
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Select a role" className="label">
                    Select a role
                  </label>
                  <Select
                    // isDisabled
                    options={roleDrop}
                    className={`select-wrap ${
                      details.role && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    placeholder="select role"
                    // value={details.role}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, role: e };
                      });
                      setSingleUser((prev) => {
                        return { ...prev, role: e };
                      });
                    }}
                    value={singleUser?.role}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Choose budget approval" className="label">
                    Submit to
                  </label>
                  <Select
                    options={usersDrop}
                    className={`select-wrap select-wrap-multi ${
                      details?.submit_to?.length > 0 && "select-wrap-active"
                    }`}
                    isMulti
                    // isDisabled
                    styles={reactSelectStyle}
                    placeholder="Submit to"
                    // value={details.submit_to}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, submit_to: e };
                      });
                      setSingleUser((prev) => {
                        return { ...prev, submit_to: e };
                      });
                    }}
                    value={singleUser?.submit_to}
                  />
                </div>
                {/* group box end */}
              </div>
            </div>
            {/* wrap end */}
            {/* submit form start */}
            {!loading ? (
              <input
                
                type="submit"
                value="Edit User"
                className={`form-group btn-reuse form-submit ${
                  details?.first_name ||
                  details?.last_name ||
                  details?.phone_number ||
                  details?.email ||
                  details?.department.length > 0 ||
                  details?.subsidiary_id ||
                  details?.role ||
                  details?.submit_to
                    ? "form-submit-active"
                    : ""
                }`}
                style={{ marginTop: "3rem" }}
              />
            ) : ( 
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "3rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* submit form  end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default EditEmployee;
