import React, { useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
// import { FaCheck } from "react-icons/fa";

const AddRevenueLimitModal = ({ onCancel }) => {
  const [details, setDetails] = useState({
    name: "",
    category: "",
    class: "",
    code: "",
    description: "",
  });

  const categoryList = [
    { label: "Zojapay", value: 1 },
    { label: "ITH", value: 2 },
    { label: "Zojatech", value: 3 },
  ];



  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };
  return (
    <>
      <CommonInputModal
        longContent
        onCancel={onCancel}
        title={`New Income Limit`}
        btnText={`Save`}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Fiscal Year <span>*</span>
          </label>
          <Select
            placeholder="Select fiscal year"
            className="select "
            value={details.class}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, class: e.value };
              });
            }}
            options={categoryList}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
          Subsidiary <span>*</span>
          </label>
          <Select
            placeholder="select subsidiary"
            className="select "
            value={details.class}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, class: e.value };
              });
            }}
            options={categoryList}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
          Departments <span>*</span>
          </label>
          <Select
            placeholder="select department"
            className="select "
            value={details.class}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, class: e.value };
              });
            }}
            options={categoryList}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
          Limit (NGN) <span>*</span>
          </label>
          <input
            name="code"
            onChange={handleChange}
            value={details.code}
            type="text"
            placeholder="enter expenditure limit"
            className={`input ${details.code && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
       
      </CommonInputModal>
    </>
  );
};

export default AddRevenueLimitModal;
