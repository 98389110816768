import React, { useState } from "react";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import EmployeeRevenueRemovableGroup from "./EmpRemovableGroup";
import Select from "react-select";
import { NumericFormat } from "react-number-format";

const EmployeeRevenueProjectionMonthlyForm = ({
  list,
  price_unit,
  quantity,
  entryType,
  onInputChange,
  handleAddToList,
  onInputValueChange,
  onSelectvalueChange,
  handleRemoveMonthList,
  total_rev_project,
  total_gross_pro,
  gross_profit_percent,
  handleEntryTypeChange,
  Total_quantity_label,
  quantity_label,
  totalQuantity,
  entry_type_value,
  loading,
  totalDisabled,
}) => {
  const [details] = useState({
    unit_price: "",
    quantity: "",
    total_quantity: "",
    total_revenue_projection: "",
    gross_profit_percentage: "",
    total_gp: "",
  });

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   const data = { ...details, [name]: value };
  //   setDetails(data);
  // };

  return (
    <>
      <div
        className="form-wrap"
        style={{ marginTop: "3rem", marginBottom: "-10rem" }}
      >
        {/* form-group start */}
        <div className="form-group">
          {/* unit price group start */}
          <label htmlFor="unit price" className="label">
            Unit Price <span>* </span>
          </label>
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            type="text"
            name="unit_price"
            id="unit_price"
            placeholder="Unit Value"
            className={`input ${price_unit && "input-active"}`}
            value={details.unit_price || price_unit}
            onChange={onInputChange}
          />
        </div>
        {/* unit price group end */}
        <div className="form-group">
          <label htmlFor="quantity" className="label">
            Entry Type <span>*</span>
          </label>
          <Select
            type="number"
            name="entry_type"
            placeholder="Select Entry Type"
            value={entry_type_value}
            className={`select-wrap ${
              entry_type_value && "select-wrap-active"
            }`}
            options={entryType || entryType}
            styles={reactSelectStyle}
            onChange={handleEntryTypeChange}
            isLoading={loading}
          />
        </div>

        {/* form-group end */}
      </div>
      {/* <div className="form-box form-group"> */}
      <div className="wrapper emp-itemize">
        <p className="box-title">Itemize monthly</p>
        <div className="step-assign-box">
          <div
            className="step-assign-line"
            style={{
              display: "flex",
              gap: "47%",
              // gap: "36rem",
              marginBottom: "-2rem",
              marginTop: "2rem",
            }}
          >
            <p className="text">Months</p>
            <p className="text">Total value</p>
          </div>
        </div>
      </div>
      <div>
        <EmployeeRevenueRemovableGroup
          list={list}
          loading={loading}
          handleAddToList={handleAddToList}
          onInputValueChange={onInputValueChange}
          handleRemoveMonthList={handleRemoveMonthList}
          onSelectvalueChange={onSelectvalueChange}
          total_rev_project={total_rev_project}
          total_gross_pro={total_gross_pro}
          gross_profit_percent={gross_profit_percent}
          onInputChange={onInputChange}
          Total_quantity_label={Total_quantity_label}
          quantity_label={quantity_label}
          totalQuantity={totalQuantity}
          totalDisabled={totalDisabled}
        />
      </div>
      {/* </div> */}
    </>
  );
};
export default EmployeeRevenueProjectionMonthlyForm;
