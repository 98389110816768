import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { updateVertical } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import Select from "react-select";
import CommonInputModal from "./CommonInputModal";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import { updatePortfolio } from "../../../../../../redux/budget-setting/revenue-projection/portfolio";

const UpdateBudgetRevPortfolioModal = ({
  onCancel,
  detail,
  onEdit,
  onFinish,
}) => {
  const { loading } = useSelector((state) => state.portfolio);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  const { verticalDrop } = useSelector((state) => state.vertical);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    organization_id: "",
    name: "",
    description: "",
    subsidiary_id: "",
    vertical_id: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    onEdit(e);
  };

  const handleSubmit = async () => {
    const obj = {
      id: detail?.id,
      name: detail?.name,
      description: details?.description || detail?.description,
      organization_id: actionCtx?.activeOrg?.id,
      subsidiary_id: detail?.subsidiary?.value || detail?.subsidiary?.id,
      vertical_id: detail?.vertical?.value || detail?.vertical?.id,
    };
    // console.log(obj);
    // return
    const data = await dispatch(updatePortfolio(obj));
    if (data?.payload?.success) {
      onFinish();
      setDetails({
        name: "",
        description: "",
      });
    }
  };

  return (
    <>
      <CommonInputModal
        loading={loading}
        btnActive={
          details?.description ||
          details?.name ||
          details?.subsidiary_id ||
          details?.vertical_id
        }
        onBtnClick={handleSubmit}
        btnDisable={
          !details?.description &&
          !details?.name &&
          !details?.subsidiary_id &&
          !details?.vertical_id
        }
        onCancel={onCancel}
        title={`Update Portfolio`}
        btnText={`Update`}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={detail?.name}
            type="text"
            placeholder="Name chart of account"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Subsidiary <span>*</span>
          </label>
          <Select
            placeholder="Select subsidiary"
            className={`select ${
              actionCtx?.activeOrg?.type !== "group" && "disable-select"
            }`}
            styles={reactSelectStyle}
            value={{
              label: detail?.subsidiary?.name,
              value: detail?.subsidiary?.id,
            }}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, subsidiary_id: e };
              });
              const obj = {
                target: {
                  name: "subsidiary",
                  value: e,
                },
              };
              onEdit(obj);
            }}
            options={subsidiariesDrop}
            isDisabled={actionCtx?.activeOrg?.type !== "group"}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Vertical <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="Select expense class"
            className="select "
            value={{
              label: detail?.vertical?.name,
              value: detail?.vertical?.id,
            }}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, vertical_id: e };
              });
              const obj = {
                target: {
                  name: "vertical",
                  value: e,
                },
              };
              onEdit(obj);
            }}
            options={verticalDrop}
          />
        </div>
        {/* {/* group box end  */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description
          </label>
          <textarea
            className={`input ${details?.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="5"
            onChange={handleChange}
            value={detail?.description}
            type="text"
            placeholder="describe your chart of account"
          ></textarea>
        </div>
        {/* group box end */}
      </CommonInputModal>
    </>
  );
};

export default UpdateBudgetRevPortfolioModal;
