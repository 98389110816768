import React from "react";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import "../../../../../styles/dashboard/modals/DeleteSharedmodal.css";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import { deleteUser } from "../../../../../redux/users/users";

const DeleteEmployeeModal = ({ onClose, onFinish, details }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.users);

  const handleDelete = async() => {
    const id = details?.id
    const data = await dispatch(deleteUser(id))
    if(data?.payload?.success){
        onFinish();  
    }
  };
  return (
    <div className="delete-shared-modal-wrap">
      <p className="title">{`Delete User ( ${details?.full_name} )`}</p>
      <p className="text">
        {` Are you sure you want to delete this user. Deleting this, would
      affect your organization budget settings. Do you still want to delete?`}
      </p>
      {/* <p className="text text-two">
      organizatiuon budget settings. Do you still want to delete?
    </p> */}

      <div className="btn-box">
        <ButtonBlue onClick={onClose} text={`Cancel`} />
        {!loading ? (
          <ButtonBlue
            bgColor={`#CC0905`}
            text={`Yes Delete`}
            onClick={handleDelete}
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteEmployeeModal;
