import React from "react";
import { useHistory } from "react-router-dom";
import NoTableContent from "../../../../fragments/NoTableContent";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import CommonCustomizationScreen from "./CommonCustomizationScreen";
// import

const CustomizationIndex = () => {
  const history = useHistory();
  // const [indexNo, setIndexNo] = useState("");
  const tableHeadList = ["Title", "Type"];

  return (
    <>
      <CommonCustomizationScreen
        onClick={() =>
          history.push(
            "/dashboard-administration-admin-settings-customization-add"
          )
        }
        btnShow
        border={`unset`}
      >
        <div className="table-wrap-body">
          <ActualReuseableTable
            // tableTitle={`Department `}
            // tableTitleSpan={`(1)`}
            // addBtntext={`New Dept.`}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            action
            // onCreateNew={() =>
            //   history.push(
            //     "/dashboard-administration-admin-settings-add-department"
            //   )
            // }
          ></ActualReuseableTable>
          {/* nocotent start */}
          <NoTableContent
            text={`Customize your email alerts and notification`}
          />
          {/* nocotent end */}
        </div>
      </CommonCustomizationScreen>
    </>
  );
};

export default CustomizationIndex;
