import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import "../../../../../../employee-folder/styles/dashboard/reports/createReport.scss";
import { useHistory } from "react-router-dom";
import deleteIcon from "../../../../../../../src/assets/delete-icon.svg";

const CreateReport = () => {
  const [details, setDetails] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showColumns, setShowColums] = useState(false);

  const handleProjectionChange = (e) => {
    e.preventDefault();
    setDetails(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    e.preventDefault();
    // const { name, value } = e.target;
    // const data = { ...details, [name]: value };
    // setDetails(data);
  };

  const handleProjectionSubmit = (e) => {
    e.preventDefault();
    const data = { details };
    if (details) {
      setSelectedList((prev) => [...prev, data]);
      setShowColums(true);
      setShowDelete(true);
      // setDetails("");
    }
  };

  const handleRemoveList = (idx) => {
    const data = [...details];
    // data.splice(idx, 1);
    setSelectedList(data.splice(idx, -1));
    setShowDelete(false);
    setShowColums(false);
  };

 

  const history = useHistory();

  return (
    <>
      {/* <EmployeeDashboardLayout pageTitle={`Reports`} goBack organization> */}
        <div className="admin-org-settings-wrap">
          {/* title container start */}
          <div className="title-btn-box subtitle-box">
            <p className="text">Reports</p>
            <FaAngleRight className="icon" />
            <p
              className="text"
              onClick={() =>
                history.push("/employee-dashboard-general-report-custom")
              }
            >
              custom Reports
            </p>
            <FaAngleRight className="icon" />
            <p className="active text">Create Report</p>
          </div>
          {/* title container end */}

          {/* form title start */}
          <div className="sub-right-box">
            <p className="title">Create Report</p>
          </div>
          {/* form title end */}

          {/* form start */}
          <div className="form-box" style={{ width: "80%" }}>
            <form onSubmit={handleSubmit} action="" className="form">
              <div
                className="wrapper"
                style={{ borderBottom: "unset", paddingBottom: "unset" }}
              >
                <p className="box-title">1. Report Information</p>
                <div className="form-wrap">
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="name" className="label">
                      Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Report Name"
                      className={`input ${details.name && "input-active"}`}
                      id="name"
                      name="name"
                      onChange={handleChange}
                      // value={details.name}
                    />
                  </div>
                  {/* group box end */}
                  {/* group box start */}
                  <div className="form-group">
                    <label
                      htmlFor="description"
                      className="label"
                      style={{ marginTop: "4px" }}
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      placeholder="Description"
                      className={`input ${details.name && "input-active"}`}
                      id="description"
                      name="description"
                      onChange={handleChange}
                      value={details.description}
                    />
                  </div>
                  {/* group box end */}
                </div>
              </div>
              <div className="title-btn-box" style={{ marginTop: "-7rem" }} />
              <div
                className="wrapper"
                style={{ borderBottom: "unset", paddingBottom: "unset" }}
              >
                <p className="box-title">2. Module Information</p>
                <div className="form-wrap">
                  {/* group box start */}
                  <div className="form-group">
                    <label htmlFor="main_module" className="label">
                      Main Module <span>*</span>
                    </label>
                    <select
                      className={`input ${
                        details.main_module && "input-active"
                      }`}
                      id="main_module"
                      name="main_module"
                      onChange={handleChange}
                      value={details.main_module}
                    >
                      <option value="select">Select</option>
                    </select>
                  </div>
                  {/* group box end */}
                  {/* group box start */}
                  <div className="form-group">
                    <label
                      htmlFor="related_module"
                      className="label"
                      style={{ marginTop: "4px" }}
                    >
                      Related Module
                    </label>
                    <select
                      className={`input ${details.name && "input-active"}`}
                      id="description"
                      name="description"
                      onChange={handleChange}
                      value={details.description}
                    >
                      <option value="select">Select</option>
                    </select>
                  </div>
                  {/* group box end */}
                </div>
                {/* group box start */}
                <div className="title-btn-box" />
                <p
                  className="box-title wrapper "
                  style={{ borderBottom: "unset", paddingBottom: "unset" }}
                >
                  3. Report Representation
                </p>
                <br />

                <div className="emp-columns-wrap">
                  {/* first column start */}
                  <div>
                    <p className="box-title">Available Columns</p>
                    {/* <p className="sub-title">Projections</p> */}
                    <div className="emp-projection-wra">
                      {/* <p className="list-title">Projections</p> */}
                      <div>
                        <select
                          // multiple
                          className="input"
                          value={details}
                          onChange={handleProjectionChange}
                          // options={projectionList}
                          // placeholder={header[0]}
                        >
                          <option value="" disabled selected hidden>
                            Projections
                          </option>
                          <option>Projected Revenue</option>
                          <option>User</option>
                          <option>Department</option>
                          <option>Subsidiary</option>
                          <option>Expense</option>
                          <option>Budget</option>
                        </select>
                        {/* <Select /> */}
                      </div>
                    </div>
                    <div className="add-btn">
                      <ButtonBlue
                        onClick={handleProjectionSubmit}
                        text={`Add`}
                      />
                    </div>
                  </div>
                  {/* first column end */}
                  {/* second column start (btn) */}
                  {/* second column end (btn) */}
                  {/* third column start */}
                  <div className="emp-selected-column-wrap">
                    {showColumns && (
                      <p className="box-title">Selected Columns</p>
                    )}

                    <div className="emp-projection-wrap">
                      {selectedList.map((item, idx) => {
                        return (
                          <p key={idx} className="list-text">
                            {item.details}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  {/* third column end */}
                  {/* fourth column start(delete) */}

                  {showDelete && (
                    <figure
                      onClick={handleRemoveList}
                      className="emp-delete-btn"
                    >
                      <img src={deleteIcon} alt="" />
                    </figure>
                  )}

                  {/* fourth column end (delete) */}
                </div>
                {/* group box end */}
              </div>
              <div className="title-btn-box" />
              <div
                className="wrapper"
                style={{ borderBottom: "unset", paddingBottom: "unset" }}
              >
                <p className="box-title">4. Filters</p>
                <div className="form-wrap">
                  {/* group-box start */}
                  <div className="form-group">
                    <label htmlFor="criteria" className="label">
                      Criteria
                    </label>
                    <input
                      type="text"
                      placeholder="Projection created time"
                      className={`input ${details.criteria && `input-active`}`}
                      id="criteria"
                      name="criteria"
                      onChange={handleChange}
                      value={details.criteria}
                    />
                  </div>
                  {/* group-box end */}
                  {/* group-box start */}
                  <div className="form-group">
                    <label
                      htmlFor="criteria"
                      className="label"
                      style={{ visibility: "hidden" }}
                    >
                      Criteria
                    </label>
                    <input
                      disabled
                      type="text"
                      placeholder="Period"
                      className={`input ${details.period && `input-active`}`}
                      id="period"
                      name="period"
                      onChange={handleChange}
                      value={details.period}
                    />
                  </div>
                  {/* group-box end */}
                </div>
                <div className="title-btn-box" />
                <ButtonBlue text={`Save & Run Report`} />
              </div>
            </form>
          </div>

          {/* form end */}
        </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default CreateReport;
