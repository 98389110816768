import React, { useContext, useEffect, useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
// import EmployeeDraftProjection from "../../projection/expense/EmpDraftProjection";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeSupplementaryLayout from "./links/CommonLayout";
import EmployeeActualSupplementaryBudgetDraft from "./draftProjectionIndex";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteActualSupplementaryBudget,
  getActualSupplementaryBudgetPagination,
  getActualSupplementaryBudgets,
  getSubmittedSupplementaryBudget,
  getSupplementaryBudgetBySearch,
  submitSingleSupplementaryDraftForApproval,
  submitSupplementaryDraftForApproval,
} from "../../../../../../redux/employee/actual-supplementary-budget/actual-supplementary-budget";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmpActualSupplementaryDelete from "./modals/delete";
import Modal from "react-awesome-modal";
import EmpSupplementaryOverview from "./modals/overview";
import { Dna, Oval } from "react-loader-spinner";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  formatNumber,
  perPageOptions,
  // FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import moment from "moment";
import ActionContext from "../../../../../../context/ActionContext";
import EmpActualSupBudgSubmitForApproval from "./modals/submit";
import { deleteExpenditueProjection } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
// import EmployeeCommonRevenueLayout from "./layout/CommonLayout";

const EmployeeActualSupplementaryIndex = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);
  const {
    loadingPage,
    loading,
    actualSupplementaryBudgets,
    submittedactualSupplementaryBudgets,
    loadingTwo,
  } = useSelector((state) => state.actual_supplementary_budget);
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const [showTable, setShowTable] = useState(false);
  // const [perPageVal, setPerPageVal] = useState({ label: "Per Page" });

  const [showModal, setShowModal] = useState({
    delete: false,
    approveOverview: false,
    submit: false,
  });

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "New Price",
    "Approved Price",
    "Unit Price",
    "Date",
  ];

  const cardHeader = ["Total Supplementary", "Exhausted Budget"];
  // const cardValue = ["00.00", "00.00"];

  useEffect(() => {
    user?.organizations?.length > 0 &&
      // dispatch(getActualSupplementaryBudgets(actionCtx?.activeOrg?.id));
      dispatch(
        getSubmittedSupplementaryBudget({ org_id: actionCtx?.activeOrg?.id })
      );
    handleGetActualSupData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, actionCtx?.activeOrg?.id, actionCtx?.perPage?.value]);

  const [search, setSearch] = useState("");

  // const SortByOptions = [
  //   { label: "Ascending", value: "ASC" },
  //   { label: "Descending", value: "DESC" },
  // ];
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  // const [sortByVal, setSortByVal] = useState({ label: "Sort by" });
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart of Account", value: "chart_of_account_id" },
    { label: "Class", value: "class" },
    // { label: "Date Created", value: "created_at" },
    // { label: "Revenue", value: "revenue" },
    // { label: "GP %", value: "gpp" },
  ];
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        q: search,
        filter_by: filterByVal?.value,
        // sort_by: sortByVal?.value,
        org_id: actionCtx?.activeOrg?.id,
        per_page: actionCtx?.perPage?.value,
      };
      dispatch(getSupplementaryBudgetBySearch(obj));
      setIndexNo(false);
      setShowTable(true);
    } else if (isMount && debouncedSearchTerm?.length < 1) {
      const obj = {
        org_id: actionCtx?.activeOrg?.id,
        per_page: actionCtx?.perPage?.value,
      };
      const data = dispatch(getActualSupplementaryBudgets(obj));
      if (data?.payload?.success) {
        setShowTable(false);
      }
    } else if (
      isMount &&
      debouncedSearchTerm?.length < 1 &&
      actualSupplementaryBudgets?.data?.length < 1
    ) {
      setShowTable(true);
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  // const handleSortChange = (param) => {
  //   const obj = {
  //     // q: search,
  //     sort_by: param?.value,
  //     // sort_by: sortByVal?.value,
  //     org_id: actionCtx?.activeOrg?.id,
  //     per_page: actionCtx?.perPage?.value,
  //   };
  //   // console.log("obj", obj)
  //   setIndexNo(false);
  //   dispatch(getSupplementaryBudgetBySearch(obj));
  // };

  // console.log("actualSupplementaryBudgets?.data", actualSupplementaryBudgets?.data);

  const handleActualSupplementaryUpdate = (param, update = false) => {
    actionCtx?.setIsModalOut(false);
    localStorage.setItem("single-actual-supplementary-budget-id", param.id);
    history.push("/employee-dashboard-actual-supplementary-budget-form-update");
    if (update) {
      history.push(
        "/employee-dashboard-actual-supplementary-budget-form-update"
      );
    }
  };

  const handleFinish = (del = false) => {
    setIndexNo(false);
    setSingleChild({});
    actionCtx?.setIsModalOut(false);
    setRefreshTable(!refreshTable);
    setShowModal((prev) => {
      return { ...prev, delete: false, submit: false };
    });
    if (del) {
      setShowModal((prev) => {
        return {
          ...prev,
          approveOverview: false,
        };
      });
    }
  };

  // const handleSubmitDrafts = async () => {
  //   const val = actualSupplementaryBudgets?.data?.map((chi) => String(chi?.id));
  //   const obj = {
  //     supplementary_ids: val,
  //   };
  //   const data = await dispatch(submitSupplementaryDraftForApproval(obj));
  //   if (data?.payload?.success) {
  //     setIndexNo(false);
  //     handleFinish();
  //   }
  // };

  const [singleItem, setSingleItem] = useState({});
  const handleGetActualSupData = async () => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx?.perPage?.value,
    };
    const data = await dispatch(getActualSupplementaryBudgets(obj));
    if (data?.payload?.success) {
      // setIndexNo(false);
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };

  const cardValue = [
    singleItem?.total_supplementary || 0,
    singleItem?.exhausted_budget || 0,
    // singleItem?.book_balance || 0,
    // singleItem?.available_balance || 0,
  ];

  // const handlePagination = async (param) => {
  //   const splitLink = param.split("/api");
  //   const linkTwo = splitLink[1];
  //   // console.log("linkTwo", linkTwo);
  //   setIndexNo(false);
  //   dispatch(getActualSupplementaryBudgetPagination(linkTwo));
  // };

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      // filter_submitted: false,
      filter_by: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: linkTwo,
    };
    setIndexNo(false);
    dispatch(getActualSupplementaryBudgetPagination(obj));
  };
  const handlePaginationNum = async (num) => {
    // const splitLink = param.split("/api");
    // const splitLink = param.split("?page=");
    // const linkTwo = splitLink[1];
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      // filter_submitted: false,
      filter_by: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: num,
    };
    setIndexNo(false);
    dispatch(getActualSupplementaryBudgetPagination(obj));
  };

  // console.log("checking this", actualSupplementaryBudgets)

  // multi select checkbox
  const [selectedSubmit, setSelectedSubmit] = useState([]);
  const [checkHeader, setCheckHeader] = useState(!true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitDrafts = async () => {
    // const val = actualSupplementaryBudgets?.data?.map((chi) => String(chi?.id));
    const obj = {
      supplementary_ids: selectedSubmit,
    };
    setSubmitLoading(true);
    const data = await dispatch(submitSupplementaryDraftForApproval(obj));
    if (data?.payload?.success) {
      setSubmitLoading(false);
      handleFinish();
      history.push("/employee-dashboard-actual-supplementary-budget-submitted");
    } else {
      setSubmitLoading(false);
    }
  };

  const handleCheckSubmit = (id) => {
    const checkedList = selectedSubmit.indexOf(id);
    if (checkedList > -1) {
      setSelectedSubmit(selectedSubmit.filter((chi) => chi !== id));
    } else {
      setSelectedSubmit([...selectedSubmit, id]);
    }
  };
  const handleAllSubmitCheck = (e) => {
    if (e.target.checked) {
      setSelectedSubmit(actualSupplementaryBudgets?.data?.map((chi) => chi.id));
    } else {
      setSelectedSubmit([]);
    }
    setCheckHeader(e.target.checked);
  };

  // handleDeleteAll
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async () => {
    const obj = {
      multi: true,
      ids: selectedSubmit,
      projection_type: "supplementary_budget",
    };
    setDeleteLoading(true);
    const data = await dispatch(deleteExpenditueProjection(obj));
    if (data?.payload?.success) {
      setDeleteLoading(false);
      handleFinish();
    } else {
      setDeleteLoading(false);
    }
  };

  // console.log(actualSupplementaryBudgets, "draft");
  // console.log(submittedactualSupplementaryBudgets, "submitted");

  const [showActionDropDown, setShowActionDropDown] = useState(false);

  return (
    <>
      {actualSupplementaryBudgets?.data?.length > 0 ||
      submittedactualSupplementaryBudgets?.data?.length > 0 ||
      showTable ? (
        <EmployeeSupplementaryLayout
          supplementaryLink={true}
          showCommonLink={actualSupplementaryBudgets?.data?.length < 1}
        >
          {(actualSupplementaryBudgets?.data?.length > 0 ||
            (debouncedSearchTerm?.length > 0 &&
              actualSupplementaryBudgets?.data?.length < 1) ||
            showTable) && (
            <EmployeeReusableTable
              submitAll
              onSubmitAll={() => {
                selectedSubmit?.length > 0 && handleSubmitDrafts();
              }}
              submitBgColor={selectedSubmit?.length < 1 && "#cccccc"}
              submitLoader={submitLoading}
              allActionBtn
              perPage
              optionPerPage={perPageOptions}
              setPerPage={(e) => {
                actionCtx?.setPerpage(e);
              }}
              perPageVal={actionCtx?.perPage}
              showDropDown={showActionDropDown}
              setShowDropDown={() => {
                setShowActionDropDown(!showActionDropDown);
                setIndexNo(false);
              }}
              filter
              // sort
              // optionSort={SortByOptions}
              // optionSortVal={sortByVal}
              // setOptionSortVal={(e) => {
              //   setIndexNo(false);
              //   setSortByVal(e);
              //   handleSortChange(e);
              // }}
              optionFilter={filterByOptions}
              optionFilterVal={filterByVal}
              setOptionFilterVal={(e) => {
                setIndexNo(false);
                setFilterByVal(e);
                // handleFil
              }}
              onSearchTable={(e) => {
                setIndexNo(false);
                setSearch(e);
                // console.log("e", e)
              }}
              cardTitleOne={cardHeader[0]}
              thirdCard
              fourthCard
              showCardThree={"hidden"}
              showCardFour={"hidden"}
              cardTitleTwo={cardHeader[1]}
              cardBodyOne={formatNumber(String(cardValue[0] || 0))}
              cardBodyTwo={formatNumber(String(cardValue[1] || 0))}
              addButton={actualSupplementaryBudgets?.data?.length > 0}
              BtnTitle={`New Budget`}
              firstCard={true}
              secondCard={true}
              showSearch
              onPage={() =>
                history.push(
                  "/employee-dashboard-actual-supplementary-budget-form"
                )
              }
              delBtn
              onDel={() => {
                selectedSubmit?.length > 0 && handleDelete();
              }}
              delLoader={deleteLoading}
              loading={loadingTwo}
              delBtnTitle={"Delete Budget"}
              delBgColor={selectedSubmit?.length > 0}
            />
          )}

          {/* table start  */}
          {/* {children} */}
          {/* <div className="table-bo"> */}
          {/* table box start */}
          {(actualSupplementaryBudgets?.data?.length > 0 ||
            (debouncedSearchTerm?.length > 0 &&
              actualSupplementaryBudgets?.data?.length < 1) ||
            showTable) && (
            <ActualReuseableTable
              key={`dffgjsh`}
              massSelectable={actualSupplementaryBudgets?.data?.length > 0}
              checkValue={checkHeader}
              onMassCheck={(e) => {
                handleAllSubmitCheck(e);
              }}
              useNumPagination
              currentPage={actualSupplementaryBudgets?.current_page}
              totalTableLength={actualSupplementaryBudgets?.total}
              onNumPage={(e) => {
                setShowActionDropDown(false);
                handlePaginationNum(e);
              }}
              dontShowBnSearch
              one={tableHeadList[0]}
              two={tableHeadList[1]}
              three={tableHeadList[2]}
              four={tableHeadList[3]}
              five={tableHeadList[4]}
              six={tableHeadList[5]}
              seven={tableHeadList[6]}
              action
              dontShowPagination={actualSupplementaryBudgets?.data?.length < 1}
              fromPage={actualSupplementaryBudgets?.from}
              toPage={actualSupplementaryBudgets?.to}
              prevPage={actualSupplementaryBudgets?.prev_page_url}
              nextPage={actualSupplementaryBudgets?.next_page_url}
              onNextPage={() => {
                setShowActionDropDown(false);
                handlePagination(actualSupplementaryBudgets?.next_page_url);
              }}
              onPrevPage={() => {
                setShowActionDropDown(false);
                handlePagination(actualSupplementaryBudgets?.prev_page_url);
              }}
              totalPage={actualSupplementaryBudgets?.total}
            >
              <>
                {actualSupplementaryBudgets?.data?.map((chi, idx) => {
                  const {
                    chartofaccount,
                    // classes,
                    new_price,
                    approved_price,
                    unit_price,
                    date,
                    id,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      checkBoxType
                      onCheck={(e) => {
                        handleCheckSubmit(id);
                      }}
                      checkValue={selectedSubmit.includes(id)}
                      checkId={`${id}-${idx}`}
                      item={chi}
                      key={idx}
                      one={chartofaccount?.name}
                      two={chartofaccount?.class}
                      three={formatNumber(String(new_price || 0))}
                      four={formatNumber(String(approved_price || 0))}
                      five={formatNumber(String(unit_price || 0))}
                      six={moment(date).format("YYYY-MM-DD")}
                      loading={loadingPage}
                      action
                      rowNo={idx}
                      indexNo={indexNo}
                      setIndexNo={() => {
                        setShowActionDropDown(false);
                        setIndexNo(idx);
                      }}
                      updateText={`Update`}
                      deleteText={`Delete`}
                      updateTextTwo={`Submit for Approval`}
                      // onUpdateTwo={async (e) => {
                      //   e.preventDefault();
                      //   // dispatch submit projection action
                      //   // then redirect to the submission page

                      //   const dat = { supplementary_ids: [chi?.id] };

                      //   const data = await dispatch(
                      //     submitSingleSupplementaryDraftForApproval(dat)
                      //   );

                      //   if (data?.payload?.success) {
                      //     handleFinish(true);
                      //   }
                      // }}
                      onUpdateTwo={() => {
                        setIndexNo(false);
                        setShowActionDropDown(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, submit: true };
                        });
                      }}
                      onDelete={() => {
                        setIndexNo(false);
                        setShowActionDropDown(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, delete: true };
                        });
                      }}
                      onUpdate={() => handleActualSupplementaryUpdate(chi)}
                      onOverview={() => {
                        setIndexNo(false);
                        setShowActionDropDown(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approveOverview: true };
                        });
                      }}
                    />
                  );
                })}
              </>
            </ActualReuseableTable>
          )}
          {/* table box end */}
          {/* </div> */}
        </EmployeeSupplementaryLayout>
      ) : (
        <div style={{ marginTop: "20rem" }}>
          {loadingPage ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Oval
                height={80}
                width={80}
                color="var(--blue-color)"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--blue-color)"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <EmployeeActualSupplementaryBudgetDraft>
              <ButtonBlue
                onClick={() =>
                  history.push(
                    "/employee-dashboard-actual-supplementary-budget-form"
                  )
                }
                text={`Add custom budget`}
              >
                <FaPlus style={{ marginRight: "15px" }} />
              </ButtonBlue>
            </EmployeeActualSupplementaryBudgetDraft>
          )}
        </div>
      )}

      {/* modal start */}
      <Modal visible={showModal.delete}>
        <EmpActualSupplementaryDelete
          details={singleChild}
          onFinish={handleFinish}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmpSupplementaryOverview
          data={singleChild}
          handleSubmit={async () => {
            // e.preventDefault();
            // dispatch submit projection action
            // then redirect to the submission page

            const dat = { supplementary_ids: [singleChild?.id] };

            const data = await dispatch(
              submitSingleSupplementaryDraftForApproval(dat)
            );

            if (data?.payload?.success) {
              handleFinish(true);
              history.push(
                "/employee-dashboard-actual-supplementary-budget-submitted"
              );
            }
          }}
          onUpdate={() =>
            // history.push("/employee-dashboard-actual-revenue-form")
            {
              // console.log("chi", singleChild?.id);
              localStorage.setItem(
                "single-actual-supplementary-budget-id",
                singleChild.id
              );
              history.push(
                "/employee-dashboard-actual-supplementary-budget-form-update"
              );
              actionCtx?.setIsModalOut(false);
            }
          }
          onDelete={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, delete: true, approveOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
          // onUpdate={handleExpenditureProjectionUpdate()}
          details={singleChild}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.submit}>
        <EmpActualSupBudgSubmitForApproval
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, submit: false };
            });
          }}
          details={singleChild}
          onFinish={handleFinish}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeActualSupplementaryIndex;
