import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import { createVertical } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import CommonInputModal from "./CommonInputModal";

const AddBudgetRevVerticalModal = ({ onCancel, onFinish }) => {
  const { loading } = useSelector((state) => state.vertical);
  const [details, setDetails] = useState({
    organization_id: "",
    name: "",
    description: "",
  });
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async () => {
    const obj = {
      organization_id: actionCtx?.activeOrg?.id,
      name: details?.name,
      description: details?.description,
    };
    const data = await dispatch(createVertical(obj));
    if (data?.payload?.success) {
      setDetails({
        organization_id: "",
        name: "",
        description: "",
      });
      onFinish();
    }
  };

  return (
    <>
      <CommonInputModal
        onCancel={onCancel}
        title={`New Vertical`}
        btnText={`Save`}
        loading={loading}
        btnActive={details?.description && details?.name}
        onBtnClick={handleSubmit}
        btnDisable={!details?.description || !details?.name}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={details.name}
            type="text"
            placeholder="Name of vertical"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
            value={details.description}
            type="text"
            placeholder="describe your vertical"
          ></textarea>
        </div>
        {/* group box end */}
      </CommonInputModal>
    </>
  );
};

export default AddBudgetRevVerticalModal;
