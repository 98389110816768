import React, { useState } from "react";
import Select from "react-select";
// import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import "../../../../../../employee-folder/styles/dashboard/home/modals/advancedSearch.scss";

const EmployeeAdvancedSearchModal = ({ children }) => {
  const [details, setDetails] = useState({
    fiscal_year: "",
    subsidiary: "",
    branch: "",
    department: "",
    user: "",
    select_date: "",
    date: "",
  });

  const optionList = [
    {
      label: "2021",
      value: 1,
    },
    {
      label: "2022",
      value: 2,
    },
    {
      label: "2023",
      value: 3,
    },
  ];

  return (
    <>
      <div className="emp-advanced-search-wrap">
        <div>
          <p className="search-head">Advanced Search</p>
        </div>
        <div className="search-input-wrap">
          <div className="search-input-box">
            <label htmlFor="fiscal_year" className="label">
              Fiscal Year
            </label>
            <Select
              options={optionList}
              value={details.fiscal_year}
              className="select"
              onChange={(e) =>
                setDetails((prev) => {
                  return { ...prev, fiscal_year: e };
                })
              } 
              placeholder="enter or select fiscal year"
            />
          </div>
          <div className="search-input-box">
            <label htmlFor="subsidiary" className="label">
              Subsidiary
            </label>
            <Select
              options={optionList}
              value={details.subsidiary}
              className="select"
              onChange={(e) =>
                setDetails((prev) => {
                  return { ...prev, subsidiary: e };
                })
              }
              placeholder="enter or select subsidiary"
            />
          </div>
          <div className="search-input-box">
            <label htmlFor="branch" className="label">
              Branch
            </label>
            <Select
              options={optionList}
              value={details.branch}
              className="select"
              onChange={(e) =>
                setDetails((prev) => {
                  return { ...prev, branch: e };
                })
              }
              placeholder="enter or select branch"
            />
          </div>
          <div className="search-input-box">
            <label htmlFor="department" className="label">
              Department
            </label>
            <Select
              options={optionList}
              value={details.department}
              className="select"
              onChange={(e) =>
                setDetails((prev) => {
                  return { ...prev, department: e };
                })
              }
              placeholder="enter or select deparment"
            />
          </div>
          <div className="search-input-box">
            <label htmlFor="user" className="label">
              User
            </label>
            <Select
              options={optionList}
              value={details.user}
              className="select"
              onChange={(e) =>
                setDetails((prev) => {
                  return { ...prev, user: e };
                })
              }
              placeholder="enter or select user"
            />
          </div>
          <div className="search-input-box search-input-box-three">
            <label htmlFor="date" className="label">
              Date within
            </label>
            <div>
              <Select
                options={optionList}
                value={details.select_date}
                className="select"
                onChange={(e) =>
                  setDetails((prev) => {
                    return { ...prev, select_date: e };
                  })
                }
                placeholder="1 day"
              />
            </div>
            <div>
              <Select
                options={optionList}
                value={details.date}
                className="select"
                onChange={(e) =>
                  setDetails((prev) => {
                    return { ...prev, date: e };
                  })
                }
                placeholder="enter date"
              />
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};
export default EmployeeAdvancedSearchModal;
