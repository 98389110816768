import React, { useContext, useEffect } from "react";
// import { useSelector } from "react-redux";
import ActionContext from "../../../context/ActionContext";
import "../../../styles/dashboard/DashboardLayout.css";
import HeaderNav from "./HeaderNav";
import Modal from "react-awesome-modal";
import SidebarMenu from "./SidebarMenu";
import NotificationModal from "./NotificationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  // useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import SubscriptionEndModal from "../../fragments/SubscriptionEndModal";
import { getUserActivePlan } from "../../../redux/users/users";
import SessionTimeoutModal from "../../fragments/SessionTimeoutModal";
import moment from "moment";
import RevvexActionBar from "../../fragments/ActionBar";
// import { toast } from "react-toastify";
// import { useThemeDetector } from "../../../utils/getThemeColor";

const DashboardLayout = ({ children, goBack, pageTitle, pageTitleSpan }) => {
  const actionCtx = useContext(ActionContext);
  const history = useHistory();
  const location = useLocation();
  // const [showExpire, setShowExpire] = useState(false);
  // const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // const { plan_details } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.user);
  const { plan_details } = useSelector((state) => state.users);

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
    };
    getUserPlanFunc(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserPlanFunc = async (obj) => {
    const data = await dispatch(getUserActivePlan(obj));
    // console.log(data);
    if (data?.payload?.success) {
      const obj = data?.payload?.data;
      if (Object.keys(obj)?.length > 0) {
        // console.log(obj);
        if (obj?.active_plan_details?.status === "active") {
          actionCtx.setPlanExpire(false);
        }
        if (obj?.active_plan_details?.status === "trail") {
          actionCtx.setPlanExpire(false);
        }
        if (
          obj?.active_plan_details?.status === "inactive" &&
          Object.keys(obj?.active_plan_details)?.length > 0 &&
          !obj?.active_plan_details?.is_trial
        ) {
          actionCtx.setPlanExpire(true);
        }
        // if( obj?.active_plan_details?.status !== "trail" && obj?.active_plan_details){
        //   setShowExpire(true);
        // }
      }
    }
    // if (!data?.payload?.response?.data?.success && !data?.payload?.success) {
    //   setShowExpire(true);
    // }
  };

  const getDaysLeft = (param) => {
    // console.log(param);
    const currentDate = moment(); // Current date and time
    const targetDate = moment(param); // Replace with your target date
    // console.log(param);
    const daysLeft = targetDate.diff(currentDate, "days");
    return daysLeft;
  };

  // useEffect(() => {
  //   if (plan_details) {
  //     if (
  //       Number(getDaysLeft(plan_details?.active_plan_details?.end_date)) <= 5 &&
  //       Object.keys(plan_details)?.length > 0
  //     ) {
  //       actionCtx.setShowInfoSubExpire(true);
  //       // console.log("owoiie");
  //     }
  //     if (
  //       Number(getDaysLeft(plan_details?.active_plan_details?.end_date)) > 5
  //     ) {
  //       actionCtx.setShowInfoSubExpire(false);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [plan_details]);

  return (
    <>
      <div className="dashboard-layout-wrap">
        <div
          className={`sidebar ${actionCtx.isSidebarReduce && "sidebar-reduce"}`}
          style={{
            backgroundColor: "var(--blue-color)",
          }}
          // style={{
          //   backgroundColor:
          //     preference?.display === "Light"
          //       ? "var(--blue-color)"
          //       : preference?.display === "Dark"
          //       ? "var(--bg-white-color)"
          //       : isDarkTheme
          //       ? "var(--bg-white-color)"
          //       : "var(--blue-color)",
          // }}
        >
          <SidebarMenu />
        </div>
        <div
          className={`header ${actionCtx.isSidebarReduce && "header-reduce"}`}
        >
          <HeaderNav
            goBack={goBack}
            pageTitle={pageTitle}
            pageTitleSpan={pageTitleSpan}
          />
        </div>
        <div
          onClick={() => {
            actionCtx.profileBoxOpenFunc("close");
            actionCtx.setShowSupportDropDown("close");
          }}
          className={`main ${actionCtx.isSidebarReduce && "main-reduce"}`}
          style={{ zIndex: actionCtx?.isModalOut ? "4" : "1" }}
        >
          {/* epire less then show start */}

          {plan_details?.active_plan_details?.is_trial && (
            <>
              {" "}
              <RevvexActionBar
                msg={ `Your free plan ${plan_details?.active_plan_details?.days_left}`
                }
                type={
                  Number(
                    getDaysLeft(plan_details?.active_plan_details?.end_date)
                  ) <= 5 &&
                  Number(
                    getDaysLeft(plan_details?.active_plan_details?.end_date)
                  ) > 2
                    ? `info`
                    : Number(
                        getDaysLeft(plan_details?.active_plan_details?.end_date)
                      ) <= 2 &&
                      Number(
                        getDaysLeft(plan_details?.active_plan_details?.end_date)
                      ) > 0
                    ? `warning`
                    : Number(
                        getDaysLeft(plan_details?.active_plan_details?.end_date)
                      ) === 0
                    ? `error`
                    : ""
                }
                visible={
                  actionCtx.showInfoSubExpire &&
                  Object.keys(plan_details)?.length > 0
                }
                actionText={
                  user?.role === "creator administrator" ? "Change Plan" : ""
                }
                onCancel={() => {
                  actionCtx.setShowInfoSubExpire(false);
                }}
                onAction={() => {
                  user?.role === "creator administrator" &&
                    history.push("/dashboard-subscription-upgrade-plan");
                }}
              />
            </>
          )}

          {!location.pathname.includes("/dashboard-single-view-for-approval") &&
            location.pathname !== "/dashboard-subscription-upgrade-plan" &&
            !plan_details?.active_plan_details?.is_trial && (
              <RevvexActionBar
                msg={
                  Number(
                    getDaysLeft(plan_details?.active_plan_details?.end_date)
                  ) <= 5 &&
                  Number(
                    getDaysLeft(plan_details?.active_plan_details?.end_date)
                  ) !== 0
                    ? `Your subscription will expire in ${getDaysLeft(
                        plan_details?.active_plan_details?.end_date
                      )} days, click on "Change Plan" to renew or change plan.`
                    : Number(
                        getDaysLeft(plan_details?.active_plan_details?.end_date)
                      ) === 0
                    ? `Youre subscription has expired, click on "Change Plan" to renew or change plan.`
                    : ``
                }
                type={
                  Number(
                    getDaysLeft(plan_details?.active_plan_details?.end_date)
                  ) <= 5 &&
                  Number(
                    getDaysLeft(plan_details?.active_plan_details?.end_date)
                  ) > 2
                    ? `info`
                    : Number(
                        getDaysLeft(plan_details?.active_plan_details?.end_date)
                      ) <= 2 &&
                      Number(
                        getDaysLeft(plan_details?.active_plan_details?.end_date)
                      ) > 0
                    ? `warning`
                    : Number(
                        getDaysLeft(plan_details?.active_plan_details?.end_date)
                      ) === 0
                    ? `error`
                    : ""
                }
                visible={
                  actionCtx.showInfoSubExpire &&
                  Object.keys(plan_details)?.length > 0
                }
                actionText={
                  user?.role === "creator administrator" ? "Change Plan" : ""
                }
                onCancel={() => {
                  actionCtx.setShowInfoSubExpire(false);
                }}
                onAction={() => {
                  user?.role === "creator administrator" &&
                    history.push("/dashboard-subscription-upgrade-plan");
                }}
              />
            )}
          {/* expire less than show end */}
          {children}
        </div>

        {/* notification start */}
        {actionCtx?.showNotification && <NotificationModal />}
        {/* notification end */}
      </div>
      <Modal
        visible={
          location.pathname === "/dashboard-subscription-upgrade-plan"
            ? false
            : location?.pathname ===
              "/dashboard-administration-admin-settings-billing"
            ? false
            : actionCtx.planExpire
            ? true
            : false
        }
      >
        <SubscriptionEndModal />
      </Modal>
      <Modal visible={actionCtx?.tokenCrashed}>
        <SessionTimeoutModal />
      </Modal>
    </>
  );
};

export default DashboardLayout;
